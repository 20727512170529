import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CurrencyMasterBusinessRule } from '../models/CurrencyMasterListModel';
import { SharedService } from 'src/app/shared/services/shared.service';
@Injectable({
  providedIn: 'root'
})
export class CurrencyMasterService {

  constructor(private httpClient: HttpClient, private sharedService: SharedService) { }

  uploadFile(file) {
    let serviceUrl = this.sharedService.configuration.baseUrl + 'api/CurrencyMaster/UploadFileForProcessingForCurrencyMaster';

    return this.httpClient.post(serviceUrl, file)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  downloadTemplate(filepath) {
    filepath = 'CurrencyMaster-Add.xlsx';
    let serviceUrl = this.sharedService.configuration.baseUrl + "api/CurrencyMaster/DownloadCurrencyMasterTemplateFileFromBlob/" + filepath; 
    return this.httpClient.get(serviceUrl, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getGridData(parameters) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.httpClient.post(serviceUrl + 'api/CurrencyMaster/GetCurrencyMasterList',parameters)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportDataToExcel(parameters) {
    return this.httpClient.post(this.sharedService.configuration.baseUrl + 'api/CurrencyMaster/GetCurrencyMasterListForExport', parameters, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
}
