import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { LostBusinessLBCTotalTransactiondrilldown } from '../model/lost-business-lbcdrilldown';
import { LostBusinessDrilldownService } from '../service/lost-business-drilldown.service';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Paginator } from 'primeng/paginator';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-lbc-totalcnt-transaction-drilldown',
  templateUrl: './lbc-totalcnt-transaction-drilldown.component.html',
  styleUrls: ['./lbc-totalcnt-transaction-drilldown.component.scss']
})
export class LbcTotalcntTransactionDrilldownComponent implements OnInit {
  private imagepath = environment.imagepath;
  @ViewChild(Paginator) dt: Paginator;
  @Output('parameter') parameter: EventEmitter<any> = new EventEmitter<any>();
  @Output('divclose') divclose:EventEmitter<any>=new EventEmitter<any>();
  @Input() showTotalText: any;
  @Input() totalCustomerCount: number;
  @Input() lostBaseYear:any;
  drilldownListData: LostBusinessLBCTotalTransactiondrilldown[];
  cols: any[];
  totalRecords: number;
  loading: boolean = false;
  pageNo = 1;
  pageSize = 10;
  configRow: number[] = [10, 20, 30, 40, 50];
  sortObj: any = {
    'sortType': '',
    'sortField': ''
  };
  flag: any;
  searchString: string = '';
  searchBy: string = '';
  successMsg: boolean = false;
  msgs: Message[] = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  apiResponseData: any;
  paginatorFirstSelector: any;
  paginatorLaststSelector: any;
  paginatorNextSelector: any;
  paginatorPrevSelector: any;
  showNoData: boolean = false;
  showErrorData: boolean = false;
  columnList = [];
  searchPlaceholder = 'Enter your text here';
  dataNotFoundMessage: any;
  paramValue :any;
  genericErrorMessage = 'An error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail;
  searchStringForState:string="";
  searchByForState:string="";
  searchByFieldValue:any;
  constructor(private LostBusinessDrilldownService: LostBusinessDrilldownService
    , private messageService: MessageService
    , private sharedService: SharedService) {
  
  }

  ngOnInit() {
   
    this.GetData();
  }
  GetData() {
    this.pageNo = 1;
    this.pageSize = 10;
    this.getColumnNameList();
    this.cols = [
      { field: 'CustomerName', header: 'Customer Name' },
      { field: 'AccountNo', header: 'Account Number' },
      { field: 'TransactionCnt', header: 'Count of Transactions ' },

    ];
    this.drilldownListData = [];
    if(this.LostBusinessDrilldownService.ddNav1GetData().Data){
     let {pageNo, pageSize, sortField, sortType, searchString, searchBy} = this.LostBusinessDrilldownService.ddNav1GetData().Data;
     this.pageNo = pageNo;
     this.pageSize= pageSize;
     this.sortObj.sortField = sortField;
     this.sortObj.sortType = sortType;
     this.searchString=this.searchStringForState=searchString;
     if(searchBy && searchBy!==''){
      this.searchByForState = this.searchByFieldValue=searchBy;
      this.searchBy= this.searchByFieldValue.code;
     }
     this.dt.first=(pageNo-1)*pageSize;
    } 
    this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
    
  }
  getColumnNameList() {
    this.columnList = [
      { name: 'Search All', code: null },
      { name: 'Customer Name', code: 'CustomerName' },
      { name: 'Account Number', code: 'AccountNumber' },

    ];
  }

  onChange(event) {
    if (event.value != null) {
      this.searchBy = event.value.code;
    } else {
      this.searchByForState= this.searchBy = '';
    }
    // To change place holder of search textbox
    if (this.searchBy === 'CustomerName') {
      this.searchPlaceholder = 'Search by Customer Name';
    } else if (this.searchBy === 'AccountNumber') {
      this.searchPlaceholder = 'Search by Account Number';
    } else {
      this.searchPlaceholder = 'Enter your text here';
    }

  }
  searchByFields(event) {
    if (event && event.which == '13' || event.which == '1') {
      if (this.searchString != null && this.searchString !== '') {
        this.dataNotFoundMessage = 'No matching search results.';
        //this.showNoData = true;
      }
      this.searchString = this.searchString;
      this.pageNo = 1;
      this.searchStringForState=this.searchString;
      this.searchByForState= this.searchByFieldValue;
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
      this.dt.first = 0;
    }
  }
  resetSearchfilter() {
    this.searchString = '';
    this.pageNo = 1,
    this.pageSize = 10;
    this.searchStringForState='';
    this.searchByForState= '';
    this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
    this.dt.first = 0;
  }
  paginationData(pageNo, pageSize, sortField = '', sortType = '', searchString = '', searchBy= '') {
    this.loading = true;
    this.drilldownListData = [];
    this.drilldownListData.push({} as LostBusinessLBCTotalTransactiondrilldown);

    this.LostBusinessDrilldownService.getTotalTransactionList(pageNo, pageSize, sortField, sortType, searchString, searchBy).then(rawData => {
      // console.log(rawData.Response);
      this.pageNavigationToolTip();
      if (rawData == null) {

        this.drilldownListData = [];
        this.totalRecords = 0;
        this.pageNo = pageNo;
        this.pageSize = pageSize;
        this.loading = false;
        this.showNoData = true;
      } else if (rawData.Status == "Success" && rawData.Response.Collection.length == 0 && this.searchBy == '' && this.searchString == ''){
        this.showNoData = true;
      } else if (rawData.Status == "Failure") {
        this.showErrorData = true;
      } else {
        let rawResponse: any = rawData
        if (rawResponse.Status == 'Success') {
          let data = rawResponse.Response;
          //console.log(data, "data");
          this.drilldownListData = data.Collection;
         // console.log(this.drilldownListData, "drilldowndata");
          this.totalRecords = data.TotalRecords;
          this.pageNo = data.CurrentPageNo;
          this.pageSize = data.PageSize;
          this.loading = false;
        } else {
          if (rawResponse.Errors != null && rawResponse.Errors.length > 0) {
            this.successMsg = true;
            this.callErrorMsg('error', '', this.genericErrorMessage);
          } else {
            this.showEmptyGrid(1, 10);
          }
        }
        //this.dt.changePage(pageNo-1)
        this.loading = false;
      }
    }).catch(e => {
      this.drilldownListData = null;
      this.successMsg = false;
      //this.showErrorData = true;
      this.callErrorMsg('error', '', this.genericErrorMessage);
    })
  }
  showEmptyGrid(page: any, size: any) {
    this.drilldownListData = [];
    this.totalRecords = 0;
    this.pageNo = page;
    this.pageSize = size;
    this.loading = false;
  }
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    window.scrollTo(0, 0);
    setTimeout(function () { document.getElementById('ClearMsg').click(); }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }

  //Code change- Applying tooltip to all html element as there are more than one html element
  //Pagination tooltip issue
  //JIRA Id- 356
  pageNavigationToolTip() {
    for (let i = 0; i < document.querySelectorAll('a.ui-paginator-first').length; i++)
      document.querySelectorAll('a.ui-paginator-first')[i]['title'] = 'First';

    for (let i = 0; i < document.querySelectorAll('a.ui-paginator-last').length; i++)
      document.querySelectorAll('a.ui-paginator-last')[i]['title'] = 'Last';

    for (let i = 0; i < document.querySelectorAll('a.ui-paginator-next').length; i++)
      document.querySelectorAll('a.ui-paginator-next')[i]['title'] = 'Next';

    for (let i = 0; i < document.querySelectorAll('a.ui-paginator-prev').length; i++)
      document.querySelectorAll('a.ui-paginator-prev')[i]['title'] = 'Previous';
  }
  paginationNavigationDetails(event) {
    if (event.page >= this.pageNo || (event.page + 2) <= this.pageNo || this.pageSize != event.rows) {
      this.pageNo = event.page + 1;
      this.pageSize = event.rows;
      this.searchStringForState=this.searchString;
      this.searchByForState= this.searchByFieldValue;
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
    }
  }
  customSort(event: any) {
    if (event.sortField) {
      event.sortOrder = event.sortOrder == 1 ? 'asc' : 'dsc';
      this.pageNo = 1;
      this.sortObj.sortType = event.sortOrder;
      this.sortObj.sortField = event.sortField;
      this.searchStringForState=this.searchString;
      this.searchByForState= this.searchByFieldValue;
      this.paginationData(this.pageNo, this.pageSize, event.sortField, event.sortOrder, this.searchString, this.searchBy);
      this.dt.first = 0;
    }
  }
  showTransDetail(data) {
    //console.log(data.TransactionCnt, 'Transcount');
   // console.log(data.AccountNo, 'AccountNo');
    //console.log(data.CustomerName, 'CustomerName');
    if(data.TransactionCnt > 0){
     
      this.paramValue = {
        accountNumber: data.AccountNo,
        custName: data.CustomerName,
        IsActive: true,
        FAMCode: data.FAMCode,
        FAMID: data.FAMID,
        BAID: data.BAID,
        pageNo :this.pageNo,
        pageSize: this.pageSize, 
        sortField: this.sortObj.sortField,
        sortType : this.sortObj.sortType, 
        searchString: this.searchStringForState,
        searchBy: this.searchByForState
      }
      this.parameter.emit(this.paramValue);
     // console.log(this.parameter,"param");
    }else{
      return null;
    }
  }
  Hidediv(){
    this.divclose.emit();
  }
}
