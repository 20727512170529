import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CtlsBreadCrumbComponent } from './ctls-bread-crumb.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CtlsBreadCrumbComponent],
  exports: [CtlsBreadCrumbComponent],
})
export class CtlsBreadCrumbModule {}
