import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ctlsTextFromArray'
})
export class CtlsTextFromArrayPipe implements PipeTransform {

  transform(value: any, key: string): any {
    return value ? value.map(obj => obj[key]).join() : '';
  }

}
