// import {ChangeDetectorRef} from '@angular/core'

import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-generic-grid',
  templateUrl: './generic-grid.component.html',
  styleUrls: ['./generic-grid.component.scss'],  
})
export class GenericGridComponent implements OnInit, OnChanges {

  loading: any;
  // constructor(private ref: ChangeDetectorRef) { }
  constructor() { }

  pageNoTemp: any = 0;
  pageSizeTemp: any = 0;
  totalRecordsTemp: any = [];
  colsTemp; any = [];
  loadingTemp: boolean = false;
  configRowTemp: number[] = [10, 20, 30, 40, 50];
  paginatorFirstSelectorTemp: any;
  paginatorLaststSelectorTemp: any;
  paginatorNextSelectorTemp: any;
  paginatorPrevSelectorTemp: any;
  dataTemp: any = [];
  colsSpanCount=0;

  @Input('cols') cols: any;
  @Input('rows') rows: any;
  @Input('gridName') gridName:string
  @Input('gridHeader') gridHeader:string
  @Input('noRecordMessage') noRecordMessage:string  

  @Output('onPageChange') onPageChange:EventEmitter<any> = new EventEmitter<any>();
  ngOnInit() {
    this.colsSpanCount = this.cols.length;
    this.colsTemp = this.cols;
    this.dataTemp = this.cols;

    if (this.pageNoTemp == 0 && this.pageSizeTemp == 0) {
      this.pageNoTemp = 1;
      this.pageSizeTemp = 10;
      this.totalRecordsTemp = 0;
      this.paginationDataTemplate(this.pageNoTemp, this.pageSizeTemp);
    }

  }

  ngAfterViewInit() {
    this.paginatorFirstSelectorTemp = <HTMLElement>document.querySelector("a.ui-paginator-first");
    this.paginatorFirstSelectorTemp.title = 'First';

    this.paginatorLaststSelectorTemp = <HTMLElement>document.querySelector("a.ui-paginator-last");
    this.paginatorLaststSelectorTemp.title = 'Last';

    this.paginatorNextSelectorTemp = <HTMLElement>document.querySelector("a.ui-paginator-next");
    this.paginatorNextSelectorTemp.title = 'Next';   

    this.paginatorPrevSelectorTemp = <HTMLElement>document.querySelector("a.ui-paginator-prev");
    this.paginatorPrevSelectorTemp.title = 'Previous';
    // this.ref.detectChanges();

  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
   
    // this.paginatorNextSelectorTemp = <HTMLElement>document.querySelector("a.ui-paginator-next");
    // this.paginatorNextSelectorTemp.title = 'Next';
    

    if (this.pageNoTemp == 0 && this.pageSizeTemp == 0) {
      this.pageNoTemp = 1;
      this.pageSizeTemp = 10;
      this.totalRecordsTemp = 0;
      
    }
    this.paginationDataTemplate(this.pageNoTemp, this.pageSizeTemp);
  }

  paginationNavigationTemp(event) {
    if (event.page >= this.pageNoTemp || (event.page + 2) <= this.pageNoTemp || this.pageSizeTemp != event.rows) {
      this.pageNoTemp = event.page + 1;
      this.pageSizeTemp = event.rows;
      this.onPageChange.emit({pageNo: this.pageNoTemp, pageSize: this.pageSizeTemp, gridName:this.gridName})
      
    }
  }
  

  paginationDataTemplate(pageNo, pageSize) {
    this.loadingTemp = true;
    this.dataTemp = [{}];

    if (this.rows && this.rows != null) {

      this.dataTemp = this.rows.Collection;
      this.totalRecordsTemp = this.rows.TotalRecords;
      this.pageNoTemp = this.rows.CurrentPageNo;
      this.pageSizeTemp = this.rows.PageSize;
      this.loadingTemp = false;

    }
    else {
      this.loadingTemp = false;
      this.dataTemp = null;
    }

  }

}
