import { ChartBindingsModel } from "src/app/components/dashboard/chartlisting.component/models/chart-binding.model";
import * as Highcharts from 'highcharts'
import { SharedService } from 'src/app/shared/services/shared.service';
import moment = require("moment");

export abstract class ChartDataParsing {
  public static dashBoardUrlImage: string;
  private static Highcharts: typeof Highcharts = Highcharts;
  private static HCColor: any = Highcharts;
  public static chart: any = {
    npst: 'npst',
    rrt: 'rrt',
    ssrr: 'ssrr',
    cc: 'cc',
    rsc:'RSC',
    nnt:'NNT'
  };
  private static LEGEND: any = {
    TREND_MRT_12: '12 MRT',
    TREND_MRT_6: '6 MRT',
    TREND_MRT_3: '3 MRT',
    TREND_MRT_12FULL: '12 Months Rolling Trend',
    TREND_MRT_6FULL: '6 Months Rolling Trend',
    TREND_MRT_3FULL: '3 Months Rolling Trend',
    TREND_CHART_MRT_12SHORT: '12 MR',
    TREND_CHART_MRT_6SHORT: '6 MR',
    TREND_CHART_MRT_3SHORT: '3 MR',
    TREND_CHART_CTSHORT: 'CT',
    TREND_CT: 'Closing Trend',
    PROMOTERS: 'Promoters',
    NEUTRAL: 'Neutral',
    DETRACTORS: 'Detractor',
    MRT_12: '12 Months Rolling Trend (12 MRT)',
    MRT_6: '6 Months Rolling Trend (6 MRT)',
    MRT_3: '3 Months Rolling Trend (3 MRT)',
    CT: 'Current Trend (CT)',
  };

  public static LEGEND_DUAL_AXIS: any = {
    RESPONSE_RECIVED: 'Response Received',
    NPS_Current_Trend: 'NPS Current Trend',
    NSS_Current_Trend: 'NSS Current Trend',
    NSS_12_MRT: 'NSS 12 MRT',
    NPS_12_MRT: 'NPS 12 MRT',
    NPS_3_MRT: 'NPS 3 MRT',
    NSS_3_MRT: 'NSS 3 MRT',
  };
  private static regularyAxis: any = {
    className: 'y-axis',
    labels: {
      format: '{value}%'
    },
    title: {
      text: ''
    },
    stackLabels: {
      enabled: true,
      style: {
        fontFamily: 'Source Sans Pro Regular',
        fontWeight: 'normal',
        color: '#666666',
        textShadow: false,
        textOutline: 'false',
        fontSize: '10px'
      },
      styledMode: true
    },
    max: 100,
    zoomEnabled: false
  };
  private static dualyAxis: any = [
    {
      labels: { format: '{value}%' },
      opposite: true,
      max: 100,
      title: { text: '' },
      lineWidth: 1,
      stackLabels: { enabled: true }
    }, {
      labels: { format: '{value}' },
      title: { text: '' },
      lineWidth: 1,
      stackLabels: { enabled: true }
    }];
  private static CHART_STATE_OPTIONS = {
    LOADING: 'loading',
    SUCCESS: 'success',
    NO_DATA: 'no_data',
    ERROR: 'error'
  }
  private static chartBottomGradient: number = 0;
  private static chartTopGradient: number = 1;

  public static parseNpsBarData(period: string, excludeProperties: string[], response: any, addDummySpline: boolean, relFlag = true): any[] {
    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    // Push placeholders for fields
    for (const key in response[period][0]) {
      if (!excludeProperties.includes(key)) {
        yAxisNpsParsed.yAxis.push({
          name: key, data: [], type: 'column', placeholder: key
        });
      }
    }
    // Add/bind data and related attributes
    response[period].forEach((element, i) => {
      yAxisNpsParsed.yAxis.forEach(i => {
        // Add data
        if (element[i.placeholder] !== undefined) {
          let period = [];
          period = element.Period.split(' ');
          if (!relFlag && period && period.length && (period[0].includes('Mar') ||
            period[0].includes('Jun') || period[0].includes('Sep') || period[0].includes('Dec'))) {
            if (i.placeholder === 'PromoterPerc') {
              i.data.push({
                y: element[i.placeholder],
                color: '#256716'
              });
            } else if (i.placeholder === 'NeutralPerc') {
              i.data.push({
                y: element[i.placeholder],
                color: '#775c1e'
              });
            } else {
              i.data.push({
                y: element[i.placeholder],
                color: '#6c1e27'
              });
            }
          } else {
            i.data.push(element[i.placeholder]);
          }
        }
        // bind related attributes
        switch (i.placeholder) {
          case 'PromoterPerc':
            i['color'] = '#43AF2A';
            i['name'] = this.LEGEND.PROMOTERS;
            break;
          case 'NeutralPerc':
            i['color'] = '#FFAF00';
            i['name'] = this.LEGEND.NEUTRAL;
            break;
          case 'DetractorPerc':
            i['color'] = '#C71026';
            i['name'] = this.LEGEND.DETRACTORS;
            break;
        }
      });
    });
    return yAxisNpsParsed.yAxis;
  }
  public static parseNpsBarData360Dashboard(period: string, excludeProperties: string[], response: any, addDummySpline: boolean): any[] {

    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    // Push placeholders for fields
    for (const key in response[period][0]) {
      if (!excludeProperties.includes(key)) {
        yAxisNpsParsed.yAxis.push({
          // name: key, data: [], type: 'column', placeholder: key
          name: response[period][0][key].ScreenCode, data: [], type: 'column', placeholder: key, color: response[period][0][key].Color
        });
      }
    }
    // Add/bind data and related attributes
    response[period].forEach((element, i) => {
      yAxisNpsParsed.yAxis.forEach(i => {
        // Add data
        if (element[i.placeholder] !== undefined)
          i.data.push(element[i.placeholder].Value)
        // bind related attributes
        switch (i.placeholder) {
          case 'PromoterPerc':
            i['color'] = '#43AF2A';
            i['name'] = this.LEGEND.PROMOTERS;
            break;
          case 'NeutralPerc':
            i['color'] = '#FFAF00';
            i['name'] = this.LEGEND.NEUTRAL;
            break;
          case 'DetractorPerc':
            i['color'] = '#C71026';
            i['name'] = this.LEGEND.DETRACTORS;
            break;
        }
      });
    });
    return yAxisNpsParsed.yAxis;
  }

  public static parseNpsBarDataTrendAnalysis(period: string, excludeProperties: string[], response: any, addDummySpline: boolean, toggleData?: boolean,chartTitle?: string): any[] {
    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    // Push placeholders for fields
    for (const key in response[period][0]) {
      if (!excludeProperties.includes(key)) {
        if( toggleData === true) {
          yAxisNpsParsed.yAxis.push({
            name: response[period][0][key].ScreenCode,yAxis: 1, data: [],toolTipData:'', type: 'column', placeholder: key, color: response[period][0][key].Color,
          });
        } else
        yAxisNpsParsed.yAxis.push({
          name: response[period][0][key].ScreenCode, yAxis: 0, data: [],toolTipData:'', type: 'column', placeholder: key, color: response[period][0][key].Color,
        });
      }
    }
    // Add/bind data and related attributes
    response[period].forEach((element, i) => {
      yAxisNpsParsed.yAxis.forEach(i => {
        // Add data
        if (element[i.placeholder] !== undefined){
          if(chartTitle === 'npst-trend' && !toggleData) {
            i.data.push(element[i.placeholder].Value)
            i.toolTipData = element[i.placeholder].Count
          }
          else if(chartTitle === 'npst-trend' && toggleData) {
            i.data.push(element[i.placeholder].Count)
            i.toolTipData = element[i.placeholder].Value
          } else {
            i.data.push(element[i.placeholder].Value)
          }
        }  
       
      });
    });
    return yAxisNpsParsed.yAxis;
  }
  public static parseNpsLineData(period: string, excludeProperties: string[], response: any, hideMRTs = false, filterData: any = {}): any[] {
    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    for (const key in response[period][0]) {
      if (!excludeProperties.includes(key)) {
        if (hideMRTs) {
          yAxisNpsParsed.yAxis.push({
            name: key, data: [], type: 'areaspline', placeholder: key, lineWidth: 1,
            dataLabels: {
              enabled: true,
              //allowOverlap:true,
              format: '{y}%',
              style: {
                fontFamily: 'Source Sans Pro Regular',
                textShadow: false,
                textOutline: 'false',
                fontSize: '10px'
              }
            }
          });
        } else {
          yAxisNpsParsed.yAxis.push({
            name: key, data: [], placeholder: key,
            dataLabels: {
              enabled: true,
              //allowOverlap:true,
              format: '{y}%',
              style: {
                fontFamily: 'Source Sans Pro Regular',
                textShadow: false,
                textOutline: 'false',
                fontSize: '10px'
              }
            }
          });
        }
      }
    }
    response[period].forEach(element => {
      let mrtIndex = yAxisNpsParsed.yAxis.findIndex(x => x.placeholder === 'MRT12Value');
      let ctIndex = yAxisNpsParsed.yAxis.findIndex(x => x.placeholder === 'CTValue');
      let halfmrtIndex = yAxisNpsParsed.yAxis.findIndex(x => x.placeholder === 'MRT6Value');
      let quartermrtIndex = yAxisNpsParsed.yAxis.findIndex(x => x.placeholder === 'MRT3Value');
      let arr = [mrtIndex, ctIndex];
      if (halfmrtIndex >= 0 && quartermrtIndex >= 0){
        arr = [ctIndex, mrtIndex, halfmrtIndex, quartermrtIndex];
      }
      for (let i = 0; i < arr.length; i++) {
      if (arr[i] > arr[i+1]) {

        let temp = yAxisNpsParsed.yAxis[arr[i]];

        yAxisNpsParsed.yAxis[arr[i]] = yAxisNpsParsed.yAxis[arr[i+1]];

        yAxisNpsParsed.yAxis[arr[i+1]] = temp;
      }
    }
      if (hideMRTs) {
        yAxisNpsParsed.yAxis.forEach(i => {
          if (element[i.placeholder] !== undefined) {
            i.data.push(element[i.placeholder]);
          }

          switch (i.placeholder) {
            case 'CTValue':
              i['color'] = '#555555';
              i['name'] = this.LEGEND.CT;
              i['fillColor'] = {
                linearGradient: {
                  x1: 1,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [this.chartBottomGradient, this.HCColor.Color('#C3E3FF').setOpacity(0.7).get('rgba')],
                  [this.chartTopGradient, this.HCColor.Color('#C3E3FF').setOpacity(0).get('rgba')]
                ]
              }
              break;
            case 'MRT12Value':
              i['color'] = '#03B5F0';
              i['name'] = this.LEGEND.MRT_12;
              i['fillColor'] = {
                linearGradient: {
                  x1: 1,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [this.chartBottomGradient, this.HCColor.Color('#A2B2BF').setOpacity(0.7).get('rgba')],
                  [this.chartTopGradient, this.HCColor.Color('#A2B2BF').setOpacity(0).get('rgba')]
                ]

              }
              break;
          }
        });
      } else {
        yAxisNpsParsed.yAxis.forEach(i => {
          if (element[i.placeholder] !== undefined) {
            i.data.push(element[i.placeholder]);
          }

          switch (i.placeholder) {
            case 'CTValue':
              i['color'] = '#5fb4e5';
              i['lineWidth'] = 0.5;
              i['type'] = 'areaspline';
              i['name'] = this.LEGEND.TREND_CHART_CTSHORT;
              i['fillColor'] = {
                linearGradient: {
                  x1: 1,
                  y1: 1,
                  x2: 0,
                  y2: 0
                },
                stops: [
                  [this.chartBottomGradient, this.HCColor.Color('#d8effd').setOpacity(0.4).get('rgba')],
                  [this.chartTopGradient, this.HCColor.Color('#5fb4e5').setOpacity(0.7).get('rgba')]
                ]
              }
              break;
            case 'MRT12Value':
              i['color'] = '#a0a0a0';
              i['marker'] = {enabled: false};
              i['states'] = {
                hover: {
                  enabled: false,
                  lineWidth: 0
                }
              };
              i['type'] = 'spline';
              i['name'] = this.LEGEND.TREND_CHART_MRT_12SHORT;
              /* i['fillColor'] = {
                linearGradient: 
                {
                  x1: 1,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [this.chartBottomGradient, this.HCColor.Color('#A2B2BF').setOpacity(0.7).get('rgba')],
                  [this.chartTopGradient, this.HCColor.Color('#A2B2BF').setOpacity(0).get('rgba')]
                ]
  
              } */
              break;
            case 'MRT6Value':
              i['color'] = '#9b562b';
              i['lineWidth'] = 0.5;
              i['type'] = 'spline';
              i['marker'] = {enabled: false};
              i['states'] = {
                hover: {
                  enabled: false,
                  lineWidth: 0
                }
              };
              i['name'] = this.LEGEND.TREND_CHART_MRT_6SHORT;
              /* i['fillColor'] = {
                linearGradient: {
                  x1: 1,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [this.chartBottomGradient, this.HCColor.Color('#A16930').setOpacity(0.7).get('rgba')],
                  [this.chartTopGradient, this.HCColor.Color('#d9ae81').setOpacity(0).get('rgba')]
                ]
  
              } */
              break;
            case 'MRT3Value':
              i['color'] = '#6ccdb3';
              i['marker'] = {enabled: false};
              i['dashStyle'] = 'Dash';
              i['type'] = 'spline';
              i['states'] = {
                hover: {
                  enabled: false,
                  lineWidth: 0
                }
              };
              i['name'] = this.LEGEND.TREND_CHART_MRT_3SHORT;
              /* i['fillColor'] = {
                linearGradient: {
                  x1: 1,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [this.chartBottomGradient, this.HCColor.Color('#0099CC').setOpacity(0.7).get('rgba')],
                  [this.chartTopGradient, this.HCColor.Color('#BFE5F2').setOpacity(0).get('rgba')]
                ]
  
              } */
              break;
          }
        });
      }
    });
    let ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === 'Number');
    if (ind >= 0) {
      yAxisNpsParsed.yAxis.splice(ind, 1);
    }
    if (hideMRTs) {
      ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === 'MRT6Value');
      if (ind >= 0) {
        yAxisNpsParsed.yAxis.splice(ind, 1);
      }
      ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === 'MRT3Value');
      if (ind >= 0) {
        yAxisNpsParsed.yAxis.splice(ind, 1);
      }
    } else {
      let currentDate = new Date(),
        startDate: any = null,
        endDate: any = null;
      if (!filterData.StartDate || !filterData.EndDate) {
        startDate = moment(currentDate);
        endDate = moment(currentDate);
      } else {
        startDate = moment(filterData.StartDate);
        endDate = moment(filterData.EndDate);
      }
      if (period === 'Quarter') {
        if (+endDate.diff(startDate, 'months', true) < 3) {
          let ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_3SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
          ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_6SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
          ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_12SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
        } else if (+endDate.diff(startDate, 'years', true) < 1) {
          let ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_6SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
          ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_12SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
        }
      } else if (period === 'HalfYearly') {
        if (+endDate.diff(startDate, 'months', true) < 6) {
          let ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_3SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
          ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_6SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
          ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_12SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
        } else if (+endDate.diff(startDate, 'years', true) < 1) {
          let ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_3SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
          ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_12SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
        }
      } else if (period === 'Year') {
        if (+endDate.diff(startDate, 'months', true) < 3) {
          let ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_3SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
          ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_6SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
        } else if (+endDate.diff(startDate, 'months', true) < 6) {
          let ind = yAxisNpsParsed.yAxis.findIndex(x => x.name === this.LEGEND.TREND_CHART_MRT_6SHORT);
          if (ind >= 0) {
            yAxisNpsParsed.yAxis.splice(ind, 1);
          }
        }
      }
    }
    return yAxisNpsParsed.yAxis;
  }

  public static parseNpsLineDataTrend(period: string, excludeProperties: string[], response: any,toggleData?: boolean): any[] {
    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    for (const key in response[period][0]) {
      if (!excludeProperties.includes(key)) {
        if(toggleData) {
          yAxisNpsParsed.yAxis.push({
            name: key, data: [], type: 'areaspline', placeholder: key, lineWidth: 1,yAxis:0, 
            dataLabels: {
              enabled: true,
              //allowOverlap:true,
              format:  '{y}%',
              style: {
                fontFamily: 'Source Sans Pro Regular',
                textShadow: false,
                textOutline: 'false',
                fontSize: '10px'
              }
            }
          });
        } else {
          yAxisNpsParsed.yAxis.push({
            name: key, data: [], type: 'areaspline', placeholder: key, lineWidth: 1, yAxis: 0,
            dataLabels: {
              enabled: true,
              //allowOverlap:true,
              format:  '{y}%',
              style: {
                fontFamily: 'Source Sans Pro Regular',
                textShadow: false,
                textOutline: 'false',
                fontSize: '10px'
              }
            }
          }); 
        }
        
      }
    }
    response[period].forEach(element => {
      yAxisNpsParsed.yAxis.forEach(i => {
        if (element[i.placeholder] !== undefined) {
          i.data.push(element[i.placeholder]);
        }

        switch (i.placeholder) {
          case 'CTValue':
            i['color'] = '#C3E3FF';
            i['name'] = this.LEGEND.TREND_CT;
            i['fillColor'] = {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [this.chartBottomGradient, this.HCColor.Color('#C3E3FF').setOpacity(0.7).get('rgba')],
                [this.chartTopGradient, this.HCColor.Color('#C3E3FF').setOpacity(0).get('rgba')]
              ]
            }
            break;
          case 'MRT12Value':
            i['color'] = '#A2B2BF';
            i['name'] = this.LEGEND.TREND_MRT_12FULL;
            i['fillColor'] = {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [this.chartBottomGradient, this.HCColor.Color('#A2B2BF').setOpacity(0.7).get('rgba')],
                [this.chartTopGradient, this.HCColor.Color('#A2B2BF').setOpacity(0).get('rgba')]
              ]

            }
            break;
        }
      });
    });
    return yAxisNpsParsed.yAxis;
  }
    public static parseNpsLineDataSurvey(period: string, excludeProperties: string[], response: any): any[] {
        let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

        for (const key in response[period][0]) {
            if (!excludeProperties.includes(key)) {
                yAxisNpsParsed.yAxis.push({
                    name: key, data: [], type: 'areaspline', placeholder: key, lineWidth: 1,
                    dataLabels: {
                        enabled: true,
                        //allowOverlap:true,
                        format: '{y}%',
                        style: {
                            fontFamily: 'Source Sans Pro Regular',
                            textShadow: false,
                            textOutline: 'false',
                            fontSize: '10px'
                        }
                    }
                });
            }
        }
        response[period].forEach(element => {
            yAxisNpsParsed.yAxis.forEach(i => {
                if (element[i.placeholder] !== undefined) {
                    i.data.push(element[i.placeholder]);
                }

                switch (i.placeholder) {
                    case 'CTValue':
                        i['color'] = '#C3E3FF';
                        i['name'] = this.LEGEND.CT;
                        i['fillColor'] = {
                            linearGradient: {
                                x1: 1,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [this.chartBottomGradient, this.HCColor.Color('#C3E3FF').setOpacity(0.7).get('rgba')],
                                [this.chartTopGradient, this.HCColor.Color('#C3E3FF').setOpacity(0).get('rgba')]
                            ]
                        }
                        break;
                    case 'MRT12Value':
                        i['color'] = '#A2B2BF';
                        i['name'] = this.LEGEND.TREND_MRT_12FULL;
                        i['fillColor'] = {
                            linearGradient: {
                                x1: 1,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [this.chartBottomGradient, this.HCColor.Color('#A2B2BF').setOpacity(0.7).get('rgba')],
                                [this.chartTopGradient, this.HCColor.Color('#A2B2BF').setOpacity(0).get('rgba')]
                            ]

                        }
                        break;
                }
            });
        });
        return yAxisNpsParsed.yAxis;
    }
  public static parseNpsxAxis(period: string, response: any): string[] {
    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    response[period].forEach((element, i) => {

            yAxisNpsParsed.xAxis.push('<span style="color:#03B5F0; font-size: 10px">' + response[period][i].NpsCount + '</span> - <span style="color:#333333; font-size: 10px">' + element['Period'] + '</span>');
    });

    return yAxisNpsParsed.xAxis;
  }

  public static parseNpsxAxisForTrend(period: string, response: any): string[] {
    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    response[period].forEach((element, i) => {
      let totalCount = 0
      for (const key in element) {
        if(element[key] && element[key].Count )
          totalCount += element[key].Count 
       } 
      yAxisNpsParsed.xAxis.push('<span style="color:#03B5F0; font-size: 10px">' + totalCount + '</span> - <span style="color:#333333; font-size: 10px">' + element['Period'] + '</span>');
    });

    return yAxisNpsParsed.xAxis;
  }

  public static parseCreatedVSClosedxAxis(period: string, response: any): string[] {
    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    response[period].forEach((element, i) => {
      yAxisNpsParsed.xAxis.push('<span style="color:#03B5F0; font-size: 10px">' + Number(response[period][i].Closed).toLocaleString() + '</span> <br> <span style="color:#333333; font-size: 10px">' + element['Period'] + '</span>');
    });

    return yAxisNpsParsed.xAxis;
  }

  /**
   * Added by Ashwini
   * parse NPS trnd chart for compare instances
  */
  public static parseNpsBarDataForInstances(excludeProperties: string[], response: any, addDummySpline: boolean): any[] {
    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    // Push placeholders for fields
    for (const key in response.NPSInstanceDataModels[0]) {
      if (!excludeProperties.includes(key)) {
        yAxisNpsParsed.yAxis.push({
          name: key, data: [], type: 'column', placeholder: key
        });
      }
    }
    // Add/bind data and related attributes
    response.NPSInstanceDataModels.forEach((element, i) => {
      yAxisNpsParsed.yAxis.forEach(i => {
        // Add data
        if (element[i.placeholder] !== undefined)
          i.data.push(element[i.placeholder])
        // bind related attributes
        switch (i.placeholder) {
          case 'PromoterPerc':
            i['color'] = '#43AF2A';
            i['name'] = this.LEGEND.PROMOTERS;
            break;
          case 'NeutralPerc':
            i['color'] = '#FFAF00';
            i['name'] = this.LEGEND.NEUTRAL;
            break;
          case 'DetractorPerc':
            i['color'] = '#C71026';
            i['name'] = this.LEGEND.DETRACTORS;
            break;
        }
      });
    });
    return yAxisNpsParsed.yAxis;
  }
  public static parseNpsLineDataForInstances(excludeProperties: string[], response: any): any[] {
    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    for (const key in response.NPSRollingDataModels[0]) {
      if (!excludeProperties.includes(key)) {
        yAxisNpsParsed.yAxis.push({
          name: key, data: [], type: 'areaspline', placeholder: key, lineWidth: 1,
          dataLabels: {
            enabled: true,
            //allowOverlap:true,
            format: '{y}%',
            style: {
              fontFamily: 'Source Sans Pro Regular',
              textShadow: false,
              textOutline: 'false',
              fontSize: '10px'
            }
          }
        });
      }
    }

    response.NPSRollingDataModels.forEach(element => {
      yAxisNpsParsed.yAxis.forEach(i => {
        if (element[i.placeholder] !== undefined) {
          i.data.push(element[i.placeholder]);
        }

        switch (i.placeholder) {
          case 'CTValue':
            i['color'] = '#555555';
            i['name'] = this.LEGEND.CT;
            i['fillColor'] = {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [this.chartBottomGradient, this.HCColor.Color('#555555').setOpacity(0.7).get('rgba')],
                [this.chartTopGradient, this.HCColor.Color('#555555').setOpacity(0).get('rgba')]
              ]
            }
            break;
          // case 'MRT12Value':
          //     i['color'] = '#03B5F0';
          //     i['name'] = this.LEGEND.MRT_12;
          //     i['fillColor'] = {
          //         linearGradient: {
          //             x1: 1,
          //             y1: 0,
          //             x2: 0,
          //             y2: 1
          //         },
          //         stops: [
          //             [this.chartBottomGradient, this.HCColor.Color('#03B5F0').setOpacity(0.7).get('rgba')],
          //             [this.chartTopGradient, this.HCColor.Color('#03B5F0').setOpacity(0).get('rgba')]
          //         ]

          //     }
          //     break;
        }
      });
    });
    return yAxisNpsParsed.yAxis;
  }
  public static parseNpsxAxisForInstances(period: string, response: any): string[] {
    let yAxisNpsParsed: ChartBindingsModel = new ChartBindingsModel();

    response.NPSInstanceDataModels.forEach((element, i) => {
      yAxisNpsParsed.xAxis.push('<span style="color:#03B5F0; font-size: 10px">' + response.NPSInstanceDataModels[i].NpsCount + '</span> - <span style="color:#333333; font-size: 10px">' + element['Period'] + '</span>');
    });

    return yAxisNpsParsed.xAxis;
  }

    public static parseNntBarData(period: string, response: any): any[] {
        let yAxisNntParsed: ChartBindingsModel = new ChartBindingsModel();
        let dispositionObj = response.ViewAnalysisBarPeriod && response.ViewAnalysisBarPeriod.length ? response.ViewAnalysisBarPeriod : [];
        let str = 'M';
        if (period === 'Year')
          str = 'Y';
        else if(period === 'Quarter')
          str = 'Q';
        dispositionObj.map(item => {
            if (str === item.ViewAnalysisBarPeriod) {
                let index = yAxisNntParsed.yAxis.findIndex(el => el.name === item.DispStatus);
                
                if (index > -1) {
                    if (!yAxisNntParsed.yAxis[index][item.ViewAnalysisBarPeriod]) {
                        yAxisNntParsed.yAxis[index][item.ViewAnalysisBarPeriod] = {};
                    }
                    yAxisNntParsed.yAxis[index][item.ViewAnalysisBarPeriod] = item;
                    yAxisNntParsed.yAxis[index].data.push(item ? item.Percentage : 0);
                } else {
                    let dataObj = {
                        name: item.DispStatus, data: [item ? item.Percentage : 0], type: 'column', placeholder: item.DispStatus,
                    };
                    switch (dataObj.placeholder) {
                        case 'Completed':
                            dataObj['color'] = '#3299D1';
                            dataObj['name'] = 'Completed';
                            break;
                        case 'No Response':
                            dataObj['color'] = '#999999';
                            dataObj['name'] = 'No Response';
                            break;
                        case 'Bounce Back':
                            dataObj['color'] = '#717171';
                            dataObj['name'] = 'Bounce Back';
                            break;
                        case 'Reply to Invitation':
                            dataObj['color'] = '#337195';
                            dataObj['name'] = 'Reply to Invitation';
                            break;
                    }
                    dataObj[item.ViewAnalysisBarPeriod] = item;
                    yAxisNntParsed.yAxis.push(dataObj);
                }
            }
        });
        // Add data to stack for bar chart
        // response.Disposition.forEach((element, i) => {
            /* yAxisRrtParsed.yAxis.forEach(item => {
                let x = response.Disposition.find(i => i.DispStatus === item.name);
                if (!item[x.ViewAnalysisBarPeriod]) {
                    item[x.ViewAnalysisBarPeriod] = {};
                }
                item.data.push(x ? x.Percentage : 0);
                switch (item.placeholder) {
                    case 'Completed':
                        item['color'] = '#3299D1';
                        item['name'] = 'Completed';
                        break;
                    case 'No Response':
                        item['color'] = '#999999';
                        item['name'] = 'No Response';
                        break;
                    case 'Bounce Back':
                        item['color'] = '#717171';
                        item['name'] = 'Bounce Back';
                        break;
                    case 'PartiallyCompleted':
                        item['color'] = '';
                        item['name'] = 'Partially Completed';
                        break;
                    case 'Reply to Invitation':
                        item['color'] = '#337195';
                        item['name'] = 'Reply to Invitation';
                        break;
                }
            }); */
        return yAxisNntParsed.yAxis;
    }
  public static parseNntLineData(period: string, excludeProperties: string[], response: any): any[] {
    let yAxisNntParsed: ChartBindingsModel = new ChartBindingsModel();

    for (const key in response[period][0])
      if (!excludeProperties.includes(key))
        yAxisNntParsed.yAxis.push({
          name: key, data: [], type: 'areaspline', placeholder: key, lineWidth: 1,
          dataLabels: {
            enabled: true,
            //allowOverlap:true,
            format: '{y}',
            style: {
              fontFamily: 'Source Sans Pro Regular',
              textShadow: false,
              textOutline: 'false',
              fontSize: '10px'
            }
          }
        });

    response[period].forEach(element => {
      yAxisNntParsed.yAxis.forEach(i => {
        if (element[i.placeholder] !== undefined) {
          i.data.push(element[i.placeholder]);
        }

        switch (i.placeholder) {
          case 'CTValue':
            i['color'] = '#777777';
            i['name'] = this.LEGEND_DUAL_AXIS.CT;
            i['fillColor'] = {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [this.chartBottomGradient, this.HCColor.Color('#777777').setOpacity(0.7).get('rgba')],
                [this.chartTopGradient, this.HCColor.Color('#777777').setOpacity(0).get('rgba')]
              ]
            }
            break;
          case 'MRT12Value':
            i['color'] = '#03b5f0';
            i['name'] = this.LEGEND.MRT_12;
            i['fillColor'] = {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [this.chartBottomGradient, this.HCColor.Color('#03b5f0').setOpacity(0.7).get('rgba')],
                [this.chartTopGradient, this.HCColor.Color('#03b5f0').setOpacity(0).get('rgba')]
              ]
            }
            break;
          default:
            i['color'] = '';
            i['name'] = i['name'];
            break;
        }
      });
    });

    return yAxisNntParsed.yAxis;
  }
    public static parseNntxAxis(period: string, response: any): string[] {
        let yAxisNntParsed: ChartBindingsModel = new ChartBindingsModel();
        let periodStr = period === 'Month' ? 'M' : period === 'Year'? 'Y': 'Q';
        let DispCollection = response.filter(x => x.ViewAnalysisType.includes(periodStr));
        if(periodStr === 'Q') {
          yAxisNntParsed.xAxis = DispCollection.map(item => '<span style="color:#333333; font-size: 10px">' + item.ViewAnalysisType+ '-' + item.ViewAnalysisBarPeriod + '</span>');
        } else
          yAxisNntParsed.xAxis = DispCollection.map(item => '<span style="color:#333333; font-size: 10px">' + item.ViewAnalysisBarPeriod + '</span>');

        return yAxisNntParsed.xAxis;
    }

    public static parseORxAxis(response: any): string[] {
      let yAxisNntParsed: ChartBindingsModel = new ChartBindingsModel();
      // let periodStr = period === 'Month' ? 'M' : period === 'Year'? 'Y': 'Q';
      let DispCollection = response;
      // if(periodStr === 'Q') {
        // yAxisNntParsed.xAxis = DispCollection.map(item => '<span style="color:#333333; font-size: 10px">' + item.ViewAnalysisType+ '-' + item.ViewAnalysisBarPeriod + '</span>');
      // } else
        // yAxisNntParsed.xAxis = DispCollection.map(item => '<span style="color:#333333; font-size: 10px">' + 'Q'+ item.QuarterNumber + '-'+ item.MonthName + '-' +item.YearPart+ '</span>');
        yAxisNntParsed.xAxis = DispCollection.map(item => '<span style="color:#333333; font-size: 10px">' +  item.MonthName+ '</span>');
      return yAxisNntParsed.xAxis;
  }
    public static parseNNTTrendChartTooltipData(dataObj: any = null) {
        let resObj: any = {};
        resObj.clickedCount = dataObj ? dataObj.ClickedCount : 0;
        resObj.deliveredCount = dataObj ? dataObj.DeliveredCount : 0;
        resObj.openedCount = dataObj ? dataObj.OpenedCount : 0;
        resObj.processedCount = dataObj ? dataObj.ProcessedCount : 0;
        resObj.currentDaysCount = dataObj ? dataObj.CurrentDaysCount : 0;
        resObj.previousDaysCount = dataObj ? dataObj.PreviousDaysCount : 0;
        resObj.actualBounceBackCount = dataObj ? dataObj.ActualBounceBackCount : 0;
        resObj.correctedBounceBackCount = dataObj ? dataObj.CorrectedBounceBackCount : 0;
        resObj.completedCount = dataObj ? dataObj.CompletedCount : 0;
        resObj.completedCBBCount = dataObj ? dataObj.CompletedCBBCount : 0;
        resObj.emailActivity = dataObj && dataObj.EmailActivity && dataObj.EmailActivity.length ? dataObj.EmailActivity : [];
        resObj.stillInBounceBackCount = Math.round(resObj.actualBounceBackCount + resObj.correctedBounceBackCount);
        resObj.noResposeCntCbb = dataObj ? dataObj.NoResposeCntCbb : 0;
        resObj.noResposeCnt = dataObj ? dataObj.NoResposeCnt : 0;
        resObj.RTICount = dataObj ? dataObj.RTICount : 0;
        resObj.RTICBBCount = dataObj ? dataObj.RTICBBCount : 0;

        resObj.CurrentDaysCount = dataObj ? dataObj.CurrentDaysCount : 0;
        resObj.PreviousDaysCount = dataObj ? dataObj.PreviousDaysCount : 0;
        resObj.CurrentCompletedCount_CBB = dataObj ? dataObj.CurrentCompletedCount_CBB : 0;
        resObj.PreviousCompletedCount_CBB = dataObj ? dataObj.PreviousCompletedCount_CBB : 0;
        resObj.suveySentBounceBackCount = dataObj && dataObj.SurveyInvitationSentCnt ? dataObj.SurveyInvitationSentCnt : 0;
        return resObj;
    }

    public static parseRrtBarData(period: string, response: any): any[] {
      let yAxisRrtParsed: ChartBindingsModel = new ChartBindingsModel();
      let dispositionObj = response.Disposition && response.Disposition.length ? response.Disposition : [];
      let str = 'M';
      if (period === 'Year')
          str = 'Y';
      dispositionObj.map(item => {
          if (str === item.ViewAnalysisType) {
              let index = yAxisRrtParsed.yAxis.findIndex(el => el.name === item.DispStatus);
              
              if (index > -1) {
                  if (!yAxisRrtParsed.yAxis[index][item.ViewAnalysisBarPeriod]) {
                      yAxisRrtParsed.yAxis[index][item.ViewAnalysisBarPeriod] = {};
                  }
                  yAxisRrtParsed.yAxis[index][item.ViewAnalysisBarPeriod] = item;
                  yAxisRrtParsed.yAxis[index].data.push(item ? item.Percentage : 0);
              } else {
                  let dataObj = {
                      name: item.DispStatus, data: [item ? item.Percentage : 0], type: 'column', placeholder: item.DispStatus,
                  };
                  switch (dataObj.placeholder) {
                      case 'Completed':
                          dataObj['color'] = '#3299D1';
                          dataObj['name'] = 'Completed';
                          break;
                      case 'No Response':
                          dataObj['color'] = '#999999';
                          dataObj['name'] = 'No Response';
                          break;
                      case 'Bounce Back':
                          dataObj['color'] = '#717171';
                          dataObj['name'] = 'Bounce Back';
                          break;
                      case 'Reply to Invitation':
                          dataObj['color'] = '#337195';
                          dataObj['name'] = 'Reply to Invitation';
                          break;
                  }
                  dataObj[item.ViewAnalysisBarPeriod] = item;
                  yAxisRrtParsed.yAxis.push(dataObj);
              }
          }
      });
      // Add data to stack for bar chart
      // response.Disposition.forEach((element, i) => {
          /* yAxisRrtParsed.yAxis.forEach(item => {
              let x = response.Disposition.find(i => i.DispStatus === item.name);
              if (!item[x.ViewAnalysisBarPeriod]) {
                  item[x.ViewAnalysisBarPeriod] = {};
              }
              item.data.push(x ? x.Percentage : 0);
              switch (item.placeholder) {
                  case 'Completed':
                      item['color'] = '#3299D1';
                      item['name'] = 'Completed';
                      break;
                  case 'No Response':
                      item['color'] = '#999999';
                      item['name'] = 'No Response';
                      break;
                  case 'Bounce Back':
                      item['color'] = '#717171';
                      item['name'] = 'Bounce Back';
                      break;
                  case 'PartiallyCompleted':
                      item['color'] = '';
                      item['name'] = 'Partially Completed';
                      break;
                  case 'Reply to Invitation':
                      item['color'] = '#337195';
                      item['name'] = 'Reply to Invitation';
                      break;
              }
          }); */
      return yAxisRrtParsed.yAxis;
  }
public static parseRrtLineData(period: string, excludeProperties: string[], response: any): any[] {
  let yAxisRrtParsed: ChartBindingsModel = new ChartBindingsModel();

  for (const key in response[period][0])
    if (!excludeProperties.includes(key))
      yAxisRrtParsed.yAxis.push({
        name: key, data: [], type: 'areaspline', placeholder: key, lineWidth: 1,
        dataLabels: {
          enabled: true,
          //allowOverlap:true,
          format: '{y}%',
          style: {
            fontFamily: 'Source Sans Pro Regular',
            textShadow: false,
            textOutline: 'false',
            fontSize: '10px'
          }
        }
      });

  response[period].forEach(element => {
    yAxisRrtParsed.yAxis.forEach(i => {
      if (element[i.placeholder] !== undefined) {
        i.data.push(element[i.placeholder]);
      }

      switch (i.placeholder) {
        case 'CTValue':
          i['color'] = '#777777';
          i['name'] = this.LEGEND.CT;
          i['fillColor'] = {
            linearGradient: {
              x1: 1,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [this.chartBottomGradient, this.HCColor.Color('#777777').setOpacity(0.7).get('rgba')],
              [this.chartTopGradient, this.HCColor.Color('#777777').setOpacity(0).get('rgba')]
            ]
          }
          break;
        case 'MRT12Value':
          i['color'] = '#03b5f0';
          i['name'] = this.LEGEND.MRT_12;
          i['fillColor'] = {
            linearGradient: {
              x1: 1,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [this.chartBottomGradient, this.HCColor.Color('#03b5f0').setOpacity(0.7).get('rgba')],
              [this.chartTopGradient, this.HCColor.Color('#03b5f0').setOpacity(0).get('rgba')]
            ]
          }
          break;
        default:
          i['color'] = '';
          i['name'] = i['name'];
          break;
      }
    });
  });

  return yAxisRrtParsed.yAxis;
}
  public static parseRrtxAxis(period: string, response: any): string[] {
      let yAxisRrtParsed: ChartBindingsModel = new ChartBindingsModel();
      let periodStr = period === 'Month' ? 'M' : 'Y';
      let DispCollection = response.Disposition.filter(x => x.DispStatus === response.Disposition[0].DispStatus && x.ViewAnalysisType === periodStr);
      yAxisRrtParsed.xAxis = DispCollection.map(item => '<span style="color:#03B5F0; font-size: 10px">' + item.SurveyInvitationSentCnt + '</span> - <span style="color:#333333; font-size: 10px">' + item.ViewAnalysisBarPeriod + '</span>');

      return yAxisRrtParsed.xAxis;
  }
  public static parseRRTrendChartTooltipData(dataObj: any = null) {
      let resObj: any = {};
      resObj.clickedCount = dataObj ? dataObj.ClickedCount : 0;
      resObj.deliveredCount = dataObj ? dataObj.DeliveredCount : 0;
      resObj.openedCount = dataObj ? dataObj.OpenedCount : 0;
      resObj.processedCount = dataObj ? dataObj.ProcessedCount : 0;
      resObj.currentDaysCount = dataObj ? dataObj.CurrentDaysCount : 0;
      resObj.previousDaysCount = dataObj ? dataObj.PreviousDaysCount : 0;
      resObj.actualBounceBackCount = dataObj ? dataObj.ActualBounceBackCount : 0;
      resObj.correctedBounceBackCount = dataObj ? dataObj.CorrectedBounceBackCount : 0;
      resObj.completedCount = dataObj ? dataObj.CompletedCount : 0;
      resObj.completedCBBCount = dataObj ? dataObj.CompletedCBBCount : 0;
      resObj.emailActivity = dataObj && dataObj.EmailActivity && dataObj.EmailActivity.length ? dataObj.EmailActivity : [];
      resObj.stillInBounceBackCount = Math.round(resObj.actualBounceBackCount + resObj.correctedBounceBackCount);
      resObj.noResposeCntCbb = dataObj ? dataObj.NoResposeCntCbb : 0;
      resObj.noResposeCnt = dataObj ? dataObj.NoResposeCnt : 0;
      resObj.RTICount = dataObj ? dataObj.RTICount : 0;
      resObj.RTICBBCount = dataObj ? dataObj.RTICBBCount : 0;

      resObj.CurrentDaysCount = dataObj ? dataObj.CurrentDaysCount : 0;
      resObj.PreviousDaysCount = dataObj ? dataObj.PreviousDaysCount : 0;
      resObj.CurrentCompletedCount_CBB = dataObj ? dataObj.CurrentCompletedCount_CBB : 0;
      resObj.PreviousCompletedCount_CBB = dataObj ? dataObj.PreviousCompletedCount_CBB : 0;
      resObj.suveySentBounceBackCount = dataObj && dataObj.SurveyInvitationSentCnt ? dataObj.SurveyInvitationSentCnt : 0;
      return resObj;
  }
  public static parseSsrBarData(period: string, excludeProperties: string[], response: any): any[] {
    let yAxisSsrParsed: ChartBindingsModel = new ChartBindingsModel();
    let grpCount: number = 0, relevantProps: string[] = [];

    // add column data
    for (const key in response[period][0]) {
      if (!excludeProperties.includes(key)) {
        yAxisSsrParsed.yAxis.push({ name: key, data: [], type: 'column', placeholder: key, stack: grpCount, yAxis: 1 });
        relevantProps.push(key);
        grpCount += 1;
      }
    }

    relevantProps.forEach(valueOf => {
      response[period].forEach((element, index) => {
        let bufArr = [];

        bufArr.push(element['Period']);
        bufArr.push(element[valueOf]);

        yAxisSsrParsed.yAxis[yAxisSsrParsed.yAxis.findIndex(itm => itm.placeholder === valueOf)].data.push(JSON.parse(JSON.stringify(bufArr)));
        // add x axis data
        yAxisSsrParsed.xAxis.push('<span style="color:#03B5F0; font-size: 10px">' + response[period][index].ResponseCount + '</span> - <span style="color:#333333; font-size: 10px">' + element['Period'] + '</span>');
      });
    });

    yAxisSsrParsed.yAxis.forEach(i => {
      switch (i.placeholder) {
        case 'SurveySentCount':
          i['color'] = '#8D529D';
          i['name'] = 'Surveys Sent';
          break;
        case 'ResponseCount':
          i['color'] = '#5CB1DE';
          i['name'] = 'Responses Received';
          break;
      }
    });

    return yAxisSsrParsed.yAxis;
  }
  public static parseSsrLineData(period: string, excludeProperties: string[], response: any): any[] {
    let yAxisSsrParsed: ChartBindingsModel = new ChartBindingsModel();

    for (const key in response[period][0]) {
      if (!excludeProperties.includes(key)) {
        yAxisSsrParsed.yAxis.push({
          name: key, data: [], type: 'areaspline', placeholder: key, yAxis: 0, lineWidth: 1,
          dataLabels: {
            enabled: true,
            format: '{y}%',
            style: {
              fontFamily: 'Source Sans Pro Regular',
              textShadow: false,
              textOutline: 'false',
              fontSize: '10px'
            }
          }
        });
      }
    }
    response[period].forEach(element => {
      yAxisSsrParsed.yAxis.forEach(i => {
        if (element[i.placeholder] !== undefined) {
          i.data.push(element[i.placeholder]);
        }

        switch (i.placeholder) {
          case 'NetPromoterPerc':
            i['color'] = '#228A39';
            i['name'] = 'Net Promoter Score';
            i['fillColor'] = {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [this.chartBottomGradient, this.HCColor.Color('#228A39').setOpacity(0.7).get('rgba')],
                [this.chartTopGradient, this.HCColor.Color('#228A39').setOpacity(0).get('rgba')]
              ]
            }
            break;
        }
      });
    });

    return yAxisSsrParsed.yAxis;

  }
  public static parseSsrxAxis(period: string, response: any): string[] {
    let yAxisSsrParsed: ChartBindingsModel = new ChartBindingsModel();

    response[period].forEach((item, index) => {
      // add x axis data
      // tempSsrChartData.xAxis.push('<span style="color:#56CDF5">' + this.ssrData[period][index].ResponseCount + '</span><br/><b>' + element['Period'] + '</b>');
      yAxisSsrParsed.xAxis.push('<span style="color:#03B5F0; font-size: 10px">' + item.ResponseCount + '</span> - <span style="color:#333333; font-size: 10px">' + item['Period'] + '</span>');
    });

    return yAxisSsrParsed.xAxis;
  }

  public static parsePndBarData(period: string, excludeProperties: string[], response: any): any[] {
    let yAxisSsrParsed: ChartBindingsModel = new ChartBindingsModel();

    for (const key in response[period][0])
      if (!excludeProperties.includes(key))
        yAxisSsrParsed.yAxis.push({
          name: key, data: [], type: 'column', placeholder: key
        });

    response[period].forEach((element, i) => {
      yAxisSsrParsed.yAxis.forEach(i => {
        // if (element[i.placeholder])
        i.data.push(element[i.placeholder])
      });
    });

    yAxisSsrParsed.yAxis.forEach(i => {
      switch (i.placeholder) {
        case 'PromoterPerc':
          i['color'] = '#43AF2A';
          i['name'] = this.LEGEND.PROMOTERS;
          break;
        case 'NeutralPerc':
          i['color'] = '#FFAF00';
          i['name'] = this.LEGEND.NEUTRAL;
          break;
        case 'DetractorPerc':
          i['color'] = '#C71026';
          i['name'] = this.LEGEND.DETRACTORS;
          break;
      }
    });
    return yAxisSsrParsed.yAxis;
  }
  public static parsePndLineData(period: string, excludeProperties: string[], response: any): any[] {
    let yAxisSsrParsed: ChartBindingsModel = new ChartBindingsModel();

    for (const key in response[period][0]) {
      if (!excludeProperties.includes(key)) {
        yAxisSsrParsed.yAxis.push({
          name: key, data: [], type: 'areaspline', placeholder: key, yAxis: 0, lineWidth: 1,
          dataLabels: {
            enabled: true,
            format: '{y}%',
            style: {
              fontFamily: 'Source Sans Pro Regular',
              textShadow: false,
              textOutline: 'false',
              fontSize: '10px'
            }
          }
        });
      }
    }
    response[period].forEach(element => {
      yAxisSsrParsed.yAxis.forEach(i => {
        if (element[i.placeholder] !== undefined) {
          i.data.push(element[i.placeholder]);
        }

        switch (i.placeholder) {
          case 'CTValue':
            i['color'] = '#555555';
            i['name'] = this.LEGEND.CT;
            i['fillColor'] = {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [this.chartBottomGradient, this.HCColor.Color('#555555').setOpacity(0.7).get('rgba')],
                [this.chartTopGradient, this.HCColor.Color('#555555').setOpacity(0).get('rgba')]
              ]
            }
            break;
          case 'MRT12Value':
            i['color'] = '#03B5F0';
            i['name'] = this.LEGEND.MRT_12;
            i['fillColor'] = {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [this.chartBottomGradient, this.HCColor.Color('#03B5F0').setOpacity(0.7).get('rgba')],
                [this.chartTopGradient, this.HCColor.Color('#03B5F0').setOpacity(0).get('rgba')]
              ]
            }
            break;
        }
      });
    });

    return yAxisSsrParsed.yAxis;
  }
  public static parsePndxAxis(period: string, response: any): string[] {
    let yAxisSsrParsed: ChartBindingsModel = new ChartBindingsModel();

    response[period].forEach((item, index) => {
      // yAxisSsrParsed.xAxis.push(item['EntityName']);
      yAxisSsrParsed.xAxis.push('<span style="color:#03B5F0; font-size: 10px">' + item['NpsCnt'] + '</span> - <span style="color:#333333; font-size: 10px">' + item['EntityName'] + '</span>');
    });
    return yAxisSsrParsed.xAxis;
  }


  /**
   * TODO: Remove if not used
   * @param chartIdentifier
   * @param chartData
   */
  public static saveParsedDataToSessionStorage(chartIdentifier: string = '', chartData: any) {
    let data = sessionStorage.getItem(chartIdentifier);
    if (data && data.length > 0)
      sessionStorage.removeItem(chartIdentifier);
    sessionStorage.setItem(chartIdentifier, JSON.stringify(chartData));
  }

  /**
 * Get default widgetOrderReceived order & visibility
 */
  public static getDefaultWidgetPos() {
    return [
      {
        "WidgetName": "Net Promoter Score Trend",
        "Position": "D1",
        "IsVisible": true,
        "WidgetCode": "NPST",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashBoardUrlImage,
        "IsDefaultView": false
      } ,
      {
        "WidgetName": "Survey Sent vs. Response Recieved",
        "Position": "D3",
        "IsVisible": true,
        "WidgetCode": "SSRR",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashBoardUrlImage,
        "IsDefaultView": false
      },
      {
        "WidgetName": "Country Comparision",
        "Position": "D4",
        "IsVisible": true,
        "WidgetCode": "CC",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashBoardUrlImage,
        "IsDefaultView": false
      },
      {
        "WidgetName": "Loyalty Shift",
        "Position": "D5",
        "IsVisible": true,
        "WidgetCode": "LSDD",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashBoardUrlImage,
        "IsDefaultView": false
      },
      {
        "WidgetName": "Response Rate Trend",
        "Position": "D2",
        "IsVisible": true,
        "WidgetCode": "RRT",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashBoardUrlImage,
        "IsDefaultView": false
      },
      {
        "WidgetName": "Revenue wise Sentiment Comparison",
        "Position": "D11",
        "IsVisible": true,
        "WidgetCode": "RSC",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashBoardUrlImage,
        "IsDefaultView": false
    },
    {
      "WidgetName": "Net Sentiment Score",
      "Position": "D8",
      "IsVisible": true,
      "WidgetCode": "NSS",
      "Category": "D",
      "TouchPoint": "",
      "IsSaved": false,
      "WidgetImage": this.dashBoardUrlImage,
      "IsDefaultView": false
  },
  {
    "WidgetName": "NPS & Net Sentiment Score Trend",
    "Position": "D9",
    "IsVisible": true,
    "WidgetCode": "NNT",
    "Category": "D",
    "TouchPoint": "",
    "IsSaved": false,
    "WidgetImage": this.dashBoardUrlImage,
    "IsDefaultView": false
  },
  {
    "WidgetName": "Sentiment Categories",
    "Position": "D10",
    "IsVisible": true,
    "WidgetCode": "SC",
    "Category": "D",
    "TouchPoint": "",
    "IsSaved": false,
    "WidgetImage": this.dashBoardUrlImage,
    "IsDefaultView": false
  },
  {
    "WidgetName": "Word Cloud",
    "Position": "D12",
    "IsVisible": true,
    "WidgetCode": "SWC",
    "Category": "D",
    "TouchPoint": "",
    "IsSaved": false,
    "WidgetImage": this.dashBoardUrlImage,
    "IsDefaultView": false
  }
    ]
  }
  public static getDefaultChartOptions(isDualyAxes: boolean, xAxisData: any | null, yAxisData: any | null) {
    let options: Highcharts.Options = {
      chart: {
        marginBottom: 90,
        panning: true,
      },
      mapNavigation: {
        enabled: true,
        enableDoubleClickZoom: true,
        buttonOptions: {
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver'
          },
          verticalAlign: 'top',
          align: "right",
          alignTo: "spacingBox",
          style: {
            fontSize: "10px"
          },
          width: 10,
          height: 10,
          padding: 7
        }
      },
      title: {
        text: null
        , verticalAlign: 'top',
        align: 'left',
        style: {
          fontFamily: 'Source Sans Pro Regular',
          fontSize: '1.3em',
          fontWeight: 'bold',
          color: '#333333'
        }
      },
      xAxis: {
        categories: JSON.parse(JSON.stringify(xAxisData)),
        labels: {
          style: {
            fontFamily: 'Source Sans Pro Regular',
            fontSize: '10px',
            color: '#333333',
            fontWeight: 'bold'
          }
        }
      },
      yAxis: isDualyAxes ? this.dualyAxis : this.regularyAxis,
      legend: {
        borderWidth: 0,
        align: 'right',
        verticalAlign: 'top',
        y: -8,
        x: -40,
        itemStyle: {
          fontFamily: 'Source Sans Pro Regular',
          fontSize: '10px',
          fontWeight: 'normal',
          color: '#666666'
        },
        symbolHeight: 10
      },
      credits: {
      enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
            borderRadius: 0,
            color: '#333333',
            borderWidth: 0,
            style: {
              fontFamily: 'Source Sans Pro Regular',
              textShadow: false,
              textOutline: 'false',
              fontSize: '10px'
            }
          },
          point: {
          },
          maxPointWidth: 35
        },

        series: {
          dataLabels: {
          },
          events: {
          }
        }
      },
      series: JSON.parse(JSON.stringify(yAxisData))
    }
    return options;
  }

  /**
   * Sorting the received data in ascending as per date; returns sorted data
   * @param data data to run sorting over
   * @param sortByProp property name to sun sorting over
   */
  public static getSortedInput(data: any[], sortByProp: string): any[] {
    data.sort(function (a, b) {
      let keyA = a[sortByProp],
        keyB = b[sortByProp];
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return data;
  }

}
