import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import { environment } from 'src/environments/environment';


HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnChanges {
  constructor(private el: ElementRef) { }

  @Input() data: any;
  @Input() chartTitle: string = '';
  @Input() isDualyAxes?: boolean = false;
  @Input() isDrilldownEnabled?: boolean = false;
  @Input() chartIdentifier: string = '';
  @Input()
  public chartState: CHART_STATE_OPTIONS;

  isLeftContextMenu: boolean = false;
  chartInstance: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  oneToOneFlag: boolean = true;
  private imagepath = environment.imagepath;

  ngOnChanges(changes: SimpleChanges) {
    //if (this.chartState === 'success') {

      this.configureChartOptions();
    // }
  }

  configureChartOptions() {
    const average = this.data ? parseFloat(this.data.NSSSCore): 0;
    let sentimentLabel = '';
    if (average >= -5 && average < 0) {
      sentimentLabel = 'NEGATIVE';
    } else if (average >= 0 && average <= 3) {
      sentimentLabel = 'NEUTRAL';
    } else if (average > 3 && average <= 5) {
      sentimentLabel = 'POSITIVE';
    }
    this.chartOptions = {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '80%',
        backgroundColor: "#F1F1F1",
       
      },
      title: {
        text: ''
      },
      tooltip: {
        enabled: false
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
      },
      yAxis: {
        startOnTick: false,
        endOnTick: false,
        min: -5,
        max: 5,
        tickPositions: [-5, 5],
        tickPixelInterval: 72,
        tickPosition: 'outside',
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
        tickLength: 20,
        tickWidth: 0,
        minorTickInterval: null,
        labels: {
          formatter: function () {
            if (this.value === average) {
              return average.toString();
            } else {
              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          distance: 20,
          style: {
            fontSize: '25px',
            // fontWeight:'bold'
            color: '#000000'
          }
        },
        lineWidth: 0,
        plotBands: [
          {
            from: -5,
            to: 5,
            thickness: 30,
            color: {
              linearGradient: {
                x1: 0,
                x2: 1,
                y1: 0,
                y2: 0
              },
              stops: [
                [0, '#C71026'],
                [0.5, '#FFAF00'],
                [1, '#43AF2A']
              ]
            }
          }
        ],
        plotLines: [
          {
            width:0,
            value:average,
            label: {
              text: (average === 5 || average === -5) ? '' :average.toString(),
              textAlign: 'center',
              style: {
                fontSize: '30px',
                textAnchor: 'middle',
                color: '#000000'
              },
              y: (average >= 0 && average < 1) ? -20 :(average >= 1 && average < 1.4) ? -20 : (average >= 1.4 && average < 2) ? -30 : (average >= 2 && average <= 3) ? -10 : (average > 3 && average <= 4) ? -5 : (average > 4 && average <= 4.9) ? -5 : average < 0 ? -10 : 0,
              x: (average === 0 || average === 0.1) ? 5 : (average > 0.1 && average < 0.7) ? 35 : (average >= 0.7 && average < 1) ? 60 : (average >= 1 && average < 1.4) ? 95 :(average >= 1.4 && average < 2) ? 130 : (average >= 2 && average <2.4) ? 180 : (average >= 2.4 && average <= 3) ? 220 : (average > 3 && average <= 4) ? 260 : (average > 4 && average <= 4.9) ? 270 : average < 0 ? -25 : 50,
            }
          }
        ]
      },
      series: [
        {
          name: 'Sentiment',
          type: 'gauge',
          data: [average],
          dataLabels: {
            formatter: function () {
              return sentimentLabel;
            },
            enabled: true,
            borderWidth: 0,
            color:
              (Highcharts.defaultOptions.title &&
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              '#000000',
            style: {
              fontSize: '28px',
              color: '#ffffff'
              
            }
          },
          dial: {
            radius: '75%',
            backgroundColor: '#054e5a',
            baseWidth: 10,
            baseLength: '0%',
            rearLength: '0%'
          },
          pivot: {
            backgroundColor: '#054e5a',
            radius: 4.5
          },
        }
      ]
    };
  }

  onChartInstance(chart: Highcharts.Chart) {
    this.chartInstance = chart;
  }
}
