import { widgetCodes } from './../../../shared/constants/shared.constants';
import { RelationshipDashboardSharedEventService } from './../../../shared/services/relationship-dashboard-shared-event.service';
import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import { SharedService } from "src/app/shared/services/shared.service";
import { CHART_STATE_OPTIONS } from "src/app/shared/enums/chart-state-options.enum";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import * as _html2canvas from "html2canvas";
const html2canvas:any = _html2canvas;
import { ExportChartModel } from "src/app/shared/models/export-chart.model";
import { surveyType } from "src/app/shared/constants/shared.constants";
import { ChartDataService } from '../service/chart-data-service.service';
import { environment } from 'src/environments/environment';
const WIDGET_CODE: string = 'rr';

@Component({
  selector: "app-response-rate",
  templateUrl: "./response-rate.component.html",
  styleUrls: ["./response-rate.component.scss"]
})
export class ResponseRateComponent implements OnInit {
  private imagepath = environment.imagepath;
  @Input() data: any;
  @Input() options: any;
  @Input() responseRate: number;
  @Input() optOutCount: number;
  @Input('boolResponseRateLoader') boolResponseRateLoader;
  @Input() responseError: boolean;
  @Input() CompletedCountData: any;
  @Input() NoResponseCountData: any;

  @Input() surveyTypeInput: string = surveyType.Transactional
  widgetCodesConst:any;

  // public height = "500";
  // public width = "400";
  // public margin = 100;

  public height = "300";
  public width = "250";
  public margin = 0;

  public middleText: string;
  public middleText2 = "%";
  public middleText3 = "Response Rate";
  public IsNoDataAvailable = false;

  private chartState: CHART_STATE_OPTIONS;
  private exportSubscription: Subscription;
  @ViewChild('pictureOf') pictureOf: ElementRef;
  widgetState: CHART_STATE_OPTIONS;

  public chartData: any;
  public chartColors: string[] = [];
  constructor(private sharedService:SharedService,private rdSharedEventService:RelationshipDashboardSharedEventService,
    private chartDataService: ChartDataService) {
    this.widgetCodesConst = widgetCodes;
    this.widgetState = CHART_STATE_OPTIONS.LOADING;

    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(update => update !== null && update.includes(WIDGET_CODE.toLowerCase()))).subscribe(val => {
      if (this.sharedService.acceptableChartState.includes(this.widgetState))
        // setTimeout(() => {
          this.toCanvas();
        // }, 500);
    });
  }

  /**
   *@description below for loop code need to uncomment for data assign to grid
   * @memberof ResponseRateComponent
   */
  ngOnInit() {
    // if data null or undefied
    if (!this.data) {
     return;
    }
    let labels = [],
      chartData = {},
      backgroundColor = [];
    this.middleText = this.data.responseRate;
    for (let index = 0; index < this.data.labels.length; index++) {
      const element = this.data.labels[index];
      labels.push(element);
    }
    let dataset = this.data["datasets"];
    for (let index = 0; index < dataset.length; index++) {
      const element = dataset[index];
      for (let index = 0; index < element.data.length; index++) {
        // to get label value
        chartData[labels[index]] = element.data[index];
        // to get background color
        backgroundColor.push(element.backgroundColor[index]);
      }
    }

    this.chartData = chartData;
    this.chartColors = backgroundColor;
  }
  ngOnChanges(){
    // if data null or undefied
    this.IsNoDataAvailable = false;
    if (!this.data) {
      this.IsNoDataAvailable = false;
      return;
    } 
    if (this.data.responseRate == null) {
      this.IsNoDataAvailable = true;
      this.responseError = false;
      return;
    }
    let labels = [],
      chartData = {},
      backgroundColor = [];
    this.middleText = this.data.responseRate == null ? 0 : this.data.responseRate;
    for (let index = 0; index < this.data.labels.length; index++) {
      const element = this.data.labels[index];
      labels.push(element);
    }
    let dataset = this.data["datasets"];
    for (let index = 0; index < dataset.length; index++) {
      const element = dataset[index];
      for (let index = 0; index < element.data.length; index++) {
        // to get label value
        chartData[labels[index]] = element.data[index];
        // to get background color
        backgroundColor.push(element.backgroundColor[index]);
      }
    }

    this.chartData = chartData;
    this.chartColors = backgroundColor;
    this.updateExportDetails();
  }
  ngOnDestroy() {
    this.exportSubscription.unsubscribe();
    this.updateExportDetails(true);
  }

  closeWidget() {
    if (this.surveyTypeInput != null && (this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput == 'OEM')) {
      this.rdSharedEventService.closeWidget(this.widgetCodesConst.ResponseRate)
    } else {
      this.sharedService.widgetCloseSubject.next(this.widgetCodesConst.ResponseRate);
    }
  }

  private toCanvas = () => {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    try {
      if (this.pictureOf && this.pictureOf.nativeElement) {
        html2canvas(this.pictureOf.nativeElement, { scrollX:0, scrollY:-window.scrollY, allowTaint: false, useCORS: false, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
          chartUpdate.UpdateTime = new Date();
          chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
          chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
          chartUpdate.CurrentState = this.widgetState;
          chartUpdate.ChartImgBase64 = canvas.toDataURL('image/png');
          this.sharedService.updateChartStatusSubject(chartUpdate);
        },
          err => {
            console.log('error occurred during image export, details as below \n', err);
            chartUpdate = this.exportErrorUpdate();
            this.sharedService.updateChartStatusSubject(chartUpdate);
          });
      } else if(this.widgetState === CHART_STATE_OPTIONS.NO_DATA) {
          chartUpdate.UpdateTime = new Date();
          chartUpdate.ChartName = this.widgetCodesConst.toLowerCase();
          chartUpdate.ChartImgId = this.widgetCodesConst.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
          chartUpdate.CurrentState = this.widgetState;
          chartUpdate.ChartImgBase64 = 'no_image_data_available_for_this_state';
          chartUpdate.HasNoData = true;
          this.sharedService.updateChartStatusSubject(chartUpdate);
      }
    } catch (error) {
      console.log('html2canvas error as', error);
      chartUpdate = this.exportErrorUpdate();
      this.sharedService.updateChartStatusSubject(chartUpdate);
    }
  }
  private updateExportDetails = (isDestroy: boolean = false) => {
    this.determineWidgetState();
    //this.widgetState = isDestroy ? CHART_STATE_OPTIONS.UNKNOWN : CHART_STATE_OPTIONS.SUCCESS;
    if(isDestroy) {
      this.widgetState = CHART_STATE_OPTIONS.UNKNOWN;
    }

    let chartUpdate: ExportChartModel = new ExportChartModel();
    chartUpdate.UpdateTime = new Date();
    chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
    chartUpdate.CurrentState = this.widgetState;
    this.sharedService.updateChartStatusSubject(chartUpdate);
  }
  exportErrorUpdate = (): ExportChartModel => {
    let err: ExportChartModel = new ExportChartModel();
    err.HasErrorInExport = true;
    err.UpdateTime = new Date();
    err.ChartName = WIDGET_CODE.toLowerCase();
    err.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
    err.CurrentState = this.chartState;
    return err;
  }
  refreshWidget(widgetKey : string) {
    let refreshData = {
      refresh: true
    };
    if (widgetKey) {
      refreshData[widgetKey] = true;
    }
    this.chartDataService.setRefreshWidgetData(refreshData);
  }
  private determineWidgetState = () => {
    if(this.boolResponseRateLoader) this.widgetState = CHART_STATE_OPTIONS.LOADING;
    else if(this.responseError) this.widgetState = CHART_STATE_OPTIONS.ERROR;
    else if(this.IsNoDataAvailable) this.widgetState = CHART_STATE_OPTIONS.NO_DATA;
    else if(this.data) this.widgetState = CHART_STATE_OPTIONS.SUCCESS;
  }
}
