import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }
    const parsedDate = this.parseDateString(value);
    if (isNaN(parsedDate.getTime())) {
      return 'Invalid date';
    }
    // const date = new Date(value);
    const now = new Date();
    const diffInMs = now.getTime() - parsedDate.getTime();

    const diffInSec = Math.floor(diffInMs / 1000);
    const diffInMin = Math.floor(diffInSec / 60);
    const diffInHours = Math.floor(diffInMin / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30); // Approximately 30 days in a month
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInSec < 60) {
        return `${diffInSec} second${diffInSec !== 1 ? 's' : ''} ago`;
    } else if (diffInMin < 60) {
        return `${diffInMin} minute${diffInMin !== 1 ? 's' : ''} ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
    } else if (diffInDays < 30) {
        return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
    } else if (diffInMonths < 12) {
        return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
    } else {
        return `${diffInYears} year${diffInYears !== 1 ? 's' : ''} ago`;
    }
  }

  parseDateString(dateString: string): Date {
    const [month, day, year, time, meridian] = dateString.match(/(\w+)\s+(\d+)\s+(\d+)\s+(\d+:\d+)(AM|PM)/).slice(1);
    const monthIndex = new Date(`${month} 1, 2021`).getMonth(); // Get month index
    let [hours, minutes] = time.split(':').map(Number);
    if (meridian === 'PM' && hours !== 12) {
      hours += 12;
    } else if (meridian === 'AM' && hours === 12) {
      hours = 0;
    }
    return new Date(parseInt(year, 10), monthIndex, parseInt(day, 10), hours, minutes);
  }
}