import { Component,Input, OnInit ,Output,EventEmitter} from '@angular/core';
import { DialogService } from 'primeng/components/common/api';
import { LostBusinessStateModel, LbDdNav2State } from '../model/lost-business-lbcdrilldown';
import { LostBusinessDrilldownService } from '../service/lost-business-drilldown.service';

@Component({
  selector: 'app-lbc-lost-drilldown-pop-up',
  templateUrl: './lbc-lost-drilldown-pop-up.component.html',
  styleUrls: ['./lbc-lost-drilldown-pop-up.component.scss']
})
export class LbcLostDrilldownPopUpComponent implements OnInit {
  currentState: LostBusinessStateModel | null = null;

  /** Localizing imports */
  enumConstants = LbDdNav2State;
  divhide: boolean = true;
  @Input()showLosttext:any;
  @Input()lostComparisonDate:any;
  @Input()lostCustomerCount:any;
  @Output() lostdisplayChange = new EventEmitter();
  constructor(private dialogService: DialogService, private drilldownService: LostBusinessDrilldownService) {
    this.currentState = this.drilldownService.ddNav2GetData();
  }


  ngOnInit() {
  }
  onClickPrevious(data) {
    this.currentState = this.drilldownService.ddNav2.goToPrevComp(data);
  }
  onClickNext(data) {
    this.currentState = this.drilldownService.ddNav2.goToNextComp(data);
  }
  getData(data){
    this.onClickNext(data);
  }
  Hidediv(){
    this.drilldownService.ddNav2ResetStates();
    this.divhide = false;
    this.lostdisplayChange.emit({hidedrilldown : true});
  }

}
