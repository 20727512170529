import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import * as OnlineHelpJson from 'src/app/shared/constants/online-help.config';
import { SharedService } from 'src/app/shared/services/shared.service';

@Directive({
  selector: '[appOnlineHelp]'
})
export class OnlineHelpDirective {
  element: ElementRef;
  navDetails = OnlineHelpJson.OnlineHelp;

  constructor(private el: ElementRef,
    private renderer: Renderer2,
    private sharedService: SharedService) {
    this.element = el;
  }

  ngOnInit() {
    this.renderer.addClass(this.element.nativeElement, 'OnlineHelp-MouseHover');
  }

  // @HostListener('mouseover')
  // onMouseOver() {
  //   if (this.sharedService.isOnlineHelpMode) {
  //     this.renderer.addClass(this.element.nativeElement, 'OnlineHelp-MouseHover');
  //   } else {
  //     this.renderer.removeClass(this.element.nativeElement, 'OnlineHelp-MouseHover');
  //   }
  // }

  // @HostListener('mouseleave')
  // onMouseLeave() {
  //   if (this.sharedService.isOnlineHelpMode) {
  //     this.renderer.removeClass(this.element.nativeElement, 'OnlineHelp-MouseHover');
  //   }
  // }

  @HostListener('click', ['$event'])
  onMouseClick(event: MouseEvent) {
    if (this.sharedService.isOnlineHelpMode) {
      const attrValue = this.element.nativeElement.attributes.apponlinehelp.value;
      const redirect = this.navDetails.find(e => e.elementKey === attrValue).redirect;
      this.sharedService.isOnlineHelpMode = false;
      // this.renderer.removeClass(this.element.nativeElement, 'OnlineHelp-MouseHover');
      document.getElementById('BodyTag-Falcon').className = '';
      window.open(redirect, 'HelpWindow');
      throw new Error('User defined exception for prevent click event on Online help integration');
    }
  }

}
