import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, HostListener } from '@angular/core';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import * as Highcharts from 'highcharts';

import HC_map from 'highcharts/modules/map';
import { ChartDataService } from '../../service/chart-data-service.service';
import { SharedService } from 'src/app/shared/services/shared.service';

HC_map(Highcharts);
import * as _html2canvas from "html2canvas";
const html2canvas:any = _html2canvas;
import { filter } from 'rxjs/operators';
import { CONTEXT_MENUES } from '../../chartlisting.component/models/chart-binding.model';
import * as $ from 'jquery/dist/jquery.min.js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.css']
})
export class BubbleChartComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() chartTitle: string = '';
  @Input() isDualyAxes?: boolean = false;
  @Input() isDrilldownEnabled?: boolean = false;
  @Input() isClick?: boolean = false;
  @Input() currentChartView?: string = '';
  // @Input() showContextMenu?: boolean = true;
  @Input() yStackLabelsEnabled: boolean = true;
  @Input() toolTipAppendStr: string = '';
  @Input() showLineLabels: boolean = false;
  @Input() fullData: any;
  @Output() unselectData = new EventEmitter();
  dataToBeSorted : any;
  private imagepath = environment.imagepath;

  @Output() contextMenuClicked = new EventEmitter<any>();

  @Input() chartIdentifier: string = '';
  @Input() currenyView: string;
  @Input() zeroRevenue: boolean;
  @Input() allzeroRevenue: boolean;

  //compareInstances : Boolean = false ;
  @Output()
  displayChange = new EventEmitter();

  showDataLabel: boolean;
  @Input() selectedLegends:any;

  Highcharts: typeof Highcharts = Highcharts;
  chartInstance: any;
  regions = '';
  salesEngineers = '';
  serviceBranchs = '';
  serviceTechnicians = '';
  lastContextMenuClicked: string = CONTEXT_MENUES.UNKNOWN.toString();
  nrrTooltipData = [];
  chartType = null;
  showEmailList = false;
  emailList = [];
  resetZoomButton: any;
  columns = [];
  emailListHeader = null;
  showThirdLevelMenu = false;
  restWidth = 0;
  restWidthright = 0;
  selectedBarStr = null;
  selectedPointColor = null;
  showChartTitle: string;
  private barChartLableVisibility: boolean;
  // css classes bug: 44279
  secondMenuClasses: any[] = [];
  thirdMenuClasses: any[] = [];
  // variables to manage the chart context menu bug: 44279
  chartXTemp: number;
  isPreviousLeft: boolean;
  RRTGraphWidth: number;
  rscTooltipData = [];
  

  @Input()
  public chartState: CHART_STATE_OPTIONS;

  options: Highcharts.Options = {
    title: {
      text: null
      , verticalAlign: 'top',
      align: 'left',
      style: {
        fontFamily: 'Source Sans Pro Regular',
        fontSize: '1.3em',
        fontWeight: 'bold',
        color: '#333333'
      }
    }
  };
  oneToOneFlag: boolean;


  isLeftContextMenu: boolean = false;
  constructor(private sharedService: SharedService, private chartService: ChartDataService) {
    
  }

  ngAfterViewInit() {
    this.fullData =this.fullData ? [...this.fullData]: [...[]]
    this.data = this.data ? [...this.data]: [...[]]
    setTimeout(() => {
      this.renderBubbleChart();
    }, 500); // The delay can be adjusted as needed, even 0 milliseconds can be sufficient.
  }

  logChartInstance(chart: Highcharts.Chart) {
    this.chartInstance = chart;
  }

  showHideLabels($event?) {

    if (!(this.chartInstance && this.chartInstance.series)) return;
    let classContext = this;
    for (let i = 0; i < this.chartInstance.series.length; i++) {
      var opt = this.chartInstance.series[i].options;
      if (opt.type.indexOf("spline") > -1) {
        opt.dataLabels.enabled = true;
      } else {
        opt.dataLabels.enabled = !opt.dataLabels.enabled;
        this.barChartLableVisibility = opt.dataLabels.enabled;
      }

      opt.dataLabels.formatter = function () {
        let _this: any = this;
        if (_this.y !== 0) {
          if (_this.point.series.initialType.toLowerCase().indexOf('spline') > -1) {
            return _this.y + '%';
          }
          if (_this.point.series.initialType.toLowerCase() == 'column') {
            if (classContext.isDualyAxes) {
              return _this.series.name.toLowerCase() !== 'surveys sent' ? '' : _this.y;
            } else { return _this.y + '%'; }
          }
        }
      };
      opt.dataLabels.color = '#333333';
      opt.dataLabels.style = {
        fontFamily: 'Source Sans Pro Regular',
        textShadow: false,
        textOutline: 'false',
        fontSize: '10px'
      };

      this.chartInstance.series[i].update(opt);
      // this.chartInstance.series.formatter = function (data) {
      // }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(this.fullData)
    if(changes.data && changes.data.currentValue)
      setTimeout(() => {
        this.renderBubbleChart();
      }, 500);
    
  }
  ngOnInit() {
    console.log('hereee bubble')
  }


  private renderBubbleChart() {
    this.oneToOneFlag = true;
    //this.chartState=  CHART_STATE_OPTIONS.SUCCESS
    this.emailListHeader = null;
    this.showEmailList = false;
    this.showThirdLevelMenu = false;
    let isFirstRender = true;
    if (!this.isClick) {
      this.hideContextMenu();
    }

    if (this.currentChartView == "euro") {
      this.showChartTitle = "Revenue (€ euro currency)";
    } else if (this.currentChartView == "fam local") {
      this.showChartTitle = "Revenue (€ fam local currency)";
    } else {
      this.showChartTitle = "Revenue (₹ local currency)";
    }
    Highcharts['seriesTypes'].areaspline.prototype.drawLegendSymbol = Highcharts['seriesTypes'].spline.prototype.drawLegendSymbol;
    let mouseIn: boolean, classContext = this;
    
    // if (!(changes && changes.data)) {
    //   return;
    // }
    // if (changes.data.currentValue === undefined) {
    //   return;
    // }
   
    // const container =document.getElementById('containerData');
    // Highcharts.chart(container, {
      this.options = {
      chart: {
        type: 'bubble',
        plotBorderWidth: 0,
        //zoomType: 'xy',
        panning: true,
        events: {
          render: this.onChartRender.bind(this),
          redraw: function () {
            classContext.hideContextMenu();
          },
          // render: function () {
          //   const _this = this
          //   _this.onChartRender.bind(_this)
          //   if (_this.resetZoomButton) {
          //     _this.resetZoomButton.hide();
          //   }
          // }
          // click: function () {
          //   if (!classContext.showEmailList) {
          //     classContext.rscTooltipData = [];
          //     classContext.hideContextMenu();
          //     classContext.showThirdLevelMenu = false;
          //   }
          // },
          // redraw: function () {
          //   classContext.hideContextMenu();
          // },
          // render: function () {
          //   if (this.resetZoomButton) {
          //     this.resetZoomButton.hide();
          //   }
          // }
        },  
      },
      plotOptions: {
        bubble: {
          minSize: this.allzeroRevenue ? -70 : 10,
          maxSize: this.zeroRevenue ? 50 : 20,
          marker: {
            symbol: 'circle',
          },
        },
        
      },
      title: {
        text: null, 
        verticalAlign: 'top',
        align: 'left',
        style: {
          fontFamily: 'Source Sans Pro Regular',
          fontSize: '1.3em',
          fontWeight: 'bold',
          color: '#333333'
        }
      },
      legend: {
        enabled: true,
        borderWidth: 0,
        align: 'right',
        verticalAlign: 'top',
        y: -8,
        x: -75,
        itemStyle: {
          fontFamily: 'Source Sans Pro Regular',
          fontSize: '12px',
          fontWeight: 'normal',
          color: '#666666',         
        },
        symbolHeight: 10,
      },
      credits: {
        enabled: false
      },
      mapNavigation: {
        enabled: true,
        enableDoubleClickZoom: true,
        enableMouseWheelZoom: true,
        buttonOptions: {
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver'
          },
          verticalAlign: 'top',
          align: "right",
          alignTo: "spacingBox",
          style: {
            fontSize: "10px"
          },
          width: 10,
          height: 10,
          padding: 7
        }
      },
      xAxis: {
        title: {
          text: 'Net Promoter Score'
        },
        //endOnTick: true,
        gridLineWidth: 0,
        labels: {
          style: {
            fontFamily: 'Source Sans Pro Regular',
            fontSize: '10px',
            color: '#333333',
            fontWeight: 'bold'
          }
        },
        scrollbar: {
          enabled: true,
          barBackgroundColor: 'white',
          barBorderColor: 'white',
          barBorderWidth: 0,
          buttonArrowColor: 'white',
          buttonBackgroundColor: 'white',
          buttonBorderColor: 'white',
          buttonBorderRadius: 0,
          buttonBorderWidth: 0,
          rifleColor: 'white',
          showFull: false,
          minWidth: 0,
          trackBackgroundColor: 'white',
          trackBorderColor: 'white'
        },
        events: {
          setExtremes: function (e: any) {
            if (e && e.target && e.target.chart && e.target.chart.yAxis && e.target.chart.yAxis.length) {
              if (e.target.chart.yAxis[0].oldUserMax !== (e.target.chart.yAxis[0].dataMax)+1 || e.target.chart.yAxis[0].oldUserMin !== (e.target.chart.yAxis[0].dataMin)-1) {
                setTimeout(function () {
                  e.target.chart.yAxis[0].setExtremes(e.target.chart.yAxis[0].dataMin-1, e.target.chart.yAxis[0].dataMax+1);
                });
              }
            }
          }
        },
        accessibility: {
          rangeDescription: 'Range: -5 to 5.'
        },
      },
    
      yAxis: {
        //min: 0,
        title: {
          text: 'Sentiment Score'
        },
        lineWidth: 0,
        gridLineWidth: 1,
        // min: -7, // Set the minimum value of the X-axis
        // max: 7,
        tickInterval: 1,
        startOnTick: false,
        endOnTick: false,
        accessibility: {
          rangeDescription: 'Range: 0 to 100.'
        },
        scrollbar: {
          enabled: true,
          showFull: false
        },
        // events: {
        //   setExtremes: function (e: any) {
        //     debugger;
        //     if (e && e.target && e.target.chart && e.target.chart.xAxis && e.target.chart.xAxis.length) {
        //       if (e.target.chart.xAxis[0].oldUserMax !== (e.target.chart.xAxis[0].dataMax) || e.target.chart.xAxis[0].oldUserMin !== (e.target.chart.xAxis[0].dataMin)) {
        //         setTimeout(function () {
        //           e.target.chart.xAxis[0].setExtremes(e.target.chart.xAxis[0].dataMin, e.target.chart.xAxis[0].dataMax);
        //         });
        //       }
        //     }
        //   }
        // }
      },
      tooltip: {
        formatter: function() {
          let dataToShow = null;
          let currencyView = '€'
          let customerData = null
          const point = this.point as Highcharts.SeriesBubbleDataOptions;
          if(classContext.fullData && classContext.fullData.length > 0) {
            dataToShow = classContext.fullData.find((data: any) => data.TouchPointCode === this.series.name)
            customerData = dataToShow.BubbleChartDetails.find((data: any) => data.Customer_Name === point.name && data.Customer_Account_Number == point['Id'])
            currencyView = classContext.currenyView === 'local' ? customerData.UserLocalCurrencySymbol :  classContext.currenyView == "famLocal" ? customerData.FAMLocalCurrencySymbol : customerData.EURSymbol;
          }    
          let nf = new Intl.NumberFormat('en-US');
          return '<br><b>Customer:</b>' + point.name + '<br><b>NPS:</b> ' + point.x + '%<br><b>Sentiment Score:</b> ' + point.y + '<br><b>TouchPoint:</b> ' + (dataToShow ? dataToShow.Description +'('+this.series.name + ')': this.series.name)  + '<br><b>Revenue:</b> ' + currencyView + nf.format(point.z);
    
        },
      },
      series: this.data.map((seriesItem: any) => {
        return {
          keys: ['x', 'y', 'z', 'name'],
          name: seriesItem.name,
          id: seriesItem.id,
          value:seriesItem.value,
          type: 'bubble',
          data: seriesItem.data,
          visible: seriesItem.visible,
          // pointPlacement:this.arrangeFormmat(element.TouchPointCode),
          marker: seriesItem.marker,
          events: {
            legendItemClick: function() {
              const series = this;
              const selectedLegend = this.name; // Name of the clicked legend
              classContext.handleLegendItemClick(selectedLegend,series.visible);
            }
          }
        }
      })   
    };
  }

  // const container =document.getElementById('containerData');
  // Highcharts.chart(container, this.options);

  onChartRender(event: Event): void {
    const chart = (event.target as any); // Assuming 'event.target' is the chart
    const legendItems = chart.legend.allItems;
 
    legendItems.forEach((item: any) => {
      const legendItem = item.legendGroup.element;
 
      legendItem.addEventListener('mouseover', (e: Event) => {
        const seriesName = (e.target as HTMLElement).textContent;
        const dataToShow = this.fullData.find((data: any) => data.TouchPointCode === seriesName);
 
        if (dataToShow) {
          const tooltip = document.createElement('div');
          tooltip.className = 'custom-tooltip';
          tooltip.innerHTML = dataToShow.Description;
 
          const legendRect = legendItem.getBoundingClientRect();
          tooltip.style.position = 'absolute';
          tooltip.style.left = `${legendRect.right + window.scrollX}px`;
          tooltip.style.top = `${legendRect.top + window.scrollY}px`;
 
          tooltip.style.padding = '5px';
          tooltip.style.background = 'rgba(0, 0, 0, 0.7)';
          tooltip.style.color = '#fff';
          tooltip.style.borderRadius = '3px';
          tooltip.style.fontFamily = 'Arial, sans-serif';
          tooltip.style.fontSize = '12px';
 
          document.body.appendChild(tooltip);
        }
      });
 
      legendItem.addEventListener('mouseout', () => {
        setTimeout(() => {
          const tooltip = document.querySelector('.custom-tooltip');
          if (tooltip) {
            tooltip.remove();
          }
        }, 200); // Adjust the delay time (in milliseconds) as needed
      });
    });
  }

  handleLegendItemClick(selectedLegend: string, visible: boolean) {
    // Check if the legend is already selected
    const indexData = this.selectedLegends.findIndex(item => item.name === selectedLegend);
  
    if (indexData !== -1) {
      // If the legend is already selected, remove it
      this.selectedLegends[indexData].visible = !visible;
    }
  
    // Perform actions based on the selected legends
    console.log('Selected Legends:', this.selectedLegends);
    this.unselectData.emit({ legendUnclicked: this.selectedLegends });
    // Add your logic here
  }
  

  compareByInstances(event) {
    let showInstances = event.checked;
    this.displayChange.emit(showInstances);
  }
  resetDrilldownView(view: string) {
    this.lastContextMenuClicked = view.toLowerCase();
  }

  showClickedListModal(type = null, data: any = { clickedItemList: [] }) {
    if (type === 'Clicked' && !!data.clickedCount) {
      this.showEmailList = true;
      this.emailList = [];
      this.showThirdLevelMenu = true;
      if (data && data.clickedItemList) {
        this.emailListHeader = type;
        this.columns = [type];
        data.clickedItemList.map((x, i) => {
          let sentDate = x.SurveySentOn.split('T');
          let splitDate = sentDate[0].split('-');
          x.SurveySentDateFormatted = splitDate[2] + splitDate[1] + splitDate[0];
          this.emailList.push(x);
        });
      }
    } else {
      this.emailListHeader = null;
      this.showEmailList = false;
      this.showThirdLevelMenu = false;
    }
  }

  showOpnendListModal(type = null, data: any = { openedItemList: [] }) {
    if (type === 'Opened' && !!data.openedCount) {
      this.showEmailList = true;
      this.emailList = [];
      this.showThirdLevelMenu = true;
      if (data && data.openedItemList) {
        this.emailListHeader = type;
        this.columns = [type];
        data.openedItemList.map((x, i) => {
          let sentDate = x.SurveySentOn.split('T');
          let splitDate = sentDate[0].split('-');
          x.SurveySentDateFormatted = splitDate[2] + splitDate[1] + splitDate[0];
          this.emailList.push(x);
        });
      }
    } else {
      this.emailListHeader = null;
      this.showEmailList = false;
      this.showThirdLevelMenu = false;
    }
  }

  closeGrid() {
    this.showEmailList = false;
  }
  hideThirdLevelMenu() {
    this.showThirdLevelMenu = false;
  }

  contextmenu(e, level: number) {
    var list = $(e.target).closest('li');
    console.log('list', list);
    var popoverList = $('.custom-popover > ul');
    // bug: 44279
    level === 2 && this.getSecondLevelMenuClass();
    level === 3 && this.getThirdLevelMenuClass();

    if (list.hasClass('selected')) {
      list.children('ul').toggle(400);
      list.removeClass('selected');
      list.parent().next('ul > li').removeClass('selected');
      list.children('ul').find('li').removeClass('selected');
      list.children('ul').find('li').children('ul').slideUp(400);
    }
    else {
      list.siblings('li').children('ul').hide(400);
      list.siblings('li').removeClass('selected');
      list.children('ul').toggle(400);
      list.addClass('selected');
    }


  }
  showContextMenuTooltip() {
    let el = document.getElementById('noResponsebyRevenuecontextMenuRrt');
    if (this.chartIdentifier.toLowerCase() !== 'nrr' || (this.chartIdentifier.toLowerCase() === 'nrr' && !(this.isClick && this.chartType && this.nrrTooltipData && this.nrrTooltipData.length))
      || !el || el && el.getAttribute('style') && el.getAttribute('style').includes('display: none')) {
      return true;
    }
    return false;
  }
  getFirstLevelMenuClass() {
    let resultClass = []
    if (this.showThirdLevelMenu) {
      resultClass.push('display-third-level-menu')
    }
  }
  //bug: 44279
  smallMenuWidth: number = 155;
  mainMenuWidth: number = 215;
  menuClick(level: number) {
    let sign = this.isPreviousLeft ? -1 : 1;
    let offsetWidth = ((this.mainMenuWidth + (this.smallMenuWidth * (level - 1))) * sign);
    let remWidth = this.chartXTemp + offsetWidth;
    let remMenuWidth = this.RRTGraphWidth - remWidth;
    let finalRemWidth = this.isPreviousLeft ? remWidth : remMenuWidth;
    if (finalRemWidth < this.smallMenuWidth) {
      if (level === 2) {
        this.secondMenuClasses.push('position-slide-reverse');
      }
      if (level === 3) {
        this.thirdMenuClasses.push('position-slide-reverse');
      }
    }
  }

  getSecondLevelMenuClass() {

    this.menuClick(2);
  }

  getThirdLevelMenuClass() {
    this.menuClick(3);
  }
  hideContextMenu() {
    let el = document.getElementById('noResponsebyRevenuecontextMenuRrt');
    if (el) {
      el.setAttribute('style', 'display: none');
    }
    let popoverEl: any = document.getElementsByClassName('child-menu');
    for (let i = 0; i < popoverEl.length; i++) {
      popoverEl[i].setAttribute('style', 'display:none !important');
      popoverEl[i].parentElement.classList.remove('selected')
    }
    this.selectedPointColor = null;
    this.selectedBarStr = null;
  }
  @HostListener('document:click', ['$event']) documentClickEvent($event: any) {

    let el = document.getElementById('noResponsebyRevenuecontextMenuRrt');
    let chartPoint = document.getElementsByClassName('highcharts-point');
    if (el && el.contains($event.target) || $event.target.classList.contains('highcharts-point')) {
      //
    } else {
      if (el && el.id !== 'noResponsebyRevenuecontextMenuRrt') {
        this.hideContextMenu();
      }
    }
    if ($event.target.classList.contains('highcharts-point')) {
      this.hideContextMenu();

    }
  }


}

