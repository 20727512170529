import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { CreateSurveyService } from '../services/create-survey.service';

@Component({
  selector: 'app-start-survey',
  templateUrl: './start-survey.component.html',
  styleUrls: ['./start-survey.component.scss']
})


export class StartSurveyComponent implements OnInit {

  skipCheckBoxValue = [];

  constructor(public router: Router, public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private _createSurveyService: CreateSurveyService) { }

  ngOnInit() {

  }

  goToSurvey() {
    
    if (this.skipCheckBoxValue.length > 0) {
      this._createSurveyService.skipInroPopup(true).then(res => {
        if (res != null && res.Response == true) {
          this.router.navigate(['/surveys/create-survey']);
          this.ref.close();
        }
      });
    } else {
      this.router.navigate(['/surveys/create-survey']);
      this.ref.close();
    }
  }

}
