import { DashboardEvent } from "../../enums/dashboard.event";

export class Widget {
    Category: string;
    IsApplicable: boolean;
    IsDefaultView: boolean;
    IsSaved: boolean;
    IsVisible: boolean;
    Position: string;
    TouchPoint: string;
    WidgetCode: string;
    WidgetImage: string;
    WidgetName: string;
}

export class WidgetMaster {
    LeftWidgetList: Array<Widget>;
    CenterWidgetList: Array<Widget>;
    DrillDownWidgetList: Array<Widget>;
    Event:DashboardEvent = DashboardEvent.Load
}
