import { Component, OnInit, Input, Renderer, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { Top5CustomersService } from '../service/top5-customers.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subscription } from 'rxjs';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
// import * as html2canvas from "html2canvas"
import { filter } from 'rxjs/operators';
import { ExportChartModel, SlideTextData } from 'src/app/shared/models/export-chart.model';
import { ChartDataService } from '../service/chart-data-service.service';
import { environment } from 'src/environments/environment';
const WIDGET_CODE = 'top5';

@Component({
  selector: 'app-top5-customers',
  templateUrl: './top5-customers.component.html',
  styleUrls: ['./top5-customers.component.scss']
})
export class Top5CustomersComponent implements OnInit, OnChanges {
  private imagepath = environment.imagepath;
  @ViewChild('topFiveMain') el: ElementRef;
  @ViewChild('toolTipCust') el2: ElementRef;
  @Input('customers') customers: any;
  @Input('errordata') errordata: any;
  @Input('boolTop5Loader') boolTop5Loader;
  leftStyle: string = '5px 0px 5px 0px';
  centerStyle: string = '0px 0px 0px 0px';
  rightStyle: string = '5px 5px 5px 0px';
  allStyle: string = '5px';
  setStyle: string;

  arrDisplay = [false, false, false, false];
  arrDisplayText = ['More...', 'More...', 'More...', 'More...', 'More...'];
  dashboardImageUrl = this.sharedService.configuration.DashboardImagePath;
  top5NoData: string = this.dashboardImageUrl +"Top5_Customers_NoData.svg";
  private chartState: CHART_STATE_OPTIONS;
  private exportSubscription: Subscription;
  @ViewChild('pictureOf') pictureOf: ElementRef;

  constructor(private top5customerService: Top5CustomersService, private render: Renderer, private sharedService: SharedService,
    private chartDataService: ChartDataService) {
    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(val => val !== null && val.includes(WIDGET_CODE.toLowerCase()))).subscribe(val => {
      setTimeout(() => {
        this.toCanvas();
      }, 200);
      this.slideTextToShow();
    });
  }

  ngOnInit() {

  }
  ngOnChanges() {
    // Update chart state
    this.updateExportDetails();
  }
  ngOnDestroy() {
    this.exportSubscription.unsubscribe();
    this.updateExportDetails(true);
  }

  showHideTopFiveData(event: any) {
    let clickedElement = event.currentTarget;
    let eleN = parseInt(clickedElement.getAttribute("data-number"));
    if (clickedElement.innerHTML != 'Less...') {
      this.arrDisplay[eleN] = true;
      this.arrDisplayText[eleN] = 'Less...';
      this.render.setElementClass(clickedElement, "clicked", true);
    }
    else {
      this.arrDisplay[eleN] = false;
      this.arrDisplayText[eleN] = 'More...'
      this.render.setElementClass(clickedElement, "clicked", false);
    }

    setTimeout(() => {
      if (this.el.nativeElement.offsetHeight > this.el.nativeElement.parentElement.offsetHeight) {
        this.render.setElementStyle(this.el.nativeElement.parentElement, 'overflow-y', 'auto');
      }
      else {
        this.render.setElementStyle(this.el.nativeElement.parentElement, 'overflow-y', 'hidden');
      }

      if (this.el2.nativeElement.offsetWidth < this.el2.nativeElement.scrollWidth) {
        this.el2.nativeElement.tooltip({
          title: this.el2.nativeElement.text(),
          placement: "bottom"
        });
        this.el2.nativeElement.tooltip('show');
      }
    }, 100);
  }

  closeWidget() {
    this.sharedService.widgetCloseSubject.next('TOP5');
  }

  getPrmoterStyles() {
    let prStyles = {
      'width': this.customers.PromoterPerc,
      'border-radius': this.customers.NeutralPerc == 0 && this.customers.DetractorPerc == 0 ? this.allStyle : this.leftStyle
    };
    return prStyles;
  }
  getNeutralStyles() {
    let prStyles = {
      'width': this.customers.NeutralPerc,
      'border-radius': this.customers.PromoterPerc == 0 && this.customers.DetractorPerc == 0 ? this.allStyle : this.rightStyle
    };
    return prStyles;
  }
  getdetractorStyles() {
    let prStyles = {
      'width': this.customers.DetractorPerc,
      'border-radius': this.customers.NeutralPerc == 0 && this.customers.PromoterPerc == 0 ? this.allStyle : this.rightStyle
    };
    return prStyles;
  }
  private toCanvas = () => {
    try {
      // if (this.pictureOf && this.pictureOf.nativeElement) {
      //   html2canvas(this.pictureOf.nativeElement, { allowTaint: false, useCORS: false, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
      //     let chartUpdate: ExportChartModel = new ExportChartModel();
      //     chartUpdate.UpdateTime = new Date();
      //     chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
      //     chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
      //     chartUpdate.CurrentState = this.chartState;
      //     chartUpdate.ChartImgBase64 = canvas.toDataURL('image/png');

      //     this.sharedService.updateChartStatusSubject(chartUpdate);
      //   },
      //     err => {
      //       console.log('error occurred during image export, details as below \n', err);
      //     });
      // }

      let chartUpdate: ExportChartModel = new ExportChartModel();
      chartUpdate.UpdateTime = new Date();
      chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
      chartUpdate.ChartImgId = null;
      chartUpdate.CurrentState = this.chartState;
      chartUpdate.ChartImgBase64 = 'this_does_not_have_to_be_uploaded_to_blob';
      this.sharedService.updateChartStatusSubject(chartUpdate);
    } catch (error) {
      console.log('html2canvas error as', error);
    }
  }
  private updateExportDetails = (isDestroyCall: boolean = false) => {
    this.determineWidgetState();
    let chartUpdate: ExportChartModel = new ExportChartModel();
    chartUpdate.UpdateTime = new Date();
    chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
    if (isDestroyCall) {
      this.chartState = CHART_STATE_OPTIONS.UNKNOWN;
    }
    chartUpdate.CurrentState = this.chartState;// ? this.chartState.toLowerCase() : CHART_STATE_OPTIONS.LOADING;
    this.sharedService.updateChartStatusSubject(chartUpdate);
  }
  private determineWidgetState = () => {
    if(this.chartState == null && !this.errordata) {
      this.chartState = CHART_STATE_OPTIONS.NO_DATA;
    }
    if(this.customers == null && this.errordata) {
      this.chartState = CHART_STATE_OPTIONS.ERROR;
    }
    this.chartState = this.boolTop5Loader ? CHART_STATE_OPTIONS.LOADING : CHART_STATE_OPTIONS.SUCCESS;
  }
  private slideTextToShow = () => {
    let textConfig: SlideTextData = new SlideTextData();
    if (this.chartState === CHART_STATE_OPTIONS.SUCCESS) {
      if (this.customers && this.customers.length > 0) {

        for (let index = 0; index < this.customers.length; index++) {
          let listString: string[] = [], slideRows: string[] = [], row: string = '';
          const customer = this.customers[index];
          row += 'CustomerName||' + customer['CustomerName'] + '||'; // no display text
          slideRows.push(row); row = '';
          row += 'TotalTransactionCnt||' + customer['TotalTransactionCnt'] + '||total transactions'; //display text
          slideRows.push(row); row = '';
          row += 'ContactRespondedCnt||' + customer['ContactRespondedCnt'] + '||contacts responded'; //display text
          slideRows.push(row); row = '';
          row += 'Surveys Sent||' + customer['SurveySentCnt'];
          slideRows.push(row); row = '';
          for (let Dispositions = 0; Dispositions < customer.DispositionBifurcations.length; Dispositions++) {
            const disposition = customer.DispositionBifurcations[Dispositions];
            row += disposition['DispositionName'] + '||' + disposition['DispositionCnt'];
            slideRows.push(row); row = '';
          }

          textConfig.SlideText = listString.join('||');
          textConfig.SlideRows = slideRows;
          textConfig.SlideId = WIDGET_CODE.toLowerCase();
          textConfig.SlideTextId = textConfig.SlideId + '_' + index;
          this.sharedService.updatechartTextSubject(textConfig);
        }
      }
    } else if (this.chartState === CHART_STATE_OPTIONS.NO_DATA) {
      textConfig.SlideText = '';
      textConfig.SlideRows = [];
      textConfig.SlideId = WIDGET_CODE;
      textConfig.SlideTextId = textConfig.SlideId;
      textConfig.HasNoData = true;
      this.sharedService.updatechartTextSubject(textConfig);
    }
  }
    
  refreshWidget(widgetKey : string) {
    let refreshData = {
      refresh: true
    };
    if (widgetKey) {
      refreshData[widgetKey] = true;
    }
    this.chartDataService.setRefreshWidgetData(refreshData);
  }
  // enter(){

  //   setTimeout(()=>{
  //     if (this.el2.nativeElement.offsetWidth < this.el2.nativeElement.scrollWidth ) {
  //       this.el2.nativeElement.tooltip({
  //           title: this.el2.nativeElement.text(),
  //           placement: "bottom"
  //       });
  //       this.el2.nativeElement.tooltip('show');
  //   }
  //   },100);

  // }

  //   setTimeout(() => { document.getElementsByClassName('tooltiptext'); }, this.MessageTimeOut);
  //   document.('mouseenter', ".iffyTip", function () {
  //     var $this = $(this);
  //     if (this.offsetWidth < this.scrollWidth && !$this.attr('title')) {
  //         $this.tooltip({
  //             title: $this.text(),
  //             placement: "bottom"
  //         });
  //         $this.tooltip('show');
  //     }
  // });
}

