import { Component, OnInit, Input, ViewChild, EventEmitter, Output, Inject, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';

import { SurveyService } from "../services/survey.service";
import { SessionStorageService } from "../services/session-storage.service";
import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
//import { MessageService } from 'primeng/api';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';


@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss'],
  providers: [MessageService]

})
export class AddTemplateComponent implements OnInit {

  userform: FormGroup;
  onAdd = new EventEmitter();
  isSkipStep: any = false;
  @Input() templatedata: any;
  model: any = {};
  addTemplateObj: any = {
    templateName: '',
    category: null,
    language: null,
    channel: {},
    type: {},
    touchPoint: ''
  }
  showFirstScreen: boolean = true;
  languagelist: any = [];
  channelList: any = [];
  categoryList: any = [];
  typeList: any = [];
  touchPointList: any = [];

  msgs: Message[] = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;

  constructor(private surveyService: SurveyService, public messageService: MessageService,
    private formBuilder: FormBuilder, public router: Router,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private sessionStorageService: SessionStorageService, private sharedService: SharedService) {

  }

  onSelectCheckBox(event) {

    this.isSkipStep = event;
  }
  onLetsStart() {
    this.showFirstScreen = false;
    this.surveyService.UpdateUserPreferenceCreateTemplate(this.isSkipStep).then(rawData => {
      if (this.isSkipStep == true)
        this.sessionStorageService.setSessionData('Preferences', 'CREATE_TRANS_TEMPLATE_INTRO=true');

    }).catch(e => {
    });
  }

  getCategoryData() {
    //this.categoryList = [{ "templateCategoryId": 1, "category": "SALES(S)", "isActive": false, "isDeleted": false, "createdBy": 0, "dateCreated": "0001 - 01 - 01T00: 00: 00", "modifiedBy": null, "modifiedDate": null }, { "templateCategoryId": 2, "category": "COMMISSION(L)", "isActive": false, "isDeleted": false, "createdBy": 0, "dateCreated": "0001 - 01 - 01T00: 00: 00", "modifiedBy": null, "modifiedDate": null }, { "templateCategoryId": 3, "category": "PLANNED MAINTENANCE(P)", "isActive": false, "isDeleted": false, "createdBy": 0, "dateCreated": "0001 - 01 - 01T00: 00: 00", "modifiedBy": null, "modifiedDate": null }, { "templateCategoryId": 4, "category": "SERVICE REPAIR(U)", "isActive": false, "isDeleted": false, "createdBy": 0, "dateCreated": "0001 - 01 - 01T00: 00: 00", "modifiedBy": null, "modifiedDate": null }, { "templateCategoryId": 5, "category": "RENTAL(R)", "isActive": false, "isDeleted": false, "createdBy": 0, "dateCreated": "0001 - 01 - 01T00: 00: 00", "modifiedBy": null, "modifiedDate": null }]

    this.surveyService.getCategoryList().then(rawData => {
      this.categoryList = rawData;
      if (this.templatedata && this.templatedata.TemplateCategoryId) {
        this.addTemplateObj.category = this.categoryList.filter(elem => elem.TemplateCategoryId == this.templatedata.TemplateCategoryId)[0];
      }
    }).catch(e => {

      // alert('something happening wrong');
    });
  }
  getChannelData() {
    //this.channelList = [{ ChannelId: 1, Channel: 'Email' }];
    if (this.templatedata && this.templatedata.ChannelId) {
      this.addTemplateObj.channel = this.channelList.filter(elem => elem.ChannelId == this.templatedata.ChannelId)[0]
    }
    this.surveyService.getChannelList().then(rawData => {
      this.channelList = rawData;
      if (this.templatedata && this.templatedata.ChannelId) {
        this.addTemplateObj.channel = this.channelList.filter(elem => elem.ChannelId == this.templatedata.ChannelId)[0]
      }
      else {
        this.addTemplateObj.channel = this.channelList.filter(elem => elem.ChannelName == 'Email')[0]
      }
    }).catch(e => {

      //alert('something happening wrong');
    });
  }
  getTypeData() {
    //this.typeList = [{ Type: 'Transactional' }]
    // if (this.templatedata && this.templatedata.TemplateTypeId) {
    //   this.addTemplateObj.type = this.typeList.filter(elem => elem.TemplateTypeId == this.templatedata.TemplateTypeId)[0]
    // }
    this.surveyService.getTypeList().then(rawData => {
      this.typeList = rawData;
      if (this.templatedata && this.templatedata.Type) {
        this.addTemplateObj.type = this.typeList.filter(elem => elem.TemplateTypeName == this.templatedata.Type)[0]
      }
      else {
        this.addTemplateObj.type = this.typeList.filter(elem => elem.TemplateTypeName == 'Transactional')[0]
      }
    }).catch(e => {

      //alert('something happening wrong');
    });
  }
  getLanguageData() {
    // this.languagelist = [
    //   {
    //     languageId: 1,
    //     languageDescription: "English",
    //     isoCode: "en-gb",
    //     isActive: true
    //   },
    //   {
    //     languageId: 2,
    //     languageDescription: "Hindi",
    //     isoCode: "en-us",
    //     isActive: true
    //   },
    //   {
    //     languageId: 3,
    //     languageDescription: "Spanish",
    //     isoCode: "es",
    //     isActive: true
    //   }];

    this.surveyService.getLanguageList().then(rawData => {
      this.languagelist = rawData;
      if (this.templatedata && this.templatedata.LanguageId) {
        this.addTemplateObj.language = this.languagelist.filter(elem => elem.LanguageId == this.templatedata.LanguageId)[0]
      }
    }).catch(e => {

      //alert('something happening wrong:LanguageAPI');
    });
  }
  getTouchPointData() {
    // this.touchPointList = [
    //   {
    //     "touchPointId": 1,
    //     "touchPointCode": "ES",
    //     "description": "Equipment Sales"
    //   },
    //   {
    //     "touchPointId": 2,
    //     "touchPointCode": "L",
    //     "description": "Commissioning"
    //   },
    //   {
    //     "touchPointId": 3,
    //     "touchPointCode": "P",
    //     "description": "Planned Maintenance"
    //   }]

    this.surveyService.getTouchPointList().then(rawData => {
      this.touchPointList = rawData;
    }).catch(e => {

      // alert('something happening wrong : TouchpointAPI');
    });
  }
  ngOnInit() {
    this.formgeneration();
    if (this.sessionStorageService.getSessionData('Preferences') == 'CREATE_TRANS_TEMPLATE_INTRO=true') {
      this.showFirstScreen = false;
    }
    if (this.config && this.config.data) {
      this.showFirstScreen = false;
      this.templatedata = this.config.data;
    }
    if (this.templatedata) {
      this.showFirstScreen = false;
      this.addTemplateObj.templateName = this.templatedata.TemplateName,
        this.addTemplateObj.touchPoint = this.templatedata.TouchPointId

    }
    this.getCategoryData();
    this.getTypeData();
    this.getLanguageData();
    this.getTouchPointData();
    this.getChannelData();
  }

  markAllControlsDirty() {
    this.userform.controls['templateName'].markAsDirty();
    this.userform.controls['templateCategory'].markAsDirty();
    this.userform.controls['language'].markAsDirty();
    this.userform.controls['touchPoint'].markAsDirty();
    // this.userform.controls['Division'].markAsDirty();
    // this.userform.controls['FAM'].markAsDirty();
  }
  onSubmit(formValue, event) {
    this.markAllControlsDirty();
    //return


    if (this.userform.valid == true) {
      let templateObj = {
        "TemplateName": this.addTemplateObj.templateName,
        "TemplateCategoryId": this.addTemplateObj.category.TemplateCategoryId,
        "TouchPointId": this.addTemplateObj.touchPoint,
        //"TouchPointId": 1,
        "LanguageId": this.addTemplateObj.language.LanguageId,
        "ChannelId": this.addTemplateObj.channel.ChannelId,
        "CreatedBy": 1,
        "CreatedOn": new Date(),
        "Type": this.addTemplateObj.type.TemplateTypeName,
        "Status": "Draft"
      }



      this.surveyService.createTemplate(templateObj).then(rawData => {
        if (rawData.Status == "Success") {

          this.ref.close();
          this.router.navigate(['/surveys/create-survey-template', rawData.Response.TemplateId, 'create']);
        }
        else {
          //let errorMsg =
          // this.messageService.add({ severity: 'error', summary: '', detail: rawData.Errors ? rawData.Errors[0].Message : '' });
          //Error
          this.callErrorMsg('error', '', rawData.Errors ? rawData.Errors[0].Message : '');
        }
        // alert('added successfully');
      }).catch(e => {

        // alert('something happening wrong');
      });
    }
  }
  onback() {

    this.ref.close();
  }
  formgeneration() {

    this.userform = this.formBuilder.group({
      'templateName': new FormControl('', Validators.required),
      'templateCategory': new FormControl('', Validators.required),
      'language': new FormControl('', Validators.required),
      'channel': new FormControl('', Validators.required),
      'type': new FormControl('', Validators.required),
      'touchPoint': new FormControl('', Validators.required)
    });
  }
  onUpdate() {
    let templateObj = {
      "TemplateId": this.config.data.TemplateId,
      "TemplateName": this.addTemplateObj.templateName,
      "TemplateCategoryId": this.addTemplateObj.category.TemplateCategoryId,
      "TouchPointId": this.addTemplateObj.touchPoint,
      "LanguageId": this.addTemplateObj.language.LanguageId,
      "ChannelId": this.addTemplateObj.channel.ChannelId,
      "CreatedBy": 1,
      "CreatedOn": new Date(),
      "Type": this.addTemplateObj.type.TemplateTypeName,
      "Status": "Draft"

      // "TemplateId": this.config.data.TemplateId,
      // "TemplateName": this.addTemplateObj.templateName,
      // "TemplateCategoryId": 1,// this.addTemplateObj.category.TemplateCategoryId,
      // "TouchPointId": 1,// this.addTemplateObj.touchPoint,
      // "LanguageId": 1,// this.addTemplateObj.language.LanguageId,
      // "ChannelId": 1,// this.addTemplateObj.channel.ChannelId,
      // "CreatedBy": 1,
      // "CreatedOn": new Date(),
      // "Type": this.addTemplateObj.type.TemplateTypeName,
      // "Status": "Draft"
    }
    this.surveyService.updateTemplate(templateObj).then(rawData => {
      if (rawData && rawData.Status == 'Success') {

        window.location.reload();
        this.ref.close();
        //this.templatedata = rawData.Response;
        // this.router.navigate(['/create-survey-template', rawData.Response.TemplateId]);
      }
      else {
        this.messageService.add({ severity: 'error', summary: '', detail: rawData.Errors ? rawData.Errors[0].Message : '' });
        //alert('API STATUS IS FAILURE AT UPDATE TEMPLATE');
      }

    }).catch(e => {

      //alert('EXCEPTION AT updateTemplate API');
    });
  }
  //severity: info/success/warn/error
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () { document.getElementById('ClearMsg').click(); }, this.MessageTimeOut);
  }
  clearMessage() {
    this.msgs = [];
  }


  getMyStyles() {
    let myStyles = {
      'margin-top': this.showFirstScreen ? '-10px' : '0',
      'background-color': this.showFirstScreen ? '#fff' : '',

    };
    return myStyles;
  }
}
