import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as Rx from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CtlsSharedService {
  // private serviceUrl = this.sharedService.configuration.baseUrl;
  private serviceUrl = environment.baseUrl;
  // private http: HttpClient;
  //HttpBackend used to avoid call through interceptor.
  //You can use this service to avoid any ctls-intercepror call .
  //If any api you dont want to go through interceptor midleware,Then you can add in this service.
  constructor(private http: HttpClient) {
    // used to avoid call through interceptor.
   // this.http = new HttpClient(handler);
  }

  /* CHART */
  chartToggleParamBehaviousSubject = new Rx.BehaviorSubject('Month');
  toggleFilter: BehaviorSubject<any> = new BehaviorSubject<any>({});


  /**Service for save  modified columns
   * @param dataObj
   */
  SaveModifyColumns(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/GridColumn/SaveModifyColumn`,
      dataObj
    );
  }

  /**Service for get modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
          SubCategoryCode: dataObj.SubCategoryCode,
        },
      }
    );
  }
  getUserFAM() {
    return this.http
      .get<any>(`${this.serviceUrl}api/CTLSConfiguration/GetFAMDetails`)
      .toPromise();
  }

  GetLanguages(): Observable<any> {
    return this.http.get<any>(`${this.serviceUrl}api/TaskListing/GetLanguages`);
  }

  updateChartToggleParameter(chartViewBy: string) {
    this.chartToggleParamBehaviousSubject.next(chartViewBy);
  }
  setToggleFilterSub(data: any) {
    this.toggleFilter.next({
      toggleFilter: data.toggleFilter,
      toggleSearch: data.toggleSearch,
      field: data.field
    });
  }
  getToggleFilterSub() {
    return this.toggleFilter.asObservable();
  }

}
