import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as Rx from "rxjs";
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class CommonFilterService {

  private serviceUrl = this.sharedService.configuration.baseUrl;

  filterObj = {
    "StartDate": "",
    "EndDate": "",
    "PeriodType": "",
    "BACode": "",
    "DivCode": "",
    "FAMCode": "",
    "BrandName": "",  
    "Country": "",
    "PinnedField": "",
    "SubCategory":"",
    "SurveyID":"",
    "SurveyInstanceID":""
  }

  globalFilterBehaviousSubject = new Rx.BehaviorSubject<any>(this.filterObj);
  constructor(private http: HttpClient, private sharedService: SharedService) {
    //this.filterService.globalFilterBehaviousSubject.next(this.filterObj);
   }

  getAllData(surveySubCategory:string) {
    return this.http.get(this.serviceUrl + "api/Dashboard/GetMasterFilterData/" + surveySubCategory +"?" + Math.random())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        
        return data;
      });
  }

  saveUserFilter(filterObject, isDate: boolean, surveyType) {
    return this.http.post(this.serviceUrl + "api/Dashboard/AddUpdateUserFilter/" + surveyType + "?IsDateParam=" + isDate, filterObject)
      .toPromise()
      .then((res: any) => { return res });
  }

  getUserFilter(surveyType): Promise<any> {
    return new Promise((resolve, reject) => {
      let filterData = JSON.parse(window.sessionStorage.getItem("commonFilterObject"));
      
      if (!filterData) {
        this.http.get(this.serviceUrl + "api/Dashboard/GetUserFilter/" + surveyType + "?" + Math.random())
          .toPromise()
          .then((res: any) => {
            if (res != null && res.Status == "Success" && res.Response != null) {
                this.mapFilterObject(res.Response, true);
                sessionStorage.setItem("commonFilterObject", JSON.stringify(res.Response));
            }
            
            return resolve(res);
          }).catch(e => { 
            return reject({
          }) });
      }
      else {
        var filterObjectStringDate = sessionStorage.getItem("commonPeriodFilterObject");
        if (filterObjectStringDate != null) {
          var dateFilters = JSON.parse(filterObjectStringDate)
          this.filterObj.StartDate = dateFilters.StartDate;
          this.filterObj.EndDate = dateFilters.EndDate;
          this.filterObj.PeriodType = dateFilters.PeriodType;
        }
        this.mapFilterObject(filterData, false);
        return resolve(this.filterObj);
      }
    })
  }

  clearUserFilter(surveyType) {
    return this.http.post(this.serviceUrl + "api/Dashboard/ClearUserFilter/" + surveyType, null)
      .toPromise()
      .then((res: any) => { return res });
  }

  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  brandOriginal = [];
//   engineerAndTechnicianOriginal = [];
  touchPointsOriginal = [];
  influencersOriginal = [];
  distributorSegmentOriginal = [];
  distributorOriginal = [];
  industrySegmentOriginal = [];
  mapFilterObject(obj, includeDateFields) {
    if (includeDateFields) {
      this.filterObj.StartDate = obj.StartDate == null ? "" : obj.StartDate;
      this.filterObj.EndDate = obj.EndDate == null ? "" : obj.EndDate;
      this.filterObj.PeriodType = obj.PeriodType == null ? "" : obj.PeriodType;

      var dateObject = {
        "StartDate": obj.StartDate == null ? "" : obj.StartDate,
        "EndDate": obj.EndDate == null ? "" : obj.EndDate,
        "PeriodType": obj.PeriodType == null ? "" : obj.PeriodType,
      }
      sessionStorage.setItem("commonPeriodFilterObject", JSON.stringify(dateObject));
    }

    this.filterObj.BACode = obj.BACode == null ? "" : obj.BACode;
    this.filterObj.FAMCode = obj.FAMCode == null ? "" : obj.FAMCode;
    this.filterObj.DivCode = obj.DivCode == null ? "" : obj.DivCode;
    this.filterObj.Country = obj.Country == null ? "" : obj.Country;
    this.filterObj.BrandName = obj.BrandName == null ? "" : obj.BrandName;
    this.filterObj.PinnedField = obj.PinnedField == null ? "" : obj.PinnedField;
  }

  updateFilterObject(): any {
    var filterObjectString = sessionStorage.getItem("commonFilterObject");
    var periodFilterString = sessionStorage.getItem("commonPeriodFilterObject");

    if (filterObjectString != null) {
      var obj = JSON.parse(filterObjectString);
      this.mapFilterObject(obj, false);
    }
    if (periodFilterString != null) {
      var obj = JSON.parse(periodFilterString);
      this.filterObj.StartDate = obj.StartDate;
      this.filterObj.EndDate = obj.EndDate;
      this.filterObj.PeriodType = obj.PeriodType;
    }
    this.globalFilterBehaviousSubject.next(this.filterObj);
  }
}
