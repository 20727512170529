import { Pipe, PipeTransform } from '@angular/core';
//import * as moment from 'moment';

@Pipe({
  name: 'formatDateTime',
})
export class CtlsUpdateDatePipe implements PipeTransform {
  transform(date: any, args?: any): any {
    const res = new Date(date).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    return res;
  }
}

/* 
const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, '0');
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
} */