import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsAddEditSDAService {
  private serviceUrl = this.sharedService.configuration.baseUrl;

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }
  GetPriority(surveyType,SDAID): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskForSurveyFeedback/GetManualTaskFeedbackMasters?surveyType=${surveyType}&&CTLSSDAMasterID=${SDAID}`
    );
  }
  GetSurveyMappingDropdown(data): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/SDA/GetDropDownList`, data);
  }
  SaveSurveyMapping(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/SDA/SaveSDADetails`,
      dataObj
    );
  }
  validateSurveyMapping(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/SDA/SurveyMappingValidation`,
      dataObj
    );
  }
  deleteSurveyMapping(data): Observable<any> {
    return this.http.put<any>(
      `${this.serviceUrl}api/SDA/DeleteSurveyMapping`,
      data
    );
  }

  /**Service for getting GetSDADetailsID
  * @param id
  */
  GetSDADetailsID(id): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/SDA/GetSDADetailsById?CTLSSDAMasterID=${id}`
    );
  }
  GetOptions(value): Observable<any> {
    if (value === 1) {
      return this.http.get<any>(
        `${this.serviceUrl}api/TaskForSurveyFeedback/GetTransactionalSurveys`
      );
    } else if (value === 2) {
      return this.http.get<any>(
        `${this.serviceUrl}api/TaskForSurveyFeedback/GetRelationshipSurveys`
      );
    }
  }
}
