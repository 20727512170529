import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsClosureTemplateListingService {
  private serviceUrl = environment.baseUrl;
  filterObjForTransactional = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: false,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: true,
    Region: false,
    Zone: false,
    Branch: false,
    PinnedField: false,
    ActionType: false,
    surveySubCategoryCode: 'TRANS',
    screenCode: 'CTLS_CLOSURE_TEMPLATE_LISTING', //SELECTED SCREEN
    sessionStorageName: 'CTLS_TRANS_CLOSURE_TEMPLATE_LISTING',
    savedFilterSessionStorageName: 'CTLS_TRANS_CLOSURE_TEMPLATE_LISTING_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: ['Brand', 'Status', 'CreatedDate', 'Comments', 'FAM', 'Country'],
  };
  filterObjForRelational = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: false,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: true,
    Region: false,
    Zone: false,
    Branch: false,
    PinnedField: false,
    ActionType: false,
    surveySubCategoryCode: 'DIST',
    screenCode: 'CTLS_CLOSURE_TEMPLATE_LISTING', //SELECTED SCREEN
    sessionStorageName: 'CTLS_DIST_CLOSURE_TEMPLATE_LISTING',
    savedFilterSessionStorageName: 'CTLS_DIST_CLOSURE_TEMPLATE_LISTING_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: ['Brand', 'Status', 'CreatedDate', 'Comments', 'FAM', 'Country'],
  };

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  /**Service to get  template listing table data
   * @param value
   * @param filterObj
   */
  getTemplateListing(value, filterObj): Observable<any> {
    if (value === 1) {
      return this.http.post<any>(
        `${this.serviceUrl}api/TaskClosureTemplate/GetAllTransactionalTaskClosureTemplateDetails`,
        filterObj
      );
    } else if (value === 2) {
      return this.http.post<any>(
        `${this.serviceUrl}api/TaskClosureTemplate/GetAllRelationshipTaskClosureTemplateDetails`,
        filterObj
      );
    }
  }

  /**Service for get  modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
          SubCategoryCode: dataObj.SubCategoryCode,
        },
      }
    );
  }

  /**Service for deleting templates
   * @param dataObj
   */
  deleteClosureTemplate(dataObj): Observable<any> {
    return this.http.put<any>(
      `${this.serviceUrl}api/TaskClosureTemplate/RemoveClosureTemplate`,
      dataObj
    );
  }
  /**Service for saving status change
   * @param dataObj
   */
  saveStatus(dataObj): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskClosureTemplate/SetTemplateStatus`,
      {
        params: {
          cTLSTaskClosureTemplateID: dataObj.cTLSTaskClosureTemplateID,
          status: dataObj.status,
        },
      }
    );
  }
  /**
   * Get Brands
   */
  getBrands(): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskClosureTemplate/getBrands`
    );
  }
  /**
   * Get Brand Logos
   */
  getBrandDetails(brandID): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskClosureTemplate/GetBrandLogosAndHeaderImages`,
      {
        params: {
          brandID: brandID,
        },
      }
    );
  }

  /**
   * Save
   * @param filterObj
   */
  SaveTaskClosureTemplate(filterObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/TaskClosureTemplate/SaveTaskClosureTemplate`,
      filterObj
    );
  }
  /**
   * Edit
   * @param filterObj
   */
  EditTaskClosureTemplate(filterObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/TaskClosureTemplate/EditTaskClosureTemplate`,
      filterObj
    );
  }
  /**
   * Closure Template Preview
   * @param previewObj
   */
  GetClosureTemplateForPreview(previewObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/TaskClosureTemplate/PreviewClosureTemplate`,
      previewObj
    );
  }
  /**
   *  service for fetching latest parent template details
   * @param ParentTaskClosureTemplateID
   */
  GetRefreshedData(id): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskClosureTemplate/GetTaskClosureTemplateByID`,
      {
        params: {
          cTLSTaskClosureTemplateID: id,
        },
      }
    );
  }
  /**
   * Serivce for checking if a a default template already exist for a brand
   * @param saveObj
   */
  CheckDefaultExist(saveObj): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskClosureTemplate/SetDefault`,
      {
        params: {
          brandID: saveObj.brandID,
          subCategory: saveObj.subCategory,
          fAMID: saveObj.fAMID,
          bAID: saveObj.bAID,
          isGlobal: saveObj.isGlobal,
        },
      }
    );
  }


  /**
  * Autosave
  * @param filterObj
  */
  UpdateTemplateForAutoSave(filterObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/TaskClosureTemplate/UpdateTemplateForAutoSave`,
      filterObj
    );
  }
}
