import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import { Subscription } from 'rxjs';
import { ExportChartModel, SlideTextData } from 'src/app/shared/models/export-chart.model';
import { filter } from 'rxjs/operators';
import { surveyType } from 'src/app/shared/constants/shared.constants';
import * as html2canvas from "html2canvas"
import { HideOtherOpenDrilldownsService } from '../../../Modules/Distributor_Survey/Services/hide-other-open-drilldowns.service';
import { ChartDataService } from '../service/chart-data-service.service';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { widgetCodes } from './../../../shared/constants/shared.constants';
import { environment } from 'src/environments/environment';
const widgetCode: string = 'nssc';
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
@Component({
  selector: 'app-nss-dashboard',
  templateUrl: './nss-dashboard.component.html',
  styleUrls: ['./nss-dashboard.component.scss']
})
export class NssDashboardComponent implements OnInit {
  @Input() response: any;
  @Input() boolNSSLoader;
  @Input() errorData: boolean;
  chartInstance: any;
  @Output() showChartEvent = new EventEmitter<boolean>();
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  widgetCodesConst:any;
  
  @Output()
  nssClicked: EventEmitter<{ showCharts: boolean, chartsToShow: any[] }> = new EventEmitter();

  private widgetState: CHART_STATE_OPTIONS;
  private exportSubscription: Subscription;
  @ViewChild('pictureOf') pictureOf: ElementRef;
  tooltipContent: string = "<b>-5 to 0:</b> This range indicates negative sentiment<br>" +
                         "<b>>0 to 3:</b> This range indicates neutral sentiment<br>" +
                         "<b>>3 to 5:</b> This range indicates positive sentiment";

  detrWidth = 0;
  private imagepath = environment.imagepath;
  constructor(private sharedService: SharedService,   private hideOtherOpenDrilldownsService : HideOtherOpenDrilldownsService,
    private chartDataService: ChartDataService) {
    this.widgetState = CHART_STATE_OPTIONS.LOADING;
    this.widgetCodesConst = widgetCodes;

    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(update => update !== null && update.includes(widgetCode.toLowerCase()))).subscribe(val => {
      if (this.sharedService.acceptableChartState.includes(this.widgetState)) {
        setTimeout(() => {
          this.toCanvas();
        }, 200);
       // this.slideTextToShow();
      }
    });
  }

  closeWidget(){
    this.sharedService.widgetCloseSubject.next(this.widgetCodesConst.NSSC);
  }

  showNSSChart() {
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('')
    this.showChartEvent.emit(true);
  }

  onChartInstance(chart: Highcharts.Chart) {
    this.chartInstance = chart;
  }

  ngOnInit() {

    
    // var rem = (this.promoters + this.neutrals + this.detractors) % 100;
    // if (rem > 0) {
    //   this.detrWidth = this.detractors - rem;
    // }
    // else {
    //   this.detrWidth = this.detractors + rem;
    // }

    // if(this.detrWidth <= 12){
    //   this.detrWidth=12;
    // }

    // if (this.detrWidth >= 1 && this.detrWidth <= 12) {
    //   for (var i = 1, j = 11; j >= 0; i++, j--) {
    //     if (this.detrWidth == i) {
    //       this.detrWidth = this.detrWidth + j;
    //       this.neutrals = this.neutrals - j;
    //     }
    //   }
    // }

  }

  // unhide the chartlisting component & specify that all charts be shown
  //showTrendAnalysis
  // showNSSChart() {
  //   this.nssClicked.emit({ showCharts: true, chartsToShow: null });
  //   this.hideOtherOpenDrilldownsService.drillDownClickEvent('');
  //   // this.sharedService.changeWidgetDDLocation(this.getChartData());
  // }

  ngOnChanges() {
    if(this.response) {
      const average = parseFloat(this.response.NSSSCore);
    let sentimentLabel = '';
    if (average >= -5 && average < 0) {
      sentimentLabel = 'NEGATIVE';
    } else if (average >= 0 && average <= 3) {
      sentimentLabel = 'NEUTRAL';
    } else if (average > 3 && average <= 5) {
      sentimentLabel = 'POSITIVE';
    }
    this.chartOptions = {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '80%',
        backgroundColor: "#F1F1F1",
      },
      title: {
        text: ''
      },
      tooltip: {
        enabled: false
      },
      credits: {
        enabled: false,
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
      },
      yAxis: {
        startOnTick: false,
        endOnTick: false,
        min: -5,
        max: 5,
        tickPositions: [-5, 5],
        tickPixelInterval: 72,
        tickPosition: 'outside',
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#00879b',
        tickLength: 20,
        tickWidth: 0,
        minorTickInterval: null,
        labels: {
          formatter: function () {
            if (this.value === average) {
              return average.toString();
            } else {
              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          distance: 20,
          style: {
            fontSize: '25px',
            // fontWeight:'bold'
            color: '#000000'
          }
        },
        lineWidth: 0,
        plotBands: [
          {
            from: -5,
            to: 5,
            thickness: 30,
            color: {
              linearGradient: {
                x1: 0,
                x2: 1,
                y1: 0,
                y2: 0
              },
              stops: [
                [0, '#C71026'],
                [0.5, '#FFAF00'],
                [1, '#43AF2A']
              ]
            }
          }
        ],
        plotLines: [
          {
            width:0,
            value:average,
            label: {
              text: (average === 5 || average === -5) ? '' :average.toString(),
              textAlign: 'center',
              style: {
                fontSize: '30px',
                textAnchor: 'middle',
                color: '#000000'
              },
              y: (average >= 0 && average < 1) ? -20 :(average >= 1 && average < 1.4) ? -20 : (average >= 1.4 && average < 2) ? -30 : (average >= 2 && average <= 3) ? -10 : (average > 3 && average <= 4) ? -5 : (average > 4 && average <= 4.9) ? -5 : average < 0 ? -10 : 0,
              x: (average === 0 || average === 0.1) ? 5 : (average > 0.1 && average < 0.7) ? 35 : (average >= 0.7 && average < 1) ? 60 : (average >= 1 && average < 1.4) ? 95 :(average >= 1.4 && average < 2) ? 130 : (average >= 2 && average <2.4) ? 180 : (average >= 2.4 && average <= 3) ? 220 : (average > 3 && average <= 4) ? 260 : (average > 4 && average <= 4.9) ? 270 : average < 0 ? -25 : 50,
            }
          }
        ]
      },
      series: [
        {
          name: 'Sentiment',
          type: 'gauge',
          data: [average],
          dataLabels: {
            formatter: function () {
              return sentimentLabel;
            },
            enabled: true,
            borderWidth: 0,
            color:
              (Highcharts.defaultOptions.title &&
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              '#000000',
            style: {
              fontSize: '28px',
              color: '#ffffff'
            }
          },
          dial: {
            radius: '75%',
            backgroundColor: '#054e5a',
            baseWidth: 10,
            baseLength: '0%',
            rearLength: '0%'
          },
          pivot: {
            backgroundColor: '#054e5a',
            radius: 4.5
          },
        }
      ]
    };
    }
    
  }
  ngOnDestroy() {
    this.exportSubscription.unsubscribe();
    //this.updateExportDetails(true);
  }

  neutralclass: string = "";
  promoterclass: string = "";
  detractorclass: string = "";
  promotersvalue: number;
  detractorsvalue: number;
  neutralsvalue: number;
  parentclass: string = "neutral";
  showPromoter: boolean = true;
  showDetractor: boolean = true;
  showNeutrals: boolean = true;
  promotersfinal: number;
  neutralsfinal: number;
  detractorsfinal: number;

  private toCanvas = () => {
    try {
      // if (this.pictureOf && this.pictureOf.nativeElement) {
      //   // html2canvas.Util.isTransparent = function(backgroundColor) {
      //   //   return (backgroundColor === "transparent" || backgroundColor === "rgba(0, 0, 0, 0)" || backgroundColor === undefined);
      //   // };
      //   html2canvas(this.pictureOf.nativeElement, { foreignObjectRendering: true, useCORS: false, 
      //     background: '#005671', logging: false, removeContainer: true}).then((canvas) => {
      //       // let lmnt = document.createElement(canvas);

      //       let chartUpdate: ExportChartModel = new ExportChartModel();
      //       chartUpdate.UpdateTime = new Date();
      //       chartUpdate.ChartName = widgetCode.toLowerCase();
      //       chartUpdate.ChartImgId = widgetCode.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
      //       chartUpdate.CurrentState = this.widgetState;
      //       chartUpdate.ChartImgBase64 = canvas.toDataURL('image/png');
      //       this.sharedService.updateChartStatusSubject(chartUpdate);

      //       // document.removeChild(lmnt);
      //     });
      // }

      let chartUpdate: ExportChartModel = new ExportChartModel();
      chartUpdate.UpdateTime = new Date();
      chartUpdate.ChartName = widgetCode.toLowerCase();
      chartUpdate.ChartImgId = null;
      chartUpdate.CurrentState = this.widgetState;
      chartUpdate.ChartImgBase64 = 'this_does_not_have_to_be_uploaded_to_blob';
      this.sharedService.updateChartStatusSubject(chartUpdate);
    } catch (error) {
      console.log('html2canvas error as', error);
    }
  }

  // private determineWidgetState = () => {
  //   this.widgetState = this.npsScore !== null ? CHART_STATE_OPTIONS.SUCCESS : (this.errorData ? CHART_STATE_OPTIONS.ERROR : CHART_STATE_OPTIONS.NO_DATA);
  // }
  
  refreshWidget(widgetKey : string) {
    let refreshData = {
      refresh: true
    };
    if (widgetKey) {
      refreshData[widgetKey] = true;
    }
    this.chartDataService.setRefreshWidgetData(refreshData);
  }
}

