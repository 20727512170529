import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CtlsVocFilterHeaderService,
  CtlsLostbusinessCustomerService,
  CtlsSharedService,
} from '../../ctls-services';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TreeTableModule } from 'primeng/treetable';
import { TreeModule } from 'primeng/tree';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CtlsVocFilterHeaderComponent } from './ctls-voc-filter-header.component';
import { CtlsVocFilterDatePipe } from '../../ctls-pipes/ctls-voc-filter-date.pipe';
import { CtlsVocUpdateDatePipe } from '../../ctls-pipes/ctls-voc-update-date.pipe';

@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    CalendarModule,
    ScrollPanelModule,
    MultiSelectModule,
    OverlayPanelModule,
    TreeTableModule,
    TreeModule,
    FormsModule,
    AutoCompleteModule
  ],
  declarations: [CtlsVocFilterHeaderComponent, CtlsVocFilterDatePipe, CtlsVocUpdateDatePipe],
  exports: [CtlsVocFilterHeaderComponent],
  providers: [
    CtlsVocFilterHeaderService,
    CtlsSharedService,
    CtlsLostbusinessCustomerService,
  ],
})
export class CtlsVocFilterHeaderModule {}
