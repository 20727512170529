import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CtlsFilterDatePipe } from '../../ctls-pipes';
import {
  CtlsFilterHeaderService,
  CtlsLostbusinessCustomerService,
  CtlsSharedService,
} from '../../ctls-services';
import { CtlsFilterHeaderComponent } from './ctls-filter-header.component';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TreeTableModule } from 'primeng/treetable';
import { TreeModule } from 'primeng/tree';
import { FormsModule } from '@angular/forms';
import { CtlsUpdateDatePipe } from '../../ctls-pipes/ctls-update-date.pipe';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    CalendarModule,
    ScrollPanelModule,
    MultiSelectModule,
    OverlayPanelModule,
    TreeTableModule,
    TreeModule,
    FormsModule,
    AutoCompleteModule
  ],
  declarations: [CtlsFilterHeaderComponent, CtlsFilterDatePipe, CtlsUpdateDatePipe],
  exports: [CtlsFilterHeaderComponent],
  providers: [
    CtlsFilterHeaderService,
    CtlsSharedService,
    CtlsLostbusinessCustomerService,
  ],
})
export class CtlsFilterHeaderModule {}
