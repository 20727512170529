import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[dynamicLeftPos]'
})
export class DynamicLeftPositionPinnedFilter {

    constructor(private el: ElementRef) { }

    @HostListener('click', ['$event.target']) onClick() {
        var sec = <HTMLElement>document.getElementById("pinfilterSec");
        var clickedEle = <HTMLElement>this.el.nativeElement;
        if (sec != null) {
            var width = "100px";
            if (clickedEle != null) {
                width = (clickedEle.offsetLeft).toString() + "px"
            }
            sec.style.left = width;
        }
    }
}