import { FormGroup, ValidatorFn } from "@angular/forms";

export class CONSTANTS {
  public static DivisionMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Division Management',
    strSpan3: '',
    strTitle: 'Division',
    url: 'falcon/setup/division-master',
    permissionConstant: 'FALCON_DIVISION',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static CountryMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Country Management',
    strSpan3: '',
    strTitle: 'Country',
    url: 'falcon/setup/country-master',
    permissionConstant: 'FALCON_COUNTRY',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static RegionMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Region Management',
    strSpan3: '',
    strTitle: 'Region',
    url: 'falcon/setup/region-master',
    permissionConstant: 'FALCON_REGION',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static BranchMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Branch Management',
    strSpan3: '',
    strTitle: 'Branch',
    url: 'falcon/setup/branch-master',
    permissionConstant: 'FALCON_BRANCH',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static BusinessAreaMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Business Area Management',
    strSpan3: '',
    strTitle: 'Business Area',
    url: 'falcon/setup/business-area-master',
    permissionConstant: 'FALCON_BUSINESS_AREA',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static BusinessLineMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Business Line Management',
    strSpan3: '',
    strTitle: 'Business Line',
    url: 'falcon/setup/business-line-master',
    permissionConstant: 'FALCON_BUSINESS_LINE',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static TouchpointMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Touchpoint Management',
    strSpan3: '',
    strTitle: 'Touchpoint',
    url: 'falcon/setup/touchpoint-master',
    permissionConstant: 'FALCON_TOUCHPOINT',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static InfluencerMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Influencer Management',
    strSpan3: '',
    strTitle: 'Influencer',
    url: 'falcon/setup/influence-level-master',
    permissionConstant: 'FALCON_INFLUENCER',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static TimezoneMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Timezone Management',
    strSpan3: '',
    strTitle: 'Timezone',
    url: 'falcon/setup/timezone-master',
    permissionConstant: 'FALCON_TIMEZONE',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static SalesChannelMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Sales Channel Management',
    strSpan3: '',
    strTitle: 'Sales Channel',
    url: 'falcon/setup/sales-channel-master',
    permissionConstant: 'FALCON_SALESCHANNEL',
    managePermissionConstant: 'MANAGE_SALESCHANNEL_MASTER',
    viewPermissionConstant: 'VIEW_SALESCHANNEL_MASTER'
  };

  public static SalesChannelMapping = {
    strSpan1: 'Master Data',
    strSpan2: 'Sales Channel Mapping',
    strSpan3: '',
    strTitle: 'Sales Channel Mapping',
    url: 'falcon/setup/sales-channel-mapping',
    permissionConstant: 'FALCON_SALESCHANNEL',
    managePermissionConstant: 'MANAGE_SALESCHANNEL_MASTER',
    viewPermissionConstant: 'VIEW_SALESCHANNEL_MASTER'
  };

  public static ServiceTypeMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Service Type Management',
    strSpan3: '',
    strTitle: 'Service Type',
    url: 'falcon/setup/service-type-master',
    permissionConstant: 'FALCON_SERVICETYPE',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static LanguageMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Language Management',
    strSpan3: '',
    strTitle: 'Language',
    url: 'falcon/setup/language-master',
    permissionConstant: 'FALCON_LANGUAGE',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static ZoneMaster = {
    strSpan1: 'Master Data',
    strSpan2: 'Zone Management',
    strSpan3: '',
    strTitle: 'Zone',
    url: 'falcon/setup/zone-master',
    permissionConstant: 'FALCON_ZONE',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };

  public static InfluenceLevelMapping = {
    strSpan1: 'Master Data',
    strSpan2: 'Influencer Mapping',
    strSpan3: '',
    strTitle: 'Influencer Mapping',
    url: 'falcon/setup/influence-level-mapping',
    permissionConstant: 'FALCON_INFLUENCE_LEVEL_MAPPING',
    managePermissionConstant: 'MANAGE_DIVISION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'

  };
  
  public static SalutationMaster = {
    strSpan1: 'Salutation Data',
    strSpan2: 'Salutation Management',
    strSpan3: '',
    strTitle: 'Salutation',
    url: 'falcon/setup/salutation-master',
    permissionConstant: 'FALCON_SALUTATION',
    managePermissionConstant: 'MANAGE_SALUTATION_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };
  
  public static SalutationGenderMapping = {
    strSpan1: 'Salutation Data',
    strSpan2: 'Salutation Gender Mapping',
    strSpan3: '',
    strTitle: 'Gender Mapping',
    url: 'falcon/setup/salutation-gender-mapping',
    permissionConstant: 'FALCON_SALUTATION_GENDER_MAPPING',
    managePermissionConstant: 'MANAGE_GENDER_MASTER',
    viewPermissionConstant: 'MSTR_MASTER'
  };
  public static MarketSubsector = {
    strSpan1: 'Market Sub Segment',
    strSpan2: 'Market Sub Segment Management',
    strSpan3: '',
    strTitle: 'Market Sub Segment',
    url: 'falcon/setup/market-subsegment-master',
    permissionConstant: 'FALCON_MARKET_SUBSECTOR',
    managePermissionConstant: 'MANAGE_MARKETSUBSECTOR_MASTER',
    viewPermissionConstant: 'VIEW_MARKETSUBSECTOR'
  };
  public static postCodeGrouping = {
    strSpan1: 'Postcode Grouping',
    strSpan2: 'Postcode Grouping',
    strSpan3: '',
    strTitle: 'Postcode Grouping',
    url: 'falcon/setup/postcode-grouping',
    permissionConstant: 'FALCON_POSTCODE_GROUPING',
    managePermissionConstant: 'MANAGE_POSTCODE_MASTER',
    // managePermissionConstant: 'MANAGE_MARKETSUBSECTOR_MASTER',
    viewPermissionConstant: 'VIEW_POSTCODEGROUPING'
  };
  public static FileEvents = {
    uploadFile: 'UPLOAD FILE',
    exportData: 'EXPORT DATA'
  };
  public static postCodeErrorCodes = {
    'Branch_IS_INVALID_Excel': 'Branch is missing or incorrect',
    'Zone_IS_INVALID_Exce': 'Zone is missing or incorrect',
    'PostCode_IS_INVALID_Excel': 'Postcode is missing or incorrect',
    'PostCode_IS_INVALID': 'Postcode is missing or incorrect',
    'BA_IS_INVALID': 'BA is missing or incorrect',
    'FAM_IS_INVALID': 'FAM is missing or incorrect',
    'Brand_IS_INVALID': 'Brand is missing or incorrect',
    'Branch_IS_INVALID': 'Branch is missing or incorrect',
    'Division_IS_INVALID': 'Division is missing or incorrect',
    'Zone_IS_INVALID': 'Zone is missing or incorrect',
    'Duplicate_value_found': 'Duplicate value found',
    'DifferentGroupName_value_found': 'Different group name',
    'DifferentCombowithSameGroup_value_found': 'Different combination with same group name',
    'Combination_Exists': 'Combination already exists'
  }
}

export const DependentFieldValidator: ValidatorFn = (fg: FormGroup) => {
  const FAM = fg.get('FamID').value;
  const BA = fg.get('BaId').value;
  const Brand = fg.get('BrandId').value;
  // const MarketSubsector = fg.get('MarketSubsectorID').value;
  return (FAM && BA && Brand) || (!FAM && !BA && !Brand)
    ? null
    : { dependentField: true };
};
export const RequireAtleastOneNeededField: ValidatorFn = (fg: FormGroup) => {
  let dataExist = [false],
  langIndex = 0;
  const language = fg.get('Language').value;
  if (language && language.length) {
    for (let langData of language) {
      dataExist[langIndex] = false;
      if (langData.Gender && langData.Gender.length) {
        for (let genderIndex in langData.Gender) {
          if ((langData.Gender[genderIndex].FormIsPlaceHolderNeeded1) || (langData.Gender[genderIndex].FormIsPlaceHolderNeeded2) ||
            (langData.Gender[genderIndex].FormIsPlaceHolderNeeded3) || (langData.Gender[genderIndex].FormIsPlaceHolderNeeded4)
            || (langData.Gender[genderIndex].FormIsPlaceHolderNeeded5)) {
            dataExist[langIndex] = true;
          }
        }
      }
      langIndex += 1;
    }
  }
  
  return (dataExist.some(x => !!x))
    ? null : { dependentField: true };
};