import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ctlsCommaSeparatedText'
})
export class CtlsCommaSeparatedTextPipe implements PipeTransform {
  delimiter;

  transform(value: any, args: any): any {
    this.delimiter = args;
    if (value && Array.isArray(value)) {
      return value.join(', ')
    }
    if (value && typeof value != 'number') {
      if (value.includes(this.delimiter)) return value.split(this.delimiter).join(', ');
      return value
    }
    else return value;
  }

}
