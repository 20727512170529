export class CONSTANTS {
  public static SurveyConfiguration = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'INITIATION',
    strSpan3: '',
    strTitle: 'Survey Configuration',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Enable / Disable',
    url: 'ctls/setup/enable-disable',
    permissionConstant: 'CTLS_ENABLE_DISABLE',
  };
  public static TaskSurveyFeedback = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'TASK MANAGEMENT',
    strSpan3: '',
    strTitle: 'Task Survey Feedback',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Task For Survey Feedback',
    url: 'ctls/task-feedback/survey-listing',
    permissionConstant: 'CTLS_SURVEY_FEEDBACK_LISTING',
  };
  public static Distributors = {
    strSpan1: '360 View',
    // strSpan2: 'TASK MANAGEMENT',
    strSpan3: '',
    // strTitle: 'Task Survey Feedback',
    // strRadioLabel1: 'Transactional Survey',
    // strRadioLabel2: 'Relationship Survey',
    // strDropdown1: 'All',
    // strDropdown2: 'All',
    strCtlsTitle: 'Distributors',
    url: 'ctls/360/distributors',
    permissionConstant: 'CTLS_CUSTOMER_360',
  };
  public static AssigneeMapping = {
    strSpan1: 'Close The Loop',
    strSpan2: 'Assignee Mapping',
    strAssigneeTitle: 'Assignee Mapping',
    url: 'ctls/setup/assignee-mapping',
    permissionConstant: 'CTLS_ASSIGNEE_MAPPING',
  };
  public static CtlsDashboard = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'TASK INSIGHT',
    strSpan3: '',
    strTitle: 'TASK INSIGHT',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strFilterRadioLabel1: 'Criteria',
    strFilterRadioLabel2: 'Scenario',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'TASK INSIGHT',
    url: 'ctls/dashboard',
    permissionConstant: 'CTLS_DASHBOARD', 
  }
  public static CtlsTrendAnalysis = {
    breadcrumb_1: 'CLOSE THE LOOP',
    title: 'TREND ANALYSIS',
    url: 'ctls/trend-analysis',
    permissionConstant: 'CTLS_TREND_ANALYSIS', 
  }
  
  public static CustomerMappingConfiguration = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'INITIATION',
    strSpan3: '',
    title: 'Customer Mapping',
    tabLabel1: 'Company Account',
    tabLabel2: 'Standard Account (SA)',
    tabLabel3: 'Global Key Account(GKA)',
    strDropdown1: 'All',
    strDropdown2: 'All',
    url: 'ctls/configurations/customer-mapping',
    permissionConstant: 'CTLS_CUSTOMER_MAPPING',
  };
  public static CustomerMapping = {
    strSpan1: 'Close The Loop',
    strSpan2: '',
    strSpan3: 'Customer Mapping',
    strCustomerTitle: 'Customer Mapping',
    url: 'ctls/configurations/customer-mapping',
    permissionConstant: 'CTLS_CUSTOMER_MAPPING',
  };
  public static dashboard = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: '360 VIEW',
    strSpan3: '',
    strTitle: '360 View',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: '360 View',
    url: 'ctls/dashboard',
    permissionConstant: 'CTLS_SURVEY_FEEDBACK_LISTING',
  };

  public static dashboardCustomer = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: '360 VIEW',
    strSpan3: '',
    strTitle: '360 View',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: '360 View',
    url: 'ctls/360/customers',
    permissionConstant: 'CTLS_CUSTOMER_360',
  };
  public static dashboardDistributor = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: '360 VIEW',
    strSpan3: '',
    strTitle: '360 View',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: '360 View',
    url: 'ctls/360/distributors',
    permissionConstant: 'CTLS_DISTRIBUTOR_360',
  };
  public static RuleAssignment = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'Rule Assignment',
    strSpan3: '',
    strTitle: 'Rule Assignment',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Rule Assignment',
    url: 'ctls/setup/rule-assignment',
    permissionConstant: 'CTLS_RULE_ASSIGNMENT',
  };
  public static TaskListing = {
    strSpan1: 'Close The Loop',
    strSpan2: 'Task Management',
    strSpan3: '',
    strCtlsTitle: 'Task Listing',
    url: 'ctls/task-feedback/task-listing/list',
    permissionConstant: 'CTLS_TASK_LISTING',
  };
  public static VocListing = {
    strSpan1: 'Close The Loop',
    strSpan2: 'Task Management',
    strSpan3: '',
    strRadioLabel1: 'Customers',
    strRadioLabel2: 'Distributors',
    strFilterRadioLabel1: 'Customers',
    strFilterRadioLabel2: 'Distributors',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Voice of Customer',
    url: 'ctls/task-feedback/voc-listing',
    permissionConstant: 'CTLS_TASK_LISTING',
  };
  public static Customers = {
    strSpan1: '360 View',
    // strSpan2: 'Task Management',
    strSpan3: '',
    strCtlsTitle: 'Customers',
    url: 'ctls/360/customers',
    permissionConstant: 'CTLS_CUSTOMER_360',
  };
  public static TaskClosure = {
    strSpan1: 'Close The Loop',
    strSpan2: 'Task Closure Template',
    strSpan3: '',
    strTitle: 'Task Closure Template',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
  };
  public static TaskClosureTemplateListing = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: '',
    strSpan3: '',
    strTitle: 'Survey Configuration',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strCtlsTitle: 'Closure Template Listing',
    url: 'ctls/setup/task-closure-listing',
    permissionConstant: 'CTLS_CLOSURE_TEMPLATE_LISTING',
  };
  public static StatusCards = {
    open: 'Open',
    inprogress: 'Inprogress',
    hold: 'Hold',
    resolved: 'Resolved',
    closed: 'Closed',
    reopened: 'Reopened',
  };
  public static StatusCardsVoc = {
    unassigned: 'Unassigned',
    open: 'Open',
    inprogress: 'In Progress',
    hold: 'Hold',
    resolved: 'Resolved',
    closed: 'Closed',
    reopened: 'Reopened',
  };
  public static SDAList = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'SDA Setup',
    strSpan3: '',
    strTitle: 'SDA Setup',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Service Delivery Agreement (SDA) List',
    url: 'ctls/setup/sda-listing',
    permissionConstant: 'CTLS_SDA_LISTING',
  };
  public static TPList = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'Touchpoint Task Configuration',
    strSpan3: '',
    strTitle: 'Touchpoint Task Configuration',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Touchpoint Task Configuration List',
    shortTerm:"TP Config",
    url: 'ctls/setup/auto-task-config',
    tabUrl:['auto-task-config'],
    tabName:"Touchpoint Task",
    permissionConstant: 'CTLS_SDA_LISTING', //CTLS_TP_LISTING
    grid:'CTLS_TP_CONFIG'
  };

  public static customerList = {
    strSpan1: '360 View',
    strSpan2: 'Customers',
    strSpan3: '',
    strTitle: '360 View',
    // strRadioLabel1: 'Transactional Survey',
    // strRadioLabel2: 'Relationship Survey',
    // strDropdown1: 'All',
    // strDropdown2: 'All',
    // strCtlsTitle: 'Service Delivery Agreement (SDA) List',
    url: 'ctls/360/customers',
    permissionConstant: 'CTLS_CUSTOMER_360',
  };

  public static AddSDA = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'Add SDA',
    strSpan3: '',
    strTitle: 'ADD SDA',
    strRadioLabel1: 'Timeline',
    strRadioLabel2: 'Mapping Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Add SDA',
    url: 'ctls/setup/add-sda',
    permissionConstant: 'CTLS_ADD_SDA',
  };
  public static NotificationMgmt = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'Notification Management',
    strSpan3: '',
    strTitle: 'Notification Management',
    strCtlsTitle: 'Configure Notification',
    url: 'ctls/configurations/notification-mgmt',
    permissionConstant: 'CTLS_NOTIFICATION',
  }
  public static UsageMgmt = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'Usage Management',
    strSpan3: '',
    strTitle: 'Usage Management',
    strCtlsTitle: 'Configure Notification',
    url: 'ctls/configurations/usage-mgmt',
    permissionConstant: 'CTLS_USAGE',
  }

  public static RuleCriteriaList = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'RULE CRITERIA MASTER',
    strSpan3: '',
    strTitle: 'RULE CRITERIA MASTER',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Rule Criteria Master',
    url: 'ctls/masters/rule-criteria-list',
    permissionConstant: 'CTLS_RULE_CRITERIA_LIST',
  };
  public static PriorityMaster = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'PRIORITY MASTER',
    strSpan3: '',
    strTitle: 'PRIORITY MASTER',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Priority Master',
    url: 'ctls/masters/priority-master',
    permissionConstant: 'CTLS_PRIORITY_MASTER',
  };
  public static CategoryMaster = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'CATEGORY MASTER',
    strSpan3: '',
    strTitle: 'CATEGORY MASTER',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Category Master',
    url: 'ctls/masters/category-master',
    permissionConstant: 'CTLS_CATEGORY_MASTER',
  };
  public static RecommendedActionListing = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'Recommended Action Master',
    strSpan3: '',
    strTitle: 'Recommended Action Master',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Recommended Action Master',
    url: 'ctls/masters/recommended-action',
    permissionConstant: 'CTLS_RECOMMENDEDACTION_MASTER',
  };
  public static RuleSetListing = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'Rule Setup',
    strSpan3: '',
    strTitle: '',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Rule Setup',
    url: 'ctls/setup/ruleset-listing',
    permissionConstant: 'CTLS_RULE_SETUP',
  }

  public static CreateRule = {
    strRadioLabel1: 'General',
    strRadioLabel2: 'Questions',
  };
  public static UnavailabilityList = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'Unavailability Management',
    strSpan3: '',
    strTitle: 'Unavailability Management',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Unavailability Management',
    url: 'ctls/configurations/unavailability-list',
    permissionConstant: 'CTLS_UNAVAILABILITY_LISTING',
  };
  public static UsageManagement = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'USAGE MANAGEMENT',
    strSpan3: '',
    strTitle: 'Usage Management',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Usage Management',
    url: 'ctls/configurations/usage-management',
    permissionConstant: 'CTLS_USAGE_MANAGEMENT',
  };
  public static AddRuleSet = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'RULE MANAGEMENT',
    strSpan3: '',
    strTitle: '',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Create Rule',
    url: 'ctls/setup/ruleset-listing',
    permissionConstant: 'CTLS_SURVEY_FEEDBACK_LISTING',
  };
  public static DesignationMaster = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'DESIGNATION MASTER',
    strSpan3: '',
    strTitle: 'DESIGNATION MASTER',
    strRadioLabel1: 'Transactional Survey',
    strRadioLabel2: 'Relationship Survey',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Designation Master',
    url: 'ctls/masters/designation-master',
    permissionConstant: 'CTLS_DESIGNATION_MASTER',
  };
  public static OperationalHoursDays = {
    strSpan1: 'CLOSE THE LOOP',
    strSpan2: 'OPERATIONAL HOURS/DAYS',
    strSpan3: '',
    strTitle: 'Operational Hours/Days',
    strDropdown1: 'All',
    strDropdown2: 'All',
    strCtlsTitle: 'Operational Hours/Days',
    url: 'ctls/setup/operational-hours-days',
    permissionConstant: 'CTLS_OPERATIONAL_HOURS_DAYS',
  };
  public static colSurveySentViewAll=[
    {field: "SurveyID", header: "Survey ID"},
    {field: "SurveyName", header: "Survey Name"},
    {field: "Instance", header: "Instance"},
    {field: "ContactName", header: "Contact Name"},
    {field: "EmailID", header: "Email ID"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"},
    {field: "SentOn", header: "Sent Date"},
    {field: "OrderNo", header: "Order No."},
    {field: "Disposition", header: "Disposition"},
  ];
  public static colSurveySentTS=[
    {field: "SurveyID", header: "Survey ID"},
    {field: "SurveyName", header: "Survey Name"},
    {field: "ContactName", header: "Contact Name"},
    {field: "EmailID", header: "Email ID"},
    {field: "SentOn", header: "Sent Date"},
    {field: "OrderNo", header: "Order No."},
    {field: "Disposition", header: "Disposition"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"},
  ];
  public static colSurveySentRS=[
    {field: "SurveyID", header: "Survey ID"},
    {field: "SurveyName", header: "Survey Name"},
    {field: "Instance", header: "Instance"},
    {field: "ContactName", header: "Contact Name"},
    {field: "EmailID", header: "Email ID"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"},
    {field: "SentOn", header: "Sent Date"},
    {field: "Disposition", header: "Disposition"},   
  ];
  public static colSurveyRespondedViewAll=[
    {field: "SurveyID", header: "Survey ID"},
    {field: "SurveyName", header: "Survey Name"},
    {field: "Instance", header: "Instance"},
    {field: "ContactName", header: "Contact Name"},
    {field: "EmailID", header: "Email ID"},
    {field: "NPS", header: "NPS"},
    {field: "SentOn", header: "Sent Date"},
    {field: "FeedbackSubmitDate", header: "Feedback Submit Date"},
    {field: "OrderNo", header: "Order No."},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"},  
  ];
  public static colSurveyRespondedTS=[
    {field: "SurveyID", header: "Survey ID"},
    {field: "SurveyName", header: "Survey Name"},
    {field: "ContactName", header: "Contact Name"},
    {field: "EmailID", header: "Email ID"},
    {field: "NPS", header: "NPS"},
    {field: "SentOn", header: "Sent Date"},
    {field: "FeedbackSubmitDate", header: "Feedback Submit Date"},
    {field: "OrderNo", header: "Order No."},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"},
  ];
  public static colSurveyRespondedRS=[
    {field: "SurveyID", header: "Survey ID"},
    {field: "SurveyName", header: "Survey Name"},
    {field: "Instance", header: "Instance"},
    {field: "ContactName", header: "Contact Name"},
    {field: "EmailID", header: "Email ID"},
    {field: "NPS", header: "NPS"},
    {field: "SentOn", header: "Sent Date"},
    {field: "FeedbackSubmitDate", header: "Feedback Submit Date"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"},  
  ];
  public static colSurveyRespondedTaskCreatedTS=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "Status", header: "Status"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"}, 
  ];
  public static colSurveyRespondedNoTaskCreatedTS=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    // {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    // {field: "AssigneeName", header: "Assignee Name"},
    // {field: "Status", header: "Status"},
    {field: "CreatedDate", header: "Created Date"},
    // {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"}, 
  ];
  public static colSurveyRespondedTaskCreatedRS=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "Status", header: "Status"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"}, 
  ];
  public static colSurveyRespondedNoTaskCreatedRS=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    // {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    // {field: "AssigneeName", header: "Assignee Name"},
    // {field: "Status", header: "Status"},
    {field: "CreatedDate", header: "Created Date"},
    // {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"}, 
  ];
  public static colTaskCreatedViewAll=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "Status", header: "Status"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"},
    {field: "RecievedBy", header: "Received By" },
    {field: "RecievedDate", header: "Received Date" },
    {field: "RecievedMode", header: "Received Mode" },
    {field: "VOCtype", header: "VOC type" }
  ];
  public static colTaskCreatedTS=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "Status", header: "Status"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"}, 
  ];
  public static colTaskCreatedRS=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "Status", header: "Status"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"}, 
  ];
  public static colTaskClosedViewAll=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "ClosedDate", header: "Closed Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"},
    {field: "RecievedBy", header: "Received By" },
    {field: "RecievedDate", header: "Received Date" },
    {field: "RecievedMode", header: "Received Mode" },
    {field: "VOCtype", header: "VOC type" }
  ];
  public static colTaskClosedTS=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "ClosedDate", header: "Closed Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"}, 
  ];
  public static colTaskClosedRS=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "ClosedDate", header: "Closed Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"}, 
  ];
  public static colTaskResolvedViewAll=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "ResolvedDate", header: "Resolved Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"},
    {field: "RecievedBy", header: "Received By" },
    {field: "RecievedDate", header: "Received Date" },
    {field: "RecievedMode", header: "Received Mode" },
    {field: "VOCtype", header: "VOC type" }
  ];
  public static colTaskResolvedTS=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "ResolvedDate", header: "Resolved Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"}, 
  ];
  public static colTaskResolvedRS=[
    {field: "TaskID", header: "Task ID"},
    {field: "SurveyID", header: "Survey ID"},
    {field: "Task", header: "Task "},
    {field: "TaskPriority", header: "Priority"},
    {field: "ContactName", header: "Contact Name"},
    {field: "AssigneeName", header: "Assignee Name"},
    {field: "CreatedDate", header: "Created Date"},
    {field: "ResolvedDate", header: "Resolved Date"},
    {field: "Category", header: "Category"},
    {field: "NPS", header: "NPS"},
    {field: "BA", header: "BA"},
    {field: "FAM", header: "FAM"},
    {field: "DIV", header: "DIV"}, 
  ];

  public static colVocTable = [   //Generic table for VOC
  { field: "TaskID", header: "Task ID" },
  { field: "AssigneeName", header: "Assignee Name" },
  { field: "RecievedMode", header: "Received Mode" },
  { field: "BA", header: "BA" },
  { field: "FAM", header: "FAM" },
  { field: "Status", header: "Status" },
  { field: "ContactName", header: "Contact Name" },
  { field: "RecievedBy", header: "Received By" },
  { field: "Description", header: "Description" },
  ];

  public static colAccountListTable = [   //Generic table for Accounts list
  { field: "Name", header: "Name" },
  { field: "EntityId", header: "Entity ID" },
   ];
  
  public static colVocTableTaskCreated = [   //Generic table for VOC for Tasks created
    { field: "TaskID", header: "Task ID" },
    { field: "Description", header: "Description" },
    { field: "AssigneeName", header: "Assignee Name" },
    { field: "RecievedMode", header: "Received Mode" },
    { field: "BA", header: "BA" },
    { field: "FAM", header: "FAM" },
    { field: "CreatedDate", header: "Created Date"},
    { field: "Status", header: "Status" },
  ];
  public static colVocTableComplimentNoTaskCreated = [
    { field: "BA", header: "BA" },
    { field: "FAM", header: "FAM" },
    { field: "ContactName", header: "Complimented By" },
    { field: "RecievedBy", header: "Received By" },
    { field: "Description", header: "Description" },
  ];
  public static colVocTableComplaintNoTaskCreated = [
    { field: "BA", header: "BA" },
    { field: "FAM", header: "FAM" },
    { field: "ContactName", header: "Complained By" },
    { field: "RecievedBy", header: "Received By" },
    { field: "Description", header: "Description" },
  ];
  public static colVocTableEnquiryNoTaskCreated = [
    { field: "BA", header: "BA" },
    { field: "FAM", header: "FAM" },
    { field: "ContactName", header: "Enquired By" },
    { field: "RecievedBy", header: "Received By" },
    { field: "Description", header: "Description" },
  ];
  public static colVocTableOtherNoTaskCreated = [
    { field: "BA", header: "BA" },
    { field: "FAM", header: "FAM" },
    { field: "ContactName", header: "Contacted By" },
    { field: "RecievedBy", header: "Received By" },
    { field: "Description", header: "Description" },
  ];

  public static colVocCreatedTaskTable = [   //Generic table for VOC created tasks
    { field: "TaskID", header: "Task ID" },
    { field: "ContactName", header: "Contact Name" },
    { field: "AssigneeName", header: "Assignee Name" },
    { field: "Status", header: "Status" },
    { field: "CreatedDate", header: "Created Date" },
    // { field: "ResolvedDate", header: "Resolved Date" },
    // { field: "NPS", header: "NPS" },
    { field: "BA", header: "BA" },
    { field: "FAM", header: "FAM" },
    { field: "DIV", header: "DIV" },
    { field: "RecievedBy", header: "Received By" },
    { field: "RecievedDate", header: "Received Date" },
    { field: "RecievedMode", header: "Received Mode" },
    { field: "VOCtype", header: "VOC type" }
  ];

  public static colVocResolvedTaskTable = [   //Generic table for VOC resolved tasks
    { field: "TaskID", header: "Task ID" },
    { field: "ContactName", header: "Contact Name" },
    { field: "AssigneeName", header: "Assignee Name" },
    { field: "CreatedDate", header: "Created Date" },
    { field: "ResolvedDate", header: "Resolved Date" },
    // { field: "NPS", header: "NPS" },
    { field: "BA", header: "BA" },
    { field: "FAM", header: "FAM" },
    { field: "DIV", header: "DIV" },
    { field: "RecievedBy", header: "Received By" },
    { field: "RecievedDate", header: "Received Date" },
    { field: "RecievedMode", header: "Received Mode" },
    { field: "VOCtype", header: "VOC type" }
  ];

  public static colVocClosedTaskTable = [   //Generic table for VOC closed tasks
    { field: "TaskID", header: "Task ID" },
    { field: "ContactName", header: "Contact Name" },
    { field: "AssigneeName", header: "Assignee Name" },
    { field: "CreatedDate", header: "Created Date" },
    {field: "ClosedDate", header: "Closed Date"},
    // { field: "NPS", header: "NPS" },
    { field: "BA", header: "BA" },
    { field: "FAM", header: "FAM" },
    { field: "DIV", header: "DIV" },
    { field: "RecievedBy", header: "Received By" },
    { field: "RecievedDate", header: "Received Date" },
    { field: "RecievedMode", header: "Received Mode" },
    { field: "VOCtype", header: "VOC type" }
  ];
  public static taskType = {
    PROMOTER: 'Promoter',
    DETRACTOR: 'Detractor',
    NEUTRAL: 'Neutral',
    COMPLIMENT: 'Compliment',
    COMPLAIN: 'Complaint',
    ENQUIRY: 'Enquiry',
    OTHERS: 'Others',
    ACCOUNTSLIST: 'AccountsList'
  };
  public static entityType = {
    GLOBAL: 'Global',
    STANDARD: 'Standard',
    ENTITY: 'Entity'
  };
}
