import { CtlsBulkUpdatePopupComponent } from './presentation/ctls-bulk-update-popup/ctls-bulk-update-popup.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CtlsSharedModule } from '../../ctls-shared/ctls-shared.module';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CtlsTaskListingRoutingModule } from './ctls-task-listing-routing.module';
import { CheckboxModule } from 'primeng/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, DialogService } from 'primeng/api';
import { CtlsCreateEditTaskPopupComponent } from './presentation/ctls-create-edit-task-popup/ctls-create-edit-task-popup.component';
import { CtlsSpinnerModule } from '../../ctls-shared/ctls-components/ctls-spinner/ctls-spinner.module';
import { CtlsSpinnerService } from '../../ctls-shared/ctls-components/ctls-spinner/ctls-spinner.service';
import { TooltipModule } from 'primeng/tooltip';
import { PopupMessageService } from '../../ctls-shared/ctls-services/ctls-popup-message.service';
import { FileUploadModule } from 'primeng/fileupload';
import { CtlsViewSurveyDetailsComponent } from './presentation/ctls-view-survey-details/ctls-view-survey-details.component';
import { commonDirectiveModule } from '../../ctls-shared/ctls-directives/common-directive.module';
import { BlockUIModule } from 'primeng/blockui';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PrimengModule } from '../../../../primeng.module';
import { CreateTaskService } from '../../ctls-shared/ctls-services/ctls-create-task.service';

import { CtlsAddAssignComponent } from './presentation/ctls-add-assign/ctls-add-assign.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    CtlsTaskListingRoutingModule,
    CtlsSharedModule,
    DropdownModule,
    MessagesModule,
    TableModule,
    PaginatorModule,
    InputSwitchModule,
    DialogModule,
    MultiSelectModule,
    CheckboxModule,
    ConfirmDialogModule,
    RadioButtonModule,
    CtlsSpinnerModule,
    TooltipModule,
    BlockUIModule,
    ScrollPanelModule,
    FileUploadModule,
    commonDirectiveModule,
    OverlayPanelModule,
    PrimengModule,
    
  ],
  providers: [
    ConfirmationService,
    DialogService,
    CtlsSpinnerService,
    PopupMessageService,
    CreateTaskService,
  ],
  declarations: [CtlsTaskListingRoutingModule.components],
  entryComponents: [
    CtlsCreateEditTaskPopupComponent,
    CtlsBulkUpdatePopupComponent,
    CtlsViewSurveyDetailsComponent,
    CtlsAddAssignComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [CtlsViewSurveyDetailsComponent],
})
export class CtlsTaskListingModule {}
