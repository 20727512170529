import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FalconSessionStorageService {

  public sessionPermissionSet = new BehaviorSubject<any>('not Done');
  
  constructor() { }

  getSessionData(key) {
    let data = window.sessionStorage.getItem(key);
    return JSON.parse(data);
  }
  setSessionData(key: string, value: any) {
    let data = JSON.stringify(value);
    window.sessionStorage.setItem(key, data);
    return { key, value };
  }
  isSessionStorageNull() {
    return window.sessionStorage.length === 0;
  }
  removeSessionData(key) {
    window.sessionStorage.removeItem(key);
    return key;
  }

}
 