import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CtlsSpinnerService {
  private visible$ = new BehaviorSubject<boolean>(false);
  show() {
    this.visible$.next(true);
  }
  hide() {
    this.visible$.next(false);
  }
  isVisible(): Observable<boolean> {
    return this.visible$.asObservable();
  }
  public visibility: EventEmitter<boolean> = new EventEmitter();
  public notVisibility: EventEmitter<boolean> = new EventEmitter();
}
