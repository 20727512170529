import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HideOtherOpenDrilldownsService {
  private hideOtherDrillDown =  new Subject<any>();
  constructor() { }
  drillDownClickEvent(message:any){
    this.hideOtherDrillDown.next(message);
  }
  getDrillDownClickEvent():Observable<any>{
    return this.hideOtherDrillDown.asObservable();
  }
}
