import { convertActionBinding } from "@angular/compiler/src/compiler_util/expression_converter";

export class WidgetExportModel {
    isWidgetExportable: boolean;
    isVisible: boolean;
    isApplicable: boolean;
    widgetCode: string;
    isDisabled: boolean;

    constructor() {
        this.isVisible = false;
        this.isWidgetExportable = false;
        this.isApplicable = false;
        this.widgetCode = '';
        this.isDisabled = true;
    }

}
export class ExportConfigModel {
    private presentObjectNames: string[] = [];
    widgetCodeList = [];
    public createFields = (widgetCodes: string[] = []): void => {
        for (let i = 0; i < widgetCodes.length; i++) {
            this.createSetField(widgetCodes[i]);
        }
    }
    public createSetField = (fieldName: string, fieldValue: WidgetExportModel = new WidgetExportModel()): void => {
        fieldValue.widgetCode = fieldName.toLowerCase();
        // delete reference & create new one
        if(this[fieldName.toLowerCase()]) {
            delete this[fieldName.toLowerCase()];
            this[fieldName.toLowerCase()] = new WidgetExportModel();
            this[fieldName.toLowerCase()] = fieldValue;
        } else {
            this[fieldName.toLowerCase()] = fieldValue;
        }
        if (!this.presentObjectNames.includes(fieldName.toLowerCase()))
            this.presentObjectNames.push(fieldName.toLowerCase());
    }
    public getField = (fieldName: string): WidgetExportModel | undefined => {
        if ((this[fieldName] !== null || this[fieldName] !== undefined) && this[fieldName])
            return this[fieldName];
        else
            return undefined;
    }
    public getPresentObjectNames = (): string[] => {
        return this.presentObjectNames.map(i => i.toLowerCase());
    }
    public getPresentObjects = (): WidgetExportModel[] => {
        let result: WidgetExportModel[] = [];
        for (let i = 0; i < this.presentObjectNames.length; i++) {
            const item = this.presentObjectNames[i];
            result.push(this.getField(item));
        }
        return result;
    }
    public getExporReadyWidgetNames = (): string[] => {
        return this.getPresentObjects().filter(i => i.isVisible && !i.isDisabled).map(i => {
            return i.widgetCode.toLowerCase();
        });
    }
}
