import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { LostBusinessWindrilldown2, LostBusinessStateModel } from '../model/lost-business-lbcdrilldown';
import { LostBusinessDrilldownService } from '../service/lost-business-drilldown.service';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Paginator } from 'primeng/paginator';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-lbc-win-cnt-contact-drilldown',
  templateUrl: './lbc-win-cnt-contact-drilldown.component.html',
  styleUrls: ['./lbc-win-cnt-contact-drilldown.component.scss']
})
export class LbcWinCntContactDrilldownComponent implements OnInit {
  private imagepath = environment.imagepath;
  @Input()continuousCustomerCount:number;
  @Input() showContitext:any;
  @Input()lostComparisonDate:any;
  @ViewChild(Paginator) dt: Paginator;
  @Output() winContactdisplayChange = new EventEmitter();
  @Output('parameter') parameter: EventEmitter<any> = new EventEmitter<any>();
  @Output('Previous') Previous: EventEmitter<any> = new EventEmitter();
  @Input() isTrendDrilldown: boolean = false;
  drilldownListData: LostBusinessWindrilldown2[];
  divhide: boolean = true;
  cols: any[];
  totalRecords: number;
  loading: boolean = false;
  pageNo = 1;
  pageSize = 10;
  configRow: number[] = [10, 20, 30, 40, 50];
  sortObj: any = {
    'sortType': '',
    'sortField': ''
  };
  flag: any;
  successMsg: boolean = false;
  msgs: Message[] = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  apiResponseData: any;
  paginatorFirstSelector: any;
  paginatorLaststSelector: any;
  paginatorNextSelector: any;
  paginatorPrevSelector: any;
  showNoData: boolean = false;
  showErrorData: boolean = false;
  searchPlaceholder = 'Enter your text here';
  dataNotFoundMessage: any;
  paramValue: any;
  genericErrorMessage = 'An error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail;
  columnList = [];
  searchString: string = '';
  searchBy: string = '';
  famAccess: any;
  compSTdate: null;
  compEDdate: null;
  drilldownData: LostBusinessStateModel;

  constructor(private LostBusinessDrilldownService: LostBusinessDrilldownService
    , private messageService: MessageService
    , private sharedService: SharedService) {}


  ngOnInit() {
  }
  ngOnChanges() {
    if(this.isTrendDrilldown) {
      this.drilldownData = { ...this.LostBusinessDrilldownService.trendGetData() };
    }
    this.getSingleFAMCol();
    this.GetData();
  }
  GetData() {
    this.pageNo = 1;
    this.pageSize = 10;

    if(this.isTrendDrilldown) {
      let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
      month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
    } else {
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
    }

    // this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType,this.searchString, this.searchBy);
  }
  getSingleFAMColumnNameList() {
    this.columnList = [
      { name: 'Search All', code: null },
      { name: 'Contact Name', code: 'ContactName' },
      { name: 'Contact Email Address', code: 'ContactEmailID' },
      { name: 'Customer Name', code: 'CustomerName' },
      { name: 'Account Number', code: 'AccountNumber' },
      { name: 'Brand', code: 'BRAND'},
      { name: 'Latest Disposition', code: 'LastDisposition'},
      { name: 'NPS Rating', code: 'NPSRating'},
      { name: 'DIV', code: 'BusinessDivision'},
    ];
  }
  getMultipleFAMColumnNameList() {
    this.columnList = [
      { name: 'Search All', code: null },
      { name: 'Contact Name', code: 'ContactName' },
      { name: 'Contact Email Address ', code: 'ContactEmailID' },
      { name: 'Customer Name', code: 'CustomerName' },
      { name: 'Account Number', code: 'AccountNumber' },
      { name: 'Brand', code: 'BRAND'},
      { name: 'Latest Disposition', code: 'LastDisposition'},
      { name: 'NPS Rating', code: 'NPSRating'},
      { name: 'BA', code: 'BA' },
      { name: 'DIV', code: 'BusinessDivision'},
      { name: 'FAM', code: 'FAM' },
    ];
  }

  getMultipleFAMCol(){
    this.getMultipleFAMColumnNameList();
    this.cols = [
      { field: 'Contact', header: 'Contact Name' },
      { field: 'ContactEmail', header: 'Contact Email Address' },
      { field: 'CustomerName', header: 'Customer Name' },
      { field: 'AccountNo', header: 'Account Number' },
      { field: 'Brand', header: 'Brand' },
      { field: 'DispositionStatus', header: 'Latest Disposition' },
      { field: 'SurveySentDate', header: 'Sent Date '},
      { field: 'EventFileProcessedDate', header: 'Event File Process Date '},
      { field: 'SurveySubmitDate', header: 'Submit Date '},
      { field: 'RTIDate', header: 'RTI Date'},
      { field: 'NPSRating', header: 'NPS Rating '},
      { field: 'BACode', header: 'BA' },
      { field: 'DIVCode', header: 'DIV' },
      { field: 'FAMCode', header: 'FAM' },


    ];
  }
  getSingleFAMCol() {
    this.getSingleFAMColumnNameList();
    this.cols = [
      { field: 'Contact', header: 'Contact Name' },
      { field: 'ContactEmail', header: 'Contact Email Address' },
      { field: 'CustomerName', header: 'Customer Name' },
      { field: 'AccountNo', header: 'Account Number' },
      { field: 'Brand', header: 'Brand' },
      { field: 'DispositionStatus', header: 'Latest Disposition' },
      { field: 'SurveySentDate', header: 'Sent Date '},
      { field: 'EventFileProcessedDate', header: 'Event File Process Date '},
      { field: 'SurveySubmitDate', header: 'Submit Date '},
      { field: 'RTIDate', header: 'RTI Date'},
      { field: 'NPSRating', header: 'NPS Rating '},
      { field: 'DIVCode', header: 'DIV' },

    ];
  }
  onChange(event) {
    if (event.value != null) {
      this.searchBy = event.value.code;
    } else {
      this.searchBy = '';
    }
    // To change place holder of search textbox
    if (this.searchBy === 'ContactName') {
      this.searchPlaceholder = 'Search by Contact Name';
    } else if (this.searchBy === 'ContactEmailID') {
      this.searchPlaceholder = 'Search by Contact Email Address';
    } else if (this.searchBy === 'FAM') {
      this.searchPlaceholder = 'Search by FAM';
    } else if (this.searchBy === 'BA') {
      this.searchPlaceholder = 'Search by BA ';
    } else if (this.searchBy === 'BusinessDivision') {
      this.searchPlaceholder = 'Search by DIV';
    }  else if (this.searchBy === 'LastDisposition') {
      this.searchPlaceholder = 'Search by Latest Disposition';
    } else if (this.searchBy === 'NPSRating') {
      this.searchPlaceholder = 'Search by NPS Rating';
    } else if (this.searchBy === 'CustomerName') {
      this.searchPlaceholder = 'Search by Customer Name';
    } else if (this.searchBy === 'AccountNo') {
      this.searchPlaceholder = 'Search by Account Number';
    }else if (this.searchBy === 'BRAND') {
      this.searchPlaceholder = 'Search by Brand';
    }
     else {
      this.searchPlaceholder = 'Enter your text here';
    }
  }
  searchByFields(event) {
    if (event && event.which == '13' || event.which == '1') {
      if (this.searchString != null && this.searchString !== '') {
        this.dataNotFoundMessage = 'No matching search results.';
        //this.showNoData = true;
      }
      this.searchString = this.searchString;
      this.pageNo = 1;
      if(this.isTrendDrilldown) {
        let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
        month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
      } else {
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
      }
      this.dt.first = 0;
    }
  }
  resetSearchfilter() {
    this.searchString = '';
    this.pageNo = 1,
      this.pageSize = 10;
    if (this.isTrendDrilldown) {
      let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
        month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
    } else {
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
    }
    this.dt.first = 0;
  }
  paginationData(PageNo, PageSize, SortField, SortType, SearchString, SearchBy, IsWin?: boolean, YearDate?: string, MonthDate?: string) {
    this.loading = true;
    this.drilldownListData = [];
    this.drilldownListData.push({} as LostBusinessWindrilldown2);
    let queryParams = this.isTrendDrilldown ? {PageNo, PageSize, SortField, SortType, SearchString, SearchBy, IsWin, YearDate, MonthDate} : {PageNo, PageSize, SortField, SortType, SearchString, SearchBy};
    this.LostBusinessDrilldownService.getWincntContactList(queryParams, this.isTrendDrilldown).then(rawData => {
      //console.log(rawData.Response);
      this.pageNavigationToolTip();
      if (rawData == null) {
        this.drilldownListData = [];
        this.totalRecords = 0;
        this.pageNo = PageNo;
        this.pageSize = PageSize;
        this.loading = false;
      }else if (rawData.Status == "Success" && rawData.Response.Collection.length == 0 && this.searchBy == '' && this.searchString == ''){
        this.loading = false;
        this.showNoData = true;
      } else if (rawData.Status === "Failure") {
        this.showErrorData = true;
      } else {
        let rawResponse: any = rawData;
        if (rawResponse.Status == 'Success') {
          let data = rawResponse.Response;
         // console.log(data, "data");
          this.drilldownListData = data.Collection;
         // console.log(this.drilldownListData, "drilldowndata");
            if(this.drilldownListData != null && this.drilldownListData.length > 0){
              this.famAccess = this.drilldownListData[0].IsSingleFAM;
              //console.log(this.famAccess, "famleveld2");
              if (this.famAccess == "Multiple"){
                this.getMultipleFAMCol();
              } else {
                this.getSingleFAMCol();
              }
            }
          this.totalRecords = data.TotalRecords;
          this.pageNo = data.CurrentPageNo;
          this.pageSize = data.PageSize;
          this.loading = false;
        } else {
          if (rawResponse.Errors != null && rawResponse.Errors.length > 0) {
            this.successMsg = true;
            this.callErrorMsg('error', '', this.genericErrorMessage);
          } else {
            this.showEmptyGrid(1, 10);
          }
        }
        this.loading = false;
      }
    }).catch(e => {
      this.drilldownListData = null;
      this.successMsg = false;
      this.showErrorData = true;
      this.callErrorMsg('error', '', this.genericErrorMessage);
    });
  }
  showEmptyGrid(page: any, size: any) {
    this.drilldownListData = [];
    this.totalRecords = 0;
    this.pageNo = page;
    this.pageSize = size;
    this.loading = false;
  }
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    window.scrollTo(0, 0);
    setTimeout(function () { document.getElementById('ClearMsg').click(); }, this.MessageTimeOut);
  }
  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }
  pageNavigationToolTip() {
    this.paginatorFirstSelector = <HTMLElement>document.querySelector("a.ui-paginator-first");
    this.paginatorFirstSelector.title = 'First';
    this.paginatorLaststSelector = <HTMLElement>document.querySelector("a.ui-paginator-last");
    this.paginatorLaststSelector.title = 'Last';
    this.paginatorNextSelector = <HTMLElement>document.querySelector("a.ui-paginator-next");
    this.paginatorNextSelector.title = 'Next';
    this.paginatorPrevSelector = <HTMLElement>document.querySelector("a.ui-paginator-prev");
    this.paginatorPrevSelector.title = 'Previous';
  }
  paginationNavigationDetails(event) {
    if (event.page >= this.pageNo || (event.page + 2) <= this.pageNo || this.pageSize != event.rows) {
      this.pageNo = event.page + 1;
      this.pageSize = event.rows;
      if(this.isTrendDrilldown) {
        let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
        month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
      } else {
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
      }
    }
  }
  customSort(event: any) {
    if (event.sortField) {
      event.sortOrder = event.sortOrder == 1 ? 'asc' : 'dsc';
      this.pageNo = 1;
      this.sortObj.sortType = event.sortOrder;
      this.sortObj.sortField = event.sortField;
      if(this.isTrendDrilldown) {
        let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
        month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
      } else {
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
      }
      this.dt.first = 0;
    }
  }
  //Export
  exportEventFile() {
    if(this.isTrendDrilldown) {
      let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
          month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
      this.exportEventFileAction(this.pageNo=1, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.flag, this.searchString, this.searchBy,this.compSTdate,this.compEDdate, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
    } else {
      this.exportEventFileAction(this.pageNo=1, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.flag, this.searchString, this.searchBy,this.compSTdate,this.compEDdate)
    }
  }
  exportEventFileAction(PageNo, PageSize,SortField,SortType,flag,SearchString,SearchBy,compSTdate,compEDdate,IsWin?: boolean ,YearDate?: string,MonthDate?: string) {
     this.loading = true;
    this.pageSize = -1;
    this.LostBusinessDrilldownService.getLBCExcelDate();
    compSTdate = this.compSTdate = this.LostBusinessDrilldownService.CPStartDate;
    compEDdate = this.compEDdate = this.LostBusinessDrilldownService.CPEndDate;
    if (this.famAccess == "Multiple"){
      this.flag = flag ="Multiple";
    } else {
      this.flag = flag ="Single";
    }
    if (this.searchString == null)
      this.searchBy = '';

      let params = this.isTrendDrilldown ? {PageNo, PageSize,SortField,SortType,flag,SearchString,SearchBy,compSTdate,compEDdate,IsWin,YearDate,MonthDate} : {PageNo, PageSize,SortField,SortType,flag,SearchString,SearchBy,compSTdate,compEDdate};

    this.LostBusinessDrilldownService.exportWincntContactData(params, this.isTrendDrilldown).then(rawData => {
      let rawResponse: any = rawData;
      if (rawData.size > 0) {
        this.converBlobtToFile(rawResponse, 'Contactswhocontinued_' + this.datetimestamp() + '.xlsx');
        this.pageSize = 10;
         this.loading = false;
      } else {
        this.loading = false;
      }
    }).catch(e => {
         this.loading = false;
         this.showErrorData = true;

    });
  }
  converBlobtToFile(theBlob: Blob, fileName: string) {

    if (navigator.appVersion.toString().indexOf('.NET') > 0)
      window.navigator.msSaveBlob(theBlob, fileName);
    else {
      let file: any = new Blob([theBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
  }


  datetimestamp() {
    var today = new Date();
    var sToday = (((today.getMonth() + 1) < 10 ? '0' : '') + (today.getMonth() + 1)).toString();
    sToday += ((today.getDate() < 10 ? '0' : '') + today.getDate()).toString();
    sToday += today.getFullYear().toString() + '_';
    sToday += (today.getHours() < 10 ? '0' : '') + today.getHours().toString();
    sToday += (today.getMinutes() < 10 ? '0' : '') + today.getMinutes().toString();
     return sToday;
  }
  Hidediv(){
    this.divhide = false;
    this.winContactdisplayChange.emit({hidewincntContactPopup : true});
  }
  onClickPrevious(){
    this.Previous.emit();
  }

}
