import { Component, OnInit,OnChanges , Input, Output, EventEmitter } from '@angular/core';
import { TemplateQuestionsService } from "../../services/template-questions.service";
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-mcq',
  templateUrl: './mcq.component.html',
  styleUrls: ['./mcq.component.scss']
})
export class McqComponent implements OnInit , OnChanges {
  @Input()
  data: any = {

    "templateId": 6,
    "questionId": 103,
    "questionText": "MCQ question statmernt",
    "responseType": "SSEL",
    "responseTypeDescription": "Single Select",
    "canEdit": false,
    "authLanguageIsoCode": "en-us",
    "authLanguageDescription": "English (United States)",
    "targetLanguageIsoCode": null,
    "targetLanguageDescription": null,
    "respAttributes": [{
      "attributeId": null,
      "attributeName": "OPTION",
      "attributeValue": "Opt A"
    },
    {
      "attributeId": null,
      "attributeName": "OPTION",
      "attributeValue": "Opt B"
    },
    {
      "attributeId": null,
      "attributeName": "OPTION",
      "attributeValue": "Opt C"
    },
    {
      "attributeId": null,
      "attributeName": "OPTION",
      "attributeValue": "Opt D"
    }
    ]

  };
  @Output()
  displayChange = new EventEmitter();
  @Input()
  buttonDisable: boolean = true;
 
  capturedData: any = {};
  queIndex :any ={};
  
  editable: boolean = false;

  selectedValues: string[] = [];

  constructor(private messageService: MessageService, public _templateQuestionService: TemplateQuestionsService) { }
  ngOnInit() {
    this.capturedData = JSON.parse(JSON.stringify(this.data));
  }

  ngOnChanges() {
    this.capturedData=JSON.parse(JSON.stringify(this.data));
  } 

  successSaveMsg() {
    this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Your responses has been saved' });
  }
  toggleEdit() {
    this.editable = !this.editable;
    this.buttonDisable = !this.buttonDisable;
    this.displayChange.emit(this.buttonDisable);
  }
  reset() {
    this.capturedData = JSON.parse(JSON.stringify(this.data));
    this.displayChange.emit(this.buttonDisable);
    this.toggleEdit();
  }
  save(Questiondata) {
    if (Questiondata.QuestionText !== "") {
      this._templateQuestionService.postEditedQuestions(Questiondata)
        .then(data => {
          this.data = Questiondata;
          this.capturedData = JSON.parse(JSON.stringify(this.data));
          return data;
        }).catch(error => {
          return error;
        })
      this.toggleEdit();
    } else {

      return false;
    }
  }
}
