import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ctls-bread-crumb',
  templateUrl: './ctls-bread-crumb.component.html',
  styleUrls: ['./ctls-bread-crumb.component.scss'],
})
export class CtlsBreadCrumbComponent implements OnInit {
  @Input() strSpan1: string;
  @Input() strSpan2: string;
  @Input() strSpan3: string;
  @Input() strTitle: string;
  @Input() paddingLeft: string = '0px';
  constructor() {}

  ngOnInit() {}
}
