import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ctlsHexCode'
})
export class CtlsHexCodePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value)
      return value.slice(1);
    else return null;
  }

}
