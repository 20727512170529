import { RelationshipDashboardSharedEventService } from './../../../shared/services/relationship-dashboard-shared-event.service';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { DashboardWidgetService } from '../service/dashboard-widget.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subscription } from 'rxjs';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import * as _html2canvas from "html2canvas";
const html2canvas:any = _html2canvas;
import { filter } from 'rxjs/operators';
import { ExportChartModel } from 'src/app/shared/models/export-chart.model';
import { surveyType, widgetCodes } from 'src/app/shared/constants/shared.constants';
import { ChartDataService } from '../service/chart-data-service.service';
import { environment } from 'src/environments/environment';
const WIDGET_CODE = 'lsdd';

@Component({
  selector: 'app-loyalty-shift-chart',
  templateUrl: './loyalty-shift-chart.component.html',
  styleUrls: ['./loyalty-shift-chart.component.scss']
})
export class LoyaltyShiftChartComponent implements OnInit {
  private imagepath = environment.imagepath;
  @Input() response: any;
  @Input('boolLoyaltyShiftLoader') boolLoyaltyShiftLoader;
  @Input('showLoyaltyShiftFlipper') showLoyaltyShiftFlipper;
  @Input() surveyTypeInput = surveyType.Transactional;
  @Input() screenCode;

  loyaltyShiftResponse = null;
  promoters = [];
  neutrals = [];
  detractors = [];
  newlyResponded = [];
  widgetCodesConst:any;
  promotersCount: number = 0;
  neutralsCount: number = 0;
  detractorsCount: number = 0;
  totalContactsResponded: number = 0;
  inLimit: boolean = false;
  pagex: number = 0;
  pagey: number = 0;
  imagedisplay: string = "none";
  // loyaltyShiftContactDetailsResponse = null;

  @Output() closeChartEvent = new EventEmitter<boolean>();

  private chartState: CHART_STATE_OPTIONS;
  private exportSubscription: Subscription;
  @ViewChild('pictureOf') pictureOf: ElementRef;

  constructor(private dashboardWidgetService: DashboardWidgetService, private sharedService: SharedService, private rdSharedEventService: RelationshipDashboardSharedEventService,
    private chartDataService: ChartDataService) {
    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(val => val !== null && val.includes(WIDGET_CODE.toLowerCase()))).subscribe(val => {
      this.decideExport();
    });
    this.widgetCodesConst = widgetCodes;
  }

  ngOnInit() {

    // this.getLoyaltyShiftContactDetails();
  }

  ngOnChanges() {
    console.log("ctls to loyalty",this.response)
    window.scrollTo(0, 0);
    this.loyaltyShiftGridHandler(false);
    this.getLoyaltyShift();
    this.updateExportDetails();
    // extra export action trigger & check because of home.component flipper
    if(!this.exportSubscription.closed){
      this.decideExport();
    }
  }
  ngOnDestroy() {
    this.exportSubscription.unsubscribe();
    this.updateExportDetails(true);
  }

  getLoyaltyShift() {
    this.chartState = CHART_STATE_OPTIONS.LOADING;
    if (this.response != null && this.response.Status == "Success" && this.response.Response != null) {
      this.chartState = CHART_STATE_OPTIONS.SUCCESS;

      this.loyaltyShiftResponse = this.response.Response;

      this.promoters = this.loyaltyShiftResponse.PromoterLoyaltyShiftModel;
      this.promoters = this.promoters.sort((a, b): number =>
        (a.MovementTo.toString() > b.MovementTo.toString()) ? -1 : (a.MovementTo.toString() < b.MovementTo.toString()) ? 1 : 0
      );

      this.neutrals = this.loyaltyShiftResponse.NeutralLoyaltyShiftModel;
      this.neutrals = this.neutrals.sort((a, b): number =>
        (a.MovementTo.toString() > b.MovementTo.toString()) ? -1 : (a.MovementTo.toString() < b.MovementTo.toString()) ? 1 : 0
      );

      this.detractors = this.loyaltyShiftResponse.DetractorLoyaltyShiftModel;
      this.detractors = this.detractors.sort((a, b): number =>
        (a.MovementTo.toString() > b.MovementTo.toString()) ? -1 : (a.MovementTo.toString() < b.MovementTo.toString()) ? 1 : 0
      );

      this.newlyResponded = this.loyaltyShiftResponse.NewResponseLoyaltyShiftModel;
      this.newlyResponded = this.newlyResponded.sort((a, b): number =>
        (a.MovementTo.toString() > b.MovementTo.toString()) ? -1 : (a.MovementTo.toString() < b.MovementTo.toString()) ? 1 : 0
      );

      // for counting no of promoters    
      if (this.promotersCount > 0) {
        this.promotersCount = 0;
      }
      this.promoters.filter(obj => {
        if (obj.MovementTo == "P")
          this.promotersCount = this.promotersCount + obj.LoyaltyShiftVal;
      })

      this.neutrals.filter(obj => {
        if (obj.MovementTo == "P")
          this.promotersCount = this.promotersCount + obj.LoyaltyShiftVal;
      })

      this.detractors.filter(obj => {
        if (obj.MovementTo == "P")
          this.promotersCount = this.promotersCount + obj.LoyaltyShiftVal;
      })

      this.newlyResponded.filter(obj => {
        if (obj.MovementTo == "P")
          this.promotersCount = this.promotersCount + obj.LoyaltyShiftVal;
      })


      // for counting no of neutrals
      if (this.neutralsCount > 0) {
        this.neutralsCount = 0;
      }
      this.promoters.filter(obj => {
        if (obj.MovementTo == "N")
          this.neutralsCount = this.neutralsCount + obj.LoyaltyShiftVal;
      })

      this.neutrals.filter(obj => {
        if (obj.MovementTo == "N")
          this.neutralsCount = this.neutralsCount + obj.LoyaltyShiftVal;
      })

      this.detractors.filter(obj => {
        if (obj.MovementTo == "N")
          this.neutralsCount = this.neutralsCount + obj.LoyaltyShiftVal;
      })

      this.newlyResponded.filter(obj => {
        if (obj.MovementTo == "N")
          this.neutralsCount = this.neutralsCount + obj.LoyaltyShiftVal;
      })

      // for counting no of detractors
      if (this.detractorsCount > 0) {
        this.detractorsCount = 0;
      }
      this.promoters.filter(obj => {
        if (obj.MovementTo == "D")
          this.detractorsCount = this.detractorsCount + obj.LoyaltyShiftVal;
      })

      this.neutrals.filter(obj => {
        if (obj.MovementTo == "D")
          this.detractorsCount = this.detractorsCount + obj.LoyaltyShiftVal;
      })

      this.detractors.filter(obj => {
        if (obj.MovementTo == "D")
          this.detractorsCount = this.detractorsCount + obj.LoyaltyShiftVal;
      })

      this.newlyResponded.filter(obj => {
        if (obj.MovementTo == "D")
          this.detractorsCount = this.detractorsCount + obj.LoyaltyShiftVal;
      })

      this.totalContactsResponded = this.promotersCount + this.neutralsCount + this.detractorsCount;
    }
    if ((this.response != null && this.response.Status == "Success" && this.response.Response == null) || (this.totalContactsResponded == 0)) {
      this.showNoData = true;
      this.chartState = CHART_STATE_OPTIONS.NO_DATA;
    } else if (this.response.Status == "Failure") {
      this.showErrorData = true;
      this.chartState = CHART_STATE_OPTIONS.ERROR;
    }else{
      this.showNoData = false;
      this.showErrorData = false;
      this.chartState = CHART_STATE_OPTIONS.SUCCESS;
    }
  }

  onCloseLoyaltyShiftChart(isClose) {
    if (this.surveyTypeInput != null && (this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput == 'OEM')) {
      this.rdSharedEventService.closeWidget(this.widgetCodesConst.LoyaltyShiftDrillDown)
    } else {
      this.sharedService.widgetCloseSubject.next(this.widgetCodesConst.LoyaltyShiftDrillDown);
    }
    this.closeChartEvent.emit(isClose);
    this.displayGrid = false;
  }

  fromPND;
  toPND;
  displayGrid: boolean = false;
  showNoData: boolean = false;
  showErrorData: boolean = false;
  overlayChartDisplay: string = 'none';
  LoyaltyShiftVal;
  LoyaltyShiftText;
  index: number;

  onClickLoyaltyShiftMatrixItem(fromPND, toPND, LoyaltyShiftVal, LoyaltyShiftText, index) {
    this.fromPND = fromPND;
    this.toPND = toPND;
    this.displayGrid = true;
    this.LoyaltyShiftVal = LoyaltyShiftVal;
    this.LoyaltyShiftText = LoyaltyShiftText;
    this.index = index;
  }

  loyaltyShiftGridHandler(displayGrid: boolean) {
    this.displayGrid = displayGrid;
  }
  showChartOverlay() {
    this.overlayChartDisplay = 'block';
  }
  hideChartOverlay() {
    this.overlayChartDisplay = 'none';
  }
  overLayClick() {
    if (this.inLimit) {
      this.overlayChartDisplay = 'none';
    }

  }
  trackMouse($event) {
    this.pagex = $event.clientX;
    this.pagey = $event.offsetY;
  }
  enableClick($event) {
    this.inLimit = ($event.type == 'mouseover') ? true : false;
    if (this.inLimit) {
      this.pagex = $event.clientX;
      this.pagey = $event.offsetY;
      this.imagedisplay = "block"
    } else {
      this.imagedisplay = "none";
    }
  }

  // getLoyaltyShiftContactDetails() {

  //   this.dashboardWidgetService.getLoyaltyShiftContactDetails().then(res => {

  //     if (res.Response != null && res.Status=="Success") {
  //       this.loyaltyShiftContactDetailsResponse = res.Response;
  //     }
  //   }).catch(e => {
  //     
  //   });
  // }

  private toCanvas = () => {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    try {
      if (this.pictureOf && this.pictureOf.nativeElement) {
        html2canvas(this.pictureOf.nativeElement, { scrollX:0, scrollY:-window.scrollY, allowTaint: false, useCORS: false, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
          chartUpdate.UpdateTime = new Date();
          chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
          chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
          chartUpdate.CurrentState = this.chartState;
          chartUpdate.ChartImgBase64 = canvas.toDataURL('image/png');
          this.sharedService.updateChartStatusSubject(chartUpdate);
        },
          err => {
            console.log('error occurred during image export, details as below \n', err);
            chartUpdate = this.exportErrorUpdate();
            this.sharedService.updateChartStatusSubject(chartUpdate);
          });
      } else if(this.chartState === CHART_STATE_OPTIONS.NO_DATA) {
          chartUpdate.UpdateTime = new Date();
          chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
          chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
          chartUpdate.CurrentState = this.chartState;
          chartUpdate.ChartImgBase64 = 'no_image_data_available_for_this_state';
          chartUpdate.HasNoData = true;
          this.sharedService.updateChartStatusSubject(chartUpdate);
      }
    } catch (error) {
      console.log('html2canvas error as', error);
      chartUpdate = this.exportErrorUpdate();
      this.sharedService.updateChartStatusSubject(chartUpdate);
    }
  }
  private updateExportDetails = (isDestroyCall: boolean = false) => {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    chartUpdate.UpdateTime = new Date();
    chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
    if (isDestroyCall) {
      this.chartState = CHART_STATE_OPTIONS.UNKNOWN;
    }
    chartUpdate.CurrentState = this.chartState;// ? this.chartState.toLowerCase() : CHART_STATE_OPTIONS.LOADING;
    this.sharedService.updateChartStatusSubject(chartUpdate);
  }
  exportErrorUpdate = (): ExportChartModel => {
    let err: ExportChartModel = new ExportChartModel();
    err.HasErrorInExport = true;
    err.UpdateTime = new Date();
    err.ChartName = WIDGET_CODE.toLowerCase();
    err.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
    err.CurrentState = this.chartState;
    return err;
  }
  private decideExport = () => {
    if (this.sharedService.acceptableChartState.includes(this.chartState)) {
      this.toCanvas();
    }
  }
  refreshWidget(widgetKey : string) {
    let refreshData = {
      refresh: true
    };
    if (widgetKey) {
      refreshData[widgetKey] = true;
    }
    this.chartDataService.setRefreshWidgetData(refreshData);
  }
}
