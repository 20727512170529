import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FamMaster } from '../model/business-rule.model';
import { Questions, QuestionsResponse } from './../../surveys/model/question.model';
import { error } from 'util';


@Injectable({
  providedIn: 'root'
})
export class FamMasterService {
  // private serviceUrl = 'http://www.json-generator.com/api/json/get/bUPvZEtYky?indent=2';
  private serviceUrl = this.sharedService.configuration.baseUrl + 'api/MDM/';
  // private serviceUrl = environment.baseUrl +'api/SurveyTemplate/Questions?';
  public salesEngineersFilter: any = {};
  public projectManagersFilter: any = {};
  public serviceEngineersFilter: any = {};

  recordCount: number;

  constructor(private http: HttpClient, private sharedService: SharedService) { this.recordCount = 0 }

  getFamMaster(page, size, sortField, sortType, filterBy) {

    return this.http.get(this.serviceUrl + "FAMMaster?pageNo=" + page + "&pageSize=" + size + "&sortField=" + sortField + "&sortType=" + sortType + "&filterBy=" + filterBy + "&buster=" + new Date().getTime())
      .toPromise()
      .then((res: FamMaster) => res)
      .then(data => {
        return data;
      });
  }
  AddFAMDivBL(data) {

    return this.http.post(this.serviceUrl + "FAMManagement/DivBlMapping", data).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
  AddBrandLanguage(data) {
    return this.http.post(this.serviceUrl + 'FAMManagement/BrandsLanguages', data).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
  AddOtherDetails(data) {
    return this.http.post(this.serviceUrl + 'FAMManagement/OtherDetails', data).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
  AddFamMaster(data) {
    return this.http.post(this.serviceUrl + "FAMMaster/Add", data).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      })
  }

  GetFAMMasterDataModel() {
    return this.http.get(this.serviceUrl + "FAMMaster/DropDownMasterData" + "?buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      })
  }
  getFAMMasterByID(id) {
    return this.http.get(this.serviceUrl + "FAMMaster/" + id + "?buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
  getDetails(FamId, BaId) {
    return this.http.get(this.serviceUrl + "FAMManagement/Details?FAMId=" + FamId + " &BAId=" + BaId + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
  //export FamData
  exportData(page, size, sortField, sortType, filterBy) {

    let serviceUrl = this.sharedService.configuration.baseUrl + 'api/MDM/FamMaster/Export?';

    return this.http.get(serviceUrl + "pageNo=" + page + "&pageSize=" + size + "&sortField=" + sortField + "&sortType=" + sortType + "&filterBy=" + filterBy + "&buster=" + new Date().getTime(), { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  updateFamMaster(famMasterData) {
    return this.http.post(this.serviceUrl + "FAMMaster/Update", famMasterData).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })

  }

  GetFamBaAuthorithy(famId, baId, pageNo, pageSize, sortField, sortType) {
    //http://falcon-uat1-web.westeurope.cloudapp.azure.com:10001/api/MDM/FAMManagement/Authorities?famid=1&baid=1&pagesize=1&pageno=10&sortfield=famauthorityid&sorttype=asc
    return this.http.get(this.serviceUrl + "FAMManagement/Authorities?famId=" + famId + "&baId=" + baId + "&pageno=" + pageNo + "&pagesize=" + pageSize + "&sortfield=" + sortField + "&sorttype=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetFamBaAuthorityID(AuthorityId) {
    return this.http.get(this.serviceUrl + "FAMManagement/Authorities/" + AuthorityId + "?buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  SaveFamBaAuthority(authorityData) {
    return this.http.post(this.serviceUrl + "FAMManagement/Authorities", authorityData).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  GetFAMBAZoneBranchMapping(famId, baId, pageNo, pageSize, sortType, sortField) {
    return this.http.get(this.serviceUrl + "FAMManagement/ZoneBranchMappings?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetFAMBAServiceTechniciansDetails(famId, baId, pageNo, pageSize, sortField, sortType) {
    return this.http.get(this.serviceUrl + "FAMManagement/ServiceTechnicians?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetFAMBASalesEngineersDetails(famId, baId, pageNo, pageSize, sortType, sortField) {
    return this.http.get(this.serviceUrl + "FAMManagement/SalesEngineers?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetFAMBAProjectManagersDetails(famId, baId, pageNo, pageSize, sortType, sortField) {
    return this.http.get(this.serviceUrl + "FAMManagement/ProjectManagers?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetFAMBAZoneDetails(famId, baId, pageNo, pageSize, sortType, sortField) {
    return this.http.get(this.serviceUrl + "FAMManagement/Zones?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetViewSaleChannelException(pageNo,pageSize,sortType,sortField,FAMID,BAID){

    return this.http.get(this.serviceUrl + "FAMManagement/ViewSaleChannel?pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortfield=" + sortField + "&sortType=" + sortType + "&FAMID=" + FAMID + "&BAID=" + BAID+ "&buster=" + new Date().getTime()).
    toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    }).catch(error => {
      return error;
    })
  }

  GetViewServiceTypeException(pageNo,pageSize,sortType,sortField,FAMID,BAID){

    return this.http.get(this.serviceUrl + "FAMManagement/ViewServiceTypeException?pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortfield=" + sortField + "&sortType=" + sortType + "&FAMID=" + FAMID + "&BAID=" + BAID+ "&buster=" + new Date().getTime()).
    toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    }).catch(error => {
      return error;
    })
  }

  SaveFAMBAZone(model, Id) {
    return this.http.post(this.serviceUrl + "FAMManagement/Zones/" + Id, model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBAZone(Id) {
    return this.http.post(this.serviceUrl + "FAMManagement/Zones/" + Id + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  GetFAMBABranchDetails(famId, baId, pageNo, pageSize, sortType, sortField) {
    return this.http.get(this.serviceUrl + "FAMManagement/Branches?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  SaveEditAuthoritySignature(data) {
    return this.http.post(this.serviceUrl + "FAMManagement/AuthoritySignature", data).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFamBaAuthority(authorityID) {
    return this.http.post(this.serviceUrl + "FAMManagement/Authorities/" + authorityID + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })

  }

  SaveFAMBABranch(model, Id) {
    return this.http.post(this.serviceUrl + "FAMManagement/Branches/" + Id, model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBABranch(Id) {
    return this.http.post(this.serviceUrl + "FAMManagement/Branches/" + Id + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  SaveFAMBAZoneBranchMapping(model) {
    return this.http.post(this.serviceUrl + "FAMManagement/ZoneBranchMappings/", model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  EditFAMBAZoneBranchMapping(model) {
    return this.http.post(this.serviceUrl + "FAMManagement/ZoneBranchMappings/Edit", model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBAZoneBranchMapping(famId, baId, divId, zoneId) {
    return this.http.post(this.serviceUrl + "FAMManagement/ZoneBranchMappings/Delete?famId=" + famId + "&baId=" + baId + "&divId=" + divId + "&zoneId=" + zoneId, null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  SaveFAMBASalesEngineer(model, Id) {
    return this.http.post(this.serviceUrl + "FAMManagement/SalesEngineers/" + Id, model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  SaveFAMBAProjectManager(model, Id) {
    return this.http.post(this.serviceUrl + "FAMManagement/ProjectManagers/" + Id, model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBASalesEngineers(Id) {
    return this.http.post(this.serviceUrl + "FAMManagement/SalesEngineers/" + Id + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBAProjectManagers(Id) {
    return this.http.post(this.serviceUrl + "FAMManagement/ProjectManagers/" + Id + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  SaveFAMBAServiceTechnician(model, Id) {
    return this.http.post(this.serviceUrl + "FAMManagement/ServiceTechnicians/" + Id, model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBAServiceTechnicians(Id) {
    return this.http.post(this.serviceUrl + "FAMManagement/ServiceTechnicians/" + Id + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  exportZonesToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(this.serviceUrl + 'ExportZones?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportBranchesToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(this.serviceUrl + 'ExportBranches?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportZoneBranchMappingToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(this.serviceUrl + 'ExportZoneBranchMapping?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportZoneAndBranchesToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(this.serviceUrl + 'ExportZoneAndBranchesToExcel?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportSalesEngineerToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(this.serviceUrl + 'ExportSalesEngineer?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportProjectManagerToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(this.serviceUrl + 'ExportProjectManager?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportServiceTechnicianToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(this.serviceUrl + 'ExportServiceTechnician?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
}
