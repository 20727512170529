import {
  AfterContentInit,
  Directive,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: 'autoFocus',
})
export class AutofocusDirective implements AfterContentInit, OnInit {
  @Input() public autoFocus: boolean;

  public constructor(private el: ElementRef) {}
  ngOnInit() {
    this.el.nativeElement.focus();
  }
  public ngAfterContentInit() {
    // setTimeout(() => {
    this.el.nativeElement.focus();
    // }, 500);
  }
}
