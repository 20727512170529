import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { CtlsCommonCommentComponent } from './ctls-common-comment.component';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule,TooltipModule,CommonModule],
  declarations: [CtlsCommonCommentComponent],
  exports: [CtlsCommonCommentComponent],
})
export class CtlsCommonCommentModule {}
