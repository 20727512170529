import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsTaskfeedbackOptionsService {
  private serviceUrl = this.sharedService.configuration.baseUrl;

  recordCount: number;
  /** Form wizard state */
  surveyID: number = 0;
  markforsurvey: string = '';

  instanceAccordionStatus: any = {};

  /** BehaviourSubjects */
  blockFull: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.recordCount = 0;
  }

  GetOptions(value): Observable<any> {
    if (value === 1) {
      return this.http.get<any>(
        `${this.serviceUrl}api/TaskForSurveyFeedback/GetTransactionalSurveys`
      );
    } else if (value === 2) {
      return this.http.get<any>(
        `${this.serviceUrl}api/TaskForSurveyFeedback/GetRelationshipSurveys`
      );
    }
  }
}
