import { Component, OnInit ,Output,EventEmitter, Input} from '@angular/core';
import { DialogService } from 'primeng/components/common/api';
import { LostBusinessStateModel, LbDdNav3State } from '../model/lost-business-lbcdrilldown';
import { LostBusinessDrilldownService } from '../service/lost-business-drilldown.service';

@Component({
  selector: 'app-lbc-win-cnt-drilldown-popup',
  templateUrl: './lbc-win-cnt-drilldown-popup.component.html',
  styleUrls: ['./lbc-win-cnt-drilldown-popup.component.scss']
})
export class LbcWinCntDrilldownPopupComponent implements OnInit {
  currentState: LostBusinessStateModel | null = null;

  /** Localizing imports */
  enumConstants = LbDdNav3State;
  divhide: boolean = true;
  @Output() windisplayChange = new EventEmitter();
  @Input()continuousCustomerCount:number;
  @Input() showContitext:any;
  @Input()lostComparisonDate:any;

  constructor(private dialogService: DialogService, private drilldownService: LostBusinessDrilldownService) {
    this.currentState = this.drilldownService.ddNav3GetData();
  }


  ngOnInit() {
  }
  onClickPrevious(data) {
    this.currentState = this.drilldownService.ddNav3.goToPrevComp(data);
  }
  onClickNext(data) {
    this.currentState = this.drilldownService.ddNav3.goToNextComp(data);
  }
  getData(data){
    this.onClickNext(data);
  }
  Hidediv(){
    this.drilldownService.ddNav3ResetStates();
    this.divhide = false;
    this.windisplayChange.emit({hidewindrilldown : true});
  }

}
