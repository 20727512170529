import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsTaskEditScreenService {
  // private serviceUrl = this.sharedService.configuration.baseUrl;
  private serviceUrl = environment.baseUrl;
  private editWidth$ = new Subject<any>();
  private drag$ = new Subject<any>();
  constructor(private http: HttpClient, private sharedService: SharedService) { }
  setWidth(data) {
    this.editWidth$.next(data);
  }
  getWidth(): Observable<any> {
    return this.editWidth$.asObservable();
  }
  setDrag(data) {
    this.drag$.next(data);
  }
  getDrag(): Observable<any> {
    return this.drag$.asObservable();
  }
  /**
   * Get designations dropdowns
   */
  getDesignations(params): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskForSurveyFeedback/GetDesignations`,
      params
    );
  }
  /**
   * Get division dropdowns
   */
  getDivision(params): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskForSurveyFeedback/GetDivision`,
      params
    );
  }

  /**
   * Get assignee dropdowns
   */
  GetAssignee(params): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskForSurveyFeedback/GetAssignee`,
      params
    );
  }
  /**
   * Get new assignee dropdowns
   */
  GetFAMBAUsers(): Observable<any> {
    return this.http.get<any>(`${this.serviceUrl}api/EditTask/GetFAMBAUsers`);
  }
  /**
   * resend email for closure
   */
  resend(data): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/EditTask/ResendClosureTemplate`,
      {
        params: {
          cTLSEditTaskClosureTemplateID: data.cTLSEditTaskClosureTemplateID,
          brandID: data.brandID,
        },
      }
    );
  }
  /**
   * Get brand dropdowns
   */
  GetBrand(params): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskForSurveyFeedback/GetBrand`,
      params
    );
  }
  /**
   * Get CommentHistory
   */
  GetCommentHistory(data): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/GetCommentHistory?CTLSTaskListID=${data.CTLSTaskListID}&&skipCount=${data.skipCount}&&takeCount=${data.takeCount}&&IsCommentEditRequired=${data.IsCommentEditRequired}`,
      {}
    );
  }
  /**
   * Get Category dropdowns
   */
  GetCategory(): Observable<any> {
    return this.http.get<any>(`${this.serviceUrl}api/TaskListing/GetCategory`);
  }
  /** Translate data
   * @param dataObj
   */
  getTranslateWithGoogle(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/Translate/TranslateWithGoogle`,
      dataObj
    );
  }
  /**Service for save edit task
   * @param dataObj
   */
  SaveTaskManagementDetails(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/SaveTaskManagementDetails`,
      dataObj
    );
  }
  /**Service for save edit transfer task
   * @param dataObj
   */
  saveTransferTaskDetails(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/SendMail`,
      dataObj
    );
  }
 /**
   * Resend TaskDetails for save
   */
  getResendTransferTaskDetails(dataObj): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/EditTask/ReSendMail?CTLSTaskListID=${dataObj.CTLSTaskListID}&CTLSTaskManagmentID=${dataObj.CTLSTaskManagmentID}`,
      dataObj
    );
  }
  /**
   * Get TaskDetails fetch after each save
   */
  GetTaskDetails(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/GetTaskDetails?CTLSTaskListID=${dataObj.CTLSTaskListID}`,
      dataObj
    );
  }
  /**Service for getting all enabled assignees
   * @param dataObj
   */
  GetAllEnabledAssignees(dataObj): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/EditTask/GetAllEnabledAssignees`,
      {
        params: {
          FAMID: dataObj.FAMID,
          BAID: dataObj.BAID,
          AssigneeID: dataObj.AssigneeID,
        },
      }
    );
  }
  /**Service for getting templates name for dropdown
   * @param dataObj
   */
  GetTemplateNames(params): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/EditTask/GetTemplateNames`,
      {
        params: {
          BAID: params.BAID,
          FAMID: params.FAMID,
          brandID: params.BrandID,
          subCategory: params.SurveyType,
        },
      }
    );
  }
  /**Service for closure template data
   * @param dataObj
   */
  GetClosureTemplate(params): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/EditTask/GetClosureTemplateWithValues`,
      {
        params: {
          cTLSTaskClosureTemplateID: params.cTLSTaskClosureTemplateID,
          taskID: params.taskID,
          isRefreshed: params.isRefreshed
        },
      }
    );
  }
  /**Service for getting template for preview by object
   * @param dataObj
   */
  GetTemplatePreview(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/ClosureTemplateEmailPreview`,
      dataObj
    );
  }
  /**Service for saving and sending closure template
   * @param dataObj
   */
  SaveTemplate(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/SaveClosureTemplate`,
      dataObj
    );
  }
  /**Service for getting template for preview by id
   * @param cTLSEditTaskClosureTemplateID
   */
  ClosureTemplateSentEmailPreview(id): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/EditTask/ClosureTemplateSentEmailPreview`,
      {
        params: {
          cTLSEditTaskClosureTemplateID: id,
        },
      }
    );
  }

  /**Service for getting GetTaskDetailsByTaskID
   * @param id
   */
  GetTaskDetailsByTaskID(id): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskListing/GetTaskDetailsByTaskID?CTLSTaskListID=${id}&&CTLSMailTransactionID=`
    );
  }

  /**Service for getting GetTaskDetailsByTaskID
   * @param id
   */
  GetImgUrl(id): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/DownloadAttachment?cTLSTaskFilesListID=${id}`,
      {}
    );
  }

  /**Service for getting GetTaskDetailsByTaskID
   * @param id
   */
  GetAttachments(id): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/GetAttachments?cTLSTaskManagementID=${id}`,
      {}
    );
  }

  /**Service for getting GetTaskDetailsByTaskID
   * @param id
   */
  GetFileUrl(id): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/DownloadAttachment?cTLSTaskFilesListID=${id}`,
      {}
    );
  }

  /**Service for update  edit task last entere comment for the login user
  * @param dataObj
  */
  getFiles(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/GetFilesforEditing?cTLSTaskManagementID=${dataObj.cTLSTaskManagementID}`,
      {},
    );
  }

  /**Service for update  edit task last entere comment for the login user
* @param dataObj
*/
  UpdateComment(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/EditTask/UpdateComment`,
      dataObj
    );
  }

  /**Service for getting recommended action as er selected priority
 * @param dataObj
 */
  getRecommendedAction(CTLSConfigurationId, CTLSPriorityID, CTLSTransType): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/TaskForSurveyFeedback/GetSDARecommendedAction?CTLSConfigurationID=${CTLSConfigurationId}&&CTLSSDAPriorityID=${CTLSPriorityID}&&CTLSTransType=${CTLSTransType}`
    );
  }
}
