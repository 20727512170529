import {
  Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef,
  Output, EventEmitter,
} from '@angular/core';
import { Message } from 'primeng/components/common/api';
import {
  DynamicDialogRef,
  DynamicDialogConfig
} from 'primeng/api';
import { CtlsTaskListingService, CtlsCommonService } from './../../../../ctls-shared/ctls-services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { CtlsSpinnerService } from 'src/app/Modules/ctls/ctls-shared/ctls-components/ctls-spinner/ctls-spinner.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-ctls-bulk-update-popup',
  templateUrl: './ctls-bulk-update-popup.component.html',
  styleUrls: ['./ctls-bulk-update-popup.component.scss']
})
export class CtlsBulkUpdatePopupComponent implements OnInit {
  @ViewChild('bulkAttach') attach: ElementRef;
  @Output()
  eventFromBulkUpdate: EventEmitter<any> = new EventEmitter<any>();

  toggleBulkUpdate: boolean = true;
  uplodedFile: FileList;
  inputFileError: string = "";
  inputMessage = "Please upload .xlsx file only";
  uploadmessage = "Choose a file or drag it here";
  ErrorData: any = [];
  rowData2: any = [];
  rowData: any = [];
  cols: any = [];
  msgs: Message[] = [];
  successRespose = { severity: 'success', summary: '', detail: 'Successfully updated' };
  errorResponse = { severity: 'error', summary: '', detail: 'Something went wrong' };
  nosearchresults: string = '';
  totalRecords: number;
  IPAddress = "";
  destroy$ = new Subject();
  success = true;

  constructor(
    private ctlsTaskListingService: CtlsTaskListingService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private spinner: CtlsSpinnerService,
    private changeRef: ChangeDetectorRef,
    private http: HttpClient,
    private commonService: CtlsCommonService,
  ) {
    // let headers = new HttpHeaders();
    // headers.append('Access-Control-Allow-Origin', '*')
    // this.http.get<{ ip: string }>('https://jsonip.com', { headers: headers }).subscribe((data) => {
    //   this.IPAddress = data.ip;
    // });
  }

  ngOnInit() {
  }
  /**
     * On Destroy
     */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
  * Bulk upload
  */
  onBulkUpload() {
    const frmData = new FormData();
    if (this.uplodedFile) {
      this.spinner.show();
      // let paraObj = {
      //   IPAddress: this.IPAddress
      // }
      // frmData.append('IPAddress', JSON.stringify(paraObj));

      frmData.append('file', this.uplodedFile[0]);
      this.ctlsTaskListingService
        .GetBulkUpdateData(frmData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.ErrorData = res.Response;
          this.spinner.hide();
          this.changeRef.detectChanges();
          if (
            !!this.ErrorData.Items && this.ErrorData.Items.length &&
            !!this.ErrorData.ItemsHeader && this.ErrorData.ItemsHeader.Headers &&
            this.ErrorData.IsBulkErrorAfterSave && res.Status === 'SUCCESS'
          ) {
            this.success = false;
            this.handleGridFromResponse(this.ErrorData);
            this.config.width = "35vw";
            this.config.height = "40vh";
            this.config.styleClass = "dynamic-table-style";
            this.config.header = "    Error Listing";
            // this.config.showHeader = false;
            this.toggleBulkUpdate = false;
            if (!!res.Response.SuccessMessage) {
              const objToaster = {
                type: 'Success',
                data: {
                  event: 'GET_UPDATED_TASK_LIST'
                }
              }
              this.commonService.setToaster(objToaster);
              // const event = 'GET_UPDATED_TASK_LIST'
              this.successRespose.detail = res.Response.SuccessMessage;
              this.msgs.push(this.successRespose);
              setTimeout(function () {
                document.getElementById('ClearMsg').click();
              }, 10000);
            }
          } else if (
            !this.ErrorData.IsBulkErrorAfterSave
          ) {
            const objToaster = {
              type: 'Success',
              data: {
                message: res.Response.SuccessMessage,
                event: 'GET_UPDATED_TASK_LIST'
              }
            }
            this.onClosePopup();
            this.commonService.setToaster(objToaster);
            // const event = 'GET_UPDATED_TASK_LIST'
            // this.successRespose.detail = res.Response.SuccessMessage;
            // this.msgs.push(this.successRespose);
            // setTimeout(() => {
            //   this.onClosePopup(event);
            //   this.clearMessage();
            // }, 3000);
          }
          else {
            this.clearMessage();
            if (res.Response && res.Response.Items) {
              // this.errorResponse.detail = res.Response.Items[0].ErrorDetails;
              this.inputFileError = res.Response.Items[0].ErrorDetails;
            } else
              this.msgs.push(this.errorResponse);
          }
        });
    }

  }
  /**
  * All response from get api
  * @param rawResponse
  */
  private handleGridFromResponse(rawResponse: any) {
    this.nosearchresults = '';
    if (!!rawResponse) {

      this.rowData = rawResponse.Items;
      this.cols = rawResponse.ItemsHeader.Headers;

      this.rowData = cloneDeep(rawResponse.Items);
      this.totalRecords = rawResponse.Count;
      this.rowData2 = [];
      if (this.rowData && this.rowData.length) {
        this.rowData2 = this.rowData.slice(0, 100);
        this.rowData2 = cloneDeep(this.rowData2);
      }
      if (
        this.rowData.length <= 0
      ) {
        this.nosearchresults = 'No records found.';
      }
    }
  }
  /**
   * Select files
   */
  selectFiles(files) {
    if (files && files[0]) {
      this.clearMessage();
      const file = files[0];
      let blnTotalMb = this.checkFormatFileSize(file.size, 2);
      if (blnTotalMb) {
        this.inputFileError = 'Total size of attachment cannot exceed 20MB';
        this.attach.nativeElement.value = '';
        this.uplodedFile = null;
      } else {
        this.inputFileError = '';
      }
      // const selectedFiles = (event.target as HTMLInputElement).files;
      if (!this.isValidFile(file)) {
        this.inputFileError = 'Invalid file format, please use .xlsx file to upload';
        this.attach.nativeElement.value = '';
        this.uplodedFile = null;

        return;
      }

      if (!!!this.inputFileError) {
        this.uplodedFile = files;
      }
    } else {
      this.attach.nativeElement.value = '';
    }
  }
  /**
 * check file size abouve 20 mb
 */
  checkFormatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return '0 Bytes';
    let k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    if (sizes[i] === 'MB') {

      if (parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) > 20) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  /**
 * vaildate file type
 */
  isValidFile(file) {
    if (
      file.name.endsWith('.xlsx')
      // .xlsx
    ) {
      return true;
    }
  }
  /**
   * to remove the selected file
   */
  removeUplodedFile() {
    this.attach.nativeElement.value = '';
    this.inputFileError = "";
    this.uplodedFile = null;
  }
  /**
 * to close the pop up
 */
  onClosePopup(val = 'close') {
    this.ref.close(val);
  }
  /**
  * navigate back to upload screen
  */
  backToUpload() {
    this.toggleBulkUpdate = true;
    this.config.width = "30vw";
    this.config.header = "Upload your file";
  }
  /**
 * Clear Message 
 */
  clearMessage() {
    this.msgs = [];
    this.inputFileError = "";
  }
  styleObject(key): Object {
    switch (key) {
      case 'SNo':
        return { width: '80px' };
      case 'Description':
        return {};
      case 'ErrorDetails':
        return {};

      default:
        return {};
    }
  }
}
