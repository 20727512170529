import { widgetCodes } from './../../../shared/constants/shared.constants';
import { RelationshipDashboardSharedEventService } from './../../../shared/services/relationship-dashboard-shared-event.service';
import { Component, OnChanges, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { surveyType } from 'src/app/shared/constants/shared.constants';
import * as _html2canvas from "html2canvas";
const html2canvas:any = _html2canvas;
import { HideOtherOpenDrilldownsService } from '../../../Modules/Distributor_Survey/Services/hide-other-open-drilldowns.service';
import { ChartDataService } from '../service/chart-data-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-loyalty-shift',
  templateUrl: './loyalty-shift.component.html',
  styleUrls: ['./loyalty-shift.component.scss']
})
export class LoyaltyShiftComponent implements OnInit, OnChanges {
  private imagepath = environment.imagepath;
  @Input() response: any;
  @Input() lsHeight: any;
  widgetCodesConst:any;
  loyltyShift = null;
  loyaltyShiftWidth: boolean = false;
  @Output() showChartEvent = new EventEmitter<boolean>();
  @Input('boolLoyaltyShiftLoader') boolLoyaltyShiftLoader;
  @Input() errorData: boolean;
  overlayDisplay : string = "none";
  @Input() surveyTypeInput: string = surveyType.Transactional;
  @Input() bookmarkBaseUrl:string = "/dashboard/home"
  constructor(private sharedService: SharedService,private rdSharedEventService:RelationshipDashboardSharedEventService, private hideOtherOpenDrilldownsService : HideOtherOpenDrilldownsService,
    private chartDataService: ChartDataService ) { 
    this.widgetCodesConst = widgetCodes;
  }
  dragDropHover: boolean = true;

  ngOnChanges() {
    this.getLoyaltyShift();
  }

  ngOnInit() {

  }

  getLoyaltyShift() {

    if (this.response != null && this.response.Response != null) {
      var maxCount = 0;
      this.loyltyShift = null;

      this.response.Response.PromoterLoyaltyShiftModel.forEach(element => {
        if (maxCount < element.LoyaltyShiftVal) {
          maxCount = element.LoyaltyShiftVal;
          this.loyltyShift = element;
        }
      });
      this.response.Response.NeutralLoyaltyShiftModel.forEach(element => {
        if (maxCount < element.LoyaltyShiftVal) {
          maxCount = element.LoyaltyShiftVal;
          this.loyltyShift = element;
        }
      });
      this.response.Response.DetractorLoyaltyShiftModel.forEach(element => {
        if (maxCount < element.LoyaltyShiftVal) {
          maxCount = element.LoyaltyShiftVal;
          this.loyltyShift = element;
        }
      });
      if (this.loyltyShift != null && this.loyltyShift.hasOwnProperty('LoyaltyShiftVal')) {
        if (this.loyltyShift.LoyaltyShiftVal > 99) {
          this.loyaltyShiftWidth = true;
        }
        else{
          this.loyaltyShiftWidth = false;
        }
      }

    }
  }

  onClickLoyaltyShiftChart() {
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('')
    this.showChartEvent.emit(true);
  }
  showOverlay(){
    this.overlayDisplay = "block";
    this.dragDropHover = false;
    this.setHeightLoyaltyShift();
  }
  hideOverlay(){
    this.overlayDisplay = "none";
    this.dragDropHover = true;
  }

  closeWidget() {
    if (this.surveyTypeInput != null && (this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput == 'OEM')) {
      this.rdSharedEventService.closeWidget(this.widgetCodesConst.LoyaltyShift)
    } else {
      this.sharedService.widgetCloseSubject.next(this.widgetCodesConst.LoyaltyShift);
    }
  }

  setHeightLoyaltyShift(){
    //dynamic heigh loyalty shift widget
    var divHeight = <HTMLElement>document.getElementById("lsSce");
    this.lsHeight = 270;
    if(divHeight !=null){
      this.lsHeight = divHeight.clientHeight
    }
  }
  @ViewChild('pictureOf') pictureOf: ElementRef;
  click() {
    if(this.pictureOf && this.pictureOf.nativeElement) {
      // let e = document.getElementById('pictureOf');
      // html2canvas(e, { foreignObjectRendering: true, useCORS: true, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
      html2canvas(this.pictureOf.nativeElement, { scrollX:0, scrollY:-window.scrollY, allowTaint: true, useCORS: false, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
        document.body.appendChild(canvas);
        var img = canvas.toDataURL('image/png');
        const link = document.createElement("a");
        link.href = 'data:' + img;
        link.download = "test.png"
        link.click();
        document.body.removeChild(canvas);
      });
    }
  }
}
