import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'
import { DatePipe } from '@angular/common';

@Pipe({
   name: 'formatDate'
})
export class FilterDatePipe implements PipeTransform {
   transform(date: any, args?: any): any {
     
   // Try and parse the passed value.
   var momentDate = moment(date);

   // If moment didn't understand the value, return it unformatted.
   if (!momentDate.isValid()) return date;

   // Otherwise, return the date formatted as requested.
   //return momentDate.format('dd MMMM yyyy');
   return momentDate.format('DD-MMM-YYYY');
      
   }
}