import {
  AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2, Optional, OnDestroy
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { log } from 'console';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[maxLength]'
})
export class InputMaxLengthDirective  {
  @Input()
  maxLength: number;

  constructor(private el: ElementRef) { }

  @HostListener('input') onInput(event) {
    const length = this.el.nativeElement.value ? this.el.nativeElement.value.length : 0;

    if (length > this.maxLength) {
      this.el.nativeElement.value = this.el.nativeElement.value.substr(0, length - 1);
    }
  }
}