import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';


export interface ComponentCanDeactivateGuard {
  canDeactivate(): boolean | Observable<boolean>;
  confirmationService: ConfirmationService;
}

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
};

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivateGuard> {

  constructor(
  ) {
  }

  canDeactivate(component: ComponentCanDeactivateGuard): boolean | Observable<boolean> {
    return !component.canDeactivate() || this.helpFun();
  }

  helpFun(): boolean {
    if (confirm('You have unsaved data. Do you still want to exit without saving?')) {
      return true;
    }
    if (CanDeactivateState.defendAgainstBrowserBackButton) {
      history.pushState(null, '', '');
    }
    return false;
  }
}
