import { Component, OnInit, EventEmitter, Output, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Message, SelectItem } from 'primeng/components/common/api';
import * as moment from 'moment';
import { ScrollPanel } from 'primeng/scrollpanel';
import { SharedService } from 'src/app/shared/services/shared.service';
import { surveyType } from '../../../shared/constants/shared.constants';
import { LostbusinessCustomerService } from '../service/lostbusiness-customer.service';
import { SurveySubCategory } from '../../../shared/constants/survey.sub.category';
import { Subscription } from 'rxjs';
import { LostBussinesHideAllOtherDialogService } from '../service/lost-bussines-hide-all-other-dialog.service';

@Component({
  selector: 'app-lost-business-filter',
  templateUrl: './lost-business-filter.component.html',
  styleUrls: ['./lost-business-filter.component.scss']
})

export class LostBusinessFilterComponent implements OnInit, OnChanges {

  @Input() surveySubCategory: string = SurveySubCategory.Transactional;
  @Input() surveyType: string = surveyType.LostCustomer;
  passDateToLostFilter: any = null;
  showBusyIndicator = false;
 private lostFilterData: Subscription;

  // Master data properties
  data = [];
  zoneBranch = [];
  countryContinent = [];
  brand = [];
  influencers = [];

  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  brandOriginal = [];
  influencersOriginal = [];
  distributorSegmentOriginal = [];
  distributorOriginal = [];
  industrySegmentOriginal = [];
  famData = [];
  baData = [];
  divData = [];
  countryData = [];
  brandData = [];
  zoneData = [];
  branchData = [];

  distributorData = [];
  distributorSegmentData = [];
  industrySegmentData = [];
  influencerData = [];
  countinentData = [];
  msgs: Message[] = [];
  isShowAlert = false;
  successMsg = false;
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  CustomerName: any;
  loading = true;
  custName: any;

  // for  period selection start and end date calendor
  periodType = {
    M: true,
    Q: false,
    H: false,
    Y: false,
  };

  delimiter = '|';
  filterToggle = false;
  filterDateToggle = false;
  pinFilterSecVisible = false;
  dateTime = new Date();
  defaultDate = new Date();
  isPeriodType = false;
  isDateRange = true;
  StartDate = '';
  EndDate = '';
  startDateCal = '';
  endDateCal = '';
  StartBaseDate = '';
  EndBaseDate = '';
  startBaseCal = '';
  endBaseCal = '';
  filterBaseDateToggle = false;
  baseFilterToggle = false;
  isbaseDateRange = true;
  startBdate = '';
  endBdate = '';

  _ComparismEndDate: any;
  _BaseStartDate: any;
  isValideDates = false;
  subscription:Subscription;
  @Input('npsWidgetStatus') npsWidgetStatus;
  @Input('savedFilterObject') savedFilterObject;
  @Input('savedFilterObjectFromDB') savedFilterObjectFromDB;
  @Output('submitted') submitted: EventEmitter<any> = new EventEmitter<any>(); // this is used for emiting fillter change
  @Output('saved') saved: EventEmitter<string> = new EventEmitter<string>();
  @Output('pinChanged') pinChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output('onPinnedValueChange') onPinnedValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output('reset') reset: EventEmitter<string> = new EventEmitter<string>();
  @Output() ShowNoData = new EventEmitter<boolean>();
  apiResponse = null;

  constructor(private filterService: LostbusinessCustomerService, private sharedService: SharedService, private lostBussinesHideAllOtherDialogService: LostBussinesHideAllOtherDialogService) {
    // this.filterService.LostCountSubject.subscribe(i => {
    //   this.passDateToLostFilter = i;
    //   this.dateUpdateActions();
    // });
    this.subscription = this.filterService.lostFilterData.subscribe(cnt => {
      if (cnt !== null) {
        // this.GetLostCount(cnt);
        this.dateUpdateActions(cnt, false);
      }
    });
    // this.filterService.test.subscribe(cnt => {
    //     console.log('test'); 
    // })
    this.lostBussinesHideAllOtherDialogService.getDialogClickEvent().subscribe((e)=>{
      this.lostBussinessHideEvent(e)
    })
  }

  ngOnInit() {
    let surveyType = 'LostCustomer';
    // this.filterService.filterObj.SubCategory = surveyType.Distributor;
    this.filterService.getUserFilter(this.surveyType).then(res => {
      this.savedFilterObject = this.filterService.filterObj1;
      this.convertToArray();
      this.convertToArrayBasePeriod();
    }).catch(error => {
      this.savedFilterObject = this.filterService.filterObj1;
      this.convertToArray();
      this.convertToArrayBasePeriod();
    });
    // this.getLostCustDropdown();
    const today = new Date();
    this.filterService.NoDataFoundSubject.next(false);
  }
  getSelectedDate(event, dateType) {
    var dateToSelect = 1;
    if (dateType === 'endDate') {
      try {
        if (this.endDateCal != null) {
          dateToSelect = parseInt(this.endDateCal.split('-')[0]);
        }
      } catch { }
    }
    if (dateType === 'startDate') {
      try {
        if (this.startDateCal != null) {
          dateToSelect = parseInt(this.startDateCal.split('-')[0]);
        }
      } catch { }
    }
    var lastDay = new Date(event.year, event.month, 0).getDate();
    if (lastDay < dateToSelect) {
      dateToSelect = lastDay;
    }
    return dateToSelect;
  }

  onMonthChange(event, dateType) {
    var dateToSelect = this.getSelectedDate(event, dateType);
    this.onDateSelection(new Date(event.year, event.month - 1, dateToSelect), dateType);
  }

  onYearChange(event, dateType) {
    var dateToSelect = this.getSelectedDate(event, dateType);
    this.onDateSelection(new Date(event.year, event.month - 1, dateToSelect), dateType);
    this.resetBaseDatefilter(new Date(event.year, event.month - 1, dateToSelect));
  }

  resetDatefilter() {
    this.isDateRange = true;
    this.isPeriodType = true;
    this.periodType.Q = false;
    this.periodType.H = false;
    this.periodType.Y = false;
    this.periodType.M = true;

    const today = new Date();
    this.StartDate = this.changeDateFormate(today);
    this.EndDate = this.changeDateFormate(today);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
    this.calculateStartAndEndDate('M');
  }

  calculateStartAndEndDate(key) {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = date;

    if (key == 'Q') {
      var quarter = Math.floor((date.getMonth() + 3) / 3);
      if (quarter == 1) {
        firstDay = new Date(y, 0, 1);
        lastDay = new Date(y, 2, 31);
      } else if (quarter == 2) {
        firstDay = new Date(y, 3, 1);
        lastDay = new Date(y, 5, 30);
      } else if (quarter == 3) {
        firstDay = new Date(y, 6, 1);
        lastDay = new Date(y, 8, 30);
      } else if (quarter == 4) {
        firstDay = new Date(y, 9, 1);
        lastDay = new Date(y, 11, 31);
      }
    }
    if (key == 'H') {
      var hy = Math.floor((date.getMonth() + 6) / 6);
      if (hy == 1) {
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, 5, 30);
      } else if (hy == 2) {
        var firstDay = new Date(y, 6, 1);
        var lastDay = new Date(y, 11, 31);
      }

    }

    if (key == 'Y') {
      var firstDay = new Date(y, 0, 1);
      var lastDay = new Date(y, 11, 31);
    }

    this.StartDate = this.changeDateFormate(firstDay);
    this.EndDate = this.changeDateFormate(lastDay);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
  }

  onDateSelection(selectedDate, dateType) {

    if (dateType === 'endDate') {
      this._ComparismEndDate = selectedDate;
    }
    this.DateValiditions('COMP');
    // if (!this.isValideDates) {
    //   return;
    // }

    this.isPeriodType = false;
    this.isDateRange = true;
    if (selectedDate != null && dateType == 'startDate') {
      this.startDateCal = this.changeDateFormate(new Date(selectedDate));
      var endDateFormat = this.changeDateFormat(this.EndDate);
      var endDate = new Date(endDateFormat);
      if (moment(selectedDate) <= moment(endDate)) {
        this.StartDate = this.changeDateFormate(new Date(selectedDate));
      } else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
      }
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          this.periodType[key] = false;
        }
      }
    } else if (selectedDate != null && dateType == 'endDate') {
      this.endDateCal = this.changeDateFormate(new Date(selectedDate));
      var startDateFormat = this.changeDateFormat(this.StartDate);
      var startDate = new Date(startDateFormat);
      if (selectedDate >= moment(startDate)) {
        this.EndDate = this.changeDateFormate(new Date(selectedDate));
      } else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'End Date should not be lesser than Start Date');
      }
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          this.periodType[key] = false;
        }
      }
    }

  }

  updatePeriodType(periodType) {
    var today = new Date();
    this.StartDate = this.changeDateFormate(today);
    this.EndDate = this.changeDateFormate(today);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
    for (var key in this.periodType) {
      if (this.periodType.hasOwnProperty(key)) {
        if (key == periodType) {
          this.periodType[key] = true;
          this.calculateStartAndEndDate(key);
        } else {
          this.periodType[key] = false;
        }
      }
    }
    this.isDateRange = false;
    this.isPeriodType = true;
  }

  ngOnChanges() {
    // this.passDateToLostFilter;
    // if (this.passDateToLostFilter.CallFrom == 'GlobalFilter') {
    //   this.startDateCal = this.passDateToLostFilter.StartDate;
    //   this.endDateCal = this.passDateToLostFilter.EndDate;
    //   if (this.passDateToLostFilter.PeriodType != null && this.passDateToLostFilter.PeriodType != '') {
    //     this.isPeriodType = true;
    //     this.updatePeriodType(this.passDateToLostFilter.PeriodType);
    //   }
    //   else{
    //     this.isPeriodType = false;
    //   }
    // }
    // if (this.passDateToLostFilter.ActionType == "Save") {
    //   this.saveMasterFilter(true);
    // }
    // else if (this.passDateToLostFilter.ActionType == "Submit"){
    //   // this.submitDatefilter(this.passDateToLostFilter.CallFrom);
    // }
    // else{
    //   this.convertToArray();
    // }
    //  this.filterService.advancedSearchDateFilterSubject.subscribe(val =>{
    //   if(val == true && val != null){
    //     sessionStorage.setItem("lostPeriodFilterObject", JSON.stringify(this.filterService.filterObj1));
    //     this.filterService.updateFilterObject();
    //    this.StartDate = this.filterService.filterObj1.CompPeriodStartDate;
    //    this.EndDate =this.filterService.filterObj1.CompPeriodEndDate;
    //    this.StartBaseDate = this.filterService.filterObj1.startBasePeriod;
    //    this.EndBaseDate= this.filterService.filterObj1.endBasePeriod;
    //    this.startDateCal =  this.StartDate;
    //    this.endDateCal =  this.EndDate;
    //    this.startBaseCal= this.StartBaseDate;
    //    this.endBaseCal = this.EndBaseDate;
    //   }
    //  })
    //     if (this.npsWidgetStatus != "") {
    //       this.filterToggle = false;
    //       this.filterDateToggle = false;
    //       this.pinFilterSecVisible = false;
    //       this.baseFilterToggle = false;
    //     }
  }

  dateUpdateActions(data, makeAPICall) {
    this.passDateToLostFilter = data;

    if (this.passDateToLostFilter.GlobalDateChanged) {
      if (this.passDateToLostFilter.CallFrom == 'GlobalFilter') {
        this.startDateCal = this.passDateToLostFilter.StartDate;
        this.endDateCal = this.passDateToLostFilter.EndDate;
        if (this.passDateToLostFilter.PeriodType != null && this.passDateToLostFilter.PeriodType != '') {
          this.isPeriodType = true;
          this.updatePeriodType(this.passDateToLostFilter.PeriodType);
        } else {
          this.isPeriodType = false;
        }
      }
    }

    this._ComparismEndDate = this.endDateCal;
    if (this._BaseStartDate === '' || this._BaseStartDate === undefined) {
      this._BaseStartDate = new Date(this.filterService.filterObj1.startBasePeriod);
    }

    if (moment(this._ComparismEndDate) > moment(this._BaseStartDate)) {
      if (this.passDateToLostFilter.ActionType === 'Save') {
        this.saveMasterFilter(true,makeAPICall);
      } else if (this.passDateToLostFilter.ActionType === 'Submit' || !this.passDateToLostFilter.GlobalDateChanged) {
        this.submitDatefilter(this.passDateToLostFilter.CallFrom, makeAPICall);
      } else {
        this.convertToArray();
      }
    } else {
      // show No data found
      this.StartDate = this.startDateCal;
      this.EndDate = this.endDateCal;
      this.ShowNoData.emit(true);
    }

  }

  convertToArray() {
    var periodType = null;
    this.successMsg = false;
    if (this.savedFilterObject != null) {
      if (this.filterService.filterObj1.CompPeriodStartDate != null && this.filterService.filterObj1.CompPeriodStartDate) {
        this.startDateCal = this.filterService.filterObj1.CompPeriodStartDate;
        this.StartDate = this.filterService.filterObj1.CompPeriodStartDate;
      }
      if (this.filterService.filterObj1.CompPeriodEndDate != null && this.filterService.filterObj1.CompPeriodEndDate) {
        this.endDateCal = this.filterService.filterObj1.CompPeriodEndDate;
        this.EndDate = this.filterService.filterObj1.CompPeriodEndDate;
        this._ComparismEndDate = this.filterService.filterObj1.CompPeriodEndDate;
      }
      if (this.filterService.filterObj1.PeriodType != null && this.filterService.filterObj1.PeriodType) {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            if (key == this.filterService.filterObj1.PeriodType) {
              this.periodType[key] = true;
              periodType = key;
              this.calculateStartAndEndDate(key);
            } else {
              this.periodType[key] = false;
            }
          }
        }
      } else {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            this.periodType[key] = false;
          }
        }
      }
    }
    if (this.StartDate != null && this.EndDate != null) {
      this.isPeriodType = false;
      this.isDateRange = true;
    }
    if (periodType != null) {
      this.isPeriodType = true;
      this.isDateRange = false;
    }
    if (this.filterService.filterObj1.CompPeriodEndDate == null && this.filterService.filterObj1.CompPeriodStartDate == null && this.periodType == null) {
      this.periodType.M = true;
      this.calculateStartAndEndDate('M');
    }
  }


  submitDatefilter(callFrom: null,makeAPICall=true) {
    this.DateValiditions('COMP');
    if (!this.isValideDates) {
      return;
    }

    this.successMsg = false;
    var startDate = '';
    var endDate = '';
    var periodType = '';
    if (this.isPeriodType) {
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          if (this.periodType[key] == true) {
            periodType = key;
          }
        }
      }
    } else {
      var startDateFormat = this.changeDateFormat(this.startDateCal);
      var startDateVal = new Date(startDateFormat);
      var endDateFormat = this.changeDateFormat(this.endDateCal);
      var endDateVal = new Date(endDateFormat);

      if (moment(startDateVal) <= moment(endDateVal)) {
        this.StartDate = this.startDateCal;
        startDate = this.startDateCal;
      } else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
        return;
      }
      if (moment(endDateVal) >= moment(startDateVal)) {
        this.EndDate = this.endDateCal;
        endDate = this.endDateCal;
      } else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'End Date should not be less than Start Date');
        return;
      }
    }

    var filterObject = {
      'StartDate': startDate,
      'EndDate': endDate,
      'PeriodType': periodType,
    };

    sessionStorage.setItem('lostPeriodFilterObject', JSON.stringify(filterObject));
    this.filterService.updateFilterObject();
    this.filterToggle = false;
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
    // this.filterService.filterObj1.FilterBy = '';
    // this.filterService.filterObj1.FilterString = null;
    // this.filterService.filterObj1.AccountNo = null;
    this.submitted.emit(this.filterService.filterObj1);
    if(makeAPICall){
        this.filterService.LostCountSubject.next(this.filterService.filterObj1);
    }
   
  }

  saveMasterFilter(isDate = false,makeAPICall=true) {
    this.DateValiditions('COMP');
    if (!this.isValideDates) {
      return;
    }

    var filterObject = null;
    if (isDate) {
      var startDate = '';
      var endDate = '';
      var periodType = '';
      if (this.isPeriodType) {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            if (this.periodType[key] == true) {
              periodType = key;
            }
          }
        }
      } else {
        var startDateFormat = this.changeDateFormat(this.startDateCal);
        var startDateVal = new Date(startDateFormat);
        var endDateFormat = this.changeDateFormat(this.endDateCal);
        var endDateVal = new Date(endDateFormat);
        if (moment(startDateVal) <= moment(endDateVal)) {
          this.StartDate = this.startDateCal;
          startDate = this.startDateCal;
        } else {
          this.successMsg = true;
          this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
          return;
        }
        if (moment(endDateVal) >= moment(startDateVal)) {
          endDate = this.endDateCal;
          this.EndDate = this.endDateCal;
        } else {
          this.successMsg = true;
          this.callErrorMsg('error', '', 'End Date should not be lesser than Start Date');
          return;
        }
      }
      filterObject = {
        'StartDate': startDate,
        'EndDate': endDate,
        'PeriodType': periodType,
      };
    } else {
      // filterObject = this.convertToDelimiterSeparated(this.delimiter);
      // var piinedFieldsArray = [];
      // for (var key in this.pinnedFields) {
      //   if (this.pinnedFields.hasOwnProperty(key)) {
      //     if (this.pinnedFields[key] == true) {
      //       piinedFieldsArray.push(key);
      //     }
      //   }
      // }
      // filterObject["PinnedField"] = piinedFieldsArray.join("|");
    }

    this.filterService.saveUserFilter(filterObject, isDate, this.surveyType).then(res => {
      if (res != null && res.Status == 'Success') {
        if (isDate) {
          // filterObject = this.filterService.filterObj;
          sessionStorage.setItem('lostPeriodFilterObject', JSON.stringify(filterObject));
          this.filterDateToggle = false;
        } else {
          sessionStorage.setItem('lostFilterObjectRelationship', JSON.stringify(filterObject));
          this.filterToggle = false;
        }
        this.filterService.updateFilterObject();
        // this.setPinnedFilterValue();
        // this.filterService.filterObj1.FilterBy = '';
        // this.filterService.filterObj1.FilterString = null;
        // this.filterService.filterObj1.AccountNo = null;
        this.submitted.emit(this.filterService.filterObj1);
        if(makeAPICall){
           this.filterService.LostCountSubject.next(this.filterService.filterObj1);
        }
      
        this.pinFilterSecVisible = false;
        this.saved.emit('Success');
      } else {
        this.saved.emit('Failure');
      }
    }).catch(e => {
      this.saved.emit('ApiError');
    });
  }

  clearUserFilter() {
    this.filterService.clearUserFilter(this.surveyType).then(res => {
      if (res.Status == 'Success' && res.Response === true) {
        // this.setPinnedFilterValue();
        var date = new Date();
        var fromDate = this.changeDateFormate(new Date(date.getFullYear(), date.getMonth(), 1));
        var toDate = this.changeDateFormate(date);

        this.isDateRange = true;
        this.isPeriodType = true;
        this.periodType.Q = false;
        this.periodType.H = false;
        this.periodType.Y = false;
        this.periodType.M = false;

        this.StartDate = fromDate;
        this.EndDate = toDate;
        this.startDateCal = this.StartDate;
        this.endDateCal = this.EndDate;

        for (var key in this.filterService.filterObj1) {
          if (this.filterService.filterObj1.hasOwnProperty(key)) {
            this.filterService.filterObj1[key] = '';
          }
        }

        this.filterService.filterObj1.CompPeriodStartDate = fromDate;
        this.filterService.filterObj1.CompPeriodEndDate = toDate;

        // this.filterService.filterObj1;
        // var filterObjMain = {
        //   "BACode": "",
        //   "DivCode": "",
        //   "FAMCode": "",
        //   "BrandName": "",
        //   "Country": "",
        //   "Zone": "",
        //   "Branch": "",
        //   "Influencer": "",
        //   "Continent": "",
        //   "Distributor" : "",
        //   "DistributorSegment" : "",
        //   "PinnedField": ""
        // }
        // var filterObjDate = {
        //   "StartDate": fromDate,
        //   "EndDate": toDate,
        //   "PeriodType": ""
        // }
        // sessionStorage.setItem("lostFilterObjectRelationship", JSON.stringify(filterObjMain));
        // sessionStorage.setItem("lostPeriodFilterObject", JSON.stringify(filterObjDate));

        this.callErrorMsg('success', '', 'Saved filter cleared successfully.');
      } else {
        this.callErrorMsg('error', '', 'Unable to clear saved filter.');
      }
    });
  }

  toggleFilter() {
    this.filterToggle = !this.filterToggle;

    if (this.filterToggle) {
      this.convertToArray();
      // this.applyCasecadingOnList();
    }
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
  }

  toggleDateFilter() {
    this.lostBussinesHideAllOtherDialogService.dialogClickEvent('toggleDateFilter')
    this.filterDateToggle = !this.filterDateToggle;
    if (this.filterDateToggle) {
      this.convertToArray();
    }
    this.filterToggle = false;
    this.pinFilterSecVisible = false;
  }

  closeInlineFilter() {
    this.pinFilterSecVisible = false;
  }

  private getDistinct(list, key): any[] {
    var uniqueLists = [];
    var ids = [];
    list.forEach(element => {
      if (!this.includes(ids, element[key]) && element[key] != null) {
        uniqueLists.push(element);
        ids.push(element[key]);
      }
    });
    uniqueLists = uniqueLists.sort(function (a, b) {
      return a.label > b.label ? 1 : -1;
    });
    return uniqueLists;
  }

  private includes(container, value) {
    var returnValue = false;
    var pos = container.indexOf(value);
    if (pos >= 0) {
      returnValue = true;
    }
    return returnValue;
  }

  private changeDateFormat(data) {
    var splitDate = data.split('-');
    var month_names = ['Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec'];
    var indexMonth = month_names.indexOf(splitDate[1]);
    return (indexMonth + 1) +
      '/' + splitDate[0] +
      '/' + splitDate[2];
  }

  private changeDateFormate(date) {
    // Try and parse the passed value.
    var momentDate = moment(date);
    return momentDate.format('DD-MMM-YYYY');
  }

  callErrorMsg(type, summary = '', detail = '') {
    this.successMsg = true;
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () {
      try {
        document.getElementById('ClearMsg').click();
      } catch (ex) { }
    }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }

  closeFilterPanel() {
    this.filterToggle = false;
    this.filterDateToggle = false;
  }

  @ViewChild('scrollPanel') private myScrollContainer;
  hideScroll = false;
  scrollPos(event) {
    this.hideScroll = true;
  }

  BasetoggleDateFilter() {
    this.lostBussinesHideAllOtherDialogService.dialogClickEvent('BaseFillterClicked')
    this.baseFilterToggle = !this.baseFilterToggle;
    if (this.baseFilterToggle) {
      this.convertToArrayBasePeriod();
    }
    // this.baseFilterToggle = false;
    this.pinFilterSecVisible = false;
  }
  private changeBaseDateFormate(date) {
    // Try and parse the passed value.
    var momentDate = moment(date);
    // return momentDate.format('DD-MMM-YYYY');
    return momentDate.format('MMM-YYYY');

  }

  resetBaseDatefilter(dateToSelect:any = null) {
    this.isbaseDateRange = true;
    const today = dateToSelect ? new Date(dateToSelect): new Date();
    // this.StartDate = this.changeBaseDateFormate(today);
    // this.EndDate = this.changeBaseDateFormate(today);
    // this.startDateCal = this.StartDate;
    // this.endDateCal = this.EndDate;
    // this.startBaseCal = ;
    var sb = this.changeBaseDateFormate(today);
    var trimDate = sb;
    var splitdate = trimDate.split('-');
    var year = splitdate[1];
    // Start date

    //var yearvalue = parseInt(year) - 3;
    ///Ticket - 62894 - Dashborad optimization - Reduced default comparison year
    var yearvalue = parseInt(year) - 1; 
    var sbdate = new Date(yearvalue, 0, 1);
    var changedate = this.changeDateFormate(new Date(sbdate));
    this.startBdate = changedate;
    this.startBaseCal = this.TrimDateInMMYYFormat(changedate);

    // End date
    var ebdate = new Date(yearvalue, 11, 31);
    var changedateformat = this.changeDateFormate(new Date(ebdate));
    this.endBdate = changedateformat;
    this.endBaseCal = this.TrimDateInMMYYFormat(changedateformat);    
    
  }


  saveBaseFilter(isDate = false) {

    this.DateValiditions('BASE');
    if (!this.isValideDates) {
      return;
    }

    if (isDate) {

      if (moment(this.startBdate) >= moment(this.endBdate)) {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
        return;
      } else {
        this.StartBaseDate = this.startBdate;
        this.EndBaseDate = this.endBdate;
      }

      // var periodType = '';
      // var startBasePeriod = '';
      // var endBasePeriod = '';

      // var startDateFormat = this.changeBaseDateFormat(this.startBdate);
      // var basestartDateVal = new Date(startDateFormat);
      // var endDateFormat = this.changeBaseDateFormat(this.endBdate);
      // var baseendDateVal = new Date(endDateFormat);
      // var compDate = new Date(this.filterService.filterObj1.CompPeriodStartDate);
      // var compendDate = new Date(this.filterService.filterObj1.CompPeriodEndDate);
      // if ((moment(basestartDateVal) <= moment(compDate) && ((moment(baseendDateVal) >= moment(compendDate)) || (moment(baseendDateVal) <= moment(compendDate))))) {
      //   if (moment(basestartDateVal) <= moment(baseendDateVal)) {
      //     this.StartBaseDate = this.startBdate;
      //     startBasePeriod = this.startBdate;
      //   } else {
      //     this.successMsg = true;
      //     this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
      //     return;
      //   }

      //   if (moment(baseendDateVal) >= moment(basestartDateVal)) {
      //     this.EndBaseDate = this.endBdate;
      //     endBasePeriod = this.endBdate;
      //   } else {
      //     this.successMsg = true;
      //     this.callErrorMsg('error', '', 'End Date should not be less than Start Date');
      //     return;
      //   }
      // } else {
      //   this.successMsg = true;
      //   this.callErrorMsg('error', '', 'Base Period should not be greater than Comparison Period');
      //   return;
      // }

    }

    let filterObject = null;
    filterObject = {
      'startBasePeriod': this.startBdate,
      'endBasePeriod': this.endBdate,
    };

    this.filterService.saveUserFilter(filterObject, isDate, this.surveyType).then(res => {
      if (res != null && res.Status == 'Success') {
        this.baseFilterToggle = false;
        if (isDate) {
          sessionStorage.setItem('lostBasePeriodFilterObject', JSON.stringify(filterObject));
          this.filterDateToggle = false;
        } else {
          sessionStorage.setItem('lostFilterObjectRelationship', JSON.stringify(filterObject));
          this.filterToggle = false;
        }
        this.filterService.updateFilterObject();
        // this.filterService.filterObj1.FilterBy = '';
        // this.filterService.filterObj1.FilterString = null;
        // this.filterService.filterObj1.AccountNo = null;
        this.submitted.emit(this.filterService.filterObj1);
        this.filterService.LostCountSubject.next(this.filterService.filterObj1);
        this.pinFilterSecVisible = false;
        this.saved.emit('Success');
      } else {

        this.saved.emit('Failure');
      }
    }).catch(e => {
      this.saved.emit('ApiError');
    });
  }

  private changeBaseDateFormat(data) {
    var splitDate = data.split('-');
    var month_names = ['Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec'];
    var indexMonth = month_names.indexOf(splitDate[1]);
    return (indexMonth + 1) +
      '/' + splitDate[0] +
      '/' + splitDate[2];
  }

  onBaseMonthChange(event, dateType) {
    var dateToSelect = this.getSelectedDate(event, dateType);
    this.onBaseDateSelection(new Date(event.year, event.month - 1, dateToSelect), dateType);
  }

  onBaseYearChange(event, dateType) {
    var dateToSelect = this.getSelectedDate(event, dateType);
    this.onBaseDateSelection(new Date(event.year, event.month - 1, dateToSelect), dateType);
  }
  daysInMonth(month, year) {
    month = month.toLowerCase();
    var months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    month = months.indexOf(month);
    var days = new Date(year, month + 1, 0).getDate();
    return days;
  }
  onBaseDateSelection(selectedDate, dateType) {

    if (dateType === 'startBaseDate') {
      this._BaseStartDate = selectedDate;
    }
    this.DateValiditions('BASE');
    // if (!this.isValideDates) {
    //   return;
    // }

    this.isPeriodType = false;
    this.isbaseDateRange = true;
    if (selectedDate != null && dateType == 'startBaseDate') {
      this.startBdate = this.changeDateFormate(new Date(selectedDate));
      var SBDate = this.TrimDateInMMYYFormat(this.startBdate);
      this.startBaseCal = SBDate;
      var dt = this.EndBaseDate.split('-');
      var month = dt[0];
      var year = dt[1];

      var days = this.daysInMonth(month, year);
      this.EndBaseDate = [days, month, year].join('-');


      // var endDateFormat = this.changeDateFormat(this.EndBaseDate);
      var endDateFormat = this.changeDateFormat(this.endBdate);
      var endDate = new Date(endDateFormat);

      if (moment(selectedDate) <= moment(endDate)) {
        this.StartBaseDate = this.changeDateFormate(new Date(selectedDate));
      } else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
      }
    } else if (selectedDate != null && dateType == 'endBaseDate') {

      this.endBdate = this.changeDateFormate(new Date(selectedDate));
      var EBDate = this.TrimDateInMMYYFormat(this.endBdate);
      this.endBaseCal = EBDate;

      var dt = this.endBdate.split('-');
      var day = dt[0];
      var month = dt[1];
      var year = dt[2];

      var days = this.daysInMonth(month, year);
      this.endBdate = [days, month, year].join('-');


      // var startDateFormat = this.changeDateFormat(this.StartBaseDate);
      var startDateFormat = this.changeDateFormat(this.startBdate);
      var startDate = new Date(startDateFormat);
      if (selectedDate >= moment(startDate)) {
        this.EndBaseDate = this.changeDateFormate(new Date(selectedDate));
      } else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'End Date should not be lesser than Start Date');

      }

    }
  }
  submitBaseDatefilter() {

    this.DateValiditions('BASE');
    if (!this.isValideDates) {
      return;
    }
    this.successMsg = false;
    var startBasePeriod = '';
    var endBasePeriod = '';

    var startDateFormat = this.changeBaseDateFormat(this.startBdate);
    var basestartDateVal = new Date(startDateFormat);
    var endDateFormat = this.changeBaseDateFormat(this.endBdate);
    var baseendDateVal = new Date(endDateFormat);

    if (moment(basestartDateVal) <= moment(baseendDateVal)) {
      this.StartBaseDate = this.startBdate;
      startBasePeriod = this.startBdate;
    } else {
      this.successMsg = true;
      this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
      return;
    }
    if (moment(baseendDateVal) >= moment(basestartDateVal)) {

      this.EndBaseDate = this.endBdate;
      endBasePeriod = this.endBdate;
    } else {
      this.successMsg = true;
      this.callErrorMsg('error', '', 'End Date should not be less than Start Date');
      return;
    }


    var filterObject = {
      'startBasePeriod': startBasePeriod,
      'endBasePeriod': endBasePeriod,
    };
    sessionStorage.setItem('lostBasePeriodFilterObject', JSON.stringify(filterObject));
    this.filterService.updateFilterObject();
    this.filterToggle = false;
    this.baseFilterToggle = false;
    this.pinFilterSecVisible = false;
    //this.filterService.filterObj1.FilterBy = '';
    //this.filterService.filterObj1.FilterString = null;
    //this.filterService.filterObj1.AccountNo = null;
    this.submitted.emit(this.filterService.filterObj1);
    this.filterService.LostCountSubject.next(this.filterService.filterObj1);

  }
  closeBaseFilterPanel() {
    this.baseFilterToggle = false;
    this.filterBaseDateToggle = false;
  }

  TrimDateInMMYYFormat(date) {
    var trimDate = date;
    var splitdate = trimDate.split('-');
    var year = splitdate[1];
    var month = splitdate[2];
    var MMYYdate = year.concat(month);
    var mmyy = [year, month].join('-');
    return mmyy;

  }
  convertToArrayBasePeriod() {
    var periodType = null;
    if (this.savedFilterObject != null) {
      if (this.filterService.filterObj1.startBasePeriod != null && this.filterService.filterObj1.startBasePeriod) {
        var startdt = this.TrimDateInMMYYFormat(this.filterService.filterObj1.startBasePeriod);
        // this.startBaseCal = this.filterService.filterObj1.startBasePeriod;
        this.startBaseCal = startdt;
        this.StartBaseDate = startdt;
        this.startBdate = this.filterService.filterObj1.startBasePeriod;
      }
      if (this.filterService.filterObj1.endBasePeriod != null && this.filterService.filterObj1.endBasePeriod) {
        var enddt = this.TrimDateInMMYYFormat(this.filterService.filterObj1.endBasePeriod);
        // this.endBaseCal = this.filterService.filterObj1.endBasePeriod;
        this.EndBaseDate = enddt;
        this.endBaseCal = enddt;
        this.endBdate = this.filterService.filterObj1.endBasePeriod;
      }

    }
    if (this.StartBaseDate != null && this.EndBaseDate != null) {
      this.isPeriodType = false;
      this.isbaseDateRange = true;
    }
    if (periodType != null) {
      this.isPeriodType = true;
      this.isbaseDateRange = false;
    }
  }

  DateValiditions(type = 'COMP') {
    this.isValideDates = false;
    if (this._ComparismEndDate === '' || this._ComparismEndDate === undefined || !moment(this._ComparismEndDate).isValid()) {
      if (moment(this.filterService.filterObj1.CompPeriodEndDate).isValid()) {
        this._ComparismEndDate = new Date(this.filterService.filterObj1.CompPeriodEndDate);
      } else {
        this._ComparismEndDate = this.endDateCal;
      }
    }

    if (this._BaseStartDate === '' || this._BaseStartDate === undefined || !moment(this._BaseStartDate).isValid()) {
      if (moment(this.filterService.filterObj1.startBasePeriod).isValid()) {
        this._BaseStartDate = new Date(this.filterService.filterObj1.startBasePeriod);
      } else {
        this._BaseStartDate = this.startBaseCal;
      }
    }

    if (moment(this._ComparismEndDate) > moment(this._BaseStartDate)) {
      this.isValideDates = true;
    } else {
      if (type === 'COMP') {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Comparison Period should not be less than Base Period');
      } else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Base Period should not be greater than Comparison Period');
      }
      this.isValideDates = false;
    }
  }
  lostBussinessHideEvent(e){
    if(e!=='BaseFillterClicked'){
      this.baseFilterToggle=false;
    }
    if(e!=='toggleDateFilter'){
      this.filterDateToggle=false;
    }
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
