import { widgetCodes } from './../../../shared/constants/shared.constants';
import { RelationshipDashboardSharedEventService } from './../../../shared/services/relationship-dashboard-shared-event.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as _html2canvas from "html2canvas";
const html2canvas:any = _html2canvas;
import { ExportChartModel } from 'src/app/shared/models/export-chart.model';
import { surveyType } from "src/app/shared/constants/shared.constants";
import { HideOtherOpenDrilldownsService } from '../../../Modules/Distributor_Survey/Services/hide-other-open-drilldowns.service';
import { environment } from 'src/environments/environment';
const WIDGET_CODE = 'sis';

@Component({
  selector: 'app-invitations-sent',
  templateUrl: './invitations-sent.component.html',
  styleUrls: ['./invitations-sent.component.scss']
})
export class InvitationsSentComponent implements OnInit, OnChanges, OnDestroy {
  @Output('showChartList') showChartList = new EventEmitter<{ showCharts: boolean, chartsToShow: any[] }>();
  widgetCodesConst;

  private chartState: CHART_STATE_OPTIONS;
  private exportSubscription: Subscription;
  @ViewChild('pictureOf') pictureOf: ElementRef;
  dashboardImageUrl = this.sharedService.configuration.DashboardImagePath;
  constructor(private sharedService: SharedService, private rdSharedEventService: RelationshipDashboardSharedEventService, private hideOtherOpenDrilldownsService : HideOtherOpenDrilldownsService) {
    this.widgetCodesConst = widgetCodes;
    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(val => val !== null && val.includes(WIDGET_CODE.toLowerCase()))).subscribe(val => {
      if (this.sharedService.acceptableChartState.includes(this.chartState)) {
        this.toCanvas();
      }
    });
   }


  @Input('invitationsSent') invitationsSent;
  @Input('boolInvitationSentLoader') boolInvitationSentLoader;
  @Input() errorData: boolean;
  @Input() surveyTypeInput: string = surveyType.Transactional;
  private imagepath = environment.imagepath;
  ngOnInit() {
  }
  ngOnDestroy(): void {
    this.updateExportDetails(true);
    this.exportSubscription.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    // this.determineWidgetState();
    this.updateExportDetails();
  }

  showSsrChart() {
    this.showChartList.emit({ showCharts: true, chartsToShow: null });
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('')
    // this.sharedService.changeWidgetDDLocation(this.getChartData());
  }


  
  closeWidget() {
    if (this.surveyTypeInput != null && (this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput == 'OEM')) {
      this.rdSharedEventService.closeWidget(this.widgetCodesConst.SurveyInvitationSent)
    } else {
      this.updateExportDetails(true);
      this.sharedService.widgetCloseSubject.next(this.widgetCodesConst.SurveyInvitationSent);
    }
  }

  getChartData() {
    return [
      {
        "WidgetName": "Net Promoter Score Trend",
        "Position": "D1",
        "IsVisible": false,
        "WidgetCode": "NPST",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashboardImageUrl,
        "IsDefaultView": false
      },
      {
        "WidgetName": "Survey Sent vs. Response Recieved",
        "Position": "D3",
        "IsVisible": true,
        "WidgetCode": "SSRR",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashboardImageUrl,
        "IsDefaultView": false
      },
      {
        "WidgetName": "Country Comparision",
        "Position": "D4",
        "IsVisible": false,
        "WidgetCode": "CC",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashboardImageUrl,
        "IsDefaultView": false
      },
      {
        "WidgetName": "Loyalty Shift",
        "Position": "D5",
        "IsVisible": false,
        "WidgetCode": "LSDD",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashboardImageUrl,
        "IsDefaultView": false
      },
      {
        "WidgetName": "Response Rate Trend",
        "Position": "D2",
        "IsVisible": false,
        "WidgetCode": "RRT",
        "Category": "D",
        "TouchPoint": "",
        "IsSaved": false,
        "WidgetImage": this.dashboardImageUrl,
        "IsDefaultView": false
      }
    ];
  }

  private toCanvas = () => {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    try {
      if (this.pictureOf && this.pictureOf.nativeElement) {
        html2canvas(this.pictureOf.nativeElement, { scrollX:0, scrollY:-window.scrollY, allowTaint: false, useCORS: false, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
          chartUpdate.UpdateTime = new Date();
          chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
          chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
          chartUpdate.CurrentState = this.chartState;
          chartUpdate.ChartImgBase64 = canvas.toDataURL('image/png');
          this.sharedService.updateChartStatusSubject(chartUpdate);
        },
          err => {
            console.log('error occurred during image export, details as below \n', err);
            chartUpdate = this.exportErrorUpdate();
            this.sharedService.updateChartStatusSubject(chartUpdate);
          });
      } else if(this.chartState === CHART_STATE_OPTIONS.NO_DATA) {
          chartUpdate.UpdateTime = new Date();
          chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
          chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
          chartUpdate.CurrentState = this.chartState;
          chartUpdate.ChartImgBase64 = 'no_image_data_available_for_this_state';
          chartUpdate.HasNoData = true;
          this.sharedService.updateChartStatusSubject(chartUpdate);
      }
    } catch (error) {
      console.log('html2canvas error as', error);
      chartUpdate = this.exportErrorUpdate();
      this.sharedService.updateChartStatusSubject(chartUpdate);
    }
  }
  determineWidgetState() {
    if(this.boolInvitationSentLoader) this.chartState = CHART_STATE_OPTIONS.LOADING;
    else if(this.errorData) this.chartState = CHART_STATE_OPTIONS.ERROR;
    else if(this.boolInvitationSentLoader == false && !this.errorData) this.chartState = CHART_STATE_OPTIONS.SUCCESS;
    else this.chartState = CHART_STATE_OPTIONS.NO_DATA;
  }
  updateExportDetails(isDestroyCall: boolean = false) {
    this.determineWidgetState();
    let chartUpdate: ExportChartModel = new ExportChartModel();
    chartUpdate.UpdateTime = new Date();
    chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
    if (isDestroyCall) {
      this.chartState = CHART_STATE_OPTIONS.UNKNOWN;
    }
    chartUpdate.CurrentState = this.chartState;
    this.sharedService.updateChartStatusSubject(chartUpdate);
  }
  exportErrorUpdate = (): ExportChartModel => {
    let err: ExportChartModel = new ExportChartModel();
    err.HasErrorInExport = true;
    err.UpdateTime = new Date();
    err.ChartName = WIDGET_CODE.toLowerCase();
    err.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
    err.CurrentState = this.chartState;
    return err;
  }

}
