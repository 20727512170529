import { from } from 'rxjs';
import { Component, OnInit, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { UserManagementService } from '../../falcon-setup/user-management/services/user-management.service';
import { GalleriaModule } from 'primeng/galleria';
import { UserAccessComponent } from '../../falcon-setup/user-management/user-access/user-access.component';
import { DynamicDialogConfig, SelectItem } from 'primeng/api';
import { Message } from 'primeng/components/common/api';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HeaderService } from 'src/app/shared/services/header.service';
import { Paginator } from 'primeng/paginator';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  private imagepath = environment.imagepath;
  @ViewChild(Paginator) dt: Paginator;
  UserID: any;
  RoleID: any;
  image: any;
  pageNo: any = 0;
  pageSize: any = 0;
  isShowAccess = false;
  ProfieForm: FormGroup;
  isEdit = false;
  isShow = true;
  successMsg = false;
  errorMessages: string;
  ext: string;
  fileName: string;
  blobFileName: string;
  msgs: Message[] = [];
  userAccessList = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  loading = false;
  displayChange = new EventEmitter();
  userId: number; // Requested UserID
  roleId: number;
  configRow: number[] = [10, 20, 30, 40, 50];
  userSelectedRoles = [];
  totalRecords: any = [];
  cols: any = [];
  searchField: any = '';
  RoleMasterWithID = [];
  isInvalidProfileFirstName = true;
  isInvalidProfileLastName = true;
  submitted: boolean;
  userData: any = {
    'FirstName': '',
    'LastName': '',
    'EmailId': '',
    'ReportingFAM': '',
    'Country': '',
    'Roles': [],
    'UserID': '',
    'PhotoUrl': '',
    'SelectedRoles': []
  };
  AddUserName: any = {
    UserID: 0,
    FirstName: '',
    LastName: ''
  };

  AddUserModel: any = {
    UserID: 0,
    FirstName: '',
    LastName: '',
    EmailID: '',
    UserName: '',
    PhotoURL: '',
    AboutUser: '',
    PhoneNumber: '',
    FamCode: '',
    Country: null,
    Roles: []
  };
  sortObj: any = {
    'sortType': '',
    'sortField': ''
  };
  random: any;
  genericErrorMessage = 'An error occurred, please contact customerloyalty@atlascopco.com';
  isMyProfile: boolean = true;
  defaultImagePath = this.imagepath + 'no_profile_picture.png';
  format = /[ !@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]/;
  containsNumber = /\d+/;

  paginatorFirstSelector: any;
  paginatorLaststSelector: any;
  paginatorNextSelector: any;
  paginatorPrevSelector: any;

  constructor(private formBuilder: FormBuilder,
    private userManagementService: UserManagementService,
    public config: DynamicDialogConfig, private headerService: HeaderService,
    private sharedService: SharedService) { }

  ngOnInit() {

    this.ProfieForm = this.formBuilder.group({
      'FirstName': new FormControl('', [Validators.required, Validators.maxLength(255), Validators.pattern('^[a-zA-Z.-]+( [a-zA-Z.-]+)*$')]),
      'LastName': new FormControl('', [Validators.required, Validators.maxLength(255), Validators.pattern('^[a-zA-Z.-]+( [a-zA-Z.-]+)*$')]),
    });

    this.random = Math.random();
    this.isMyProfile = this.config.data.isMyProfile ? true : false;
    this.showUserDetails(); // show user specific details

    this.cols = [
      { field: 'BAs', header: 'BA' },
      { field: 'DIVs', header: 'DIV' },
      { field: 'BLs', header: 'BL' },
      { field: 'FAMs', header: 'FAM' },
      { field: 'Brands', header: 'Brand' },
      { field: 'Zones', header: 'Zone' },
      { field: 'Branch', header: 'Branch' },
      { field: 'CountryIDs', header: 'Country' },
    ];
  }

  lazyLoad(event) {
    if (event.sortField) {
      event.sortOrder = event.sortOrder === 1 ? 'asc' : 'dsc';
      this.GetUserAccess(1, this.pageSize, event.sortOrder, event.sortField, this.searchField, null, null);
      this.sortObj.sortType = event.sortOrder;
      this.sortObj.sortField = event.sortField;
    }
  }

  pageToolTip() {
    let i = 0;
    this.paginatorFirstSelector = document.querySelectorAll('a.ui-paginator-first');
    for (i = 0 ; i <  this.paginatorFirstSelector.length ; i++) {
      this.paginatorFirstSelector[i].title = 'First';
    }

    this.paginatorLaststSelector =document.querySelectorAll('a.ui-paginator-last');
    for (i = 0 ; i <  this.paginatorLaststSelector.length ; i++) {
       this.paginatorLaststSelector[i].title = 'Last';
    }

    this.paginatorNextSelector = document.querySelectorAll('a.ui-paginator-next');
    for (i = 0 ; i <  this.paginatorNextSelector.length ; i++) {
      this.paginatorNextSelector[i].title = 'Next';
    }

    this.paginatorPrevSelector = document.querySelectorAll('a.ui-paginator-prev');
    for(i = 0 ; i <  this.paginatorPrevSelector.length ; i++) {
        this.paginatorPrevSelector[i].title = 'Previous';
    }
  }

  myFiles: string[] = [];
  @ViewChild('myInput') myInputVariable: ElementRef;
  url: any = "";
  hideSpan: boolean = false;

  uploadEventFile(event) {
      const checkfiletype = event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png';
      if (!checkfiletype) {
        this.errorMessages = 'The file extension/type is not supported by the application. Application only supports .jpg, .jpeg and .png';
        this.callErrorMsg('error', this.errorMessages);
        return;
      }

    this.myFiles = [];
    const frmData = new FormData();
    if (event.target.files.length === 0) { return; }
    for (let i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }

    var file = event.target.files[0];
    for (var i = 0; i < this.myFiles.length; i++) {
      frmData.append('fileUpload', this.myFiles[i]);

    }
    let fname;
    if (this.UserID == 0) {
      fname = '';
    } else {
      fname = this.UserID;
    }
    this.userManagementService
      .UploadUserDP(frmData, fname)
      .then(rawData => {
        const rawResponse: any = rawData;
        if (rawResponse.Status === 'Success') {
          if (rawData) {
            this.ext = rawData.Response.ImageName.split('.').pop();
            this.blobFileName = this.AddUserModel.UserID + '_' + Date.now() + '.' + this.ext;
            // rawData.Response.ImageName = this.blobFileName;
            this.url = rawData.Response.ImagePath;
            this.AddUserModel.PhotoURL = rawData.Response.ImageName;
            this.hideSpan = true;
          }

        }
        if (rawResponse.Status === 'Failure' || rawResponse.Status === null) {
          this.successMsg = true;
          this.errorMessages = rawResponse.Errors.map(dat => dat.Message).join(
            ', '
          );
          this.errorMessages =
            '' + this.errorMessages;
          this.callErrorMsg('error', '', this.errorMessages);
        }
      }).catch(e => {

      });
  }
  // severity: info/success/warn/error
  callErrorMsg(type, summary = '', detail = '') {
    this.successMsg = true;
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () { document.getElementById('ClearMsgUserRoles').click(); }, this.MessageTimeOut);
  }

  userRoleEventHandler(event) {

    if (event.ShowAccess) {
      this.isShowAccess = true;
    }
  }

  showUserDetails() {
    this.image = [];
    this.userManagementService.getUserDetails().then(rawData => {

      this.userData.FirstName = rawData.Response.FirstName;
      this.userData.LastName = rawData.Response.LastName;
      
      this.userData.EmailId = rawData.Response.EmailID;
      this.userData.ReportingFAM = rawData.Response.FAM;
      this.userData.Country = rawData.Response.Country;
      this.userData.SelectedRoles = rawData.Response.SelectedRoles;
      this.userData.UserID = rawData.Response.UserID;
      this.userSelectedRoles = this.userData.SelectedRoles;
      this.UserID = rawData.Response.UserID;
      this.AddUserModel.Roles = rawData.Response.SelectedRoles;
      this.userData.Roles = this.userData.SelectedRoles.map(e => e.RoleName).join(', ');

      // User click on edit then first name and last name shown in textbox
      this.AddUserModel.FirstName = this.userData.FirstName;
      this.AddUserModel.LastName = this.userData.LastName;
      this.ProfieForm.patchValue({
        FirstName: this.userData.FirstName,
        LastName:this.userData.LastName
      })
      if (this.pageNo === 0 && this.pageSize === 0) {
        this.pageNo = 1;
        this.pageSize = 10;
        this.GetUserAccess(this.pageNo, this.pageSize, null, null, null, this.userData.SelectedRoles, this.userData.UserID);
      }

      if (rawData.Response.PhotoURL) {
        this.hideSpan = true;
        var photourl = rawData.Response.PhotoURL;

        if (photourl.indexOf('/') != '-1' && photourl.indexOf('?') != '-1') {
            rawData.Response.ImageName = photourl.substring(photourl.lastIndexOf('/') + 1, photourl.lastIndexOf('?'));
          }

        this.AddUserModel.PhotoURL = rawData.Response.ImageName;
        this.url = rawData.Response.PhotoURL;
      }
    });
  }

  callDefaultMessage() {
    this.successMsg = true;
    this.callErrorMsg('error', '', this.genericErrorMessage);
    window.scrollTo(0, 0);
  }

  GetUserAccess(pageNumber = 1, pageSize = 10, sortOrder = '', sortField = '', searchField = '', roleId, requestedUserId) {
    this.roleId = typeof (roleId) === 'object' ? roleId[0].RoleId : roleId;
    if (roleId != null) {
      this.loading = true;
      this.userAccessList = [0];
      const urlParams = '?roleID=' + this.roleId + '&requestedUserID=' + requestedUserId
      + '&pageNo=' + pageNumber + '&pageSize=' + pageSize + '&sortType=' + sortOrder + '&sortField=' + sortField;
      this.userManagementService.getAccessList(urlParams).then(apiResponse => {
        if (apiResponse != null) {
          if (apiResponse.Status === 'Failure') {
            this.pageToolTip();
            if (apiResponse.Errors != null) {
              if (apiResponse.Errors.length > 0 && apiResponse.Errors.length <= 1) {
                this.successMsg = true;
                this.callErrorMsg('error', '', apiResponse.Errors[0].Message);
                this.loading = false;
              } else if (apiResponse.Errors.length > 1) {
                const message = apiResponse.Errors.map(e => e.Message).join(', ');
                this.callErrorMsg('error', '', message);
                this.loading = false;
              }
            } else {
              this.callDefaultMessage();
              this.loading = false;
            }
          } else {
            if (apiResponse.Response != null) {
              this.totalRecords = apiResponse.Response.TotalRecords;
              this.pageNo = apiResponse.Response.CurrentPageNo;
              this.pageSize = apiResponse.Response.PageSize;
              this.userAccessList = apiResponse.Response.Collection;
              this.loading = false;
              this.pageToolTip();
            }
          }
        } else {
          this.callDefaultMessage();
          this.loading = false;
        }
      }).catch(e => {

        this.callDefaultMessage();
        this.loading = false;
      });
    }
  }

  

  paginationNavigationDetails(event) {
    if (event.page >= this.pageNo || (event.page + 2) <= this.pageNo || this.pageSize != event.rows) {
      this.pageNo = event.page + 1;
      this.pageSize = event.rows;
      this.GetUserAccess(this.pageNo, this.pageSize, this.sortObj.sortType, this.sortObj.sortField, this.searchField, this.roleId, this.UserID);
    }
  }

  onRoleChange(event) {
    this.clearMessage(); // Clear Message when tab change
    this.roleId = this.userSelectedRoles[event.index].RoleId;
    this.dt.first = 0;
    this.GetUserAccess(1, this.pageSize, this.sortObj.sortType, this.sortObj.sortField, this.searchField, this.roleId, this.userData.UserID);
  }

  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }

  checkValidation(data) {
    let profileData = this.ProfieForm.getRawValue();
    data.FirstName= profileData.FirstName;
    data.LastName= profileData.LastName;

    if (data.FirstName == '' || data.LastName == '') {
      return false;
    } else {
      this.submitted = true;
      this.isShow = true;
      this.saveUserName(data);
    }
  }

  markAllControlsDirty() {
    this.ProfieForm.controls['FirstName'].markAsDirty();
    this.ProfieForm.controls['LastName'].markAsDirty();
  }

  saveUserName(data) {
    var photourl = data.PhotoURL;

    if (photourl.indexOf('/') != '-1' && photourl.indexOf('?') != '-1') {
        photourl = photourl.substring(photourl.lastIndexOf('/') + 1, photourl.lastIndexOf('?'));
    }

    if (this.userData.UserID  != null && this.userData.UserID > 0) {
      const userData = {
        'FirstName': data.FirstName,
        'LastName': data.LastName,
        'UserId': this.userData.UserID,
        'PhotoURL': photourl
      };

      if (userData.FirstName != null && userData.LastName != null) {

        if (userData.FirstName.match(this.format) || userData.LastName.match(this.format)
          || userData.FirstName.match(this.containsNumber) || userData.LastName.match(this.containsNumber)) {
        } else {
          this.userData.FirstName = userData.FirstName;
          this.userData.LastName = userData.LastName;
          this.userManagementService.UpdateUserName(userData).then(data => {
            if (data.status == 'Failure') {
              if (data.Errors != null && data.Errors.length > 0) {
                this.errorMessages = data.Errors.map(e => e.Message).join(', ');
              } else {
                this.errorMessages = 'An error occured, please contact system administrator';
              }
              this.errorMessages =  this.errorMessages;
              this.callErrorMsg('error', '', this.errorMessages);
            } else {

               // User click on save then first name and last name update in textbox
              this.AddUserModel.FirstName = this.userData.FirstName;
              this.AddUserModel.LastName = this.userData.LastName;

              this.successMsg = true;
              this.callErrorMsg('success', '', 'User successfully updated.');
              this.isEdit = false;

               // object to store in session storage
               userData.PhotoURL=this.url;
              // sessionStorage.setItem('userProfile', JSON.stringify(userData));   
              let userProfileonLoad = JSON.parse(sessionStorage.getItem('userProfileonLoad'))
              this.displayName = userData.FirstName + ' ' + userData.LastName;
              this.profileimagurl = userData.PhotoURL? userData.PhotoURL: userProfileonLoad.PhotoURL;
              const userData1 = {
                'PhotoURL': this.profileimagurl,
                'displayName': this.displayName?this.displayName:userProfileonLoad.displayName,
                'defaultViewUrl': userProfileonLoad.defaultViewUrl,
                'userId':  this.userData.UserID
              };

              sessionStorage.setItem('userProfileonLoad', JSON.stringify(userData1));

              this.headerService.displayName.next(this.displayName);
              this.headerService.profileimagurl.next(this.profileimagurl);
            }
          });
        }
      } else {
      }
    }
  }

  displayName:string='';
  profileimagurl:string='';

  toggle() {
    if (this.isEdit) {
      this.isEdit = false;
      this.isShow = true;
    } else {
      this.isEdit = true;
      this.isShow = false;
    }
  }

  cancelToggle() {
    if (this.isEdit) {
      this.isEdit = false;
      this.isShow = true;
    }
  }

  isShowProfileInvalidFN(value) {
    this.isInvalidProfileFirstName = value;
  }

  isShowProfileInvalidLN(value) {
    this.isInvalidProfileLastName = value;
  }

}
