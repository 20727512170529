import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[dynamicTooltip]'
})
export class DynamicTooltipDistributorMasterDirective {

    constructor(private el: ElementRef) { }

    //@Input('dynamicTooltip') dynamicTooltip: string;

    @HostListener('mouseenter') onMouseEnter() {

        var tooltipSpan = <HTMLElement>this.el.nativeElement.querySelector('span.tooltiptext');
       var textSpan = <HTMLElement>this.el.nativeElement.querySelector('span.textSpan')
        if (tooltipSpan != null && textSpan != null) {
            if (textSpan.offsetWidth < textSpan.scrollWidth) {
                tooltipSpan.style.display = 'block'
            }
            else {
                tooltipSpan.style.display = 'none'
            }
        }

    }

    @HostListener('mouseleave') onMouseLeave() {
        //this.highlight(null);
    }    
}