import { Component, OnInit,ViewChild,Output, Input, EventEmitter} from '@angular/core';
import { LostBusinessWindrilldown2, LostBusinessStateModel } from '../model/lost-business-lbcdrilldown';
import {LostBusinessDrilldownService} from '../service/lost-business-drilldown.service';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Paginator } from 'primeng/paginator';
import { SharedService } from 'src/app/shared/services/shared.service';
import { LostbusinessCustomerService } from '../../../components/dashboard/service/lostbusiness-customer.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-lbc-wincount-drilldown',
  templateUrl: './lbc-wincount-drilldown.component.html',
  styleUrls: ['./lbc-wincount-drilldown.component.scss']
})
export class LbcWincountDrilldownComponent implements OnInit {

  private imagepath = environment.imagepath;
  @ViewChild(Paginator) dt: Paginator;
  @Output('parameter') parameter: EventEmitter<any | LostBusinessStateModel> = new EventEmitter<any | LostBusinessStateModel>();
  @Output('Previous') Previous: EventEmitter<any> = new EventEmitter();
  @Output('windivclose') windivclose: EventEmitter<any> = new EventEmitter<any>();
  @Input() isTrendDrilldown: boolean = false;
  @Input()continuousCustomerCount:number;
  @Input() showContitext:any;
  @Input()lostComparisonDate:any;
  drilldownListData: LostBusinessWindrilldown2[];
  cols: any[];
  totalRecords: number;
  loading: boolean = false;
  pageNo = 1;
  pageSize = 10;
  configRow: number[] = [10, 20, 30, 40, 50];
  sortObj: any = {
    'sortType': '',
    'sortField': ''
  };
  flag: any;
  successMsg: boolean = false;
  msgs: Message[] = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  apiResponseData: any;
  paginatorFirstSelector: any;
  paginatorLaststSelector: any;
  paginatorNextSelector: any;
  paginatorPrevSelector: any;
  showNoData: boolean = false;
  showErrorData: boolean = false;
  searchPlaceholder = 'Enter your text here';
  dataNotFoundMessage: any;
  paramValue: any;
  genericErrorMessage = 'An error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail;
  columnList = [];
  searchString: string = '';
  searchBy: string = '';
  famAccess: any;
  BAId:number;
  FAMID:number;
  drilldownData: LostBusinessStateModel;
  cols1:any[];
  len:number= 0;
  years:any= 0;
  searchStringForState:string="";
  searchByForState:any;
  searchByFieldValue:any;
  constructor(private LostBusinessDrilldownService: LostBusinessDrilldownService
    , private messageService: MessageService
    , private sharedService: SharedService
    , private drillDownService: LostBusinessDrilldownService
    , private lbservice: LostbusinessCustomerService) { }

  ngOnInit() { }

  ngOnChanges() {
    
    this.drilldownData = this.isTrendDrilldown ? { ...this.LostBusinessDrilldownService.trendGetData() } : { ...this.LostBusinessDrilldownService.ddNav3GetData() };

    this.getSingleFAMCol();
    this.GetData();
  }
  GetData() {
    if(this.drilldownData && this.drilldownData.Data){
      this.pageNo = this.drilldownData.Data.pageNo? this.drilldownData.Data.pageNo:1;
      this.pageSize= this.drilldownData.Data.pageSize?this.drilldownData.Data.pageSize:10;
      this.sortObj.sortField = this.drilldownData.Data.sortField?this.drilldownData.Data.sortField:"";
      this.sortObj.sortType= this.drilldownData.Data.sortType? this.drilldownData.Data.sortType:"";
      this.searchStringForState= this.searchString= this.drilldownData.Data.searchString?this.drilldownData.Data.searchString:"";
      if(this.drilldownData.Data.searchBy){
         this.searchByForState= this.searchByFieldValue = this.drilldownData.Data.searchBy;
         this.searchBy = this.searchByForState.code;
      }
    } else{
          this.pageNo = 1;
         this.pageSize = 10;
    }
    if(this.isTrendDrilldown) {
      let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
      month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
    } else {
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
    }
    this.dt.first=(this.pageNo-1)*this.pageSize;
  }
  getSingleFAMColumnNameList() {
    this.columnList = [
      { name: 'Search All', code: null },
      { name: 'Customer Name', code: 'CustomerName' },
      { name: 'Account Number', code: 'AccountNumber' },
    ];
  }
  getMultipleFAMColumnNameList() {
    this.columnList = [
      { name: 'Search All', code: null },
      { name: 'Customer Name', code: 'CustomerName' },
      { name: 'Account Number', code: 'AccountNumber' },
      { name: 'Country', code: 'Country'},
      { name: 'BA', code: 'BA' },
      { name: 'FAM', code: 'FAM' },
    ];
  }

  getMultipleFAMCol() {
    this.getMultipleFAMColumnNameList();

    this.cols = [
      { field: 'CustomerName', header: 'Customer Name' },
      { field: 'AccountNo', header: 'Account Number', colspan: this.len },
      { field: 'NoOfContact', header: 'Number of Contacts' },
      { field:'Totaltransactioncnt',header:'Total Transactions'},
      { field: 'CountryName', header: 'Country' },
      { field: 'BACode', header: 'BA' },
      { field: 'FAMCode', header: 'FAM' },

    ];
    this.cols1 = [];
    var year = this.lbservice.filterObj1.CompPeriodStartDate.split("-");
    this.years = year[2];
    for (let i = 0; i < this.len; i++)
    {
      this.cols1.push({ field: 'YearWiseCount.' + i, header: this.years, colspan: this.len })
      this.years++;
    }
    

  }
  getSingleFAMCol() {
    this.getSingleFAMColumnNameList();
    this.cols = [
      { field: 'CustomerName', header: 'Customer Name' },
      { field: 'AccountNo', header: 'Account Number' },
      { field: 'NoOfContact', header: 'Number of Contacts' },
      { field:'Totaltransactioncnt',header:'Total Transactions'}
    ];
    this.cols1 = [];

    var startyear = this.lbservice.filterObj1.CompPeriodStartDate.split("-");
    this.years = startyear[2];
    for (let i = 0; i < this.len; i++) {
      this.cols1.push({ field: 'YearWiseCount.' + i, header: this.years})
      this.years++;
    }
  }
  onChange(event) {
    if (event.value != null) {
      this.searchBy = event.value.code;
    } else {
      this.searchByForState=  this.searchBy = '';
    }
    // To change place holder of search textbox
    if (this.searchBy === 'CustomerName') {
      this.searchPlaceholder = 'Search by Customer Name';
    } else if (this.searchBy === 'AccountNumber') {
      this.searchPlaceholder = 'Search by Account Number';
    } else if (this.searchBy === 'FAM') {
      this.searchPlaceholder = 'Search by FAM';
    } else if (this.searchBy === 'BA') {
      this.searchPlaceholder = 'Search by BA ';
    } else if (this.searchBy === 'Country') {
      this.searchPlaceholder = 'Search by Country';
    } else {
      this.searchPlaceholder = 'Enter your text here';
    }
  }
  searchByFields(event) {
    if (event && event.which == '13' || event.which == '1') {
      if (this.searchString != null && this.searchString !== '') {
        this.dataNotFoundMessage = 'No matching search results.';
        //this.showNoData = true;
      }
      this.searchString = this.searchString;
      this.searchStringForState=this.searchString;
      this.searchByForState= this.searchByFieldValue;
      this.pageNo = 1;
      if(this.isTrendDrilldown) {
        let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
        month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
      } else {
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
      }
      this.dt.first = 0;
    }
  }
  resetSearchfilter() {
    this.searchStringForState=this.searchString = '';
    this.pageNo = 1,
      this.pageSize = 10;
      if(this.isTrendDrilldown) {
        let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
        month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
      } else {
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
      }
    this.dt.first = 0;
  }
  paginationData(PageNo, PageSize, SortField, SortType, SearchString, SearchBy, IsWin?: boolean, YearDate?: string, MonthDate?: string) {
    this.loading = true;
    this.drilldownListData = [];
    this.drilldownListData.push({} as LostBusinessWindrilldown2);
    let queryParams = this.isTrendDrilldown ? {PageNo, PageSize, SortField, SortType, SearchString, SearchBy, IsWin, YearDate, MonthDate} : {PageNo, PageSize, SortField, SortType, SearchString, SearchBy};
    this.LostBusinessDrilldownService.getWinCustomerList(queryParams, this.isTrendDrilldown).then(rawData => {
      //console.log(rawData.Response);
      this.pageNavigationToolTip();
      if (rawData == null) {
        this.drilldownListData = [];
        this.totalRecords = 0;
        this.pageNo = PageNo;
        this.pageSize = PageSize;
        this.loading = false;
        this.showNoData = true;
      } else if (rawData.Status == "Success" && rawData.Response.Collection.length == 0 && this.searchBy == '' && this.searchString == ''){
        this.loading = false;
        this.showNoData = true;
      } else if (rawData.Status === "Failure") {
        this.showErrorData = true;
      }else {
        let rawResponse: any = rawData;
        if (rawResponse.Status == 'Success') {
          let data = rawResponse.Response;
          
          //console.log(data, "data");
          this.drilldownListData = data.Collection;
         // console.log(this.drilldownListData, "drilldowndata");
            if(this.drilldownListData != null && this.drilldownListData.length > 0){
              this.famAccess = this.drilldownListData[0].IsSingleFAM;
              if (this.isTrendDrilldown) {
                this.len = 0;
              }
              else {
                this.len = this.drilldownListData[0].YearWiseCount.length;
              }
              //console.log(this.famAccess, "famlevel");
              if (this.famAccess == "Multiple"){
                this.getMultipleFAMCol();
              } else {
                this.getSingleFAMCol();
              }
            }
          this.totalRecords = data.TotalRecords;
          this.pageNo = data.CurrentPageNo;
          this.pageSize = data.PageSize;
          this.loading = false;
        } else {
          if (rawResponse.Errors != null && rawResponse.Errors.length > 0) {
            this.successMsg = true;
            this.callErrorMsg('error', '', this.genericErrorMessage);
          } else {
            this.showEmptyGrid(1, 10);
          }
        }
        this.loading = false;
      }
    }).catch(e => {
      this.drilldownListData = null;
      this.successMsg = false;
      this.showErrorData = true;
      this.callErrorMsg('error', '', this.genericErrorMessage);
    });
  }
  showEmptyGrid(page: any, size: any) {
    this.drilldownListData = [];
    this.totalRecords = 0;
    this.pageNo = page;
    this.pageSize = size;
    this.loading = false;
  }
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    window.scrollTo(0, 0);
    setTimeout(function () { document.getElementById('ClearMsg').click(); }, this.MessageTimeOut);
  }
  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }
  pageNavigationToolTip() {
    this.paginatorFirstSelector = <HTMLElement>document.querySelector("a.ui-paginator-first");
    this.paginatorFirstSelector.title = 'First';
    this.paginatorLaststSelector = <HTMLElement>document.querySelector("a.ui-paginator-last");
    this.paginatorLaststSelector.title = 'Last';
    this.paginatorNextSelector = <HTMLElement>document.querySelector("a.ui-paginator-next");
    this.paginatorNextSelector.title = 'Next';
    this.paginatorPrevSelector = <HTMLElement>document.querySelector("a.ui-paginator-prev");
    this.paginatorPrevSelector.title = 'Previous';
  }
  paginationNavigationDetails(event) {
    if (event.page >= this.pageNo || (event.page + 2) <= this.pageNo || this.pageSize != event.rows) {
      this.pageNo = event.page + 1;
      this.pageSize = event.rows;
      this.searchStringForState=this.searchString;
      this.searchByForState= this.searchByFieldValue;
      if(this.isTrendDrilldown) {
        let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
        month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
      } else {
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
      }
    }
  }
  customSort(event: any) {
    if (event.sortField) {
      event.sortOrder = event.sortOrder == 1 ? 'asc' : 'dsc';
      this.pageNo = 1;
      this.sortObj.sortType = event.sortOrder;
      this.sortObj.sortField = event.sortField;
      this.searchStringForState=this.searchString;
      this.searchByForState= this.searchByFieldValue;
      if(this.isTrendDrilldown) {
        let year = this.drilldownData.Data['period'].split(' ').length === 1 ? this.drilldownData.Data['period'] : this.drilldownData.Data['period'].split(' ')[1],
        month = this.drilldownData.Data['period'].split(' ').length === 1 ? undefined : ((new Date(this.drilldownData.Data['period'])).getMonth() + 1);
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy, this.drilldownData.Data['isOnWinPath'], year, `${month}`);
      } else {
        this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.searchString, this.searchBy);
      }
      this.dt.first = 0;
    }
  }
  showCustomerDetail(data){
    // console.log(data,"lsotdata");
    // console.log(data.AccountNo, 'AccountNo');
    // console.log(data.CustomerName, 'CustomerName');
    if (data.NoOfContact > 0) {
      this.paramValue = {
        accountNumber: data.AccountNo,
        custName: data.CustomerName,
        BACode: data.BACode,
        FAMCode: data.FAMCode,
        IsActive: true,
        FAMID:data.FAMID,
        BAID:data.BAId,
        pageNo : this.pageNo,
        pageSize: this.pageSize, 
        sortField: this.sortObj.sortField,
        sortType : this.sortObj.sortType, 
        searchString: this.searchStringForState,
        searchBy: this.searchByForState
      }
      if(this.isTrendDrilldown ){ 
        ["pageNo","pageSize", "sortField", "sortType", "searchString", "searchBy"].forEach(el => {delete this.drilldownData.Data[el];} )
      }
      this.isTrendDrilldown ? this.parameter.emit(this.drillDownService.trendNext({...this.paramValue, ...this.drilldownData.Data})) : this.parameter.emit(this.paramValue);
     // console.log(this.paramValue,"Winparam");
    } else {
        return null;
    }
  }
  onClickPrevious(){
    this.Previous.emit();
  }
  Hidediv(){
    this.windivclose.emit();
  }
}
