import {
  Component, ContentChild, HostListener, Input, OnInit, TemplateRef, ViewChild
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CtlsRuleSetService } from 'src/app/Modules/ctls/ctls-shared/ctls-services/ctls-rule-set.service';

@Component({
  selector: 'app-ctls-rule-popover',
  templateUrl: './ctls-rule-popover.component.html',
  styleUrls: ['./ctls-rule-popover.component.scss']
})
export class CtlsRulePopoverComponent {
  @Input() appendTo: any;

  @ContentChild(TemplateRef) overlayTemplate: TemplateRef<any>;

  @ViewChild('overlaypanel') private overlay: OverlayPanel;
  positionLeft = '0px';
  positionTop = '20px';
  marginStyle = {
    'margin-left': this.positionLeft,
    'margin-top': this.positionTop,
  };
  blnFlag=false;
  constructor(private ctlsRuleSetService: CtlsRuleSetService) { }

  /**
   * show popup
   * @param event
   * @param target
   */
  show(event, target?) {
    this.overlay.show(event, target);
  }

  /**
   * Hide popup
   */
  hide() {
    const f = this.ctlsRuleSetService.getRulePopoverStatus();
    if (!f) {
      this.overlay.hide();
    }
  }
  @HostListener('document:click', ['$event']) clickedOutside($event){
    // $event.preventDefault();
    if (this.blnFlag) {
      this.overlay.hide();
    }
  }
  mouseEnter(){
    this.blnFlag = false;
  }
  mouseLeave(){
    this.blnFlag = true;
  }
}
