import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[dynamicTitle]'
})
export class DynamicTitleDirective {

    constructor(private el: ElementRef) { }

    //@Input('dynamicTooltip') dynamicTooltip: string;

    @HostListener('mouseenter') onMouseEnter() {
       
       var textSpan = <HTMLElement>this.el.nativeElement.querySelector('span.textSpan')
        if (textSpan != null) {
            if (textSpan.offsetWidth < textSpan.scrollWidth) {
                textSpan.title = textSpan.innerText;
            }
            else {
                textSpan.title = "";
            }
        }

    }

    @HostListener('mouseleave') onMouseLeave() {
        //this.highlight(null);
    }    
}