import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlightCategoryReason'
})
export class HighlightCategoryReasonPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(responseValue: string, categoryReason: string): SafeHtml {
    if(!responseValue || !categoryReason) {
        return responseValue;
    }
    const reasons = this.parseCategoryReason(categoryReason);
    let transformedValue = responseValue;
    if(reasons && reasons.length > 0) {
      reasons.forEach(reason => {
        if(!reason.Category_text && !reason.text_segment) {
          transformedValue = transformedValue;
        } else {
          const regex = new RegExp(`(${reason.text_segment ?reason.text_segment :reason.Category_text})`, 'gi');
          const sentimentColor = this.getSentimentColor(reason.sentiment ? reason.sentiment :reason.Sentiment ?reason.Sentiment: reason.sentiment );
         transformedValue = transformedValue.replace(regex, `<span  class="${sentimentColor}" >$1<span class="customTooltip">${reason.category ? reason.category:reason.Category}</span></span>`);
        }
      });

      return this.sanitizer.bypassSecurityTrustHtml(transformedValue);
    } else {
      return responseValue;
    }
  }

  private parseCategoryReason(categoryReason: string): any[] {
    try {
      const formattedCategoryReason = categoryReason.replace(/'/g, '"');
      return JSON.parse(formattedCategoryReason);
    } catch (error) {
      console.error('Error parsing category reason:', error);
      return [];
    }
  }

  private getSentimentColor(sentiment: string): string {
    if(sentiment){
      switch (sentiment.toLowerCase()) {
        case 'positive':
          return 'positive-comment';
        case 'negative':
          return 'negative-comment';
        case 'neutral':
          return 'neutral-comment';
        case 'Positive':
          return 'positive-comment';
        case 'Negative':
          return 'negative-comment';
        case 'Neutral':
          return 'neutral-comment';
        default:
          return '';
      }
    }
    
  }
}