import {
  Directive, EventEmitter, HostListener, Output
} from '@angular/core';

@Directive({
  selector: '[dragndrop]'
})
export class DragndropDirective {
  @Output() fileDropped: EventEmitter<any> = new EventEmitter<any>();
  // @HostBinding('class.fileover') fileover: boolean;
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.fileover = true;
  };
  @HostListener('dragleave', ['$event']) onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  };
  @HostListener('drop', ['$event']) onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.fileover = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  };
  constructor() { }

}
