import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  ViewChild,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { Message, TreeNode } from 'primeng/components/common/api';
import * as moment from 'moment';
import {
  CtlsDashboardCustomerService,
  CtlsFilterHeaderService,
  CtlsLostbusinessCustomerService,
  CtlsSharedService
} from '../../ctls-services';
import * as _ from 'lodash';
import { CtlsDashboardFilterService } from '../../ctls-services/ctls-dashboard-filter.service';

export type HeaderFor = 'GLOBAL' | 'DASHBOARD_360' | 'CTLS';

@Component({
  selector: 'app-ctls-filter-header',
  templateUrl: './ctls-filter-header.component.html',
  styleUrls: ['./ctls-filter-header.component.scss'],
})
export class CtlsFilterHeaderComponent implements OnInit, OnChanges {
  @Input('objCtlsFilterFields') objCtlsFilterFields;
  @Input('objCtlsFilterPinnedFields') objCtlsFilterPinnedFields = null;
  @Input('defaultHeaderFilter') defaultHeaderFilter = true;

  /* auto select customer */
  @Input('filteredCustomerList') filteredCustomerList;
  @Input('headerFor') headerFor: HeaderFor;
  @Input('selectedRadio') selectedRadio;
  selectedCustomer: any;
  storyByNumber: boolean = true;
  storyByPercentage: boolean = false;

  enable360Dashboard: boolean = false;
  showBusyIndicator: boolean = true;
  clearTimeoutFilter: any;
  clearTimeoutPinned: any;
  clearTimeoutLabel: any;
  blnSetPinned = false;
  delay = 1000;
  //Master data properties
  data = [];
  zoneBranch = [];
  countryContinent = [];
  brand = [];
  engineerAndTechnician = [];
  touchPoints = [];
  influencers = [];
  customerNames = []; //11
  customerAccountNumbers = [];
  distributors = []; //33
  distributorSegment = []; //44
  distributorAccountNumber = []; //55
  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  brandOriginal = [];
  engineerAndTechnicianOriginal = [];
  touchPointsOriginal = [];
  influencersOriginal = [];
  customerNamesOriginal = []; //11
  customerAccountNumbersOriginal = [];
  distributorsOriginal = [];
  distributorSegmentOriginal = [];
  distributorAccountNumberOriginal = [];
  //dropdowndata
  famData = [];
  baData = [];
  divData = [];
  blData = [];
  countryData = [];
  operationalCountryData = [];
  categoryData = [];
  brandData = [];
  zoneData = [];
  branchData = [];
  salesEngData = [];
  serviceTechData = [];
  touchPointData: Array<TreeNode> = [];
  influencerData = [];
  countinentData = [];
  regionData = [];
  customerNameData = []; //11
  customerAccountNumberData = [];
  distributorsData = [];
  distributorsDataFull = [];
  distributorSegmentData = [];
  distributorAccountNumberData = [];
  tpSelectionlabel = 'Select Touchpoint';
  //calanderSelectedDate = "";
  msgs: Message[] = [];
  isShowAlert: boolean = false;
  successMsg: boolean = false;
  // TODO
  MessageTimeOut = 10000;
  // MessageTimeOut = this.sharedService.configuration.MessageTimeOut;

  //selected filter
  selectedTouchPointNodes: Array<TreeNode> = [];

  filterMapping: any = {
    FAMId: null,
    BAId: null,
    DIVId: null,
    BLId: null,
    CountryId: null,
    OperationalCountryId: null,
    CategoryId: null,
    BrandId: null,
    ZoneId: null,
    BranchId: null,
    SalesEngId: null,
    ServiceTechId: null,
    TouchPointId: null,
    InfluencerId: null,
    ContinentId: null,
    regionId: null,
    customerNameId: null, //11
    customerAccountNumberId: null,
    distributorsId: null,
    distributorSegmentId: null,
    distributorAccountNumberId: null,
  };

  pinnedFields = {
    baPinned: true,
    divPinned: true,
    famPinned: true,
    blPinned: false,
    countryPinned: true,
    operationalCountryPinned: false,
    categoryPinned: false,
    brandPinned: false,
    zonePinned: false,
    branchPinned: false,
    salesPinned: false,
    servicePinned: false,
    touchpointPinned: false,
    influencerPinned: false,
    continentPinned: false,
    regionPinned: false,
    customerNamePinned: false, //11
    customerAccountNumberPinned: false,
    distributorsPinned: false,
    distributorSegmentPinned: false,
    distributorAccountNumberPinned: false,
  };
  pinnedFieldValuess = {
    baPinnedValue: 'All',
    divPinnedValue: 'All',
    famPinnedValue: 'All',
    blPinnedValue: 'All',
    countryPinnedValue: 'All',
    operationalCountryPinnedValue: 'All',
    categoryPinnedValue: 'All',
    brandPinnedValue: 'All',
    zonePinnedValue: 'All',
    branchPinnedValue: 'All',
    salesPinnedValue: 'All',
    servicePinnedValue: 'All',
    touchpointPinnedValue: 'All',
    influencerPinnedValue: 'All',
    continentPinnedValue: 'All',
    regionPinnedValue: 'All',
    customerNamePinnedValue: 'All', //11
    customerAccountNumberPinnedValue: 'All',
    distributorsPinnedValue: 'All',
    distributorSegmentPinnedValue: 'All',
    distributorAccountNumberPinnedValue: 'All',
  };

  pinnedFieldValuesArray = {
    baPinnedValue: [],
    divPinnedValue: [],
    famPinnedValue: [],
    blPinnedValue: [],
    countryPinnedValue: [],
    operationalCountryPinnedValue: [],
    categoryPinnedValue: [],
    brandPinnedValue: [],
    zonePinnedValue: [],
    branchPinnedValue: [],
    salesPinnedValue: [],
    servicePinnedValue: [],
    touchpointPinnedValue: [],
    influencerPinnedValue: [],
    continentPinnedValue: [],
    regionPinnedValue: [],
    customerNamePinnedValue: [], //11
    customerAccountNumberPinnedValue: [],
    distributorsPinnedValue: [],
    distributorSegmentPinnedValue: [],
    distributorAccountNumberPinnedValue: [],
  };

  inlineFilter = {
    baInline: false,
    famInline: false,
    divInline: false,
    blInline: false,
    countryInline: false,
    operationalCountryInline: false,
    categoryInline: false,
    brandInline: false,
    zoneInline: false,
    branchInline: false,
    salesInline: false,
    serviceInline: false,
    touchpointInline: false,
    influencerInline: false,
    continentInline: false,
    regionInline: false,
    customerNameInline: false, //11
    customerAccountNumberInline: false,
    distributorsInline: false,
    distributorSegmentInline: false,
    distributorAccountNumberInline: false,
  };

  countOfAllData = {
    baCount: 0,
    divCount: 0,
    famCuunt: 0,
    blCount: 0,
    countryCount: 0,
    operationalCountryCount: 0,
    categoryCount: 0,
    brandCount: 0,
    zoneCount: 0,
    branchCount: 0,
    salesCount: 0,
    serviceCount: 0,
    touchpointCount: 0,
    influencerCount: 0,
    continentCount: 0,
    regionCount: 0,
    customerNameCount: 0, //11
    customerAccountNumberCount: 0,
    distributorsCount: 0,
    distributorSegmentCount: 0,
    distributorAccountNumberCount: 0,
  };

  selectedCount = {
    baCount: [],
    divCount: [],
    famCount: [],
    blCount: [],
    countryCount: [],
    operationalCountryCount: [],
    categoryCount: [],
    brandCount: [],
    zoneCount: [],
    branchCount: [],
    salesCount: [],
    serviceCount: [],
    touchpointCount: [],
    influencerCount: [],
    continentCount: [],
    regionCount: [],
    customerNameCount: [], //11
    customerAccountNumberCount: [],
    distributorsCount: [],
    distributorSegmentCount: [],
    distributorAccountNumberCount: [],
  };
  initialCount = {
    baCount: [],
    divCount: [],
    famCount: [],
    blCount: [],
    countryCount: [],
    operationalCountryCount: [],
    categoryCount: [],
    brandCount: [],
    zoneCount: [],
    branchCount: [],
    salesCount: [],
    serviceCount: [],
    touchpointCount: [],
    influencerCount: [],
    continentCount: [],
    regionCount: [],
    customerNameCount: [], //11
    customerAccountNumberCount: [],
    distributorsCount: [],
    distributorSegmentCount: [],
    distributorAccountNumberCount: [],
  };
  periodType = {
    M: true,
    Q: false,
    H: false,
    Y: false,
  };

  delimiter = '|';
  filterToggle: boolean = false;
  filterDateToggle: boolean = false;
  pinFilterSecVisible: boolean = false;

  // rangeDates: Date[];
  dateTime = new Date();
  defaultDate = new Date();
  isPeriodType: boolean = false;
  isDateRange: boolean = true;
  StartDate = '';
  EndDate = '';
  startDateCal = '';
  endDateCal = '';

  /* Auto search */
  suggessionSearchList: any = [];

  @Input('npsWidgetStatus') npsWidgetStatus;
  @Input('savedFilterObject') savedFilterObject;
  @Input('savedFilterObjectFromDB') savedFilterObjectFromDB;
  @Input('filterData') filterData;
  @Input('showDateFilter') showDateFilter;
  @Input('showMasterFilter') showMasterFilter = true;
  @Input('objMasterData') objMasterData;
  @Input() commonDateFilters: any;
  @Input('invalidData') invalidData;
  @Input('surveyType') surveyType;
  ddrStartDate: any;
  ddrEndDate: any;
  @Input('objDefaultDateRange') objDefaultDateRange;

  // @Output('submitted') submitted: EventEmitter<any> = new EventEmitter<any>();
  // @Output('saved') saved: EventEmitter<string> = new EventEmitter<string>();
  // @Output('pinChanged') pinChanged: EventEmitter<any> = new EventEmitter<any>();
  // @Output('onPinnedValueChange')
  // onPinnedValueChange: EventEmitter<any> = new EventEmitter<any>();
  // @Output('reset') reset: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  eventFromCtlsFilterHeader: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('famDDL') famDDL;
  @ViewChild('baDDL') baDDL;
  @ViewChild('divDDL') divDDL;
  @ViewChild('blDDL') blDDL;
  @ViewChild('countryDDL') countryDDL;
  @ViewChild('operationalCountryDDL') operationalCountryDDL;
  @ViewChild('categoryDDL') categoryDDL;

  @ViewChild('brandDDL') brandDDL;
  @ViewChild('zoneDDL') zoneDDL;
  @ViewChild('branchDDL') branchDDL;
  @ViewChild('salesDDL') salesDDL;
  @ViewChild('serviceDDL') serviceDDL;
  @ViewChild('continentDDL') continentDDL;
  @ViewChild('regionDDL') regionDDL;
  @ViewChild('tpDDL') tpDDL;
  @ViewChild('infDDL') infDDL;
  @ViewChild('custNameDDL') custNameDDL; //11   CustomerName
  @ViewChild('custAccNumDDL') custAccNumDDL; //  CustomerAccountNumber;
  @ViewChild('distributorsDDL') distributorsDDL; //  Distributors;
  @ViewChild('distributorSegmentDDL') distributorSegmentDDL; //  DistributorSegment ;
  @ViewChild('distributorAccountNumberDDL') distributorAccountNumberDDL; //  DistributorAccountNumber ;

  @ViewChild('famDDLPinned') famDDLPinned;
  @ViewChild('baDDLPinned') baDDLPinned;
  @ViewChild('divDDLPinned') divDDLPinned;
  @ViewChild('blDDLPinned') blDDLPinned;
  @ViewChild('countryDDLPinned') countryDDLPinned;

  @ViewChild('operationalCountryDDLPinned') operationalCountryDDLPinned;
  @ViewChild('categoryDDLPinned') categoryDDLPinned;

  @ViewChild('brandDDLPinned') brandDDLPinned;
  @ViewChild('zoneDDLPinned') zoneDDLPinned;
  @ViewChild('branchDDLPinned') branchDDLPinned;
  @ViewChild('salesDDLPinned') salesDDLPinned;
  @ViewChild('serviceDDLPinned') serviceDDLPinned;
  @ViewChild('continentDDLPinned') continentDDLPinned;
  @ViewChild('regionDDLPinned') regionDDLPinned;
  @ViewChild('tpDDLPinned') tpDDLPinned;
  @ViewChild('infDDLPinned') infDDLPinned;
  @ViewChild('custNameDDLPinned') custNameDDLPinned; //11
  @ViewChild('custAccNumDDLPinned') custAccNumDDLPinned;
  @ViewChild('distributorsDDLPinned') distributorsDDLPinned;
  @ViewChild('distributorSegmentDDLPinned') distributorSegmentDDLPinned;
  @ViewChild('distributorAccountNumberDDLPinned')
  distributorAccountNumberDDLPinned;
  blnMaster = false;
  blnInit = false;
  count = 0;
  invalidTextMessage: any;
  invalidText: boolean;
  preValues: any;
  constructor(
    private filterService: CtlsFilterHeaderService,
    private ctlsFilterService: CtlsDashboardFilterService,
    private lostfilterService: CtlsLostbusinessCustomerService,
    private ctlsDashboardCustomerService: CtlsDashboardCustomerService,
    private ctlsSharedService: CtlsSharedService
  ) {
    filterService.is360Dashboard.subscribe(flag => {
      this.enable360Dashboard = flag;
    })
  }

  UpdatedDateTime: any;
  refresh() {
    this.UpdatedDateTime = new Date();
    var filterObject = {
      StartDate: this.StartDate,
      EndDate: this.EndDate
    };

    this.eventFromCtlsFilterHeader.emit({
      operation: 'REFRESH_360',
      data: filterObject,
    });
    localStorage.setItem('onSearch', 'true');
    localStorage.removeItem('backButton');
    localStorage.removeItem('filterClicked');
    sessionStorage.removeItem('HMGlobalDetails');
    sessionStorage.removeItem('HMStandardDetails');
    sessionStorage.removeItem('HMEntityDetails');
  }
  currentHeader: HeaderFor = 'GLOBAL';
  ngOnInit() {
    this.selectedRadio = "All"
    console.log("header all", this.headerFor);
    if (this.headerFor == 'CTLS') {
      let date_range = sessionStorage.getItem(
        'ctlsDashboardPeriodFilterObject'
      );
      let range = JSON.parse(date_range);
      this.StartDate = range.StartDate;
      this.EndDate = range.EndDate;
      this.periodType = {
        M: false,
        Q: false,
        H: false,
        Y: false,
      };
      var formatted_today = this.changeDateFormate(new Date());
      let valid = this.EndDate == formatted_today;
      //if (range.PeriodType == 'M' && valid) {
      if (range.PeriodType && valid) {
        this.periodType[range.PeriodType] = true;
      }
      this.startDateCal = this.StartDate;
      this.endDateCal = this.EndDate;
    } else if ((this.headerFor != 'DASHBOARD_360')) {
      var today = new Date();
      this.StartDate = this.changeDateFormate(today);
      this.EndDate = this.changeDateFormate(today);
    }
    this.UpdatedDateTime = new Date();
    //TODO created seperate function and call from both ngOnInit and ngOnChanges
    // this.getFilterAndViewData();
    this.blnInit = true;
    
    this.ctlsSharedService.getToggleFilterSub()
    .subscribe(res => {
      if ((res.field === 'Filter' || res.field === 'Search') && res.toggleBusinessFilter) {
        this.filterToggle = false;
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('Changes', changes)
    
    let objHeaderFor: SimpleChange = changes.headerFor;
    let searchObj: SimpleChange = changes.filteredCustomerList;
    let invalidMessage: SimpleChange = changes.invalidData
    if (searchObj && searchObj.currentValue) {
      this.suggessionSearchList = searchObj.currentValue;
      this.preValues = searchObj.previousValue
      this.invalidText = false;
    }
    if (invalidMessage && invalidMessage.currentValue) {
      this.suggessionSearchList = []
      this.preValues = ''
      this.invalidTextMessage = invalidMessage.currentValue
      this.invalidText = true;
    }
    if (this.invalidData && !this.invalidData.length) {
      this.setClearError();
    }
    if (!!this.objCtlsFilterPinnedFields) {
      this.pinnedFields = { ...this.pinnedFields, ...this.objCtlsFilterPinnedFields }
    }
    if (changes.commonDateFilters) {
      if (!!this.commonDateFilters) {
        this.StartDate = this.commonDateFilters[0]
          ? this.commonDateFilters[0]
          : '';
        this.EndDate = this.commonDateFilters[1]
          ? this.commonDateFilters[1]
          : '';
      } else {
        this.resetDatefilter();
      }
    }
    if (!!changes.objMasterData && !!changes.objMasterData.currentValue) {
      if (Object.keys(this.objMasterData).length) {
        this.blnMaster = true;
        this.getMasterData(this.objMasterData);
        this.getViewData();
        this.getFilterAndViewData();
      }
    }
    if((!!changes.objCtlsFilterFields  && !!changes.objCtlsFilterFields.currentValue) || (changes.selectedRadio &&changes.selectedRadio.currentValue)) {
      if(changes.selectedRadio && changes.selectedRadio.currentValue)
        this.selectedRadio = changes.selectedRadio.currentValue;
      this.applyCasecadingOnList().then(async (ele) => {
        await this.setPinnedFilterValue(true);
        await this.applyfilterOnLabel();
      });
    }
    if (!!changes.filterData && !!changes.filterData.currentValue) {
      if (this.enable360Dashboard) {


        /*  if (this.surveyType == 'CUST') {
           this.objCtlsFilterFields =
             this.ctlsDashboardCustomerService.filterObjForDashboardCustomer;
         }
         if (this.surveyType == 'DIST') {
           this.objCtlsFilterFields =
             this.ctlsDashboardCustomerService.filterObjForDashboardDistributor;
         } */

        let range = this.filterData;
        this.StartDate = range.StartDate;
        this.EndDate = range.EndDate;
        this.periodType = {
          M: false,
          Q: false,
          H: false,
          Y: false,
        };
        var formatted_today = this.changeDateFormate(new Date());
        let valid = this.EndDate == formatted_today;
        if (range.PeriodType == 'M' && valid) {
          this.periodType[range.PeriodType] = true;
        }
        this.startDateCal = this.StartDate;
        this.endDateCal = this.EndDate;

        /* this.StartDate = this.filterData.StartDate;
        this.EndDate = this.filterData.EndDate;

        this.startDateCal = this.StartDate;
        this.endDateCal = this.EndDate; */

        /* Search Filter Cascading */
        /*  this.filterObj = this.mappingFromFilterDataToObj(
           this.filterObj,
           this.filterData,
           false
       ); */


        this.filterService.setUserFilterData360(range);
        this.getFilterAndViewData360();

      }
      else {
        this.filterService.setUserFilterData(this.filterData);
        this.getFilterAndViewData();
      }
    }
    if (!!changes.npsWidgetStatus) {
      if (this.npsWidgetStatus != '') {
        this.filterToggle = false;
        this.filterDateToggle = false;
        this.pinFilterSecVisible = false;
      }
    }

    if (objHeaderFor && objHeaderFor.currentValue) {
      this.currentHeader = objHeaderFor.currentValue;
    }
    if (!!changes.defaultHeaderFilter) {
      //this.enable360Dashboard = true;

      /*  if(changes.objDefaultDateRange.currentValue){
         this.ddrStartDate = changes.objDefaultDateRange.currentValue.startDate;
         this.ddrEndDate = changes.objDefaultDateRange.currentValue.endDate;
         
       }
       this.calculateStartAndEndDate('360'); */

      //this.loadDefault360DashboardDateRange();
    }


  }
  testValue: any
  toggleByPercentage() {
    this.testValue = "percentage"
    this.storyByNumber = false;
    this.storyByPercentage = true;
    this.eventFromCtlsFilterHeader.emit({
      operation: 'TOGGLEVALUES',
      data: this.testValue,
    });
  }

  toggleByNumber() {
    this.testValue = "number"
    this.storyByNumber = true;
    this.storyByPercentage = false;
    this.eventFromCtlsFilterHeader.emit({
      operation: 'TOGGLEVALUES',
      data: this.testValue,
    });
  }

  private getFilterAndViewData() {
    this.savedFilterObject = this.filterService.filterObj;
    this.convertToArray();
    if (this.filterService.dataOriginal.length > 0) {
      this.influencerData = this.filterService.influencersOriginal.map((x) => {
        return { value: x.Influencelevel, label: x.Influencelevel };
      });
      this.applyCasecadingOnList().then(async (ele) => {
        await this.setPinnedFilterValue(true);
        await this.applyfilterOnLabel();
      });
    }
  }

  private getFilterAndViewData360() {
    this.savedFilterObject = this.filterService.filterObj;
    this.convertToArray();
    if (this.filterService.dataOriginal.length > 0) {
      this.influencerData = this.filterService.influencersOriginal.map((x) => {
        return { value: x.Influencelevel, label: x.Influencelevel };
      });
      this.applyCasecadingOnList().then(async (ele) => {
        await this.setPinnedFilterValue(true);
        await this.applyfilterOnLabel();
      });
    }
  }
  getSelectedDate(event, dateType) {
    var dateToSelect = 1;
    if (dateType === 'endDate') {
      try {
        if (this.endDateCal != null) {
          dateToSelect = parseInt(this.endDateCal.split('-')[0]);
        }
      } catch { }
    }
    if (dateType === 'startDate') {
      try {
        if (this.startDateCal != null) {
          dateToSelect = parseInt(this.startDateCal.split('-')[0]);
        }
      } catch { }
    }
    var lastDay = new Date(event.year, event.month, 0).getDate();
    if (lastDay < dateToSelect) {
      dateToSelect = lastDay;
    }
    return dateToSelect;
  }

  onMonthChange(event, dateType) {
    var dateToSelect = this.getSelectedDate(event, dateType);
    this.onDateSelection(
      new Date(event.year, event.month - 1, dateToSelect),
      dateType
    );
  }

  onYearChange(event, dateType) {
    var dateToSelect = this.getSelectedDate(event, dateType);
    this.onDateSelection(
      new Date(event.year, event.month - 1, dateToSelect),
      dateType
    );
  }

  resetDatefilter() {
    // debugger
    if (this.headerFor == 'DASHBOARD_360') {

      this.periodType = {
        M: false,
        Q: false,
        H: false,
        Y: false,
      };
      /* START - Reset Date in DB */

      this.resetDateFilterInDb(true);
      /* END - Reset Date in DB */
      var today = new Date();
      var date = new Date();
      date.setMonth(date.getMonth() - 11);
      var y = date.getFullYear();
      var m = date.getMonth();
      this.StartDate = this.changeDateFormate(new Date(y, m, 1));
      this.EndDate = this.changeDateFormate(today);
      //sessionStorage.setItem('ctlsDashboardPeriodFilterObject', JSON.stringify({ StartDate: this.StartDate, EndDate: this.EndDate, PeriodType: this.periodType }));
    } else if (this.headerFor == 'CTLS') {
      this.periodType = {
        M: false,
        Q: false,
        H: false,
        Y: false,
      };
      var today = new Date();
      var date = new Date();
      date.setMonth(date.getMonth() - 11);
      var y = date.getFullYear();
      var m = date.getMonth();
      this.StartDate = this.changeDateFormate(new Date(y, m, 1));
      this.EndDate = this.changeDateFormate(today);
      sessionStorage.setItem('ctlsDashboardPeriodFilterObject', JSON.stringify({ StartDate: this.StartDate, EndDate: this.EndDate, PeriodType: this.periodType }));
    } else {
      this.isDateRange = true;
      this.isPeriodType = true;
      this.periodType.Q = false;
      this.periodType.H = false;
      this.periodType.Y = false;
      this.periodType.M = true;

      this.StartDate = this.changeDateFormate(today);
      this.EndDate = this.changeDateFormate(today);
      this.calculateStartAndEndDate('M');
    }
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
  }

  calculateStartAndEndDate(key) {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = date;

    if (key == 'Q') {
      var quarter = Math.floor((date.getMonth() + 3) / 3);
      if (quarter == 1) {
        firstDay = new Date(y, 0, 1);
        lastDay = new Date(y, 2, 31);
      } else if (quarter == 2) {
        firstDay = new Date(y, 3, 1);
        lastDay = new Date(y, 5, 30);
      } else if (quarter == 3) {
        firstDay = new Date(y, 6, 1);
        lastDay = new Date(y, 8, 30);
      } else if (quarter == 4) {
        firstDay = new Date(y, 9, 1);
        lastDay = new Date(y, 11, 31);
      }
    }
    if (key == 'H') {
      var hy = Math.floor((date.getMonth() + 6) / 6);
      if (hy == 1) {
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, 5, 30);
      } else if (hy == 2) {
        var firstDay = new Date(y, 6, 1);
        var lastDay = new Date(y, 11, 31);
      }
    }

    if (key == 'Y') {
      var firstDay = new Date(y, 0, 1);
      var lastDay = new Date(y, 11, 31);
    }
    this.StartDate = this.changeDateFormate(firstDay);
    this.EndDate = this.changeDateFormate(lastDay);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
  }

  loadDefault360DashboardDateRange() {
    if (this.enable360Dashboard) {
      this.StartDate = this.ddrStartDate;
      this.EndDate = this.ddrEndDate;
      this.startDateCal = this.StartDate;
      this.endDateCal = this.EndDate;

    }

  }
  onDateSelection(selectedDate, dateType) {
    this.isPeriodType = false;
    this.isDateRange = true;
    if (selectedDate != null && dateType == 'startDate') {
      this.startDateCal = this.changeDateFormate(new Date(selectedDate));
      var endDateFormat = this.changeDateFormat(this.EndDate);
      var endDate = new Date(endDateFormat);

      if (moment(selectedDate) <= moment(endDate)) {
        this.StartDate = this.changeDateFormate(new Date(selectedDate));
      } else {
        this.successMsg = true;
        this.callErrorMsg(
          'error',
          '',
          'Start Date should not be greater than End Date'
        );
      }
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          this.periodType[key] = false;
        }
      }
    } else if (selectedDate != null && dateType == 'endDate') {
      this.endDateCal = this.changeDateFormate(new Date(selectedDate));
      var startDateFormat = this.changeDateFormat(this.StartDate);
      var startDate = new Date(startDateFormat);
      if (selectedDate >= moment(startDate)) {
        this.EndDate = this.changeDateFormate(new Date(selectedDate));
      } else {
        this.successMsg = true;
        this.callErrorMsg(
          'error',
          '',
          'End Date should not be lesser than Start Date'
        );
      }
      //this.EndDate = this.changeDateFormate(new Date(selectedDate));
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          this.periodType[key] = false;
        }
      }
    }
    //  else if (selectedDate[0] != null) {
    //this.calanderSelectedDate = this.changeDateFormate(new Date(selectedDate[0]));
    // }
    this.GlobalDateChanged();
  }

  updatePeriodType(periodType) {
    var today = new Date();
    this.StartDate = this.changeDateFormate(today);
    this.EndDate = this.changeDateFormate(today);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
    for (var key in this.periodType) {
      if (this.periodType.hasOwnProperty(key)) {
        if (key == periodType) {
          this.periodType[key] = true;
          this.calculateStartAndEndDate(key);
        } else {
          this.periodType[key] = false;
        }
      }
    }
    this.isDateRange = false;
    this.isPeriodType = true;
    this.GlobalDateChanged();
  }

  convertToArray() {
    // var dateRange = [];
    var periodType = null;
    if (this.savedFilterObject != null) {
      if (
        this.filterService.filterObj.StartDate != null &&
        this.filterService.filterObj.StartDate
      ) {
        // dateRange.push(new Date(this.filterService.filterObj.StartDate));
        this.startDateCal = this.filterService.filterObj.StartDate;

        this.StartDate = this.filterService.filterObj.StartDate;
      }
      if (
        this.filterService.filterObj.EndDate != null &&
        this.filterService.filterObj.EndDate
      ) {
        // dateRange.push(new Date(this.filterService.filterObj.EndDate));
        this.endDateCal = this.filterService.filterObj.EndDate;
        this.EndDate = this.filterService.filterObj.EndDate;
      }
      if (
        this.filterService.filterObj.PeriodType != null &&
        this.filterService.filterObj.PeriodType
      ) {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            if (key == this.filterService.filterObj.PeriodType) {
              this.periodType[key] = true;
              periodType = key;
              this.calculateStartAndEndDate(key);
            } else {
              this.periodType[key] = false;
            }
          }
        }
      } else {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            this.periodType[key] = false;
          }
        }
      }
      //11 start
      this.filterMapping.customerNameId =
        this.savedFilterObject.CustomerNameCode != null &&
          this.savedFilterObject.CustomerNameCode != ''
          ? this.savedFilterObject.CustomerNameCode.split(this.delimiter)
          : [];

      //11 end

      this.filterMapping.customerAccountNumberId =
        this.savedFilterObject.CustomerAccountNumberCode != null &&
          this.savedFilterObject.CustomerAccountNumberCode != ''
          ? this.savedFilterObject.CustomerAccountNumberCode.split(
            this.delimiter
          )
          : [];
      this.filterMapping.distributorsId =
        this.savedFilterObject.Distributors != null &&
          this.savedFilterObject.Distributors != ''
          ? this.savedFilterObject.Distributors.split(this.delimiter)
          : [];
      //44
      this.filterMapping.distributorSegmentId =
        this.savedFilterObject.DistributorSegment != null &&
          this.savedFilterObject.DistributorSegment != ''
          ? this.savedFilterObject.DistributorSegment.split(this.delimiter)
          : [];
      //44
      //55
      this.filterMapping.distributorAccountNumberId =
        this.savedFilterObject.DistributorAccountNumber != null &&
          this.savedFilterObject.DistributorAccountNumber != ''
          ? this.savedFilterObject.DistributorAccountNumber.split(
            this.delimiter
          )
          : [];

      //55

      this.filterMapping.BAId =
        this.savedFilterObject.BACode != null &&
          this.savedFilterObject.BACode != ''
          ? this.savedFilterObject.BACode.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.DIVId =
        this.savedFilterObject.DivCode != null &&
          this.savedFilterObject.DivCode != ''
          ? this.savedFilterObject.DivCode.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.FAMId =
        this.savedFilterObject.FAMCode != null &&
          this.savedFilterObject.FAMCode != ''
          ? this.savedFilterObject.FAMCode.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.BLId =
        this.savedFilterObject.BLCode != null &&
          this.savedFilterObject.BLCode != ''
          ? this.savedFilterObject.BLCode.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.CountryId =
        this.savedFilterObject.Country != null &&
          this.savedFilterObject.Country != ''
          ? this.savedFilterObject.Country.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.OperationalCountryId =
        this.savedFilterObject.OperationalCountry != null &&
          this.savedFilterObject.OperationalCountry != ''
          ? this.savedFilterObject.OperationalCountry.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.CategoryId =
        this.savedFilterObject.Category != null &&
          this.savedFilterObject.Category != ''
          ? this.savedFilterObject.Category.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.BrandId =
        this.savedFilterObject.BrandName != null &&
          this.savedFilterObject.BrandName != ''
          ? this.savedFilterObject.BrandName.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.ZoneId =
        this.savedFilterObject.Zone != null && this.savedFilterObject.Zone != ''
          ? this.savedFilterObject.Zone.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.BranchId =
        this.savedFilterObject.Branch != null &&
          this.savedFilterObject.Branch != ''
          ? this.savedFilterObject.Branch.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.SalesEngId =
        this.savedFilterObject.SalesEngineer != null &&
          this.savedFilterObject.SalesEngineer != ''
          ? this.savedFilterObject.SalesEngineer.split(this.delimiter)
          : [];
      this.filterMapping.ServiceTechId =
        this.savedFilterObject.ServiceTechnician != null &&
          this.savedFilterObject.ServiceTechnician != ''
          ? this.savedFilterObject.ServiceTechnician.split(this.delimiter)
          : [];
      this.filterMapping.TouchPointId =
        this.savedFilterObject.Touchpoint != null &&
          this.savedFilterObject.Touchpoint != ''
          ? this.savedFilterObject.Touchpoint.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.InfluencerId =
        this.savedFilterObject.Influencer != null &&
          this.savedFilterObject.Influencer != ''
          ? this.savedFilterObject.Influencer.split(this.delimiter)
          : [];
      this.filterMapping.ContinentId =
        this.savedFilterObject.Continent != null &&
          this.savedFilterObject.Continent != ''
          ? this.savedFilterObject.Continent.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
      this.filterMapping.regionId =
        this.savedFilterObject.Region != null &&
          this.savedFilterObject.Region != ''
          ? this.savedFilterObject.Region.split(this.delimiter)
            .map(Number)
            .filter((x) => !isNaN(x))
          : [];
    }

    if (this.StartDate != null && this.EndDate != null) {
      this.isPeriodType = false;
      this.isDateRange = true;
    }

    if (periodType != null) {
      this.isPeriodType = true;
      this.isDateRange = false;
    }
    if (
      this.filterService.filterObj.EndDate == null &&
      this.filterService.filterObj.StartDate == null &&
      this.periodType == null
    ) {
      this.periodType.M = true;
      this.calculateStartAndEndDate('M');
    }
  }

  convertToDelimiterSeparated(del): any {
    return {
      BACode:
        this.filterMapping.BAId != null
          ? this.filterMapping.BAId.join(del)
          : '',
      FAMCode:
        this.filterMapping.FAMId != null
          ? this.filterMapping.FAMId.join(del)
          : '',
      DivCode:
        this.filterMapping.DIVId != null
          ? this.filterMapping.DIVId.join(del)
          : '',
      BLCode:
        this.filterMapping.BLId != null
          ? this.filterMapping.BLId.join(del)
          : '',
      Country:
        this.filterMapping.CountryId != null
          ? this.filterMapping.CountryId.join(del)
          : '',
      OperationalCountry:
        this.filterMapping.OperationalCountryId != null
          ? this.filterMapping.OperationalCountryId.join(del)
          : '',
      Category:
        this.filterMapping.CategoryId != null
          ? this.filterMapping.CategoryId.join(del)
          : '',
      BrandName:
        this.filterMapping.BrandId != null
          ? this.filterMapping.BrandId.join(del)
          : '',
      SalesEngineer:
        this.filterMapping.SalesEngId != null
          ? this.filterMapping.SalesEngId.join(del)
          : '',
      Touchpoint:
        this.filterMapping.TouchPointId != null
          ? this.filterMapping.TouchPointId.join(del)
          : '',
      Influencer:
        this.filterMapping.InfluencerId != null
          ? this.filterMapping.InfluencerId.join(del)
          : '',
      ServiceTechnician:
        this.filterMapping.ServiceTechId != null
          ? this.filterMapping.ServiceTechId.join(del)
          : '',
      Continent:
        this.filterMapping.ContinentId != null
          ? this.filterMapping.ContinentId.join(del)
          : '',
      Region:
        this.filterMapping.regionId != null
          ? this.filterMapping.regionId.join(del)
          : '',
      Zone:
        this.filterMapping.ZoneId != null
          ? this.filterMapping.ZoneId.join(del)
          : '',
      Branch:
        this.filterMapping.BranchId != null
          ? this.filterMapping.BranchId.join(del)
          : '',
      //11 start
      CustomerName:
        this.filterMapping.customerNameId != null
          ? this.filterMapping.customerNameId.join(del)
          : '',
      //11 end

      CustomerAccountNumber:
        this.filterMapping.customerAccountNumberId != null
          ? this.filterMapping.customerAccountNumberId.join(del)
          : '',
      Distributors:
        this.filterMapping.distributorsId != null
          ? this.filterMapping.distributorsId.join(del)
          : '',

      DistributorSegment:
        this.filterMapping.distributorSegmentId != null
          ? this.filterMapping.distributorSegmentId.join(del)
          : '',

      DistributorAccountNumber:
        this.filterMapping.distributorAccountNumberId != null
          ? this.filterMapping.distributorAccountNumberId.join(del)
          : '',

      PinnedField: '',
    };
  }

  formatTouchPointData(list: Array<any>): Array<any> {
    let ids = [];
    let tpList = [];
    var uq_list = [];
    var distArr = [];

    list.forEach(function (x) {
      if (!distArr.includes(x.TouchPointID)) {
        distArr.push(x.TouchPointID);
        uq_list.push(x);
      }
    });

    uq_list.forEach((element) => {
      let childrens = [];
      if (
        ids.indexOf(element.TouchPointID) < 0 &&
        element.ParentTouchPointId == null
      ) {
        uq_list.forEach((e) => {
          if (e.ParentTouchPointId == element.TouchPointID) {
            childrens.push({
              label: e.TouchPoint,
              data: e.TouchPointID,
              children: [],
            });
            ids.push(e.TouchPointID);
          }
        });
        tpList.push({
          label: element.TouchPoint,
          data: element.TouchPointID,
          children: childrens,
        });
        ids.push(element.TouchPointID);
      }
    });
    tpList = tpList.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );
    return tpList;
  }

  onTouchpointUnSelect(event) {
    var UnselectedID = event.node.data;
    var index = this.filterMapping.TouchPointId.indexOf(UnselectedID);
    if (index > -1) {
      this.filterMapping.TouchPointId.splice(index, 1);
    }
    // this.SelectedTouchPoints(true);
    this.updateTouchpointTreeLabel();
  }

  onTouchpointSelect(event) {
    var selectedID = event.node.data;
    var index = this.filterMapping.TouchPointId.indexOf(selectedID);
    if (index == -1) {
      this.filterMapping.TouchPointId.push(selectedID);
    }
    // this.SelectedTouchPoints(true);
    this.updateTouchpointTreeLabel();
  }

  updateTouchpointTreeLabel() {
    if (
      this.filterMapping.TouchPointId == null ||
      this.filterMapping.TouchPointId.length == 0
    ) {
      this.tpSelectionlabel = 'Select Touchpoint';
    } else if (this.filterMapping.TouchPointId.length >= 4) {
      this.tpSelectionlabel =
        this.selectedTouchPointNodes.length + ' items selected';
    } else if (
      this.filterMapping.TouchPointId.length < 4 &&
      this.filterMapping.TouchPointId.length >= 1
    ) {
      this.tpSelectionlabel = this.selectedTouchPointNodes
        .map((ele) => ele.label)
        .join(', ');
    }
  }

  getSelectedNodeArray() {
    var selectedNodes = [];
    this.touchPointData.forEach((ele) => {
      if (this.filterMapping.TouchPointId) {
        if (this.filterMapping.TouchPointId.indexOf(ele.data) >= 0) {
          selectedNodes.push(ele);
        }
        ele.children.forEach((eleChild) => {
          if (this.filterMapping.TouchPointId.indexOf(eleChild.data) >= 0) {
            selectedNodes.push(eleChild);
          }
        });
      }
    });
    return selectedNodes;
  }

  pouplateTreeSelection() {
    this.selectedTouchPointNodes = [];
    if (this.filterMapping.TouchPointId) {
      this.filterMapping.TouchPointId.forEach((element) => {
        var selectedNode = null;
        this.touchPointData.forEach((ele) => {
          if (ele.data == element) {
            selectedNode = ele;
          } else if (ele.children.length > 0) {
            return ele.children.filter((eleChild) => {
              if (eleChild.data == element) {
                selectedNode = eleChild;
              }
            });
          }
        });
        if (selectedNode != null) {
          const node = selectedNode;
          this.selectedTouchPointNodes.push(node);
        }
      });
    }
  }
  /**
   * set data from container to filter service
   * @param objMasterData from container
   */
  getMasterData(objMasterData) {
    let rawdata = objMasterData;
    if (rawdata != null) {
      this.filterService.dataOriginal = Object.assign([],
        rawdata.FamBADivBLFilterModel
      );
      this.filterService.zoneBranchOriginal = Object.assign([],
        rawdata.FamBADivZoneBranchFilterModel
      );
      this.filterService.engineerAndTechnicianOriginal = Object.assign([],
        rawdata.FamBANameTypeFilterModel
      );
      this.filterService.countryContinentOriginal = Object.assign([],
        rawdata.FamCountryContiRegionFilterModel
      );
      if (rawdata.CategoryList) {
        this.filterService.categoryOriginal = Object.assign([],
          rawdata.CategoryList
        );
      }

      this.filterService.brandOriginal = Object.assign([],
        rawdata.FamBABrandFilterModel
      );
      this.filterService.touchPointsOriginal = Object.assign([],
        rawdata.TouchPointFilterModel
      );
      this.filterService.influencersOriginal = Object.assign([],
        rawdata.InfluencerModel
      );
      //11 start
      this.filterService.customerNameOriginal = Object.assign([],
        rawdata.FamBACustomerFilterModel
      );
      //11 end

      this.filterService.customerAccountNumberOriginal = Object.assign([],
        rawdata.FamBACustomerFilterModel
      );
      this.filterService.distributorsOriginal = Object.assign([],
        rawdata.FamBADistributorFilterModel
      );

      this.filterService.distributorSegmentOriginal = Object.assign([],
        rawdata.BABrandDistributorSegmentModel
      );

      this.filterService.distributorAccountNumberOriginal = Object.assign([],
        rawdata.FamBADistributorFilterModel
      );

      this.influencerData = this.filterService.influencersOriginal.map((x) => {
        return { value: x.Influencelevel, label: x.Influencelevel };
      });

      this.applyCasecadingOnList().then(async (ele) => {
        await this.setPinnedFilterValue();
        await this.applyfilterOnLabel();
      });
      this.setAllDataCount();
    } else {
      this.applyCasecadingOnList().then(async (ele) => {
        await this.setPinnedFilterValue();
        await this.applyfilterOnLabel();
      });
    }
  }

  getViewData() {
    if (this.filterService.dataOriginal.length > 0) {
      //added new method  'getDistinctByValue' removed getDistinct method and map method to resolve slowness of filter issue, as getDistinct method is very slow to respone
      //Need casecading filter effect
      this.famData = !!this.objCtlsFilterFields.FAMCode
        ? this.getDistinctByValue(
          this.filterService.dataOriginal,
          'FAMID',
          'FAMCode',
          'value'
        )
        : [];
      this.baData = !!this.objCtlsFilterFields.BACode
        ? this.getDistinctByValue(
          this.filterService.dataOriginal,
          'BAID',
          'BACode',
          'value'
        )
        : [];
      this.divData = !!this.objCtlsFilterFields.DivCode
        ? this.getDistinctByValue(
          this.filterService.dataOriginal,
          'DivisionID',
          'DivisionCode',
          'value'
        )
        : [];
      this.blData = !!this.objCtlsFilterFields.BLCode
        ? this.getDistinctByValue(
          this.filterService.dataOriginal,
          'BLID',
          'BLName',
          'value'
        )
        : [];

      this.countryData = !!this.objCtlsFilterFields.Country
        ? this.getDistinctByValue(
          this.filterService.countryContinentOriginal,
          'CountryID',
          'CountryName',
          'value'
        )
        : [];
      this.operationalCountryData = !!this.objCtlsFilterFields.OperationalCountry
        ? this.getDistinctByValue(
          this.filterService.countryContinentOriginal,
          'CountryID',
          'CountryName',
          'value'
        )
        : [];
      this.categoryData = !!this.objCtlsFilterFields.Category
        ? this.getDistinctByValue(
          this.filterService.categoryOriginal,
          'CTLSCategoryID',
          'CTLSCategoryName',
          'CTLSCategoryID'
        )
        : [];
      this.countinentData = !!this.objCtlsFilterFields.Continent
        ? this.getDistinctByValue(
          this.filterService.countryContinentOriginal,
          'ContinentID',
          'ContinentName',
          'value'
        )
        : [];
      this.regionData = !!this.objCtlsFilterFields.Region
        ? this.getDistinctByValue(
          this.filterService.countryContinentOriginal,
          'RegionID',
          'RegionName',
          'value'
        )
        : [];

      this.zoneData = !!this.objCtlsFilterFields.Zone
        ? this.getDistinctByValue(
          this.filterService.zoneBranchOriginal,
          'ZoneMasterID',
          'ZoneCode',
          'value'
        )
        : [];
      this.branchData = !!this.objCtlsFilterFields.Branch
        ? this.getDistinctByValue(
          this.filterService.zoneBranchOriginal,
          'BranchMasterID',
          'BranchCode',
          'value'
        )
        : [];

      this.salesEngData = !!this.objCtlsFilterFields.SalesEngineer
        ? this.getDistinctByValue(
          this.filterService.engineerAndTechnicianOriginal.filter((x) => {
            if (x.EngineerType == 'Sales') {
              return x;
            }
          }),
          'EngineerName',
          'EngineerName',
          'label'
        )
        : [];
      this.serviceTechData = !!this.objCtlsFilterFields.ServiceTechnician
        ? this.getDistinctByValue(
          this.filterService.engineerAndTechnicianOriginal.filter((x) => {
            if (x.EngineerType == 'Service') {
              return x;
            }
          }),
          'EngineerName',
          'EngineerName',
          'label'
        )
        : [];

      this.brandData = !!this.objCtlsFilterFields.BrandName
        ? this.getDistinctByValue(
          this.filterService.brandOriginal,
          'BrandID',
          'BrandName',
          'value'
        )
        : [];
      //11 start

      this.customerNames = !!this.objCtlsFilterFields.CustomerName
        ? this.getDistinctByValue(
          this.filterService.customerNameOriginal,
          'CustomerName',
          'CustomerName',
          'label'
        )
        : [];
      //11 end

      this.customerAccountNumbers = !!this.objCtlsFilterFields
        .CustomerAccountNumber
        ? this.getDistinctByValue(
          this.filterService.customerAccountNumberOriginal,
          'CustomerAccountNumber',
          'CustomerAccountNumber',
          'label'
        )
        : [];
      this.distributors = !!this.objCtlsFilterFields.Distributors
        ? this.getDistinctByValue(
          this.filterService.distributorsOriginal,
          'DistributorName',
          'DistributorName',
          'label'
        )
        : [];

      this.distributorSegment = !!this.objCtlsFilterFields.DistributorSegment
        ? this.getDistinctByValue(
          this.filterService.distributorSegmentOriginal,
          'DistributorSegment',
          'DistributorSegment',
          'label'
        )
        : [];

      this.distributorAccountNumber = !!this.objCtlsFilterFields
        .DistributorAccountNumber
        ? this.getDistinctByValue(
          this.filterService.distributorAccountNumberOriginal,
          'AccountNumber',
          'AccountNumber',
          'label'
        )
        : [];

      //Independent dropdowns
      this.touchPointData = this.formatTouchPointData(
        this.filterService.touchPointsOriginal
      ); // this.filterService.touchPointsOriginal.map(x => { return { value: x.TouchPointID, label: x.TouchPointCode } });
      this.influencerData = this.filterService.influencersOriginal.map((x) => {
        return { value: x.Influencelevel, label: x.Influencelevel };
      });

      //this.brandData = this.filterService.brandOriginal.map(x => { return { value: x.BrandID, label: x.BrandName } });

      this.filterMapping.BAId = [];
      this.filterMapping.FAMId = [];
      this.filterMapping.DIVId = [];
      this.filterMapping.BLId = [];
      this.filterMapping.CountryId = [];
      this.filterMapping.OperationalCountryId = [];
      this.filterMapping.CategoryId = [];
      this.filterMapping.BrandId = [];
      this.filterMapping.ZoneId = [];
      this.filterMapping.BranchId = [];
      this.filterMapping.SalesEngId = [];
      this.filterMapping.ServiceTechId = [];
      this.filterMapping.TouchPointId = [];
      this.filterMapping.InfluencerId = [];
      this.filterMapping.ContinentId = [];
      this.filterMapping.regionId = [];
      this.filterMapping.customerNameId = []; //11
      this.filterMapping.customerAccountNumberId = [];
      this.filterMapping.distributorsId = [];
      this.filterMapping.distributorSegmentId = [];
      this.filterMapping.distributorAccountNumberId = [];
      this.tpSelectionlabel = 'Select Touchpoint';
      //Clear selected meta tag
      for (var key in this.selectedCount) {
        if (this.selectedCount.hasOwnProperty(key)) {
          this.selectedCount[key] = [];
        }
      }
    }
    //  else {

    //   this.filterService
    //     .getAllData()
    //     .then((rawdata) => {
    //       if (rawdata != null) {
    //         this.filterService.dataOriginal = Object.assign(
    //           rawdata.FamBADivBLFilterModel
    //         );
    //         this.filterService.zoneBranchOriginal = Object.assign(
    //           rawdata.FamBADivZoneBranchFilterModel
    //         );
    //         this.filterService.engineerAndTechnicianOriginal = Object.assign(
    //           rawdata.FamBANameTypeFilterModel
    //         );
    //         this.filterService.countryContinentOriginal = Object.assign(
    //           rawdata.FamCountryContiRegionFilterModel
    //         );
    //         this.filterService.brandOriginal = Object.assign(
    //           rawdata.FamBABrandFilterModel
    //         );
    //         this.filterService.touchPointsOriginal = Object.assign(
    //           rawdata.TouchPointFilterModel
    //         );
    //         this.filterService.influencersOriginal = Object.assign(
    //           rawdata.InfluencerModel
    //         );
    //         //11 start
    //         this.filterService.customerNameOriginal = Object.assign(
    //           rawdata.FamBACustomerFilterModel
    //         );
    //         //11 end

    //         this.filterService.customerAccountNumberOriginal = Object.assign(
    //           rawdata.FamBACustomerFilterModel
    //         );
    //         this.filterService.distributorsOriginal = Object.assign(
    //           rawdata.FamBADistributorFilterModel
    //         );

    //         this.filterService.distributorSegmentOriginal = Object.assign(
    //           rawdata.BABrandDistributorSegmentModel
    //         );

    //         this.filterService.distributorAccountNumberOriginal = Object.assign(
    //           rawdata.FamBADistributorFilterModel
    //         );

    //         this.influencerData = this.filterService.influencersOriginal.map(
    //           (x) => {
    //             return { value: x.Influencelevel, label: x.Influencelevel };
    //           }
    //         );

    //         this.applyCasecadingOnList().then((ele) => {
    //           this.setPinnedFilterValue();
    //           this.applyfilterOnLabel();
    //         });
    //         this.setAllDataCount();
    //       }
    //       else {
    //         this.applyCasecadingOnList().then((ele) => {
    //           this.setPinnedFilterValue();
    //           this.applyfilterOnLabel();
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       this.applyCasecadingOnList().then((ele) => {
    //         this.setPinnedFilterValue();
    //       });
    //     });
    // }
  }

  setAllDataCount() {
    //Independent dropdowns
    var touchPointData = this.filterService.touchPointsOriginal.map((x) => {
      return { value: x.TouchPointID, label: x.TouchPointCode };
    });
    var influencerData = this.filterService.influencersOriginal.map((x) => {
      return { value: x.Influencelevel, label: x.Influencelevel };
    });

    //added new method  'getDistinctByValue' removed getDistinct method and map method to resolve slowness of filter issue, as getDistinct method is very slow to respone
    //Need casecading filter effect
    this.countOfAllData.famCuunt = this.getDistinctByValue(
      this.filterService.dataOriginal,
      'FAMID',
      'FAMCode',
      'value'
    ).length;
    this.countOfAllData.baCount = this.getDistinctByValue(
      this.filterService.dataOriginal,
      'BAID',
      'BACode',
      'value'
    ).length;
    this.countOfAllData.divCount = this.getDistinctByValue(
      this.filterService.dataOriginal,
      'DivisionID',
      'DivisionCode',
      'value'
    ).length;
    this.countOfAllData.blCount = this.getDistinctByValue(
      this.filterService.dataOriginal,
      'BLID',
      'BLName',
      'value'
    ).length;

    this.countOfAllData.countryCount = this.getDistinctByValue(
      this.filterService.countryContinentOriginal,
      'CountryID',
      'CountryName',
      'value'
    ).length;

    this.countOfAllData.operationalCountryCount = this.getDistinctByValue(
      this.filterService.countryContinentOriginal,
      'CountryID',
      'CountryName',
      'value'
    ).length;

    this.countOfAllData.categoryCount = this.getDistinctByValue(
      this.filterService.categoryOriginal,
      'CategoryID',
      'CategoryName',
      'value'
    ).length;

    this.countOfAllData.continentCount = this.getDistinctByValue(
      this.filterService.countryContinentOriginal,
      'ContinentID',
      'ContinentName',
      'value'
    ).length;
    this.countOfAllData.regionCount = this.getDistinctByValue(
      this.filterService.countryContinentOriginal,
      'RegionID',
      'RegionName',
      'value'
    ).length;

    this.countOfAllData.zoneCount = this.getDistinctByValue(
      this.filterService.zoneBranchOriginal,
      'ZoneMasterID',
      'ZoneCode',
      'value'
    ).length;
    this.countOfAllData.branchCount = this.getDistinctByValue(
      this.filterService.zoneBranchOriginal,
      'BranchMasterID',
      'BranchCode',
      'value'
    ).length;
    //11 start
    this.countOfAllData.customerNameCount = this.getDistinctByValue(
      this.filterService.customerNameOriginal,
      'CustomerName',
      'CustomerName',
      'label'
    ).length;
    //11 end

    this.countOfAllData.customerAccountNumberCount = this.getDistinctByValue(
      this.filterService.customerAccountNumberOriginal,
      'CustomerAccountNumber',
      'CustomerAccountNumber',
      'label'
    ).length;
    this.countOfAllData.distributorsCount = this.getDistinctByValue(
      this.filterService.distributorsOriginal,
      'DistributorName',
      'DistributorName',
      'label'
    ).length;

    this.countOfAllData.distributorSegmentCount = this.getDistinctByValue(
      this.filterService.distributorSegmentOriginal,
      'DistributorSegment',
      'DistributorSegment',
      'label'
    ).length;

    this.countOfAllData.distributorAccountNumberCount = this.getDistinctByValue(
      this.filterService.distributorAccountNumberOriginal,
      'AccountNumber',
      'AccountNumber',
      'label'
    ).length;

    this.countOfAllData.salesCount = this.getDistinctByValue(
      this.filterService.engineerAndTechnicianOriginal.filter((x) => {
        if (x.EngineerType == 'Sales') {
          return x;
        }
      }),
      'EngineerName',
      'EngineerName',
      'label'
    ).length;
    this.countOfAllData.serviceCount = this.getDistinctByValue(
      this.filterService.engineerAndTechnicianOriginal.filter((x) => {
        if (x.EngineerType == 'Service') {
          return x;
        }
      }),
      'EngineerName',
      'EngineerName',
      'label'
    ).length;

    this.countOfAllData.brandCount = this.getDistinctByValue(
      this.filterService.brandOriginal,
      'BrandID',
      'BrandName',
      'value'
    ).length;
  }

  filterByBA(list, baList): any {
    return list.filter((ele) => {
      if (baList.indexOf(ele.BAID) >= 0) {
        return ele;
      }
    });
  }

  filterByFAM(list, famList): any {
    return list.filter((ele) => {
      if (famList.indexOf(ele.FAMID) >= 0) {
        return ele;
      }
    });
  }
  filterByDIV(list, divList): any {
    return list.filter((ele) => {
      if (divList.indexOf(ele.DivisionID) >= 0) {
        return ele;
      }
    });
  }

  // Filter based on FAM
  SelectedFams(event) {
    if (this.filterMapping.FAMId.length ||
      (this.selectedCount.famCount.length && !this.filterMapping.FAMId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList()
        .then((res) => {
          setTimeout(() => {
            this.showBusyIndicator = false;
          }, this.delay);
          this.applyfilterOnLabel();
        })
        .catch((e) => { });
    }
  }

  removeFakeClass() {
    var element = <HTMLElement>(
      document.getElementsByClassName('ui-multiselect-panel')[0]
    );
    if (element) {
      element.classList.remove('remove-arrow');
    }
  }

  onOpenPanel() {
    var element = <HTMLElement>(
      document.getElementsByClassName('ui-multiselect-panel')[0]
    );
    element.className += ' remove-arrow';
  }
  // Filter based on BA
  SelectedBas(event) {
    if (
      this.filterMapping.BAId.length ||
      (this.selectedCount.baCount.length && !this.filterMapping.BAId.length) ||
      event === true
    ) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList('ba').then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  // Filter based on DIV
  SelectedDivs(event) {
    if (this.filterMapping.DIVId.length ||
      (this.selectedCount.divCount.length && !this.filterMapping.DIVId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  // Filter based on BL
  SelectedBls(event) {
    if (this.filterMapping.BLId.length ||
      (this.selectedCount.blCount.length && !this.filterMapping.BLId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedCountries(event) {
    if (this.filterMapping.CountryId.length ||
      (this.selectedCount.countryCount.length && !this.filterMapping.CountryId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.hideScroll = false;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedOperationalCountries(event) {
    if (this.filterMapping.OperationalCountryId.length ||
      (this.selectedCount.operationalCountryCount.length && !this.filterMapping.OperationalCountryId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.hideScroll = false;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }
  SelectedCategory(event) {
    if (this.filterMapping.CategoryId.length ||
      (this.selectedCount.categoryCount.length && !this.filterMapping.CategoryId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.hideScroll = false;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedBrands(event) {
    if (this.filterMapping.BrandId.length ||
      (this.selectedCount.brandCount.length && !this.filterMapping.BrandId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedZones(event) {
    if (this.filterMapping.ZoneId.length ||
      (this.selectedCount.zoneCount.length && !this.filterMapping.ZoneId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedBranchs(event) {
    if (this.filterMapping.BranchId.length ||
      (this.selectedCount.branchCount.length && !this.filterMapping.BranchId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedSalesEngs(event) {
    if (this.filterMapping.SalesEngId.length ||
      (this.selectedCount.salesCount.length && !this.filterMapping.SalesEngId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedServiceTechs(event) {
    if (this.filterMapping.ServiceTechId.length ||
      (this.selectedCount.serviceCount.length && !this.filterMapping.ServiceTechId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedContinents(event) {
    if (this.filterMapping.ContinentId.length ||
      (this.selectedCount.continentCount.length && !this.filterMapping.ContinentId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }
  SelectedRegions(event) {
    if (this.filterMapping.regionId.length ||
      (this.selectedCount.regionCount.length && !this.filterMapping.regionId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedTouchPoints(event) {
    if (this.selectedTouchPointNodes.length ||
      (this.selectedCount.touchpointCount.length && !this.selectedTouchPointNodes.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }
  //11 start
  SelectedCustomerNames(event) {
    if (this.filterMapping.customerNameId.length ||
      (this.selectedCount.customerNameCount.length && !this.filterMapping.customerNameId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }
  //11 end

  SelectedCustomerAccountNumbers(event) {
    if (this.filterMapping.customerAccountNumberId.length ||
      (this.selectedCount.customerAccountNumberCount.length && !this.filterMapping.customerAccountNumberId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }
  SelectedDistributors(event) {
    if (this.filterMapping.distributorSegmentId.length ||
      (this.selectedCount.distributorsCount.length && !this.filterMapping.distributorSegmentId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedDistributorSegment(event) {
    if (this.filterMapping.distributorSegmentId.length ||
      (this.selectedCount.distributorSegmentCount.length && !this.filterMapping.distributorSegmentId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedDistributorAccountNumber(event) {
    if (this.filterMapping.distributorAccountNumberId.length ||
      (this.selectedCount.distributorAccountNumberCount.length && !this.filterMapping.distributorAccountNumberId.length) ||
      event === true) {
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then((res) => {
        setTimeout(() => {
          this.showBusyIndicator = false;
        }, this.delay);
        this.applyfilterOnLabel();
      });
    }
  }

  SelectedInfluncer(event) {
    setTimeout(() => {
      this.applyfilterOnLabel();
    }, 10);
  }

  resetFilter() {
    this.getViewData();
  }

  submit() {
    this.GlobalDateChanged(false);
    this.reomveExtraIds();
    var delimiterSepratedObject = this.convertToDelimiterSeparated(
      this.delimiter
    );
    var piinedFieldsArray = [];
    if (this.headerFor != 'DASHBOARD_360') {
      for (var key in this.pinnedFields) {
        if (this.pinnedFields.hasOwnProperty(key)) {
          if (this.pinnedFields[key] == true) {
            piinedFieldsArray.push(key);
          }
        }
      }
      delimiterSepratedObject['PinnedField'] = piinedFieldsArray.join('|');

      sessionStorage.setItem(
        'ctlsGlobalFilterObject',
        JSON.stringify(delimiterSepratedObject)
      );
      this.filterService.updateFilterObject();
      this.filterService.updateSelectedFilter(this.selectedCount);
      this.setPinnedFilterValue();
    }
    this.filterToggle = false;
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
    this.lostfilterService.LostCountSubject.next(true);
    // this.submitted.emit(delimiterSepratedObject);
    this.clearMessage();

    this.eventFromCtlsFilterHeader.emit({
      operation: 'FILTER_SUBMIT',
      data: delimiterSepratedObject,
    });
  }

  submitDatefilter() {
    this.successMsg = false;
    var startDate = '';
    var endDate = '';
    var periodType = '';

    if (this.isPeriodType) {
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          if (this.periodType[key] == true) {
            periodType = key;
          }
        }
      }
    }
    var startDateFormat = this.changeDateFormat(this.startDateCal);
    var startDateVal = new Date(startDateFormat);
    var endDateFormat = this.changeDateFormat(this.endDateCal);
    var endDateVal = new Date(endDateFormat);

    if (moment(startDateVal) <= moment(endDateVal)) {
      this.StartDate = this.startDateCal;
      startDate = this.startDateCal;
    } else {
      this.successMsg = true;
      this.callErrorMsg(
        'error',
        '',
        'Start Date should not be greater than End Date'
      );
      return;
    }
    if (moment(endDateVal) >= moment(startDateVal)) {
      this.EndDate = this.endDateCal;
      endDate = this.endDateCal;
    } else {
      this.successMsg = true;
      this.callErrorMsg(
        'error',
        '',
        'End Date should not be less than Start Date'
      );
      return;
    }
    var filterObject = {
      StartDate: startDate,
      EndDate: endDate,
      PeriodType: periodType,
    };
    if (this.enable360Dashboard) {

      this.filterToggle = false;
      this.filterDateToggle = false;
      this.pinFilterSecVisible = false;
      this.filterService.filterObj.ActionType = 'Submit';

      this.eventFromCtlsFilterHeader.emit({
        operation: 'FILTER_DATE_SUBMIT',
        data: filterObject,
      });
      this.lostfilterService.LostCountSubject.next(true);
      return
    }
    this.filterService.filterObj.StartDate = startDate;
    this.filterService.filterObj.EndDate = endDate;
    this.filterService.filterObj.PeriodType = periodType;

    this.GlobalDateChanged();
    if (this.headerFor == 'CTLS') {
      sessionStorage.setItem(
        'ctlsDashboardPeriodFilterObject',
        JSON.stringify(filterObject)
      );
    } else {
      sessionStorage.setItem(
        'ctlsPeriodFilterObject',
        JSON.stringify(filterObject)
      );
    }
    this.filterService.updateFilterObject();

    this.filterToggle = false;
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
    this.filterService.filterObj.ActionType = 'Submit';

    this.eventFromCtlsFilterHeader.emit({
      operation: 'FILTER_DATE_SUBMIT',
      data: filterObject,
    });
    this.lostfilterService.LostCountSubject.next(true);
    // this.submitted.emit(this.filterService.filterObj);
  }

  saveMasterFilter(isDate = false) {
    if (this.headerFor != 'DASHBOARD_360') {
      this.GlobalDateChanged(isDate);
    }

    var filterObject = null;
    if (isDate) {
      var startDate = this.StartDate;
      var endDate = this.EndDate;
      var periodType = '';

      if (this.isPeriodType) {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            if (this.periodType[key] == true) {
              periodType = key;
            }
          }
        }
      } else {
        var startDateFormat = this.changeDateFormat(this.startDateCal);
        var startDateVal = new Date(startDateFormat);
        var endDateFormat = this.changeDateFormat(this.endDateCal);
        var endDateVal = new Date(endDateFormat);

        if (moment(startDateVal) <= moment(endDateVal)) {
          this.StartDate = this.startDateCal;
          startDate = this.startDateCal;
        } else {
          this.successMsg = true;
          this.callErrorMsg(
            'error',
            '',
            'Start Date should not be greater than End Date'
          );
          return;
        }
        if (moment(endDateVal) >= moment(startDateVal)) {
          endDate = this.endDateCal;
          this.EndDate = this.endDateCal;
        } else {
          this.successMsg = true;
          this.callErrorMsg(
            'error',
            '',
            'End Date should not be lesser than Start Date'
          );
          return;
        }
      }

      filterObject = {
        StartDate: startDate,
        EndDate: endDate,
        PeriodType: periodType,
      };

      if (this.headerFor == "DASHBOARD_360") {

      } else {
        this.ctlsFilterService
          .saveCTLSFilter(filterObject)
          .then();
      }

    } else {
      this.reomveExtraIds();
      filterObject = this.convertToDelimiterSeparated(this.delimiter);
      var piinedFieldsArray = [];
      for (var key in this.pinnedFields) {
        if (this.pinnedFields.hasOwnProperty(key)) {
          if (this.pinnedFields[key] == true) {
            piinedFieldsArray.push(key);
          }
        }
      }
      filterObject['PinnedField'] = piinedFieldsArray.join('|');
    }
    if (this.headerFor == "CTLS") {
      this.filterService.filterObj.StartDate = startDate;
      this.filterService.filterObj.EndDate = endDate;
      this.filterService.filterObj.PeriodType = periodType;
      this.eventFromCtlsFilterHeader.emit({
        operation: 'FILTER_DATE_SAVE',
        data: this.filterService.filterObj,
      });
      sessionStorage.setItem(
        'ctlsDashboardPeriodFilterObject',
        JSON.stringify(filterObject)
      );
    } else {

      /* if (this.headerFor == "DASHBOARD_360") {
        filterObject = { ...this.filterData, ...filterObject }
      } */
      filterObject.surveySubCategoryCode = this.objCtlsFilterFields.surveySubCategoryCode;
      filterObject.SubCategory = this.objCtlsFilterFields.surveySubCategoryCode;
      filterObject.screenCode = this.objCtlsFilterFields.screenCode;
      filterObject.IsDateParam = isDate;



      this.filterService
        .saveUserFilter(filterObject, isDate)
        .then((res) => {
          if (res != null && res.Status == 'SUCCESS') {
            if (isDate) {
              if (this.headerFor != 'DASHBOARD_360') {
                sessionStorage.setItem(
                  'ctlsPeriodFilterObject',
                  JSON.stringify(filterObject)
                );
              }
              this.filterDateToggle = false;
            } else {
              if (this.headerFor != 'DASHBOARD_360') {
                sessionStorage.setItem(
                  'ctlsGlobalFilterObject',
                  JSON.stringify(filterObject)
                );
              }
              this.filterToggle = false;
            }

            if (this.headerFor == 'DASHBOARD_360') {
              this.eventFromCtlsFilterHeader.emit({
                operation: 'FILTER_DATE_SUBMIT',
                data: filterObject,
              });
              return;
            }
            this.filterService.updateFilterObject();
            this.setPinnedFilterValue();
            if (isDate == true) this.filterService.filterObj.ActionType = 'Save';
            else this.filterService.filterObj.ActionType = 'Submit';
            this.lostfilterService.LostCountSubject.next(true);
            this.eventFromCtlsFilterHeader.emit({
              operation: 'FILTER_MASTER_SAVE',
              data: this.filterService.filterObj,
            });


            this.pinFilterSecVisible = false;
          }
        })
        .catch((e) => {

        });
    }
    this.closeFilterPanel();
  }

  resetDateFilterInDb(isDate) {
    let filterObject = {
      StartDate: "",
      EndDate: "",
      PeriodType: "",
      surveySubCategoryCode: "",
      SubCategory: "",
      screenCode: "",
      IsDateParam: ""

    };

    filterObject.surveySubCategoryCode = this.objCtlsFilterFields.surveySubCategoryCode;
    filterObject.SubCategory = this.objCtlsFilterFields.surveySubCategoryCode;
    filterObject.screenCode = this.objCtlsFilterFields.screenCode;
    filterObject.IsDateParam = isDate;



    this.filterService
      .saveUserFilter(filterObject, isDate)
      .then((res) => {
        if (res != null && res.Status == 'SUCCESS') {


        }
      })
      .catch((e) => {

      });
  }

  clearUserFilter() {

    if (this.headerFor == 'DASHBOARD_360') {
      this.getViewData();
      this.setPinnedFilterValue();
      this.saveMasterFilter(false);
      return;
    }

    this.filterService
      .clearUserFilter(
        this.objCtlsFilterFields.surveySubCategoryCode,
        this.objCtlsFilterFields.screenCode
      )
      .then((res) => {
        if (res.Status == 'SUCCESS') {
          this.getViewData();
          this.setPinnedFilterValue();

          var date = new Date();
          var fromDate = this.changeDateFormate(
            new Date(date.getFullYear(), date.getMonth(), 1)
          );
          var toDate = this.changeDateFormate(date);

          this.isDateRange = true;
          this.isPeriodType = true;
          this.periodType.Q = false;
          this.periodType.H = false;
          this.periodType.Y = false;
          this.periodType.M = false;

          this.StartDate = fromDate;
          this.EndDate = toDate;
          this.startDateCal = this.StartDate;
          this.endDateCal = this.EndDate;
          this.tpSelectionlabel = 'Select Touchpoint';
          for (var key in this.filterService.filterObj) {
            if (this.filterService.filterObj.hasOwnProperty(key)) {
              this.filterService.filterObj[key] = '';
            }
          }

          this.filterService.filterObj.StartDate = fromDate;
          this.filterService.filterObj.EndDate = toDate;

          this.filterService.filterObj;
          var filterObjMain = {
            BACode: '',
            FAMCode: '',
            DivCode: '',
            BLCode: '',
            Country: '',
            OperationalCountry: '',
            Category: '',
            BrandName: '',
            SalesEngineer: '',
            Touchpoint: '',
            Influencer: '',
            ServiceTechnician: '',
            Continent: '',
            Region: '',
            Zone: '',
            Branch: '',
            PinnedField: '',
            CustomerNameCode: '', //11
            CustomerAccountNumberCode: '',
            DistributorsCode: '',
            DistributorSegmentCode: '',
            DistributorAccountNumberCode: '',
          };


          var filterObjDate = {
            StartDate: fromDate,
            EndDate: toDate,
            PeriodType: '',
          };

          /* if(this.headerFor == 'DASHBOARD_360'){
            this.eventFromCtlsFilterHeader.emit({
              operation: 'CLEAR_SAVED_FILTER',
              data: {},
            });
            return;
          } */
          this.eventFromCtlsFilterHeader.emit({
            operation: 'CLEAR_SAVED_FILTER',
            data: this.filterService.filterObj,
          });
          sessionStorage.setItem(
            'ctlsGlobalFilterObject',
            JSON.stringify(filterObjMain)
          );

          if (this.headerFor == 'CTLS') {
            var today = new Date();
            var date = new Date();
            date.setDate(date.getDate() - 365);
            this.StartDate = this.changeDateFormate(date);
            this.EndDate = this.changeDateFormate(today);
            sessionStorage.setItem('ctlsDashboardPeriodFilterObject', JSON.stringify({ StartDate: this.StartDate, EndDate: this.EndDate }))
          } else {
            sessionStorage.setItem(
              'ctlsPeriodFilterObject',
              JSON.stringify(filterObjDate)
            );
          }

          this.callErrorMsg(
            'success',
            '',
            'Saved filter cleared successfully.'
          );
        } else {
          this.callErrorMsg('error', '', 'Unable to clear saved filter.');
        }
      });
  }

  setPinnedFilterValue(blnFlag = false) {
    this.blnSetPinned = false;
    // if (blnFlag) {
    //   this.checkIndicator(true);
    // }
    return new Promise((resolve) => {
      this.clearTimeoutPinned =
        setTimeout(() => {
          try {
            this.pinnedFieldValuesArray.famPinnedValue = [];
            this.pinnedFieldValuesArray.baPinnedValue = [];
            this.pinnedFieldValuesArray.divPinnedValue = [];
            this.pinnedFieldValuesArray.blPinnedValue = [];
            this.pinnedFieldValuesArray.countryPinnedValue = [];
            this.pinnedFieldValuesArray.operationalCountryPinnedValue = [];
            this.pinnedFieldValuesArray.categoryPinnedValue = [];
            this.pinnedFieldValuesArray.brandPinnedValue = [];
            this.pinnedFieldValuesArray.zonePinnedValue = [];
            this.pinnedFieldValuesArray.branchPinnedValue = [];
            this.pinnedFieldValuesArray.salesPinnedValue = [];
            this.pinnedFieldValuesArray.servicePinnedValue = [];
            this.pinnedFieldValuesArray.touchpointPinnedValue = [];
            this.pinnedFieldValuesArray.influencerPinnedValue = [];
            this.pinnedFieldValuesArray.continentPinnedValue = [];
            this.pinnedFieldValuesArray.regionPinnedValue = [];
            this.pinnedFieldValuesArray.customerNamePinnedValue = []; //11
            this.pinnedFieldValuesArray.customerAccountNumberPinnedValue = [];
            this.pinnedFieldValuesArray.distributorsPinnedValue = [];
            this.pinnedFieldValuesArray.distributorAccountNumberPinnedValue = [];
            this.pinnedFieldValuesArray.distributorSegmentPinnedValue = [];

            if (
              this.filterMapping.FAMId != null &&
              this.filterMapping.FAMId.length > 0
            ) {
              var codes = this.famData
                .filter((ele) => {
                  if (this.filterMapping.FAMId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });

              this.pinnedFieldValuess.famPinnedValue =
                this.filterMapping.FAMId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.FAMId.length == this.countOfAllData.famCuunt
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.famPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.famPinnedValue = 'All';
            }

            if (
              this.filterMapping.BAId != null &&
              this.filterMapping.BAId.length > 0
            ) {
              var codes = this.baData
                .filter((ele) => {
                  if (this.filterMapping.BAId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.baPinnedValue =
                this.filterMapping.BAId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.BAId.length == this.countOfAllData.baCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.baPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.baPinnedValue = 'All';
            }

            if (
              this.filterMapping.DIVId != null &&
              this.filterMapping.DIVId.length > 0
            ) {
              var codes = this.divData
                .filter((ele) => {
                  if (this.filterMapping.DIVId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.divPinnedValue =
                this.filterMapping.DIVId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.DIVId.length == this.countOfAllData.divCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.divPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.divPinnedValue = 'All';
            }
            //11 start
            if (
              this.filterMapping.customerNameId != null &&
              this.filterMapping.customerNameId.length > 0
            ) {
              var codes = this.customerNameData
                .filter((ele) => {
                  if (this.filterMapping.customerNameId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.customerNamePinnedValue =
                this.filterMapping.customerNameId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.customerNameId.length ==
                    this.countOfAllData.customerNameCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.customerNamePinnedValue = codes;
            } else {
              this.pinnedFieldValuess.customerNamePinnedValue = 'All';
            }

            //11 end

            if (
              this.filterMapping.customerAccountNumberId != null &&
              this.filterMapping.customerAccountNumberId.length > 0
            ) {
              var codes = this.customerAccountNumberData
                .filter((ele) => {
                  if (
                    this.filterMapping.customerAccountNumberId.indexOf(ele.value) >=
                    0
                  ) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.customerAccountNumberPinnedValue =
                this.filterMapping.customerAccountNumberId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.customerAccountNumberId.length ==
                    this.countOfAllData.customerAccountNumberCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.customerAccountNumberPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.customerAccountNumberPinnedValue = 'All';
            }

            if (
              this.filterMapping.distributorsId != null &&
              this.filterMapping.distributorsId.length > 0
            ) {
              var codes = this.distributorsData
                .filter((ele) => {
                  if (this.filterMapping.distributorsId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.distributorsPinnedValue =
                this.filterMapping.distributorsId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.distributorsId.length ==
                    this.countOfAllData.distributorsCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.distributorsPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.distributorsPinnedValue = 'All';
            }

            if (
              this.filterMapping.distributorSegmentId != null &&
              this.filterMapping.distributorSegmentId.length > 0
            ) {
              var codes = this.distributorSegmentData
                .filter((ele) => {
                  if (
                    this.filterMapping.distributorSegmentId.indexOf(ele.value) >= 0
                  ) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.distributorSegmentPinnedValue =
                this.filterMapping.distributorSegmentId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.distributorSegmentId.length ==
                    this.countOfAllData.distributorSegmentCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.distributorSegmentPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.distributorSegmentPinnedValue = 'All';
            }

            if (
              this.filterMapping.distributorAccountNumberId != null &&
              this.filterMapping.distributorAccountNumberId.length > 0
            ) {
              var codes = this.distributorAccountNumberData
                .filter((ele) => {
                  if (
                    this.filterMapping.distributorAccountNumberId.indexOf(
                      ele.value
                    ) >= 0
                  ) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.distributorAccountNumberPinnedValue =
                this.filterMapping.distributorAccountNumberId.length == 1 &&
                  !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.distributorAccountNumberId.length ==
                    this.countOfAllData.distributorAccountNumberCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.distributorAccountNumberPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.distributorAccountNumberPinnedValue = 'All';
            }

            if (
              this.filterMapping.BLId != null &&
              this.filterMapping.BLId.length > 0
            ) {
              var codes = this.blData
                .filter((ele) => {
                  if (this.filterMapping.BLId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.blPinnedValue =
                this.filterMapping.BLId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.BLId.length == this.countOfAllData.blCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.blPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.blPinnedValue = 'All';
            }

            if (
              this.filterMapping.CountryId != null &&
              this.filterMapping.CountryId.length > 0
            ) {
              var codes = this.countryData
                .filter((ele) => {
                  if (this.filterMapping.CountryId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.countryPinnedValue =
                this.filterMapping.CountryId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.CountryId.length ==
                    this.countOfAllData.countryCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.countryPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.countryPinnedValue = 'All';
            }


            if (
              this.filterMapping.OperationalCountryId != null &&
              this.filterMapping.OperationalCountryId.length > 0
            ) {
              var codes = this.operationalCountryData
                .filter((ele) => {
                  if (this.filterMapping.OperationalCountryId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.operationalCountryPinnedValue =
                this.filterMapping.OperationalCountryId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.OperationalCountryId.length ==
                    this.countOfAllData.operationalCountryCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.operationalCountryPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.operationalCountryPinnedValue = 'All';
            }

            if (
              this.filterMapping.CategoryId != null &&
              this.filterMapping.CategoryId.length > 0
            ) {
              var codes = this.categoryData
                .filter((ele) => {
                  if (this.filterMapping.CategoryId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.categoryPinnedValue =
                this.filterMapping.CategoryId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.CategoryId.length ==
                    this.countOfAllData.categoryCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.categoryPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.categoryPinnedValue = 'All';
            }

            if (
              this.filterMapping.BrandId != null &&
              this.filterMapping.BrandId.length > 0
            ) {
              var codes = this.brandData
                .filter((ele) => {
                  if (this.filterMapping.BrandId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.brandPinnedValue =
                this.filterMapping.BrandId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.BrandId.length ==
                    this.countOfAllData.brandCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.brandPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.brandPinnedValue = 'All';
            }

            if (
              this.filterMapping.ZoneId != null &&
              this.filterMapping.ZoneId.length > 0
            ) {
              var codes = this.zoneData
                .filter((ele) => {
                  if (this.filterMapping.ZoneId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.zonePinnedValue =
                this.filterMapping.ZoneId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.ZoneId.length ==
                    this.countOfAllData.zoneCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.zonePinnedValue = codes;
            } else {
              this.pinnedFieldValuess.zonePinnedValue = 'All';
            }

            if (
              this.filterMapping.BranchId != null &&
              this.filterMapping.BranchId.length > 0
            ) {
              var codes = this.branchData
                .filter((ele) => {
                  if (this.filterMapping.BranchId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.branchPinnedValue =
                this.filterMapping.BranchId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.BranchId.length ==
                    this.countOfAllData.branchCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.branchPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.branchPinnedValue = 'All';
            }

            if (
              this.filterMapping.SalesEngId != null &&
              this.filterMapping.SalesEngId.length > 0
            ) {
              var codes = this.salesEngData
                .filter((ele) => {
                  if (this.filterMapping.SalesEngId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.salesPinnedValue =
                this.filterMapping.SalesEngId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.SalesEngId.length ==
                    this.countOfAllData.salesCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.salesPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.salesPinnedValue = 'All';
            }

            if (
              this.filterMapping.ServiceTechId != null &&
              this.filterMapping.ServiceTechId.length > 0
            ) {
              var codes = this.serviceTechData
                .filter((ele) => {
                  if (this.filterMapping.ServiceTechId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });

              this.pinnedFieldValuess.servicePinnedValue =
                this.filterMapping.ServiceTechId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.ServiceTechId.length ==
                    this.countOfAllData.serviceCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.servicePinnedValue = codes;
            } else {
              this.pinnedFieldValuess.servicePinnedValue = 'All';
            }

            if (
              this.filterMapping.TouchPointId != null &&
              this.filterMapping.TouchPointId.length > 0
            ) {
              var codes = this.getSelectedNodeArray().map((ele) => {
                return ele.label as any;
              });

              this.pinnedFieldValuess.touchpointPinnedValue =
                this.filterMapping.TouchPointId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.TouchPointId.length ==
                    this.countOfAllData.touchpointCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.touchpointPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.touchpointPinnedValue = 'All';
            }

            if (
              this.filterMapping.InfluencerId != null &&
              this.filterMapping.InfluencerId.length > 0
            ) {
              var codes = this.influencerData
                .filter((ele) => {
                  if (this.filterMapping.InfluencerId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.influencerPinnedValue =
                this.filterMapping.InfluencerId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.InfluencerId.length ==
                    this.countOfAllData.influencerCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.influencerPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.influencerPinnedValue = 'All';
            }

            if (
              this.filterMapping.ContinentId != null &&
              this.filterMapping.ContinentId.length > 0
            ) {
              var codes = this.countinentData
                .filter((ele) => {
                  if (this.filterMapping.ContinentId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.continentPinnedValue =
                this.filterMapping.ContinentId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.ContinentId.length ==
                    this.countOfAllData.continentCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.continentPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.continentPinnedValue = 'All';
            }

            if (
              this.filterMapping.regionId != null &&
              this.filterMapping.regionId.length > 0
            ) {
              var codes = this.regionData
                .filter((ele) => {
                  if (this.filterMapping.regionId.indexOf(ele.value) >= 0) {
                    return ele;
                  }
                })
                .map((ele) => {
                  return ele.label;
                });
              this.pinnedFieldValuess.regionPinnedValue =
                this.filterMapping.regionId.length == 1 && !!codes[0]
                  ? codes[0].toString()
                  : this.filterMapping.regionId.length ==
                    this.countOfAllData.regionCount
                    ? 'All'
                    : codes.length;
              this.pinnedFieldValuesArray.regionPinnedValue = codes;
            } else {
              this.pinnedFieldValuess.regionPinnedValue = 'All';
            }
            if (
              this.filterService.filterObj.PinnedField != null &&
              this.filterService.filterObj.PinnedField != '' &&
              !this.enable360Dashboard // pinned field not required for 360 dashboard
            ) {
              for (var key in this.pinnedFields) {
                if (this.pinnedFields.hasOwnProperty(key)) {
                  this.pinnedFields[key] = false;
                  if (
                    this.filterService.filterObj.PinnedField.split(
                      this.delimiter
                    ).indexOf(key) >= 0
                  ) {
                    this.pinnedFields[key] = true;
                  }
                }
              }
            }
            this.blnSetPinned = true;
            // if (this.blnMaster) {
            //   this.showBusyIndicator = false;
            // }
          }
          catch (e) {
            this.showBusyIndicator = false;
          }
          resolve({ Status: 'success' });
        }, 0);
    });
  }
  reomveExtraIds(displayOnly = false) {
    var famids = [];
    var baids = [];
    var divids = [];
    var blids = [];
    var countryids = [];
    var operationalCountryids = [];
    var categoryids = [];
    var brandids = [];
    var zoneids = [];
    var branchids = [];
    var salesids = [];
    var serviceids = [];
    var continentids = [];
    var regionids = [];
    var customerNames = []; //11
    var customerAccountNumbers = [];
    var distributors = [];
    var distributorSegment = [];
    var distributorAccountNumber = [];

    if (
      this.filterMapping.FAMId != null &&
      this.filterMapping.FAMId.length > 0
    ) {
      this.filterMapping.FAMId.forEach((element) => {
        if (
          this.famData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          famids.push(element);
        }
      });
    }

    if (this.filterMapping.BAId != null && this.filterMapping.BAId.length > 0) {
      this.filterMapping.BAId.forEach((element) => {
        if (
          this.baData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          baids.push(element);
        }
      });
    }

    if (
      this.filterMapping.DIVId != null &&
      this.filterMapping.DIVId.length > 0
    ) {
      this.filterMapping.DIVId.forEach((element) => {
        if (
          this.divData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          divids.push(element);
        }
      });
    }
    //11 start
    if (
      this.filterMapping.customerNameId != null &&
      this.filterMapping.customerNameId.length > 0
    ) {
      this.filterMapping.customerNameId.forEach((element) => {
        if (
          this.customerNameData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          customerNames.push(element);
        }
      });
    }
    //11 end

    if (
      this.filterMapping.customerAccountNumberId != null &&
      this.filterMapping.customerAccountNumberId.length > 0
    ) {
      this.filterMapping.customerAccountNumberId.forEach((element) => {
        if (
          this.customerAccountNumberData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          customerAccountNumbers.push(element);
        }
      });
    }
    if (
      this.filterMapping.distributorsId != null &&
      this.filterMapping.distributorsId.length > 0
    ) {
      this.filterMapping.distributorsId.forEach((element) => {
        if (
          this.distributorsData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          distributors.push(element);
        }
      });
    }

    if (
      this.filterMapping.distributorSegmentId != null &&
      this.filterMapping.distributorSegmentId.length > 0
    ) {
      this.filterMapping.distributorSegmentId.forEach((element) => {
        if (
          this.distributorSegmentData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          distributorSegment.push(element);
        }
      });
    }

    if (
      this.filterMapping.distributorAccountNumberId != null &&
      this.filterMapping.distributorAccountNumberId.length > 0
    ) {
      this.filterMapping.distributorAccountNumberId.forEach((element) => {
        if (
          this.distributorAccountNumberData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          distributorAccountNumber.push(element);
        }
      });
    }

    if (this.filterMapping.BLId != null && this.filterMapping.BLId.length > 0) {
      this.filterMapping.BLId.forEach((element) => {
        if (
          this.blData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          blids.push(element);
        }
      });
    }

    if (
      this.filterMapping.BrandId != null &&
      this.filterMapping.BrandId.length > 0
    ) {
      this.filterMapping.BrandId.forEach((element) => {
        if (
          this.brandData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          brandids.push(element);
        }
      });
    }

    if (
      this.filterMapping.CountryId != null &&
      this.filterMapping.CountryId.length > 0
    ) {
      this.filterMapping.CountryId.forEach((element) => {
        if (
          this.countryData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          countryids.push(element);
        }
      });
    }

    if (
      this.filterMapping.OperationalCountryId != null &&
      this.filterMapping.OperationalCountryId.length > 0
    ) {
      this.filterMapping.OperationalCountryId.forEach((element) => {
        if (
          this.operationalCountryData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          operationalCountryids.push(element);
        }
      });
    }

    if (
      this.filterMapping.CategoryId != null &&
      this.filterMapping.CategoryId.length > 0
    ) {
      this.filterMapping.CategoryId.forEach((element) => {
        if (
          this.categoryData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          categoryids.push(element);
        }
      });
    }


    if (
      this.filterMapping.ContinentId != null &&
      this.filterMapping.ContinentId.length > 0
    ) {
      this.filterMapping.ContinentId.forEach((element) => {
        if (
          this.countinentData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          continentids.push(element);
        }
      });
    }
    if (
      this.filterMapping.regionId != null &&
      this.filterMapping.regionId.length > 0
    ) {
      this.filterMapping.regionId.forEach((element) => {
        if (
          this.regionData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          regionids.push(element);
        }
      });
    }

    if (
      this.filterMapping.ZoneId != null &&
      this.filterMapping.ZoneId.length > 0
    ) {
      this.filterMapping.ZoneId.forEach((element) => {
        if (
          this.zoneData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          zoneids.push(element);
        }
      });
    }

    if (
      this.filterMapping.BranchId != null &&
      this.filterMapping.BranchId.length > 0
    ) {
      this.filterMapping.BranchId.forEach((element) => {
        if (
          this.branchData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          branchids.push(element);
        }
      });
    }

    if (
      this.filterMapping.ServiceTechId != null &&
      this.filterMapping.ServiceTechId.length > 0
    ) {
      this.filterMapping.ServiceTechId.forEach((element) => {
        if (
          this.serviceTechData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          serviceids.push(element);
        }
      });
    }

    if (
      this.filterMapping.SalesEngId != null &&
      this.filterMapping.SalesEngId.length > 0
    ) {
      this.filterMapping.SalesEngId.forEach((element) => {
        if (
          this.salesEngData
            .map((ele) => {
              return ele.value;
            })
            .indexOf(element) >= 0
        ) {
          salesids.push(element);
        }
      });
    }


    if (displayOnly) {
      this.selectedCount.famCount = this.famData.filter((ele) => {
        if (famids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      this.selectedCount.baCount = this.baData.filter((ele) => {
        if (baids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      this.selectedCount.divCount = this.divData.filter((ele) => {
        if (divids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      //11 start
      this.selectedCount.customerNameCount = this.customerNameData.filter(
        (ele) => {
          if (customerNames.indexOf(ele.value) >= 0) {
            return ele;
          }
        }
      );
      //11 end

      this.selectedCount.customerAccountNumberCount = this.customerAccountNumberData.filter(
        (ele) => {
          if (customerAccountNumbers.indexOf(ele.value) >= 0) {
            return ele;
          }
        }
      );
      this.selectedCount.distributorsCount = this.distributorsData.filter(
        (ele) => {
          if (distributors.indexOf(ele.value) >= 0) {
            return ele;
          }
        }
      );

      this.selectedCount.distributorSegmentCount = this.distributorSegmentData.filter(
        (ele) => {
          if (distributorSegment.indexOf(ele.value) >= 0) {
            return ele;
          }
        }
      );

      this.selectedCount.distributorAccountNumberCount = this.distributorAccountNumberData.filter(
        (ele) => {
          if (distributorAccountNumber.indexOf(ele.value) >= 0) {
            return ele;
          }
        }
      );

      this.selectedCount.blCount = this.blData.filter((ele) => {
        if (blids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      this.selectedCount.countryCount = this.countryData.filter((ele) => {
        if (countryids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });

      this.selectedCount.operationalCountryCount = this.operationalCountryData.filter((ele) => {
        if (operationalCountryids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });

      this.selectedCount.categoryCount = this.categoryData.filter((ele) => {
        if (categoryids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });

      this.selectedCount.brandCount = this.brandData.filter((ele) => {
        if (brandids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      this.selectedCount.salesCount = this.salesEngData.filter((ele) => {
        if (salesids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      this.selectedCount.serviceCount = this.serviceTechData.filter((ele) => {
        if (serviceids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      this.selectedCount.zoneCount = this.zoneData.filter((ele) => {
        if (zoneids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      this.selectedCount.branchCount = this.branchData.filter((ele) => {
        if (branchids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      this.selectedCount.continentCount = this.countinentData.filter((ele) => {
        if (continentids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      this.selectedCount.regionCount = this.regionData.filter((ele) => {
        if (regionids.indexOf(ele.value) >= 0) {
          return ele;
        }
      });

      this.selectedCount.touchpointCount = this.getSelectedNodeArray(); //this.touchPointData.filter(ele => { if (this.filterMapping.TouchPointId.indexOf(ele.data) >= 0) { return ele } });
      this.selectedCount.influencerCount = this.influencerData.filter((ele) => {
        if (this.filterMapping.InfluencerId.indexOf(ele.value) >= 0) {
          return ele;
        }
      });
      this.filterService.updateSelectedFilter(this.selectedCount);
      //.map(ele=>{ return ele.label});
    } else {
      this.filterMapping.FAMId = famids;
      this.filterMapping.BAId = baids;
      this.filterMapping.DIVId = divids;
      this.filterMapping.BLId = blids;
      this.filterMapping.CountryId = countryids;
      this.filterMapping.OperationalCountryId = operationalCountryids;
      this.filterMapping.CategoryId = categoryids;
      this.filterMapping.BrandId = brandids;
      this.filterMapping.ZoneId = zoneids;
      this.filterMapping.BranchId = branchids;
      this.filterMapping.SalesEngId = salesids;
      this.filterMapping.ServiceTechId = serviceids;
      this.filterMapping.ContinentId = continentids;
      this.filterMapping.regionId = regionids;
      this.filterMapping.customerNameId = customerNames; //11
      this.filterMapping.customerAccountNumberId = customerAccountNumbers;
      this.filterMapping.distributorsId = distributors;
      this.filterMapping.distributorSegmentId = distributorSegment;
      this.filterMapping.distributorAccountNumberId = distributorAccountNumber;
    }
  }

  togglePin(fieldName) {
    var pinnedCount = 0;

    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          pinnedCount++;
        }
      }
    }

    if (pinnedCount <= 3) {
      switch (fieldName) {
        case 'fam': {
          this.pinnedFields.famPinned = !this.pinnedFields.famPinned;
          break;
        }
        case 'ba': {
          this.pinnedFields.baPinned = !this.pinnedFields.baPinned;
          break;
        }
        case 'div': {
          this.pinnedFields.divPinned = !this.pinnedFields.divPinned;
          break;
        }
        //11 start
        case 'custName': {
          this.pinnedFields.customerNamePinned = !this.pinnedFields
            .customerNamePinned;
          break;
        }
        //11 end

        case 'custAccNum': {
          this.pinnedFields.customerAccountNumberPinned = !this.pinnedFields
            .customerAccountNumberPinned;
          break;
        }
        case 'distributors': {
          this.pinnedFields.distributorsPinned = !this.pinnedFields
            .distributorsPinned;
          break;
        }

        case 'distributorSegment': {
          this.pinnedFields.distributorSegmentPinned = !this.pinnedFields
            .distributorSegmentPinned;
          break;
        }

        case 'distributorAccountNumber': {
          this.pinnedFields.distributorAccountNumberPinned = !this.pinnedFields
            .distributorAccountNumberPinned;
          break;
        }

        case 'bl': {
          this.pinnedFields.blPinned = !this.pinnedFields.blPinned;
          break;
        }
        case 'country': {
          this.pinnedFields.countryPinned = !this.pinnedFields.countryPinned;
          break;
        }
        case 'brand': {
          this.pinnedFields.brandPinned = !this.pinnedFields.brandPinned;
          break;
        }
        case 'zone': {
          this.pinnedFields.zonePinned = !this.pinnedFields.zonePinned;
          break;
        }
        case 'branch': {
          this.pinnedFields.branchPinned = !this.pinnedFields.branchPinned;
          break;
        }
        case 'sales': {
          this.pinnedFields.salesPinned = !this.pinnedFields.salesPinned;
          break;
        }
        case 'service': {
          this.pinnedFields.servicePinned = !this.pinnedFields.servicePinned;
          break;
        }
        case 'touchpoint': {
          this.pinnedFields.touchpointPinned = !this.pinnedFields
            .touchpointPinned;
          break;
        }
        case 'influencer': {
          this.pinnedFields.influencerPinned = !this.pinnedFields
            .influencerPinned;
          break;
        }
        case 'continent': {
          this.pinnedFields.continentPinned = !this.pinnedFields
            .continentPinned;
          break;
        }
        case 'region': {
          this.pinnedFields.regionPinned = !this.pinnedFields.regionPinned;
          break;
        }
      }
    } else {
      switch (fieldName) {
        case 'fam': {
          this.isShowAlert = !this.pinnedFields.famPinned;
          this.pinnedFields.famPinned = this.pinnedFields.famPinned
            ? !this.pinnedFields.famPinned
            : this.pinnedFields.famPinned;
          break;
        }
        case 'ba': {
          this.isShowAlert = !this.pinnedFields.baPinned;
          this.pinnedFields.baPinned = this.pinnedFields.baPinned
            ? !this.pinnedFields.baPinned
            : this.pinnedFields.baPinned;
          break;
        }
        case 'div': {
          this.isShowAlert = !this.pinnedFields.divPinned;
          this.pinnedFields.divPinned = this.pinnedFields.divPinned
            ? !this.pinnedFields.divPinned
            : this.pinnedFields.divPinned;
          break;
        }
        //11 start
        case 'custName': {
          this.isShowAlert = !this.pinnedFields.customerNamePinned;
          this.pinnedFields.customerNamePinned = this.pinnedFields
            .customerNamePinned
            ? !this.pinnedFields.customerNamePinned
            : this.pinnedFields.customerNamePinned;
          break;
        }
        //11 end

        case 'custAccNum': {
          this.isShowAlert = !this.pinnedFields.customerAccountNumberPinned;
          this.pinnedFields.customerAccountNumberPinned = this.pinnedFields
            .customerAccountNumberPinned
            ? !this.pinnedFields.customerAccountNumberPinned
            : this.pinnedFields.customerAccountNumberPinned;
          break;
        }
        case 'distributors': {
          this.isShowAlert = !this.pinnedFields.distributorsPinned;
          this.pinnedFields.distributorsPinned = this.pinnedFields
            .distributorsPinned
            ? !this.pinnedFields.distributorsPinned
            : this.pinnedFields.distributorsPinned;
          break;
        }

        case 'distributorSegment': {
          this.isShowAlert = !this.pinnedFields.distributorSegmentPinned;
          this.pinnedFields.distributorSegmentPinned = this.pinnedFields
            .distributorSegmentPinned
            ? !this.pinnedFields.distributorSegmentPinned
            : this.pinnedFields.distributorSegmentPinned;
          break;
        }

        case 'distributorAccountNumber': {
          this.isShowAlert = !this.pinnedFields.distributorAccountNumberPinned;
          this.pinnedFields.distributorAccountNumberPinned = this.pinnedFields
            .distributorAccountNumberPinned
            ? !this.pinnedFields.distributorAccountNumberPinned
            : this.pinnedFields.distributorAccountNumberPinned;
          break;
        }

        case 'bl': {
          this.isShowAlert = !this.pinnedFields.blPinned;
          this.pinnedFields.blPinned = this.pinnedFields.blPinned
            ? !this.pinnedFields.blPinned
            : this.pinnedFields.blPinned;
          break;
        }
        case 'country': {
          this.isShowAlert = !this.pinnedFields.countryPinned;
          this.pinnedFields.countryPinned = this.pinnedFields.countryPinned
            ? !this.pinnedFields.countryPinned
            : this.pinnedFields.countryPinned;
          break;
        }
        case 'brand': {
          this.isShowAlert = !this.pinnedFields.brandPinned;
          this.pinnedFields.brandPinned = this.pinnedFields.brandPinned
            ? !this.pinnedFields.brandPinned
            : this.pinnedFields.brandPinned;
          break;
        }
        case 'zone': {
          this.isShowAlert = !this.pinnedFields.zonePinned;
          this.pinnedFields.zonePinned = this.pinnedFields.zonePinned
            ? !this.pinnedFields.zonePinned
            : this.pinnedFields.zonePinned;
          break;
        }
        case 'branch': {
          this.isShowAlert = !this.pinnedFields.branchPinned;
          this.pinnedFields.branchPinned = this.pinnedFields.branchPinned
            ? !this.pinnedFields.branchPinned
            : this.pinnedFields.branchPinned;
          break;
        }
        case 'sales': {
          this.isShowAlert = !this.pinnedFields.salesPinned;
          this.pinnedFields.salesPinned = this.pinnedFields.salesPinned
            ? !this.pinnedFields.salesPinned
            : this.pinnedFields.salesPinned;
          break;
        }
        case 'service': {
          this.isShowAlert = !this.pinnedFields.servicePinned;
          this.pinnedFields.servicePinned = this.pinnedFields.servicePinned
            ? !this.pinnedFields.servicePinned
            : this.pinnedFields.servicePinned;
          break;
        }
        case 'touchpoint': {
          this.isShowAlert = !this.pinnedFields.touchpointPinned;
          this.pinnedFields.touchpointPinned = this.pinnedFields
            .touchpointPinned
            ? !this.pinnedFields.touchpointPinned
            : this.pinnedFields.touchpointPinned;
          break;
        }
        case 'influencer': {
          this.isShowAlert = !this.pinnedFields.influencerPinned;
          this.pinnedFields.influencerPinned = this.pinnedFields
            .influencerPinned
            ? !this.pinnedFields.influencerPinned
            : this.pinnedFields.influencerPinned;
          break;
        }
        case 'continent': {
          this.isShowAlert = !this.pinnedFields.continentPinned;
          this.pinnedFields.continentPinned = this.pinnedFields.continentPinned
            ? !this.pinnedFields.continentPinned
            : this.pinnedFields.continentPinned;
          break;
        }
        case 'region': {
          this.isShowAlert = !this.pinnedFields.regionPinned;
          this.pinnedFields.regionPinned = this.pinnedFields.regionPinned
            ? !this.pinnedFields.regionPinned
            : this.pinnedFields.regionPinned;
          break;
        }
      }

      if (this.isShowAlert) {
        this.successMsg = true;
        this.callErrorMsg('info', '', 'You can select only 4 quick filters.');
        // alert('Can not pin more than four field. Please unpin one of the finned field first.');
      }
    }

    var piinedFieldsArray = [];
    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          piinedFieldsArray.push(key);
        }
      }
    }

    var valueFromSession = sessionStorage.getItem('ctlsGlobalFilterObject');
    var delimiterSepratedObject = this.filterService.filterObj;
    if (valueFromSession != null) {
      delimiterSepratedObject = JSON.parse(
        sessionStorage.getItem('ctlsGlobalFilterObject')
      );
    }
    delimiterSepratedObject['PinnedField'] = piinedFieldsArray.join('|');

    sessionStorage.setItem(
      'ctlsGlobalFilterObject',
      JSON.stringify(delimiterSepratedObject)
    );
    this.filterService.updateFilterObject();

    // this.pinChanged.emit(this.pinnedFields);
    this.eventFromCtlsFilterHeader.emit({
      operation: 'FILTER_TOGGLE_PIN',
      data: this.pinnedFields,
    });
  }
  checkPinnedAvailability() {
    return _.values(this.pinnedFieldValuess).some((item) => item != 'All');
  }
  checkSelectedAvailability() {
    return Object.entries(this.selectedCount).toString() !== Object.entries(this.initialCount).toString();
  }
  toggleFilter() {
    this.successMsg = false;
    this.msgs = [];
    this.filterToggle = !this.filterToggle;
    this.ctlsSharedService.setToggleFilterSub({
      field: 'BusinessFilter',
      toggleFilter: true,
      toggleSearch: true
    });
    if (this.filterToggle) {
      if (this.blnInit) {
        // if(this.clearTimeoutFilter){
        //   this.clearTimeoutFilter = clearTimeout(this.clearTimeoutFilter);
        // }
        setTimeout(() => {
          this.showBusyIndicator = true;
        }, 0)
      }
      ////if (!this.checkSelectedAvailability() && this.checkPinnedAvailability()) {
      ////  this.showBusyIndicator = true;
      ////}
      ////else if (this.checkSelectedAvailability() && !this.checkPinnedAvailability()) {
      ////  this.showBusyIndicator = true;
      ////}
      if (!this.blnInit) {
        this.convertToArray();
        this.applyCasecadingOnList().then(async (res) => {
          await this.applyfilterOnLabel();
        });
      }
    } else {
      this.showBusyIndicator = false;
    }
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
  }

  toggleDateFilter() {
    this.filterDateToggle = !this.filterDateToggle;
    if (this.filterDateToggle) {
      this.convertToArray();
    }
    this.filterToggle = false;
    this.pinFilterSecVisible = false;
    localStorage.setItem('onSearch', 'true');
    localStorage.removeItem('backButton');
    localStorage.removeItem('filterClicked');
  }

  closeInlineFilter() {
    this.pinFilterSecVisible = false;
  }

  toggleInlineFilter(fieldName, fieldCountName, pinnedFieldName) {
    this.pinFilterSecVisible = !this.pinFilterSecVisible;
    if (this.pinFilterSecVisible) {
      if (this.blnInit) {
        // if(this.clearTimeoutFilter){
        //   this.clearTimeoutFilter = clearTimeout(this.clearTimeoutFilter);
        // }
        setTimeout(() => {
          this.showBusyIndicator = true;
        }, 0)
      }
      ////if(this.selectedCount[fieldCountName].length){
      ////  this.checkIndicator(true);
      ////}
      ////if (!this.selectedCount[fieldCountName].length && this.pinnedFieldValuess[pinnedFieldName] != 'All') {
      ////  this.showBusyIndicator = true;
      ////}
      ////if (this.selectedCount[fieldCountName].length && this.pinnedFieldValuess[pinnedFieldName] == 'All') {
      ////  this.showBusyIndicator = true;
      ////}

      if (!this.blnInit) {
        this.convertToArray();
        this.applyCasecadingOnList().then(async (res) => {
          await this.applyfilterOnLabel();
        });
      }
    } else {
      this.showBusyIndicator = false;
    }

    for (var key in this.inlineFilter) {
      if (this.inlineFilter.hasOwnProperty(key)) {
        this.inlineFilter[key] = false;
        if (key == fieldName) {
          this.inlineFilter[key] = true;
        }
      }
    }

    this.filterDateToggle = false;
    this.filterToggle = false;
  }
  applyfilterOnLabel() {
    return new Promise((resolve) => {
      this.clearTimeoutLabel =
        setTimeout(() => {
          this.reomveExtraIds(true);
          if (!!this.objCtlsFilterFields.FAMCode)
            if (
              this.filterMapping.FAMId != null &&
              this.filterMapping.FAMId.length >= 4
            ) {
              if (this.famDDL) {
                this.famDDL.valuesAsString =
                  this.selectedCount.famCount.length + ' items selected';
              } else if (this.famDDLPinned) {
                this.famDDLPinned.valuesAsString =
                  this.selectedCount.famCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.FAMId != null &&
              this.filterMapping.FAMId.length < 4 &&
              this.filterMapping.FAMId.length >= 1
            ) {
              if (this.famDDL) {
                this.famDDL.valuesAsString = this.selectedCount.famCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.famDDLPinned) {
                this.famDDLPinned.valuesAsString = this.selectedCount.famCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }

          if (!!this.objCtlsFilterFields.BACode)
            if (
              this.filterMapping.BAId != null &&
              this.filterMapping.BAId.length >= 4
            ) {
              if (this.baDDL) {
                this.baDDL.valuesAsString =
                  this.selectedCount.baCount.length + ' items selected';
              } else if (this.baDDLPinned) {
                this.baDDLPinned.valuesAsString =
                  this.selectedCount.baCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.BAId != null &&
              this.filterMapping.BAId.length < 4 &&
              this.filterMapping.BAId.length >= 1
            ) {
              if (this.baDDL) {
                this.baDDL.valuesAsString = this.selectedCount.baCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.baDDLPinned) {
                this.baDDLPinned.valuesAsString = this.selectedCount.baCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.DivCode)
            if (
              this.filterMapping.DIVId != null &&
              this.filterMapping.DIVId.length >= 4
            ) {
              if (this.divDDL) {
                this.divDDL.valuesAsString =
                  this.selectedCount.divCount.length + ' items selected';
              } else if (this.divDDLPinned) {
                this.divDDLPinned.valuesAsString =
                  this.selectedCount.divCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.DIVId != null &&
              this.filterMapping.DIVId.length < 4 &&
              this.filterMapping.DIVId.length >= 1
            ) {
              if (this.divDDL) {
                this.divDDL.valuesAsString = this.selectedCount.divCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.divDDLPinned) {
                this.divDDLPinned.valuesAsString = this.selectedCount.divCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          //11 start
          if (!!this.objCtlsFilterFields.CustomerName)
            if (
              this.filterMapping.customerNameId != null &&
              this.filterMapping.customerNameId.length >= 4
            ) {
              if (this.custNameDDL) {
                this.custNameDDL.valuesAsString =
                  this.selectedCount.customerNameCount.length + ' items selected';
              } else if (this.custNameDDLPinned) {
                this.custNameDDLPinned.valuesAsString =
                  this.selectedCount.customerNameCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.customerNameId != null &&
              this.filterMapping.customerNameId.length < 4 &&
              this.filterMapping.customerNameId.length >= 1
            ) {
              if (this.custNameDDL) {
                this.custNameDDL.valuesAsString = this.selectedCount.customerNameCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.custNameDDLPinned) {
                this.custNameDDLPinned.valuesAsString = this.selectedCount.customerNameCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          //11 end
          if (!!this.objCtlsFilterFields.CustomerAccountNumber)
            if (
              this.filterMapping.customerAccountNumberId != null &&
              this.filterMapping.customerAccountNumberId.length >= 4
            ) {
              if (this.custAccNumDDL) {
                this.custAccNumDDL.valuesAsString =
                  this.selectedCount.customerAccountNumberCount.length +
                  ' items selected';
              } else if (this.custAccNumDDLPinned) {
                this.custAccNumDDLPinned.valuesAsString =
                  this.selectedCount.customerAccountNumberCount.length +
                  ' items selected';
              }
            } else if (
              this.filterMapping.customerAccountNumberId != null &&
              this.filterMapping.customerAccountNumberId.length < 4 &&
              this.filterMapping.customerAccountNumberId.length >= 1
            ) {
              if (this.custAccNumDDL) {
                this.custAccNumDDL.valuesAsString = this.selectedCount.customerAccountNumberCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.custAccNumDDLPinned) {
                this.custAccNumDDLPinned.valuesAsString = this.selectedCount.customerAccountNumberCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.Distributors)
            if (
              this.filterMapping.distributorsId != null &&
              this.filterMapping.distributorsId.length >= 4
            ) {
              if (this.distributorsDDL) {
                this.distributorsDDL.valuesAsString =
                  this.selectedCount.distributorsCount.length + ' items selected';
              } else if (this.distributorsDDLPinned) {
                this.distributorsDDLPinned.valuesAsString =
                  this.selectedCount.distributorsCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.distributorsId != null &&
              this.filterMapping.distributorsId.length < 4 &&
              this.filterMapping.distributorsId.length >= 1
            ) {
              if (this.distributorsDDL) {
                this.distributorsDDL.valuesAsString = this.selectedCount.distributorsCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.distributorsDDLPinned) {
                this.distributorsDDLPinned.valuesAsString = this.selectedCount.distributorsCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.DistributorSegment)
            if (
              this.filterMapping.distributorSegmentId != null &&
              this.filterMapping.distributorSegmentId.length >= 4
            ) {
              if (this.distributorSegmentDDL) {
                this.distributorSegmentDDL.valuesAsString =
                  this.selectedCount.distributorSegmentCount.length +
                  ' items selected';
              } else if (this.distributorSegmentDDLPinned) {
                this.distributorSegmentDDLPinned.valuesAsString =
                  this.selectedCount.distributorSegmentCount.length +
                  ' items selected';
              }
            } else if (
              this.filterMapping.distributorSegmentId != null &&
              this.filterMapping.distributorSegmentId.length < 4 &&
              this.filterMapping.distributorSegmentId.length >= 1
            ) {
              if (this.distributorSegmentDDL) {
                this.distributorSegmentDDL.valuesAsString = this.selectedCount.distributorSegmentCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.distributorSegmentDDLPinned) {
                this.distributorSegmentDDLPinned.valuesAsString = this.selectedCount.distributorSegmentCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.DistributorAccountNumber)
            if (
              this.filterMapping.distributorAccountNumberId != null &&
              this.filterMapping.distributorAccountNumberId.length >= 4
            ) {
              if (this.distributorAccountNumberDDL) {
                this.distributorAccountNumberDDL.valuesAsString =
                  this.selectedCount.distributorAccountNumberCount.length +
                  ' items selected';
              } else if (this.distributorAccountNumberDDLPinned) {
                this.distributorAccountNumberDDLPinned.valuesAsString =
                  this.selectedCount.distributorAccountNumberCount.length +
                  ' items selected';
              }
            } else if (
              this.filterMapping.distributorAccountNumberId != null &&
              this.filterMapping.distributorAccountNumberId.length < 4 &&
              this.filterMapping.distributorAccountNumberId.length >= 1
            ) {
              if (this.distributorAccountNumberDDL) {
                this.distributorAccountNumberDDL.valuesAsString = this.selectedCount.distributorAccountNumberCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.distributorAccountNumberDDLPinned) {
                this.distributorAccountNumberDDLPinned.valuesAsString = this.selectedCount.distributorAccountNumberCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.BLCode)
            if (
              this.filterMapping.BLId != null &&
              this.filterMapping.BLId.length >= 4
            ) {
              if (this.blDDL) {
                this.blDDL.valuesAsString =
                  this.selectedCount.blCount.length + ' items selected';
              } else if (this.blDDLPinned) {
                this.blDDLPinned.valuesAsString =
                  this.selectedCount.blCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.BLId != null &&
              this.filterMapping.BLId.length < 4 &&
              this.filterMapping.BLId.length >= 1
            ) {
              if (this.blDDL) {
                this.blDDL.valuesAsString = this.selectedCount.blCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.blDDLPinned) {
                this.blDDLPinned.valuesAsString = this.selectedCount.blCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }

          if (!!this.objCtlsFilterFields.Country)
            if (
              this.filterMapping.CountryId != null &&
              this.filterMapping.CountryId.length >= 4
            ) {
              if (this.countryDDL) {
                this.countryDDL.valuesAsString =
                  this.selectedCount.countryCount.length + ' items selected';
              } else if (this.countryDDLPinned) {
                this.countryDDLPinned.valuesAsString =
                  this.selectedCount.countryCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.CountryId != null &&
              this.filterMapping.CountryId.length < 4 &&
              this.filterMapping.CountryId.length >= 1
            ) {
              if (this.countryDDL) {
                this.countryDDL.valuesAsString = this.selectedCount.countryCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.countryDDLPinned) {
                this.countryDDLPinned.valuesAsString = this.selectedCount.countryCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }

          if (!!this.objCtlsFilterFields.OperationalCountry)
            if (
              this.filterMapping.OperationalCountryId != null &&
              this.filterMapping.OperationalCountryId.length >= 4
            ) {
              if (this.operationalCountryDDL) {
                this.operationalCountryDDL.valuesAsString =
                  this.selectedCount.operationalCountryCount.length + ' items selected';
              } else if (this.operationalCountryDDLPinned) {
                this.operationalCountryDDLPinned.valuesAsString =
                  this.selectedCount.operationalCountryCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.OperationalCountryId != null &&
              this.filterMapping.OperationalCountryId.length < 4 &&
              this.filterMapping.OperationalCountryId.length >= 1
            ) {

              if (this.operationalCountryDDL) {
                this.operationalCountryDDL.valuesAsString = this.selectedCount.operationalCountryCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.operationalCountryDDLPinned) {
                this.operationalCountryDDLPinned.valuesAsString = this.selectedCount.operationalCountryCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }


          if (!!this.objCtlsFilterFields.Category)
            if (
              this.filterMapping.CategoryId != null &&
              this.filterMapping.CategoryId.length >= 4
            ) {
              if (this.categoryDDL) {
                this.categoryDDL.valuesAsString =
                  this.selectedCount.categoryCount.length + ' items selected';
              } else if (this.categoryDDLPinned) {
                this.categoryDDLPinned.valuesAsString =
                  this.selectedCount.categoryCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.CategoryId != null &&
              this.filterMapping.CategoryId.length < 4 &&
              this.filterMapping.CategoryId.length >= 1
            ) {
              if (this.categoryDDL) {
                this.categoryDDL.valuesAsString = this.selectedCount.categoryCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.categoryDDLPinned) {
                this.categoryDDLPinned.valuesAsString = this.selectedCount.categoryCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }


          if (!!this.objCtlsFilterFields.BrandName)
            if (
              this.filterMapping.BrandId != null &&
              this.filterMapping.BrandId.length >= 4
            ) {
              if (this.brandDDL) {
                this.brandDDL.valuesAsString =
                  this.selectedCount.brandCount.length + ' items selected';
              } else if (this.brandDDLPinned) {
                this.brandDDLPinned.valuesAsString =
                  this.selectedCount.brandCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.BrandId != null &&
              this.filterMapping.BrandId.length < 4 &&
              this.filterMapping.BrandId.length >= 1
            ) {
              if (this.brandDDL) {
                this.brandDDL.valuesAsString = this.selectedCount.brandCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.brandDDLPinned) {
                this.brandDDLPinned.valuesAsString = this.selectedCount.brandCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.Zone)
            if (
              this.filterMapping.ZoneId != null &&
              this.filterMapping.ZoneId.length >= 4
            ) {
              if (this.zoneDDL) {
                this.zoneDDL.valuesAsString =
                  this.selectedCount.zoneCount.length + ' items selected';
              } else if (this.zoneDDLPinned) {
                this.zoneDDLPinned.valuesAsString =
                  this.selectedCount.zoneCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.ZoneId != null &&
              this.filterMapping.ZoneId.length < 4 &&
              this.filterMapping.ZoneId.length >= 1
            ) {
              if (this.zoneDDL) {
                this.zoneDDL.valuesAsString = this.selectedCount.zoneCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.zoneDDLPinned) {
                this.zoneDDLPinned.valuesAsString = this.selectedCount.zoneCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.Branch)
            if (
              this.filterMapping.BranchId != null &&
              this.filterMapping.BranchId.length >= 4
            ) {
              if (this.branchDDL) {
                this.branchDDL.valuesAsString =
                  this.selectedCount.branchCount.length + ' items selected';
              } else if (this.branchDDLPinned) {
                this.branchDDLPinned.valuesAsString =
                  this.selectedCount.branchCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.BranchId != null &&
              this.filterMapping.BranchId.length < 4 &&
              this.filterMapping.BranchId.length >= 1
            ) {
              if (this.branchDDL) {
                this.branchDDL.valuesAsString = this.selectedCount.branchCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.branchDDLPinned) {
                this.branchDDLPinned.valuesAsString = this.selectedCount.branchCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.SalesEngineer)
            if (
              this.filterMapping.SalesEngId != null &&
              this.filterMapping.SalesEngId.length >= 4
            ) {
              if (this.salesDDL) {
                this.salesDDL.valuesAsString =
                  this.selectedCount.salesCount.length + ' items selected';
              } else if (this.salesDDLPinned) {
                this.salesDDLPinned.valuesAsString =
                  this.selectedCount.salesCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.SalesEngId != null &&
              this.filterMapping.SalesEngId.length < 4 &&
              this.filterMapping.SalesEngId.length >= 1
            ) {
              if (this.salesDDL) {
                this.salesDDL.valuesAsString = this.selectedCount.salesCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.salesDDLPinned) {
                this.salesDDLPinned.valuesAsString = this.selectedCount.salesCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.ServiceTechnician)
            if (
              this.filterMapping.ServiceTechId != null &&
              this.filterMapping.ServiceTechId.length >= 4
            ) {
              if (this.serviceDDL) {
                this.serviceDDL.valuesAsString =
                  this.selectedCount.serviceCount.length + ' items selected';
              } else if (this.serviceDDLPinned) {
                this.serviceDDLPinned.valuesAsString =
                  this.selectedCount.serviceCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.ServiceTechId != null &&
              this.filterMapping.ServiceTechId.length < 4 &&
              this.filterMapping.ServiceTechId.length >= 1
            ) {
              if (this.serviceDDL) {
                this.serviceDDL.valuesAsString = this.selectedCount.serviceCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.serviceDDLPinned) {
                this.serviceDDLPinned.valuesAsString = this.selectedCount.serviceCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.Continent)
            if (
              this.filterMapping.ContinentId != null &&
              this.filterMapping.ContinentId.length >= 4
            ) {
              if (this.continentDDL) {
                this.continentDDL.valuesAsString =
                  this.selectedCount.continentCount.length + ' items selected';
              } else if (this.continentDDLPinned) {
                this.continentDDLPinned.valuesAsString =
                  this.selectedCount.continentCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.ContinentId != null &&
              this.filterMapping.ContinentId.length < 4 &&
              this.filterMapping.ContinentId.length >= 1
            ) {
              if (this.continentDDL) {
                this.continentDDL.valuesAsString = this.selectedCount.continentCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.continentDDLPinned) {
                this.continentDDLPinned.valuesAsString = this.selectedCount.continentCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }
          if (!!this.objCtlsFilterFields.Region)
            if (
              this.filterMapping.regionId != null &&
              this.filterMapping.regionId.length >= 4
            ) {
              if (this.regionDDL) {
                this.regionDDL.valuesAsString =
                  this.selectedCount.regionCount.length + ' items selected';
              } else if (this.regionDDLPinned) {
                this.regionDDLPinned.valuesAsString =
                  this.selectedCount.regionCount.length + ' items selected';
              }
            } else if (
              this.filterMapping.regionId != null &&
              this.filterMapping.regionId.length < 4 &&
              this.filterMapping.regionId.length >= 1
            ) {
              if (this.regionDDL) {
                this.regionDDL.valuesAsString = this.selectedCount.regionCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              } else if (this.regionDDLPinned) {
                this.regionDDLPinned.valuesAsString = this.selectedCount.regionCount
                  .map((ele) => {
                    return ele.label;
                  })
                  .join(', ');
              }
            }

          // for touchpoint
          if (!!this.objCtlsFilterFields.Touchpoint)
            if (
              this.filterMapping.TouchPointId == null ||
              this.filterMapping.TouchPointId.length == 0
            ) {
              this.tpSelectionlabel = 'Select Touchpoint';
            } else if (this.filterMapping.TouchPointId.length >= 4) {
              this.tpSelectionlabel =
                this.selectedCount.touchpointCount.length + ' items selected';
            } else if (
              this.filterMapping.TouchPointId.length < 4 &&
              this.filterMapping.TouchPointId.length >= 1
            ) {
              this.tpSelectionlabel = this.selectedCount.touchpointCount
                .map((ele) => ele.label)
                .join(', ');
            }
          // if(this.clearTimeoutPinned){
          //   this.clearTimeoutPinned = clearTimeout(this.clearTimeoutPinned);
          // }
          // if(!!this.clearTimeoutFilter){
          //   this.clearTimeoutFilter = clearTimeout(this.clearTimeoutFilter);
          // }
          if (this.count) {
            if (this.blnInit) {
              this.showBusyIndicator = false;
              this.blnInit = false;
            }
          }
          if (this.count === 0) {
            this.count++
          }
          resolve({ Status: 'success' });
        }, 0)
    })
  }

  checkIndicator(blnIndicator) {
    if (Object.entries(this.selectedCount).toString() !== Object.entries(this.initialCount).toString()) {
      this.showBusyIndicator = blnIndicator;
    }
  }
  removeSelectedValue(id, fieldName) {
    switch (fieldName) {
      case 'fam': {
        var index = this.filterMapping.FAMId.indexOf(id);
        if (index > -1) {
          this.filterMapping.FAMId.splice(index, 1);
          this.SelectedFams(true);
          if (this.famDDL) {
            this.famDDL.updateLabel();
          } else if (this.famDDLPinned) {
            this.famDDLPinned.updateLabel();
          }
        }
        break;
      }
      case 'ba': {
        var index = this.filterMapping.BAId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BAId.splice(index, 1);
          this.SelectedBas(true);
          if (this.baDDL) {
            this.baDDL.updateLabel();
          } else if (this.baDDLPinned) {
            this.baDDLPinned.updateLabel();
          }
        }
        break;
      }
      case 'div': {
        var index = this.filterMapping.DIVId.indexOf(id);
        if (index > -1) {
          this.filterMapping.DIVId.splice(index, 1);
          this.SelectedDivs(true);
          if (this.divDDL) {
            this.divDDL.updateLabel();
          } else if (this.divDDLPinned) {
            this.divDDLPinned.updateLabel();
          }
        }
        break;
      }
      //11 start
      case 'custName': {
        var index = this.filterMapping.customerNameId.indexOf(id);
        if (index > -1) {
          this.filterMapping.customerNameId.splice(index, 1);
          this.SelectedCustomerNames(true);
          if (this.custNameDDL) {
            this.custNameDDL.updateLabel();
          } else if (this.custNameDDLPinned) {
            this.custNameDDLPinned.updateLabel();
          }
        }
        break;
      }
      //11 end
      case 'custAccNum': {
        var index = this.filterMapping.customerAccountNumberId.indexOf(id);
        if (index > -1) {
          this.filterMapping.customerAccountNumberId.splice(index, 1);
          this.SelectedCustomerAccountNumbers(true);
          if (this.custAccNumDDL) {
            this.custAccNumDDL.updateLabel();
          } else if (this.custAccNumDDLPinned) {
            this.custAccNumDDLPinned.updateLabel();
          }
        }
        break;
      }
      case 'distributors': {
        var index = this.filterMapping.distributorsId.indexOf(id);
        if (index > -1) {
          this.filterMapping.distributorsId.splice(index, 1);
          this.SelectedDistributors(true);
          if (this.distributorsDDL) {
            this.distributorsDDL.updateLabel();
          } else if (this.distributorsDDLPinned) {
            this.distributorsDDLPinned.updateLabel();
          }
        }
        break;
      }

      case 'distributorSegment': {
        var index = this.filterMapping.distributorSegmentId.indexOf(id);
        if (index > -1) {
          this.filterMapping.distributorSegmentId.splice(index, 1);
          this.SelectedDistributorSegment(true);
          if (this.distributorSegmentDDL) {
            this.distributorSegmentDDL.updateLabel();
          } else if (this.distributorSegmentDDLPinned) {
            this.distributorSegmentDDLPinned.updateLabel();
          }
        }
        break;
      }

      case 'distributorAccountNumber': {
        var index = this.filterMapping.distributorAccountNumberId.indexOf(id);
        if (index > -1) {
          this.filterMapping.distributorAccountNumberId.splice(index, 1);
          this.SelectedDistributorAccountNumber(true);
          if (this.distributorAccountNumberDDL) {
            this.distributorAccountNumberDDL.updateLabel();
          } else if (this.distributorAccountNumberDDLPinned) {
            this.distributorAccountNumberDDLPinned.updateLabel();
          }
        }
        break;
      }

      case 'bl': {
        var index = this.filterMapping.BLId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BLId.splice(index, 1);
          this.SelectedBls(true);
          if (this.blDDL) {
            this.blDDL.updateLabel();
          } else if (this.blDDLPinned) {
            this.blDDLPinned.updateLabel();
          }
        }
        break;
      }
      case 'country': {
        var index = this.filterMapping.CountryId.indexOf(id);
        if (index > -1) {
          this.filterMapping.CountryId.splice(index, 1);
          this.SelectedCountries(true);
          if (this.countryDDL) {
            this.countryDDL.updateLabel();
          } else if (this.countryDDLPinned) {
            this.countryDDLPinned.updateLabel();
          }
        }
        break;
      }

      case 'operationalCountry': {
        var index = this.filterMapping.OperationalCountryId.indexOf(id);
        if (index > -1) {
          this.filterMapping.OperationalCountryId.splice(index, 1);
          this.SelectedCountries(true);
          if (this.operationalCountryDDL) {
            this.operationalCountryDDL.updateLabel();
          } else if (this.operationalCountryDDLPinned) {
            this.operationalCountryDDLPinned.updateLabel();
          }
        }
        break;
      }

      case 'category': {
        var index = this.filterMapping.CategoryId.indexOf(id);
        if (index > -1) {
          this.filterMapping.CategoryId.splice(index, 1);
          this.SelectedCategory(true);
          if (this.categoryDDL) {
            this.categoryDDL.updateLabel();
          } else if (this.categoryDDLPinned) {
            this.categoryDDLPinned.updateLabel();
          }
        }
        break;
      }

      case 'brand': {
        var index = this.filterMapping.BrandId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BrandId.splice(index, 1);
          this.SelectedBrands(true);
          if (this.brandDDL) {
            this.brandDDL.updateLabel();
          } else if (this.brandDDLPinned) {
            this.brandDDLPinned.updateLabel();
          }
        }
        break;
      }
      case 'zone': {
        var index = this.filterMapping.ZoneId.indexOf(id);
        if (index > -1) {
          this.filterMapping.ZoneId.splice(index, 1);
          this.SelectedZones(true);
          if (this.zoneDDL) {
            this.zoneDDL.updateLabel();
          } else if (this.zoneDDLPinned) {
            this.zoneDDLPinned.updateLabel();
          }
        }
        break;
      }
      case 'branch': {
        var index = this.filterMapping.BranchId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BranchId.splice(index, 1);
          this.SelectedBranchs(true);
          if (this.branchDDL) {
            this.branchDDL.updateLabel();
          } else if (this.branchDDLPinned) {
            this.branchDDLPinned.updateLabel();
          }
        }
        break;
      }
      case 'sales': {
        var index = this.filterMapping.SalesEngId.indexOf(id);
        if (index > -1) {
          this.filterMapping.SalesEngId.splice(index, 1);
          this.SelectedSalesEngs(true);
          if (this.salesDDL) {
            this.salesDDL.updateLabel();
          } else if (this.salesDDLPinned) {
            this.salesDDLPinned.updateLabel();
          }
        }
        break;
      }
      case 'service': {
        var index = this.filterMapping.ServiceTechId.indexOf(id);
        if (index > -1) {
          this.filterMapping.ServiceTechId.splice(index, 1);
          this.SelectedServiceTechs(true);
          if (this.serviceDDL) {
            this.serviceDDL.updateLabel();
          } else if (this.serviceDDLPinned) {
            this.serviceDDLPinned.updateLabel();
          }
        }
        break;
      }

      case 'touchpoint': {
        var index = this.filterMapping.TouchPointId.indexOf(id);
        if (index > -1) {
          this.filterMapping.TouchPointId.splice(index, 1);
          this.SelectedTouchPoints(true);
          if (this.tpDDL) {
            this.tpDDL.updateLabel();
          } else if (this.tpDDLPinned) {
            this.tpDDLPinned.updateLabel();
          }
        }
        // this.updateTouchpointTreeLabel();
        break;
      }
      case 'influencer': {
        var index = this.filterMapping.InfluencerId.indexOf(id);
        if (index > -1) {
          this.filterMapping.InfluencerId.splice(index, 1);
          this.reomveExtraIds(true);
          if (this.infDDL) {
            this.infDDL.updateLabel();
          } else if (this.infDDLPinned) {
            this.infDDLPinned.updateLabel();
          }
        }
        break;
      }
      case 'continent': {
        var index = this.filterMapping.ContinentId.indexOf(id);
        if (index > -1) {
          this.filterMapping.ContinentId.splice(index, 1);
          this.SelectedContinents(true);
          if (this.continentDDL) {
            this.continentDDL.updateLabel();
          } else if (this.continentDDLPinned) {
            this.continentDDLPinned.updateLabel();
          }
        }
        break;
      }
      case 'region': {
        var index = this.filterMapping.regionId.indexOf(id);
        if (index > -1) {
          this.filterMapping.regionId.splice(index, 1);
          this.SelectedRegions(true);
          if (this.regionDDL) {
            this.regionDDL.updateLabel();
          } else if (this.regionDDLPinned) {
            this.regionDDLPinned.updateLabel();
          }
        }
        break;
      }
    }
  }

  private includes(container, value) {
    var returnValue = false;
    var pos = container.indexOf(value);
    if (pos >= 0) {
      returnValue = true;
    }
    return returnValue;
  }

  private changeDateFormat(data) {
    var splitDate = data.split('-');
    var month_names = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var indexMonth = month_names.indexOf(splitDate[1]);
    return indexMonth + 1 + '/' + splitDate[0] + '/' + splitDate[2];
  }

  private changeDateFormate(date) {
    var momentDate = moment(date);
    return momentDate.format('DD-MMM-YYYY');
  }

  //severity: info/success/warn/error
  callErrorMsg(type, summary = '', detail = '') {
    this.successMsg = true;
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () {
      document.getElementById('ClearMsg').click();
    }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }
  // added below function in replacement of getDistinct
  // parameters
  // data -array list from which unique list will be returned
  //value- key for value
  //label- key for label
  //key - to check label or anyother key
  private getDistinctByValue(data, value, label, key) {
    let uniqueLists = [];
    let ids = [];
    if (key == 'label') {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element[label] != null) {
          if (ids.indexOf(element[label].trim().toLowerCase()) == -1) {
            // removed map method due to this fillter is resposing slow
            uniqueLists.push({
              value: element[value],
              label: element[label],
            }); // directly pushing distinct  object  to uniquelist array
            ids.push(element[label].trim().toLowerCase());
          }
        }
      }
    } else {
      data.forEach((element) => {
        if (ids.indexOf(element[value]) == -1 && element[value] != null) {
          uniqueLists.push({ value: element[value], label: element[label] }); // directly pushing distinct  object  to uniquelist array
          ids.push(element[value]);
        }
      });
    }
    uniqueLists = uniqueLists.sort(function (a, b) {
      return a.label > b.label ? 1 : -1;
    });
    return uniqueLists;
  }
  applyCasecadingOnList(field = '') {
    if (this.blnInit) {
      this.showBusyIndicator = true;
    }
    let _this =this;
    return new Promise((resolve) => {
      //TODO commented setTimeout due to loading issue.. before loading cant stop.
      this.clearTimeoutFilter =
        setTimeout(() => {
          //added new method  'getDistinctByValue' removed getDistinct method and map method to resolve slowness of filter issue, as getDistinct method is very slow to respone

          this.famData = !!this.objCtlsFilterFields.FAMCode
            ? this.getDistinctByValue(
              this.filterList('fam'),
              'FAMID',
              'FAMCode',
              'value'
            )
            : [];
          this.baData = !!this.objCtlsFilterFields.BACode
            ? this.getDistinctByValue(
              this.filterList('ba'),
              'BAID',
              'BACode',
              'value'
            )
            : [];
          this.divData = !!this.objCtlsFilterFields.DivCode
            ? this.getDistinctByValue(
              this.filterList('div'),
              'DivisionID',
              'DivisionCode',
              'value'
            )
            : [];
          //11 start
          this.customerNameData = !!this.objCtlsFilterFields.CustomerName
            ? this.getDistinctByValue(
              this.filterList('custName'),
              'CustomerName',
              'CustomerName',
              'label'
            )
            : [];
          //11 end

          this.customerAccountNumberData = !!this.objCtlsFilterFields
            .CustomerAccountNumber
            ? this.getDistinctByValue(
              this.filterList('custAccNum'),
              'CustomerAccountNumber',
              'CustomerAccountNumber',
              'label'
            )
            : [];

          this.distributorsData = !!this.objCtlsFilterFields.Distributors
            ? this.getDistinctByValue(
              this.filterList('distributors'),
              'DistributorName',
              'DistributorName',
              'label'
            )
            : [];
           
          this.distributorSegmentData = !!this.objCtlsFilterFields
            .DistributorSegment
            ? this.getDistinctByValue(
              this.filterList('distributorSegment'),
              'DistributorSegment',
              'DistributorSegment',
              'label'
            )
            : [];
          this.distributorAccountNumberData = !!this.objCtlsFilterFields
            .DistributorAccountNumber
            ? this.getDistinctByValue(
              this.filterList('distributorAccountNumber'),
              'AccountNumber',
              'AccountNumber',
              'label'
            )
            : [];
          this.blData = !!this.objCtlsFilterFields.BLCode
            ? this.getDistinctByValue(
              this.filterList('bl'),
              'BLID',
              'BLName',
              'value'
            )
            : [];
          this.brandData = !!this.objCtlsFilterFields.BrandName
            ? this.getDistinctByValue(
              this.filterList('brand'),
              'BrandID',
              'BrandName',
              'value'
            )
            : [];
          this.countryData = !!this.objCtlsFilterFields.Country
            ? this.getDistinctByValue(
              this.filterList('country'),
              'CountryID',
              'CountryName',
              'value'
            )
            : [];
          this.operationalCountryData = !!this.objCtlsFilterFields.OperationalCountry
            ? this.getDistinctByValue(
              this.filterList('operationalCountry'),
              'CountryID',
              'CountryName',
              'value'
            )
            : [];
          this.categoryData = !!this.objCtlsFilterFields.Category
            ? this.getDistinctByValue(
              this.filterList('category'),
              'CTLSCategoryID',
              'CTLSCategoryName',
              'CTLSCategoryName'
            )
            : [];
          this.countinentData = !!this.objCtlsFilterFields.Continent
            ? this.getDistinctByValue(
              this.filterList('continent'),
              'ContinentID',
              'ContinentName',
              'value'
            )
            : [];
          this.regionData = !!this.objCtlsFilterFields.Region
            ? this.getDistinctByValue(
              this.filterList('region'),
              'RegionID',
              'RegionName',
              'value'
            )
            : [];
          this.zoneData = !!this.objCtlsFilterFields.Zone
            ? this.getDistinctByValue(
              this.filterList('zone'),
              'ZoneMasterID',
              'ZoneCode',
              'value'
            )
            : [];
          this.branchData = !!this.objCtlsFilterFields.Branch
            ? this.getDistinctByValue(
              this.filterList('branch'),
              'BranchMasterID',
              'BranchCode',
              'value'
            )
            : [];
          this.salesEngData = !!this.objCtlsFilterFields.SalesEngineer
            ? this.getDistinctByValue(
              this.filterList('sales').filter((x) => {
                if (x.EngineerType == 'Sales') {
                  return x;
                }
              }),
              'EngineerName',
              'EngineerName',
              'label'
            )
            : [];
          this.serviceTechData = !!this.objCtlsFilterFields.ServiceTechnician
            ? this.getDistinctByValue(
              this.filterList('service').filter((x) => {
                if (x.EngineerType == 'Service') {
                  return x;
                }
              }),
              'EngineerName',
              'EngineerName',
              'label'
            )
            : [];

          this.touchPointData = this.formatTouchPointData(this.filterList('tp'));
          this.pouplateTreeSelection();

          this.removeFakeClass();
          //TODO commented due to loading issue.. applyfilterOnLabel() is separately call after every applyCasecadingOnList() fn.
          // setTimeout(() => {
          // this.applyfilterOnLabel();
          // }, 5);
          resolve({ Status: 'success' });
        }, 5);
    });
  }
  //TODO NEEDED TO CHECK WITH MULTPLE Fields
  filterList(fieldName): Array<any> {
    var badivfamblList = JSON.parse(
      JSON.stringify(this.filterService.dataOriginal)
    );

    var brandList = JSON.parse(
      JSON.stringify(this.filterService.brandOriginal)
    );
    //11 start
    var customerNameList = JSON.parse(
      JSON.stringify(this.filterService.customerNameOriginal)
    );
    //11 end

    var customerAccountNumberList = JSON.parse(
      JSON.stringify(this.filterService.customerAccountNumberOriginal)
    );
    var distributorsList = JSON.parse(
      JSON.stringify(this.filterService.distributorsOriginal)
    );

    var distributorSegmentList = JSON.parse(
      JSON.stringify(this.filterService.distributorSegmentOriginal)
    );

    var distributorAccountNumberList = JSON.parse(
      JSON.stringify(this.filterService.distributorAccountNumberOriginal)
    );

    var zoneBranchList = JSON.parse(
      JSON.stringify(this.filterService.zoneBranchOriginal)
    );
    var engTechList = JSON.parse(
      JSON.stringify(this.filterService.engineerAndTechnicianOriginal)
    );
    var countryContinentList = JSON.parse(
      JSON.stringify(this.filterService.countryContinentOriginal)
    );
    var tpList = JSON.parse(
      JSON.stringify(this.filterService.touchPointsOriginal)
    );

    var categoryList = JSON.parse(
      JSON.stringify(this.filterService.categoryOriginal)
    );

    var listToReturn = [];
    //
    if (
      this.filterMapping.FAMId != null &&
      this.filterMapping.FAMId.length > 0 &&
      fieldName != 'fam'
    ) {
      badivfamblList = badivfamblList.filter((ele) => {
        if (this.filterMapping.FAMId.indexOf(ele.FAMID) >= 0) {
          return ele;
        }
      });
      // enter to this fn when have selected fam and  fieldname not equal to 'fam'
      // when selection is made  on FAM, all other fields should pass through this function with dependency(division,fam,ba) and wise versa
      badivfamblList = this.filterBasedOnEligibility(
        true, //for div eligibility
        true, //for fam eligibility
        true, //for ba eligibility
        badivfamblList, // fromList
        badivfamblList //toList
      );
      categoryList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        badivfamblList,
        categoryList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        badivfamblList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerAccountNumberList
      );

      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      tpList = this.filterBasedOnEligibility(
        true,
        false,
        true,
        badivfamblList,
        tpList
      );
    }

    if (
      this.filterMapping.BAId != null &&
      this.filterMapping.BAId.length > 0 &&
      fieldName != 'ba'
    ) {
      badivfamblList = badivfamblList.filter((ele) => {
        if (this.filterMapping.BAId.indexOf(ele.BAID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        badivfamblList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        badivfamblList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        badivfamblList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      tpList = this.filterBasedOnEligibility(
        true,
        false,
        true,
        badivfamblList,
        tpList
      );
    }

    if (
      this.filterMapping.DIVId != null &&
      this.filterMapping.DIVId.length > 0 &&
      fieldName != 'div'
    ) {
      badivfamblList = badivfamblList.filter((ele) => {
        if (this.filterMapping.DIVId.indexOf(ele.DivisionID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        badivfamblList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        badivfamblList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        badivfamblList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      tpList = this.filterBasedOnEligibility(
        true,
        false,
        true,
        badivfamblList,
        tpList
      );
    }

    if (
      this.filterMapping.BLId != null &&
      this.filterMapping.BLId.length > 0 &&
      fieldName != 'bl'
    ) {
      badivfamblList = badivfamblList.filter((ele) => {
        if (this.filterMapping.BLId.indexOf(ele.BLID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        badivfamblList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        badivfamblList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        badivfamblList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      tpList = this.filterBasedOnEligibility(
        true,
        false,
        true,
        badivfamblList,
        tpList
      );
    }

    if (
      this.filterMapping.BrandId != null &&
      this.filterMapping.BrandId.length > 0 &&
      fieldName != 'brand'
    ) {
      brandList = brandList.filter((ele) => {
        if (this.filterMapping.BrandId.indexOf(ele.BrandID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        brandList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        brandList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        brandList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        brandList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        brandList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        brandList,
        countryContinentList
      );
      //11 start  for comment
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerNameList
      );
      //11 end
      // for comment
      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        brandList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      tpList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        brandList,
        tpList
      );
    }

    if (
      this.filterMapping.CountryId != null &&
      this.filterMapping.CountryId.length > 0 &&
      fieldName != 'country'
    ) {
      countryContinentList = countryContinentList.filter((ele) => {
        if (this.filterMapping.CountryId.indexOf(ele.CountryID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        countryContinentList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      //tpList = this.filterBasedOnEligibility(false,false,false,countryContinentList, tpList);
    }

    if (
      this.filterMapping.OperationalCountryId != null &&
      this.filterMapping.OperationalCountryId.length > 0 &&
      fieldName != 'operationalCountry'
    ) {
      countryContinentList = countryContinentList.filter((ele) => {
        if (this.filterMapping.OperationalCountryId.indexOf(ele.CountryID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        countryContinentList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      //tpList = this.filterBasedOnEligibility(false,false,false,countryContinentList, tpList);
    }

    if (
      this.filterMapping.ContinentId != null &&
      this.filterMapping.ContinentId.length > 0 &&
      fieldName != 'continent'
    ) {
      countryContinentList = countryContinentList.filter((ele) => {
        if (this.filterMapping.ContinentId.indexOf(ele.ContinentID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        countryContinentList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );
    }
    if (
      this.filterMapping.regionId != null &&
      this.filterMapping.regionId.length > 0 &&
      fieldName != 'region'
    ) {
      countryContinentList = countryContinentList.filter((ele) => {
        if (this.filterMapping.regionId.indexOf(ele.RegionID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        countryContinentList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        countryContinentList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerAccountNumberList
      );

      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );
    }

    if (
      this.filterMapping.ZoneId != null &&
      this.filterMapping.ZoneId.length > 0 &&
      fieldName != 'zone'
    ) {
      zoneBranchList = zoneBranchList.filter((ele) => {
        if (this.filterMapping.ZoneId.indexOf(ele.ZoneMasterID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        zoneBranchList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        zoneBranchList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        zoneBranchList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        zoneBranchList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        zoneBranchList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        zoneBranchList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerAccountNumberList
      );

      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      tpList = this.filterBasedOnEligibility(
        true,
        false,
        true,
        zoneBranchList,
        tpList
      );
    }

    if (
      this.filterMapping.BranchId != null &&
      this.filterMapping.BranchId.length > 0 &&
      fieldName != 'branch'
    ) {
      zoneBranchList = zoneBranchList.filter((ele) => {
        if (this.filterMapping.BranchId.indexOf(ele.BranchMasterID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        zoneBranchList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        zoneBranchList,
        badivfamblList
      );

      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        zoneBranchList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        true,
        true,
        true,
        zoneBranchList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        zoneBranchList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        zoneBranchList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      tpList = this.filterBasedOnEligibility(
        true,
        false,
        true,
        zoneBranchList,
        tpList
      );
    }

    if (
      this.filterMapping.ServiceTechId != null &&
      this.filterMapping.ServiceTechId.length > 0 &&
      fieldName != 'service'
    ) {
      var techList = engTechList.filter((ele) => {
        if (
          this.filterMapping.ServiceTechId.indexOf(ele.EngineerName) >= 0 &&
          ele.EngineerType == 'Service'
        ) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        techList,
        categoryList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        techList,
        engTechList
      );

      engTechList = engTechList.filter((ele) => {
        if (
          ele.EngineerType == 'Sales' ||
          (this.filterMapping.ServiceTechId.indexOf(ele.EngineerName) >= 0 &&
            ele.EngineerType == 'Service')
        ) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        engTechList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        engTechList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        engTechList,
        zoneBranchList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        engTechList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        engTechList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        engTechList,
        customerAccountNumberList
      );

      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      tpList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        engTechList,
        tpList
      );
    }

    if (
      this.filterMapping.SalesEngId != null &&
      this.filterMapping.SalesEngId.length > 0 &&
      fieldName != 'sales'
    ) {
      var engList = engTechList.filter((ele) => {
        if (
          this.filterMapping.SalesEngId.indexOf(ele.EngineerName) >= 0 &&
          ele.EngineerType == 'Sales'
        ) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        engList,
        categoryList
      );

      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        engList,
        engTechList
      );

      engTechList = engTechList.filter((ele) => {
        if (
          ele.EngineerType == 'Service' ||
          (this.filterMapping.SalesEngId.indexOf(ele.EngineerName) >= 0 &&
            ele.EngineerType == 'Sales')
        ) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        engTechList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        engTechList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        engTechList,
        zoneBranchList
      );

      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        engTechList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        engTechList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        engTechList,
        customerAccountNumberList
      );

      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      tpList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        engTechList,
        tpList
      );
    }

    if (
      this.filterMapping.TouchPointId != null &&
      this.filterMapping.TouchPointId.length > 0 &&
      fieldName != 'tp'
    ) {
      tpList = tpList.filter((ele) => {
        if (this.filterMapping.TouchPointId.indexOf(ele.TouchPointID) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        tpList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        true,
        false,
        true,
        tpList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        tpList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        true,
        false,
        true,
        tpList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        tpList,
        engTechList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        tpList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        tpList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );

      //countryContinentList = this.filterBasedOnEligibility(false, true, false, tpList, countryContinentList); // Commented by Dwarika as no dircet relation with touchpoint.
      tpList = this.filterBasedOnEligibility(true, false, true, tpList, tpList);
    }

    //11 start
    if (
      this.filterMapping.customerNameId != null &&
      this.filterMapping.customerNameId.length > 0 &&
      fieldName != 'custName'
    ) {
      customerNameList = customerNameList.filter((ele) => {
        if (this.filterMapping.customerNameId.indexOf(ele.CustomerName) >= 0) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        customerNameList,
        categoryList
      );
      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        customerNameList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        customerNameList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        customerNameList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        customerNameList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        customerNameList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        customerNameList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );
    }
    //11 end

    if (
      this.filterMapping.customerAccountNumberId != null &&
      this.filterMapping.customerAccountNumberId.length > 0 &&
      fieldName != 'custAccNum'
    ) {
      customerAccountNumberList = customerAccountNumberList.filter((ele) => {
        if (
          this.filterMapping.customerAccountNumberId.indexOf(
            ele.CustomerAccountNumber
          ) >= 0
        ) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        customerAccountNumberList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        customerAccountNumberList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        customerAccountNumberList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        customerAccountNumberList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        customerAccountNumberList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        customerAccountNumberList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        customerAccountNumberList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );
    }

    if (
      this.filterMapping.distributorsId != null &&
      this.filterMapping.distributorsId.length > 0 &&
      fieldName != 'distributors'
    ) {
      distributorsList = distributorsList.filter((ele) => {
        if (
          this.filterMapping.distributorsId.indexOf(ele.DistributorName) >= 0
        ) {
          return ele;
        }
      });
      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        distributorsList,
        categoryList
      );
      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        distributorsList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        brandList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );
    }

    if (
      this.filterMapping.distributorSegmentId != null &&
      this.filterMapping.distributorSegmentId.length > 0 &&
      fieldName != 'distributorSegment'
    ) {
      distributorSegmentList = distributorSegmentList.filter((ele) => {
        if (
          this.filterMapping.distributorSegmentId.indexOf(
            ele.DistributorSegment
          ) >= 0
        ) {
          return ele;
        }
      });

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        distributorSegmentList,
        categoryList
      );

      //needed to check
      badivfamblList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        distributorSegmentList,
        badivfamblList
      );
      brandList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        distributorSegmentList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        distributorSegmentList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        distributorSegmentList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        distributorSegmentList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        distributorSegmentList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );
    }
    //44

    if (
      this.filterMapping.distributorAccountNumberId != null &&
      this.filterMapping.distributorAccountNumberId.length > 0 &&
      fieldName != 'distributorAccountNumber'
    ) {
      distributorAccountNumberList = distributorAccountNumberList.filter(
        (ele) => {
          if (
            this.filterMapping.distributorAccountNumberId.indexOf(
              ele.AccountNumber
            ) >= 0
          ) {
            return ele;
          }
        }
      );

      categoryList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        distributorAccountNumberList,
        categoryList
      );

      badivfamblList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        distributorAccountNumberList,
        badivfamblList
      );

      brandList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        distributorAccountNumberList,
        brandList
      );
      zoneBranchList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        badivfamblList,
        zoneBranchList
      );
      engTechList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        distributorAccountNumberList,
        engTechList
      );
      countryContinentList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        distributorAccountNumberList,
        countryContinentList
      );
      //11 start
      customerNameList = this.filterBasedOnEligibility(
        false,
        false,
        false,
        distributorAccountNumberList,
        customerNameList
      );
      //11 end

      customerAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        false,
        distributorAccountNumberList,
        customerAccountNumberList
      );
      distributorsList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorsList
      );

      distributorSegmentList = this.filterBasedOnEligibility(
        false,
        false,
        true,
        badivfamblList,
        distributorSegmentList
      );

      distributorAccountNumberList = this.filterBasedOnEligibility(
        false,
        true,
        true,
        badivfamblList,
        distributorAccountNumberList
      );


    }
    //55

    // if (
    //   this.filterMapping.CategoryId != null &&
    //   this.filterMapping.CategoryId.length > 0 &&
    //   fieldName != 'category'
    // ) {
    //   categoryList = categoryList.filter(
    //     (ele) => {
    //       if (
    //         this.filterMapping.CategoryId.indexOf(
    //           ele.CTLSCategoryID
    //         ) >= 0
    //       ) {
    //         return ele;
    //       }
    //     }
    //   );

    //   badivfamblList = this.filterBasedOnEligibility(
    //     false,
    //     true,
    //     true,
    //     categoryList,
    //     badivfamblList
    //   );

    //   brandList = this.filterBasedOnEligibility(
    //     false,
    //     true,
    //     true,
    //     categoryList,
    //     brandList
    //   );
    //   zoneBranchList = this.filterBasedOnEligibility(
    //     false,
    //     true,
    //     false,
    //     badivfamblList,
    //     zoneBranchList
    //   );
    //   engTechList = this.filterBasedOnEligibility(
    //     false,
    //     true,
    //     false,
    //     categoryList,
    //     engTechList
    //   );
    //   countryContinentList = this.filterBasedOnEligibility(
    //     false,
    //     true,
    //     false,
    //     categoryList,
    //     countryContinentList
    //   );
    //   //11 start
    //   customerNameList = this.filterBasedOnEligibility(
    //     false,
    //     false,
    //     false,
    //     categoryList,
    //     customerNameList
    //   );
    //   //11 end

    //   customerAccountNumberList = this.filterBasedOnEligibility(
    //     false,
    //     true,
    //     false,
    //     categoryList,
    //     customerAccountNumberList
    //   );
    //   distributorsList = this.filterBasedOnEligibility(
    //     false,
    //     true,
    //     true,
    //     badivfamblList,
    //     distributorsList
    //   );

    //   distributorSegmentList = this.filterBasedOnEligibility(
    //     false,
    //     false,
    //     true,
    //     badivfamblList,
    //     distributorSegmentList
    //   );

    //   distributorAccountNumberList = this.filterBasedOnEligibility(
    //     false,
    //     true,
    //     true,
    //     badivfamblList,
    //     distributorAccountNumberList
    //   );
    //   categoryList = this.filterBasedOnEligibility(
    //     false,
    //     false,
    //     false,
    //     badivfamblList,
    //     categoryList
    //   );
    // }

    //11 needed to update here
    if (
      fieldName == 'fam' ||
      fieldName == 'ba' ||
      fieldName == 'div' ||
      fieldName == 'bl'
    ) {
      listToReturn = badivfamblList;
    } else if (fieldName == 'branch' || fieldName == 'zone') {
      listToReturn = zoneBranchList;
    } else if (
      fieldName == 'country' ||
      fieldName == 'continent' ||
      fieldName == 'region' ||
      fieldName == 'operationalCountry'
    ) {
      listToReturn = countryContinentList;
    } else if (fieldName == 'sales' || fieldName == 'service') {
      listToReturn = engTechList;
    } else if (fieldName == 'brand') {
      listToReturn = brandList;
    } else if (fieldName == 'tp') {
      listToReturn = tpList;
    } else if (fieldName == 'custName') {
      //11 start
      listToReturn = customerNameList; //11 end
    } else if (fieldName == 'custAccNum') {
      listToReturn = customerAccountNumberList;
    } else if (fieldName == 'distributors') {
      if(this.selectedRadio == 'Distributor'){
        listToReturn= distributorsList.filter(data=>data.EntityType=='DIST')
      } else if(this.selectedRadio == 'OEM'){
        listToReturn = distributorsList.filter(data=>data.EntityType=='OEM')
      } else {
        listToReturn = distributorsList;
      }
      // listToReturn = distributorsList;
    } else if (fieldName == 'distributorSegment') {
      listToReturn = distributorSegmentList;
    } else if (fieldName == 'distributorAccountNumber') {
      listToReturn = distributorAccountNumberList;
    } else if (fieldName == 'category') {
      listToReturn = categoryList;
    }
    return listToReturn;
  }

  filterBasedOnEligibility(
    divEligible,
    famEligible,
    baEligible,
    fromList,
    toList
  ): Array<any> {
    if (divEligible) {
      var divs = [];
      for (var i = 0; i < fromList.length; i++) {
        divs.push(fromList[i].DivisionID);
      } //.map(ele => { return ele.DivisionID });
      toList = this.filterByDIV(toList, divs);
    }
    if (famEligible) {
      //var fams = fromList.map(ele => { return ele.FAMID });
      var fams = [];
      for (var i = 0; i < fromList.length; i++) {
        fams.push(fromList[i].FAMID);
      }
      toList = this.filterByFAM(toList, fams);
    }
    if (baEligible) {
      //var bas = fromList.map(ele => { return ele.BAID });
      var bas = [];
      for (var i = 0; i < fromList.length; i++) {
        bas.push(fromList[i].BAID);
      }
      toList = this.filterByBA(toList, bas);
    }

    return toList;
  }

  closeFilterPanel() {
    this.filterToggle = false;
    this.filterDateToggle = false;
    this.successMsg = false;
    this.msgs = [];
    this.StartDate = this.StartDate;
    this.EndDate = this.EndDate;
  }

  @ViewChild('scrollPanel') private myScrollContainer;
  // @ViewChild('scroll') scroll
  hideScroll: boolean = false;
  scrollPos(event) {
    this.hideScroll = true;
    //this.myScrollContainer.contentViewChild.nativeElement.scrollTop+=200
  }

  GlobalDateChanged(flag = true) {
    this.filterService.GlobalDateChanged = flag;
  }

  /* 360 dashboard account search */
  accountSearch: any;

  clearError(event) {
    if (!this.accountSearch.trim().length) {
      this.invalidText = false;
    }
  }
  setClearError() {
    this.accountSearch = "";
    this.invalidText = false;
  }
  onEnterAccountSearch(event: any) {
    let filterObject = {
      AccountSearch: event.query
    };
    this.eventFromCtlsFilterHeader.emit({
      operation: 'FILTER_SEARCH',
      data: filterObject,
    });
    localStorage.setItem('onSearch', 'true');
    localStorage.removeItem('backButton');
    localStorage.removeItem('filterClicked');
  }
  account: any;
  onDropDownClick(event) {

    let tempObj = {
      AccountNumber: "",
      Name: "",
      EntityId: "",
      GLobalEntityId: null,
      GlobalEntityName: null,
      StandaradEntityName: null,
      StandardEntityId: null,
      Type: "",
      CustomerName: "",
      Criteria: ""
    };
    tempObj = { ...tempObj, ...event };
    tempObj.Name = event.CustomerName;
    delete tempObj.CustomerName;
    /* 
    if (event.Type == 'COMPANY') {
      tempObj={...tempObj,...event};
      tempObj.Name = event.CustomerName;
      delete tempObj.CustomerName;
    }
    if (event.Type == 'STANDARD') {
      tempObj={...tempObj,...event};
      tempObj.Name = event.CustomerName;
      delete tempObj.CustomerName;
    }
    if (event.Type == 'GLOBAL') {
      tempObj={...tempObj,...event};
      tempObj.Name = event.CustomerName;
      delete tempObj.CustomerName;
    } */
    this.accountSearch = tempObj['CommonSearchName'];
    this.eventFromCtlsFilterHeader.emit({
      operation: 'FILTER_SEARCH_SELECTED',
      data: tempObj,
    });
  }

  defaultDateRange(dateToAddMonths: Date | string, monthToADd: number): Date {
    const dateToTransform = new Date(dateToAddMonths);
    const n = dateToTransform.getDate();
    dateToTransform.setDate(1);
    dateToTransform.setMonth(dateToTransform.getMonth() + monthToADd);
    dateToTransform.setDate(Math.min(n, this.getDaysofMonth(dateToTransform.getFullYear(), dateToTransform.getMonth())))
    return dateToTransform;
  }

  getDaysofMonth(yr, mnt) {
    return [31, (this.isLeapYear(yr) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][mnt];
  }
  isLeapYear(yr) {
    return (((yr % 4 === 0) && (yr % 100 !== 0)) || (yr % 400 === 0));
  }
  removeTitle(){
    let elemList = Array.from(document.querySelectorAll('.ui-multiselect-label-container'));   
    elemList.forEach((elem)=>{
      elem.setAttribute('title', '');
    });    
  }
}
