import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as html2canvas from "html2canvas"
import { ExportChartModel } from 'src/app/shared/models/export-chart.model';
import HC_drilldown from 'highcharts/modules/drilldown';
import { style } from '@angular/animations';
HC_drilldown(Highcharts);
import { ChartDataService } from '../service/chart-data-service.service';

const WIDGET_CODE: string = 'rr';

const yaxisValue = 500;

@Component({
  selector: 'app-response-rate-donut-chart',
  templateUrl: './response-rate-donut-chart.component.html',
  styleUrls: ['./response-rate-donut-chart.component.css'],
  // tslint:disable-next-line:use-input-property-decorator
  inputs: [
    // 'height',
    'width',
    'margin',
    'middleText',
    'middleText2',
    'middleText3',
    'data',
    'colors',
    'CompletedCountData',
    'NoResponseCountData'
  ]
})
export class ResponseRateDonutChartComponent implements OnInit {
  public CompletedCountData: any;
  public NoResponseCountData: any;
  public data: any;
  public colors: any;
  public highcharts: typeof Highcharts = Highcharts;
  public middleText;
  public middleText2;
  public middleText3;

  // public highcharts: any;
  public drilldownFlag = false;
  public chartConstructor: string;
  public chartOptions: any;
  public updateFlag = false; // optional boolean
  public oneToOneFlag = true; // optional boolean, defaults to false
  public runOutsideAngular = false; // optional boolean, defaults to false

  public dummyChartOptions: Highcharts.Options;
  public showDummyChart: boolean = false;

  chartInstance: any;
  eventvalue: string;
  showGrid = false;
  private lastHoveredOn: string;

  constructor(private sharedService: SharedService,
    private chartDataService: ChartDataService) {
  }

  ngOnInit() {
    this.chartDataService.drilldownSubject.subscribe(res => {
      this.showGrid = res.showGrid;
    });
  }
  ngOnChanges() {
    this.drawHighChart();
  }

  logChartInstance(chart: Highcharts.Chart) {
    this.chartInstance = chart;
    //this.chartInstance.drillUpButton = this.chartInstance.drillUpButton.destroy();  
  }
  hideTooltipVisibility() {
    this.chartInstance.tooltip.options.hideDelay = 0;
    this.chartInstance.tooltip.hide();
  }
  resetTooltipVisibility() {
    this.chartInstance.tooltip.options.hideDelay = 99999999999999999;
  }
  drillupEvent() {
    this.drilldownFlag = false;
    if (this.chartInstance.drilldownLevels && this.chartInstance.drilldownLevels.length > 0) {
      this.chartInstance.tooltip.options.hideDelay = 0;
      this.chartInstance.tooltip.hide();
      this.chartInstance.drillUp();
    }
  }
  drilldownEvent(arg: any, event: any) {
    if (arg === 'Completed_CC' || arg === 'NPS only' || arg === 'Feedback not submitted' ||
    arg && (arg.includes('Processed') || arg.includes('Delivered') || arg.includes('Clicked') || arg.includes('Opened')) ||
      arg === 'Bounce Back' || arg === 'RTI') {
      this.chartDataService.drilldownSubject.next({
        showGrid: true,
        status: arg,
        offsetX: event.originalEvent.offsetX + 30,
        offsetY: event.originalEvent.offsetY
      });
      this.showGrid = true;
      this.hideTooltipVisibility();
    } else {
      // this.chartInstance.tooltip.options.hideDelay = 0;
      this.drilldownFlag = true;
      this.chartDataService.drilldownSubject.next({
        showGrid: false,
        status: null,
        offsetX: event.originalEvent.offsetX + 30,
        offsetY: event.originalEvent.offsetY
      });
      this.showGrid = false;
    }
  }
  drawHighChart() {
    let blkstr;
    let componentContext = this;
    // let chartData = [];
    let copmletechartArray: any[] = [], subdispositionArray: any[] = [], labels: any[] = [], tooltipArray: any[] = [],
      subdispositionvalue = {}, dataset: any[] = [], subdispositionNoResponseArr = [], subdispositionBounceBackValue = {};
    this.highcharts = Highcharts; // required
    this.chartConstructor = 'chart'; // optional string, defaults to 'chart'

    //Drilldown data
    for (const keys of Object.keys(this.data)) {
      const element = this.data[keys];
      copmletechartArray.push({
        name: keys,
        y: element,
        drilldown: (keys == "Completed" || keys == "Bounce Back" || keys == "No Response" ||
          keys == "RTI") ? keys : undefined
      });
    }

    //separating labels and store in subdisposition
    subdispositionArray = this.constructDispositionArray();
    subdispositionNoResponseArr = this.constructDispositionArray('NoResponseCountData');

    this.chartOptions = {
      exporting: {
        chartOptions: { // specific options for the exported image
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return this.point.name + ": " + this.point.y + "%";
                },
                allowOverlap: false,
                style: {
                  textShadow: false,
                  textOutline: 'false',
                  color: '#000000'
                }
              }
            }
          }
          , title: {
            text: this.middleText,
            style: {
              fontSize: '4em',
              fontWeight: '900',
              fontFamily: 'Open Sans, sans-serif',
              color: '#666666'
            },
          }
        }
      },
      navigation: {
        buttonOptions: {
          enabled: false,
          y: 40
        }
      },
      legend: {
        width: 300,
        floating: true,
        align: 'left',
        x: -20,
        y: -20,
        itemWidth: 127,
        itemDistance: -100,
        borderWidth: 0,
        margin: 100, 
        enabled: true,

        useHTML: true,
        labelFormatter: function () {
          var tooltip;
          switch (this.name) {
            case 'Completed':
              tooltip = 'Completed';
              break;
            case 'RTI':
              tooltip = 'Reply to Invitation';
              break;
           case 'No Response':
              tooltip = 'No Response';
              break;
            case 'Bounce Back':
              tooltip = 'Bounce Back';
              break;
          }

          if(this.name=="Completed" || this.name=="RTI" || this.name=="No Response" || this.name=="Bounce Back") {
            return '<span data-toggle="tooltip" class="donut-legend" title="' + tooltip + '">' + this.name + '</span>';
          } else {
            return '<span data-toggle="tooltip" class="donut-legend" title="' + this.name + '">' + this.name + '</span>';
          }
          
        }
        
      },
      credits: {
        enabled: false
      },
      lang: {
        drillUpText: ''
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        type: 'pie',
        events: {
          drillup: function (e) {
          },
          // click: function (e) {
          //   console.log(e)
          //   console.log(e.target.innerHTML);
          //   console.log(e.target.className.baseVal);
          //    if ((e.y > yaxisValue) ||( e.target.innerHTML!="") || (e.target.className.baseVal=="highcharts-point")) {                    
          //     return;
          //   }
          //   else {              
          //     this.drilldownFlag = false;
          //     componentContext.drillupEvent();
          //   }
          // },
          click: function (e) {
            if ((e.target.innerHTML != "") && e.target.parentNode.className.baseVal === 'highcharts-title') {
              componentContext.chartDataService.drilldownSubject.next({
                showGrid: true,
                status: null,
                offsetX: e.offsetX + 70,
                offsetY: e.offsetY
              });
              this.showGrid = true;
            } else if ((e.target.innerHTML != "") || (e.target.className.baseVal == "highcharts-point")) {
              if (e.y > yaxisValue) {
                return
              }
              componentContext.chartDataService.drilldownSubject.next({
                showGrid: false,
                status: null
              });
              this.showGrid = false;
            } else {
              componentContext.chartDataService.drilldownSubject.next({
                showGrid: false,
                status: null
              });
              this.showGrid = false;
              this.drilldownFlag = false;
              componentContext.resetTooltipVisibility();
              componentContext.drillupEvent();
            }
          },
          drilldown: function (e) {
            this.drilldownFlag = true;
            // componentContext.resetTooltipVisibility();
            // componentContext.hideTooltipVisibility();
            componentContext.drilldownEvent(e.point.drilldown, e);

          }
        }
      },
      title: {
        text:
          '<span style="font-size:2.63em;font-weight:900;font-family:Open Sans, sans-serif;color:#666666">' +
          this.middleText +
          '<span>' +
          '<span style="font-size:1.50em;font-family:Source Sans Pro Regular; color:#666666">%<span><br>' +
          "<span style='font-size:0.94em;font-family:Source Sans Pro Regular;color:#666666'>Response Rate<span>",
        align: 'center',
        verticalAlign: 'middle',
        /*  y: -25 */ //commented out as part of highmap version upgrade issue
      },
      tooltip: {
        // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        // pointFormat: '<b>{point.y}%</b>',
        useHTML: true,
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        hideDelay: 99999999999999999,
        formatter: function () {
          if (!componentContext.showGrid) {
            let key = [this.key];
            if (this.key && this.key.includes('Delivered') || this.key.includes('Opened') ||
            this.key.includes('Clicked') || this.key.includes('Processed')) {
              key = this.key.split('-');
            }
          return '<div style="padding: 5px 8px !important; border-radius: 3px !important;color: white !important;background-color:' + this.color + '" class="high-chart-tooltip"> ' +
          Math.round(this.y) + '%<br>' + key[0] +
            '</div>';
          } else {
            return false;
          }
        },
        shared: true,
        enabled: true
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
            distance: -20,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: 0,
          endAngle: 0,
          center: ['50%', '40%'],
          size: '90%',
          showInLegend: true
          , point: {
            events: {
              legendItemClick: (a) => {
                if (a.target.name !== componentContext.lastHoveredOn)
                  return;
                componentContext.hideTooltipVisibility();
              },
              mouseOut: ($eventArg) => {
                let _this = this;
                componentContext.lastHoveredOn = $eventArg.target.name;
              },
              mouseOver: ($eventArg) => {
                componentContext.resetTooltipVisibility();
              },
            },

          }
        },
        series: {
          states: {
            hover: {
              enabled: true,
              halo: {
                size: 5
              }
            }
            , events: {
              legendItemClick: () => {
                console.log('legend click');
              }
              , hide: () => {
                console.log('legend hidden');
              }
            }
          }
        }
      },
      series: [{
        type: 'pie',
        innerSize: '70%',
        name: "Response Rate",
        colorByPoint: true,
        showInLegend: true,
        data: copmletechartArray
      }],
      colors: this.colors,
      drilldown: {
        drillUpButton: {
          theme: {
            display: 'none'
          }

        },
        series: [{
          //cursor:'pointer',
          innerSize: '70%',
          name: "Completed",
          id: "Completed",
          showInLegend: true,
          data: subdispositionArray,
          colors: [
            "#51acdc",
            "#70bae2",
            "#99ceea"
          ]
        },
        {
          innerSize: '70%',
          name: "No Response",
          id: "No Response",
          showInLegend: true,
          data: subdispositionNoResponseArr,
          colors: [
            "#339dd6",
            "#337195",
            "#999999",
            "#717171"
          ]
        }
        ]
      }
    };
    // this.prepareDummyChart();
  }

  private constructDispositionArray(selectedLegendData = 'CompletedCountData') {
    let labels = [], dataset = [], subdispositionvalue = {}, subdispositionArray = [], countData: any = {};
    countData = selectedLegendData === 'CompletedCountData' ? this.CompletedCountData : this.NoResponseCountData;
    for (let i = 0; i < countData.labels.length; i++) {
      const element = countData.labels[i];
      labels.push(element);
    }
    //separating data for labels and store in subdisposition
    dataset = countData["datasets"];
    for (let i = 0; i < dataset.length; i++) {
      const element = dataset[i];
      for (let i = 0; i < element[selectedLegendData].length; i++) {
        subdispositionvalue[labels[i]] = element[selectedLegendData][i];
      }
    }
    // assemble subdisposition key-values
    for (const keys of Object.keys(subdispositionvalue)) {
      const element = subdispositionvalue[keys];
      if (selectedLegendData === 'CompletedCountData') {
        subdispositionArray.push({
          name: keys,
          drilldown: (keys == "Completed") ? keys + '_CC' : keys,
          y: subdispositionvalue[keys]
        });
      } else {
        subdispositionArray.push({
          name: keys,
          drilldown: keys,
          y: subdispositionvalue[keys]
        });
      }
    }
    return subdispositionArray;
  }

  // private prepareDummyChart = () => {
  //   this.dummyChartOptions = Object.assign({}, this.chartOptions);
  //   this.dummyChartOptions.title = {
  //     text:
  //       '<span style="font-size:100em;font-weight:900;font-family:Open Sans, sans-serif;color:#666666">' +
  //       this.middleText
  //       + '<span>' +
  //       // + '<span>' 
  //       // '<span style="font-size:57em;font-family:Source Sans Pro Regular; color:#666666">%<span><br>' +
  //       '<span style="font-size:57em;font-family:Source Sans Pro Regular; color:#666666">%<span><br>'
  //     // "<span style='font-size:34em;font-family:Source Sans Pro Regular;color:#666666'>Response Rate<span>"
  //     , align: 'center',
  //     verticalAlign: 'middle'
  //     , y: 30
  //     , x: -20
  //     , style: {
  //       fontSize: '4em',
  //       fontWeight: '900',
  //       fontFamily: 'Open Sans, sans-serif',
  //       color: '#666666'
  //     }
  //   };
  //   this.dummyChartOptions.plotOptions = {
  //     series: {
  //       dataLabels: {
  //         enabled: true
  //         , style: {
  //           fontWeight: 'bold',
  //           color: 'black'
  //         }
  //       }
  //     },
  //     pie: {
  //       dataLabels: {
  //         enabled: true,
  //         // format: '{point.percentage} %',
  //         formatter: function () {
  //           // display only if larger than 1
  //           // return this.y > 5 ? '<b>' + this.point.name + ':</b> ' +
  //           //   this.y + '%' : null;
  //           return this.point.name + ": " + this.point.y + "%";
  //         },
  //         allowOverlap: false,
  //         style: {
  //           textShadow: false,
  //           textOutline: 'false',
  //           color: '#000000'
  //         }
  //       }
  //     }
  //   }
  // }

  chartCallback(chart) { } // optional function, defaults to null
}
