import { environment } from 'src/environments/environment';

export default {
  jsonData: [
    {
      topheader: 'Dashboard',
      icon: 'fa-tachometer',
      submenuData: [
        {
          pvkey: 'VIEW_DASHBOARD',
          headerText: 'Dashboard',
          submenuImageurl: environment.imagepath+ 'Mega_Menu_Icon_Dashboard.svg',
          submenuDesc:
            'Monitor live customer feedback 24/7, and get key business insights to cater to the evolving customer demands.'
        },
        {
          pvkey: 'VIEW_DASHBOARD',
          LandingPageCode: 'TD',
          headerText: 'Transactional Surveys',
          submenuImageurl: environment.imagepath + 'Mega_Menu_Icon_Transactional_Survey.svg',
          routerLink: '/dashboard/home',
          submenuDesc:
            'Based on every job performed, monitor customer\'s opinion and have a quick analysis of our strengths and areas of improvements. Gain business insights to take required actions and ensure customer loyalty.'
        },
        {
          pvkey: 'VIEW_FILE_TRACKING_DASHBOARD',
          LandingPageCode: 'FTD',
          headerText: 'File Tracking',
          submenuImageurl: environment.imagepath+ 'Mega_Menu_Icon_File_Tracking.svg',
          routerLink: '/dashboard/file-tracking',
          submenuDesc:
            'Track event files received and identify records considered for survey roll out, including errors and actionable warnings. Upload files manually for processing surveys (if not automated).'
        },
        {
          pvkey: 'VIEW_DATA_ERROR_TRACKING',
          LandingPageCode: 'DTD',
          headerText: 'Data and Error Tracking',
          submenuImageurl: environment.imagepath + 'Mega_Menu_Icon_Error_Tracking.svg',
          routerLink: '/dashboard/data-tracking',
          submenuDesc:
            'Manage and resolve data errors & address actionable warnings for reporting purposes. It also identifies the status of all records that have reached Falcon.'
        },
        {
          /**
           * Added By Meherban for Dashboard Distributor Munu - On HOlD for text And image
           */
          pvkey: 'VIEW_DASHBOARD_DISTRIBUTOR',
          LandingPageCode: 'DD',
          headerText: 'Relationship Surveys',
          submenuImageurl: environment.imagepath+ 'Megamenu_icon_Relationship.svg',
          routerLink: '/Distributor_Survey/dashboard/distributor',
          submenuDesc:
            'Have an overview of the overall relationship we have created with our stakeholders. Relationship survey is based on emotions and expectations that needs to be managed.'
        },
        {
          //TODO change pvkey to VIEW_CTLS_INSIGHTS  to VIEW_CTLS_DASHBOARD
          pvkey: 'VIEW_DASHBOARD_DISTRIBUTOR',
          LandingPageCode: 'CTL',
          headerText: 'Close the Loop',
          submenuImageurl: environment.imagepath+ 'close-the-loop-ico.png',
          routerLink: '/ctls/dashboard',
          submenuDesc:
            "Get an insight on the various task statuses including breaches. Also, view the trend analysis of the tasks that are executed. "
        }
      ]
    },
    {
      topheader: 'FALCON SETUP',
      icon: 'fa-sitemap',
      submenuData: [
        {
          menuList: [
            {
              menuHeader: 'Survey Library',
              menuList: [
                {
                  pvkey: 'VIEW_BRAND',
                  headerText: '',
                  submenuImageIcon: 'fa-heart',
                  routerLink: '/fam-management/brand-management',
                  headerMenuName: 'Brand Master',
                  submenuDesc:
                    'Create and View list of brands in the organization.'
                },
                {
                  pvkey: 'VIEW_GAC_CODES',
                  headerText: '',
                  submenuImageIcon: 'fa-money-bill-wave',
                  routerLink: '/falcon-setup/show-gac-list',
                  headerMenuName: 'GAC / ORG Master',
                  submenuDesc: 'View & Manage GAC codes'
                }
              ]
            }
          ]
        },
        {
          menuList: [
            {
              menuHeader: 'FAM Management',
              menuList: [
                {
                  pvkey: 'VIEW_FAM_MASTER',
                  headerText: 'FAM Management',
                  submenuImageIcon: 'fa-building',
                  routerLink: '/fam-management/fam-master',
                  headerMenuName: 'FAM Master',
                  submenuDesc:
                    'Create and Manage FAMs and also get a quick glance of the summary of a FAM.'
                },
                {
                  pvkey: 'VIEW_FAM_OUTBOUND',
                  headerText: 'FAM Management',
                  submenuImageIcon: 'fa-indent',
                  routerLink: '/fam-management/outbound-trigger',
                  headerMenuName: 'Outbound Trigger',
                  submenuDesc:
                    'Create and Manage outbound triggers per BA & FAM'
                }
              ]
            }
          ]
        },
        {
          menuList: [
            {
              menuHeader: 'Customer Management',
              menuList: [
                {
                  pvkey: 'VIEW_CUSTMASTER',
                  headerText: 'CM',
                  submenuImageIcon: 'fa-user-chart',
                  routerLink: '/customer-master/customer-master',
                  headerMenuName: 'Customer Master',
                  submenuDesc:
                    'View, Create and Manage customer companies and contacts and also exclude them for surveys.'
                },
              ]
            },
            {
              menuHeader: 'Company Management',
              menuList: [
                {
                  pvkey: 'VIEW_DISTMASTER',
                  headerText: 'DM',
                  submenuImageurl: environment.imagepath+ 'user-book.svg',
                  routerLink: '/distributor-master/distributor-master',
                  headerMenuName: 'Company Master',
                  submenuDesc:
                    'View, Create and Manage companies and contacts for Distributors & OEMs and mark them for surveys.'
                },
              ]
            },

          ]
        },
        {
          menuList: [
            {
              menuHeader: 'Revenue Management',
              menuList: [
                {
                  pvkey: 'VIEW_CURRENCY_MASTER',
                  headerText: 'Currency Master',
                  submenuImageIcon: 'fal fa-exchange',
                  routerLink: '/currency-master/currency-master',
                  headerMenuName: 'Currency Master',
                  submenuDesc:
                    'View & manage monthly currency exchange rates'
                },
                {
                  pvkey: 'VIEW_REVENUE_REPORT',
                  headerText: 'Revenue Report',
                  submenuImageIcon: 'fal fa-coins',
                  routerLink: '/revenue-report/revenue-report',
                  headerMenuName: 'Revenue Report',
                  submenuDesc:
                    'View & track YoY revenue of Distributors, OEMs and Customers'
                },
              ]

            },
          ]
        },
        {
          pvkey: 'VIEW_USER_MGMT',
          headerText: 'User Role Management',
          submenuImageIcon: 'fa-users-cog',
          routerLink: '/falcon-setup/user-management',
          headerMenuName: 'Manage Users',
          submenuDesc:
            'Create and Manage Users along with their roles and access.'
        }
      ]
    },
    {
      topheader: 'SURVEY SETUP',
      icon: 'fa-user-cog',
      submenuData: [
        {
          menuList: [
            {
              pvkey: 'VIEW_QUESTIONS',
              menuHeader: 'Question Bank',
              headerText: 'Question Bank',
              submenuImageIcon: 'fa-question-square',
              routerLink: '/surveys/manage-question',
              headerMenuName: 'Manage Questions',
              submenuDesc: 'Create and View questions in Falcon.'
            },
            {
              pvkey: 'VIEW_SURVEY_TEMPLATES',
              menuHeader: 'Survey Templates',
              headerText: 'Survey Templates',
              submenuImageIcon: 'fa-poll-people',
              routerLink: '/surveys/manage-survey-template',
              headerMenuName: 'Manage Survey Templates',
              submenuDesc:
                'Create survey templates by grouping the required set of questions or make any modifications to existing templates.'
            },
            {
              menuHeader: 'Transactional Survey Library',
              menuList: [
                {
                  pvkey: 'VIEW_TRAN_SURVEY_LIBRARY',
                  headerText: 'Survey Library',
                  submenuImageIcon: 'fa-books',
                  routerLink: '/surveys/survey-library',
                  headerMenuName: 'Manage Survey',
                  submenuDesc:
                    'Create and Manage surveys that are mapped to business. Also provides information on the readiness of the survey roll out.'
                },
                {
                  pvkey: 'PREVIEW_TRANS_SURVEY',
                  menuHeader: 'Survey Library',
                  headerText: 'Survey Library',
                  submenuImageIcon: 'fa-tasks',
                  routerLink: '/surveys/test-survey',
                  headerMenuName: 'Test Survey',
                  submenuDesc:
                    'Preview of  the survey invitation and questionnaire.'
                }
              ]
            }
          ]
        },
        {
          menuList: [
            {
              menuHeader: 'Review Translations',
              menuList: [
                {
                  pvkey: 'REVIEW_LINGUIST_TRANSLATION',
                  menuHeader: 'Review Translations',
                  headerText: 'Review Translations',
                  submenuImageIcon: 'fa-ballot-check',
                  routerLink: '/surveys/surveyTemplates',
                  headerMenuName: 'Survey Template',
                  submenuDesc:
                    'Review and confirm translations of survey templates.'
                },
                {
                  pvkey: 'REVIEW_SIGN_LINGUIST_TRANSLATION',
                  menuHeader: 'Review Translations',
                  headerText: 'Survey Templates',
                  submenuImageIcon: 'fa-file-signature',
                  routerLink: '/surveys/survey/signature',
                  headerMenuName: 'Signature',
                  submenuDesc:
                    'Review and confirm translations of signatures that will be part of the survey invitation.'
                }
              ]
            },
            {
              menuHeader: 'Relationship Survey Library',
              menuList: [
                {
                  pvkey: 'VIEW_REL_SURVEYS',
                  menuHeader: 'Relationship Survey Library',
                  headerText: 'Relationship Survey Library',
                  submenuImageIcon: 'fa-pen-nib',
                  routerLink: '/relationship-survey/relationship-survey',
                  headerMenuName: 'Manage Survey',
                  submenuDesc:
                    'To create and manage relationship surveys that are used to understand the health of the relationship with any stakeholder.'
                }
                , {
                  pvkey: 'TEST_REL_SURVEYS',
                  menuHeader: 'Relationship Survey Library',
                  headerText: 'Relationship Survey Library',
                  submenuImageIcon: 'fa-tasks',
                  routerLink: '/relationship-survey/test-relationship-survey',
                  headerMenuName: 'Test Survey',
                  submenuDesc:
                    'Preview of the survey invitation and questionnaire.'
                }
              ]
            }
          ]
        },
        {
          menuList: [
            {
              menuHeader: 'Survey Scheduler and Controller',
              menuList: [
                {
                  pvkey: 'VIEW_REMINDER_BUSINESSRULE',
                  menuHeader: 'Survey Scheduler and Controller',
                  headerText: 'Survey Scheduler and Controller',
                  submenuImageIcon: 'fa-calendar-alt',
                  routerLink: '/surveys/survey-schedularandreminder',
                  headerMenuName: 'Reminders and Expiry Settings',
                  submenuDesc:
                    'Set the mode and number of days after which the automated reminders need to be sent. Set the period after which the survey will expire.'
                },
                {
                  pvkey: 'VIEW_SURVEY_TIMINGS',
                  menuHeader: 'Survey Scheduler and Controller',
                  headerText: 'Survey Scheduler and Controller',
                  submenuImageIcon: 'fa-stopwatch',
                  routerLink: '/surveys/survey-timings',
                  headerMenuName: 'Survey Timings',
                  submenuDesc:
                    'Set up and manage the time zones, opening and closing time of surveys, including the days of the week on which surveys will be sent out and exclusion dates at a country level.'
                }
              ]
            }
          ]
        },
        {
          menuList: [
            {
              menuHeader: 'Business Rules and Exceptions',
              menuList: [
                {
                  pvkey: 'VIEW_NO_SEND_RULE',
                  headerText: 'Business Rules and Exceptions',
                  submenuImageIcon: 'fa-do-not-enter',
                  routerLink: '/fam-management/nosend-business-rule',
                  headerMenuName: 'No Send List',
                  submenuDesc:
                    'Add and view list of email addresses of customers who have instructed not to send any surveys to them.'
                },
                {
                  pvkey: 'VIEW_RESTRICTED_DOMAIN',
                  headerText: 'Survey Scheduler and Controller',
                  submenuImageIcon: 'fa-times-octagon',
                  routerLink: '/fam-management/dr-business-rule',
                  headerMenuName: 'Domain Restriction List',
                  submenuDesc:
                    'Add and view list of internal domains to which surveys should not be sent out. Exclusions can also be created here.'
                }
              ]
            }
          ]
        },
        // {
        //   menuList: [
        //     {
        //       menuHeader: 'Master Data',
        //       menuType: 'Region',
        //       menuList: [
        //         {
        //           pvkey: 'MASTER_DATA_REGION',
        //           menuHeader: 'Master Data',
        //           headerText: 'Master Data',
        //           submenuImageIcon: 'fa-map-marker-alt',
        //           routerLink: '/master-data/region',
        //           headerMenuName: 'Region',
        //         },
        //         {
        //           pvkey: 'MASTER_DATA_COUNTRY',
        //           menuHeader: 'Master Data',
        //           headerText: 'Master Data',
        //           submenuImageIcon: 'fa-flag-alt',
        //           routerLink: '/master-data/country',
        //           headerMenuName: 'Country',
        //         },
        //         {
        //           pvkey: 'MASTER_DATA_TIMEZONE',
        //           menuHeader: 'Master Data',
        //           headerText: 'Master Data',
        //           submenuImageIcon: 'fa-clock',
        //           routerLink: '/master-data/timezone',
        //           headerMenuName: 'Timezone',
        //         },
        //       ]
        //     }
        //   ]
        // },
        // {
        //   menuList: [
        //     {
        //       menuHeader: 'Master Data',
        //       menuType: 'Business',
        //       menuList: [
        //         {
        //           pvkey: 'MASTER_DATA_BA',
        //           menuHeader: 'Master Data',
        //           headerText: 'Master Data',
        //           submenuImageIcon: 'fa-briefcase',
        //           routerLink: '/master-data/business-area',
        //           headerMenuName: 'Business Area',
        //         },
        //         {
        //           pvkey: 'MASTER_DATA_DIVISION',
        //           menuHeader: 'Master Data',
        //           headerText: 'Master Data',
        //           submenuImageIcon: 'fa-boxes-alt',
        //           routerLink: '/master-data/division',
        //           headerMenuName: 'Division',
        //         },
        //         {
        //           pvkey: 'MASTER_DATA_BL',
        //           menuHeader: 'Master Data',
        //           headerText: 'Master Data',
        //           submenuImageIcon: 'fa-sliders-h',
        //           routerLink: '/master-data/business-line',
        //           headerMenuName: 'Business Line',
        //         },
        //         {
        //           pvkey: 'MASTER_DATA_TOUCHPOINT',
        //           menuHeader: 'Master Data',
        //           headerText: 'Master Data',
        //           submenuImageIcon: 'fa-tools',
        //           routerLink: '/master-data/touchpoint',
        //           headerMenuName: 'TouchPoint',
        //         },
        //         {
        //           pvkey: 'MASTER_DATA_SERVICE_TYPE',
        //           menuHeader: 'Master Data',
        //           headerText: 'Master Data',
        //           submenuImageIcon: 'fa-tools',
        //           routerLink: '/master-data/service-type',
        //           headerMenuName: 'Service Type',
        //         }
        //       ]
        //     }
        //   ]
        // }
      ]
    },
    /* {
      topheader: 'FEEDBACK ZONE',
      icon: 'fa-clipboard-check',
      submenuData: [
        {
          menuList: [
            {
              pvkey: 'VIEW_CUSTOMER_FEEDBACK',
              headerText: 'Global Master Data Setup',
              submenuImageIcon: 'fa-comments',
              routerLink: ['/feedback', 'DASH', 'TRANS'],
              queryParams: { type: 'D' },
              headerMenuName: 'Feedback',
              submenuDesc:
                'View the feedback, including Reply to invitation.'
            },
            {
              pvkey: 'RESEND_SURVEY',
              headerText: 'Global Master Data Setup',
              submenuImageIcon: 'fa-inbox-out',
              routerLink: ['/feedback', 'NoR', 'All'],
              queryParams: '',
              headerMenuName: 'Re-Send',
              submenuDesc: 'Resend surveys to recipients.'
            },
            {
              pvkey: 'VIEW_BOUNCE_BACK',
              headerText: 'Global Master Data Setup',
              submenuImageIcon: 'fa-recycle',
              routerLink: '/bounce-back',
              queryParams: '',
              headerMenuName: 'Bounce Back',
              submenuDesc:
                'View and address bounce backs by updating the correct email address.'
            },
            {
              pvkey: 'VIEW_OPT_OUT_LIST',
              headerText: 'Global Master Data Setup',
              submenuImageIcon: 'fa-sign-out-alt',
              routerLink: '/optout-business-rule',
              queryParams: '',
              headerMenuName: 'Opt-out List',
              submenuDesc:
                'View list of contacts who have unsubscribed from one or more brands for surveys.'
            }
          ]
        }
      ]
    }, */

    {
      topheader: 'FEEDBACK ZONE',
      icon: 'fa-clipboard-check',
      submenuData: [
        {
          menuList: [
            {
              pvkey: 'VIEW_CUSTOMER_FEEDBACK',
              headerText: 'Global Master Data Setup',
              submenuImageIcon: 'fa-comments',
              routerLink: ['/surveys/feedback', 'DASH', 'TRANS'],
              queryParams: { type: 'D' },
              headerMenuName: 'Feedback',
              submenuDesc:
                'View the feedback, including Reply to invitation.'
            },
            {
              pvkey: 'RESEND_SURVEY',
              headerText: 'Global Master Data Setup',
              submenuImageIcon: 'fa-inbox-out',
              routerLink: ['/surveys/feedback', 'NoR', 'All'],
              queryParams: '',
              headerMenuName: 'Re-Send',
              submenuDesc: 'Resend surveys to recipients.'
            },
            {
              pvkey: 'VIEW_BOUNCE_BACK',
              headerText: 'Global Master Data Setup',
              submenuImageIcon: 'fa-recycle',
              routerLink: '/surveys/bounce-back',
              queryParams: '',
              headerMenuName: 'Bounce Back',
              submenuDesc:
                'View and address bounce backs by updating the correct email address.'
            },
            {
              pvkey: 'VIEW_OPT_OUT_LIST',
              headerText: 'Global Master Data Setup',
              submenuImageIcon: 'fa-sign-out-alt',
              routerLink: '/fam-management/optout-business-rule',
              queryParams: '',
              headerMenuName: 'Opt-out List',
              submenuDesc:
                'View list of contacts who have unsubscribed from one or more brands for surveys.'
            }
          ]
        }
      ]
    },

    

    // {
    //   topheader: 'CLOSE THE LOOP',
    //   icon: 'fa-repeat',
    //   mainmenuImageurl: 'imagepath + ' /customer-service.png',
    //   submenuData: [
    //         {
    //       pvkey: 'CTLS_SURVEY_CONFIGURATION',
    //       headerText: 'Set Up',
    //       submenuImageurl: 'imagepath + ' /configuration.png',
    //       routerLink: ['ctls/setup'],
    //       headerMenuName: 'Initiation',
    //       submenuDesc: [
    //         'Enable / Disable',
    //         'Assignee Mapping',
    //         'Closure Template Listing',
    //       ],
    //     },
    //     {
    //       pvkey: 'CTLS_TASK_SURVEY_FEEDBACK',
    //       headerText: 'Task Management',
    //       submenuImageurl: 'imagepath + ' /list-text.png',
    //       routerLink: ['ctls/task-feedback'],
    //       headerMenuName: 'Task Management',
    //       submenuDesc: ['Survey Feedback Listing', 'Task Listing'],
    //     },
    //   ],
    // },
  ]
};
