import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signout-callback-oidc',
  templateUrl: './signout-callback-oidc.component.html',
  styleUrls: ['./signout-callback-oidc.component.scss']
})
export class SignoutCallbackOidcComponent implements OnInit {

  constructor() { 
    console.log("entered --signout")
  }

  ngOnInit() {
    console.log("entered --signout")
  }

}
