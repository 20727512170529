import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'todropdown'
})
export class TodropdownPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let result = [];
    let arr = args ? args.split('|') : [];
    if (value && value.length) {
      if (arr && arr.length && arr[3] && arr[3] !== 'null') {
        result = value.map(x => {
          return {
            value: x[arr[0]],
            label: arr[1] ? (x[arr[1]] + ' (' + x[arr[3]] + ')') : x[arr[0]]
          }
        });
      } else {
        result = value.map(x => {
          return {
            value: x[arr[0]],
            label: x[arr[1] ? arr[1] : arr[0]]
          }
        });
      }
    }
    result.sort((a, b) => (a.label ? a.label.toLowerCase() : a.label) < (b.label ? b.label.toLowerCase() : b.label) ? -1 : 1);
    if (arr && arr.length && arr[2] === 'true') {
      if (arr[4] && arr[4] === 'number') {
        result.unshift({
          label: 'Select',
          value: 0
        });
      } else {
        result.unshift({
          label: 'Select',
          value: null
        });
      }
    }
    return result;
  }

}
