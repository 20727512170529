import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CtlsSessionStorageService } from './ctls-session-storage.service';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class CtlsCommonService {
  public messageToaster$ = new BehaviorSubject<any>(null);
  // messageToaster$ = this.messageToaster.asObservable();
  constructor(private ctlsSessionStorageService: CtlsSessionStorageService) {}
  /**
   * Setting mesasage
   * @param data  {type:"Success",
    data:{
    message:"Successfully Updated"
    }
  }
   */
  setToaster(data) {
    this.messageToaster$.next({ ...data });
  }
  getToaster(): Observable<any> {
    return this.messageToaster$.asObservable();
  }
  clearToaster() {
    this.messageToaster$.next(null);
  }

  /**
   * Copy to Clipboard Functionality
   * @param objGrid => Array of data passed from component with Header and Items
   */
  copy2DToClipboard(objGrid) {
    let arrHeader = objGrid.header;
    let arrItems = objGrid.items;
    const blnChangeStatus = objGrid.blnChangeStatusToActiveDeactive;
    let finalArray = [];
    let finalHeaderArray = [];
    //Making 2D array
    arrHeader.forEach((element) => {
      if(element && element.header)
        finalHeaderArray.push(this.capitalizeFirstLetter(element.header));
    });
    finalArray.push(finalHeaderArray);
    //Making 2D array

    arrItems.forEach((element) => {
      if (!!blnChangeStatus) {
        if ('Status' in element) {

          element.Status = element.Status === true ? 'Active' : 'Inactive';
        }
      }
      finalArray.push(Object.values(element));
    });

    if (!!finalArray) {
      // get the reference for the body
      let body = document.getElementsByTagName('body')[0];
      // creates a <table> element and a <tbody> element
      let tbl = document.createElement('table');
      let tblBody = document.createElement('tbody');

      // creating all cells
      for (let i = 0; i < finalArray.length; i++) {
        // creates a table row
        let row = document.createElement('tr');

        for (let j = 0; j < Object.keys(finalArray[i]).length; j++) {
          // Create a <td> element and a text node, make the text
          // node the contents of the <td>, and put the <td> at
          // the end of the table row
          let cell;
          let text = '';
          let cellText;

          if (i === 0) {
            cell = document.createElement('th');
            cell.style.border = '1px solid black';
            // cell.style.width = '15%';
            // cell.style.background = '#ccebf5';
            text = finalArray[i][j] !== null ? '' + finalArray[i][j] : ' ';
            cellText = document.createTextNode(text);
            cell.appendChild(cellText);
            row.appendChild(cell);
          } else {
            cell = document.createElement('td');
            cell.style.border = '1px solid black';
            // cell.style.width = '15%';

            text = finalArray[i][j] !== null ? '' + finalArray[i][j] : ' ';
            cellText = document.createTextNode(text);
            cell.appendChild(cellText);
            
            row.appendChild(cell);
          }
        }
        // add the row to the end of the table body
        tblBody.appendChild(row);
      }
      // put the <tbody> in the <table>
      tbl.appendChild(tblBody);
      // appends <table> into <body>
      // sets the border attribute of tbl to 2;
      tbl.setAttribute('border', '2');
      tbl.style.color = 'black';
      tbl.style.fontSize = '13px';
      tbl.style.border = '1px solid black';
      document.body.appendChild(tbl);
      try {
        this.selectNode(tbl);
        let successful = document.execCommand('copy');
        document.body.removeChild(tbl);
        return new Promise((resolve, reject) => {
          successful ? resolve(true) : reject(false);
        });
      } catch (err) {
        document.body.removeChild(tbl);
        return new Promise((resolve, reject) => {
          reject(false);
        });
      }
    }
  }
  /**
   * select created table node
   */
  selectNode(node) {
    let range = document.createRange();
    range.selectNodeContents(node);

    let select = window.getSelection();
    select.removeAllRanges();
    select.addRange(range);
  }
  /**
   * Capitalize first letter
   */
  capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  }
  /**
   * module Permission check
   * @param action 
   * @returns 
   */
  allowPermission(action: any) {
    const permissions = this.ctlsSessionStorageService.getSessionData('Permissions');
    return _.includes(permissions, action);
  }
}
