import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[dynamicWidth]'
})
export class ResponsiveSliderDirective {

    constructor(private el: ElementRef) { }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        var tooltipSpan = <HTMLElement>this.el.nativeElement;
        if (tooltipSpan != null) {
            tooltipSpan.parentElement.style.maxWidth = (tooltipSpan.parentElement.parentElement.parentElement.clientWidth - 1).toString() + "px";
        }
    }

}