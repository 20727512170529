import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CtlsSharedService,
  CtlsSurveyOptionsService,
} from '../../ctls-services';

@Component({
  selector: 'app-ctls-survey-options',
  templateUrl: './ctls-survey-options.component.html',
  styleUrls: ['./ctls-survey-options.component.scss'],
})
export class CtlsSurveyOptionsComponent implements OnInit, OnChanges {
  @Output()
  eventFromCtlsSurveyOption: EventEmitter<any> = new EventEmitter<any>();
  @Input() blnShowDropdown = true;
  @Input() objSurveyInstance: any;
  @Input() objSurveyName: any;
  @Input() strRadioLabel1: string;
  @Input() strRadioLabel2: string;
  @Input() tabLabel3: string;
  @Input() blnRadio2Disable = false;
  @Input() strDropdown1: string = 'All';
  @Input() strDropdown2: string = 'All';
  @Input() blnTaskButton = false;
  @Input() blnAtlasCopco = false;
  @Input() blnSurveyNameInstanceList = true;
  @Input() selectedNextStepSurveyID: any;
  @Input() selectedNextStepInstance: any;
  @Input() blnShowSurveyDropdown = true;
  @Input() blnManageButton = false;
  @Input() showRule = true;
  @Input() showRuleSet = true;
  @Input() selectedTabForRule = 'ManageRule';
  @Input('enableManageRuleSetTab') enableManageRuleSetTab;



  @Input() selectedRadioOption: any;
  surveyInstance: string;
  @Input() selectedRadio: number = 1;
  @ViewChild('dropDownInstance') dropDownInstance: Dropdown;
  @ViewChild('dropDownSurveyName') dropDownSurveyName: Dropdown;
  isTaskNotRaised = true;
  isClosedWithoutTask = false;
  clearSurveyName = false;
  clearSurveyInstance = false;
  selectedSurveyName;
  selectedInstnce;
  destroy$ = new Subject();
  userFAM = '';
  CommonDateFilters: any;
  isManageRule = true;
  isManageRuleSet = false;  
  collapseData: boolean;
  constructor(private CtlsSharedService: CtlsSharedService,
    private ctlsSurveyOptionsService: CtlsSurveyOptionsService) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log("Changes", changes)
    if (changes.selectedTabForRule) {
      this.isManageRule = this.selectedTabForRule === 'ManageRule' ? true : false;
      this.isManageRuleSet = this.selectedTabForRule === 'ManageRuleSet' ? true : false;
    }
    if (changes.selectedRadioOption) {
      this.selectedRadio = this.selectedRadioOption;     
      this.isTaskNotRaised = true;
      this.isClosedWithoutTask = false;
    }
    

    if (changes.dropDownSurveyName) {
      if (!!this.dropDownSurveyName) {
        this.dropDownSurveyName.value = '';
        this.selectedSurveyName = '';
      }
    }
    if (changes.dropDownInstance) {
      if (!!this.dropDownInstance) {
        this.dropDownInstance.value = '';
        this.selectedInstnce = '';
      }
    }
    if (changes.objSurveyInstance || changes.selectedNextStepInstance) {
      this.objSurveyInstance = this.objSurveyInstance;
      if (this.objSurveyInstance && this.selectedNextStepInstance) {
        this.objSurveyInstance.forEach((element) => {
          if (element.code === this.selectedNextStepInstance) {
            this.selectedInstnce = element;
            if (this.selectedInstnce) {
              this.clearSurveyInstance = true;
            } else {
              this.clearSurveyInstance = false;
            }
          }
        });
      }
    }
    if (changes.objSurveyName || changes.selectedNextStepSurveyID) {
      this.objSurveyName = this.objSurveyName;
      if (this.objSurveyName && this.selectedNextStepSurveyID) {
        this.objSurveyName.forEach((element) => {
          if (element.code === this.selectedNextStepSurveyID) {
            this.selectedSurveyName = element;
            if (this.selectedSurveyName) {
              this.clearSurveyName = true;
            } else {
              this.clearSurveyName = false;
            }
          }
        });
      }
    }
    if (this.selectedInstnce || this.selectedSurveyName) {
      const nextStepSurveyOptionObj = {
        selectedInstnce: this.selectedInstnce,
        selectedSurveyName: this.selectedSurveyName,
      };
      this.eventFromCtlsSurveyOption.emit({
        operation: 'NEXT_STEP_SELECTED_SURVEY_OPTIONS',
        data: nextStepSurveyOptionObj,
      });
    }

    
  }

  ngOnInit() {
    // this.selectedRadio = 1;
    this.ctlsSurveyOptionsService.getUserFAM()
      .pipe(takeUntil(this.destroy$))
      .subscribe((famDetail => {
        if (!!famDetail && !!famDetail.Response && !!famDetail.Response.FamCode) {
          this.userFAM = famDetail.Response.FamCode
        } else {
          this.userFAM = null;
        }
      }))
    this.CtlsSharedService.getToggleFilterSub()
      .subscribe(res => {
        if ((res.field === 'Search' || res.field === 'BusinessFilter') && res.toggleFilter) {
          this.collapseData = false;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  radioButtonClick() {
   
    this.objSurveyInstance = [];
    this.objSurveyName = [];

    this.clearSurveyName = false;
    this.clearSurveyInstance = false;

    this.selectedInstnce = '';
    this.selectedSurveyName = '';

    this.isTaskNotRaised = true;
    this.isClosedWithoutTask = false;

    this.eventFromCtlsSurveyOption.emit({
      operation: 'RADIO_CHANGE',
      data: Number(this.selectedRadio),
    });
  }

  onDropdownChange1(event) {
    this.selectedInstnce = '';

    if (this.dropDownSurveyName.value) {
      this.clearSurveyName = true;
    } else {
      this.clearSurveyName = false;
    }
    if (!!this.dropDownInstance) this.dropDownInstance.value = '';
    this.clearSurveyInstance = false;
    this.eventFromCtlsSurveyOption.emit({
      operation: 'DROPDOWN_CHANGE_1',
      data: event.value,
    });
  }

  onDropdownChange2(event) {
    if (!!this.dropDownInstance.value) {
      let dateformat = this.dropDownInstance.value.name.split('(');
      dateformat = dateformat[1] ? dateformat[1].split(')') : null;
      dateformat = dateformat[0] ? dateformat[0].split('to') : null;
      this.CommonDateFilters = dateformat;

      this.clearSurveyInstance = true;
    } else {
      this.clearSurveyInstance = false;
      this.CommonDateFilters = '';
      this.selectedInstnce = '';
    }
    this.eventFromCtlsSurveyOption.emit({
      operation: 'DROPDOWN_CHANGE_2',
      data: event.value,
      dates: this.CommonDateFilters,
    });
  }
  /**
   * Task not raised and closed without task button click
   */
  taskChange(value) {
    if (value == 'TaskRaised') {
      this.isTaskNotRaised = true;
      this.isClosedWithoutTask = false;
      this.eventFromCtlsSurveyOption.emit({
        operation: 'TAB_CHANGE',
        tabValue: value,
      });
    } else if (value == 'CloseWithoutTask') {
      this.isTaskNotRaised = false;
      this.isClosedWithoutTask = true;
      this.eventFromCtlsSurveyOption.emit({
        operation: 'TAB_CHANGE',
        tabValue: value,
      });
    } else if (value == 'ManageRule') {
      this.isManageRuleSet = false;
      this.isManageRule = true;
      this.eventFromCtlsSurveyOption.emit({
        operation: 'TAB_CHANGE',
        tabValue: value,
      });
    } else if (value == 'ManageRuleSet') {
      this.isManageRule = false;
      this.isManageRuleSet = true;
      this.eventFromCtlsSurveyOption.emit({
        operation: 'TAB_CHANGE',
        tabValue: value,
      });
    }
  }
  toggleFilterView() {
    this.collapseData = !this.collapseData;
    this.CtlsSharedService.setToggleFilterSub({
      field: 'Filter',
      toggleSearch: true,
      toggleBusinessFilter: true
    });
  }
}
