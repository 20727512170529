import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TemplateQuestionsService } from "../../services/template-questions.service";

@Component({
  selector: 'app-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.scss']
})
export class NpsComponent implements OnInit {
  @Output()
  displayChange = new EventEmitter();
  @Input()
  buttonDisable: boolean = true;
  @Input()
  data: any = {};

  capturedData: any = {};
  queIndex: any = {};

  npsMin = 0;
  npsMax = 10;
  npsRange = [];

  @Input()
  canRate: boolean = false;
  editable: boolean = false;


  constructor(private messageService: MessageService, public _templateQuestionService: TemplateQuestionsService) {
    for (let i = this.npsMin; i <= this.npsMax; i++) {
      this.npsRange.push(i);
    }
  }

  successSaveMsg() {
    this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Your responses has been saved' });
  }

  ngOnInit() {
    this.capturedData = JSON.parse(JSON.stringify(this.data));
  }
  ngOnChanges() {
    this.capturedData = JSON.parse(JSON.stringify(this.data));
  }


  toggleEdit() {
    this.editable = !this.editable;
    this.buttonDisable = !this.buttonDisable;
    this.displayChange.emit(this.buttonDisable);
  }

  reset() {
    this.capturedData = JSON.parse(JSON.stringify(this.data));
    this.displayChange.emit(this.buttonDisable);
    this.toggleEdit();
  }

  save(Questiondata) {
    this._templateQuestionService.postEditedQuestions(Questiondata)
      .then(data => {
        this.data = Questiondata;
        this.capturedData = JSON.parse(JSON.stringify(this.data));
        return data;
      }).catch(error => {
        return error;
      })
    this.toggleEdit();
  }

  getAttributeByName(data, attributeName) {
    var dt = (data.filter(elem => elem.AttributeName == attributeName)[0]);
    if (!dt) {
      return { AttributeValue: "" };
    }
    if (dt.AttributeValue == null) {
      return { AttributeValue: "" };
    }
    return dt;

  }

}

