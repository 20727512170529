import { Component, OnInit, Input, Output, EventEmitter, OnChanges  } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TemplateQuestionsService } from "../../services/template-questions.service";

@Component({
  selector: 'app-multi-scale',
  templateUrl: './multi-scale.component.html',
  styleUrls: ['./multi-scale.component.scss']
})
export class MultiScaleComponent implements OnInit, OnChanges  {
  @Output()
  displayChange = new EventEmitter();
  @Input()
  buttonDisable: boolean = true;

  @Input()
  data:any={};
  capturedData:any={};
  queIndex:any={};
  editable:boolean=false;
  min:number=0;
  max:number=10;

  constructor(private messageService: MessageService, public _templateQuestionService : TemplateQuestionsService) { }

  ngOnInit() {
    this.capturedData = JSON.parse(JSON.stringify(this.data));
    
  }

  ngOnChanges() {
    this.capturedData=JSON.parse(JSON.stringify(this.data));
  } 

  successSaveMsg(){
    this.messageService.add({severity:'success', summary:'Saved', detail:'Your responses has been saved'});
  }

  toggleEdit() {
    this.editable = !this.editable;
    this.buttonDisable = !this.buttonDisable;
    this.displayChange.emit(this.buttonDisable);
  }
  reset() {
    this.capturedData = JSON.parse(JSON.stringify(this.data));
    this.displayChange.emit(this.buttonDisable);
    this.toggleEdit();
  }
  save(Questiondata) {
    this._templateQuestionService.postEditedQuestions(Questiondata)
      .then(data => {
        this.data = Questiondata;
        this.capturedData = JSON.parse(JSON.stringify(this.data));
        return data;
      }).catch(error => {
        return error;
      })
    this.toggleEdit();
  }

}
