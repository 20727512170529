import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LostBussinesHideAllOtherDialogService {
  private hideOtherDialog =  new Subject<any>();
  constructor() { }
  dialogClickEvent(message:any){
    this.hideOtherDialog.next(message);
  }
  getDialogClickEvent():Observable<any>{
    return this.hideOtherDialog.asObservable();
  }
}
