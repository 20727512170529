import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDynamicTooltipTable]'
})
export class DynamicTooltipTableDirective {

  constructor(private el: ElementRef) { }
  @HostListener('mouseenter') onMouseEnter() {
    var tooltipSpan = <HTMLElement>this.el.nativeElement.querySelector('span.tooltiptext');
    var textSpan = <HTMLElement>this.el.nativeElement.querySelector('span.textSpan')
    if (tooltipSpan != null && textSpan != null) {
      if (textSpan.offsetWidth < textSpan.scrollWidth) {
        tooltipSpan.style.display = 'block'
      }
      else {
        tooltipSpan.style.display = 'none'
      }
    }
    var tooltipSpanLabel = <HTMLElement>this.el.nativeElement.querySelector('span.tooltiptextlabel');
    var textSpanLabel = <HTMLElement>this.el.nativeElement.querySelector('span.textSpanLabel')
    if (tooltipSpanLabel != null && textSpanLabel != null) {
      if (tooltipSpanLabel.offsetHeight > 38) {
        tooltipSpanLabel.style.display = 'block';
        tooltipSpanLabel.style.visibility = 'visible';
      }
      else {
        tooltipSpanLabel.style.visibility = 'hidden';
      }
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    var tooltipSpanLabel = <HTMLElement>this.el.nativeElement.querySelector('span.tooltiptextlabel');
    if (tooltipSpanLabel != null) {
      tooltipSpanLabel.style.visibility = 'hidden';
    }
  }
}

