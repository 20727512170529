import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map, takeUntil } from 'rxjs/operators';
import { CommonFilterService } from "../shared/services/common-filter.service";
import { Injectable } from '@angular/core';
import { HttpCancelService } from '../shared/services/http-cancel.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from 'src/app/oauth2.config';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  urlfromserver = ''
  constructor(private httpCancelService: HttpCancelService,private oauthService: OAuthService,) { 
    
  }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
    let currentUrl = window.location.href;
    request = request.clone(
      {
          headers: request.headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`)
      });
 
    this.urlfromserver = currentUrl.substring(currentUrl.lastIndexOf("/"), currentUrl.length);
    if(this.urlfromserver == "" || this.urlfromserver == "/" || this.urlfromserver.includes('from=dashboard') || request.url.includes('TaskID?') || request.url.includes('GetCTLSDashboardDefaultView') || request.url.includes('GetCustomerFeedbackSummary') || request.url.includes('GetMasterFilter') || request.url.includes('GetDetails') || request.url.includes('CheckUserRole')) {
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body != null && event.body.Status == "UnAuthorized") {
              window.sessionStorage.clear();
              //window.location.href = "./logout";
              localStorage.removeItem('views')
              localStorage.clear();
              this.oauthService.configure(authConfig);
              this.oauthService.logOut();
              localStorage.removeItem('views')
            }
  
          }
          return event;
        }),
        retry(0),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            //errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            if (error.status == 401) {
              window.sessionStorage.clear();
              //window.location.href = "./logout";
              localStorage.removeItem('views')
              localStorage.clear();
              this.oauthService.configure(authConfig);
              this.oauthService.logOut();
              localStorage.removeItem('views')
            }
          }
          //window.alert(errorMessage);
   
          return throwError(errorMessage);
        }));
    } else {
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body != null && event.body.Status == "UnAuthorized") {
              window.sessionStorage.clear();
              //window.location.href = "./logout";
              localStorage.removeItem('views')
              localStorage.clear();
              this.oauthService.configure(authConfig);
              this.oauthService.logOut();
              localStorage.removeItem('views')
            }
  
          }
          return event;
        }),
        takeUntil(this.httpCancelService.onCancelPendingRequests()),
        retry(0),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            //errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            if (error.status == 401) {
              window.sessionStorage.clear();
              //window.location.href = "./logout";
              localStorage.removeItem('views')
              localStorage.clear();
              this.oauthService.configure(authConfig);
              this.oauthService.logOut();
              localStorage.removeItem('views')
            }
          }
          //window.alert(errorMessage);
   
          return throwError(errorMessage);
        }));
    }  
  }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   return next.handle(request)
  //     .pipe(
  //       retry(1),
  //       catchError((error: HttpErrorResponse) => {
  //         let errorMessage = '';
  //         if (error.error instanceof ErrorEvent) {
  //           // client-side error
  //           //errorMessage = `Error: ${error.error.message}`;
  //         } else {
  //           // server-side error
  //           //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //           if (error.status == 401) {
  //             window.location.href = "./logout";
  //           }
  //         }
  //         //window.alert(errorMessage);
  //         return throwError(errorMessage);
  //       })
  //     )
  // }
}