import { CtlsComponent } from './Modules/ctls/ctls.component';
import { Routes } from '@angular/router';

import { HomeComponent } from './components/dashboard/home/home.component';
//import { ManageQuestionsComponent } from './components/surveys/manage-questions/manage-questions.component';
//import { ManageSurvayTemplatesComponent } from './components/surveys/manage-survay-templates/manage-survay-templates.component';
//import { CreateSurveyTemplateComponent } from './components/surveys/create-survey-template/create-survey-template.component';
//import { SurveyTemplatesComponent } from './components/surveys/survey-templates/survey-templates.component';
//import { ManageSurveysComponent } from './components/surveys/manage-surveys/manage-surveys.component';
//import { SurveyLibraryComponent } from './components/surveys/survey-library/survey-library/survey-library.component';
//import { CreateSurveyComponent } from './components/surveys/survey-library/create-survey/create-survey.component';
//import { SurveySignatureComponent } from './components/surveys/survey-signature/survey-signature.component';
//import { ManageSurvayTemplatesComponent } from './components/surveys/manage-survay-templates/manage-survay-templates.component'
//import { FileTrackingComponent } from './components/dashboard/file-tracking/file-tracking.component';
//import { DesignModuleComponent } from './components/surveys/design-module/design-module.component';
//import { FamMasterComponent } from './components/fam-management/fam-master/fam-master.component';
//import { XyBusinessRulesComponent } from './components/fam-management/xy-business-rules/xy-business-rules.component';
//import { AddXYRuleComponent } from './components/fam-management/add-xyrule/add-xyrule.component';
//import { OptoutBusinessRuleComponent } from './components/fam-management/optout-business-rule/optout-business-rule.component';
//import { NosendBusinessRuleComponent } from './components/fam-management/nosend-business-rule/nosend-business-rule.component';
//import { ReminderBusinessRuleComponent } from './components/fam-management/reminder-business-rule/reminder-business-rule.component';
//import { FamsaleschannelBusinessRuleComponent } from './components/fam-management/famsaleschannel-business-rule/famsaleschannel-business-rule.component';
//import { SaleschannelBusinessRuleComponent } from './components/fam-management/saleschannel-business-rule/saleschannel-business-rule.component';
//import { StBusinessRuleComponent } from './components/fam-management/st-business-rule/st-business-rule.component';
//import { DRBusinessRuleComponent } from './components/fam-management/dr-business-rule/dr-business-rule.component';
//import { DataTrackingComponent } from './components/dashboard/data-tracking/data-tracking.component';
//import { AddFamComponent } from './components/fam-management/add-fam/add-fam.component';
//import { EditFamComponent } from './components/fam-management/edit-fam/edit-fam.component';
//import { ShowDesignModulesComponent } from './components/surveys/create-design-module/show-design-modules/show-design-modules.component';
//import { BAFAMDIVMappingComponent } from './components/surveys/create-design-module/ba-fam-div-mapping/ba-fam-div-mapping.component';
import { CreateEmailDesignModuleComponent } from './components/surveys/create-design-module/create-email-design-module/create-email-design-module.component';
import { CreatePageDesignModuleComponent } from './components/surveys/create-design-module/create-page-design-module/create-page-design-module.component';
//import { ViewFamComponent } from './components/fam-management/view-fam/view-fam.component';
//import { SurveyDesignComponent } from './components/surveys/survey-design/survey-design/survey-design.component';
//import { CreateDesignComponent } from './components/surveys/survey-design/create-design/create-design.component';
//import { EmailDesignComponent } from './components/surveys/survey-design/email-design/email-design.component';
//import { SurveyQuestionnaireComponent } from './components/surveys/survey-design/survey-questionnaire/survey-questionnaire.component';
//import { ViewDesignComponent } from './components/surveys/survey-design/view-design/view-design.component';
//import { ManageSurveyTimingsComponent } from './components/surveys/survey-timing/manage-survey-timings/manage-survey-timings.component'
//import { EditSurveyTimingsComponent } from './components/surveys/survey-timing/edit-survey-timings/edit-survey-timings.component'
//import { ShowGacListComponent } from './components/falcon-setup/GACCodeExport/show-gac-list/show-gac-list.component';
/*User Management*/
//import { UserManagementComponent } from './components/falcon-setup/user-management/user-management.component';
//import { AddUserComponent } from './components/falcon-setup/user-management/add-user/add-user.component';
// import { UserAccessComponent } from './components/falcon-setup/user-management/user-access/user-access.component';
// import { UserRolesComponent } from './components/falcon-setup/user-management/user-roles/user-roles.component';
/*User Management*/

//import { FeedbackListComponent } from './components/surveys/customer-feedback/feedback-list/feedback-list.component';
//import { BrandManagementComponent } from './components/fam-management/brand-management/brand-management.component';
//import { FamMasterStepComponent } from './components/fam-management/fam-master-step/fam-master-step.component'
//import { ViewDIVBrandsLanguagesComponent } from './components/fam-management/fam-summary/view-div-brands-languages/view-div-brands-languages.component';
//import { ViewBrandsLanguagesComponent } from './components/fam-management/fam-summary/view-brands-languages/view-brands-languages.component';
//import { ViewOtherDetailsComponent } from './components/fam-management/fam-summary/view-other-details/view-other-details.component';
//import { ViewZoneBranchesComponent } from './components/fam-management/fam-summary/view-zone-branches/view-zone-branches.component';
//import { ViewSalesEngineersServiceTechniciansComponent } from './components/fam-management/fam-summary/view-sales-engineers-service-technicians/view-sales-engineers-service-technicians.component';
//import { ViewSalesEngineersComponent } from './components/fam-management/fam-summary/view-sales-engineers/view-sales-engineers.component';
//import { ViewServiceTechniciansComponent } from './components/fam-management/fam-summary/view-service-technicians/view-service-technicians.component';
//import { BounceBackComponent } from './components/surveys/bounce-back/bounce-back.component';
import { CloneUserComponent } from './components/falcon-setup/user-management/clone-user/clone-user.component';
//import { BounceBackDomainComponent } from './components/surveys/bounce-back-domain/bounce-back-domain.component';

import { NpsResponselistComponent } from './components/dashboard/nps-responselist/nps-responselist.component';
import { TotalResponseComponent } from './components/dashboard/total-response/total-response.component';
import { TotalResponseChartComponent } from './components/dashboard/total-response-chart/total-response-chart.component';
import { UserProfileComponent } from './components/profile/user-profile/user-profile.component';
//import { OutboundTriggerComponent } from './components/fam-management/outbound-trigger/outbound-trigger.component';
//import { SurveySchedularandreminderComponent } from './components/surveys/survey-schedularandreminder/survey-schedularandreminder.component';
import { AccessDeniedComponent } from './shared/components/Errors/access-denied/access-denied.component';
import { DashboardComponent } from './Modules/Distributor_Survey/components/dashboard/dashboard.component';
//import { ComparisonDashboardComponent } from './Modules/comparison-dashboard/components/comparison-dashboard/comparison-dashboard.component';
import { DistributorsComponent } from './Modules/distributor-master/components/distributors/distributors.component';
import { CurrencyMasterListComponent } from './Modules/currency-master/components/currency-master-list/currency-master-list.component';
import { RevenueReportListComponent } from './Modules/revenue-report/components/revenue-report-list/revenue-report-list.component';
import { CreateRelationshipSurveyComponent } from './Modules/relationship-survey/components/create-relationship-survey/create-relationship-survey.component';
import { ManageRelationshipSurveyComponent } from './Modules/relationship-survey/components/manage-relationship-survey/manage-relationship-survey.component';
// Customer Management Changes
import { CustomersComponent } from './Modules/customer-master/components/customers/customers.component';
import { CtlsUsageManagementComponent } from './Modules/ctls/ctls-usage-management/ctls-usage-management.component';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';
import { StartupComponent } from './startup/startup.component';
import { SignoutCallbackOidcComponent } from './signout-callback-oidc/signout-callback-oidc.component';
//import { RegionComponent } from './components/master-data/region/region.component';
//import { BusinessAreaComponent } from './components/master-data/business-area/business-area.component';
//import { AddBusinessAreaComponent } from './components/master-data/add-business-area/add-business-area.component';
//import { DivisionComponent } from './components/master-data/division/division.component';
//import { CurrencyComponent } from './components/master-data/currency/currency.component';
//import { CountryComponent } from './components/master-data/country/country.component';
//import { TimezoneComponent } from './components/master-data/timezone/timezone.component';
//import { GacComponent } from './components/master-data/gac/gac.component';
//import { BusinessLineComponent } from './components/master-data/business-line/business-line.component';
//import { BrandComponent } from './components/master-data/brand/brand.component';

//import { LazyloadVirtualscroll } from './components/master-data/lazyload-virtualscroll/lazyload-virtualscroll.component';
//import { TouchpointComponent } from './components/master-data/touchpoint/touchpoint.component';
//import { ServiceTypeComponent } from './components/master-data/service-type/service-type.component';
//import { InfluenceLevelComponent } from './components/master-data/influence-level/influence-level.component';
//import { SalesChannelComponent } from './components/master-data/sales-channel/sales-channel.component';
//import { BranchComponent } from './components/master-data/branch/branch.component';
//import { ZoneComponent } from './components/master-data/zone/zone.component';

// export function getRelationalSurveyModule():Promise<any>  {
//   return import('./Modules/Distributor_Survey/distributor-survey.module').then(m =>  m.DistributorSurveyModule );
// };
// export function getRelationalDistributorModule():Promise<any>  {
//   return import('./Modules/distributor-master/distributor-master.module').then(m =>  m.DistributorMasterModule );
// }
// export function getCurrencyMasterModule():Promise<any>  {
//   return import('./Modules/currency-master/currency-master.module').then(m =>  m.CurrencyMasterModule );
// }
// export function getRevenueReportModule():Promise<any>  {
//   return import('./Modules/revenue-report/revenue-report.module').then(m =>  m.RevenueReportModule );
// }
// export function getComparisonModule():Promise<any> {
//   return import('./Modules/comparison-dashboard/comparison-dashboard.module').then(m => m.ComparisonDashboardModule);
// }
// export function getRelationshipSurveyModule():Promise<any>  {  
//   return import('./Modules/relationship-survey/relationship-survey.module').then(m =>  m.RelationshipSurveyModule );
// }

export const appRouter: Routes = [
  // { path: 'usage', component: CtlsUsageManagementComponent },
  //{ path: 'home', component: HomeComponent },
  //{ path: 'manage-question', component: ManageQuestionsComponent, data: { title: 'Manage Questions' } },
  //{ path: 'fam-master', component: FamMasterComponent, data: { title: 'FAM Master' } },
  //{ path: 'add-fam', component: AddFamComponent, data: { title: 'Add FAM' } },
  //{ path: 'edit-fam', component: EditFamComponent, data: { title: 'Edit FAM' } },
  //{ path: 'fam-master-step/:famID/:baID', component: FamMasterStepComponent, data: { title: 'FAM Master Step (BA/FAM)' } },
  //{ path: 'fam-master-step', component: FamMasterStepComponent, data: { title: 'FAM Master Step' } },
 // { path: 'view-fam', component: ViewFamComponent, data: { title: 'View FAM' } },
  //{ path: 'survey-design', component: SurveyDesignComponent, data: { title: 'Survey Design' } },
 // { path: 'publish-design/:IsPublished', component: SurveyDesignComponent, data: { title: 'Publish Design' } },
 // { path: 'create-design', component: CreateDesignComponent, data: { title: 'Create Design' } },
  //{ path: 'edit-design/:FAMDesignModuleID', component: CreateDesignComponent, data: { title: 'Edit Design' } },
  //{ path: 'email-design', component: EmailDesignComponent, data: { title: 'Email Design' } },
  //{ path: 'survey-questionnaire', component: SurveyQuestionnaireComponent, data: { title: 'Survey Questionnaire' } },
 // { path: 'view-design', component: ViewDesignComponent, data: { title: 'View Design' } },
  //{ path: 'nps-responselist', component: NpsResponselistComponent, data: { title: 'NPS Response List' } },
  //{ path: 'total-responselist', component: TotalResponseComponent, data: { title: 'Total Response List' } },
  //{ path: 'total-responselist-chart', component: TotalResponseChartComponent, data: { title: 'Total ResponseList Chart' } },
  //{ path: 'xy-business-rules', component: XyBusinessRulesComponent, data: { title: 'XY-Business Rules' } },
  //{ path: 'add-xyrule', component: AddXYRuleComponent, data: { title: 'Add XYRule' } },
  //{ path: 'optout-business-rule/:Dashboardoptout/:surveyType', component: OptoutBusinessRuleComponent, data: { title: 'Optout Business Rule' } },
  //{ path: 'optout-business-rule', component: OptoutBusinessRuleComponent, data: { title: 'Optout Business Rule' } },
 // { path: 'nosend-business-rule', component: NosendBusinessRuleComponent, data: { title: 'No Send Business Rule' } },
  //{ path: 'reminder-business-rule', component: ReminderBusinessRuleComponent, data: { title: 'Reminder Business Rule' } },
  //{ path: 'famsaleschannel-business-rule', component: FamsaleschannelBusinessRuleComponent, data: { title: 'FAM Sales Channel Business Rule' } },
  //{ path: 'saleschannel-business-rule', component: SaleschannelBusinessRuleComponent, data: { title: 'Sales Channel Business Rule' } },
  //{ path: 'st-business-rule', component: StBusinessRuleComponent, data: { title: 'ST Business Rule' } },
 // { path: 'dr-business-rule', component: DRBusinessRuleComponent, data: { title: 'Domain Restriction List' } },
  //{ path: 'manage-survey-template', component: ManageSurvayTemplatesComponent, data: { title: 'Manage Survey Template' } },
  //{ component: SurveyTemplatesComponent, path: 'surveyTemplates', data: { title: 'Survey Templates' } },
  //{ path: 'manage-survey', component: ManageSurveysComponent, data: { title: 'Manage Survey' } },
  //{ path: 'survey-library', component: SurveyLibraryComponent, data: { title: 'Survey Library' } },
  //{ path: 'test-survey', component: SurveyLibraryComponent, data: { title: 'Test Survey' } },
 // { path: 'edit-survey/:id/:status', component: CreateSurveyComponent, data: { title: 'Edit Survey' } },
  //{ path: 'create-survey', component: CreateSurveyComponent, data: { title: 'Create Survey' } },
  //{ path: 'feedback/:fromMenu/:type', component: FeedbackListComponent, data: { title: 'Feedback' } },
  //{ path: 'brand-management', component: BrandManagementComponent, data: { title: 'Brand Master' } },
  //{ path: 'outbound-trigger', component: OutboundTriggerComponent, data: { title: 'Outbound Trigger' } },
 // { path: 'survey-schedularandreminder', component: SurveySchedularandreminderComponent, data: { title: 'Reminders and Expiry Settings' } },

  // { path: 'create-survey-template', component: CreateSurveyTemplateComponent },
  //{ path: 'create-survey-template/:templateId', component: CreateSurveyTemplateComponent, data: { title: 'Create Survey Template' } },
  //{ path: 'create-survey-template/:templateId/:breadcrumbPara', component: CreateSurveyTemplateComponent, data: { title: 'Create Survey Template' } },
 // { path: 'dashboard/file-tracking', component: FileTrackingComponent, data: { title: 'File Tracking' } },
  //{ path: 'design-module', component: DesignModuleComponent, data: { title: 'Design Module' } },
  //{ path: 'survey/signature', component: SurveySignatureComponent, data: { title: 'Survey/Signature' } },
  // { path: 'dashboard/data-tracking', component: DataTrackingComponent, data: { title: 'Dashboard/Data Tracking' } },
 // { path: 'show-design-module', component: ShowDesignModulesComponent, data: { title: 'Show Design Module' } },
  //{ path: 'ba-fam-div-design-module', component: BAFAMDIVMappingComponent, data: { title: 'BA FAM Div Design Module' } },
  //{ path: 'survey-timings', component: ManageSurveyTimingsComponent, data: { title: 'Survey Timings' } },
 // { path: 'edit-survey-timings', component: EditSurveyTimingsComponent, data: { title: 'Edit Survey Timings' } },
  //{ path: 'show-gac-list', component: ShowGacListComponent, data: { title: 'Show Gac List' } },
  //{ path: 'user-management', component: UserManagementComponent, data: { title: 'Manage Users' } },
  //{ path: 'add-user', component: AddUserComponent, data: { title: 'Add User' } },
  // {
  //     path: 'add-user', component: AddUserComponent, children: [
  //         { path: '', component: UserRolesComponent, outlet: 'usermanagement' },
  //         { path: 'user-roles', component: UserRolesComponent, outlet: 'usermanagement' },
  //         { path: 'user-access', component: UserAccessComponent, outlet: 'usermanagement' }
  //     ]
  // },
  //{ path: 'view-divbl-br-lang', component: ViewDIVBrandsLanguagesComponent, data: { title: 'View Brands Languages' } },
  //{ path: 'view-brands-languages', component: ViewBrandsLanguagesComponent, data: { title: 'View Brands Languages' } },
  //{ path: 'view-brands-languages', component: ViewOtherDetailsComponent, data: { title: 'View Brands Languages' } },
  //{ path: 'view-zone-branches', component: ViewZoneBranchesComponent, data: { title: 'View Zone Branches' } },
  //{ path: 'view-sales-service-technicians', component: ViewSalesEngineersServiceTechniciansComponent, data: { title: 'View Sales Service Technicians' } },
  //{ path: 'view-sales-engineers', component: ViewSalesEngineersComponent, data: { title: 'View Sales Engineers' } },
 // { path: 'view-service-technicians', component: ViewServiceTechniciansComponent, data: { title: 'View Service Technicians' } },
  //{ path: 'bounce-back', component: BounceBackComponent, data: { title: 'Bounce Back' } },
 // { path: 'clone-user', component: CloneUserComponent, data: { title: 'Clone User' } },
  
  {path:'signout-callback-oidc',component :SignoutCallbackOidcComponent},
  {path:'',component :StartupComponent},
 { path: 'signin-oidc', component: SigninOidcComponent},
  { path: 'user-profile', component: UserProfileComponent, data: { title: 'User Profile' } },
  { path: 'error/accessdenied', component: AccessDeniedComponent, data: { title: 'Error/Accessdenied' } },
  //{ path: 'distributor', component: DashboardComponent },
 // { path: '', component: HomeComponent, pathMatch: 'full' },
  //   { path: 'dashboard',   children: [
  //     {
  //       path: '',
  //       loadChildren: './Modules/Distributor_Survey/distributor-survey.module#DistributorSurveyModule?sync=true'
  //     }
  //   ], data: { title: 'Relationship Survey' } },
  // //   //{ path: 'dashboard', loadChildren: () => import('./Modules/Distributor_Survey/distributor-survey.module').then(m => m.DistributorSurveyModule), data: { title: 'Relationship Survey' } },
  // //   // { path:'distributorMaster', loadChildren: getRelationalDistributorModule },
  //   { path: 'distributor-master', 
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: './Modules/distributor-master/distributor-master.module#DistributorMasterModule?sync=true'
  //     }
  //   ]
  //   , data: { title: 'Manage Distributors' } },
  //   { path: 'currency-master',
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: './Modules/currency-master/currency-master.module#CurrencyMasterModule?sync=true'
  //     }
  //   ]
  //   , data: { title: 'Currency Master' } },
  //   { path: 'revenue-report',   children: [
  //     {
  //       path: '',
  //       loadChildren: './Modules/revenue-report/revenue-report.module#RevenueReportModule?sync=true'
  //     }
  //   ],data: { title: 'Revenue Report' } },
  //{ path: 'dashboard/data-tracking/:blankInfluencer', component: DataTrackingComponent, data: { title: 'Data Tracking' } },
  //   { path: 'comparison-dashboard', children: [
  //     {
  //       path: '',
  //       loadChildren: './Modules/comparison-dashboard/comparison-dashboard.module#ComparisonDashboardModule?sync=true'
  //     }
  //   ] , data: { title: 'Comparison Dashboard' } },
  //  { path:'relationship-survey',
  //  children: [
  //   {
  //     path: '',
  //     loadChildren: './Modules/relationship-survey/relationship-survey.module#RelationshipSurveyModule?sync=true'
  //   }
  // ]
  // }
  // {�path:�'dashboard/data-tracking/:blankInfluencer',�component:�DataTrackingComponent�, data: { title: 'Data Tracking' } },

  // { path: 'dashboard', loadChildren: getRelationalSurveyModule, data: { title: 'Relationship Survey' } },
  //   { path:'distributorMaster', loadChildren: getRelationalDistributorModule },
  //   { path: 'distributor-master', loadChildren: getRelationalDistributorModule, data: { title: 'Manage Distributors' } },
  //  { path: 'currency-master', loadChildren: getCurrencyMasterModule, data: { title: 'Currency Master' } },
  //   { path: 'revenue-report', loadChildren: getRevenueReportModule, data: { title: 'Revenue Report' } },
  //  { path: 'comparison-dashboard', loadChildren: getComparisonModule, data: { title: 'Comparison Dashboard' } },
  //  { path:'relationship-survey', loadChildren: getRelationshipSurveyModule },
  // { path: 'dashboard', component:DashboardComponent , data: { title: 'Relationship Survey' } },
 // { path: 'dashboard/distributor', component: DashboardComponent, data: { title: 'Relationship Survey' } },
  //{ path: 'distributor-master', component: DistributorsComponent, data: { title: 'Manage Distributors' } },
 // { path: 'customer-master', component: CustomersComponent, data: { title: 'Manage Customers' } },
 // { path: 'currency-master', component: CurrencyMasterListComponent, data: { title: 'Currency Master' } },
  //{ path: 'revenue-report', component: RevenueReportListComponent, data: { title: 'Revenue Report' } },
  //{ path: 'comparison-dashboard/comparison-dashboard', component: ComparisonDashboardComponent, data: { title: 'Comparison Dashboard' } },

  // { path: 'relationship-survey/create-relationship-survey', component: CreateRelationshipSurveyComponent },
  // { path: 'relationship-survey/create-relationship-survey/:surveyID', component: CreateRelationshipSurveyComponent },
  // { path: 'test-relationship-survey', component: ManageRelationshipSurveyComponent },
  // { path: 'relationship-survey', component: ManageRelationshipSurveyComponent },
  // {
  //   path: 'ctls',
  //   loadChildren: './Modules/ctls/ctls.module#CtlsModule',
  // },
  // {
  //   path: 'ctls-notification/:id',
  //   component: HomeComponent,
  // },
// { path: 'relationship-survey/create-relationship-survey', component: CreateRelationshipSurveyComponent },
// { path: 'relationship-survey/create-relationship-survey/:surveyID', component: CreateRelationshipSurveyComponent },
// { path:'test-relationship-survey', component: ManageRelationshipSurveyComponent },
// { path:'relationship-survey', component: ManageRelationshipSurveyComponent },
// { path: 'maintenance', component: MaintenanceComponent, data: { title: 'Maintenance' } },
{
  path: 'ctls',
  loadChildren: './Modules/ctls/ctls.module#CtlsModule',
},
{
  path: 'falcon',
  loadChildren: './Modules/falcon/falcon.module#FalconModule',
},
{
  path: 'currency-master',
  loadChildren: './Modules/currency-master/currency-master.module#CurrencyMasterModule',
},
{
  path: 'customer-master',
  loadChildren: './Modules/customer-master/customer-master.module#CustomerMasterModule',
},
{
  path: 'distributor-master',
  loadChildren: './Modules/distributor-master/distributor-master.module#DistributorMasterModule',
},
{
  path: 'revenue-report',
  loadChildren: './Modules/revenue-report/revenue-report.module#RevenueReportModule',
},
{
  path: 'relationship-survey',
  loadChildren: './Modules/relationship-survey/relationship-survey.module#RelationshipSurveyModule',
},
{
  path: 'Distributor_Survey',
  loadChildren: './Modules/Distributor_Survey/distributor-survey.module#DistributorSurveyModule',
},


//Newly added on 06-06-2023//
{
  path: 'dashboard',
  loadChildren: './components/dashboard/dashboard.module#DashboardModule',
},
//Newly added on 16-06-2023//
{
  path: 'falcon-setup',
  loadChildren: './components/falcon-setup/falcon-setup.module#FalconSetupModule',
},
//Newly added on 19-06-2023
{
  path: 'fam-management',
  loadChildren: './components/fam-management/fam-management.module#FamManagementModule',
},
//Newly added on 20-06-2023
{
  path: 'surveys',
  loadChildren: './components/surveys/surveys.module#SurveysModule',
},
//{ path:'master-data/region', component: RegionComponent },
//{ path:'master-data/business-area', component: BusinessAreaComponent },
//{ path:'master-data/add-business-area', component: AddBusinessAreaComponent },
//{ path:'master-data/division', component: DivisionComponent },
//{ path:'master-data/currency', component: CurrencyComponent },
//{ path:'master-data/country', component: CountryComponent },
//{ path:'master-data/timezone', component: TimezoneComponent },
//{ path:'master-data/gac-org', component: GacComponent },
//{ path:'master-data/business-line', component: BusinessLineComponent },
//{ path:'master-data/brand', component: BrandComponent },
//{ path:'master-data/touchpoint', component: TouchpointComponent },
//{ path:'master-data/service-type', component: ServiceTypeComponent },
//{ path:'master-data/influence-level', component: InfluenceLevelComponent },
//{ path:'master-data/sales-channel', component: SalesChannelComponent },
//{ path:'master-data/branch', component: BranchComponent },
//{ path:'master-data/zone', component: ZoneComponent },
//{ path:'master-data/lazyload-virtualscroll', component: LazyloadVirtualscroll }
];

