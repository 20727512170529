import { CtlsShortFilterDatePipe } from './../../ctls-pipes/ctls-short-filter-date.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CtlsFilterDatePipe } from '../../ctls-pipes';
import {
  CtlsFilterHeaderService,
  CtlsLostbusinessCustomerService,
  CtlsSharedService,
} from '../../ctls-services';
import { CtlsShortFilterHeaderComponent } from './ctls-short-filter-header.component';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TreeTableModule } from 'primeng/treetable';
import { TreeModule } from 'primeng/tree';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    CalendarModule,
    ScrollPanelModule,
    MultiSelectModule,
    DropdownModule,
    OverlayPanelModule,
    TreeTableModule,
    TreeModule,
    FormsModule,
    TooltipModule
  ],
  declarations: [CtlsShortFilterHeaderComponent, CtlsShortFilterDatePipe],
  exports: [CtlsShortFilterHeaderComponent],
  providers: [
    CtlsFilterHeaderService,
    CtlsSharedService,
    CtlsLostbusinessCustomerService,
  ],
})
export class CtlsShortFilterHeaderModule {}
