import { Component, OnInit,ViewChild,Output, Input, EventEmitter } from '@angular/core';
import { NpsResponselist } from '../model/nps-responselist.model';
import {NpsResponselistService} from '../service/nps-responselist.service';
import { Paginator } from 'primeng/paginator';
import { ActivatedRoute } from '@angular/router';
import { DashboardWidgetService } from 'src/app/components/dashboard/service/dashboard-widget.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { SharedService } from 'src/app/shared/services/shared.service';
import { surveyType } from './../../../shared/constants/shared.constants';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-nps-responselist',
  templateUrl: './nps-responselist.component.html',
  styleUrls: ['./nps-responselist.component.scss']
})
export class NpsResponselistComponent implements OnInit {
  private imagepath = environment.imagepath;
  @ViewChild(Paginator) dt: Paginator;
  @Input() surveyTypeInput;
  selectedCategory: any;
  NpsResponselistData: NpsResponselist[];
  cols: any[];
  boolInvitation : string= "none";
  totalRecords: number;
  loading: boolean = false;
  pageNo = 1;
  pageSize = 15;
  configRow: number[] = [15, 25, 35, 45, 55];
  searchFileString: any = '';

  UserID: any;
  sortObj: any = {
    'sortType': '',
    'sortField': ''
  };
  successMsg: boolean = false;
  msgs: Message[] = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  genericErrorMessage = 'An error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail;

  dataNotFoundMessage = "No data available.";
  apiResponseData: any;
  paginatorFirstSelector: any;
  paginatorLaststSelector: any;
  paginatorNextSelector: any;
  paginatorPrevSelector: any;
  divhide: boolean=true;
  showNoData: boolean = false;
  showErrorData: boolean = false;
  @Input()
  Divshow:boolean=true;


  @Output()
  displayChange = new EventEmitter();

  constructor(private npmresponselistService:NpsResponselistService,private filterService: FilterService,
    private dashboardWidgetService: DashboardWidgetService, private messageService: MessageService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.boolInvitation = "block";
    this.GetData();
  }

  Hidediv(){
    this.divhide=false;
    this.displayChange.emit({hideArrow : true});
  }

  GlobalFilterRecords() {
    this.filterService.getUserFilter().then().catch(
      e => {
        this.NpsResponselistData = null;
        this.successMsg = true;
        this.callErrorMsg('error', '', this.genericErrorMessage);
      }
    );
  }

  GetData() {
    this.pageNo = 1;
    this.pageSize = 15;
    this.cols = [
      { field: 'SurveyId', header: 'Survey ID' },
      { field: 'FAM', header: 'FAM' },
      { field: 'CustomerName', header: (this.surveyTypeInput === 'DIST' ||this.surveyTypeInput === 'OEM')? 'Company Name' : 'Customer Name'  },
      { field: 'CustomerContactFullName', header: (this.surveyTypeInput === 'DIST' || this.surveyTypeInput === 'OEM') ? 'Contact Full Name' : 'Customer Contact Full Name' },
      { field: 'EmailAddress', header: 'Email Address' },
      { field: 'SubmitDate', header: 'Submit Date' },
      { field: 'Score', header: 'Score' }
    ];
    this.NpsResponselistData = [];

    var res = this.filterService.filterObj;
    // this.NPSResponsemodel = {
    //   // PageNo: this.pageNo,
    //   // PageSize: this.pageSize,
    //   // SortField: this.sortObj.sortField,
    //   // sortType: this.sortObj.sortType,
    //   FromDate: res.StartDate == "" ? null : res.StartDate,
    //   ToDate: res.EndDate == "" ? null : res.EndDate,
    //   Branch: res.Branch == null ? null : res.Branch.replace(/[|]/g, ','),
    //   Brand: res.BrandName == null ? null : res.BrandName.replace(/[|]/g, ','),
    //   BusinessArea: res.BACode == null ? null : res.BACode.replace(/[|]/g, ','),
    //   BusinessLine: res.BLCode == null ? null : res.BLCode.replace(/[|]/g, ','),
    //   Country: res.Country == null ? null : res.Country.replace(/[|]/g, ','),
    //   Continent: res.Continent == null ? null : res.Continent.replace(/[|]/g, ','),
    //   Region: res.Region == null ? null : res.Region.replace(/[|]/g, ','),
    //   Division: res.DivCode == null ? null : res.DivCode.replace(/[|]/g, ','),
    //   FAM: res.FAMCode == null ? null : res.FAMCode.replace(/[|]/g, ','),
    //   InfluenceLevel: res.Influencer == null ? null : res.Influencer.replace(/[|]/g, ','),
    //   SalesEngineer: res.SalesEngineer == null ? null : res.SalesEngineer.replace(/[|]/g, ','),
    //   ServiceTechnician: res.ServiceTechnician == null ? null : res.ServiceTechnician.replace(/[|]/g, ','),
    //   TouchPoints: res.Touchpoint == null ? null : res.Touchpoint.replace(/[|]/g, ','),
    //   Zone: res.Zone == null ? null : res.Zone.replace(/[|]/g, ','),
    //   PeriodType: res.PeriodType
    // }
    this.paginationData(this.sortObj.sortField,this.sortObj.sortType,this.pageNo,this.pageSize,this.filterService.filterObj);

  }
  NPSResponsemodel: any = {
    FromDate: null,
    ToDate: null,
    Branch: null,
    Brand: null,
    BusinessArea: null,
    BusinessLine: null,
    Country: null,
    Continent: null,
    Region: null,
    Division: null,
    FAM: null,
    InfluenceLevel: null,
    SalesEngineer: null,
    ServiceTechnician: null,
    TouchPoints: null,
    Zone: null,
    PeriodType: null
  }

  paginationData(sortField,sortType,pageNo,pageSize,NPSResponsemodel) {
    this.loading = true;
    this.NpsResponselistData = [];
    this.NpsResponselistData.push({} as NpsResponselist);
    this.npmresponselistService.getNPSResponseList(sortField,sortType,pageNo,pageSize,this.filterService.filterObj).then(rawData => {
     // console.log(rawData.Response);
      if (rawData == null ) {

        this.NpsResponselistData = [];
        this.totalRecords = 0;
        this.pageNo = pageNo;
        this.pageSize = pageSize;

        this.loading = false;
      }else if(rawData.Status =="Success" && rawData.Response == null){
        this.showNoData = true;
      }else if(rawData.Status =="Failure"){
        this.showErrorData = true;
      }else {
        let rawResponse: any = rawData
        if (rawResponse.Status == 'Success') {
          let data = rawResponse.Response;
          this.NpsResponselistData = data.Collection;
          this.totalRecords = data.TotalRecords;
          this.boolInvitation = "none";
          this.pageNo = data.CurrentPageNo;
          this.pageSize = data.PageSize;
          this.loading = false;
        }
        else {
          if (rawResponse.Errors != null && rawResponse.Errors.length > 0) {
            this.successMsg = true;
            this.callErrorMsg('error', '', rawResponse.Errors[0].Message);
          }
          else {
            this.showEmptyGrid(1, 15);
          }
        }
        this.loading = false;
      }
    }).catch(e => {
      this.NpsResponselistData = null;
      this.successMsg = false;
      this.showErrorData = true;
      this.callErrorMsg('error', '', this.genericErrorMessage);
    });

  }
  showEmptyGrid(page: any, size: any) {
    this.NpsResponselistData = [];
    this.totalRecords = 0;
    this.pageNo = page;
    this.pageSize = size;
    this.loading = false;
  }
  ngAfterViewInit() {
    this.paginatorFirstSelector = <HTMLElement>document.querySelector("a.ui-paginator-first");
    this.paginatorFirstSelector.title = 'First';

    this.paginatorLaststSelector = <HTMLElement>document.querySelector("a.ui-paginator-last");
    this.paginatorLaststSelector.title = 'Last';

    this.paginatorNextSelector = <HTMLElement>document.querySelector("a.ui-paginator-next");
    this.paginatorNextSelector.title = 'Next';

    this.paginatorPrevSelector = <HTMLElement>document.querySelector("a.ui-paginator-prev");
    this.paginatorPrevSelector.title = 'Previous';
  }
  paginationNavigationDetails(event) {
    if (event.page >= this.pageNo || (event.page + 2) <= this.pageNo || this.pageSize != event.rows) {
      this.pageNo = event.page + 1;
      this.pageSize = event.rows;
      this.paginationData(this.sortObj.sortField,this.sortObj.sortType,this.pageNo,this.pageSize,this.filterService.filterObj);
    }
  }
  customSort(event: any) {
    if (event.sortField) {
      event.sortOrder = event.sortOrder == 1 ? 'asc' : 'dsc';

      this.pageNo = 1;
      this.sortObj.sortType = event.sortOrder;
      this.sortObj.sortField = event.sortField;
      this.paginationData(event.sortField,event.sortOrder,this.pageNo,this.pageSize,this.filterService.filterObj);
      this.dt.first = 0;
    }

  }
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    window.scrollTo(0, 0);
    setTimeout(function () { document.getElementById('ClearMsg').click(); }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }
}
