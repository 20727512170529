import { EntityData } from "./api-data-models.class";
import { ChartConfigs } from "../configs/default-chart-options.config";

export class ParseablePeriodicData {
    public Data: EntityData[];
    public Period: string;
    public _timestamp: Date;

    constructor() {
        this._timestamp = new Date();
        this.Data = [];
        this.Period = '';
    }
}
export class ParsableChartData {
    Month: ParseablePeriodicData[];
    Year: ParseablePeriodicData[];

    constructor() {
        this.Month = [];
        this.Year = [];
    }
}

export class SeriesElements {
    name: string;
    data: number[];
    color: string;
    negativeColor?: string;
    Opacity?: number;
    maxPointWidth?: number;
    xAxis?: number;

    constructor() {
        this.name = '';
        this.data = [];
        this.color = ChartConfigs.WinColor;
        this.negativeColor = ChartConfigs.LoseColor;
        this.maxPointWidth = ChartConfigs.MaxPointWidth;
    }
}

export class ParsedChartData {
    xAxis: string[];
    yAxis: SeriesElements[];

    constructor() {
        this.xAxis = [];
        this.yAxis = [];
    }
}


/******************** New models: Jan 31 2020 ********************/

export class ParsedTrendData {
    WinCount: number = -1;
    LostCount: number = -1;
    Period: string = '';
    WinList: string[] = [];
    LostList: string[] = [];
    _timestamp?: Date = new Date();

    constructor() {}
}

export class ParsedTrendDataResponse {
    Year: ParsedTrendData[] = [];
    Month: ParsedTrendData[] = [];

    constructor() {}
}

