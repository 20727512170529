import { CtlsTaskListingService } from './../../../../ctls-shared/ctls-services/ctls-task-listing.service';
import {
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-ctls-comment-popover',
  templateUrl: './ctls-comment-popover.component.html',
  styleUrls: ['./ctls-comment-popover.component.scss'],
})
export class CtlsCommentPopoverComponent {
  constructor(private taskListingService: CtlsTaskListingService) {}
  @Input() appendTo: any;

  @ContentChild(TemplateRef) overlayTemplate: TemplateRef<any>;

  @ViewChild('overlaypanel') private overlay: OverlayPanel;
  positionLeft = '0px';
  positionTop = '20px';
  marginStyle = {
    'margin-left': this.positionLeft,
    'margin-top': this.positionTop,
  };
  /**
   * show popup
   * @param event
   * @param target
   */
  show(event, target?) {
    this.overlay.show(event, target);
  }

  /**
   * Hide popup
   */
  hide() {
    const f = this.taskListingService.getCurrentCommentStatus();
    if (!f) {
      this.overlay.hide();
    }
  }
}
