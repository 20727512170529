export class LostBusinessLBCdrilldown {
}
export interface LostBusinessLBCdrilldown {
  CustomerName: string;
  AccountNo: string;
  FAMCode: string;
  BACode: string;
  CountryName: string;
  NoOfContact: number;
  IsSingleFAM: boolean;
  FAMID: number;
  BAId: number;
  TotalCount: number;
}
export interface LostBusinessLBCdrilldownResponse {
  status: string;
  errors: any[];
  Response: {
    pageCount: number;
    pageSize: number;
    totalRecords: number;
    currentPageNo: number;
    collection: LostBusinessLBCdrilldown[];
  }
}
export interface LostBusinessLBCdrilldown2 {
  CustomerName: string;
  AccountNo: string;
  FAMCode: string;
  BACode: string;
  CountryName: string;
  Contact: string;
  ContactEmail: string;
  Brand: string;
  DIVCode: string;
  DispositionStatus: string;
  TransactionDate: string;
  IsSingleFAM: boolean;
  TotalCount: number;
  ErrorContactNameEmail:boolean;
  ErrorDivisionCode:boolean;
  ErrorContactName:boolean;
  ErrorBrandName:boolean;
  ErrorCountryName:boolean;
}
export interface LostBusinessLBCdrilldown2Response {
  status: string;
  errors: any[];
  Response: {
    pageCount: number;
    pageSize: number;
    totalRecords: number;
    currentPageNo: number;
    collection: LostBusinessLBCdrilldown2[];
  }
}
export interface LostBusinessLBCTotaldrilldown {
  CustomerName: string;
  AccountNo: number;
  Contact: string;
  ContactEmail: string;
  BACode: string;
  FAMCode: string;
  SurveyId: number;
  BrandName: string;
  LstTrnsSurveyDate: string;
  Divisioncode: string;
  ErrorContactNameEmail:boolean;
  ErrorDivisionCode:boolean;
  ErrorContactName:boolean;
  ErrorBrandName:boolean;
  ErrorCountryName:boolean;
  SubmitDate : string
}
export interface LostBusinessLBCTotaldrilldownResponse {
  status: string;
  errors: any[];
  Response: {
    pageCount: number;
    pageSize: number;
    totalRecords: number;
    currentPageNo: number;
    collection: LostBusinessLBCTotaldrilldown[];
  }
}

export interface LostBusinessLBCTotalDispodrilldown {
  count: number;
  DispositionStatus: string;
}
export interface LostBusinessLBCTotalDispodrilldownResponse {
  status: string;
  errors: any[];
  Response: {
    pageCount: number;
    pageSize: number;
    totalRecords: number;
    currentPageNo: number;
    collection: LostBusinessLBCTotalDispodrilldown[];
  }
}

export interface LostBusinessLBCTotalTransactiondrilldown {
  CustomerName: string;
  AccountNo: number;
  TransactionCnt: number;
  FAMID: number;
  FAMCode: string;
  BAID: number;
}
export interface LostBusinessLBCTotalTransactiondrilldownResponse {
  status: string;
  errors: any[];
  Response: {
    pageCount: number;
    pageSize: number;
    totalRecords: number;
    currentPageNo: number;
    collection: LostBusinessLBCTotalTransactiondrilldown[];
  }
}
export interface LostBusinessWindrilldown2 {
  CustomerName: string;
  AccountNo: string;
  FAMCode: string;
  BACode: string;
  CountryName: string;
  Contact: string;
  ContactEmail: string;
  Brand: string;
  DIVCode: string;
  DispositionStatus: string;
  SurveySentDate: string;
  IsSingleFAM: boolean;
  TotalCount: number;
  SurveySubmitDate: string;
  EventFileProcessedDate: string;
  RTIDate: string;
  NPSRating: number;
  PeriodTrans:string;
  FAMID:number;
  BAId:number;
  ErrorContactNameEmail:boolean;
  ErrorDivisionCode:boolean;
  ErrorContactName:boolean;
  ErrorBrandName:boolean;
  ErrorCountryName:boolean;
  Totaltransactioncnt: number;
  YearWiseCount: any[];
}
export interface LostBusinessWindrilldown2Response {
  status: string;
  errors: any[];
  Response: {
    pageCount: number;
    pageSize: number;
    totalRecords: number;
    currentPageNo: number;
    collection: LostBusinessWindrilldown2[];
  }
}
export interface LostBusinessTotalContactDrilldown {
  CustomerName: string;
  AccountNo: string;
  FAMCode: string;
  BACode: string;
  Contact: string;
  ContactEmail: string;
  BrandName: string;
  Divisioncode: string;
  DispositionStatus: string;
  LstTrnsSurveyDate: string;
  IsSingleFAM: boolean;
  TotalCount: number;
  ErrorContactNameEmail:boolean;
  ErrorDivisionCode:boolean;
  ErrorContactName:boolean;
  ErrorBrandName:boolean;
  ErrorCountryName:boolean;
}
export interface LostBusinessTotalContactDrilldownResponse {
  status: string;
  errors: any[];
  Response: {
    pageCount: number;
    pageSize: number;
    totalRecords: number;
    currentPageNo: number;
    collection: LostBusinessTotalContactDrilldown[];
  }
}

/**Code for State management */
export enum LbDdNav1State {
  A1,
  A2,
  A3,
  UNKNOWN
}
export enum LbDdNav2State {
  B1,
  B2,
  UNKNOWN
}
export enum LbDdNav3State {
  C1,
  C2,
  UNKNOWN
}
export enum TrendDrilldownState {
  BASE,
  LOST_1,
  LOST_2,
  WIN_1,
  WIN_2,

  CONTACT_WIN,
  CONTACT_LOSE
}


export class LostBusinessStateModel {
  Id: LbDdNav1State | LbDdNav2State |LbDdNav3State | TrendDrilldownState;
  Next: string | null;
  // Data: Object | number | string | boolean | null;
  Data: any | null;
  IsActive: boolean;
  Previous: string | null;

  constructor() {
    this.Previous = '';
    this.Next = '';
    this.IsActive = false;
    this.Id = LbDdNav1State.UNKNOWN;
    this.Data = null;

    this.Previous = '';
    this.Next = '';
    this.IsActive = false;
    this.Id = LbDdNav2State.UNKNOWN;
    this.Data = null;

    this.Previous = '';
    this.Next = '';
    this.IsActive = false;
    this.Id = LbDdNav3State.UNKNOWN;
    this.Data = null;
  }
}
