import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import * as _ from 'lodash';
import { ConfirmationService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { Subject, timer } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CtlsSpinnerService } from 'src/app/Modules/ctls/ctls-shared/ctls-components/ctls-spinner/ctls-spinner.service';
import { CtlsCategoryMasterService } from '../../../../ctls-shared/ctls-services/ctls-category-master.service';
import { Message } from 'primeng/components/common/api';
import { CtlsUnavailabiltyListService } from 'src/app/Modules/ctls/ctls-shared/ctls-services/ctls-unavailabilty-list.service';
import moment = require('moment');
import { CtlsTaskListingService } from 'src/app/Modules/ctls/ctls-shared/ctls-services';


@Component({
  selector: 'app-ctls-add-assign',
  templateUrl: './ctls-add-assign.component.html',
  styleUrls: ['./ctls-add-assign.component.scss']
})
export class CtlsAddAssignComponent implements OnInit {

  destroy$ = new Subject();
  recommendedAction: any;
  width: number;
  height: number;
  addAssignForm: FormGroup;
  saveUpdate: string;
  popUpHeader = 'Assign Task';
  successMsg: boolean = false;
  MessageTimeOut = 10000;
  msgs: Message[] = [];
  submitted= false;
  BAs:any;
  FAMs:any;
  Divs:any;
  Brands:any;
  assigneeNames:any;
  userRole: any;
  IsMultipleFAMBADIV: any;
  divIdToString: any;
  brandIdToString: string;
  objDataFromListingTable: any;
  objDataDivId: any;
  constructor(
    public config: DynamicDialogConfig,
    public ref: ChangeDetectorRef,
    private fb: FormBuilder,
    private spinner: CtlsSpinnerService,
    public dynamicDialogRef: DynamicDialogRef,
    private TaskListingService: CtlsTaskListingService,

  ) { 

  }

  ngOnInit() { 
    this.objDataFromListingTable=this.config.data.objData;
    this.objDataDivId=this.config.data.objData.DIVID;
    this.userRole=this.config.data.userRole;
    this.IsMultipleFAMBADIV=this.config.data.IsMultipleFAMBADIV;
    this.saveUpdate="Save"
    /**
    * initialize form
    */
    this.addAssignForm = this.fb.group({
      strBA: [null, Validators.required],
      strFAM:[null, Validators.required],
      strAssignee: [null, Validators.required],
      strDIV:[null,Validators.required],
      strBrand:[null,Validators.required],
    });
    this.loadBA()
  }

  get form() {
    return this.addAssignForm.controls;
  }
  
  
  
  loadBA(){   
    this.spinner.show()
    const BAs = this.TaskListingService.getBA();
    BAs.pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!!data.Response ) {
         this.BAs=data.Response;    
         if(this.userRole=='USER' || (!this.IsMultipleFAMBADIV && this.userRole=='CHAMPION') ||(!this.IsMultipleFAMBADIV && this.userRole=='CHAMPION ASSISTANT')){
          this.addAssignForm.controls.strBA.setValue(this.BAs[0])
          this.onBAChange();
         } else{
          this.spinner.hide()
         } 
        } 
      });
  }

  onBAChange(event?) {
    this.spinner.show()
    this.FAMs = [];
    this.Divs = [];
    this.Brands = [];
    this.assigneeNames = [];
    this.form.strFAM.reset();
    this.form.strDIV.reset();
    this.form.strBrand.reset();
    this.form.strAssignee.reset();   
    if (!!this.form.strBA.value) {
      let dataObj={BAID:this.form.strBA.value.BAID}
      this.TaskListingService
        .getFAMForUnAssigned(dataObj)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
         this.FAMs=res.Response;
         if(this.userRole=='USER' || (!this.IsMultipleFAMBADIV && this.userRole=='CHAMPION') ||(!this.IsMultipleFAMBADIV && this.userRole=='CHAMPION ASSISTANT')){
          this.addAssignForm.controls.strFAM.setValue(this.FAMs[0])
          this.onFAMChange();
         } else{
          this.spinner.hide()

         }
        });
    }
  }

  onFAMChange(event?) { 
    this.spinner.show()

    this.Divs = [];
    this.Brands = [];
    this.assigneeNames = [];
    this.form.strDIV.reset();
    this.form.strBrand.reset();
    this.form.strAssignee.reset();   
    if (!!this.form.strFAM.value && !!this.form.strBA.value){
      let dataObj={BA:this.form.strBA.value.BAID,FAM:this.form.strFAM.value.FAMID}
      this.TaskListingService
        .getDIVUnAssigned(dataObj)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
         this.Divs=res.Response;
         if(this.userRole=='USER'){ 
          if(this.Divs && this.Divs.length > 0) {
            this.addAssignForm.controls.strDIV.setValue([this.Divs[0]])
            this.onDIVChange();
          }
         } 
        });
    }
    this.spinner.hide()
  }
 
  onDIVChange(event?) { 
    this.spinner.show()
    this.Brands = [];
    this.assigneeNames = [];
    this.form.strBrand.reset();
    this.form.strAssignee.reset();   
    if (!!this.form.strFAM.value && !!this.form.strBA.value &&!!this.form.strDIV.value) {
      const divIds = [];
      this.divIdToString = '';  
      for (let index = 0; index < this.form.strDIV.value.length; index++) {
        const element = this.form.strDIV.value[index];
        if(element && element.DivisionID) {
          divIds.push(element.DivisionID);
          this.divIdToString = divIds.toString();
        }   
      } 
      if(divIds && divIds.length > 0){
        let dataObj={BA:this.form.strBA.value.BAID,FAM:this.form.strFAM.value.FAMID,div:divIds}
        this.TaskListingService
          .getBrand(dataObj)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
          this.Brands=res.Response;
          if(this.userRole=='USER'){
            this.addAssignForm.controls.strBrand.setValue(this.Brands[0])
            this.onBrandChange();
          } else{
            this.spinner.hide()
          }
        });
      } else {
        this.spinner.hide()
      }
    } else {
      this.spinner.hide()
    }
  }
 
  onBrandChange(event?) { 
     this.spinner.show()
    this.assigneeNames = [];
    this.form.strAssignee.reset();   
    if (!!this.form.strFAM.value && !!this.form.strBA.value &&!!this.form.strDIV.value&&!!this.form.strBrand.value) {
      let dataObj={BA:this.form.strBA.value.BAID,FAM:this.form.strFAM.value.FAMID,
        div:this.divIdToString,
        BrandID:this.form.strBrand.value.BrandID}
      this.TaskListingService
        .getAssignees(dataObj)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
         this.assigneeNames=res.Response;
         if(this.userRole=='USER'){
          this.addAssignForm.controls.strAssignee.setValue(this.assigneeNames[0])
          this.addAssignForm.controls['strAssignee'].disable();
         } 
        });
    }
    this.spinner.hide()
  }
 



  /**
   * Save Category and Api integration
   */
   onSubmit() {
    this.submitted=true;
    if (this.addAssignForm.valid) {
      var TaskIDArray=[];
      if(this.config.data.operation == 'FROM_BUTTON_CLICK'){
        this.config.data.objData.map((data) => {
          TaskIDArray.push(data.TaskID)
        })
      }
      let saveObj = {
        CTLSTaskListID :this.config.data.operation == 'FROM_BUTTON_CLICK'?TaskIDArray:[this.config.data.objData.TaskID],
        BAID: this.addAssignForm.controls.strBA.value.BAID,
        FAMID: this.addAssignForm.controls.strFAM.value.FAMID,       
        AssigneeID: this.addAssignForm.controls.strAssignee.value? this.addAssignForm.controls.strAssignee.value.CTLSAssigneeMappingID:null,
         BrandID  :this.addAssignForm.controls.strBrand.value?this.addAssignForm.controls.strBrand.value.BrandID:null,
         DIVID :this.objDataFromListingTable?this.objDataDivId:null 
      }
      this.spinner.show();
      this.TaskListingService
        .SaveAssign(saveObj)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          if (result.Status == 'SUCCESS') {
            this.dynamicDialogRef.close(result);
          } else {
            this.successMsg = true;
            this.callErrorMsg('error', '', result.Response); 
            this.spinner.hide();
 
          }
        });
      }
    }
  

 
  /**
   * close button click
   */
  cancel() {
    this,this.addAssignForm.reset();
    this.dynamicDialogRef.close(null);
  }
 
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () {
      document.getElementById('ClearMsg').click();
    }, this.MessageTimeOut);
  }

  /**
* Clear Message => triggered from HTML Button Click
*/
clearMessage() {
   this.msgs = [];
  this.successMsg = false;  
}

}
