import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CtlsSurveyOptionsComponent } from './ctls-survey-options.component';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    RadioButtonModule,
    FormsModule,
    TooltipModule,
  ],
  declarations: [CtlsSurveyOptionsComponent],
  exports: [CtlsSurveyOptionsComponent],
})
export class CtlsSurveyOptionModule {}
