import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { CreateSurveyService } from '../services/create-survey.service';

@Component({
  selector: 'app-pending-info-survey',
  templateUrl: './pending-info-survey.component.html',
  styleUrls: ['./pending-info-survey.component.scss']
})
export class PendingInfoSurveyComponent implements OnInit {

  constructor(public router: Router, public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private _createSurveyService: CreateSurveyService) { }

  surveId: number = 0;
  lineItem:any = null;

  // pageNoTemp: any = 0;
  // pageSizeTemp: any = 0;
  // totalRecordsTemp: any = [];
  // colsTemp; any = [];
  // loadingTemp: boolean = false;
  // configRowTemp: number[] = [10, 20, 30, 40, 50];
  // paginatorFirstSelectorTemp: any;
  // paginatorLaststSelectorTemp: any;
  // paginatorNextSelectorTemp: any;
  // paginatorPrevSelectorTemp: any;
  dataNotFoundMessageTemp = "No template(s) pending for translation.";
  headerTemp = "Template Translation Pending";
  // dataTemp = [];
  responseObject: any = {};
  colsTemp = []

  responseObjectForAuthorityPending: any = {};
  colsAuthorityPending = [];

  responseObjectForAuthorityNotCreated: any = {};
  colsAuthorityNotCreated = [];

  responseObjectForBrandPending: any = {};
  colsBrandPending = [];

  responseObjectForDesignPending: any = {};
  colsDesignPending = [];

  responseObjectForDesignNotCreated: any = {};
  colsDesignNotCreated = [];

  ngOnInit() {
    this.surveId = this.config.data.surveyid;
    this.lineItem = this.config.data.LineItem;

    this.colsTemp = [{ field: 'TemplateName', header: 'Name' },
    { field: 'Language', header: 'Language' }];

    //this.loadDataFromServer({ pageNo: 1, pageSize: 10, gridName: "template" });

    this.colsAuthorityPending = [{ field: 'AuthorityName', header: 'Name' },
    { field: 'BA', header: 'BA' },
    { field: 'DIV', header: 'DIV' },
    { field: 'FAM', header: 'FAM' },
    { field: 'Brand', header: 'Brand' }
    ];
    //this.loadDataFromServer({ pageNo: 1, pageSize: 10, gridName: "authorityPending" });


    this.colsAuthorityNotCreated = [{ field: 'BA', header: 'BA' },
    { field: 'FAM', header: 'FAM' }
    ];
    //this.loadDataFromServer({ pageNo: 1, pageSize: 10, gridName: "authorityNotCreated" });

    this.colsBrandPending = [{ field: 'Brand', header: 'Brand' }];
    //this.loadDataFromServer({ pageNo: 1, pageSize: 10, gridName: "brandPending" });

    this.colsDesignPending = [{ field: 'BA', header: 'BA' },
    { field: 'DIV', header: 'DIV' },
    { field: 'FAM', header: 'FAM' },
    { field: 'Language', header: 'Language' }];
    //this.loadDataFromServer({ pageNo: 1, pageSize: 10, gridName: "designPending" });

    this.colsDesignNotCreated = [{ field: 'BA', header: 'BA' },
    { field: 'DIV', header: 'DIV' },
    { field: 'FAM', header: 'FAM' }
    ];
    //this.loadDataFromServer({ pageNo: 1, pageSize: 10, gridName: "designNotCreated" });
    this.getPendingTemplateInfoForSurvey();
  }

  paginatorFirstSelectorTemp;
  paginatorLaststSelectorTemp;
  paginatorNextSelectorTemp;
  paginatorPrevSelectorTemp;

  ngAfterViewInit() {
    this.paginatorFirstSelectorTemp = <HTMLElement>document.querySelector("a.ui-paginator-first");
    this.paginatorFirstSelectorTemp.title = 'First';

    this.paginatorLaststSelectorTemp = <HTMLElement>document.querySelector("a.ui-paginator-last");
    this.paginatorLaststSelectorTemp.title = 'Last';

    this.paginatorNextSelectorTemp = <HTMLElement>document.querySelector("a.ui-paginator-next");
    this.paginatorNextSelectorTemp.title = 'Next';

    this.paginatorPrevSelectorTemp = <HTMLElement>document.querySelector("a.ui-paginator-prev");
    this.paginatorPrevSelectorTemp.title = 'Previous';

  }


  loadDataFromServer(event) {

    switch (event.gridName) {
      case "template": {
        this._createSurveyService.getPendingTemplateInfoForSurvey(this.surveId, event.pageNo, event.pageSize, this.lineItem).then(res => {
          if (res != null && res.Status == "Success" && res.Response != null) {
            this.responseObject = res.Response;
          }
    
        }).catch(e => {
          // this.dataTemp = null; 
        });
        break;
      }
      case "authorityPending": {
        this._createSurveyService.getAuthorityTranslationPendingInfoForSurvey(this.surveId, event.pageNo, event.pageSize, this.lineItem).then(res => {
          if (res != null && res.Status == "Success" && res.Response != null) {
            this.responseObjectForAuthorityPending = res.Response;
          }
    
        }).catch(e => {
          // this.dataTemp = null; 
        });
        break;
      }
      case "authorityNotCreated": {
        this._createSurveyService.getAuthorityNotCreatedInfoForSurvey(this.surveId, event.pageNo, event.pageSize, this.lineItem).then(res => {
          if (res != null && res.Status == "Success" && res.Response != null) {
            this.responseObjectForAuthorityNotCreated = res.Response;
          }
    
        }).catch(e => {
          // this.dataTemp = null; 
        });
        break;
      }
      case "brandPending": {
        this._createSurveyService.getBrandDesignPendingInfoForSurvey(this.surveId, event.pageNo, event.pageSize, this.lineItem).then(res => {
          if (res != null && res.Status == "Success" && res.Response != null) {
            this.responseObjectForBrandPending = res.Response;
          }
    
        }).catch(e => {
          // this.dataTemp = null; 
        });
        break;
      }
      case "designPending": {
        this._createSurveyService.getEmailAndQuestionnaireContentTranslationPendingInfoForSurvey(this.surveId, event.pageNo, event.pageSize, this.lineItem).then(res => {
          if (res != null && res.Status == "Success" && res.Response != null) {
            this.responseObjectForDesignPending = res.Response;
          }
    
        }).catch(e => {
          // this.dataTemp = null; 
        });
        break;
      }
      case "designNotCreated": {
        this._createSurveyService.getEmailAndQuestionnaireContentNotCreatedInfoForSurvey(this.surveId, event.pageNo, event.pageSize, this.lineItem).then(res => {
          if (res != null && res.Status == "Success" && res.Response != null) {

            this.responseObjectForDesignNotCreated = res.Response;
          }
    
        }).catch(e => {
          // this.dataTemp = null; 
        });
        break;
      }
    }    
  }

  getPendingTemplateInfoForSurvey(){
    this._createSurveyService.getPendingTemplateInfoForSurvey(this.surveId, 1, 10, this.lineItem).then(res => {
      if (res != null && res.Status == "Success" && res.Response != null) {
        this.responseObject = res.Response;
        this.getAuthorityTranslationPendingInfoForSurvey();
      }

    }).catch(e => {
      // this.dataTemp = null; 
    });
  }
  getAuthorityTranslationPendingInfoForSurvey(){
    this._createSurveyService.getAuthorityTranslationPendingInfoForSurvey(this.surveId, 1, 10, this.lineItem).then(res => {
      if (res != null && res.Status == "Success" && res.Response != null) {
        this.responseObjectForAuthorityPending = res.Response;
        this.getAuthorityNotCreatedInfoForSurvey();
      }
  
    }).catch(e => {
      // this.dataTemp = null; 
    });
  }
  getAuthorityNotCreatedInfoForSurvey(){
    this._createSurveyService.getAuthorityNotCreatedInfoForSurvey(this.surveId, 1, 10, this.lineItem).then(res => {
      if (res != null && res.Status == "Success" && res.Response != null) {
        this.responseObjectForAuthorityNotCreated = res.Response;
        this.getBrandDesignPendingInfoForSurvey();
      }
  
    }).catch(e => {
      // this.dataTemp = null; 
    });
  }
  getBrandDesignPendingInfoForSurvey(){
    this._createSurveyService.getBrandDesignPendingInfoForSurvey(this.surveId, 1, 10, this.lineItem).then(res => {
      if (res != null && res.Status == "Success" && res.Response != null) {
        this.responseObjectForBrandPending = res.Response;
        this.getEmailAndQuestionnaireContentTranslationPendingInfoForSurvey();
      }
  
    }).catch(e => {
      // this.dataTemp = null; 
    });
  }
  getEmailAndQuestionnaireContentTranslationPendingInfoForSurvey(){
    this._createSurveyService.getEmailAndQuestionnaireContentTranslationPendingInfoForSurvey(this.surveId, 1, 10, this.lineItem).then(res => {
      if (res != null && res.Status == "Success" && res.Response != null) {
        this.responseObjectForDesignPending = res.Response;
        this.getEmailAndQuestionnaireContentNotCreatedInfoForSurvey();
      }
  
    }).catch(e => {
      // this.dataTemp = null; 
    });
  }
  getEmailAndQuestionnaireContentNotCreatedInfoForSurvey(){
    this._createSurveyService.getEmailAndQuestionnaireContentNotCreatedInfoForSurvey(this.surveId, 1, 10, this.lineItem).then(res => {
      if (res != null && res.Status == "Success" && res.Response != null) {
       
        this.responseObjectForDesignNotCreated = res.Response;

      }  
    }).catch(e => {
      // this.dataTemp = null; 
    });
  }
  
  
}
