import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-total-response-chart',
  templateUrl: './total-response-chart.component.html',
  styleUrls: ['./total-response-chart.component.scss'],
  inputs: [
    'height',
    'width',
    'margin',
    'middleText',
    'middleText2',
    'middleText3',
    'chartData',
    'colors',
    'CompletedCountData'
  ]
})
export class TotalResponseChartComponent implements OnInit {
  @Input() chartData: any;
  colors: any;
  public highcharts: any;
  public chartConstructor: string;
  public chartOptions: any;
  public updateFlag = false; // optional boolean
  public oneToOneFlag = true; // optional boolean, defaults to false
  public runOutsideAngular = false; // optional boolean, defaults to false
  constructor() { }
 
  ngOnInit() {
    this.drawHighChart();
    console.log(this.chartData)
  }
  drawHighChart() {
    this.highcharts = Highcharts; // required
    this.chartConstructor = 'chart'; // optional string, defaults to 'chart'
   
    // let xaxis = ["Yes", "No", "Needs Improvement", "Not Applicable"];
    // let yaxis = [{y:0, color:'#69BF55'},{y:0, color:'#D24051'},{y:0, color:'#FFBF33'},{y:0, color:'#B2B2B2'}]
    // for(var i=0; i<this.chartData.length; i++){
    //     if(xaxis.indexOf(this.chartData[i]["OptionAns"]) > -1){
    //         yaxis[xaxis.indexOf(this.chartData[i]["OptionAns"])]["y"] = (this.chartData[i]["ValuePerc"] == null) ? 0 : (this.chartData[i]["ValuePerc"]);
    //     }
    let xaxis = [];
    let yaxis = [];
       for(var i=0; i<this.chartData.length; i++){
xaxis.push(this.chartData[i].OptionAns);
yaxis.push(this.chartData[i].ValuePerc)
    } 
    this.chartOptions = {
      chart: {
        backgroundColor: '#EDF8FC',
      },
      title: {
          text: ''
      },
      subtitle: {
          text: ''
      },
      xAxis: {
          categories: xaxis,
          title: {
              text: null
          }
      },
      yAxis: {
          title: {
              text: '',
              align: 'high'
          },
          labels: {
              overflow: 'justify',
              format: '{value}%',
          },
          min: 0, 
          softMax:1,
          fontWeight : "normal"
      },
      tooltip: {
          valueSuffix: '%',
          followPointer : true,
          formatter: function () {
                return '<p style = "font-size: 20px">' + this.x + ":" +'</p>' + '<br> <p style ="font-size:20px;font-weight:900">' + this.y  + '%</p>';}
      },
      legend: {
        enabled: false,
        
    },
      plotOptions: {
          bar: {
              dataLabels: {
                  enabled: true,
                 
                  style: {
                    fontSize: '11px',
                    fontWeight: 'normal',
                    color: '#333'
                    , textOutline : 'false'
                  }
              }
          },
          series: {
            pointWidth: 16,
            colorByPoint :true,
        }
      },
      credits: {
          enabled: false
      },
      exporting :{
        buttons:{
          contextButton: {
              enabled: false
          }
        } 
      },
      series: [{
          name: '', 
          data: yaxis,
          dataLabels: [{
            format: '{y}%',
          }],
          type: 'bar'
      }]
    };
  }

  chartCallback(chart) {} // optional function, defaults to null
}
