import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CtlsAddRuleSetService {
  private serviceUrl = environment.baseUrl;
  filterObj = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: true,
    Influencer: false,
    ServiceTechnician: false,
    Continent: true,
    Region: true,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: '',
    screenCode: 'CTLS_CATEGORY_MASTER', //SELECTED SCREEN
    sessionStorageName: 'CTLS_CATEGORY_MASTER',
    savedFilterSessionStorageName: 'CTLS_CATEGORY_MASTER_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: [
      'Status',
      'IsDeleteEnable'
    ],
  };
  selectedRuleSetForAdd: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedRuleSetForEdit: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedRuleSetForClone: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  objStatusResponse: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  /**Service for save  modified columns
   * @param dataObj
   */
  SaveModifyColumns(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/GridColumn/SaveModifyColumn`,
      dataObj
    );
  }

  /**Service for get  modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj) {
    return this.http.get<any>(
      `${this.serviceUrl}api/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
          SubCategoryCode: dataObj.SubCategoryCode,
        },
      }
    );
  }
  /**Service for get rule list for popupTable data from API service
  * @param dataObj
  */
  getRuleListForPopUp(selectedRadioOption, filterObj) {
    let apiUrl = (selectedRadioOption === 1) ? 'GetTransactionalRuleList' : 'GetRelationshipRuleList';
    return this.http.post<any>(
      `${this.serviceUrl}api/RuleSet/${apiUrl}`, filterObj
    );
  }
  //http://tpfalcon1qa.centralindia.cloudapp.azure.com:1000/api/RuleSetup/GetTransactionalRulesByID?CTLSRuleID=243
  //http://tpfalcon1qa.centralindia.cloudapp.azure.com/api/RuleSet/GetTransactionalRuleSet
  /**Service for get rule list for popupTable data from API service
  * @param dataObj
  */
  getRuleListByID(surveyType, ID) {
    let apiUrl = (surveyType.toUpperCase() === 'TRANS') ? 'GetTransactionalRulesByID' : 'GetRelationshipRulesByID';

    console.log("Sree", apiUrl,ID,`${this.serviceUrl}api/RuleSetup/${apiUrl}?CTLSRuleID=${ID}`)
  /*   try {
      return this.http.post<any>(
        `${this.serviceUrl}api/RuleSetup/${apiUrl}?CTLSRuleID=${ID}`, {}
      );
    } catch (e) {
      console.log("Sree", e)
    } */

    return this.http.get<any>(
      `${this.serviceUrl}api/RuleSetup/${apiUrl}?CTLSRuleID=${ID}`, {}
    );

  }

  getRuleSetListByID(surveyType, ID) {
    let apiUrl = (surveyType.toUpperCase() === 'TRANS') ? 'GetTransactionalRuleSetByID' : 'GetRelationshipRuleSetByID';

  /*   try {
      return this.http.post<any>(
        `${this.serviceUrl}api/RuleSetup/${apiUrl}?CTLSRuleID=${ID}`, {}
      );
    } catch (e) {
      console.log("Sree", e)
    } */

    return this.http.post<any>(
      `${this.serviceUrl}api/RuleSet/${apiUrl}?CTLSRuleSetID=${ID}`, {}
    );

  }

  /**Service for get  rule set by id
  * @param dataObj
  */
  getRuleByID(id) {
    return this.http.post<any>(
      `${this.serviceUrl}api/RuleSet/GetRuleByID?RuleSetID=${id}`, {}
    );
  }
  /**Service for get  fam  dropdown list
   * @param dataObj
   */
  getFAMDetails(BAID, SurveyIDs) {
    return this.http.get<any>(
      `${this.serviceUrl}api/RuleSet/GetFAMDetails?BAIDs=${BAID}&SurveyIDs=${SurveyIDs}`,
      //
    );
  }
  /**Service for get  ba  dropdown list
  * @param dataObj
  */
  getBADetails(SurveyIDs) {
    return this.http.get<any>(
      `${this.serviceUrl}api/RuleSet/GetBADetails?SurveyIDs=${SurveyIDs}`,
    );
  }
  /**Service for get  div  dropdown list
  * @param dataObj
  */
  getDivisions(reqObj) {
    return this.http.post<any>(
      `${this.serviceUrl}api/RuleSet/GetDivisions`,
      reqObj
    );
  }
  /**
  *
  * @param param selected rows from eule setup page
  */
  public changeParamForAdd(param: any): void {
    this.selectedRuleSetForAdd.next(Object.assign([], param));
  }
  /**
 *
 * @param param selected rows from eule setup page
 */
  public changeParamForEdit(param: any): void {
    this.selectedRuleSetForEdit.next(Object.assign([], param));
  }
  /**
 *
 * @param param selected rows from eule setup page
 */
  public changeParamForClone(param: any): void {
    this.selectedRuleSetForClone.next(Object.assign([], param));
  }
  /** 
   *   * @param param selected rows from eule setup page
  */
  public changeResponse(param: any): void {
    this.objStatusResponse.next(Object.assign([], param));
  }
  /**Service for save  rulset
  * @param dataObj
  */
  SaveRuleSet(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/RuleSet/SaveRuleSet`,
      dataObj
    );
  }
}
