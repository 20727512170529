const partialUrl = 'dist/assets/OnlineHelp/html/index.htm#t=';
export const OnlineHelp = [
  {
    pageUrl: '/surveys/test-survey',
    elementKey: 'TS_SEND_A_TEST_SURVEY',
    redirect: partialUrl + 'Send_a_Test_Survey.htm'
  },
  {
    pageUrl: '/surveys/test-survey',
    elementKey: 'TS_SEARCH_A_SURVEY_BY_NAME',
    redirect: partialUrl + 'Search_a_Survey_by_Name.htm'
  },
  {
    pageUrl: '/surveys/test-survey',
    elementKey: 'TS_FILTER_THE_LIST_VIEW',
    redirect: partialUrl + 'Filter_the_List_View.htm#clearfilter'
  },
  {
    pageUrl: '/surveys/test-survey',
    elementKey: 'TS_ABOUT_THE_MANAGE_SURVEY_PAGE',
    redirect: partialUrl + 'About_the_Manage_Survey_Page.htm'
  }
];
