import * as Highcharts from 'highcharts';

export const ChartConfigs = {
    WinColor: '#44bcee',
    LoseColor: '#df7833',
    MaxPointWidth: 35,
    PLACEHOLDER: '%_&_|_&_%',
    CONTINUED_HOVER_TEXT: '%_&_|_&_% continued to do business',
    DISCONTINUED_HOVER_TEXT: '%_&_|_&_% did not continue to do business'
};

export const ChartOptions: Highcharts.Options = {
    chart: {
        marginBottom: 90,
        panning: true,
    },
    exporting: {
        sourceWidth: 1000,
        sourceHeight: 450,
        chartOptions: {
            chart: {
                marginBottom: 90,
                height: '345px'
            }
        }
    },
    navigation: {
        buttonOptions: {
            enabled: false
        }
    },
    mapNavigation: {
        enabled: true,
        enableDoubleClickZoom: true,
        buttonOptions: {
            theme: {
                fill: 'white',
                'stroke-width': 1,
                stroke: 'silver'
            },
            verticalAlign: 'top',
            align: "right",
            alignTo: "spacingBox",
            style: {
                fontSize: "10px"
            },
            width: 10,
            height: 10,
            padding: 7
        }
    },
    title: {
        text: ''
        // text: null
        , verticalAlign: 'top',
        align: 'left',
        style: {
            fontFamily: 'Source Sans Pro Regular',
            fontSize: '1.3em',
            fontWeight: 'bold',
            color: '#333333'
        }
    },
    // xAxis: {
    // labels: {
    //     style: {
    //         fontFamily: 'Source Sans Pro Regular',
    //         fontSize: '10px',
    //         color: '#333333',
    //         fontWeight: 'bold'
    //     }
    // }
    // },
    // yAxis: [{
    //     lineWidth: 1,
    //     title: {
    //         text: 'Primary Axis'
    //     }
    // }, {
    //     lineWidth: 1,
    //     opposite: true,
    //     title: {
    //         text: 'Secondary Axis'
    //     }
    // }],
    // yAxis: [{
    //     labels: { format: '{value}' },
    //     opposite: true,
    //     title: { text: 'Axis 1' },
    //     lineWidth: 1,
    //     stackLabels: { enabled: true }
    //   }, {
    //     labels: { format: '{value}' },
    //     title: { text: 'Axis 2' },
    //     lineWidth: 1,
    //     stackLabels: { enabled: true }
    //   }],
    yAxis: {
        title: {
            text: null
        },
        labels: {
            formatter: function () {
                var s = Math.abs(this.value);
                if (parseInt(s.toFixed(0)) >= 1000000) {
                    return parseInt(s.toFixed(0)) / 1000000 + 'M';
                } else {
                    return parseInt(s.toFixed(0)) / 1000 + 'K';
                }
            }
        }
    },
    legend: {
        enabled: true,
        borderWidth: 0,
        align: 'right',
        verticalAlign: 'top',
        y: -8,
        x: -40,
        itemStyle: {
            fontFamily: 'Source Sans Pro Regular',
            fontSize: '10px',
            fontWeight: 'normal',
            color: '#666666'
        },
        symbolHeight: 10
    },
    credits: {
        enabled: false
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false,
                borderRadius: 0,
                color: '#333333',
                borderWidth: 0,
                style: {
                    fontFamily: 'Source Sans Pro Regular',
                    textShadow: false,
                    textOutline: 'false',
                    fontSize: '10px'
                }
            },
            maxPointWidth: 35
        },

        series: {
            dataLabels: {
            },
            events: {
            },
            states: {
                inactive: {
                    opacity: 1
                }
            }
        }
    },
    // series: JSON.parse(JSON.stringify(changes.data.currentValue.yAxis))
    // series: JSON.parse(JSON.stringify(DummyParsedData.yAxis))
};

export const POC_OPTIONS = {
    "mapNavigation": {
      "enabled": true,
      "enableDoubleClickZoom": true,
      "buttonOptions": {
        "theme": {
          "fill": "white",
          "stroke-width": 1,
          "stroke": "silver"
        },
        "verticalAlign": "top",
        "align": "right",
        "alignTo": "spacingBox",
        "style": {
          "fontSize": "10px"
        },
        "width": 10,
        "height": 10,
        "padding": 7
      }
    },
    "navigation": {
      "buttonOptions": {
        "enabled": false
      }
    },
    "chart": {
      "type": "bar",
      "panning": true,
      "resetZoomButton": {
        "theme": {
          "display": "none"
        }
      }
    },
    "legend": {
      "align": "right",
      itemStyle: {
        fontFamily: 'Source Sans Pro Regular',
        fontSize: '10px',
        fontWeight: 'normal',
        color: '#666666'
      },
      symbolHeight: 10
    },
    "plotOptions": {
      "series": {
        "cursor": "pointer",
        "stacking": "normal",
        "states": {
            "hover": {
                "enabled": false
            }
        }
      }
    },
    "yAxis": {
      "allowDecimals": false,
      "labels": {
      },
      "title": {
        "text": null
      }
    },
    "tooltip": {
        "useHTML": true
    },
    "credits": {
      "enabled": false
    },
    series: [],
    // "series": [
    //   {
    //     "name": "Customers did not continue to do business",
    //     "data": [
    //       -96459,
    //       -96446
    //     ],
    //     "color": "#FF0000",
    //     "maxPointWidth": 35
    //   },
    //   {
    //     "name": "Customers continued to do business",
    //     "data": [
    //       5750,
    //       45577
    //     ],
    //     "color": "#00FF00",
    //     "maxPointWidth": 35
    //   }
    // ],
    // "xAxis": [
    //   {
    //     "categories": [
    //       "Dec 2019",
    //       "Nov 2019"
    //     ],
    //     "crosshair": true,
    //     "labels": {
    //       "step": 1
    //     },
    //     "index": 0,
    //     "isX": true
    //   },
    //   {
    //     "linkedTo": 0,
    //     "opposite": true,
    //     "categories": [
    //       "Dec 2019",
    //       "Nov 2019"
    //     ],
    //     "labels": {
    //       "step": 1
    //     },
    //     "index": 1,
    //     "isX": true
    //   }
    // ],
    "title": {
      "text": ""
    }
  };

export const DummyExpectedOptions = {
    mapNavigation: {
        enabled: true,
        enableDoubleClickZoom: true,
        buttonOptions: {
            theme: {
                fill: 'white',
                'stroke-width': 1,
                stroke: 'silver'
            },
            verticalAlign: 'top',
            align: "right",
            alignTo: "spacingBox",
            style: {
                fontSize: "10px"
            },
            width: 10,
            height: 10,
            padding: 7
        }
    },
    navigation: {
        buttonOptions: {
            enabled: false
        }
    },
    chart: {
        type: 'bar',
        // width: 900,
        // width: 1000,
        height: 450,
        panning: true,
        resetZoomButton: {
            theme: {
                display: 'none'
            }
        }
    },
    legend: {
        align: 'right',
        x: -70
    },
    plotOptions: {
        series: {
            cursor: 'pointer',
            stacking: 'normal',
            // events: {
            //     click: null
            // },
            states: {
                hover: {
                    enabled: false
                }
            }
        }
    },
    yAxis: {},
    tooltip: {
        useHTML: true
    },
    credits: {
        enabled: false
    },
    title: {
        text: ''
    }
};
