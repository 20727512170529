import { Injectable } from '@angular/core';
import * as Rx from "rxjs";
import { RelationshipDashboardSharedService } from './relationship-dashboard-shared.service';
import { Widget, WidgetMaster } from '../models/dashboard-models/dashbaord.models';
import { DashboardEvent } from '../enums/dashboard.event';

@Injectable({
  providedIn: 'root'
})
export class RelationshipDashboardSharedEventService {

  widgetPropertyChanged = new Rx.BehaviorSubject<WidgetMaster>(null);
  // widgetViewChanged = new Rx.BehaviorSubject<WidgetMaster>(null);
  // widgetClosed = new Rx.BehaviorSubject<WidgetMaster>(null);
  widgetCloseSubject = new Rx.BehaviorSubject<any>('widgetClose');

  widgetListMaster : WidgetMaster;

  constructor(private relationshipDashboardSharedService : RelationshipDashboardSharedService) { 
    this.widgetListMaster = relationshipDashboardSharedService.widgetListMaster;    
  }

  public constructSubList(widgetList: Array<Widget>, event : DashboardEvent){
    this.relationshipDashboardSharedService.constructSubList(widgetList, event);    
  }

  notifySubscriber(event : DashboardEvent){
    this.relationshipDashboardSharedService.widgetListMaster.Event = event;
    this.widgetPropertyChanged.next(this.relationshipDashboardSharedService.widgetListMaster);
  }

  getMasterWidget() : WidgetMaster {
    return this.relationshipDashboardSharedService.widgetListMaster;
  }

  getWidgetList() : Array<Widget> {
    return [...this.relationshipDashboardSharedService.widgetListMaster.LeftWidgetList,
      ...this.relationshipDashboardSharedService.widgetListMaster.CenterWidgetList,
      ...this.relationshipDashboardSharedService.widgetListMaster.DrillDownWidgetList];
  }

  closeWidget(widgetCode: string) {
    let widgetList = this.getWidgetList();
    widgetList.map(res => {
      if (res.WidgetCode == widgetCode) {
        res.IsVisible = false;
        res.IsSaved = false;
      }
      return res;
    });
    this.hideWidgets(widgetList, DashboardEvent.Closed);
     }

  hideWidgets(widgetList : Array<Widget>, event : DashboardEvent){
    this.relationshipDashboardSharedService.constructSubList(widgetList, event);    
    this.notifySubscriber(event);
  }

  saveWidgetList(){
    
  }
}
