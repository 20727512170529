import { Component, OnInit } from '@angular/core';
import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../oauth2.config';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss']
})
export class StartupComponent implements OnInit {

  constructor(private oauthService: OAuthService,private appConfig: SharedService) {
    console.log('inside startup');
    // this.appConfig.loadConfig();
    this.configureSingleSignOn();
    console.log('after startup');
   }

  ngOnInit() {

  }
  async configureSingleSignOn() {
    console.log('inside sign on');
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();    
    await this.oauthService.loadDiscoveryDocumentAndLogin();
  }

}
