import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[dynamicDivTitle]'
})
export class DynamicDivTitleDirective {

  constructor(private el: ElementRef) { }
  @HostListener('mouseenter') onMouseEnter() {
       
    var textSpan = <HTMLElement>this.el.nativeElement.querySelector('span.textSpan')
     if (textSpan != null) {
         if (textSpan.offsetParent.clientWidth < textSpan.offsetParent.scrollWidth) {
             textSpan.title = textSpan.innerText;
         }
         else {
             textSpan.title = "";
         }
     }
 }

 @HostListener('mouseleave') onMouseLeave() {
     //this.highlight(null);
 }   
}
