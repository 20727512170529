import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, ElementRef, ViewChild, Renderer2, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts/highstock'
import HC_map from 'highcharts/modules/map';
import HC_exporting from 'highcharts/modules/exporting'
import { ChartDataService } from '../../service/chart-data-service.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ChartDataParsing } from 'src/app/shared/utils/chart-data-parsing.util';
import { Subscription } from 'rxjs';
import { ExportChartsService } from 'src/app/shared/services/export-charts.service';
import { ExportChartModel, SlideTextData } from 'src/app/shared/models/export-chart.model';
HC_map(Highcharts);
import * as _html2canvas from "html2canvas";
const html2canvas:any = _html2canvas;
import { filter } from 'rxjs/operators';
import { CONTEXT_MENUES } from '../models/chart-binding.model';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import * as $ from 'jquery/dist/jquery.min.js';
import { environment } from 'src/environments/environment';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { style } from '@angular/animations';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
@Component({
  selector: 'app-stack-bar-chart',
  templateUrl: './stack-bar-chart.component.html',
  styleUrls: ['./stack-bar-chart.component.css']
})
export class StackBarChartComponent implements OnInit, OnChanges, OnDestroy {
  private imagepath = environment.imagepath;
  @ViewChild('pictureOf') pictureOf: ElementRef;
  @Input() data: any;
  @Input() chartTitle: string = '';
  @Input() isDualyAxes?: boolean = false;
  @Input() isTripleAxes?: boolean = false;
  @Input() isDrilldownEnabled?: boolean = false;
  @Input() isClick?: boolean = false;
  @Input() currentChartView?: string = '';
  // @Input() showContextMenu?: boolean = true;
  @Input() yStackLabelsEnabled: boolean = true;
  @Input() toolTipAppendStr: string = '';
  @Input() showLineLabels: boolean = false;
  @Input() revenue: any;
  @Input() symbol : any;
  @Input() previous: any;
  @Input() yearLabels: any;
  @Input() quarterLabels: any;

  @Output() contextMenuClicked = new EventEmitter<any>();

  @Input() chartIdentifier: string = '';
  @Output() unselectData = new EventEmitter();
  Highcharts: typeof Highcharts = Highcharts;
  chartInstance: any;
  regions = '';
  salesEngineers = '';
  serviceBranchs = '';
  serviceTechnicians = '';
  ProjectManagers = '';
  lastContextMenuClicked: string = CONTEXT_MENUES.UNKNOWN.toString();
  rrtTooltipData = [];
  chartType = null;
  showEmailList = false;
  emailList = [];
  columns = [];
  emailListHeader = null;
  showThirdLevelMenu = false;
  restWidth = 0;
  restWidthright = 0;
  selectedBarStr = null;
  selectedPointColor = null;
  // dummyChartInstance: any;
  private tooltipBrand = '';
  private tooltipCntry = '';
  private tooltipOperationalCntry
  private tooltipFAMCode = '';
  private exportSubscription: Subscription;
  private chartToggelSub: Subscription;
  private globFilterSub: Subscription;
  private barChartLableVisibility: boolean;
  private hideLegendText: string[] = [];
  private globalFilterData: any;

  // css classes bug: 44279
  secondMenuClasses: any[] = [];
  thirdMenuClasses: any[] = [];
  // variables to manage the chart context menu bug: 44279
  chartXTemp:number;
  isPreviousLeft: boolean;
  RRTGraphWidth: number;
  viewPeriod: string;
  tooltipLoader = false;
  drillDownOpened: false
  resetZoomButton: any;
  viewObj:any={};
  @Input() public chartState: CHART_STATE_OPTIONS;

  options:any = {
    title: {
      text: null
      , verticalAlign: 'top',
      align: 'left',
      style: {
        fontFamily: 'Source Sans Pro Regular',
        fontSize: '1.3em',
        fontWeight: 'bold',
        color: '#333333'
      }
    }
  };
  oneToOneFlag: boolean;
  // dummyOptions: Highcharts.Options = {};
  // enableDummyForExport: boolean = false;
  // isAlternateClick: boolean = true;
  regularyAxis: any = {
    // scrollbar: {
    //   enabled: true,
    //   showFull: false
    // },
    className: 'y-axis',
    labels: {
      format: '{value}%',
      style: {
        fontSize: '12px'
      },
    },
    title: {
      text: ''
    },
    // max: 100,
    stackLabels: {
      enabled: this.yStackLabelsEnabled,
      style: {
        fontFamily: 'Source Sans Pro Regular',
        fontWeight: 'normal',
        color: '#666666',
        textShadow: false,
        textOutline: 'false',
        fontSize: '10px'
      },
      styledMode: true
    },
    // max: 100,
    // minRange: 1,
    zoomEnabled: false
  };
  regularNPSTyAxis: any = {
    // scrollbar: {
    //   enabled: true,
    //   showFull: false
    // },
    className: 'y-axis',
    labels: {
      format: '{value}%',
      style: {
        fontSize: '12px'
      },
    },
    title: {
      text: ''
    },
    max: 100,
    stackLabels: {
      enabled: this.yStackLabelsEnabled,
      style: {
        fontFamily: 'Source Sans Pro Regular',
        fontWeight: 'normal',
        color: '#666666',
        textShadow: false,
        textOutline: 'false',
        fontSize: '10px'
      },
      styledMode: true
    },
    // max: 100,
    // minRange: 1,
    zoomEnabled: false
  };
  dualyAxis: any = [{
    // scrollbar: {
    //   enabled: true,
    //   showFull: false
    // },
    labels: {
      format: '{value}%',
      style: {
      fontSize: '12px'
      }
    },
    opposite: true,
    // minRange: 1,
    title: { text: '' },
    lineWidth: 1,
    stackLabels: { enabled: this.yStackLabelsEnabled },
    events: {
      setExtremes: function (e) {
        if (e && e.target) {
          if (e.target.oldUserMax !== e.target.dataMax || e.target.oldUserMin !== e.target.dataMin) {
            setTimeout(function () {
              e.target.chart.yAxis[0].setExtremes(e.target.dataMin, e.target.dataMax);
            });
          }
        }
      }
    }
  }, {
    // scrollbar: {
    //   enabled: true,
    //   showFull: false
    // },
    labels: {
      format: '{value}',
      style: {
        fontSize: '12px'
      } 
    },
    title: { text: '' },
    lineWidth: 1,
    stackLabels: { enabled: this.yStackLabelsEnabled },
    events: {
      setExtremes: function (e) {
        if (e && e.target) {
          if (e.target.oldUserMax !== e.target.dataMax || e.target.oldUserMin !== e.target.dataMin) {
            setTimeout(function () {
              e.target.chart.yAxis[0].setExtremes(e.target.dataMin, e.target.dataMax);
            });
          }
        }
      }
    }
  }];

  trippleAxis: any = [{
    min: 0,
    labels: {
      format: '{value}',
      style: {
        fontSize: '12px',
        color: Highcharts.getOptions().colors[1]
      }
    },
   //opposite: true,
    title: {
      text: 'Responses Received',
      style: {
        color: Highcharts.getOptions().colors[1]
      }
    },
    // minRange: 1,
    // max: 100,
    //lineWidth: 1,
   stackLabels: { enabled: false },
    events: {
      setExtremes: function (e) {
        if (e && e.target) {
          if (e.target.oldUserMax !== e.target.dataMax || e.target.oldUserMin !== e.target.dataMin) {
            setTimeout(function () {
              e.target.chart.yAxis[0].setExtremes(e.target.dataMin, e.target.dataMax);
            });
          }
        }
      }
    }
  }, {
    min: -100,
    max: 100,
    gridLineWidth: 0,
    endOnTick: false,
    title: {
      text: 'Net Promotor Score',
      style: {
        // color: Highcharts.getOptions().colors[0]
      }
    },
    // scrollbar: {
    //   enabled: true,
    //   showFull: false
    // },
    labels: {
      format: '{value}',
      style: {
        fontSize: '12px',
       // color: Highcharts.getOptions().colors[0]
      }
    },
    opposite: true,
    lineWidth: 1,
    stackLabels: { enabled: this.yStackLabelsEnabled },
    events: {
      setExtremes: function (e) {
        if (e && e.target) {
          if (e.target.oldUserMax !== e.target.dataMax || e.target.oldUserMin !== e.target.dataMin) {
            setTimeout(function () {
              e.target.chart.yAxis[0].setExtremes(e.target.dataMin, e.target.dataMax);
            });
          }
        }
      }
    }
  },
  {
    min: -5,
    max: 5,
    gridLineWidth: 0,
    endOnTick: false,
    title: {
      text: 'Net Sentiment Score',
      style: {
        //color: Highcharts.getOptions().colors[0]
      }
    },
    // scrollbar: {
    //   enabled: true,
    //   showFull: false
    // },
    labels: {
      format: '{value}',
      style: {
        fontSize: '12px',
        //color: Highcharts.getOptions().colors[0]
      }
    },
    opposite: true,
    lineWidth: 1,
    stackLabels: { enabled: this.yStackLabelsEnabled },
    tickPositions: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5],
    events: {
      setExtremes: function (e) {
        if (e && e.target) {
          if (e.target.oldUserMax !== e.target.dataMax || e.target.oldUserMin !== e.target.dataMin) {
            setTimeout(function () {
              e.target.chart.yAxis[0].setExtremes(e.target.dataMin, e.target.dataMax);
            });
          }
        }
      }
    }
  }
];

trippleAxisRevenue: any = [{
  min: 0,
  lineWidth: 1,
  labels: {
    // format: '{value}',
    // labels: {
      formatter: function() {
          let value = this.value;
          if (value >= 1000000000000) { // Trillions
              return (value / 1000000000000).toFixed(1) + 'T';
          } else if (value >= 1000000000) { // Billions
              return (value / 1000000000).toFixed(1) + 'B';
          } else if (value >= 1000000) { // Millions
              return (value / 1000000).toFixed(1) + 'M';
          } else if (value >= 1000) { // Thousands
              return (value / 1000).toFixed(1) + 'K';
          } else {
              return value;
          }
      },
  // }
    style: {
      fontSize: '12px',
      color: Highcharts.getOptions().colors[1]
    }
  },
 //opposite: true,
  title: {
    text: 'Revenue',
    style: {
      color: Highcharts.getOptions().colors[1]
    }
  },
  
  // minRange: 1,
  // max: 100,
  //lineWidth: 1,
 stackLabels: { enabled: false },
  // events: {
  //   setExtremes: function (e) {
  //     if (e && e.target) {
  //       if (e.target.oldUserMax !== e.target.dataMax || e.target.oldUserMin !== e.target.dataMin) {
  //         setTimeout(function () {
  //           e.target.chart.yAxis[0].setExtremes(e.target.dataMin, e.target.dataMax);
  //         });
  //       }
  //     }
  //   }
  // }
}, 
{
  min: 0,
  // max: 5,
  gridLineWidth: 0,
  endOnTick: false,
  title: {
    text: 'Revenue',
    style: {
      //color: Highcharts.getOptions().colors[0]
    }
  },
  // scrollbar: {
  //   enabled: true,
  //   showFull: false
  // },
  labels: {
    // format: '{value}',
    // labels: {
      formatter: function() {
          let value = this.value;
          if (value >= 1000000000000) { // Trillions
              return (value / 1000000000000).toFixed(1) + 'T';
          } else if (value >= 1000000000) { // Billions
              return (value / 1000000000).toFixed(1) + 'B';
          } else if (value >= 1000000) { // Millions
              return (value / 1000000).toFixed(1) + 'M';
          } else if (value >= 1000) { // Thousands
              return (value / 1000).toFixed(1) + 'K';
          } else {
              return value;
          }
      // }
  },
    style: {
      fontSize: '12px',
      //color: Highcharts.getOptions().colors[0]
    }
  },
  opposite: true,
  lineWidth: 1,
  stackLabels: { enabled: this.yStackLabelsEnabled },
  // tickPositions: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5],
  // events: {
  //   setExtremes: function (e) {
  //     if (e && e.target) {
  //       if (e.target.oldUserMax !== e.target.dataMax || e.target.oldUserMin !== e.target.dataMin) {
  //         setTimeout(function () {
  //           e.target.chart.yAxis[0].setExtremes(e.target.dataMin, e.target.dataMax);
  //         });
  //       }
  //     }
  //   }
  // }
},
{
  min: -100,
  max: 100,
  gridLineWidth: 0,
  endOnTick: false,
  title: {
    text: 'Net Promotor Score',
    style: {
      // color: Highcharts.getOptions().colors[0]
    }
  },
  // scrollbar: {
  //   enabled: true,
  //   showFull: false
  // },
  labels: {
    format: '{value}',
    style: {
      fontSize: '12px',
     // color: Highcharts.getOptions().colors[0]
    }
  },
  opposite: true,
  lineWidth: 1,
  stackLabels: { enabled: this.yStackLabelsEnabled },
  // events: {
  //   setExtremes: function (e) {
  //     if (e && e.target) {
  //       if (e.target.oldUserMax !== e.target.dataMax || e.target.oldUserMin !== e.target.dataMin) {
  //         setTimeout(function () {
  //           e.target.chart.yAxis[0].setExtremes(e.target.dataMin, e.target.dataMax);
  //         });
  //       }
  //     }
  //   }
  // }
}
];
  disableDdList = ['branch', 'zone', 'engineer', 'technician','ProjectManager'];
  famDdListEnabled: boolean = true;
  isLeftContextMenu: boolean = false;
  EmailActivity: any=[];
  loading:boolean=false;
  symbolOnLocalStorage: string;

  constructor(private sharedService: SharedService, public chartService: ChartDataService,
    private globalFilter: FilterService,
    private router: Router) {

    // update the chart svg versions in chart util class
    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(val => val !== null && val.includes(this.chartIdentifier.toLowerCase()))).subscribe(val => {
      if (this.sharedService.acceptableChartState.includes(this.chartState))
        this.toCanvas();
        if(this.chartIdentifier.toLowerCase() === 'cc') {
          this.slideTextToShow();
        }
    });
  }

  logChartInstance(chart: Highcharts.Chart) {
    this.chartInstance = chart;
  }
  // logDummyChartInstance(chart: Highcharts.Chart) {
  //     this.dummyChartInstance = chart;
  //     for (let i = 0; i < this.dummyChartInstance.series.length; i++) {
  //         const arrayItem = this.dummyChartInstance.series[i];
  //         if(arrayItem && arrayItem.name && this.hideLegendText.includes(arrayItem.name.toLowerCase())) {
  //             arrayItem.hide();
  //         } else {
  //             arrayItem.show();
  //         }
  //     }
  // }

  showHideLabels() {
    let classContext = this;
    for (let i = 0; i < this.chartInstance.series.length; i++) {
      var opt = this.chartInstance.series[i].options;
      if (opt.type.indexOf("spline") > -1 ) {
        if(this.chartIdentifier.toLowerCase() != 'or' && this.chartIdentifier.toLowerCase() != 'oi')
          opt.dataLabels.enabled = true;
        else {
          opt.dataLabels.enabled = !opt.dataLabels.enabled;
          localStorage.setItem('dataLabel',opt.dataLabels.enabled)

        }
      } else {
        opt.dataLabels.enabled = !opt.dataLabels.enabled;
        if(this.chartIdentifier.toLowerCase() != 'or' && this.chartIdentifier.toLowerCase() != 'oi') {
          localStorage.setItem('dataLabel',opt.dataLabels.enabled)
        }
        this.barChartLableVisibility = opt.dataLabels.enabled;
      }
      if (!opt.dataLabels) {
        opt.dataLabels = {};
      }
      
      if(this.chartIdentifier.toLowerCase() != 'or' && this.chartIdentifier.toLowerCase() != 'oi') {
        opt.dataLabels.style = {
          fontFamily: 'Source Sans Pro Regular',
          textShadow: false,
          textOutline: 'false',
          fontSize: '10px'
        };
        opt.dataLabels.color = '#333333';
        opt.dataLabels.formatter = function () {
          let _this: any = this;
          if (_this.y !== 0) {
            if (_this.point.series.initialType.toLowerCase().indexOf('spline') > -1) {
              return _this.y + '%';
            }
            if (_this.point.series.initialType.toLowerCase() == 'column') {
              if (classContext.isDualyAxes || classContext.isTripleAxes ) {
                // return _this.series.name.toLowerCase() !== 'surveys sent' ? '' : _this.y; // make labels invisible on blue bars (As per VD requirement): April-May 2019
                return _this.y; // make labels visible on blue bars: April 2020
              } else { return _this.y + '%'; }
            }
          }
        };
      } else {
        opt.dataLabels.style = {
          fontFamily: 'Source Sans Pro Regular',
          textShadow: false,
          textOutline: 'false',
          fontSize: '10px',
          fontWeight: '500'
        };
        // opt.dataLabels.color = '#333333';
        opt.dataLabels.formatter = function () {
          let _this: any = this;
        //   function formatValue(value) {
        //     if (value >= 1000000000000) { // Trillions
        //         return (value / 1000000000000).toFixed(1) + 'T';
        //     } else if (value >= 1000000000) { // Billions
        //         return (value / 1000000000).toFixed(1) + 'B';
        //     } else if (value >= 1000000) { // Millions
        //         return (value / 1000000).toFixed(1) + 'M';
        //     } else if (value >= 1000) { // Thousands
        //         return (value / 1000).toFixed(1) + 'K';
        //     } else {
        //         return value;
        //     }
        // }
        // console.log(_this.point,'pounttttttttt')
        // console.log(_this.point.series,'seriessssss');
        // console.log(JSON.stringify(_this.point.series.initialType),'tt11111')
        // console.log(_this.y,'yyyyyyyyyyyyyyy')
        if (_this.y !== 0) {
          if (opt.type.indexOf("spline") > -1 ) {
            if (_this.y >= 1000000000000) { // Trillions
                      return (_this.y / 1000000000000).toFixed(1) + 'T';
                  } else if (_this.y >= 1000000000) { // Billions
                      return (_this.y / 1000000000).toFixed(1) + 'B';
                  } else if (_this.y >= 1000000) { // Millions
                      return (_this.y / 1000000).toFixed(1) + 'M';
                  } else if (_this.y >= 1000) { // Thousands
                      return (_this.y / 1000).toFixed(1) + 'K';
                  } else {
                      return _this.y;
                  }
                // return formatValue(_this.y);
            }
          else {
                if (!classContext.isDualyAxes || !classContext.isTripleAxes) {
                  if (_this.y >= 1000000000000) { // Trillions
                    return (_this.y / 1000000000000).toFixed(1) + 'T';
                } else if (_this.y >= 1000000000) { // Billions
                    return (_this.y / 1000000000).toFixed(1) + 'B';
                } else if (_this.y >= 1000000) { // Millions
                    return (_this.y / 1000000).toFixed(1) + 'M';
                } else if (_this.y >= 1000) { // Thousands
                    return (_this.y / 1000).toFixed(1) + 'K';
                } else {
                    return _this.y;
                }
                }
            }
        }
        // return _this.y; // return
        };
      }
      

      this.chartInstance.series[i].update(opt);
    }
    // this.showHideDummyLabels();
  }
  // showHideDummyLabels() {
  //     if (!this.dummyChartInstance)
  //         return;

  //     let classContext = this;
  //     for (let i = 0; i < this.dummyChartInstance.series.length; i++) {
  //         var opt = this.dummyChartInstance.series[i].options;
  //         if (opt.type.indexOf("spline") > -1) {
  //             opt.dataLabels.enabled = true;
  //         } else {
  //             opt.dataLabels.enabled = !opt.dataLabels.enabled;
  //             this.barChartLableVisibility = opt.dataLabels.enabled;
  //         }
  //         opt.dataLabels.formatter = function () {
  //             let _this: any = this;
  //             if (_this.y !== 0) {
  //                 // if (_this.point.series.initialType.toLowerCase().indexOf('spline') > -1) {
  //                 if (_this.point.series.options.type.toLowerCase().indexOf('spline') > -1) {
  //                     return _this.y + '%';
  //                 }
  //                 // if (_this.point.series.initialType.toLowerCase() == 'column') {
  //                 if (_this.point.series.options.type.toLowerCase() == 'column') {
  //                     if (classContext.isDualyAxes) {
  //                         return _this.series.name.toLowerCase() !== 'surveys sent' ? '' : _this.y;
  //                     } else { return _this.y + '%'; }
  //                 }
  //             }
  //         };
  //         opt.dataLabels.color = '#333333';
  //         opt.dataLabels.style = {
  //             fontFamily: 'Source Sans Pro Regular',
  //             textShadow: false,
  //             textOutline: 'false',
  //             fontSize: '10px'
  //         };
  //         this.dummyChartInstance.series[i].update(opt);
  //     }
  // }

  ngOnChanges(changes: SimpleChanges) {
    let tooltip  =this.toolTipAppendStr;
    let symbol = this.symbol;
    let symbolOnLocalStorage = localStorage.getItem('symbol');
    this.symbolOnLocalStorage =  localStorage.getItem('symbol');
    this.decideCcChartView();
    this.setFamDdVisibility();
    this.oneToOneFlag = true;
    this.emailListHeader = null;
    this.showEmailList = false;
    this.showThirdLevelMenu = false;
    this.updateExportDetails();
    if (!this.isClick && this.chartIdentifier == 'rrt') {
      this.hideContextMenu();
    }
    if(this.chartIdentifier.toLowerCase() != 'nnt')
      Highcharts['seriesTypes'].areaspline.prototype.drawLegendSymbol = Highcharts['seriesTypes'].spline.prototype.drawLegendSymbol;
    else
      Highcharts['seriesTypes'].areaspline.prototype.drawLegendSymbol = Highcharts['seriesTypes'].column.prototype.drawLegendSymbol;
    if(this.chartIdentifier.toLowerCase() == 'or' || this.chartIdentifier.toLowerCase() == 'oi') {
      Highcharts['height'] = 300
    } else {
      Highcharts['height'] = 800;
    }
    let mouseIn: boolean, classContext = this;
    this.regularyAxis.stackLabels.enabled = this.yStackLabelsEnabled ? true : false;
    this.regularNPSTyAxis.stackLabels.enabled = this.yStackLabelsEnabled ? true : false;
    this.dualyAxis.forEach(element => element.stackLabels.enabled = this.yStackLabelsEnabled ? true : false);

    if (!(changes && changes.data)) {
      return;
    }
    if (changes.data.currentValue === undefined) {
      return;
    }
    this.options = {
      chart: {
        marginBottom: 90,
        panning: true,
        labels: { 
          formatter: function() {
            if(classContext.chartIdentifier.toLowerCase() == 'or' || classContext.chartIdentifier.toLowerCase() == 'oi') {
              let value = this.value;
              if (value >= 1e12) {
                  return (value / 1e12).toFixed(1) + 'T';
              } else if (value >= 1e9) {
                  return (value / 1e9).toFixed(1) + 'B';
              } else if (value >= 1e6) {
                  return (value / 1e6).toFixed(1) + 'M';
              } else if (value >= 1e3) {
                  return (value / 1e3).toFixed(1) + 'K';
              } else {
                  return value;
              }
            }
          }  
        },
        events: {
          click: function () {
            if (!classContext.showEmailList) {
              classContext.rrtTooltipData = [];
              classContext.hideContextMenu();
              classContext.showThirdLevelMenu = false;
            }
          },
          redraw: function() {
            classContext.hideContextMenu();
          },
          render: function () {
            if (this.resetZoomButton) {
              this.resetZoomButton.hide();
            }
          }
        }
      },
      exporting: {
        sourceWidth: 1000,
        sourceHeight: 450,
        chartOptions: {
          chart: {
            marginBottom: 90,
            height: '345px'
          }
        }
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      mapNavigation: {
        enabled: (classContext.chartIdentifier.toLowerCase() == 'or' || classContext.chartIdentifier.toLowerCase() == 'oi') ? false : true,
        enableDoubleClickZoom: true,
        buttonOptions: {
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver'
          },
          verticalAlign: 'top',
          align: "right",
          alignTo: "spacingBox",
          style: {
            fontSize: "10px"
          },
          width: 10,
          height: 10,
          padding: 7
        }
      },
      title: {
        text: null
        , verticalAlign: 'top',
        align: 'left',
        style: {
          fontFamily: 'Source Sans Pro Regular',
          fontSize: '1.3em',
          fontWeight: 'bold',
          color: '#333333'
        }
      },
      xAxis: {
        categories: JSON.parse(JSON.stringify(changes.data.currentValue.xAxis)),
        labels: {
          style: {
            fontFamily: 'Source Sans Pro Regular',
            fontSize: '10px',
            color: '#333333',
            fontWeight: 'bold'
          }
        },
        scrollbar: {
          enabled: true,
          barBackgroundColor: 'white',
          barBorderColor: 'white',
          barBorderWidth: 0,
          buttonArrowColor: 'white',
          buttonBackgroundColor: 'white',
          buttonBorderColor: 'white',
          buttonBorderRadius: 0,
          buttonBorderWidth: 0,
          rifleColor: 'white',
          showFull: false,
          minWidth: 0,
          trackBackgroundColor: 'white',
          trackBorderColor: 'white'
        },
        events: {
          setExtremes: function (e: any) {
            if (e && e.target && e.target.chart && e.target.chart.yAxis && e.target.chart.yAxis.length) {
              if (e.target.chart.yAxis[0].oldUserMax !== e.target.chart.yAxis[0].dataMax || e.target.chart.yAxis[0].oldUserMin !== e.target.chart.yAxis[0].dataMin) {
                setTimeout(function () {
                  e.target.chart.yAxis[0].setExtremes(e.target.chart.yAxis[0].dataMin, e.target.chart.yAxis[0].dataMax);
                });
              }
            }
          }
        } 
      },
      yAxis: this.isDualyAxes ? this.dualyAxis : this.isTripleAxes ? this.trippleAxis :(this.chartIdentifier.toLowerCase() == 'or' || this.chartIdentifier.toLowerCase() == 'oi') ? this.trippleAxisRevenue : this.chartIdentifier.toLowerCase() == 'npst' ? this.regularNPSTyAxis : this.regularyAxis,
      legend: {
        enabled: true,
        borderWidth: 0,
        align: 'right',
        verticalAlign: 'top',
        y: -8,
        x: -40,
        itemStyle: {
          fontFamily: 'Source Sans Pro Regular',
          fontSize: '12px',
          fontWeight: 'normal',
          color: '#666666'
        },
        symbolHeight: 10,
        symbolRadius: (this.chartIdentifier.toLowerCase() == 'or' || this.chartIdentifier.toLowerCase() == 'oi') ? 1: 10 
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        areaspline: {
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: []
          },
          legend: {
             marker: {}
          },
          marker: {
            radius: 0
          },
          lineWidth: 0,
          states: {
            hover: {
              enabled: false,
              lineWidth: 0
            }
          },
          threshold: null,
          showInLegend: true 
        },
        column: {
          stacking: 'normal',
          dataLabels: {
          //   formatter: function() {
          //     let value = this.value;
          //     if (value >= 1000000000000) { // Trillions
          //         return (value / 1000000000000).toFixed(1) + 'T';
          //     } else if (value >= 1000000000) { // Billions
          //         return (value / 1000000000).toFixed(1) + 'B';
          //     } else if (value >= 1000000) { // Millions
          //         return (value / 1000000).toFixed(1) + 'M';
          //     } else if (value >= 1000) { // Thousands
          //         return (value / 1000).toFixed(1) + 'K';
          //     } else {
          //         return value;
          //     }
          // },
            enabled: false,
            borderRadius: 0,
            color: '#333333',
            borderWidth: 0,
            style: {
              fontFamily: 'Source Sans Pro Regular',
              textShadow: false,
              textOutline: 'false',
              fontSize: '10px'
            }
          },
          point: {
            events: {
              click: function (event: any) {
                this.secondMenuClasses = [];
                this.thirdMenuClasses = [];
                this.chartXTemp = event.chartX;
                if (this.chartIdentifier.toLowerCase() === 'cc') {
                  let str = event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1];
                  let entityId = null;
                  let tooltipPropIdx = -1;
                  let lengthyToolTipTrimmer = (tooltipVal: string): string => {
                    return tooltipVal.split(',')[0] + ',' + tooltipVal.split(',')[1] + ', ' + '...';
                  }

                  for (let i = 0; i < changes.data.currentValue.chartRawData.Entity.length; i++) {
                    if (str.toLowerCase() === changes.data.currentValue.chartRawData.Entity[i].EntityName.toLowerCase()) {
                      entityId = changes.data.currentValue.chartRawData.Entity[i].EntityID;
                      if ((changes.data.currentValue.chartRawData.Entity[i].TooltipBrand.match(/,/g) || []).length >= 2)
                        classContext.tooltipBrand = '- ' + lengthyToolTipTrimmer(changes.data.currentValue.chartRawData.Entity[i].TooltipBrand);
                      else classContext.tooltipBrand = (changes.data.currentValue.chartRawData.Entity[i].TooltipBrand.length === 0 ? '' : '- ') + changes.data.currentValue.chartRawData.Entity[i].TooltipBrand;

                      if ((changes.data.currentValue.chartRawData.Entity[i].TooltipFAMCode.match(/,/g) || []).length >= 2)
                        classContext.tooltipFAMCode = '- ' + lengthyToolTipTrimmer(changes.data.currentValue.chartRawData.Entity[i].TooltipFAMCode);
                      else classContext.tooltipFAMCode = (changes.data.currentValue.chartRawData.Entity[i].TooltipFAMCode.length === 0 ? '' : '- ') + changes.data.currentValue.chartRawData.Entity[i].TooltipFAMCode;
                    
                      if ((changes.data.currentValue.chartRawData.Entity[i].TooltipCntry.match(/,/g) || []).length >= 2)
                        classContext.tooltipCntry = '- ' + lengthyToolTipTrimmer(changes.data.currentValue.chartRawData.Entity[i].TooltipCntry);
                      else classContext.tooltipCntry = (changes.data.currentValue.chartRawData.Entity[i].TooltipCntry.length === 0 ? '' : '- ') + changes.data.currentValue.chartRawData.Entity[i].TooltipCntry;

                    
                    if ((changes.data.currentValue.chartRawData.Entity[i].TooltipOperationalCntry.match(/,/g) || []).length >= 2)
                      classContext.tooltipOperationalCntry = '- ' + lengthyToolTipTrimmer(changes.data.currentValue.chartRawData.Entity[i].TooltipOperationalCntry);
                    else classContext.tooltipOperationalCntry = (changes.data.currentValue.chartRawData.Entity[i].TooltipOperationalCntry.length === 0 ? '' : '- ') + changes.data.currentValue.chartRawData.Entity[i].TooltipOperationalCntry;

                    
                    if ((changes.data.currentValue.chartRawData.Entity[i].RegionName.match(/,/g) || []).length >= 2)
                      classContext.regions = '- ' + lengthyToolTipTrimmer(changes.data.currentValue.chartRawData.Entity[i].RegionName);
                    else classContext.regions = (changes.data.currentValue.chartRawData.Entity[i].RegionName.length === 0 ? '' : '- ') + changes.data.currentValue.chartRawData.Entity[i].RegionName;
                      if ((changes.data.currentValue.chartRawData.Entity[i].SalesEngineer.match(/,/g) || []).length >= 2)
                        classContext.salesEngineers = '- ' + lengthyToolTipTrimmer(changes.data.currentValue.chartRawData.Entity[i].SalesEngineer);
                      else classContext.salesEngineers = (changes.data.currentValue.chartRawData.Entity[i].SalesEngineer.length === 0 ? '' : '- ') + changes.data.currentValue.chartRawData.Entity[i].SalesEngineer;

                      if ((changes.data.currentValue.chartRawData.Entity[i].ServiceTechnician.match(/,/g) || []).length >= 2)
                        classContext.serviceTechnicians = '- ' + lengthyToolTipTrimmer(changes.data.currentValue.chartRawData.Entity[i].ServiceTechnician);
                      else classContext.serviceTechnicians = (changes.data.currentValue.chartRawData.Entity[i].ServiceTechnician.length === 0 ? '' : '- ') + changes.data.currentValue.chartRawData.Entity[i].ServiceTechnician;
                                            if ((changes.data.currentValue.chartRawData.Entity[i].ProjectManager.match(/,/g) || []).length >= 2)
                        classContext.ProjectManagers = '- ' + lengthyToolTipTrimmer(changes.data.currentValue.chartRawData.Entity[i].ProjectManager);
                      else classContext.ProjectManagers = (changes.data.currentValue.chartRawData.Entity[i].ProjectManager.length === 0 ? '' : '- ') + changes.data.currentValue.chartRawData.Entity[i].ProjectManager;
                      
                      if ((changes.data.currentValue.chartRawData.Entity[i].ServiceBranch.match(/,/g) || []).length >= 2)
                        classContext.serviceBranchs = '- ' + lengthyToolTipTrimmer(changes.data.currentValue.chartRawData.Entity[i].ServiceBranch);
                      else classContext.serviceBranchs = (changes.data.currentValue.chartRawData.Entity[i].ServiceBranch.length === 0 ? '' : '- ') + changes.data.currentValue.chartRawData.Entity[i].ServiceBranch;
                    }
                  }
                  let result = str;
                  let eventContext = this;
                  let contextMenu = document.getElementById('contextMenu');
                  let contextMenuItem1 = document.getElementById('contextMenuItem1');
                  let contextMenuItem2 = document.getElementById('contextMenuItem2');
                  let contextMenuItem3 = document.getElementById('contextMenuItem3');
                  let contextMenuItem4 = document.getElementById('contextMenuItem4');
                  let contextMenuItem5 = document.getElementById('contextMenuItem5');
                  let contextMenuItem6 = document.getElementById('contextMenuItem6');
                  let contextMenuItem7 = document.getElementById('contextMenuItem7');
                  let contextMenuItem8 = document.getElementById('contextMenuItem8');
                  let contextMenuItem9 = document.getElementById('contextMenuItem9');

                  setTimeout(() => {
                    classContext.isLeftContextMenu = false;
                    if (entityId && entityId >= 0 && classContext.isDrilldownEnabled) {
                      contextMenu.setAttribute('style', 'display: block');

                      let xBoundary = classContext.chartInstance.chartWidth - (contextMenu['offsetWidth'] + 30);
                      let xPos = (event.chartX + 30);
                      if ((event.chartX + 30) > xBoundary) {
                        classContext.isLeftContextMenu = true;
                        xPos -= (contextMenu['offsetWidth'] + 30);
                      } else { classContext.isLeftContextMenu = false; }

                      contextMenu.setAttribute('style', 'top: ' + (event.chartY + 40) + 'px; left:' + xPos + 'px;');
                    } else {
                      contextMenu.setAttribute('style', 'display: none');
                    }
                  }, 100);
                  
                  // classContext.isLeftContextMenu = false;
                  // if (entityId && entityId >= 0 && classContext.isDrilldownEnabled) {
                  //   contextMenu.setAttribute('style', 'display: block');

                  //   let xBoundary = classContext.chartInstance.chartWidth - (contextMenu['offsetWidth'] + 30);
                  //   let xPos = (event.chartX + 30);
                  //   if((event.chartX + 30) > xBoundary) {
                  //     classContext.isLeftContextMenu = true;
                  //     xPos -= (contextMenu['offsetWidth'] + 30);
                  //   } else { classContext.isLeftContextMenu = false; }

                  //   contextMenu.setAttribute('style', 'top: ' + (event.chartY + 40) + 'px; left:' + xPos + 'px;');
                  // } else {
                  //   contextMenu.setAttribute('style', 'display: none');
                  // }
                  let functArr =  classContext.contextMenuDecln(classContext, contextMenu, event);
                  let callDrillDown1 = functArr[0];
                  let callDrillDown2 = functArr[1];
                  let callDrillDown3 = functArr[2];
                  let callDrillDown4 = functArr[3];
                  let callDrillDown5 = functArr[4];
                  let callDrillDown6 = functArr[5];
                  let callDrillDown7 = functArr[6];
                  let callDrillDown8 = functArr[7];
                  let callDrillDown9 = functArr[8];

                  if (contextMenuItem1) contextMenuItem1.onclick = callDrillDown1;
                  if (contextMenuItem2) contextMenuItem2.onclick = callDrillDown2;
                  if (contextMenuItem3) contextMenuItem3.onclick = callDrillDown3;
                  if (contextMenuItem4) contextMenuItem4.onclick = callDrillDown4;
                  if (contextMenuItem5) contextMenuItem5.onclick = callDrillDown5;
                  if (contextMenuItem6) contextMenuItem6.onclick = callDrillDown6;
                  if (contextMenuItem7) contextMenuItem7.onclick = callDrillDown7;
                  if (contextMenuItem8) contextMenuItem8.onclick = callDrillDown8;
                  if (contextMenuItem9) contextMenuItem9.onclick = callDrillDown9;
                  contextMenu.onmouseenter = () => {
                    mouseIn = true;
                  }

            /*    function callDrillDown1($event: any) {
                  classContext.contextMenuClick(document.getElementById('contextMenuItem1').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
                }
                function callDrillDown2($event: any) {
                  classContext.contextMenuClick(document.getElementById('contextMenuItem2').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
                }
                function callDrillDown3($event: any) {
                  classContext.contextMenuClick(document.getElementById('contextMenuItem3').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
                }
                function callDrillDown4($event: any) {
                  classContext.contextMenuClick(document.getElementById('contextMenuItem4').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
                }
                function callDrillDown5($event: any) {
                  classContext.contextMenuClick(document.getElementById('contextMenuItem5').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
                }
                function callDrillDown6($event: any) {
                  classContext.contextMenuClick(document.getElementById('contextMenuItem6').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
                }
                function callDrillDown7($event: any) {
                  classContext.contextMenuClick(document.getElementById('contextMenuItem7').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
                }
                function callDrillDown8($event: any) {
                  classContext.contextMenuClick(document.getElementById('contextMenuItem8').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
                }
                */

                  contextMenu.onmouseleave = () => {
                    mouseIn = false;
                    setTimeout(() => {
                      if (!mouseIn) {
                        classContext.tooltipCntry = classContext.tooltipFAMCode = classContext.tooltipBrand = classContext.regions = classContext.serviceBranchs = classContext.serviceTechnicians = classContext.salesEngineers = classContext.ProjectManagers = '';
                        contextMenu.setAttribute('style', 'display: none');
                      }
                    }, 150);
                  };
                } else {
                  let resultData = {}, chartType = '';
                  classContext.rrtTooltipData = [];
                  if (event.point.color === '#717171') {
                    classContext.chartType = 'Bounceback';
                    chartType = 'Bounce Back';
                  } else if (event.point.color === '#999999') {
                    classContext.chartType = 'NoResponse';
                    chartType = 'No Response';
                  } else if (event.point.color === '#3299D1') {
                    classContext.chartType = 'Completed';
                    chartType = 'Completed';
                  } else if (event.point.color === '#337195') {
                    classContext.chartType = 'ReplytoInvitation';
                    chartType = 'Reply to Invitation';
                  } else {
                    classContext.chartType = null;
                  }
                  classContext.tooltipLoader = true;
                  this.open = true
                   this.viewObj = {
                    ViewAnalysisType: this.viewPeriod === 'Month' ? 'M' : 'Y',
                    ViewAnalysisBarPeriod: event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1],
                    DispositionStatus: chartType
                  };
                  let contextMenu = document.getElementById('contextMenuRrt');

                        if (contextMenu && classContext.selectedBarStr === this.viewObj.ViewAnalysisBarPeriod && event.point.color === classContext.selectedPointColor) {
                          classContext.hideContextMenu();
                        } else {
                          classContext.selectedBarStr = this.viewObj.ViewAnalysisBarPeriod;
                          classContext.selectedPointColor = event.point.color;
                        }
                        setTimeout(() => {
                          classContext.isLeftContextMenu = false;
                          if (classContext.isClick && this.chartType) {
                            contextMenu.setAttribute('style', 'display: block');
                            let offsetWidth = contextMenu['offsetWidth'];
                            //Find Chart Width 
                            let outerWidth = classContext.chartInstance.chartWidth;
                            this.RRTGraphWidth = outerWidth;
                            //Find Center of chart                
                            let chartCenter = (outerWidth / 2);
                            //Find X Position of click                          
                            let balanceWidth = outerWidth - event.chartX;
                            this.isPreviousLeft = event.chartX > chartCenter;
                            if (event.chartX > chartCenter) {
                              this.restWidth = Math.abs(680 - event.chartX);
                            }
                            else {
                              this.restWidth = Math.abs(680 - balanceWidth);
                            }


                            console.log(this.restWidth);

                            let v = (classContext.chartInstance.chartWidth * 1.5) / 170;
                            let h = (classContext.chartInstance.chartWidth * 1.5) / 110;
                            let xBoundary = classContext.chartInstance.chartWidth - (offsetWidth + v);

                            let xPos = (event.chartX + v);

                            // console.log(xPos);

                            if ((event.chartX) > (chartCenter)) {
                              classContext.isLeftContextMenu = true;
                              xPos -= (offsetWidth + (v + 5));


                            } else { classContext.isLeftContextMenu = false; }
                            if (contextMenu) {
                              contextMenu.setAttribute('style', 'top: ' + (event.chartY + h) + 'px; left:' + xPos + 'px;');
                            }
                            this.emailListHeader = null;
                            this.showEmailList = false;
                            this.showThirdLevelMenu = false;
                          }
                        }, 100);
                      
                    this.chartService.getRRTrendData(this.globalFilterData, this.viewObj).subscribe(rrRes => {
                    if (rrRes.Response && rrRes.Status == 'Success') {
                      if (rrRes && rrRes.Response && rrRes.Response.Disposition && rrRes.Response.Disposition.length) {
                        resultData = rrRes.Response.Disposition.find(x => x.DispStatus === chartType && x.ViewAnalysisType === this.viewObj.ViewAnalysisType && x.ViewAnalysisBarPeriod === this.viewObj.ViewAnalysisBarPeriod);
                        classContext.rrtTooltipData = resultData ? [ChartDataParsing.parseRRTrendChartTooltipData(resultData)] : [];
                        classContext.tooltipLoader = false;
                        this.drillDownOpened =true
                      } else {
                        classContext.tooltipLoader = false;
                        classContext.rrtTooltipData = [ChartDataParsing.parseRRTrendChartTooltipData()];
                        this.drillDownOpened =false
                      }
                    } else {
                      classContext.tooltipLoader = false;
                      this.drillDownOpened = false
                      classContext.rrtTooltipData = [ChartDataParsing.parseRRTrendChartTooltipData()];
                    }
                  },
                    (err) => {
                      classContext.tooltipLoader = false;
                      classContext.rrtTooltipData = [ChartDataParsing.parseRRTrendChartTooltipData()];
                    });


                }
              }.bind(this)
            }
          },
          maxPointWidth: 35
        },
        line: {
          dataLabels: {
            // formatter: function() {
            //   let value = this.value;
            //   if (value >= 1000000000000) { // Trillions
            //       return (value / 1000000000000).toFixed(1) + 'T';
            //   } else if (value >= 1000000000) { // Billions
            //       return (value / 1000000000).toFixed(1) + 'B';
            //   } else if (value >= 1000000) { // Millions
            //       return (value / 1000000).toFixed(1) + 'M';
            //   } else if (value >= 1000) { // Thousands
            //       return (value / 1000).toFixed(1) + 'K';
            //   } else {
            //       return value;
            //   }
            // },
          },
        },

        series: {
          dataLabels: {
          },
          events: {
            legendItemClick: function (e) {
              classContext.hideContextMenu();
              const selectedLegend = this.name;
              const series = this;
              if(selectedLegend == 'Previous Year')
                classContext.handleLegendItemClick(selectedLegend,series.visible);
            }
          },
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      },
      series: JSON.parse(JSON.stringify(changes.data.currentValue.yAxis))
      , tooltip: {
        lang: {
          thousandsSep: ','
        },
        formatter: function () {
          let _this: any = this;
          let el = document.getElementById('contextMenuRrt');
          if (classContext.chartIdentifier !== 'rrt' || (classContext.chartIdentifier === 'rrt' && !(classContext.isClick && classContext.chartType && classContext.rrtTooltipData && classContext.rrtTooltipData.length)) ||
            (!el || (el && el.getAttribute('style') && el.getAttribute('style').includes('display: none')))) {
            let legendText: string = _this.point.series.name;
            if (legendText.indexOf('(12 MRT)') > -1)
              legendText = '12 MRT';
            let yAxis = _this.y;
            if (classContext.chartIdentifier === 'rrt')
            yAxis = _this.y;
            if (_this.x.length && _this.x.indexOf(' - ') > -1) {
              if ((_this.series.name.toLowerCase() === 'net promoter score') && classContext.isDualyAxes) {
                return _this.x.split(' - ')[1] + '<br/>' + legendText + ': ' + yAxis + (classContext.toolTipAppendStr.length > 0 ? classContext.toolTipAppendStr : '') + '%';
              } else {
                return _this.x.split(' - ')[1] + '<br/>' + legendText + ': ' + yAxis + (classContext.toolTipAppendStr.length > 0 ? classContext.toolTipAppendStr : '');
              }
            }
            else {
              if (classContext.isDualyAxes) {
                return '<b>' + _this.x + '</b><br/>' + legendText + ': ' + yAxis + (classContext.toolTipAppendStr.length > 0 ? classContext.toolTipAppendStr : '') + '%';
              } else {
                if(classContext.chartIdentifier === 'nnt') {
                  if(legendText ==='Response Received' ||legendText ==='NSS Current Trend' || legendText ==='NSS 12 MRT' || legendText ==='NSS 3 MRT') {
                    return '<b>' + _this.x + '</b><br/>' + legendText + ': ' + yAxis;
                  }
                }
                if(legendText != 'NPS 12 MRT' && classContext.chartIdentifier.toLowerCase() != 'or' && classContext.chartIdentifier.toLowerCase() != 'oi'){
                  return '<b>' + _this.x + '</b><br/>' + legendText + ': ' + classContext.symbol +' ' + yAxis + (classContext.toolTipAppendStr && classContext.toolTipAppendStr.length > 0 ? classContext.toolTipAppendStr : '');
                }
                else {
                  if(classContext.chartIdentifier.toLowerCase() == 'or' || classContext.chartIdentifier.toLowerCase() == 'oi') {
                    if(legendText == 'NPS 12 MRT'){
                      classContext.toolTipAppendStr =  '%'
                      classContext.symbol = '';
                    } 
                    else if(legendText == '4x3 M' || legendText == '12 M' || legendText == 'Order Invoiced' || legendText == 'Previous Year'){
                      classContext.toolTipAppendStr =  ''
                      classContext.symbol = symbolOnLocalStorage;

                    } else {
                      classContext.toolTipAppendStr =  tooltip;
                      classContext.symbol = symbolOnLocalStorage;
                    }
                  }
                  let axis = classContext.formatNumberWithCommas(yAxis);
                  let xAxis =  _this.x;
                  if(legendText== 'Previous Year') {
                    xAxis = classContext.formatPreviousYear(_this.x)
                  }
                  return '<b>' + xAxis + '</b><br/>' + legendText + ': ' + (classContext.symbol ? classContext.symbol + ' '  : '') + axis + (classContext.toolTipAppendStr && classContext.toolTipAppendStr.length > 0 ? classContext.toolTipAppendStr : '');
                }
              }
            }
          } else {
            return false;
          }
        },
        shared: false
      }
    };
    // Highcharts.wrap(Highcharts.Pointer.prototype, "dragStart", function(p,e) {
    //     document.getElementById('contextMenu').setAttribute('style', 'display: none');
    //     p.call(this, e);
    // });
    // (function (H) {
    //     H.wrap(H.Chart.prototype, 'pan', function (proceed) {
    //         document.getElementById('contextMenu').setAttribute('style', 'display: none');
    //         proceed.call(this, arguments[1], arguments[2]);
    //     });
    // })(Highcharts);
    if (this.isDualyAxes) {
      this.options.plotOptions.series = JSON.parse('{"pointPadding":0,"groupPadding":0.0}');
    }
    if((this.chartIdentifier.toLowerCase() == 'or' || this.chartIdentifier.toLowerCase() == 'oi') && !this.previous) {
      this.options.plotOptions.series.pointWidth = 12;
    } 
    if (this.chartIdentifier.toLowerCase() === 'or' || this.chartIdentifier.toLowerCase() === 'oi') {
      this.options.resetZoomButton = {
        style: {
          display:'none'
        }
      }
      this.options.exporting= {
        enabled: false
      },
      this.options.xAxis.plotBands = classContext.quarterLabels.map((q, i) => ({
          from: i === 0 ? -0.5 : this.quarterLabels[i - 1].position + 1.5,
          to: q.position + 1.5,
          label: {
              text: q.label,
              verticalAlign: 'bottom',
              align: 'center',
              y: 50,
              style: {
                  fontFamily: 'Source Sans Pro Regular',
                  fontSize: '10px',
                  color: '#000000', // Text color
                  border: '1px solid #000000',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor: '#FFFFFF', // Background color for the label
              }
          },
          color: 'rgba(0, 0, 0, 0)' // Transparent color for the plot band
      }));
  
      this.options.xAxis.plotLines = this.yearLabels.map(y => ({
          value: y.position,
          width: 2,
          color: 'transparent', // Transparent color for the plot line
          label: {
              text: y.label,
              verticalAlign: 'bottom',
              align: 'center',
              rotation: 0,
              y: 70,
              style: {
                  fontFamily: 'Source Sans Pro Regular',
                  fontSize: '10px',
                  color: '#000000', // Text color
                  border: '1px solid #000000',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor: '#FFFFFF' // Background color for the label
              }
          }
      }));
  }
    let zeroAxis = 0;
    let oneAxis = 0;
    let ThreeAxis = 0
    if(this.isTripleAxes) {
      if(changes.data.currentValue.yAxis) {
        changes.data.currentValue.yAxis.forEach(element => {
          if(element.name ==='NSS Current Trend') {
            element.data.forEach(data => {
                if(data <= 0) {
                  zeroAxis++
                } else if(data > 0 && data <=3 ) {
                  oneAxis++;
                } else if(data > 3) {
                  ThreeAxis++
                }
            });
          }
        });
      }
      let obj = [
        [0, 'rgba(67,176,42,0.4)'],    // Color at the top (for values >= 3)
        [0.5, 'rgba(233,233,66,0.4)'], // Color in the center (for values between 3 and 0)

        [1, 'rgba(234,18,13,0.4)']       // Color at the bottom (for values < 0)
      ] 
      if(ThreeAxis > 0) {
        obj = [
          [0, 'rgba(67,176,42,0.4)'],    // Color at the top (for values >= 3)
          [0.5, 'rgba(233,233,66,0.4)'], // Color in the center (for values between 3 and 0)
  
          [1, 'rgba(234,18,13,0.4)']       // Color at the bottom (for values < 0)
        ] 
      } else if(oneAxis > 0) {
        obj = [
          [0, 'rgba(233,233,66,0.4)'],    // Color at the top (for values >= 3)
          [0.5, 'rgba(234,18,13,0.4)'], // Color in the center (for values between 3 and 0)
  
          [1, 'rgba(234,18,13,0.4)']       // Color at the bottom (for values < 0)
        ] 
      } else if(zeroAxis > 0) {
        obj = [
          [0, 'rgba(234,18,13,0.4)'],    // Color at the top (for values >= 3)
          [0.5, 'rgba(234,18,13,0.4)'], // Color in the center (for values between 3 and 0)
  
          [1, 'rgba(234,18,13,0.4)']       // Color at the bottom (for values < 0)
        ] 
      }
      let areasplineOptions = {
        // areaspline: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: obj
        },
        legend: {
           marker: {}
        },
        marker: {
          symbol: 'triangle',
          radius:0
        },
        lineWidth: 0,
        states: {
          hover: {
            enabled: false,
            lineWidth: 0
          }
        },
        threshold: null,
        showInLegend: true 
      //  }
      }
      this.options.plotOptions.areaspline = areasplineOptions
      // this.options.plotOptions = Object.assign({}, this.options.plotOptions, areasplineOptions);
      console.log(this.options,'optopnsssssssss dataaaa')
    }
    if (!this.isDrilldownEnabled && !this.isClick)
      this.options.plotOptions.column.point.events.click = () => { };

    this.options.plotOptions.line = {
      dataLabels: {
      //   formatter: function() {
      //     let value = this.value;
      //     if (value >= 1000000000000) { // Trillions
      //         return (value / 1000000000000).toFixed(1) + 'T';
      //     } else if (value >= 1000000000) { // Billions
      //         return (value / 1000000000).toFixed(1) + 'B';
      //     } else if (value >= 1000000) { // Millions
      //         return (value / 1000000).toFixed(1) + 'M';
      //     } else if (value >= 1000) { // Thousands
      //         return (value / 1000).toFixed(1) + 'K';
      //     } else {
      //         return value;
      //     }
      // },
      }
    }
    if(this.chartInstance && this.chartIdentifier.toLowerCase() =='rsc' )
      this.chartInstance.zoomOut();
  }
  contextMenuDecln(classContext, contextMenu, event) {
    function callDrillDown1($event: any) {
      classContext.contextMenuClick(document.getElementById('contextMenuItem1').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
    }
    function callDrillDown2($event: any) {
      classContext.contextMenuClick(document.getElementById('contextMenuItem2').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
    }
    function callDrillDown3($event: any) {
      classContext.contextMenuClick(document.getElementById('contextMenuItem3').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
    }
    function callDrillDown4($event: any) {
      classContext.contextMenuClick(document.getElementById('contextMenuItem4').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
    }
    function callDrillDown5($event: any) {
      classContext.contextMenuClick(document.getElementById('contextMenuItem5').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
    }
    function callDrillDown6($event: any) {
      classContext.contextMenuClick(document.getElementById('contextMenuItem6').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
    }
    function callDrillDown7($event: any) {
      classContext.contextMenuClick(document.getElementById('contextMenuItem7').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
    }
    function callDrillDown8($event: any) {
      classContext.contextMenuClick(document.getElementById('contextMenuItem8').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
    }
    function callDrillDown9($event: any) {
      classContext.contextMenuClick(document.getElementById('contextMenuItem9').getAttribute('data-ddflag'), event.point.category.split(' - ')[1].split('</span>')[0].split('">')[1], contextMenu);
    }
    return [callDrillDown1, callDrillDown2, callDrillDown3, callDrillDown4, callDrillDown5, callDrillDown6, callDrillDown7, callDrillDown8, callDrillDown9]
  }

  handleLegendItemClick(legend, visibility) {
    this.previous = !visibility;
    this.unselectData.emit({ visiblityCheck: visibility ? false : true });
  }

  formatNumberWithCommas(x: any): string {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatPreviousYear(input) {
    return input.replace(/(\d{4})(?=<\/span>)/, (match, p1) => {
      const decreasedYear = (parseInt(p1) - 1).toString();
      return decreasedYear;
    });
  }

  ngOnInit() {
    this.chartToggelSub = this.sharedService.chartToggleParamBehaviousSubject.subscribe(viewBy => {
      this.viewPeriod = viewBy;
    });
    this.globFilterSub = this.globalFilter.globalFilterBehaviousSubject.subscribe(val => {
      this.globalFilterData = val;
    });
  }
  ngOnDestroy() {
    this.exportSubscription.unsubscribe();
    this.chartToggelSub.unsubscribe();
    this.globFilterSub.unsubscribe();
    this.updateExportDetails(true);
  }
  setCurrentChartView(currentView: string) {
    this.currentChartView = this.lastContextMenuClicked = currentView.toLowerCase();
    this.setFamDdVisibility()
  }
  contextMenuClick(eventFlag: string | null, compareWith: string, contextMenu: any) {
    let eventObj = {
      compareWith: eventFlag,
      entityName: compareWith
    };
    if (this.isDrilldownEnabled)
      this.contextMenuClicked.emit(eventObj);
    this.tooltipCntry = this.tooltipOperationalCntry = this.tooltipFAMCode = this.tooltipBrand = this.regions = this.serviceBranchs = this.serviceTechnicians = this.salesEngineers = this.ProjectManagers = '';
    contextMenu.setAttribute('style', 'display: none');
    this.setCurrentChartView(eventFlag);
  }
  setFamDdVisibility() {
    if (this.currentChartView === 'fam') {
      // this.famDdListEnabled = true;
    } else {
      // this.famDdListEnabled = !this.disableDdList.includes(this.currentChartView);
    }
  }
  decideCcChartView() {
    this.sharedService.exportFamComparison.includes(this.currentChartView.trim()) ? this.currentChartView = 'fam' : null;
  }
  resetDrilldownView(view: string) {
    this.lastContextMenuClicked = view.toLowerCase();
  }

  updateExportDetails(isDestroyCall: boolean = false) {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    chartUpdate.UpdateTime = new Date();
    chartUpdate.ChartName = this.chartIdentifier.toLowerCase();
    // if (isDestroyCall) {
    //   chartUpdate.CurrentState = CHART_STATE_OPTIONS.UNKNOWN;
    // } else {
    //   chartUpdate.CurrentState = this.chartState ? this.chartState : CHART_STATE_OPTIONS.UNKNOWN;
    // }
    if (isDestroyCall) {
      this.chartState = CHART_STATE_OPTIONS.UNKNOWN;
    }
    chartUpdate.CurrentState = this.chartState;
    this.sharedService.updateChartStatusSubject(chartUpdate);
  }
  toCanvas() {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    try {
      if (this.pictureOf && this.pictureOf.nativeElement) {
        html2canvas(this.pictureOf.nativeElement, { scrollX:0, scrollY:-window.scrollY, allowTaint: false, useCORS: false, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
          chartUpdate.UpdateTime = new Date();
          chartUpdate.ChartName = this.chartIdentifier.toLowerCase();
          chartUpdate.ChartImgId = this.chartIdentifier.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
          chartUpdate.CurrentState = this.chartState;
          chartUpdate.ChartImgBase64 = canvas.toDataURL('image/png');
          this.sharedService.updateChartStatusSubject(chartUpdate);
        },
          err => {
            console.log('error occurred during image export, details as below \n', err);
            chartUpdate = this.exportErrorUpdate();
            this.sharedService.updateChartStatusSubject(chartUpdate);
          });
      }
      else if (this.chartState === CHART_STATE_OPTIONS.NO_DATA) {
        chartUpdate.UpdateTime = new Date();
        chartUpdate.ChartName = this.chartIdentifier.toLowerCase();
        chartUpdate.ChartImgId = this.chartIdentifier.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
        chartUpdate.CurrentState = this.chartState;
        chartUpdate.ChartImgBase64 = 'no_image_data_available_for_this_state';
        chartUpdate.HasNoData = true;
        this.sharedService.updateChartStatusSubject(chartUpdate);
      }
    } catch (error) {
      console.log('html2canvas error as', error);
      chartUpdate = this.exportErrorUpdate();
      this.sharedService.updateChartStatusSubject(chartUpdate);
    }
  }
  private slideTextToShow = () => {
    let chartTitle: string = '';
    switch (this.currentChartView.toLowerCase()) {
      case 'fam':
        chartTitle = 'FAM Comparison';
        break;
      case 'brand':
        chartTitle = 'Brand Comparison';
        break;
      case 'country':
        chartTitle = 'Country Comparison';
        break;

      default:
        chartTitle = 'Country Comparison +';
        break;
    }
    let textConfig: SlideTextData = new SlideTextData();
    textConfig.SlideText = 'chartTitle||' + chartTitle;
    textConfig.SlideRows = [];
    textConfig.SlideId = this.chartIdentifier.toLowerCase();
    textConfig.SlideTextId = textConfig.SlideId + '_' + this.chartIdentifier.toLowerCase();
    textConfig.HasNoData = false;
    this.sharedService.updatechartTextSubject(textConfig);
  }
  exportErrorUpdate = (): ExportChartModel => {
    let err: ExportChartModel = new ExportChartModel();
    err.HasErrorInExport = true;
    err.UpdateTime = new Date();
    err.ChartName = this.chartIdentifier.toLowerCase();
    err.ChartImgId = this.chartIdentifier.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
    err.CurrentState = this.chartState;
    return err;
  }
  showEmailListModal(type = null, data: any = { emailActivity: [] }) {
    if (type === 'Clicked' && !!data.clickedCount || type === 'Opened' && !!data.openedCount) {
      this.showEmailList = true;
      this.emailList = [];
      this.showThirdLevelMenu = true;
      this.loading = true;
      this.chartService.getRRTrendDataEmailActivity(this.globalFilterData, this.viewObj).subscribe((rrEmailRes: any) => {

        if (rrEmailRes.Response) {
          this.EmailActivity = rrEmailRes.Response.EmailActivity;

          if (this.EmailActivity) {    //if (data && data.emailActivity) {
            this.emailListHeader = type;
            this.columns = [type];
            this.EmailActivity.map((x, i) => {
              if (!!x[type]) {
                let sentDate = x.SurveySentDate.split('T');
                let splitDate = sentDate[0].split('-');
                x.SurveySentDateFormatted = splitDate[2] + splitDate[1] + splitDate[0];
                this.emailList.push(x);

              }
            });
          }
        }
        this.loading = false;
      });
    } else {
      this.emailListHeader = null;
      this.showEmailList = false;
      this.showThirdLevelMenu = false;
    }
  }
  closeGrid() {
    this.showEmailList = false;
  }
  hideThirdLevelMenu() {
    this.showThirdLevelMenu = false;
  }

contextmenu(e,level: number) {
  
   var list = $(e.target).closest('li');
  var popoverList =  $('.custom-popover > ul'); 
  
  level ===2 && this.getSecondLevelMenuClass();
  level === 3 && this.getThirdLevelMenuClass();

  if(list.hasClass('selected')){
    list.children('ul').toggle(400);
    list.removeClass('selected'); 
    list.parent().next('ul > li').removeClass('selected');
    list.children('ul').find('li').removeClass('selected'); 
    list.children('ul').find('li').children('ul').toggle(400);  
       
  }

  else{  
    list.siblings('li').children('ul').hide(400); 
    list.siblings('li').removeClass('selected');

    list.children('ul').show(400);
    list.addClass('selected'); 
    
  
    
       
    
  } 
 

}
showContextMenuTooltip() {
  let el = document.getElementById('contextMenuRrt');
  if (this.chartIdentifier.toLowerCase() !== 'rrt' || (this.chartIdentifier.toLowerCase() === 'rrt' && !(this.isClick && this.chartType && this.rrtTooltipData && this.rrtTooltipData.length))
    || !el || el && el.getAttribute('style') && el.getAttribute('style').includes('display: none')) {
    return true;
  }
  return false;
}

getFirstLevelMenuClass(){
  let resultClass = []
  if (this.showThirdLevelMenu) {
      resultClass.push('display-third-level-menu')
  } 
}
  smallMenuWidth: number = 155;
  mainMenuWidth: number = 215;
  menuClick(level: number) {
    let sign = this.isPreviousLeft ? -1 : 1;
    let offsetWidth = ((this.mainMenuWidth + (this.smallMenuWidth * (level - 1))) * sign);
    let remWidth = this.chartXTemp + offsetWidth;
    let remMenuWidth = this.RRTGraphWidth - remWidth;
    let finalRemWidth = this.isPreviousLeft ? remWidth : remMenuWidth;
    if (finalRemWidth < this.smallMenuWidth) {
      if (level === 2) {
        this.secondMenuClasses.push('position-slide-reverse');
      }
      if (level === 3) {
        this.thirdMenuClasses.push('position-slide-reverse');
      }
    }
  }


getSecondLevelMenuClass() {
  // let resultClass = []
  //  if (this.showThirdLevelMenu) {
  //     resultClass.push('display-third-level-menu')
  // } 
  // if (this.restWidth < 10) {
  //     resultClass = resultClass.concat(['position-slide-reverse'])
  // }   
  this.menuClick(2);
}



getThirdLevelMenuClass() {  
  // let resultClass = []
  // if (this.showThirdLevelMenu) {
  //     resultClass.push('display-third-level-menu')
  // } 
  // if (this.restWidth > 10) {
  //     resultClass = resultClass.concat(['position-slide-reverse'])
  // }  
  this.menuClick(3);
  }
  hideContextMenu() {
    let el = document.getElementById('contextMenuRrt');
    let el2 = document.getElementById('contextMenu');
    let emailListMod = document.getElementById('emailListModal');
    if (el && !this.tooltipLoader && this.drillDownOpened && !emailListMod) {
      el.setAttribute('style', 'display: none');
    }
    if (el2 && !this.tooltipLoader && this.drillDownOpened && !emailListMod) {
      el2.setAttribute('style', 'display: none');
    }
    let popoverEl: any = document.getElementsByClassName('child-menu');
    for (let i = 0; i < popoverEl.length; i++) {
      if (!this.tooltipLoader && this.drillDownOpened && !emailListMod) {
        popoverEl[i].setAttribute('style', 'display:none !important');
        popoverEl[i].parentElement.classList.remove('selected')
      }  
    }
    this.selectedPointColor = null;
    this.selectedBarStr = null;
  }

  @HostListener('document:click', ['$event']) documentClickEvent($event: any) {
    let el = document.getElementById('contextMenuRrt');
    let chartPoint = document.getElementsByClassName('highcharts-point');
    if (el && el.contains($event.target) || $event.target.classList.contains('highcharts-point')) {
      //
    } else {
      if (el && el.id !== 'contextMenuRrt') {
        this.hideContextMenu();
      }
    }
    if( $event.target.classList.contains('highcharts-point')){
      this.hideContextMenu();
    }
  }

 
}



