import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService, LazyLoadEvent } from 'primeng/api';
import { CONSTANTS } from 'src/app/Modules/ctls/ctls-shared/ctls-constants/ctls-common-constants';
import { CtlsDashboardCustomerService } from '../ctls-services';
import { CtlsRulePopoverComponent } from '../../ctls-pages/ctls-ruleset/presentation/ctls-rule-popover/ctls-rule-popover.component';
import { CtlsViewSurveyDetailsComponent } from '../../ctls-pages/ctls-task-listing/presentation/ctls-view-survey-details/ctls-view-survey-details.component';

@Component({
  selector: 'app-heat-map-details-list',
  templateUrl: './heat-map-details-list.component.html',
  styleUrls: ['./heat-map-details-list.component.scss']
})
export class HeatMapDetailsListComponent implements OnInit {
  colTable = [];
  countData = 0;
  showDesc = false;
  selectedRowID = null;
  selectedRow: any = {};
  styleObj: any = {};
  selectedSurveyTypeClass = '';
  activeFilter = 'All';
  hideBAFAMCol = true;
  originalListArray: any = [];
  listData: any = [];
  allCount: any = 0;
  openCount: any = 0;
  resolvedCount: any = 0;
  closedCount: any = 0;
  taskType = CONSTANTS.taskType;
  taskTypeMapping = {
    'Promoter': 'P',
    'Detractor': 'D',
    'Neutral': 'N'
  };
  @Input('selectedTaskType') selectedTaskType;
  @Input('accountDetails') accountDetails;
  @Input('taskDetails') taskDetails;
  @Input('surveyDetails') surveyDetails;
  @Input('clickEventData') clickEventData;
  @ViewChild("popupHeatMapDetailsList") private popupHeatMapDetailsList: CtlsRulePopoverComponent;
  destroy$ = new Subject;
  constructor(private router: Router,
    private ctlsDashboardCustomerService: CtlsDashboardCustomerService,
    private dialogService: DialogService,
    ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.clickEventData && changes.clickEventData.currentValue) {
      
      this.styleObj = {'top': (changes.clickEventData.currentValue.pageY + 5) + 'px', 'left': (changes.clickEventData.currentValue.pageX - 15 ) + 'px' };
      this.setTableData();
      this.popupHeatMapDetailsList.blnFlag = false;
      this.popupHeatMapDetailsList.show(changes.clickEventData.currentValue);
    }
    if (changes && changes.accountDetails && changes.accountDetails.currentValue && changes.accountDetails.currentValue.length) {
      this.setTableData();
    }
    if (changes && changes.taskDetails && changes.taskDetails.currentValue && changes.taskDetails.currentValue.length) {
      this.setTableData();
    }
    if (changes && changes.surveyDetails && changes.surveyDetails.currentValue && changes.surveyDetails.currentValue.length) {
      this.setTableData();
    }

  }
  ngOnDestroy() {
    this.popupHeatMapDetailsList.hide();
    this.destroy$.next();
    this.destroy$.complete();
  }
  customSort(event: LazyLoadEvent) {
    if (this.showDesc) {
      this.showDesc = false;
    }
    //this.sortObj = event; event.sortField
    if (!!this.listData) {

      let result;
      this.listData.sort((a, b) => {
        if (!a[event.sortField] && !!b[event.sortField]) {
          result = -1;
        } else if (!!a[event.sortField] && !b[event.sortField]) {
          result = 1;
        } else if (!a[event.sortField] && !b[event.sortField]) {
          result = 0;
        } else if (event.sortField.endsWith('Date')) {
          if (
            typeof a[event.sortField] === 'string' &&
            typeof b[event.sortField] === 'string'
          ) {
            var dateA = new Date(a[event.sortField]);
            var dateB = new Date(b[event.sortField]);
            result = dateA.getTime() - dateB.getTime();
          }
        } else if (
          typeof a[event.sortField] === 'string' &&
          typeof b[event.sortField] === 'string'
        ) {
          result = a[event.sortField].localeCompare(b[event.sortField]);
        } else {
          result =
            a[event.sortField] < b[event.sortField]
              ? -1
              : a[event.sortField] > b[event.sortField]
                ? 1
                : 0;
        }
        return event.sortOrder * result;
      });
    }
  }
  filterGridData(listData, countData, filterData = '', tableColData = '') {
    if (!filterData) {
      this.originalListArray = Object.assign([], listData);
      this.hideBAFAMCol = true;
      this.allCount = Object.assign([], this.originalListArray).length;
      this.openCount = Object.assign([], this.originalListArray.filter(x => x.Status === 'Open')).length;
      this.resolvedCount = Object.assign([], this.originalListArray.filter(x => x.Status === 'Resolved')).length;
      this.closedCount = Object.assign([], this.originalListArray.filter(x => x.Status === 'Closed')).length;
      this.countData = this.listData && this.listData.length ? this.listData.length : 0;
      this.activeFilter = 'All';
    } else if (filterData) {
      if (filterData === 'hide') {
        this.hideBAFAMCol = !this.hideBAFAMCol;
        if (this.hideBAFAMCol) {
          this.removeBAFAM(listData);
        } else {
          this.getTableCols();
        }
      } else if (filterData === 'All') {
        this.activeFilter = filterData;
        this.listData = Object.assign([], this.originalListArray);
        this.countData = this.listData && this.listData.length ? this.listData.length : 0;
      } else {
        this.activeFilter = filterData;
        this.listData = Object.assign([], this.originalListArray.filter(x => x.Status === filterData));
        this.countData = this.listData && this.listData.length ? this.listData.length : 0;
      }
    }
  }
  removeBAFAM(listData) {
    if (listData && listData.length) {
      let index = listData.findIndex(x => x.field === 'BA');
      if (index != -1) {
        listData.splice(index, 2);
      }
    }
  }
  styleObject(key): Object {
    switch (key) {
      case 'SurveyID':
        return { width: '80px' };
      case 'SurveyName':
        return { width: '115px' };
      case 'Instance':
        return { width: '60px' };
      case 'ContactName':
        return { width: '100px' };
      case 'EmailID':
        return { width: '140px' };
      case 'BA':
        return { width: '50px' };
      case 'FAM':
        return { width: '50px' };
      case 'DIV':
        return { width: '50px' };
      case 'SentOn':
        return { width: '95px' };
      case 'OrderNo':
        return { width: '95px' };
      case 'Disposition':
        return { width: '100px' };
      case 'Instance':
        return { width: '60px' };
      case 'NPS':
        return { width: '45px' };
      case 'FeedbackSubmitDate':
        return { width: '130px' };
      case 'OrderNo':
        return { width: '95px' };
      case 'TaskID':
        return { width: '115px' };
      case 'Task':
        return { width: '100px' };
      case 'TaskPriority':
        return { width: '75px' };
      case 'AssigneeName':
        return { width: '120px' };
      case 'NPS':
        return { width: '45px' };
      case 'Status':
        return { width: '60px' };
      case 'CreatedDate':
        return { width: '95px' };
      case 'Category':
        return { width: '75px' };
      case 'Task':
        return { width: '100px' };
      case 'TaskPriority':
        return { width: '75px' };
      case 'ResolvedDate':
        return { width: '95px' };
      case 'ClosedDate':
        return { width: '95px' };
      case 'RecievedBy':
        return { width: '100px' };
      case 'RecievedDate':
        return { width: '95px' };
      case 'RecievedMode':
        return { width: '100px' };
      case 'VOCtype':
        return { width: '75px' };
      case 'Description':
        return { width: '80px' };
      case 'Name':
        return { width: '100px' };
      case 'EntityId':
        return { width: '80px' };

      default:
        return {};
    }
  }
  operTaskEditPage(item) {
    if (item.SurveyType.toUpperCase() == 'TRANS' || item.SurveyType.toUpperCase() == 'DIST') {
      this.router.navigate(['ctls/task-listing/edit'], {
        queryParams: {
          TaskID: item.CTLSTaskListID,
          selectedRadioOption: item.SurveyType.toUpperCase() == 'TRANS' ? 1 : 2,
        },
        // skipLocationChange: true,
      });
    } else {
      this.router.navigate(['ctls/voc-listing/edit'], {
        queryParams: {
          TaskID: item.CTLSTaskListID,
          selectedRadioOption: 1,
        },
        // skipLocationChange: true,
      });
    }
  }
  showDescription(rowData = null, event = null) {
    this.showDesc = !this.showDesc;
    if (rowData && this.showDesc) {
      this.selectedSurveyTypeClass = 'description-modal-' + rowData.SurveyType.toLowerCase();
      this.selectedRowID = rowData.TaskID;
      this.selectedRow = rowData;
      this.styleObj = {'top': (event.pageY + 5) + 'px', 'left': (event.pageX - 15 ) + 'px' };
    } else {
      this.selectedRowID = null;
    }
  }
  setTableData() {
    if (this.selectedTaskType === 'AccountsList' &&  this.accountDetails && this.accountDetails.length) {
      this.listData = [...this.accountDetails.map(x => {
        return {
           Name: x.Name,
           EntityId: x.EntityId ? x.EntityId : x.StandardEntityId ? x.StandardEntityId : x.GlobalEntityId ? x.GlobalEntityId : "",
         };
      })];
      this.colTable = Object.assign([], CONSTANTS.colAccountListTable);
    } else if ([this.taskType.PROMOTER, this.taskType.DETRACTOR, this.taskType.NEUTRAL]
      .includes(this.selectedTaskType)) {
      if (this.surveyDetails && this.surveyDetails.length) {
        this.listData = [...this.surveyDetails.filter(x =>
          x.SubType.toLowerCase() === this.taskTypeMapping[this.selectedTaskType].toLowerCase())];
        this.colTable = Object.assign([], CONSTANTS.colSurveyRespondedViewAll);
      }
    } else {
      if (this.taskDetails && this.taskDetails.length) {
        this.listData = [...this.taskDetails.filter(x =>
          x.SurveyType.toLowerCase() === this.selectedTaskType.toLowerCase())];
        }
      this.colTable = Object.assign([], CONSTANTS.colVocTable);
    }
    if (this.hideBAFAMCol) {
      this.removeBAFAM(this.colTable);
    }
    this.filterGridData(this.listData, 0);
  }
  getTableCols() {
    if ([this.taskType.PROMOTER, this.taskType.DETRACTOR, this.taskType.NEUTRAL]
      .includes(this.selectedTaskType)) {
      this.colTable = Object.assign([], CONSTANTS.colSurveyRespondedViewAll);
    } else if (this.taskDetails && this.taskDetails.length) {
      this.colTable = Object.assign([], CONSTANTS.colVocTable);
    } else if (this.accountDetails && this.accountDetails.length) {
      this.colTable = Object.assign([], CONSTANTS.colAccountListTable);
    }
  }
  showSurveyDetails(rowData) {
    this.ctlsDashboardCustomerService
      .View360TransactionalSurveyDetailsById(rowData.SurveyID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        res['surveyType'] = 1;
        this.openViewDialog(res);
      });
  }
  openViewDialog(data) {
    const ref = this.dialogService.open(CtlsViewSurveyDetailsComponent, {
      width: '80%',
      height: '70vh',
      transitionOptions: '0ms',
      data: data,
      baseZIndex:999999
    });
    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((objSaveTask) => {
    });
  }
}
