import { PermissionsService } from 'src/app/app.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Message } from 'primeng/components/common/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { RelationshipFilterService } from 'src/app/shared/services/filter-relationship.service';
import { DistDashbaordService } from './../../Services/dist-dashbaord.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SessionStorageService } from 'src/app/components/surveys/services/session-storage.service';
import { filter } from 'rxjs/operators';
export const enum RES_MSG {
  DEF_USER = 'No default selected',
  DEF_ALL = 'No default selected for All',
  NO_SEARCH_DATA = 'No search results to display',
  ASK_ALL = 'Are you sure you want to make your selection Default for All?',
  ASK_USER = 'Are you sure you want to make your selection Default?'
}
@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {
  isSuperUser: boolean = false;
  noSearchData: String = null;
  noRecentSearchData: String = null;
  status: any[];
  surveylist: any;
  sureDefaultText: String;
  defaultSelectedAllbool: boolean = true;
  defaultSelectedbool: boolean = true;
  selectedCity: any;
  selectedStatus: any = "On Going";
  value: Date = new Date();
  /** Adv search variables */
  errormsgs: any = '';
  msgs: Message[] = [];
  successMsg: boolean = false;
  successMsgSure: boolean = false;
  selected: number;
  recentSelected: number;
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  surveyStatusCollection: any;
  surveyCategoryCollection: any;
  recentSearchCollection: any;
  selectedSurveyID: any;
  selectedRecentSurveyID: any;
  defaultSelected: any;
  defaultSelectedAll: any;
  defaultSelectedID: any;
  defaultSelectedAllID: any;
  recentSearchSelectedArray = [];
  searchSurveySelectedArray = [];
  makeDefaultbool: Boolean = false;
  responseLoader: Boolean = false;
  selectedDashSurveyId:any;
  periodType = {
    M: true,
    Q: false,
    H: false,
    Y: false,
  }
  delimiter = "|";
  isPeriodType: boolean = false;
  isDateRange: boolean = true;
  StartDate = "";
  EndDate = "";
  startDateCal = "";
  endDateCal = "";
  genericErrorMessage = 'An error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail;
  filterSurveyList: any;
  SearchFlag: boolean = false;
  filterStartDate: any;
  filterEndDate: any;
  origSurveyList: any;

  constructor(private dashboardWidgetService: DistDashbaordService, private permissionsService: PermissionsService, private sessionStorageService: SessionStorageService,
    private sharedService: SharedService, private filterService: RelationshipFilterService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    //this.filterStartDate = new Date(this.filterService.filterObj.StartDate);

    this.getBrowser()
    this.filterSurveyList = {
      FilterText: '',
      CategoryCode: '',
      Status: [],
      StartDate: this.filterStartDate,
      EndDate: this.filterEndDate
    };
  }
  ngOnInit() {
    this.getSurveyStatusAndCategory();
    this.checkPermission();
    this.filterService.surveyListSubject.pipe(filter(r => r !== null)).subscribe(val => {
      this.origSurveyList = val.surveylist;
    });
  }
  checkPermission() {
    //for role check
    this.permissionsService.CheckMultiplePermissionByMultipleKeys("FULL_ACCESS").then((data: any) => {
      if (data.FULL_ACCESS == true) {
        this.isSuperUser = true
      }
      else {
        this.isSuperUser = false;
      }
    }).catch(err => {
      this.isSuperUser = false;
    })
  }
  calculateStartAndEndDate(key) {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = date;

    if (key == "Q") {
      var quarter = Math.floor((date.getMonth() + 3) / 3);
      if (quarter == 1) {
        firstDay = new Date(y, 0, 1);
        lastDay = new Date(y, 2, 31);
      } else if (quarter == 2) {
        firstDay = new Date(y, 3, 1);
        lastDay = new Date(y, 5, 30);
      }
      else if (quarter == 3) {
        firstDay = new Date(y, 6, 1);
        lastDay = new Date(y, 8, 30);
      }
      else if (quarter == 4) {
        firstDay = new Date(y, 9, 1);
        lastDay = new Date(y, 11, 31);
      }
    }
    if (key == "H") {
      var hy = Math.floor((date.getMonth() + 6) / 6);
      if (hy == 1) {
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, 5, 30);
      }
      else if (hy == 2) {
        var firstDay = new Date(y, 6, 1);
        var lastDay = new Date(y, 11, 31);
      }
    }

    if (key == "Y") {
      var firstDay = new Date(y, 0, 1);
      var lastDay = new Date(y, 11, 31);
    }

    this.StartDate = this.changeDateFormate(firstDay);
    this.EndDate = this.changeDateFormate(lastDay);
  }
  getBrowser() {
    if(this.filterService.filterObj.PeriodType!=null && this.filterService.filterObj.PeriodType != ''){
      this.calculateStartAndEndDate(this.filterService.filterObj.PeriodType);
    }
    else{
      this.StartDate = this.filterService.filterObj.StartDate;
      this.EndDate = this.filterService.filterObj.EndDate;
    }
    if (window.navigator.userAgent.indexOf('Mozzila') != -1) {
      console.log("Moz")
      this.filterEndDate = new Date(this.EndDate);
      this.filterStartDate = new Date(this.StartDate);
    } else if (window.navigator.userAgent.indexOf('Trident/') != -1) {
      console.log("IE")
      this.filterEndDate = new Date(moment(this.EndDate, 'DD-MMM-YYYY').toString());
      this.filterStartDate = new Date(moment(this.StartDate, 'DD-MMM-YYYY').toString());
    } else if (window.navigator.userAgent.indexOf('Firefox') != -1) {
      console.log("fire")
      this.filterEndDate = this.EndDate;
      this.filterStartDate = this.StartDate;
    } else if (window.navigator.userAgent.indexOf('MSIE ') != -1) {
      console.log("MSIE")
      this.filterEndDate = new Date(moment(this.EndDate, 'DD-MMM-YYYY').format('DD-MM-YYYY'));
      this.filterStartDate = new Date(moment(this.StartDate, 'DD-MMM-YYYY').format('DD-MM-YYYY'));
    } else {
      console.log("chrome")
      this.filterEndDate = new Date(this.EndDate);
      this.filterStartDate = new Date(this.StartDate);
    }
  }
  getSurveyStatusAndCategory() {
    //for getting status ,category and recently search data
    this.responseLoader = true;
    this.dashboardWidgetService.getAdvancedSearchStatus().then(response => {
      if (response.Status == 'Success') {
        let data = response.Response.SearchstatusModel;
        this.surveyStatusCollection = data.map(x => {
          return { value: x.Status, label: x.Status }
        });
        this.filterSurveyList.Status = [this.surveyStatusCollection.find(x => x.value == "Ongoing").value]
      } else {
        (response.Errors != null && response.Errors.length > 0) ? this.statusFailedMessage(response.Errors[0].Message) : this.errorMessage();
      }
    });
    this.dashboardWidgetService.getAdvancedSearchCategory().then(response => {
      if (response.Status == 'Success') {
        let data = response.Response;
        this.surveyCategoryCollection = data.map(x => { return { value: x.CategoryCode, label: x.CategoryName } });
      }
      else {
        (response.Errors != null && response.Errors.length > 0) ? this.statusFailedMessage(response.Errors[0].Message) : this.errorMessage();
      }
    });
    this.dashboardWidgetService.getRecentlySearchedSurveys().then(response => {
      if (response.Status == 'Success' && response.Response != null) {
        this.responseLoader = false;
        let data = response.Response;
        if(data.RecentSurveyListResponseModel != null){
        if (data.RecentSurveyListResponseModel.length > 0 )  {
          this.noRecentSearchData = null;
          this.recentSearchCollection = data.RecentSurveyListResponseModel;
          this.recentSearchSelectedArray =[];
          this.recentSearchCollection.forEach(sn => {
            this.recentSearchSelectedArray.push({ label: sn.SurveyName, value: sn.SurveyID });
          });
        } else {
          this.noRecentSearchData = RES_MSG.NO_SEARCH_DATA;
        }}else{this.noRecentSearchData = RES_MSG.NO_SEARCH_DATA;}
        if (data.MarkDefaultModel && data.MarkDefaultModel.SurveyNameDefaultSelectedForUser != null) {
          this.defaultSelectedbool = false;
          this.defaultSelected = data.MarkDefaultModel.SurveyNameDefaultSelectedForUser + " (" + data.MarkDefaultModel.SurveyID + ")"
        } else {
          this.defaultSelectedbool = true;
          this.defaultSelected = RES_MSG.DEF_USER;
        }
        if (data.MarkDefaultAllModel && data.MarkDefaultAllModel.SurveyNameDefaultSelectedForAll != null) {
          this.defaultSelectedAllbool = false;
          this.defaultSelectedAll = data.MarkDefaultAllModel.SurveyNameDefaultSelectedForAll + " (" + data.MarkDefaultAllModel.SurveyID + ")"
        } else {
          this.defaultSelectedAllbool = true;
          this.defaultSelectedAll = RES_MSG.DEF_ALL;
        }
      } else if (response.Response == null) {
        this.noRecentSearchData = RES_MSG.NO_SEARCH_DATA;
      } else {
        (response.Errors != null && response.Errors.length > 0) ? this.statusFailedMessage(response.Errors[0].Message) : this.errorMessage();
      }
    })
  }

  SearchSurveyList(statusFlag) {
    //for getting data on search
    this.selectedDashSurveyId = this.filterService.filterObj.SurveyID;
    this.responseLoader = true;
    if (this.filterSurveyList.Status || this.filterSurveyList.Status != '' || this.filterSurveyList.CategoryCode != '' || this.filterSurveyList.FilterText != '') {
      this.SearchFlag = statusFlag
      var filterSurveyListClone = JSON.parse(JSON.stringify(this.filterSurveyList));
      filterSurveyListClone.StartDate = this.changeDateFormate(this.filterSurveyList.StartDate);
      filterSurveyListClone.EndDate = this.changeDateFormate(this.filterSurveyList.EndDate);
      filterSurveyListClone.Status = (this.filterSurveyList.Status != null) ? filterSurveyListClone.Status.toString() : null;
      this.dashboardWidgetService.searchSurvey(filterSurveyListClone, this.SearchFlag).then(response => {
        if (response.Status == 'Success' && response.Response.length > 0) {
          this.successMsg = false;
          this.responseLoader = false;
          this.noSearchData = null
          //this.surveylist = response.Response;
          this.searchSurveySelectedArray = [];
          response.Response.forEach(sn => {
            this.searchSurveySelectedArray.push({ label: sn.SurveyName, value: sn.SurveyID });
          });
        } else if (response.Response.length <= 0 && response.Status == 'Success') {
          this.successMsg = false;
          this.responseLoader = false;
          this.searchSurveySelectedArray = [];
          this.noSearchData = RES_MSG.NO_SEARCH_DATA;
        }
        else {
          (response.Errors != null && response.Errors.length > 0) ? this.statusFailedMessage(response.Errors[0].Message) : this.errorMessage();
        }
      });
    } else {
      this.successMsg = true;
      this.responseLoader = false;
      this.callErrorMsg('warn', '', 'Please select at least 1 filter to search');
    }
  }
  selectedSurvey(text, surveyID, i) {
    if (text == 'onsearch') {
      this.selected = i;
      this.recentSelected = null;
      this.selectedRecentSurveyID = null;
      this.selectedSurveyID = surveyID
    } else if (text == 'onrecent') {
      this.selected = null;
      this.recentSelected = i;
      this.selectedSurveyID = null;
      this.selectedRecentSurveyID = surveyID
    }
  }

  getSelectedDate(event, dateType) {
    var dateToSelect = 1;
    if (dateType === "endDate") {
      try {
        if (this.filterSurveyList.EndDate != null) {
          dateToSelect = parseInt(this.filterSurveyList.EndDate.split("-")[0]);
        }
      } catch{ }
    }
    if (dateType === "startDate") {
      try {
        if (this.filterSurveyList.StartDate != null) {
          dateToSelect = parseInt(this.filterSurveyList.StartDate.split("-")[0]);
        }
      } catch{ }
    }
    var lastDay = new Date(event.year, event.month, 0).getDate();
    if (lastDay < dateToSelect) {
      dateToSelect = lastDay
    }
    return dateToSelect;
  }

  SubmitData() {
    //for final submit selected survey and loadiong dashboard
    let ID;
     if (this.selectedSurveyID) {
      ID = this.selectedSurveyID
    } else if (this.selectedRecentSurveyID) {
      ID = this.selectedRecentSurveyID;
    } else {      
          this.dashboardWidgetService.getRelationalSurveyList("").then(response => {
          if (response.Status == 'Success' && response != null) {
            if((response.Response.Collection != null && response.Response.Collection.length > 0)){
            this.filterService.surveyListSubject.next({surveylist:response.Response.Collection,bool:true})}
            else{
              // this.noSurveyListError = true;
              // this.loadWidgets();
            }
          }                 
        });
    }     
    this.responseLoader = true;
    this.filterService.filterObj.SubCategory
    this.filterService.filterObj.StartDate = this.changeDateFormate(this.filterSurveyList.StartDate);
     this.filterService.filterObj.EndDate = this.changeDateFormate(this.filterSurveyList.EndDate);
      // this.filterService.filterObj.StartDate =this.StartDate;
    // this.filterService.filterObj.EndDate = this.EndDate;
    this.filterService.filterObj.SurveyID = ID;
    // this.filterService.filterObj.SurveyID = this.filterService.filterObj.SubCategory;
    let surveyList;

    surveyList = this.surveylist ? this.surveylist : this.origSurveyList;
    surveyList.forEach(sn => {
      sn.IsDefault = false;
    })
    surveyList.forEach(sn => {
      if (sn.SurveyID == ID) {
        sn.IsDefault = true;
      }
    })
    var filterSurveyListClone = JSON.parse(JSON.stringify(this.filterSurveyList));
    filterSurveyListClone.StartDate = this.filterSurveyList.StartDate;
    filterSurveyListClone.EndDate = this.filterSurveyList.EndDate;
    filterSurveyListClone.Status = (this.filterSurveyList.Status != null) ? filterSurveyListClone.Status.toString() : null;
    this.dashboardWidgetService.SaveRecentSearch(filterSurveyListClone).then(response => {
      if (response.Status == 'Success') {
        this.ref.close(this.filterService.filterObj);
        this.filterService.surveyListSubject.next({ surveylist: surveyList, bool: true })
        this.responseLoader = false;
        this.successMsg = false;
      }
      else {
        (response.Errors != null && response.Errors.length > 0) ? this.statusFailedMessage(response.Errors[0].Message) : this.errorMessage();
      }
    })
  }
  private changeDateFormate(date) {
    // Try and parse the passed value.
    var momentDate = moment(date);
    return momentDate.format('DD-MMM-YYYY');
  }
  sureMakeDefault(text) {
    // for ensuring if user wanted to make default
    let ID
    ID = this.selectedSurveyID ? this.selectedSurveyID : this.selectedRecentSurveyID;
    if (ID != null) {
      this.sureDefaultText = text;
      this.successMsg = false;
      this.successMsgSure = true
      this.makeDefaultbool = true;
      let msg = (this.sureDefaultText == 'forUser') ? RES_MSG.ASK_USER : RES_MSG.ASK_ALL;
      this.callErrorMsg('info', '', msg);
    } else {
      this.successMsgSure = false;
      this.successMsg = true;
      this.callErrorMsg('error', '', 'Please select survey');
    }
  }
  markDefaultSurvey() {
    // for making default survey
    this.makeDefaultbool = true;
    this.responseLoader = true;
    let ID
    let surveyName
    ID = this.selectedSurveyID ? this.selectedSurveyID : this.selectedRecentSurveyID;
    // ID = surveyName.match(/\(([^)]+)\)/)[1];
    let flag;
    flag = (this.sureDefaultText == 'forUser') ? false : true;
    this.dashboardWidgetService.MarkDefaultSurvey(ID, flag).then(response => {
      if (response.Status == 'Success' && response.Response != null) {
        let ID;
        if (this.sureDefaultText != null) {
          if (this.selectedSurveyID) {
            this.searchSurveySelectedArray.forEach(sn => {
              if (sn.value == this.selectedSurveyID) {
                ID = sn.label;
              }
            });
            if (this.sureDefaultText == 'forUser') {
              this.defaultSelected = ID;
              this.defaultSelectedbool = false
            }
            if (this.sureDefaultText == 'forAll') {
              this.defaultSelectedAll = ID;
              this.defaultSelectedAllbool = false;
            }
          } else if (this.selectedRecentSurveyID) {
            this.recentSearchCollection.forEach(sn => {
              if (sn.SurveyID == this.selectedRecentSurveyID) {
                ID = sn.SurveyName + " (" + sn.SurveyID + ")"
              }
            })
            if (this.sureDefaultText == 'forUser') {
              this.defaultSelected = ID;
              this.defaultSelectedbool = false;
            }
            if (this.sureDefaultText == 'forAll') {
              this.defaultSelectedAll = ID;
              this.defaultSelectedAllbool = false;
            }
          }
        }
        this.responseLoader = false;
        this.successMsg = false;
        this.successMsgSure = false;
      } else {
        this.makeDefaultbool = false;
        (response.Errors != null && response.Errors.length > 0) ? this.statusFailedMessage(response.Errors[0].Message) : this.errorMessage();
      }
    })

  }
  deleteDefaultSurvey(text) {
    //for delete survey
    let ID
    let surveyName
    surveyName = (text == 'forUser') ? this.defaultSelected : this.defaultSelectedAll;
    ID = surveyName.match(/\(([^)]+)\)/)[1];
    let flag;
    flag = (text == 'forUser') ? false : true;
    this.dashboardWidgetService.DeleteDefaultSurvey(ID, flag).then(response => {
      if (response.Status == 'Success') {
        if (text == 'forUser') {
          this.defaultSelectedbool = true;
          this.defaultSelected = RES_MSG.DEF_USER;
        } else if (text == 'forAll') {
          this.defaultSelectedAllbool = true;
          this.defaultSelectedAll = RES_MSG.DEF_ALL;
        }
      }
    })
  }
  resetSearchfilter(){
    this.filterSurveyList.FilterText = '';
  }
  resetData() {
    this.filterSurveyList.FilterText = '';
    this.filterSurveyList.CategoryCode = '';
    this.filterSurveyList.Status = '';
    this.searchSurveySelectedArray = [];
    this.noRecentSearchData = null;
    this.filterSurveyList.StartDate = this.filterStartDate;
    this.filterSurveyList.EndDate = this.filterEndDate;
    this.filterSurveyList.Status = [this.surveyStatusCollection.find(x => x.value == "Ongoing").value]
  }

  //severity: info/success/warn/error
  callErrorMsg(type, summary = '', detail = '') {    
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () { document.getElementById('ClearMsg1').click();
                           }, this.MessageTimeOut);
  }
  closeDialog() {
    this.ref.close()
  }
  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
    this.makeDefaultbool = false;
  }
  errorMessage() {
    this.successMsg = true;
    this.responseLoader = false;
    this.callErrorMsg('error', '', this.genericErrorMessage);
  }
  statusFailedMessage(msg) {
    this.successMsg = true;
    this.responseLoader = false;
    this.callErrorMsg('error', '', msg);
  }
}
