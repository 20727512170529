import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FileUploadModule} from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuItem } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { StepsModule } from 'primeng/steps';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import {ScrollPanelModule} from 'primeng/scrollpanel';

import {BlockUIModule} from 'primeng/blockui';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar';
import { AccordionModule } from 'primeng/accordion';
import { CarouselModule } from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import {TreeTableModule} from 'primeng/treetable';
import {OrderListModule} from 'primeng/orderlist';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    DropdownModule,
    PaginatorModule,
    MegaMenuModule,
    DialogModule,
    ButtonModule,
    TabMenuModule,
    InputTextModule,
    CheckboxModule,
    InputTextareaModule,
    RadioButtonModule,
    ToastModule,
    DynamicDialogModule,
    TabViewModule,
    RadioButtonModule,
    StepsModule,
    TabViewModule,
    InputSwitchModule,
    MultiSelectModule,
    MessageModule,
    MessagesModule,
    PanelModule,
    AutoCompleteModule,
    //ConfirmationService,
    ConfirmDialogModule,
    BlockUIModule,
    AccordionModule,
    CarouselModule,
    CalendarModule,
    ScrollPanelModule,
    ChartModule,
    TreeModule,
    OverlayPanelModule,
    ListboxModule,
    TreeTableModule,
    OrderListModule
    ],
  exports: [
    TableModule,
    DropdownModule,
    PaginatorModule,
    MegaMenuModule,
    DialogModule,
    ButtonModule,
    TabMenuModule,
    InputTextModule,
    CheckboxModule,
    InputTextareaModule,
    RadioButtonModule,
    ToastModule,
    TabViewModule,
    DynamicDialogModule,
    RadioButtonModule,
    StepsModule,
    TabViewModule,
    InputSwitchModule,
     MultiSelectModule,
    MessageModule,
    MessagesModule,
    PanelModule,
    AutoCompleteModule,
    //ConfirmationService,
     ConfirmDialogModule,
     BlockUIModule,
     CalendarModule,
   // ConfirmationService,
    FileUploadModule,
    AccordionModule,
    CarouselModule,
    CalendarModule,
    ScrollPanelModule,
    TreeModule,
    OverlayPanelModule,
    ListboxModule,
    TreeTableModule,
    OrderListModule
  ],
  declarations: [],
  providers: [MessageService,
    ]
})
export class PrimengModule { }
