import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { DynamicDialogRef, Message, MessageService } from 'primeng/api';
import { SharedService } from '../../services/shared.service';
import { UserFeedbackService } from 'src/app/shared/services/user-feedback.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss']
})
export class UserFeedbackComponent implements OnInit {
  private assetpath = environment.assetpath;
  FeedbackModel: any = {
    Subject: '',
    Feedback: ''
  };
  feedbackform: FormGroup;
  submitted: boolean;
  FeedbackTypeOptions = [];
  ToAdminEmailID = this.sharedService.configuration.ContactUsEmail;

  showThankyouDiv = false;
  msgs: Message[] = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  genericErrorMessage = 'An error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail;
  isPartialSubmitted = false;
  isSubmitDisabled = false;

  constructor(
    private fbformBuilder: FormBuilder
    , public dynamicDialogRef: DynamicDialogRef
    , private messageService: MessageService
    , private sharedService: SharedService
    , private userFeedbackService: UserFeedbackService
  ) { }

  ngOnInit() {
    this.feedbackformValidations();
  }
  ngAfterViewInit() {
    document.getElementById('feedback-Subject').focus();
  }

  feedbackformValidations() {
    this.feedbackform = this.fbformBuilder.group({
      Subject: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      Feedback: new FormControl('', [
        Validators.required,
        Validators.maxLength(1000),
      ])
    });
  }

  submitFeedbackForm(data) {
    this.submitted = true;
    if (this.feedbackform.valid) {
      this.isSubmitDisabled = true;
      this.saveFeedback(data);
    } else {
      this.markControlsDirty();
      return false;
    }
  }

  markControlsDirty() {
    this.feedbackform.controls['Subject'].markAsDirty();
    this.feedbackform.controls['Feedback'].markAsDirty();
  }

  saveFeedback(data) {
    const feedbackData = {
      'UserID': 0,
      'Subject': data.Subject,
      'Feedback': data.Feedback
    };

    this.userFeedbackService.saveUserFeedback(feedbackData).subscribe(res => {
      if (res == null) {
        this.isSubmitDisabled = false;
        this.callErrorMsg('error', '', this.genericErrorMessage);
        return;
      }
      if (res.Status === 'Success') {
        this.showThankyouDiv = true;
        let ele = document.getElementsByClassName('userfeedback-popup')[0];
        ele.setAttribute('style', 'left: 40%; top: 35%;bottom: auto;');
      } else {
        this.isSubmitDisabled = false;
        let errorMsg = '';
        if (res.Errors != null && res.Errors.length > 0) {
          errorMsg = res.Errors.map(e => e.Message).join(', ');
        } else {
          errorMsg = this.genericErrorMessage;
        }
        this.callErrorMsg('error', '', errorMsg);
      }
    });
  }

  FeedbackHide() {
    this.dynamicDialogRef.close();
  }

  // severity: info/success/warn/error
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () { document.getElementById('btnFeedbackClearMsg').click(); }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
  }

  confirmFeedback() {
    if (this.FeedbackModel.Subject !== '' || this.FeedbackModel.Feedback !== '') {
      this.isPartialSubmitted = true;
    } else {
      this.FeedbackHide();
    }
  }

  PartialSubmitted(val) {
    if (val) {
      this.FeedbackHide();
    } else {
      this.isPartialSubmitted = false;
    }
  }

}
