
import { CtlsSpinnerService } from '../ctls-components/ctls-spinner/ctls-spinner.service';
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';

import { HttpBackend } from "@angular/common/http";
import { tap } from 'rxjs/operators';

  @Injectable({ providedIn: 'root' })
export class Loader {
  pending = false;
}

@Injectable()
export class CtlsHttpInterceptor implements HttpInterceptor{
  constructor(public spinnerService: CtlsSpinnerService) {}
  count = 0;
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // this.spinnerService.show();
    //If we want to avoid any request from inerceptor use this method.
    ////this.http.get(url, {headers:{skip:"true"});
    // const skipIntercept = req.headers.has('skip');
      // if (skipIntercept) {
        // req = req.clone({
        //       headers: req.headers.delete('skip')
        //   });
      // }else{
        this.spinnerService.show();
        this.count++;
        console.log("test--")
        req = req.clone(
          {
              headers: req.headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`)
          });  
        return next.handle(req).pipe(tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.count--;
              if (this.count == 0) this.spinnerService.hide();
            }
          },
          (err: any) => {
            if (req.headers.get("handleError") === "onService") {
              console.log("Interceptor does nothing...");
            } else {
              console.log("onInterceptor handle ", err);
            }
          }
        ));
      // }
      
  }
}