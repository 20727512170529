import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ctls-file-viewer',
  templateUrl: './ctls-file-viewer.component.html',
  styleUrls: ['./ctls-file-viewer.component.scss']
})
export class CtlsFileViewerComponent implements OnInit {
  @Input() FileUrl: string = "";

  constructor() { }

  ngOnInit() {
    this.isDocLoaded();
  }

  isDocLoaded() {
    //TODO if loading is not dismissed after file is loaded quickly, move the function call to ngonChanges
    const timeIntervalFrame = setInterval(() => {
      var el = document.getElementById('iframe');
      if (el) {
        clearInterval(timeIntervalFrame);
        el.addEventListener('load', function () {
          console.log("Load event: Iframe doc loaded. 1");
          document.getElementById("progressSpinner").style.display = "none";

          el.addEventListener('load', function () {
            console.log("Load event: Iframe doc loaded. 2");
          }, true);

        }, true);
      }
    }, (0));
  }
}
