import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-ctls',
  templateUrl: './header-ctls.component.html',
  styleUrls: ['./header-ctls.component.scss'],
})
export class HeaderCtlsComponent {
  @Input() public itemList: any;
  @Input() public index: any;
  @Input() public topheader: string;
  constructor() {}
}
