import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalconBreadCrumbModule } from './falcon-components/falcon-bread-crumb/falcon-bread-crumb.module';
import { FalconCommonService, FalconHttpInterceptor } from './falcon-services';
import { TodropdownPipe } from '../todropdown.pipe';
import { DynamicTooltipTableDirective } from './directives/dynamic-tooltip-table.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FalconSpinnerService } from './falcon-components/falcon-spinner/falcon-spinner.service';
import { PostcodeFilterComponent } from './falcon-components/postcode-filter/postcode-filter.component';
import { PostcodeFilterModule } from './falcon-components/postcode-filter/postcode-filter.module';
//import { FalconFilterHeaderModule } from './falcon-components/falcon-flter-header/falcon-filter-header.module';
//import { FalconSurveyOptionModule } from './falcon-components/falcon-survey-options/falcon-survey-options.module';
//import { FalconCommonCommentModule } from './falcon-components/falcon-common-comment/falcon-commomn-comment.module';
//import { FalconShortFilterHeaderModule } from './falcon-components/falcon-short-flter-header/falcon-short-filter-header.module';

const componentArray = [];
const exportModulesArray = [
  FalconBreadCrumbModule,
  //FalconFilterHeaderModule,
  //FalconShortFilterHeaderModule,
  //FalconSurveyOptionModule,
];
@NgModule({
  imports: [
    CommonModule,
    FalconBreadCrumbModule,
    PostcodeFilterModule
    //FalconFilterHeaderModule,
    //FalconShortFilterHeaderModule,
    //FalconSurveyOptionModule,
    //FalconCommonCommentModule,
  ],
  declarations: [componentArray, TodropdownPipe, DynamicTooltipTableDirective],
  exports: [...componentArray, ...exportModulesArray, TodropdownPipe, DynamicTooltipTableDirective, PostcodeFilterModule],
  providers: [FalconCommonService,FalconSpinnerService,
    { provide: HTTP_INTERCEPTORS, useClass: FalconHttpInterceptor, multi: true }]
})
export class FalconSharedModule {}
// app-ctls-filter-header
