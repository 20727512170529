import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'assetsPathname'
})
export class GetAssetsPathPipe implements PipeTransform {
    transform(path: string): string {
        if (!path)
            return '';
        const brokenPath = path.split('/');
        
        if (brokenPath.length > 0) {
            return 'assets/images/' + brokenPath[brokenPath.length - 1];
        }
        else {
            return '';
        }
    }
}