import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CtlsTaskSurveyFeedbackService } from 'src/app/Modules/ctls/ctls-shared/ctls-services';
import * as _ from 'lodash';
import { PopupMessageService } from 'src/app/Modules/ctls/ctls-shared/ctls-services/ctls-popup-message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-ctls-create-edit-task-popup',
  templateUrl: './ctls-create-edit-task-popup.component.html',
  styleUrls: ['./ctls-create-edit-task-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtlsCreateEditTaskPopupComponent implements OnInit, OnDestroy {
  objPriorities: any;
  objAssignees: any;
  objCategory: any;
  objCommentHistory: any;
  objActions: any;
  objRecommendedActions: any;
  objDesignations: any;
  objDivs: any;
  objBrands: any;
  objStatus: any;
  blnTaskFromGrid = false;
  objHeaderContent: any;
  strMaxSizeError = '';
  objLanguages: any;
  surveyIDList: any[] = [];
  showCustomerData = false;
  selectedRadio = '';
  selectedSubRadio = 'internal';
  initialStatus: any;
  commentRequired = false;
  categoryRequired = false;
  emailRequired = false;
  userSelectionRequired = false;
  falconUserRequired = false;
  intFileTotal = 0;
  uplodedFiles: FileList;
  objTaskForm = this.fb.group({
    strStatus: [''],
    strComments: [''],
    strTransComments: [''],
    strLanguage: [''],
    strCategory: [],
    strTaskAction: [],
    strAssignee: [],
    strEmailID: [''],
    strCommunicationType: [],
  });
  chartView: any[] = [
    { label: 'Country Comparison', value: 'country' },
    { label: 'FAM Comparison', value: 'fam' },
    { label: 'Brand Comparison', value: 'brand' },
  ];
  selectedStatus: string;
  IPAddress: any;
  destroy$ = new Subject();
  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    public dynamicDialogRef: DynamicDialogRef,
    private ctlsTaskSurveyFeedbackService: CtlsTaskSurveyFeedbackService,
    public cd: ChangeDetectorRef,
    private popupMessageService: PopupMessageService,
    private http: HttpClient
  ) {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    this.http
      .get<{ ip: string }>('https://jsonip.com', { headers: headers })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.IPAddress = data.ip;
      });
  }
  ngOnInit() {
    this.objTaskForm.get('strCategory').disable();
    if (this.config.data.operation === 'FROM_GRID') {
      this.surveyIDList.push(this.config.data.objData);
      this.blnTaskFromGrid = true;
      this.objHeaderContent = this.config.data.objData;
    }
    /**
     * Dropdown API
     */
    this.ctlsTaskSurveyFeedbackService
      .getManualTaskFeedbackMasters('')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data.Response) {
          let objAllDropdowns = data.Response;
          this.objStatus = objAllDropdowns.statuses;
          this.objLanguages = objAllDropdowns.languages;
          this.initialStatus = this.objHeaderContent.CTLSStatusID;
          const selectedStatus = this.objStatus.find(
            (i) => i.CTLSStatusID === this.initialStatus
          );
          this.objTaskForm.controls.strStatus.setValue(selectedStatus);
        }
        this.objLanguages.map((item) => {
          item.Language = item.LanguageDescription + '(' + item.ISOCode + ')';
        });
        this.cd.detectChanges();
      });
    this.getUsers(this.objHeaderContent);
    this.getCategory(this.objHeaderContent);
    this.getCommentHistory(this.objHeaderContent);
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * toggle Customer Data
   */
  toggleCustomerData() {
    this.showCustomerData = !this.showCustomerData;
  }
  /**
   * Transalte comment
   */
  translate() {
    if (!!this.objTaskForm.value.strComments) {
      let objData = {
        LanguageId: this.objTaskForm.value.strLanguage.LangID,
        TextToTranslate: this.objTaskForm.value.strComments,
      };
      this.ctlsTaskSurveyFeedbackService
        .getTranslateWithGoogle(objData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.Response) {
            this.objTaskForm
              .get('strTransComments')
              .setValue(res.Response.TranslatedText);
          }
          this.cd.detectChanges();
        });
    }
  }

  /**
   * Close popup
   */
  close() {
    this.objTaskForm.reset();
    this.dynamicDialogRef.close('close');
  }
  /**
   * check file size abouve 20 mb
   */
  checkFormatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return '0 Bytes';
    let k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    if (sizes[i] === 'MB') {
      if (parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) > 20) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  /**
   * clear all uploaded files
   */
  removeAllUplodedFile() {
    this.attach.nativeElement.value = '';
    this.strMaxSizeError = '';
    this.intFileTotal = 0;
  }
  /**
   * vaildate file type
   */
  isValidFile(file) {
    if (
      file.name.toLowerCase().endsWith('.jpg') ||
      file.name.toLowerCase().endsWith('.jpeg') ||
      file.name.toLowerCase().endsWith('.png') ||
      file.name.toLowerCase().endsWith('.tiff') ||
      file.name.toLowerCase().endsWith('.bmp') ||
      file.name.toLowerCase().endsWith('.msg') ||
      file.name.toLowerCase().endsWith('.doc') ||
      file.name.toLowerCase().endsWith('.docx') ||
      file.name.toLowerCase().endsWith('.pdf')
      // image/*, .doc, .docx, .pdf, .msg
    ) {
      return true;
    }
  }
  /**
   * Select files
   */
  @ViewChild('attach')
  attach: ElementRef;
  selectFiles(event) {
    this.strMaxSizeError = '';
    if (event.target.files && event.target.files[0]) {
      // this.attach.nativeElement.value = event.target.files;
      this.intFileTotal = event.target.files.length;
      if (this.intFileTotal > 5) {
        this.strMaxSizeError = 'Only 5 attachment can be added';
        this.intFileTotal = 0;
        this.attach.nativeElement.value = '';
        return;
      } else {
        this.strMaxSizeError = '';
      }
      let filesAmount = event.target.files.length;
      let intByteSize = 0;
      for (let i = 0; i < filesAmount; i++) {
        intByteSize += event.target.files[i].size;
      }
      let blnTotalMb = this.checkFormatFileSize(intByteSize, 2);
      if (blnTotalMb) {
        this.strMaxSizeError = 'Total size of attachment/s cannot exceed 20MB';
        this.intFileTotal = 0;
        this.attach.nativeElement.value = '';
      } else {
        this.strMaxSizeError = '';
      }
      const selectedFiles = (event.target as HTMLInputElement).files;
      Array.from(selectedFiles).forEach((file) => {
        if (!this.isValidFile(file)) {
          this.strMaxSizeError = 'Invalid file format';
          this.intFileTotal = 0;
          this.attach.nativeElement.value = '';
          return;
        }
      });

      if (!!!this.strMaxSizeError) {
        this.uplodedFiles = (event.target as HTMLInputElement).files;
      }
    } else {
      this.attach.nativeElement.value = '';
      this.intFileTotal = 0;
    }
  }
  /**
   * Save task
   */
  saveTask() {
    const selectedStatus = this.objTaskForm.value.strStatus;
    const selectedCategory = this.objTaskForm.value.strCategory;
    const selectedAssignee = this.objTaskForm.value.strAssignee;
    const reqBody = {
      IPAddress: this.IPAddress,
      CTLSTaskListID: this.objHeaderContent.TaskID,
      TaskAction: this.selectedRadio,
      CommunicationType:
        this.selectedRadio === 'Communication' ? this.selectedSubRadio : '',
      AssigneeID: selectedAssignee
        ? selectedAssignee.CTLSAssigneeMappingID
        : '',
      Emailid: this.objTaskForm.value.strEmailID
        ? this.objTaskForm.value.strEmailID
        : '',
      Comment: this.objTaskForm.value.strComments,
      StatusID: selectedStatus ? selectedStatus.CTLSStatusID : '',
      ViewedFlag: false,
      CTLSCategoryID: selectedCategory ? selectedCategory.CTLSCategoryID : '',
    };

    let objSaveTask = {
      data: reqBody,
      surveyIDList: this.surveyIDList,
      uplodedFiles: this.uplodedFiles ? this.uplodedFiles : null,
      operation: 'SAVE_TASK',
    };
    this.popupMessageService.sendMessage(objSaveTask); // pass data from popup to container
    this.resetPage();
    this.getCommentHistory(this.objHeaderContent);
  }
  resetPage() {
    this.categoryRequired = false;
    this.commentRequired = false;
    this.emailRequired = false;
    this.userSelectionRequired = false;
    this.setDynamicValidators();
    this.objTaskForm.reset();
    this.uplodedFiles = null;
    this.intFileTotal = 0;
    this.strMaxSizeError = '';
    this.selectedSubRadio = 'internal';
    this.objTaskForm.patchValue({
      strCommunicationType: 'internal',
    });
  }
  /**
   * chang of status
   * @param e selected status
   */
  onChangeStatus(e, type) {
    this.objTaskForm.get('strCategory').enable();
    this.commentRequired = true;
    this.categoryRequired = true;
    // if (this.initialStatus === e.value.CTLSStatusID) {
    this.setDynamicValidators();
  }
  /**
   * chang of Category
   * @param e selected Category
   */
  onChangeCategory(e, type) {
    this.commentRequired = true;
    this.setDynamicValidators();
  }
  /**
   * set required validator for comment, users list
   */
  setDynamicValidators() {
    if (this.commentRequired) {
      // set comment validation
      this.objTaskForm.controls['strComments'].setValidators([
        Validators.required,
      ]);
      this.objTaskForm.controls['strComments'].updateValueAndValidity();
    } else {
      this.objTaskForm.controls['strComments'].clearValidators();
      this.objTaskForm.controls['strComments'].updateValueAndValidity();
    }
    if (this.categoryRequired) {
      // set category validation
      this.objTaskForm.controls['strCategory'].setValidators([
        Validators.required,
      ]);
      this.objTaskForm.controls['strCategory'].updateValueAndValidity();
    } else {
      this.objTaskForm.controls['strCategory'].clearValidators();
      this.objTaskForm.controls['strCategory'].updateValueAndValidity();
    }
    if (this.emailRequired) {
      // set email vaildation
      this.objTaskForm.controls['strEmailID'].setValidators([
        Validators.required,
        Validators.email,
      ]);
      this.objTaskForm.controls['strEmailID'].updateValueAndValidity();
    } else {
      this.objTaskForm.controls['strEmailID'].clearValidators();
      this.objTaskForm.controls['strEmailID'].updateValueAndValidity();
    }

    this.cd.detectChanges();
  }

  /**
   * getUsers
   */
  getUsers(objHeaderContent) {
    this.objAssignees = [];
    /**
     * Dropdown API
     */
    const req = {
      params: {
        desigId: objHeaderContent.DesignationID,
        div: objHeaderContent.DIVID,
        FAM: objHeaderContent.FAM,
        BA: objHeaderContent.BA,
      },
    };
    this.ctlsTaskSurveyFeedbackService
      .GetAssignee(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.objAssignees = _.uniqBy(res.Response, 'CTLSAssigneeMappingID');
        this.cd.detectChanges();
      });
  }
  /**
   * Get Comment History
   */
  getCommentHistory(objHeaderContent) {
    this.objCommentHistory = [];
    /**
     * Dropdown API
     */
    const CTLSTaskListID = objHeaderContent.TaskID;
    this.ctlsTaskSurveyFeedbackService
      .GetCommentHistory(CTLSTaskListID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.objCommentHistory = res.Response;
        this.cd.detectChanges();
      });
  }
  /**
   * GetCategory
   */
  getCategory(objHeaderContent) {
    this.objCategory = [];

    /**
     * Dropdown API
     */

    this.ctlsTaskSurveyFeedbackService
      .GetCategory()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.objCategory = res.Response;
        this.cd.detectChanges();
      });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.objTaskForm.controls;
  }
  /**
   * strTaskAction radio button
   */
  radioButtonClick() {
    this.selectedRadio = this.objTaskForm.value.strTaskAction;
    this.commentRequired = true;
    this.falconUserRequired = true;
    this.userSelectionRequired = true;
    this.setDynamicValidators();
    this.selectedSubRadio = 'internal';
    this.objTaskForm.patchValue({
      strCommunicationType: 'internal',
    });
  }
  /**
   * strCommunicationTyperadio button
   */
  subRadioButtonClick() {
    this.selectedSubRadio = this.objTaskForm.value.strCommunicationType;
    if (
      this.selectedSubRadio === 'external' ||
      this.selectedSubRadio === 'customer'
    ) {
      this.emailRequired = true;
      this.userSelectionRequired = false;
    } else {
      this.emailRequired = false;
      this.userSelectionRequired = true;
    }
    this.setDynamicValidators();
  }
  /**
   * get control of email field
   */
  get primaryEmail() {
    return this.objTaskForm.get('strEmailID');
  }
}
