import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { LostBusinessLBCTotaldrilldown } from '../model/lost-business-lbcdrilldown';
import { LostBusinessDrilldownService } from '../service/lost-business-drilldown.service';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Paginator } from 'primeng/paginator';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lbc-totalcount-list-drilldown',
  templateUrl: './lbc-totalcount-list-drilldown.component.html',
  styleUrls: ['./lbc-totalcount-list-drilldown.component.scss']
})
export class LbcTotalcountListDrilldownComponent implements OnInit {
  private imagepath = environment.imagepath;
  @ViewChild(Paginator) dt: Paginator;
  @Input() showTotalText: any;
  @Input() totalCustomerCount: number;
  @Input() lostBaseYear:any;
  @Output('Previous') Previous: EventEmitter<any> = new EventEmitter();
  @Output('backtofirst')  backtofirst: EventEmitter<any> = new EventEmitter();
  @Output('firstlist')firstlist:EventEmitter<any> = new EventEmitter();
  @Output('divclose') divclose:EventEmitter<any>=new EventEmitter<any>();


  drilldownListData: LostBusinessLBCTotaldrilldown[];
  cols: any[];
  totalRecords: number;
  loading: boolean = false;
  pageNo = 1;
  pageSize = 10;
  configRow: number[] = [10, 20, 30, 40, 50];
  sortObj: any = {
    'sortType': '',
    'sortField': ''
  };
  accountNumber: string='';
  customerName: string='';
  disposition: any;
  searchString: string = '';
  searchBy: string = '';
  successMsg: boolean = false;
  msgs: Message[] = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  apiResponseData: any;
  paginatorFirstSelector: any;
  paginatorLaststSelector: any;
  paginatorNextSelector: any;
  paginatorPrevSelector: any;
  showNoData: boolean = false;
  showErrorData: boolean = false;
  genericErrorMessage = 'An error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail;  columnList = [];
  searchPlaceholder = 'Enter your text here';
  dataNotFoundMessage: any;
  dispositionValue: any;
  flag:any;
  DispositionCount:any;
  FAMID: number;
  FAMCode: string='';
  BAID:number;
  compSTdate: null;
  compEDdate: null;
  Data:any;
  constructor(private LostBusinessDrilldownService: LostBusinessDrilldownService
    , private messageService: MessageService
    , private sharedService: SharedService
    ) {
    this.dispositionValue = { ...this.LostBusinessDrilldownService.ddNav1GetData() };
    this.Data = this.dispositionValue.Data;
   // console.log(Data, 'DispoDataNav');
       this.accountNumber = this.Data.accountNumber;
       this.customerName = this.Data.custName;
       this.disposition = this.Data.DispositionStatus;
       this.DispositionCount = this.Data.DispositionCount;
       this.FAMCode = this.Data.FAMCode;
       this.FAMID = this.Data.FAMID;
       this.BAID =this.Data.BAID;
   
  }

  ngOnInit() {
    this.GetData();
  }
  GetData() {

    this.pageNo = 1;
    this.pageSize = 10;
    this.getColumnNameList();
    if (this.disposition == "Completed survey"){
      this.cols = [
        //{ field: 'AccountNo', header: 'Account Number' },
       // { field: 'CustomerName', header: 'Customer Name' },
       // { field: 'FAMCode', header: 'FAM' },
        { field: 'Contact', header: 'Contact Name' },
        { field: 'ContactEmail', header: 'Contact Email Address' },
        { field: 'SurveyId', header: 'Survey ID' },
        { field: 'SubmitDate', header: 'Submit Date' },
        { field: 'BrandName', header: 'Brand' },
        { field: 'NPSRating', header: 'NPS Rating' },
        { field: 'BACode', header: 'BA' },
        { field: 'Divisioncode', header: 'DIV' },


      ];
    } else if (this.disposition == "Did not respond") {
      this.cols = [
       // { field: 'AccountNo', header: 'Account Number' },
       // { field: 'CustomerName', header: 'Customer Name' },
       { field: 'Contact', header: 'Contact Name' },
       { field: 'ContactEmail', header: 'Contact Email Address' },
       { field: 'SurveyId', header: 'Survey ID' },
       { field: 'SubmitDate', header: 'Submit Date' },
       { field: 'BrandName', header: 'Brand' },
       { field: 'NPSRating', header: 'NPS Rating' },
       { field: 'BACode', header: 'BA' },
       { field: 'Divisioncode', header: 'DIV' },
      ];
    } else if (this.disposition == "Blocked in X/Y rule") {
      this.cols = [
        //{ field: 'AccountNo', header: 'Account Number' },
       // { field: 'CustomerName', header: 'Customer Name' },
       { field: 'Contact', header: 'Contact Name' },
       { field: 'ContactEmail', header: 'Contact Email Address' },
       { field: 'LstTrnsSurveyDate', header: 'Last Transactional Survey Date' },
       { field: 'SubmitDate', header: 'Submit Date' },
       { field: 'BrandName', header: 'Brand' },
       { field: 'NPSRating', header: 'NPS Rating' },
       { field: 'BACode', header: 'BA' },
       { field: 'Divisioncode', header: 'DIV' },

      ];
    } else {
      this.cols = [
       // { field: 'AccountNo', header: 'Account Number' },
       // { field: 'CustomerName', header: 'Customer Name' },
       { field: 'Contact', header: 'Contact Name' },
       { field: 'ContactEmail', header: 'Contact Email Address' },
       { field: 'SubmitDate', header: 'Submit Date' },
       { field: 'BrandName', header: 'Brand' },
       { field: 'NPSRating', header: 'NPS Rating' },
       { field: 'BACode', header: 'BA' },
       { field: 'Divisioncode', header: 'DIV' },
      ];
    }

    this.drilldownListData = [];
    this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.accountNumber, this.customerName, this.disposition, this.searchString, this.searchBy,this.FAMID,this.BAID);
  }

  dropcolumnlist(){
      this.columnList = [
        { name: 'Search All', code: null },
        { name: 'Contact Name', code: 'ContactName' },
        { name: 'Contact Email Address', code: 'ContactEmailID' },
        { name: 'SurveyID', code: 'SurveyID' },
        { name: 'Brand', code: 'Brand' }, 
        { name: 'BA', code: 'BACode' },
        { name: 'DIV', code: 'DIVCode' },         
      ];
    
  }

  getColumnNameList() {
     if (this.disposition == "Completed survey"){ 
        this.dropcolumnlist();
      }
      else if(this.disposition == "Did not respond"){
        this.dropcolumnlist();
      }
      else {
      this.columnList = [
        { name: 'Search All', code: null },   
        { name: 'Contact Name', code: 'ContactName' },
        { name: 'Contact Email Address', code: 'ContactEmailID' },
        { name: 'Brand', code: 'Brand' },
        { name: 'BA', code: 'BACode' },
        { name: 'DIV', code: 'DIVCode' },
      
      ];
    }
  }

  onChange(event) {
    if (event.value != null) {
      this.searchBy = event.value.code;
    } else {
      this.searchBy = '';
    }
    // To change place holder of search textbox
    if (this.searchBy === 'FAMCode') {
      this.searchPlaceholder = 'Search by FAM';
    } else if (this.searchBy === 'BACode') {
      this.searchPlaceholder = 'Search by BA';
    } else if (this.searchBy === 'Brand') {
      this.searchPlaceholder = 'Search by Brand';
    } else if (this.searchBy === 'CustomerName') {
      this.searchPlaceholder = 'Search by Customer Name';
    } else if (this.searchBy === 'AccountNo') {
      this.searchPlaceholder = 'Search by Account Number';
    } else if (this.searchBy === 'ContactName') {
      this.searchPlaceholder = 'Search by Contact Name';
    } else if (this.searchBy === 'ContactEmailID') {
      this.searchPlaceholder = 'Search by Contact Email Address';
    } else if (this.searchBy === 'LstTrnsSurveyDate') {
      this.searchPlaceholder = 'Search by Survey Date';
    } else if (this.searchBy === 'SurveyID') {
      this.searchPlaceholder = 'Search by SurveyID';
    } else if (this.searchBy === 'DIVCode') {
      this.searchPlaceholder = 'Search by DIV';
    }
    else {
      this.searchPlaceholder = 'Enter your text here';
    }

  }
  searchByFields(event) {
    if (event && event.which == '13' || event.which == '1') {
      if (this.searchString != null && this.searchString !== '') {
        this.dataNotFoundMessage = 'No matching search results.';
        //this.showNoData = true;
      }
      this.searchString = this.searchString;
      this.pageNo = 1;
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.accountNumber, this.customerName, this.disposition, this.searchString, this.searchBy,this.FAMID,this.BAID);
      this.dt.first = 0;
    }
  }
  resetSearchfilter() {
    this.searchString = '';
    this.pageNo = 1,
      this.pageSize = 10;
    this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.accountNumber, this.customerName, this.disposition, this.searchString, this.searchBy,this.FAMID,this.BAID);
    this.dt.first = 0;
  }

  paginationData(pageNo, pageSize, sortField = '', sortType='', accountNumber, customerName, disposition, searchString='', searchBy='',FAMID,BAID) {
    this.loading = true;
    this.drilldownListData = [];
    this.drilldownListData.push({} as LostBusinessLBCTotaldrilldown);
    this.LostBusinessDrilldownService.getTotalCustomerList(pageNo, pageSize, sortField, sortType, accountNumber, customerName, disposition, searchString, searchBy,FAMID,BAID).then(rawData => {
      this.pageNavigationToolTip();
      //console.log(rawData.Response);
      if (rawData == null) {

        this.drilldownListData = [];
        this.totalRecords = 0;
        this.pageNo = pageNo;
        this.pageSize = pageSize;
        this.loading = false;
      } else if (rawData.Status == "Success" && rawData.Response.Collection.length == 0 && this.searchBy == '' && this.searchString == ''){
        this.showNoData = true;
      } else if (rawData.Status === "Failure") {
        this.showErrorData = true;
        } else {
        let rawResponse: any = rawData
        if (rawResponse.Status == 'Success') {
          let data = rawResponse.Response;
          //console.log(data, "data");
          this.drilldownListData = data.Collection;
         // console.log(this.drilldownListData, "drilldowndata");
          this.totalRecords = data.TotalRecords;
          this.pageNo = data.CurrentPageNo;
          this.pageSize = data.PageSize;
          this.loading = false;
        } else {
          if (rawResponse.Errors != null && rawResponse.Errors.length > 0) {
            this.successMsg = true;
            this.callErrorMsg('error', '', this.genericErrorMessage);
          } else {
            this.showEmptyGrid(1, 10);
          }
        }
        this.loading = false;
      }
    }).catch(e => {
      this.drilldownListData = null;
      this.successMsg = false;
      //this.showErrorData = true;
      this.callErrorMsg('error', '', this.genericErrorMessage);
    });

  }
  showEmptyGrid(page: any, size: any) {
    this.drilldownListData = [];
    this.totalRecords = 0;
    this.pageNo = page;
    this.pageSize = size;
    this.loading = false;
  }
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    window.scrollTo(0, 0);
    setTimeout(function () { document.getElementById('ClearMsg').click(); }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }
  pageNavigationToolTip() {
    this.paginatorFirstSelector = <HTMLElement>document.querySelector("a.ui-paginator-first");
    this.paginatorFirstSelector.title = 'First';

    this.paginatorLaststSelector = <HTMLElement>document.querySelector("a.ui-paginator-last");
    this.paginatorLaststSelector.title = 'Last';

    this.paginatorNextSelector = <HTMLElement>document.querySelector("a.ui-paginator-next");
    this.paginatorNextSelector.title = 'Next';

    this.paginatorPrevSelector = <HTMLElement>document.querySelector("a.ui-paginator-prev");
    this.paginatorPrevSelector.title = 'Previous';
  }
  paginationNavigationDetails(event) {
    if (event.page >= this.pageNo || (event.page + 2) <= this.pageNo || this.pageSize != event.rows) {
      this.pageNo = event.page + 1;
      this.pageSize = event.rows;
      // tslint:disable-next-line: max-line-length
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.accountNumber, this.customerName, this.disposition, this.searchString, this.searchBy,this.FAMID,this.BAID);
    }
  }
  customSort(event: any) {
    if (event.sortField) {
      event.sortOrder = event.sortOrder == 1 ? 'asc' : 'dsc';

      this.pageNo = 1;
      this.sortObj.sortType = event.sortOrder;
      this.sortObj.sortField = event.sortField;
      // tslint:disable-next-line: max-line-length
      this.paginationData(this.pageNo, this.pageSize, event.sortField, event.sortOrder, this.accountNumber, this.customerName, this.disposition, this.searchString, this.searchBy,this.FAMID,this.BAID);
      this.dt.first = 0;
    }

  }
  //Export
  exportEventFile(event) {
    this.loading = true;
    this.pageSize = -1;
    this.LostBusinessDrilldownService.getLBCExcelDate();
    this.compSTdate = this.LostBusinessDrilldownService.CPStartDate;
    this.compEDdate = this.LostBusinessDrilldownService.CPEndDate;
   // console.log(this.compSTdate);
   // console.log(this.compEDdate);
    if (this.searchString == null)
      this.searchBy = '';
      this.flag= "Multiple";
    this.LostBusinessDrilldownService.exportData(this.pageNo=1, this.pageSize, this.sortObj.sortField, this.sortObj.sortType, this.accountNumber, this.customerName, this.disposition, this.searchString, this.searchBy,this.FAMID,this.FAMCode,this.flag,this.BAID,this.compSTdate,this.compEDdate).then(rawData => {
      let rawResponse: any = rawData;
      if (rawData.size > 0) {
        this.converBlobtToFile(rawResponse, 'TotalContacts_' + this.datetimestamp() + '.xlsx');
        this.pageSize = 10;
        this.loading = false;
      } else {
        this.loading = false;
      }
    }).catch(e => {
      this.loading = false;
      this.showErrorData = true;
    });
  }
  converBlobtToFile(theBlob: Blob, fileName: string) {

    if (navigator.appVersion.toString().indexOf('.NET') > 0)
      window.navigator.msSaveBlob(theBlob, fileName);
    else {
      let file: any = new Blob([theBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
  }


  datetimestamp() {
    var today = new Date();
    var sToday = (((today.getMonth() + 1) < 10 ? '0' : '') + (today.getMonth() + 1)).toString();
    sToday += ((today.getDate() < 10 ? '0' : '') + today.getDate()).toString();
    sToday += today.getFullYear().toString() + '_';
    sToday += (today.getHours() < 10 ? '0' : '') + today.getHours().toString();
    sToday += (today.getMinutes() < 10 ? '0' : '') + today.getMinutes().toString();
     return sToday;
  }
  onClickPrevious(){
     this.Previous.emit(this.Data);
  }
  Hidediv(){
    this.divclose.emit();
  }
  GotoTransList(){
    // this.firstlist.emit();
    //this.LostBusinessDrilldownService.Total1stddNavSubject.next(true);
    this.backtofirst.emit(this.Data);
  }
}
