import { widgetCodes } from './../../../shared/constants/shared.constants';
import { RelationshipDashboardSharedEventService } from './../../../shared/services/relationship-dashboard-shared-event.service';
import { Component, OnInit, Input, OnChanges, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DashboardWidgetService } from '../service/dashboard-widget.service';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ExportChartModel } from 'src/app/shared/models/export-chart.model';
import * as _html2canvas from "html2canvas";
const html2canvas:any = _html2canvas;
import { surveyType } from 'src/app/shared/constants/shared.constants';
import { DistDashbaordService } from 'src/app/Modules/Distributor_Survey/Services/dist-dashbaord.service';
import { HideOtherOpenDrilldownsService } from '../../../Modules/Distributor_Survey/Services/hide-other-open-drilldowns.service';
import { environment } from 'src/environments/environment';
const WIDGET_CODE = 'ar';

@Component({
  selector: 'app-average-rating',
  templateUrl: './average-rating.component.html',
  styleUrls: ['./average-rating.component.scss']
})
export class AverageRatingComponent implements OnInit, OnChanges, OnDestroy {
  private imagepath = environment.imagepath;
  loadAvgRatingData: boolean = false;
  boolAvgRatingLoader: boolean = false;
  @Input() avgRating: number;
  @Input() responseCount: number;
  @Input() ratingData: any[];
  @Input('boolAverageRatingLoader') boolAverageRatingLoader;
  @Input() errorData: boolean;
  @Input() surveyTypeInput: string = surveyType.Transactional;
  isVisible: boolean = false;
  avgDisaplay: string = "none";
  className: string = "";
  widgetCodesConst: any;
  // isShown:boolean = true;
  // showHide:string;
  starArr: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  private chartState: CHART_STATE_OPTIONS;
  private exportSubscription: Subscription;
  @ViewChild('pictureOf') pictureOf: ElementRef;

  constructor(private sharedService: SharedService, private dashboardWidgetService: DashboardWidgetService, private distDashbaordService: DistDashbaordService, private rdSharedEventService: RelationshipDashboardSharedEventService, private hideOtherOpenDrilldownsService : HideOtherOpenDrilldownsService) {
    this.widgetCodesConst = widgetCodes;
    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(val => val !== null && val.includes(WIDGET_CODE.toLowerCase()))).subscribe(val => {
      if (this.sharedService.acceptableChartState.includes(this.chartState)) {
        this.toCanvas();
      }
    });
    // this.updateExportDetails(true);
  }

  ngOnInit() {
    this.hideOtherOpenDrilldownsService.getDrillDownClickEvent().subscribe(e =>{
      if(e!=='average-rating'){
        this.isVisible = false;
        this.avgDisaplay ='none';
      }
    })
  }
  ngOnChanges() {
    this.caluclateAvgRating();
    this.avgDisaplay = 'none';
    this.loadAvgRatingData = true;
    // this.ratingData = this.ratingData.reverse();
    // this.determineWidgetState();
    this.updateExportDetails();
  }
  ngOnDestroy(): void {
    this.updateExportDetails(true);
    this.exportSubscription.unsubscribe();
  }

  caluclateAvgRating() {
    this.starArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    if (this.avgRating != null) {
      let tempStrAvg = this.avgRating.toFixed(1).toString().split('.');
      let rating = tempStrAvg[0];
      for (var i = 0; i <= parseInt(rating); i++) {
        this.starArr[i] = 1;
      }
      if (tempStrAvg[1] <= '5' && tempStrAvg[1] >= '1') {
        this.starArr[i] = 2;
      } else if (tempStrAvg[1] >= '6') {
        this.starArr[i] = 1;
      }
    }

  }

  onAvgPanelClose() {
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('average-rating')
    this.isVisible = !this.isVisible;
    this.boolAvgRatingLoader = true;
    if (this.isVisible == true && this.loadAvgRatingData == true) {
      this.avgDisaplay = 'block';

      if (this.surveyTypeInput != null && (this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput == 'OEM')) {
        this.distDashbaordService.getNpsRating().then(res => {
          this.formatAverageRatingData(res);
        });
      } else {
        this.dashboardWidgetService.getNpsRating().then(res => {
          this.formatAverageRatingData(res);
        });
      }
    }
    else if (this.isVisible == true && this.loadAvgRatingData == false) {
      this.avgDisaplay = 'block';
      this.boolAvgRatingLoader = false;
    }
    else
      this.avgDisaplay = 'none';
  }

  formatAverageRatingData(res) {
    if (res != null) {
      this.ratingData = [];
      res.forEach(element => {
        var ele = element;
        ele.ResponsePerc = ele.ResponsePerc != null ? ele.ResponsePerc : 0;
        ele.ResponseCnt = ele.ResponseCnt != null ? ele.ResponseCnt : 0;
        this.ratingData.push(ele);
      });

      //this.caluclateAvgRating();
      this.ratingData = this.ratingData.reverse();
      if (this.ratingData.length != 0) {
        this.className = this.isVisible ? 'longDiv' : '';
      }
    }
    this.loadAvgRatingData = false;
    this.boolAvgRatingLoader = false;
  }

  closeWidget() {
    if (this.surveyTypeInput != null && (this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput == 'OEM')) {
      this.rdSharedEventService.closeWidget(this.widgetCodesConst.AverageRating)
    } else {
      this.sharedService.widgetCloseSubject.next(this.widgetCodesConst.AverageRating);
    }
  }

  // onAvgRatingClose(){
  //   this.isShown = !this.isShown;
  //   this.showHide = this.isShown ? 'block': 'none';
  // }

  private toCanvas = () => {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    try {
      if (this.pictureOf && this.pictureOf.nativeElement) {
        html2canvas(this.pictureOf.nativeElement, { scrollX:0, scrollY:-window.scrollY, allowTaint: false, useCORS: false, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {

          chartUpdate.UpdateTime = new Date();
          chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
          chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
          chartUpdate.CurrentState = this.chartState;
          chartUpdate.ChartImgBase64 = canvas.toDataURL('image/png');

          this.sharedService.updateChartStatusSubject(chartUpdate);
        },
          err => {
            console.log('error occurred during image export, details as below \n', err);
            chartUpdate = this.exportErrorUpdate();
            this.sharedService.updateChartStatusSubject(chartUpdate);
          });
      } else if (this.chartState === CHART_STATE_OPTIONS.NO_DATA) {
        chartUpdate.UpdateTime = new Date();
        chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
        chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
        chartUpdate.CurrentState = this.chartState;
        chartUpdate.ChartImgBase64 = 'no_image_data_available_for_this_state';
        chartUpdate.HasNoData = true;
        this.sharedService.updateChartStatusSubject(chartUpdate);
      }
    } catch (error) {
      console.log('html2canvas error as', error);
      chartUpdate = this.exportErrorUpdate();
      this.sharedService.updateChartStatusSubject(chartUpdate);
    }
  }
  determineWidgetState() {
    if (this.boolAverageRatingLoader) { this.chartState = CHART_STATE_OPTIONS.LOADING; }
    else if (this.responseCount != null && this.responseCount > 0) { this.chartState = CHART_STATE_OPTIONS.SUCCESS; }
    else if (this.errorData) { this.chartState = CHART_STATE_OPTIONS.ERROR; }
    else if (!this.errorData) { this.chartState = CHART_STATE_OPTIONS.NO_DATA; }
  }
  updateExportDetails(isDestroyCall: boolean = false) {
    this.determineWidgetState();
    let chartUpdate: ExportChartModel = new ExportChartModel();
    chartUpdate.UpdateTime = new Date();
    chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
    if (isDestroyCall) {
      this.chartState = CHART_STATE_OPTIONS.UNKNOWN;
    }
    chartUpdate.CurrentState = this.chartState;
    this.sharedService.updateChartStatusSubject(chartUpdate);
  }
  exportErrorUpdate = (): ExportChartModel => {
    let err: ExportChartModel = new ExportChartModel();
    err.HasErrorInExport = true;
    err.UpdateTime = new Date();
    err.ChartName = WIDGET_CODE.toLowerCase();
    err.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
    err.CurrentState = this.chartState;
    return err;
  }

}
