import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LostbusinessCustomerService } from '../service/lostbusiness-customer.service';
import { Subscription } from 'rxjs';
import { RelationshipDashboardSharedEventService } from './../../../shared/services/relationship-dashboard-shared-event.service';
import { surveyType } from 'src/app/shared/constants/shared.constants';
import { SharedService } from 'src/app/shared/services/shared.service';
import { widgetCodes } from './../../../shared/constants/shared.constants';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-lostbusiness-left-widget',
  templateUrl: './lostbusiness-left-widget.component.html',
  styleUrls: ['./lostbusiness-left-widget.component.scss']
})
export class LostbusinessLeftWidgetComponent implements OnInit {
  private imagepath = environment.imagepath;
  //@Input('loading') loading;
  @Input() lostCustomerCount: number;
  @Input() boolLostcustLoader;
  @Input() errorData: boolean;
  @Input() lostBusinesssResponse: any;
  @Output('navigateLostWidget') navigateLostWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output('tinyLostWidgetClosed') tinyLostWidgetClosed: EventEmitter<any> = new EventEmitter<any>();
  @Input() showLostMainWidget;
  @Input("lostComparisonDate") lostComparisonDate: any;

  overlayDisplay: string = "none";
  dragDropHover: boolean = true;
  lsHeight: number;
  @Input() surveyTypeInput: string = surveyType.Transactional;
  widgetCodesConst: any;
  LostBusinessCustomer = null;
  lostBusinessText: string = "customers do not seem to have had any business transactions with us during";
  apiResponse: any = null;

  private LostCountSubject: Subscription;
  private NoDataFoundSubject: Subscription;
  private lostCountTextSubject: Subscription;
  //lostCustomerCount:number;
  constructor(private lostbusinessCustomerService: LostbusinessCustomerService,
    private rdSharedEventService: RelationshipDashboardSharedEventService,
    private sharedService: SharedService) {
    this.widgetCodesConst = widgetCodes;
    this.lostbusinessCustomerService.apiResponse.subscribe(res => {
      this.apiResponse = res;
    });

    this.NoDataFoundSubject = this.lostbusinessCustomerService.NoDataFoundSubject.subscribe(res => {
      if (res !== null && res) {
        this.showNoDateImage();
      }
    });

    this.lostCountTextSubject = this.lostbusinessCustomerService.lostCountTextSubject.subscribe(res => {
      if (res !== null && res) {
        this.updateText(res);
      }
    });

  }
  // GetLostCount(cnt) {
  //   //alert(cnt);
  //   this.lostCustomerCount=cnt;
  // }
  ngOnChanges() {
    // this.passDateToLostFilter = this.passDateToLostComponent;
    if ((this.boolLostcustLoader || this.errorData) && this.lostBusinesssResponse == null) {
      this.lostBusinesssResponse = null;
    }
  }
  showNoDateImage() {
    this.lostBusinesssResponse = null;
    this.errorData = false;
  }
  updateText(text){
   this.lostBusinessText = text;
  }

  ngOnDestroy() {
    if (this.NoDataFoundSubject) {
      this.NoDataFoundSubject.unsubscribe();
    }
    if(this.lostCountTextSubject){
      this.lostCountTextSubject.unsubscribe();
    }
  }

  clickHere(id) {
    this.navigateLostWidget.emit(id);
  }
  ngOnInit() {
  }
  closeWidget() {
    if (this.surveyTypeInput != null && (this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput == 'OEM')) {
      this.rdSharedEventService.closeWidget(this.widgetCodesConst.LostBusinessCustomer)
    } else {
      this.sharedService.widgetCloseSubject.next(this.widgetCodesConst.LostBusinessCustomer);
      this.tinyLostWidgetClosed.emit(new Date());
    }
  }
  showOverlay() {
    this.overlayDisplay = "block";
    this.dragDropHover = false;
    this.setHeightLostWidget();
  }
  hideOverlay() {
    this.overlayDisplay = "none";
    this.dragDropHover = true;
  }
  setHeightLostWidget() {
    //dynamic heigh loyalty shift widget
    var divHeight = <HTMLElement>document.getElementById("lsSce");
    this.lsHeight = 270;
    if (divHeight != null) {
      this.lsHeight = divHeight.clientHeight
    }
  }
}
