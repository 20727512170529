import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TemplateQuestionsService } from "../../services/template-questions.service";
import { MessageService } from 'primeng/api';
import { getOrCreateChangeDetectorRef } from '@angular/core/src/render3/di';
@Component({
  selector: 'app-matrix-single-select',
  templateUrl: './matrix-single-select.component.html',
  styleUrls: ['./matrix-single-select.component.scss']
})
export class MatrixSingleSelectComponent implements OnInit {
  cols: any = [];
  questionData: any[];
  @Input() data: any;

  @Output() displayChange = new EventEmitter();

  @Input() buttonDisable: boolean = true;

  capturedData: any = {};
  editable: boolean = false;

  selectedValues: string[] = [];
  rows = [];

  constructor(private messageService: MessageService, public _templateQuestionService: TemplateQuestionsService) { }

  ngOnInit() {
    this.capturedData = JSON.parse(JSON.stringify(this.data));

    this.getHeader(this.capturedData);
  }
  ngOnChanges() {
    this.capturedData = JSON.parse(JSON.stringify(this.data));
  }

  successSaveMsg() {
    this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Your responses has been saved' });
  }

  onRadioClick(event, col) {

  }

  headerList = [];

  getHeader(data) {

    var columns = this.data.RespAttributes.filter(ele => {
      if (ele.AttributeName == "C_OPTION") {
        return ele;
      }
    });


    this.cols = (columns.map(ele => {
      return { field: ele.AttributeValue, header: ele.AttributeValue }
    }));

    this.headerList = (columns.map(ele => {
      return { attrValue: ele.AttributeValue, attrId: ele.AttributeValue }
    }));

    this.rows = this.data.RespAttributes.filter(ele => {
      if (ele.AttributeName == "R_OPTION") {
        return ele;
      }
    });

  }
  toggleEdit() {
    this.editable = !this.editable;
    this.buttonDisable = !this.buttonDisable;
    this.displayChange.emit(this.buttonDisable);
  }
  reset() {
    this.capturedData = JSON.parse(JSON.stringify(this.data));
    this.displayChange.emit(this.buttonDisable);
    this.toggleEdit();
  }

  save(Questiondata) {

 
    var obj = {};

    obj["QuestionId"] = this.capturedData.QuestionId;
    obj["QuestionText"] = this.capturedData.QuestionText;
    obj["QuestionTranslationDraftID"] = this.capturedData.QuestionTranslationDraftID;


    var respAttributes = [];

    var colcount = 0;
    var rowcount = 0;

    Questiondata.RespAttributes.forEach(element => {



      if (element.AttributeName == "C_OPTION") {
        respAttributes.push({
          QuestionResponseTranslationDraftId: element.QuestionResponseTranslationDraftId,
          AttributeValue: this.cols[colcount].header,
          AttributeName: "C_OPTION"
        });
        colcount = colcount + 1;
      }

      if (element.AttributeName == "R_OPTION") {
        respAttributes.push({
          QuestionResponseTranslationDraftId: element.QuestionResponseTranslationDraftId,
          AttributeValue: this.rows[rowcount].AttributeValue,
          AttributeName: "R_OPTION"
        });

        rowcount = rowcount + 1
      }

    });

    obj["RespAttributes"] = respAttributes;



    if (obj["QuestionText"] !== "") {
      this._templateQuestionService.postEditedQuestions(obj)
      .then(data => {
      this.data = Questiondata;
      this.capturedData = JSON.parse(JSON.stringify(this.data));
      return data;
      }).catch(error => {
      return error;
      })
      this.toggleEdit();
      } else {

      return false;
      } 
  }
}
