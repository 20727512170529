import { Component, OnChanges, Input, ChangeDetectorRef, ViewChild, ElementRef, ViewContainerRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { surveyType } from 'src/app/shared/constants/shared.constants';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ChartDataService } from '../service/chart-data-service.service';
import { environment } from 'src/environments/environment';
declare var $: any;

// in secs
const interval: number = 5;

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.scss']
})
export class CustomerFeedbackComponent implements OnChanges {
  private imagepath = environment.imagepath;
  @Input() res: any;
  @Input('boolCustomerFeedbackLoader') boolCustomerFeedbackLoader;
  @Input() errorVal: any;
  @Input() surveyTypeInput: string = surveyType.Transactional;
  @ViewChild('timerHolder', { read: ViewContainerRef }) timerNode: ElementRef;

  detractorStatus: boolean = true;
  isDetractorPaused: boolean = false;
  detractorA = 0;
  detractorPieTimerInterval: any;
  detractorAnim: string = '';
  detractorDegrees = -1;
  dData = [];
  dDataCount: number = this.dData.length;
  dCurentIndex: number = 0;

  promoterStatus: boolean = true;
  ispromoterPaused: boolean = false;
  promoterA = 0;
  promoterPieTimerInterval: any;
  promoterAnim: string = '';
  promoterDegrees = -1;
  pData = [];
  pDataCount: number = this.pData.length;
  pCurentIndex: number = 0;

  neutralStatus: boolean = true;
  isneutralPaused: boolean = false;
  neutralA = 0;
  neutralPieTimerInterval: any;
  neutralAnim: string = '';
  neutralDegrees = -1;
  nData = [];
  nDataCount: number = this.nData.length;
  nCurentIndex: number = 0;

  isSpinnerRunning: boolean = false;
  startTimeMS = 0;
  pi = Math.PI;
  // 30 corresponds to 10 second timer, increment in ~15 steps to add 10 seconds
  t = 30;

  data = [];
  isPlay: boolean = false;
  isCarouselSet: boolean = true;
  isCarousePromoterIdlPause: boolean = false;
  isCarouselNeutralsIdPause: boolean = false;
  isCarouselDetractorsIdPause: boolean = false;
  carouselPromoterIdImg: any = "Timer_Animation1.svg"
  carouselNeutralsIdImg: any = "Timer_Animation1.svg"
  carouselDetractorsIdImg: any = "Timer_Animation1.svg"
  headerText = "Customer Feedback"
  otherLang =[
    "FRENCH","SPANISH","PORTUGUESE","INDONESIAN","RUSSIAN","SERBIAN"
  ]
  exceptionLang =[
    "CHINESE","JAPANESE","KOREAN"
  ]
  dashboardImageUrl = this.sharedService.configuration.DashboardImagePath;

  constructor(private router: Router, private cd: ChangeDetectorRef, private renderer: Renderer2,private sharedService: SharedService,
    private chartDataService: ChartDataService) { }

  ngOnInit() {
    if(this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput == 'OEM'){
      this.headerText = "Feedback"
    }
  }
  ngOnDestroy() {
    this.pauseDetractorTimer();
    this.pauseNeutralTimer();
    this.pausePromoterTimer();
  }

  ngOnChanges() {
    this.pData = [];
    this.nData = [];
    this.dData = [];
    this.data = [];
    if(this.res && this.res.Response && this.res.Response.Collection && ((this.surveyTypeInput === this.res.Response.Collection[0].SubCategory) || this.surveyTypeInput=='TRANS')) {
      this.getCustomerFeedback();
    } else {
      this.pData = [];
      this.nData = [];
      this.dData = [];
      this.data = [];
    }
    
    // this.pauseAllSlideAnimations();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.pauseAllSlideAnimations();

      this.startPromoterTimer();
      this.startNeutralTimer();
      this.startDetractorTimer();
    },
      // delay added for UI to be initialized & rendered
      // TODO: remove delay
      (3 * 1000));
  }

  getCustomerFeedback(): void {

    this.pData = [];
    this.nData = [];
    this.dData = [];

    if (this.res != null && this.res.Response != null) {
      this.data = this.res.Response.Collection;

      this.data.forEach(element => {
        if(this.otherLang.includes(element.SurveyLanguage.trim().toUpperCase())){
          if(element.FeedbackText.length>45){
            element.FeedbackText = element.FeedbackText.slice(0,45) + '...';
          }
        }
        else if(this.exceptionLang.includes(element.SurveyLanguage.trim().toUpperCase())){
          if(element.FeedbackText.length>23){
            element.FeedbackText = element.FeedbackText.slice(0,23) + '...';
          }
        }
        else{
          if(element.FeedbackText.length>51){
          element.FeedbackText = element.FeedbackText.slice(0,51) + '...';
          }
        }
        switch (element.PNDFlag) {
          case 'P':
            this.pData.push(element);
            break;
          case 'N':
            this.nData.push(element);
            break;
          case 'D':
            this.dData.push(element);
            break;

          default:
            break;
        }
      });
    }
  }

  carouselClickAction(actionFor) {
    // this.pauseAllSlideAnimations();
    switch (actionFor) {
      case 'P':
        this.getCustomerFeedbackData(actionFor, this.pData[this.pCurentIndex].SurveyId);
        break;
      case 'N':
        this.getCustomerFeedbackData(actionFor, this.nData[this.nCurentIndex].SurveyId);
        break;
      case 'D':
        this.getCustomerFeedbackData(actionFor, this.dData[this.dCurentIndex].SurveyId);
        break;

      default:
        break;
    }
  }
  getCustomerFeedbackData(strVal, surveyId) {
    this.pauseAllSlideAnimations();
    this.router.navigate(['/surveys/feedback/DASH/'+ this.surveyTypeInput ], { queryParams: { type: strVal, surveyId: surveyId } });
  }
  pauseAllSlideAnimations() {
    $('#promoterId').carousel('pause');
    $('#neutralsId').carousel('pause');
    $('#detractorsId').carousel('pause');
  }

  paginationClick(actionFor, index) {
    this.pauseAllSlideAnimations();
    switch (actionFor) {
      case 'P':
        this.pCurentIndex = index;
        break;
      case 'N':
        this.pCurentIndex = index;
        break;
      case 'D':
        this.pCurentIndex = index;
        break;
      default:
        break;
    }
  }

  playpauseCorausalPromotorId($event) {
    $event.stopPropagation();
    this.isCarouselSet = false;

    /*if (this.isCarousePromoterIdlPause) {
      $('#promoterId').carousel('cycle');
      this.isCarousePromoterIdlPause = false;
      this.carouselPromoterIdImg = "Timer_Animation.svg"
    } else {
      $('#promoterId').carousel('pause');
      this.isCarousePromoterIdlPause = true;
      this.carouselPromoterIdImg = "Timer_Animation1.svg"
    }*/

  }

  playpauseCorausalNeutralsId($event) {
    $event.stopPropagation();
    this.isCarouselSet = false;

    /*if (this.isCarouselNeutralsIdPause) {
      $('#neutralsId').carousel('cycle');
      this.isCarouselNeutralsIdPause = false;
      this.carouselNeutralsIdImg = "Timer_Animation.svg"
    } else {
      $('#neutralsId').carousel('pause');
      this.isCarouselNeutralsIdPause = true;
      this.carouselNeutralsIdImg = "Timer_Animation1.svg"
    } */
  }

  playpauseCorausalDetractorsId($event) {
    $event.stopPropagation();
    this.isCarouselSet = false;

    /*if (this.isCarouselDetractorsIdPause) {
      $('#detractorsId').carousel('cycle');
      this.isCarouselDetractorsIdPause = false;
      this.carouselDetractorsIdImg = "Timer_Animation.svg"
    } else {
      $('#detractorsId').carousel('pause');
      this.isCarouselDetractorsIdPause = true;
      this.carouselDetractorsIdImg = "Timer_Animation1.svg"
    }*/
  }

  playPausePromoter() {
    // this.isPlay = !this.isPlay;
    this.isPlay = this.isPlay ? false : true;
  }

  toggleDetractorTimer() {
    this.detractorStatus = !this.detractorStatus;
    if (this.detractorStatus) {
      this.startDetractorTimer();
    } else {
      this.pauseDetractorTimer();
    }
  }
  startDetractorTimer() {
    this.isDetractorPaused = false;
    this.detractorPieTimerInterval = setInterval(() => this.detractorDraw(), this.t);
  }
  pauseDetractorTimer() {
    clearInterval(this.detractorPieTimerInterval);
    this.isDetractorPaused = true;
    $('#detractorsId').carousel('pause');
  }
  detractorTimeOutEvent() {

    if (this.dDataCount === (this.dData.length - 1)) {
      this.dDataCount = -1;
    }
    if (this.detractorStatus) {
      this.dDataCount++;
      this.dCurentIndex = this.dDataCount;
      $('#detractorsId').carousel(this.dDataCount);
      $('#detractorsId').carousel('pause');
    }
  }
  detractorDraw() {
    this.detractorA++;
    this.detractorA %= 360;
    let r = (this.detractorA * this.pi / 180)
      , x = Math.sin(r) * 15
      , y = Math.cos(r) * - 15
      , mid = (this.detractorA > 180) ? 1 : 0;
    this.detractorAnim = 'M 0 0 v -15 A 15 15 1 '
      + mid + ' 1 '
      + x + ' '
      + y + ' z';
    this.detractorDegrees++;
    if (this.detractorDegrees === 360) {
      // 1 full rotation is complete, do something
      this.detractorTimeOutEvent();
      this.detractorDegrees = 0;
    }
  }

  togglePromoterTimer() {
    this.promoterStatus = !this.promoterStatus;
    if (this.promoterStatus) {
      this.startPromoterTimer();
    } else {
      this.pausePromoterTimer();
    }
  }
  startPromoterTimer() {
    this.ispromoterPaused = false;
    this.promoterPieTimerInterval = setInterval(() => this.promoterDraw(), this.t);
  }
  pausePromoterTimer() {
    clearInterval(this.promoterPieTimerInterval);
    this.ispromoterPaused = true;
    $('#promotersId').carousel('pause');
  }
  promoterTimeOutEvent() {

    if (this.pDataCount === (this.pData.length - 1)) {
      this.pDataCount = -1;
    }
    if (this.promoterStatus) {
      this.pDataCount++;
      this.pCurentIndex = this.pDataCount;
      $('#promoterId').carousel(this.pDataCount);
      $('#promoterId').carousel('pause');
    }
  }
  promoterDraw() {
    this.promoterA++;
    this.promoterA %= 360;
    let r = (this.promoterA * this.pi / 180)
      , x = Math.sin(r) * 15
      , y = Math.cos(r) * - 15
      , mid = (this.promoterA > 180) ? 1 : 0;
    this.promoterAnim = 'M 0 0 v -15 A 15 15 1 '
      + mid + ' 1 '
      + x + ' '
      + y + ' z';
    this.promoterDegrees++;
    if (this.promoterDegrees === 360) {
      // 1 full rotation is complete, do something
      this.promoterTimeOutEvent();
      this.promoterDegrees = 0;
    }
  }


  toggleNeutralTimer() {
    this.neutralStatus = !this.neutralStatus;
    if (this.neutralStatus) {
      this.startNeutralTimer();
    } else {
      this.pauseNeutralTimer();
    }
  }
  startNeutralTimer() {
    this.isneutralPaused = false;
    this.neutralPieTimerInterval = setInterval(() => this.neutralDraw(), this.t);
  }
  pauseNeutralTimer() {
    clearInterval(this.neutralPieTimerInterval);
    this.isneutralPaused = true;
    $('#neutralsId').carousel('pause');
  }
  neutralTimeOutEvent() {

    if (this.nDataCount === (this.nData.length - 1)) {
      this.nDataCount = -1;
    }
    if (this.neutralStatus) {
      this.nDataCount++;
      this.nCurentIndex = this.nDataCount;
      $('#neutralsId').carousel(this.nDataCount);
      $('#neutralsId').carousel('pause');
    }
  }
  neutralDraw() {
    this.neutralA++;
    this.neutralA %= 360;
    let r = (this.neutralA * this.pi / 180)
      , x = Math.sin(r) * 15
      , y = Math.cos(r) * - 15
      , mid = (this.neutralA > 180) ? 1 : 0;
    this.neutralAnim = 'M 0 0 v -15 A 15 15 1 '
      + mid + ' 1 '
      + x + ' '
      + y + ' z';
    this.neutralDegrees++;
    if (this.neutralDegrees === 360) {
      // 1 full rotation is complete, do something
      this.neutralTimeOutEvent();
      this.neutralDegrees = 0;
    }
  }
  refreshWidget(widgetKey : string) {
    let refreshData = {
      refresh: true
    };
    if (widgetKey) {
      refreshData[widgetKey] = true;
    }
    this.chartDataService.setRefreshWidgetData(refreshData);
  }
}
