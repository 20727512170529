import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter } from "@angular/core";
import * as Highcharts from 'highcharts';
import HC_map from 'highcharts/modules/map';
import { ParsedChartData, ParsedTrendDataResponse } from "../../models/classes/chart-data-models.class";
import { DummyExpectedOptions, ChartConfigs, POC_OPTIONS } from "../../models/configs/default-chart-options.config";
import { ChartDataUtility } from "../../utils/chart-data-utility.module";
import { HOVERING_ON } from "../../models/enums/period-type.enum";
HC_map(Highcharts);


@Component({
    selector: 'lb-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit, OnDestroy, OnChanges {
    @Input() data: ParsedChartData = new ParsedChartData();
    @Input() rawData: ParsedTrendDataResponse = new ParsedTrendDataResponse();
    @Input() viewByYear: boolean;
    @Input() filterBy: string = 'Customer';
    @Output() clickParams: EventEmitter<{ count: number, period: string, type: string } | null> = new EventEmitter<{ count: number, period: string, type: string } | null>();

    /** Configuring highcharts */
    Highcharts: typeof Highcharts = Highcharts;
    // options: Highcharts.Options;
    options: any;
    oneToOneFlag: boolean = true;
    chartInstance: any;

    /** details */
    private hoveringOn: HOVERING_ON = HOVERING_ON.UNKNOWN;

    /** Dynamic tooltip markup */
    private readonly PERIOD = `{PERIOD}`;
    private readonly COUNT = `{COUNT}`;
    private readonly HINT = `{HINT}`;
    private readonly NAMELIST = `{NAMELIST}`;
    private readonly ELLIPSISICON = `{ELLIPSISICON}`;
    private readonly COUNTCOLOR = `{COUNTCOLOR}`;
    private readonly ADDELLIPSISICON = `<div class="text-right" style="font-size: 19px; color: #0099cc;">
                                            <i class="far fa-ellipsis-h"></i>
                                        </div>`;
    private readonly TOOLTIP_MARKUP = `<div style="padding: 7px 10px; width: 210px; line-height: 16px; font-family: 'Source Sans Pro Regular'">
                                            <div style="font-size: 10px; font-weight:bold; color: #333; margin: 0;">${this.PERIOD}</div>
                                            <div style="margin: 2px 0; font-size: 16px; font-weight:bold; color: ${this.COUNTCOLOR}">${this.COUNT}</div>
                                            <div style="font-size: 11px; color: #777; margin: -2px 0 7px 0;">${this.HINT}</div>
                                            <div style="font-size: 12px; color: #666; margin: 0; line-height: 15px; white-space: initial;">${this.NAMELIST}</div>
                                        </div>`;

    constructor() {
        this.options = JSON.parse(JSON.stringify(POC_OPTIONS));
        // this.options = JSON.parse(JSON.stringify(DummyExpectedOptions));
        this.options.series = [];
    }
    ngOnInit(): void {
    }
    ngOnChanges(changes: SimpleChanges): void {
        let context = this;
        try {
            if (this.data) {
                /** Data binding */
                this.options = JSON.parse(JSON.stringify(POC_OPTIONS));
                // this.options = JSON.parse(JSON.stringify(DummyExpectedOptions));
                this.data.yAxis.forEach(i => i.name = i.name.split(ChartConfigs.PLACEHOLDER).join(context.filterBy + 's'));
                let temp = this.data.yAxis;
                this.options.series = JSON.parse(JSON.stringify(temp));
                let x = JSON.parse(JSON.stringify(this.data.xAxis));
                this.options.xAxis = [{
                    categories: [...x],
                    crosshair: true,
                    labels: {
                        step: 1
                    },
                    title: {
                        text: ''
                    }
                }, {
                    linkedTo: 0,
                    opposite: true,
                    categories: [...x],
                    labels: {
                        step: 1
                    },
                    title: {
                        text: ''
                    }
                }];

                /** X axis label formatter */
                this.options.yAxis['labels'] = {};
                this.options.yAxis['labels']['formatter'] = function () {
                    return Math.abs(this.value);
                }
                /** X axis title disabled */
                if (!this.options.yAxis['title']) {
                    this.options.yAxis['title'] = {};
                }
                this.options.yAxis['title']['enabled'] = false;
                this.options.legend.enabled=true;
                this.options.legend.floating=true;
                this.options.legend.y=10;
                this.options.chart.margin=[10, 110, 65, 110];
                /** Changing tooltip formatting */
                this.options['tooltip']['formatter'] = function () {
                    let viewingBy: string = context.viewByYear ? 'Year' : 'Month',
                        tooltip = null, countColor: string = '', tooltipTxt = '', tooltipMarkup = '';
                    if (this.series.name.toLowerCase() === ChartConfigs.DISCONTINUED_HOVER_TEXT.split(ChartConfigs.PLACEHOLDER).join(context.filterBy.toLowerCase() + 's').toLowerCase()) {
                        // if (this.series.name === context.filterBy + ' did not continue to do business') {
                        tooltip = context.rawData[viewingBy][context.rawData[viewingBy].findIndex(i => i.Period === `${this.x}`)]['LostList'];
                        context.hoveringOn = HOVERING_ON.LOST;
                        tooltipMarkup = context.replaceAllOccurances(context.TOOLTIP_MARKUP, context.PERIOD, '' + this.x);
                        tooltipMarkup = context.replaceAllOccurances(tooltipMarkup, context.COUNTCOLOR, ChartConfigs.LoseColor);
                        tooltipMarkup = context.replaceAllOccurances(tooltipMarkup, context.COUNT, ChartDataUtility.convertToABB(`${Math.abs(this.point.y)}`, 0));
                        tooltipMarkup = context.replaceAllOccurances(tooltipMarkup, context.HINT, ChartConfigs.DISCONTINUED_HOVER_TEXT.split(ChartConfigs.PLACEHOLDER).join(context.filterBy + 's'));
                        if (tooltip.length === 11) {
                            tooltip[10] = context.ADDELLIPSISICON;
                        }
                      tooltipMarkup = context.replaceAllOccurances(tooltipMarkup, context.NAMELIST, tooltip.join('<br/>'));
                        // tooltipMarkup = context.replaceAllOccurances(tooltipMarkup, context.ELLIPSISICON, tooltip.length === 11 ? context.ADDELLIPSISICON : '');
                        return tooltipMarkup;
                    } else {
                        tooltip = context.rawData[viewingBy][context.rawData[viewingBy].findIndex(i => i.Period === `${this.x}`)]['WinList'];
                        context.hoveringOn = HOVERING_ON.WIN;
                        tooltipMarkup = context.replaceAllOccurances(context.TOOLTIP_MARKUP, context.PERIOD, '' + this.x);
                        tooltipMarkup = context.replaceAllOccurances(tooltipMarkup, context.COUNTCOLOR, ChartConfigs.WinColor);
                        tooltipMarkup = context.replaceAllOccurances(tooltipMarkup, context.COUNT, ChartDataUtility.convertToABB(`${Math.abs(this.point.y)}`, 0));
                        tooltipMarkup = context.replaceAllOccurances(tooltipMarkup, context.HINT, ChartConfigs.CONTINUED_HOVER_TEXT.split(ChartConfigs.PLACEHOLDER).join(context.filterBy + 's'));
                        if (tooltip.length === 11) {
                            tooltip[10] = context.ADDELLIPSISICON;
                        }
                       // tooltipMarkup = context.replaceAllOccurances(tooltipMarkup, context.NAMELIST, tooltip.map(i => i.split('|')[0] + ' - <b>' + i.split('|')[1] + '</b>').join('<br/>'));
                       tooltipMarkup = context.replaceAllOccurances(tooltipMarkup, context.NAMELIST, tooltip.map(i => i.split('|')[0] + (i.split('|')[1] === undefined ? "" : ' - <b>'+ i.split('|')[1])  + '</b>').join('<br/>'));
                        return tooltipMarkup;
                    }

                    return tooltipMarkup;
                }

                /** Click event detection */
                this.options.plotOptions = {
                    series: {
                        stacking: 'normal',
                        cursor: 'pointer',
                        point:{
                             events:{
                                click:function(e){
                                    let data = {
                                        count: Math.abs(event['point']['options']['y']),
                                        period: `${event['point']['category']}`,
                                        type: context.hoveringOn == HOVERING_ON.LOST ? 'Lost' : 'Win'
                                    };
                                    this.clickParams.emit(data);
                                }.bind(this)
                            }
                        }
                    }
                };
                //this.options.plotOptions['series']['events'] = {};
                // this.options.plotOptions['series']['events']['click'] = function (event) {
           
                // }
            }
        } catch (error) {
            console.log('error ', error);
        }
    }
    ngAfterViewInit() {

    }
    ngOnDestroy(): void {
    }
    logChartInstance(chart: Highcharts.Chart) {
        this.chartInstance = chart;
    }
    replaceAllOccurances(originalString: string, findString: string, replaceWith: string): string {
        return originalString.split(findString).join(replaceWith);
    }
}
