import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as Rx from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RevenueFilterService {

  private serviceUrl = this.sharedService.configuration.baseUrl;

  filterObj = {
    "StartDate": "",
    "EndDate": "",
    "PeriodType": "",
    "BACode": "",
    "FAMCode": "",
    "BrandName": "",
    "Country": "",
    "DistributorSegment": "",
    "Distributor": "",
    "PinnedField": "",
    "SubCategory": "",
    "SurveyID": "",
    "SurveyInstanceID": "",
    "Years": "",
    "OnChangeEventHandle": ""
  }

  globalFilterBehaviousSubject = new Rx.BehaviorSubject<any>(this.filterObj);
  advancedSearchDateFilterSubject = new Rx.BehaviorSubject<any>(null);
  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getAllData(surveySubCategory: string) {
    return this.http.get(this.serviceUrl + "api/Dashboard/GetMasterFilterData/" + surveySubCategory + "?" + Math.random())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getAllDistCustData(surveySubCategoryKey: string) {
    return this.http.get(this.serviceUrl + "api/Dashboard/GetDistCustMasterFilterData/" + surveySubCategoryKey + "?" + Math.random())
      .toPromise()
      .then((res: any) => res)
      .then(data => {

        return data;
      });
  }

  saveUserFilter(filterObject, isDate: boolean, surveyType) {
    return this.http.post(this.serviceUrl + "api/Dashboard/AddUpdateUserFilter/" + surveyType + "?IsDateParam=" + isDate, filterObject)
      .toPromise()
      .then((res: any) => { return res });
  }

  getUserFilter(surveyType, OldSubCategory, SubCategory): Promise<any> {
    return new Promise((resolve, reject) => {
      //check here with new value and old value
      this.http.get(this.serviceUrl + "api/Dashboard/GetUserFilter/" + surveyType + "?" + Math.random())
        .toPromise()
        .then((res: any) => {
          if (res != null && res.Status == "Success" && res.Response != null) {
            this.mapFilterObject(res.Response, true);
            sessionStorage.setItem("revenueFilterObject", JSON.stringify(res.Response));
          }

          return resolve(res);
        }).catch(e => {
          return reject({
          })
        });
    })
  }

  clearUserFilter(surveyType) {
    return this.http.post(this.serviceUrl + "api/Dashboard/ClearUserFilter/" + surveyType, null)
      .toPromise()
      .then((res: any) => { return res });
  }

  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  brandOriginal = [];
  //   engineerAndTechnicianOriginal = [];
  touchPointsOriginal = [];
  influencersOriginal = [];
  distributorSegmentOriginal = [];
  distributorOriginal = [];
  industrySegmentOriginal = [];
  mapFilterObject(obj, includeDateFields) {
    if (includeDateFields) {
      this.filterObj.Years = obj.Years;
      var dateObject = {
        "Years": obj.Years
      }
      sessionStorage.setItem("revenueperiodFilterObject", JSON.stringify(dateObject));
    }

    this.filterObj.BACode = obj.BACode == null ? "" : obj.BACode;
    this.filterObj.FAMCode = obj.FAMCode == null ? "" : obj.FAMCode;
    this.filterObj.Country = obj.Country == null ? "" : obj.Country;
    this.filterObj.BrandName = obj.BrandName == null ? "" : obj.BrandName;
    this.filterObj.PinnedField = obj.PinnedField == null ? "" : obj.PinnedField;
    this.filterObj.Distributor = obj.Distributor == null ? "" : obj.Distributor;
    this.filterObj.DistributorSegment = obj.DistributorSegment == null ? "" : obj.DistributorSegment;
  }

  updateFilterObject(): any {
    var filterObjectString = sessionStorage.getItem("revenueFilterObject");
    var periodFilterString = sessionStorage.getItem("revenueperiodFilterObject");

    if (filterObjectString != null) {
      var obj = JSON.parse(filterObjectString);
      this.mapFilterObject(obj, false);
    }
    if (periodFilterString != null) {
      var obj = JSON.parse(periodFilterString);
      this.filterObj.Years = obj.Years;
    }
    this.globalFilterBehaviousSubject.next(this.filterObj);
  }
}