import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import { Subscription } from 'rxjs';
import { ExportChartModel, SlideTextData } from 'src/app/shared/models/export-chart.model';
import { filter } from 'rxjs/operators';
import { surveyType } from 'src/app/shared/constants/shared.constants';
import * as html2canvas from "html2canvas"
import { HideOtherOpenDrilldownsService } from '../../../Modules/Distributor_Survey/Services/hide-other-open-drilldowns.service';
import { ChartDataService } from '../service/chart-data-service.service';
import { environment } from 'src/environments/environment';
const widgetCode: string = 'nps';

@Component({
  selector: 'app-nps-dashboard',
  templateUrl: './nps-dashboard.component.html',
  styleUrls: ['./nps-dashboard.component.scss']
})
export class NpsDashboardComponent implements OnInit {
  private imagepath = environment.imagepath;
  @Input() npsScore: any;
  @Input() promoters: number;
  @Input() neutrals: number;
  @Input() detractors: number;
  @Input() boolAverageRatingLoader;
  @Input() errorData: boolean;
  @Input() surveyTypeInput:string = "";
  promoterswidth: number;
  neutralswidth: number;
  detractorswidth: number;
  rightCurve: string = "";
  leftCurve: string = "";
  @Output()
  trendAnalysisClicked: EventEmitter<{ showCharts: boolean, chartsToShow: any[] }> = new EventEmitter();

  private widgetState: CHART_STATE_OPTIONS;
  private exportSubscription: Subscription;
  @ViewChild('pictureOf') pictureOf: ElementRef;

  detrWidth = 0;
  constructor(private sharedService: SharedService,   private hideOtherOpenDrilldownsService : HideOtherOpenDrilldownsService,
    private chartDataService: ChartDataService) {
    this.widgetState = CHART_STATE_OPTIONS.LOADING;

    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(update => update !== null && update.includes(widgetCode.toLowerCase()))).subscribe(val => {
      if (this.sharedService.acceptableChartState.includes(this.widgetState)) {
        setTimeout(() => {
          this.toCanvas();
        }, 200);
        this.slideTextToShow();
      }
    });
  }

  ngOnInit() {

    
    // var rem = (this.promoters + this.neutrals + this.detractors) % 100;
    // if (rem > 0) {
    //   this.detrWidth = this.detractors - rem;
    // }
    // else {
    //   this.detrWidth = this.detractors + rem;
    // }

    // if(this.detrWidth <= 12){
    //   this.detrWidth=12;
    // }

    // if (this.detrWidth >= 1 && this.detrWidth <= 12) {
    //   for (var i = 1, j = 11; j >= 0; i++, j--) {
    //     if (this.detrWidth == i) {
    //       this.detrWidth = this.detrWidth + j;
    //       this.neutrals = this.neutrals - j;
    //     }
    //   }
    // }

  }

  // unhide the chartlisting component & specify that all charts be shown
  showTrendAnalysis() {
    this.trendAnalysisClicked.emit({ showCharts: true, chartsToShow: null });
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('');
    // this.sharedService.changeWidgetDDLocation(this.getChartData());
  }

  ngOnChanges() {
    console.log(this.surveyTypeInput);
    this.determineWidgetState();
    this.updateExportDetails();
    this.parentclass = 'neutral';
    let constnum = 15;
    this.promoterswidth = this.promoters;
    this.neutralswidth = this.neutrals;
    this.detractorswidth = this.detractors;
    if (this.promoters > 0 && this.promoters < 16) {
      this.promoterswidth = constnum;
      if (this.neutrals > 50) {
        this.neutralswidth = this.neutrals - (constnum - this.promoters);
      } else {
        if(this.neutrals > 0 && this.neutrals < 16){
          this.neutralswidth = constnum;
          this.detractorswidth = 100 - (constnum + constnum);
        }else{
          this.detractorswidth = this.detractors - (constnum - this.promoters);
        }
      }
    }
    if (this.detractors > 0 && this.detractors < 16) {
      this.detractorswidth = constnum;
      if (this.neutrals > 50) {
        this.neutralswidth = this.neutralswidth - (constnum - this.detractors);
      } else {
        if(this.neutrals > 0 && this.neutrals < 16){
          this.neutralswidth = constnum;
          this.promoterswidth = 100 - (constnum + constnum);
        }else{
          this.promoterswidth = this.promoters - (constnum - this.detractors);
        }
        
      }

    }
    // if (this.neutrals > 0 && this.neutrals < 3) {
    //   this.neutralswidth = this.neutralswidth + 2;
    //   this.promoterswidth = this.promoterswidth - 2;
    // }

    this.showDetractor = true;
    this.showPromoter = true;
    this.showNeutrals = true;
    if (this.detractors == 0) {
      this.rightCurve = "rightcurve";
      this.showDetractor = false;
    }
    if (this.promoters == 0) {
      this.leftCurve = "leftcurve";
      this.showPromoter = false;
    }
    if (this.neutrals == 0) {
      this.showNeutrals = false;
      this.parentclass = 'promoter';
    }
    if (this.detractors == 0 && this.promoters == 0 && this.neutrals == 0) {
      this.parentclass = "";
    }

    this.promotersfinal = this.promoterswidth;
    this.neutralsfinal = this.neutralswidth;
    this.detractorsfinal = this.detractorswidth;
    // this.npsScore = 45; 
  }
  ngOnDestroy() {
    this.exportSubscription.unsubscribe();
    this.updateExportDetails(true);
  }
  togglePromoters() {
    if (this.promoters > 0) {
      this.showPromoter = !this.showPromoter;
    } else {
      if (this.promoterclass == 'inactive') {
        this.promoterclass = '';
      } else {
        this.promoterclass = 'inactive';
      }
    }

    if (!this.showPromoter) {
      if (this.promoters > 0) {
        this.promoterclass = 'inactive';
      }
      this.leftCurve = "leftcurve";
      if (!this.showDetractor && !this.showNeutrals) {
        this.parentclass = '';
      } else if (!this.showNeutrals) {
        this.detractorswidth = 100;
      } else if (!this.showDetractor) {
        this.neutralswidth = 100;
      } else {
        /*  this.promotersvalue = this.promoterswidth;
          this.neutralsvalue = this.neutralswidth; */
        this.neutralswidth = this.neutralsfinal + (this.promoterswidth / 2);
        this.detractorswidth = this.detractorsfinal + (this.promoterswidth / 2);
      }
    } else {
      this.leftCurve = "";
      this.promoterclass = '';
      if (!this.showDetractor && !this.showNeutrals) {
        this.promoterswidth = 100;
        this.parentclass = 'promoter';
      } else if (!this.showDetractor) {
        this.promoterswidth = this.promotersfinal + (this.detractorsfinal / 2);
        this.neutralswidth = this.neutralsfinal + (this.detractorsfinal / 2);
      } else if (!this.showNeutrals) {
        this.parentclass = 'promoter';
        this.promoterswidth = this.promotersfinal + (this.neutralsfinal / 2);
        this.detractorswidth = this.detractorsfinal + (this.neutralsfinal / 2);
      } else {
        this.promoterswidth = this.promotersfinal;
        this.neutralswidth = this.neutralsfinal;
        this.detractorswidth = this.detractorsfinal;
      }
    }
  }
  toggleDetractors() {
    if (this.detractors > 0) {
      this.showDetractor = !this.showDetractor;
    } else {
      if (this.detractorclass == 'inactive') {
        this.detractorclass = '';
      } else {
        this.detractorclass = 'inactive';
      }
    }

    if (!this.showDetractor) {
      if (this.detractors > 0) {
        this.detractorclass = 'inactive';
      }
      this.rightCurve = "rightcurve";
      if (!this.showPromoter && !this.showNeutrals) {
        this.parentclass = '';
      } else if (!this.showNeutrals) {
        this.promoterswidth = 100;
      } else if (!this.showPromoter) {
        this.neutralswidth = 100;
      } else {
        /*  this.promotersvalue = this.promoterswidth;
          this.neutralsvalue = this.neutralswidth; */
        this.promoterswidth = this.promoterswidth + (this.detractorswidth / 2);
        this.neutralswidth = this.neutralswidth + (this.detractorswidth / 2);
      }
    } else {
      this.detractorclass = '';
      this.rightCurve = '';
      if (!this.showPromoter && !this.showNeutrals) {
        this.detractorswidth = 100
      } else if (!this.showPromoter) {
        this.detractorswidth = this.detractorsfinal + (this.promotersfinal / 2);
        this.neutralswidth = this.neutralsfinal + (this.promotersfinal / 2);
      } else if (!this.showNeutrals) {
        this.promoterswidth = this.promotersfinal + (this.neutralsfinal / 2);
        this.detractorswidth = this.detractorsfinal + (this.neutralsfinal / 2);
      } else {
        this.promoterswidth = this.promotersfinal;
        this.neutralswidth = this.neutralsfinal;
        this.detractorswidth = this.detractorsfinal;
      }
    }
  }
  toggleNeutrals() {
    if (this.neutrals > 0) {
      this.showNeutrals = !this.showNeutrals;
    } else {
      if (this.neutralclass == 'inactive') {
        this.neutralclass = '';
      } else {
        this.neutralclass = 'inactive';
      }
    }

    if (!this.showNeutrals) {
      this.parentclass = 'promoter';
      if (this.neutrals > 0) {
        this.neutralclass = 'inactive';
      }
      if (!this.showDetractor && !this.showPromoter) {
        this.parentclass = '';
      } else if (!this.showPromoter) {
        this.detractorswidth = 100;
      } else if (!this.showDetractor) {
        this.promoterswidth = 100;
      } else {

        this.promoterswidth = this.promotersfinal + (this.neutralswidth / 2);
        this.detractorswidth = this.detractorsfinal + (this.neutralswidth / 2);
      }
    } else {
      this.neutralclass = '';
      this.parentclass = 'neutral';
      if (!this.showDetractor && !this.showPromoter) {
        this.neutralswidth = 100
      } else if (!this.showDetractor) {
        this.promoterswidth = this.promotersfinal + (this.detractorsfinal / 2);
        this.neutralswidth = this.neutralsfinal + (this.detractorsfinal / 2);
      } else if (!this.showPromoter) {
        this.neutralswidth = this.neutralsfinal + (this.promotersfinal / 2);
        this.detractorswidth = this.detractorsfinal + (this.promotersfinal / 2);
      } else {
        this.promoterswidth = this.promotersfinal;
        this.neutralswidth = this.neutralsfinal;
        this.detractorswidth = this.detractorsfinal;
      }
    }
  }
  neutralclass: string = "";
  promoterclass: string = "";
  detractorclass: string = "";
  promotersvalue: number;
  detractorsvalue: number;
  neutralsvalue: number;
  parentclass: string = "neutral";
  showPromoter: boolean = true;
  showDetractor: boolean = true;
  showNeutrals: boolean = true;
  promotersfinal: number;
  neutralsfinal: number;
  detractorsfinal: number;

  private toCanvas = () => {
    try {
      // if (this.pictureOf && this.pictureOf.nativeElement) {
      //   // html2canvas.Util.isTransparent = function(backgroundColor) {
      //   //   return (backgroundColor === "transparent" || backgroundColor === "rgba(0, 0, 0, 0)" || backgroundColor === undefined);
      //   // };
      //   html2canvas(this.pictureOf.nativeElement, { foreignObjectRendering: true, useCORS: false, 
      //     background: '#005671', logging: false, removeContainer: true}).then((canvas) => {
      //       // let lmnt = document.createElement(canvas);

      //       let chartUpdate: ExportChartModel = new ExportChartModel();
      //       chartUpdate.UpdateTime = new Date();
      //       chartUpdate.ChartName = widgetCode.toLowerCase();
      //       chartUpdate.ChartImgId = widgetCode.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
      //       chartUpdate.CurrentState = this.widgetState;
      //       chartUpdate.ChartImgBase64 = canvas.toDataURL('image/png');
      //       this.sharedService.updateChartStatusSubject(chartUpdate);

      //       // document.removeChild(lmnt);
      //     });
      // }

      let chartUpdate: ExportChartModel = new ExportChartModel();
      chartUpdate.UpdateTime = new Date();
      chartUpdate.ChartName = widgetCode.toLowerCase();
      chartUpdate.ChartImgId = null;
      chartUpdate.CurrentState = this.widgetState;
      chartUpdate.ChartImgBase64 = 'this_does_not_have_to_be_uploaded_to_blob';
      this.sharedService.updateChartStatusSubject(chartUpdate);
    } catch (error) {
      console.log('html2canvas error as', error);
    }
  }
  private slideTextToShow = () => {
    let textConfig: SlideTextData = new SlideTextData();

    if (this.widgetState === CHART_STATE_OPTIONS.NO_DATA) {
      textConfig.SlideText = '';
      textConfig.SlideRows = [];
      textConfig.SlideId = 'npsnresponse'.toLowerCase();
      textConfig.SlideTextId = textConfig.SlideId + '_' + widgetCode.toLowerCase();
      textConfig.HasNoData = true
    } else if (this.widgetState === CHART_STATE_OPTIONS.SUCCESS) {

      let text = '', listString: string[] = [], slideRows: string[] = [], row: string = '';

      text += 'Promoter:' + this.promoters;
      listString.push(text); text = '';
      text += 'Neutral:' + this.neutrals;
      listString.push(text); text = '';
      text += 'Detractors:' + this.detractors;
      listString.push(text); text = '';
      text += 'NPS:' + this.npsScore.toFixed(0);
      listString.push(text); text = '';

      row += 'PromotersWidth||' + this.promoterswidth;
      slideRows.push(row); row = '';
      row += 'NeutralsWidth||' + this.neutralswidth;
      slideRows.push(row); row = '';
      row += 'DetractorsWidth||' + this.detractorswidth;
      slideRows.push(row); row = '';
      textConfig.SlideText = listString.join('||');
      textConfig.SlideRows = slideRows;
      textConfig.SlideId = 'npsnresponse'.toLowerCase();
      textConfig.SlideTextId = textConfig.SlideId + '_' + widgetCode.toLowerCase();
    }
    this.sharedService.updatechartTextSubject(textConfig);
  }
  private updateExportDetails = (isDestroyCall: boolean = false) => {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    chartUpdate.UpdateTime = new Date();
    chartUpdate.ChartName = widgetCode.toLowerCase();
    if (isDestroyCall) {
      this.widgetState = CHART_STATE_OPTIONS.UNKNOWN;
    }
    chartUpdate.CurrentState = this.widgetState ? this.widgetState : CHART_STATE_OPTIONS.UNKNOWN;
    this.sharedService.updateChartStatusSubject(chartUpdate);
  }
  private determineWidgetState = () => {
    this.widgetState = this.npsScore !== null ? CHART_STATE_OPTIONS.SUCCESS : (this.errorData ? CHART_STATE_OPTIONS.ERROR : CHART_STATE_OPTIONS.NO_DATA);
  }
  
  refreshWidget(widgetKey : string) {
    let refreshData = {
      refresh: true
    };
    if (widgetKey) {
      refreshData[widgetKey] = true;
    }
    this.chartDataService.setRefreshWidgetData(refreshData);
  }
}

