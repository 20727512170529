import { LostBusinessStateModel, LbDdNav1State,LbDdNav2State,LbDdNav3State, TrendDrilldownState } from '../model/lost-business-lbcdrilldown';

export class BaseStateManagement {
    goToNextComp(data: any): LostBusinessStateModel {
        const objName: string[] = Object.getOwnPropertyNames(this);
        for (let i = 0; i < objName.length; i++) {
            if (this[objName[i]] && this[objName[i]]['IsActive']) {
                this[this[objName[i]]['Next']]['IsActive'] = true;
                this[this[objName[i]]['Next']]['Data'] = data;
                this[objName[i]]['IsActive'] = false;
                break;
            }
        }
        return this.getDataActiveState();
    }

    goToPrevComp(data:any): LostBusinessStateModel {
        const objName: string[] = Object.getOwnPropertyNames(this);
        for (let i = 0; i < objName.length; i++) {
            if (this[objName[i]] && this[objName[i]]['IsActive']) {
                this[this[objName[i]]['Previous']]['IsActive'] = true;
                this[objName[i]]['IsActive'] = false;
                this[objName[i]]['Data'] = null;
                this[this[objName[i]]['Previous']]['Data'] = data;
                break;
            }
        }
        return this.getDataActiveState();
    }

    getDataActiveState(): LostBusinessStateModel {
        const objName: string[] = Object.getOwnPropertyNames(this);
        for (let i = 0; i < objName.length; i++) {
            if (this[objName[i]] && this[objName[i]]['IsActive']) {
                return this[objName[i]];
            }
        }
        return new LostBusinessStateModel();
    }
}

export class LbcDrilldownNavigation extends BaseStateManagement {
    /**
     * Represents initial state for Total Count Drilldown flow
     */
    s1: LostBusinessStateModel = new LostBusinessStateModel();
    /**
     * Represents intermediate state for Total Count Drilldown flow
     */
    s2: LostBusinessStateModel = new LostBusinessStateModel();
    /**
     * Represents last state for Total Count Drilldown flow
     */
    s3: LostBusinessStateModel = new LostBusinessStateModel();

    constructor() {
        super();
        this.s1.Id = LbDdNav1State.A1;
        this.s1.Next = 's2';
        this.s1.Data = null;
        this.s1.IsActive = true;
        this.s1.Previous = null;

        this.s2 = { ...this.s1 };
        this.s2.Id = LbDdNav1State.A2;
        this.s2.IsActive = false;
        this.s2.Next = 's3';
        this.s2.Previous = 's1';

        this.s3 = { ...this.s1 };
        this.s3.Id = LbDdNav1State.A3;
        this.s3.IsActive = false;
        this.s3.Next = null;
        this.s3.Previous = 's2';
    }
}
export class LbcLostCntDrilldownNavigation extends BaseStateManagement {
    /**
     * Represents initial state for Lost Count Drilldown flow
     */
    s1: LostBusinessStateModel = new LostBusinessStateModel();
    /**
     * Represents intermediate state for Lost Count Drilldown flow
     */
    s2: LostBusinessStateModel = new LostBusinessStateModel();

    constructor() {
        super();
        this.s1.Id = LbDdNav2State.B1;
        this.s1.Next = 's2';
        this.s1.Data = null;
        this.s1.IsActive = true;
        this.s1.Previous = null;

        this.s2 = { ...this.s1 };
        this.s2.Id = LbDdNav2State.B2;
        this.s2.IsActive = false;
        this.s2.Next = null;
        this.s2.Previous = 's1';
    }
}
export class LbcWinCntDrilldownNavigation extends BaseStateManagement {
  /**
   * Represents initial state for Win Count Drilldown flow
   */
  s1: LostBusinessStateModel = new LostBusinessStateModel();
  /**
   * Represents intermediate state for Win Count Drilldown flow
   */
  s2: LostBusinessStateModel = new LostBusinessStateModel();

  constructor() {
      super();
      this.s1.Id = LbDdNav3State.C1;
      this.s1.Next = 's2';
      this.s1.Data = null;
      this.s1.IsActive = true;
      this.s1.Previous = null;

      this.s2 = { ...this.s1 };
      this.s2.Id = LbDdNav3State.C2;
      this.s2.IsActive = false;
      this.s2.Next = null;
      this.s2.Previous = 's1';
  }
}

export class TrendDrilldownNavigation extends BaseStateManagement {
  baseView: LostBusinessStateModel = new LostBusinessStateModel();
  lost_1: LostBusinessStateModel = new LostBusinessStateModel();
  lost_2: LostBusinessStateModel = new LostBusinessStateModel();
  win_1: LostBusinessStateModel = new LostBusinessStateModel();
  win_2: LostBusinessStateModel = new LostBusinessStateModel();
  
  contact_win: LostBusinessStateModel = new LostBusinessStateModel();
  contact_lose: LostBusinessStateModel = new LostBusinessStateModel();

  constructor() {
      super();
      this.baseView.Id = TrendDrilldownState.BASE;
      this.baseView.Next = null;
      this.baseView.Previous = null;
      this.baseView.Data = null;
      this.baseView.IsActive = true;

      this.lost_1.Id = TrendDrilldownState.LOST_1;
      this.lost_1.IsActive = false;
      this.lost_1.Data = null;
      this.lost_1.Next = 'lost_2';
      this.lost_1.Previous = 'baseView';

      this.lost_2.Id = TrendDrilldownState.LOST_2;
      this.lost_2.IsActive = false;
      this.lost_2.Data = null;
      this.lost_2.Next = null;
      this.lost_2.Previous = 'lost_1';

      this.win_1.Id = TrendDrilldownState.WIN_1;
      this.win_1.IsActive = false;
      this.win_1.Data = null;
      this.win_1.Next = 'win_2';
      this.win_1.Previous = 'baseView';

      this.win_2.Id = TrendDrilldownState.WIN_2;
      this.win_2.IsActive = false;
      this.win_2.Data = null;
      this.win_2.Next = null;
      this.win_2.Previous = 'win_1';

      this.contact_lose.Id = TrendDrilldownState.CONTACT_LOSE;
      this.contact_lose.IsActive = false;
      this.contact_lose.Data = null;
      this.contact_lose.Next = null;
      this.contact_lose.Previous = 'baseView';

      this.contact_win.Id = TrendDrilldownState.CONTACT_WIN;
      this.contact_win.IsActive = false;
      this.contact_win.Data = null;
      this.contact_win.Next = null;
      this.contact_win.Previous = 'baseView';
  }
}
