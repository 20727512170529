import { Component, OnInit, Input, Output, EventEmitter,OnChanges, DoCheck } from '@angular/core';
import {MessageService} from 'primeng/api';
import { TemplateQuestionsService } from "../../services/template-questions.service";

@Component({
  selector: 'app-free-form',
  templateUrl: './free-form.component.html',
  styleUrls: ['./free-form.component.scss'],
  
})
export class FreeFormComponent implements OnInit , OnChanges ,DoCheck{
  @Input()
  data: any = {};
  
  capturedData:any={};
  queIndex :any ={};
  editable:boolean=false;
  @Input()
  canRate:boolean=false;
  @Output()
  displayChange = new EventEmitter();
  @Input()
  buttonDisable: boolean = true;

  constructor(private messageService: MessageService, private _templateQuestionService : TemplateQuestionsService) {
    
  }
  successSaveMsg(){
    this.messageService.add({severity:'success', summary:'Saved', detail:'Your responses has been saved'});
  }

  ngOnInit() {
    this.capturedData=JSON.parse(JSON.stringify(this.data));
  }

  ngOnChanges() {
    this.capturedData=JSON.parse(JSON.stringify(this.data));
  }

  ngDoCheck() {
   
  }

  toggleEdit(){
    this.editable = !this.editable;
    this.buttonDisable = !this.buttonDisable;
    this.displayChange.emit(this.buttonDisable);
  }
  reset(){
    this.capturedData=JSON.parse(JSON.stringify(this.data));
    this.displayChange.emit(this.buttonDisable);
    this.toggleEdit();

  }
  save(Questiondata){
    this._templateQuestionService.postEditedQuestions(Questiondata)
      .then(data => {
        this.data = Questiondata;
        this.capturedData = JSON.parse(JSON.stringify(this.data));
        return data;
      }).catch(error => {
        return error;
      })
    this.toggleEdit();
  }


}
