import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import * as _ from 'lodash';
import { CtlsCommonService } from '../ctls-services';

@Directive({
  selector: '[appPermissionAccess]'
})
export class PermissionAccessDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private ctlsCommonService: CtlsCommonService) {
  }
  @Input() set appPermissionAccess(action: string) {
    if (this.ctlsCommonService.allowPermission(action)) {
      // If condition is true add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // Else remove template from DOM
      // this.messageService.showWarning(`Sorry.. You don't have permission to view this page`, 'Warning');
      this.viewContainer.clear();
      // this.router.navigate(['**']);
    }
  }



}
