import { CHART_STATE_OPTIONS } from "../enums/chart-state-options.enum";

export class ExportChartModel {
    ChartName: string;
    ChartImgBase64: string;
    ChartImgId?: string;
    UpdateTime: Date;
    CurrentState: CHART_STATE_OPTIONS;
    ChartBlobURI?: string;
    ConvertSVG2PNG?: boolean;
    HasNoData: boolean;
    HasErrorInExport: boolean;
    constructor() {
        this.HasErrorInExport = false;
        this.HasNoData = false;
        this.ChartImgBase64 = '';
        this.ChartName = '';
        this.UpdateTime = new Date();
        this.CurrentState = CHART_STATE_OPTIONS.UNKNOWN;
        this.ChartBlobURI = '';
    }
}

export class SlideTextData
{
    SlideText: string;
    SlideRows: string[];
    SlideTextId: string;
    ChildData?: SlideTextData[];
    SlideId: string;
    HasNoData: boolean;
    constructor() {
        this.HasNoData = false;
        this.SlideText = '';
        this.SlideRows = [];
        this.SlideTextId = '';
        this.ChildData = [];
        this.SlideId = '';
    }
}

export class ExportOrchestrationData {
    globalFilter: any;
    exportFormat?: string;
    selectedCharts?: string[];
    exportedMembers?: ExportChartModel[];
    slideTextData: SlideTextData[];
    HasNoData: boolean;
    constructor() {
        this.exportFormat = 'pptx';
        this.globalFilter = {};
        this.selectedCharts = [];
        this.exportedMembers = [];
        this.slideTextData = [];
    }
}

export class ImagesForExport {
    ChartName: string;
    ChartImgId?: string;
    ChartImgBase64?: string;
    URI?: string;
    ConvertSVG2PNG?: boolean;
    constructor() {
        this.ChartName = '';
        this.ChartImgId = '';
        this.ChartImgBase64 = '';
        this.URI = '';
        this.ConvertSVG2PNG = false;
    }
}

export class ExportSlideData {
    SlideTitle: string;
    SlideId: string;
    SlideImages: ImagesForExport[];
    SlideText: SlideTextData[];
    constructor() {
        this.SlideId = '';
        this.SlideTitle = '';
        this.SlideImages = [];
        this.SlideText = [];
    }
}