import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { LostbusinessCustomerService } from '../service/lostbusiness-customer.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { Message, DialogService } from 'primeng/components/common/api';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DashboardWidgetService } from '../service/dashboard-widget.service';
import { Router } from '@angular/router';
import { LostBusinessDrilldownService } from '../service/lost-business-drilldown.service';
import { LostBussinesHideAllOtherDialogService } from '../service/lost-bussines-hide-all-other-dialog.service';
import { ChartDataService } from '../service/chart-data-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-lostbusiness-customer',
  templateUrl: './lostbusiness-customer.component.html',
  styleUrls: ['./lostbusiness-customer.component.scss']
})
export class LostbusinessCustomerComponent implements OnInit {
  private imagepath = environment.imagepath;
  @Output('submittedcust') submittedcust: EventEmitter<any> = new EventEmitter<any>();
  @Output() lostCustomerCountvalue: EventEmitter<number> = new EventEmitter();
  @Output('hideWidget') hideWidget: EventEmitter<any> = new EventEmitter();

  @Input() totalCustomerCount: number;
  @Input() lostCustomerCount: number;
  @Input() continuousCustomerCount: number;
  @Input() boolLostcustLoader;
  @Input() errorData: boolean;
  @Input('savedFilterObject') savedFilterObject
  @Input('dropdownLostcustResponse') dropdownLostcustResponse: any;
  @Input() custDropdownloading;
  @Input("lostBaseYear") lostBaseYear: any;
  @Input("lostComparisonDate") lostComparisonDate: any;
  @Input() lostBusinesssResponse: any;
  @Input("ShowName") ShowName: any;
  @Input() showLostMainWidget;
  @Input() passDateToLostComponent;

  // txtSearchCustomer: any[] = [];
  txtSearchCustomer = '';
  passDateToLostFilter;
  msgs: Message[] = [];
  isShowAlert: boolean = false;
  successMsg: boolean = false;
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  CustomerName: any;
  loading: boolean = true;
  custName: any;
  cbAllCustomers = false;
  customersDataOptions: any = [];
  customersDataOptionsDB: any = [];
  SearchNoResult = false;
  dbSearchLoader = false;
  getlostSubscription: Subscription;
  selectedCustomers = false;

  lostcustomerDashboardError: boolean = false;
  AllCustomers: any = [];
  AllCustomersdata: boolean = true;
  ViewbyCustData: boolean = true;
  ViewbyContactData: boolean = false;
  selectedCBValue: any = [];
  splitValue: any = [];
  accNo: any = [];
  custNameparam: any = [];

  showTotalText: any;
  showLosttext: any;
  showContitext: any;
  showChart: boolean = false;
  ShowViewbyName: any;
  showViewbyDropdown: boolean = false;
  enableTotalCustPopUp: boolean = false;
  enableTotalContactPopUp: boolean = false;
  enableLostCustPopUp: boolean = false;
  enableLostContactPopUp: boolean = false;
  enableWinCustPopUp: boolean = false;
  enableWinContactPopUp: boolean = false;

  trendChartVisibility: boolean = false;
  singleCustomerName:any;
  showSingleName:boolean= false;
  selectedViewbyName:any;
  searchPlaceholder="Search Customer name OR A/C#";
  private LostCountSubject: Subscription;
  private NoDataFoundSubject: Subscription;
  private lostCountTextSubject:Subscription;
  @ViewChild('lostviewBy') lostviewBy;
  @ViewChild('lostCustOP') lostCustOP;

  constructor(public router: Router
    , private dashboardWidgetService: DashboardWidgetService
    , private LostbusinessCustomerService: LostbusinessCustomerService
    , private filterService: FilterService
    , private dialogService: DialogService
    , private drillDownService: LostBusinessDrilldownService
    , private sharedService: SharedService
    , private lostBussinesHideAllOtherDialogService: LostBussinesHideAllOtherDialogService
    , private chartDataService: ChartDataService) {
      this.lostBussinesHideAllOtherDialogService.getDialogClickEvent().subscribe((e)=>{
        this.hideOtherDialog(e);
      })
     }


  showAllcustomerText() {
    this.showViewbyDropdown = true;
    this.ShowViewbyName = "Customers";
    this.selectedViewbyName =  this.ShowViewbyName;
    this.showTotalText = "customers had done business transactions with us during";
    this.showLosttext = "customers do not seem to have had any business transactions with us during";
    this.showContitext = "customers continued to do business with us during";
    this.closeAlldrilldown();
    this.LostbusinessCustomerService.lostCountTextSubject.next(this.showLosttext);
  }

  showOneCustomerText() {
    this.ShowViewbyName = "Contacts";
    this.selectedViewbyName =  this.ShowViewbyName;
    this.showTotalText = "contacts had done business transactions with us during";
    this.showLosttext = "contacts do not seem to have had any business transactions with us during";
    this.showContitext = "contacts continued to do business with us during";
    this.closeAlldrilldown();
    this.LostbusinessCustomerService.lostCountTextSubject.next(this.showLosttext);
  }

  ngOnInit() {
    this.cbAllCustomers = true;
    this.showViewbyDropdown = true;
    this.ShowName = 'All customers';
    this.ShowViewbyName = "Customers";
    this.selectedViewbyName =  this.ShowViewbyName;
    this.ViewbyCustData = true;
    this.ViewbyContactData = false;
    this.showAllcustomerText();
  }
  closeWidget() {
    this.hideWidget.emit(false);
    this.sharedService.widgetCloseSubject.next('LBCM');
  }
  ngOnChanges() {
    // this.passDateToLostFilter = this.passDateToLostComponent;
    if (this.boolLostcustLoader || this.errorData) {
      this.trendChartVisibility = false;
      //this.lostBusinesssResponse = null;
    }
    if ((this.boolLostcustLoader || this.errorData) && this.lostBusinesssResponse == null){
      this.lostBusinesssResponse = null;
    }
  }
  applyCustFilter(filter) {
    if(filter.ViewedBy == 'Contact')
    {
      this.showOneCustomerText();
    }else{
      this.showAllcustomerText();
    }
    // this.showViewbyDropdown = true;
    // this.AllCustomersdata = true;
    // this.ViewbyCustData = true;
    // this.AllCustomers = [];
    // this.ViewbyContactData = false;
    // this.ViewbyCustData = true;
    // this.customersDataOptions = [];
    // this.ShowName = 'All customers';
    // this.txtSearchCustomer = '';
    //this.showSingleName=false;
    this.closeAlldrilldown();
    //this.LostbusinessCustomerService.filterObj1.ViewedBy = 'Customer';
  }

  onSearchKeyPress(event: any) {
    if (this.txtSearchCustomer == '' || this.txtSearchCustomer.length < 3) {
      return;
    }
    if (event && event.which == '13') {
      this.FilterSearchClickEvent();
    }
  }

  viewByClick(namevalue) {
    if (namevalue == 'customers') {
      this.LostbusinessCustomerService.filterObj1.ViewedBy = 'Customer';
      this.submittedcust.emit(this.LostbusinessCustomerService.filterObj1);
      this.ViewbyContactData = false;
      this.ViewbyCustData = true;
      this.ShowViewbyName = "Customers";
      this.selectedViewbyName =  this.ShowViewbyName;
      this.showSingleName=false;
      this.showAllcustomerText();
    } else {
      this.LostbusinessCustomerService.filterObj1.ViewedBy = 'Contact';
      this.submittedcust.emit(this.LostbusinessCustomerService.filterObj1);
      this.ViewbyContactData = true;
      this.ViewbyCustData = false;
      this.ShowViewbyName = "Contacts";
      this.selectedViewbyName =  this.ShowViewbyName;
      this.showViewbyDropdown = true;
      this.showSingleName=false;
      this.showOneCustomerText();
    }
  }
  cbAllCustomersClick() {
    this.txtSearchCustomer = '';
    if (this.cbAllCustomers) {
      this.AllCustomers = [];
      this.showSingleName=false;
      this.cbAllCustomers = true;
      this.SearchNoResult = false;
      this.LostbusinessCustomerService.filterObj1.FilterBy = '';
      this.LostbusinessCustomerService.filterObj1.FilterString = null;
      this.LostbusinessCustomerService.filterObj1.AccountNo = null;
      this.LostbusinessCustomerService.filterObj1.ViewedBy = 'Customer';
      this.ShowName = 'All customers';
      this.submittedcust.emit(this.LostbusinessCustomerService.filterObj1);
    } else {
      this.AllCustomers = [];
      this.cbAllCustomers = true;
      this.SearchNoResult = false;
      this.showSingleName=false;
      this.LostbusinessCustomerService.filterObj1.FilterBy = '';
      this.LostbusinessCustomerService.filterObj1.FilterString = null;
      this.LostbusinessCustomerService.filterObj1.AccountNo = null;
      this.LostbusinessCustomerService.filterObj1.ViewedBy = 'Customer';
      this.ShowName = 'All customers';
      this.submittedcust.emit(this.LostbusinessCustomerService.filterObj1);
    }
    this.showAllcustomerText();
  }

  cbSelectedCustomersClick(name) {
    this.cbAllCustomers = false;
    if (this.AllCustomers.includes(name)) {
      // remove from selected list
      var index = this.AllCustomers.indexOf(name);
      if (index > -1) {
        this.AllCustomers.splice(index, 1);
        var elements = this.AllCustomers;
      }
      var custID = [];
      var custName = [];
      this.AllCustomers.forEach(x => {
        var temp = x.split('(');
        custID.push(temp[1].replace(')', ''));
        custName.push(temp[0]);
      })
      this.accNo = custID.map(e => e).join('|');
      this.custNameparam = custName.map(e => e).join('|');
      this.LostbusinessCustomerService.filterObj1.AccountNo = this.accNo;
      this.LostbusinessCustomerService.filterObj1.FilterString = this.custNameparam;
      this.LostbusinessCustomerService.filterObj1.FilterBy = 'CUSTOMERNAME';
      if (this.AllCustomers.length > 1) {
        this.showAllcustomerText();
        this.LostbusinessCustomerService.filterObj1.ViewedBy = 'Customer';
      } else {
        this.showViewbyDropdown = false;
        this.showOneCustomerText();
        this.LostbusinessCustomerService.filterObj1.ViewedBy = 'Contact';
      }
      this.submittedcust.emit(this.LostbusinessCustomerService.filterObj1);
      this.displayShowName();

     
      if (this.AllCustomers.length == 0) {
        this.AllCustomersdata = true;
        this.showAllcustomerText();
        this.LostbusinessCustomerService.filterObj1.FilterBy = '';
        this.LostbusinessCustomerService.filterObj1.FilterString = null;
        this.LostbusinessCustomerService.filterObj1.AccountNo = null;
        this.LostbusinessCustomerService.filterObj1.ViewedBy = null;
        this.ShowName = 'All customers'
        this.submittedcust.emit(this.LostbusinessCustomerService.filterObj1);
      }

    } else {
      this.AllCustomers.push(name);
      this.selectedCBValue.push(name);
      if (this.AllCustomers.length > 1) {
        this.showAllcustomerText();
        var elements = this.selectedCBValue;
        this.AllCustomersdata = false;
        var custID = [];
        var custName = [];
        this.AllCustomers.forEach(x => {
          var temp = x.split('(');
          custID.push(temp[1].replace(')', ''));
          custName.push(temp[0]);
        })
        this.accNo = custID.map(e => e).join('|');
        this.custNameparam = custName.map(e => e).join('|');
        this.LostbusinessCustomerService.filterObj1.AccountNo = this.accNo;
        this.LostbusinessCustomerService.filterObj1.FilterString = this.custNameparam;
        this.LostbusinessCustomerService.filterObj1.FilterBy = 'CUSTOMERNAME';
        this.LostbusinessCustomerService.filterObj1.ViewedBy = 'Customer';
        this.submittedcust.emit(this.LostbusinessCustomerService.filterObj1);
        this.displayShowName();
      } else {
        this.showViewbyDropdown = false;
        this.showOneCustomerText();
        this.AllCustomersdata = false;
        var custID = [];
        var custName = [];
        this.AllCustomers.forEach(x => {
          var temp = x.split('(');
          custID.push(temp[1].replace(')', ''));
          custName.push(temp[0]);
        })
        this.accNo = custID.map(e => e).join('|');
        this.custNameparam = custName.map(e => e).join('|');
        this.LostbusinessCustomerService.filterObj1.AccountNo = this.accNo;
        this.LostbusinessCustomerService.filterObj1.FilterString = this.custNameparam;
        this.LostbusinessCustomerService.filterObj1.FilterBy = 'CUSTOMERNAME';
        this.LostbusinessCustomerService.filterObj1.ViewedBy = 'Contact';
        this.submittedcust.emit(this.LostbusinessCustomerService.filterObj1);
        this.displayShowName();
      }

    }
  }

  displayShowName() {
    this.ShowName = this.AllCustomers.map(e => e).join(', ');
    if (this.AllCustomers.length == 1) {
      this.singleCustomerName = this.ShowName;
      this.showSingleName=true;
    }
    else{
      this.showSingleName=false;
    }
  }

  FilterSearchClickEvent() {
    if (this.txtSearchCustomer == '' || this.txtSearchCustomer.length < 3) {
      return;
    }
    this.SearchNoResult = false;
    this.cbAllCustomers = false;
    this.selectedCustomers = true;
    this.LostbusinessCustomerService.filterObj1.FilterBy = 'CUSTOMERNAME';
    this.LostbusinessCustomerService.filterObj1.FilterString = this.txtSearchCustomer;
    this.GetDropdownData();
  }

  GetDropdownData() {
    this.dbSearchLoader = true;
    this.getlostSubscription = this.dashboardWidgetService.getLostCustDropdownData()
      .subscribe(apiResponse => {
        if (apiResponse != null) {
          if (apiResponse['Response'] != null && apiResponse['Status'] == 'Success') {
            this.customersDataOptionsDB = apiResponse['Response']['Collection'].map(e => {
              return { 'code': e.CustomerName, 'name': e.CustomerName };
            });
            this.SearchNoResult = false;
            this.customersDataOptions = this.customersDataOptionsDB;
            if (this.customersDataOptions.length > 0) {
              this.SearchNoResult = false;
            } else {
              this.customersDataOptions = [];
              this.SearchNoResult = true;
            }
            this.dbSearchLoader = false;
          } else {
            this.customersDataOptions = [];
            this.SearchNoResult = true;
            this.LostbusinessCustomerService.filterObj1.FilterString = null;
            this.LostbusinessCustomerService.filterObj1.FilterBy = null;
          }
          this.dbSearchLoader = false;
        } else {
          this.customersDataOptions = [];
          this.SearchNoResult = true;
          this.dbSearchLoader = false;
          this.LostbusinessCustomerService.filterObj1.FilterString = null;
          this.LostbusinessCustomerService.filterObj1.FilterBy = null;
        }
      }, err => {
        this.dbSearchLoader = false;
      });
  }

  showChartEvent(visibility: boolean) {
    // this.showChart = visibility;
    this.showChart = !this.showChart;
  }
  //To Open Drilldown on click of total count
  openTotalPopUp() {
    if (this.totalCustomerCount === 0) {
      return;
    } else {
      this.lostBussinesHideAllOtherDialogService.dialogClickEvent('TotalPopUpClicked')
      if (this.LostbusinessCustomerService.filterObj1.ViewedBy == 'Contact') {
        this.enableTotalCustPopUp = false;
        this.enableTotalContactPopUp = true;
        this.enableLostContactPopUp = false;
        this.enableWinContactPopUp = false;

      } else {
        this.enableTotalCustPopUp = true;
        this.enableTotalContactPopUp = false;
        this.enableLostCustPopUp = false;
        this.enableWinCustPopUp = false;
      }
    }
  }
  hideArrow(event) {
    if (event.hideArrow) {
      this.enableTotalCustPopUp = false;
    } else {
      this.enableTotalCustPopUp = true;
    }
  }
  hidetotalContactPopup(event) {
    if (event.hidetotalContactPopup) {
      this.enableTotalContactPopUp = false;
    } else {
      this.enableTotalContactPopUp = true;
    }
  }
  openLostPopUp() {
    if (this.lostCustomerCount === 0) {
      return;
    } else {
      this.lostBussinesHideAllOtherDialogService.dialogClickEvent('LostPopUpClicked')
      if (this.LostbusinessCustomerService.filterObj1.ViewedBy == 'Contact') {
        this.enableLostCustPopUp = false;
        this.enableLostContactPopUp = true;
        this.enableTotalContactPopUp = false;
        this.enableWinContactPopUp = false;
      } else {
        this.enableLostCustPopUp = true;
        this.enableLostContactPopUp = false;
        this.enableTotalCustPopUp =false;
        this.enableWinCustPopUp = false;
      }
    }
  }
  hidedrilldown(event) {
    if (event.hidedrilldown) {
      this.enableLostCustPopUp = false;
    } else {
      this.enableLostCustPopUp = true;
    }
  }
  hidelostcontactPopup(event) {
    if (event.hidelostcntContactPopup) {
      this.enableLostContactPopUp = false;
    } else {
      this.enableLostContactPopUp = true;
    }
  }
  openWinPopUp() {
    if (this.continuousCustomerCount === 0) {
      return;
    } else {
      this.lostBussinesHideAllOtherDialogService.dialogClickEvent('WinPopUpClicked')
      if (this.LostbusinessCustomerService.filterObj1.ViewedBy == 'Contact') {
        this.enableWinCustPopUp = false;
        this.enableWinContactPopUp = true;
        this.enableTotalContactPopUp = false;
        this.enableLostContactPopUp = false;
      } else {
        this.enableWinCustPopUp = true;
        this.enableWinContactPopUp = false;
        this.enableTotalCustPopUp = false;
        this.enableLostCustPopUp = false
      }
    }
  }
  hidewindrilldown(event) {
    if (event.hidewindrilldown) {
      this.enableWinCustPopUp = false;
    } else {
      this.enableWinCustPopUp = true;
    }
  }
  hidewincntContactPopup(event) {
    if (event.hidewincntContactPopup) {
      this.enableWinContactPopUp = false;
    } else {
      this.enableWinContactPopUp = true;
    }
  }

  showTrendChart() {
    this.lostBussinesHideAllOtherDialogService.dialogClickEvent('showTrendChartClicked')
    this.trendChartVisibility = true;
  }
  closeTrendChart() {
    this.trendChartVisibility = false;
  }

  ShowNoData($event) {
    this.lostBusinesssResponse = null;
    this.errorData = false;
    this.boolLostcustLoader = false;
    this.LostbusinessCustomerService.NoDataFoundSubject.next(true);
  }

closeAlldrilldown(){
  this.enableWinCustPopUp = false;
  this.enableWinContactPopUp = false;
  this.enableTotalCustPopUp = false;
  this.enableLostCustPopUp = false;
  this.enableLostContactPopUp = false;
  this.enableLostContactPopUp = false;

}
toggleLostView(e){
  this.lostBussinesHideAllOtherDialogService.dialogClickEvent('toggleLostViewCLick')
this.lostviewBy.toggle(e);
}
toggleLostCustOP(e){
  this.lostBussinesHideAllOtherDialogService.dialogClickEvent('toggleLostCustOPCLick')
  this.lostCustOP.toggle(e)
}
hideOtherDialog(e){
  console.log(e);
  if(e!='TotalPopUpClicked'){
    this.enableTotalCustPopUp = false;
    this.enableTotalContactPopUp = false;
  }
  if(e!='WinPopUpClicked'){
    this.enableWinCustPopUp = false;
    this.enableWinContactPopUp = false;
  }
  if(e!='LostPopUpClicked'){
    this.enableLostCustPopUp = false;
    this.enableLostContactPopUp = false;
  }
  if(e!='showTrendChartClicked'){
    this.trendChartVisibility=false;
  }
}
refreshWidget(widgetKey : string) {
  let refreshData = {
    refresh: true
  };
  if (widgetKey) {
    refreshData[widgetKey] = true;
  }
  this.chartDataService.setRefreshWidgetData(refreshData);
}
}
