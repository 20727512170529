import { NgModule } from '@angular/core';
import { AutofocusDirective } from './auto-focus.directive';
import { DragndropDirective } from './dragndrop.directive';
import { PermissionAccessDirective } from './permission-access.directive';
import { ResizableDirective } from './resizable.directive';
import { OnlyNumberDirective } from './onlynumber.directive';
import { DigitOnlyDirective } from './digit-only.directive';
import { InputMaxLengthDirective } from './input-max-length.directive';

@NgModule({
  declarations: [
    AutofocusDirective, 
    DragndropDirective,
    ResizableDirective,
    PermissionAccessDirective,
    OnlyNumberDirective,
    DigitOnlyDirective,
    InputMaxLengthDirective
  ],
  exports: [
    AutofocusDirective, 
    DragndropDirective,
    ResizableDirective,
    PermissionAccessDirective,
    OnlyNumberDirective,
    DigitOnlyDirective,
    InputMaxLengthDirective
  ],
})
export class commonDirectiveModule { }
