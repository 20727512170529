import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CtlsFileViewerComponent } from './ctls-file-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    NgxDocViewerModule
  ],
  declarations: [CtlsFileViewerComponent],
  exports: [CtlsFileViewerComponent]
})
export class CtlsFileViewerModule { }
