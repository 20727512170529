import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import {  takeUntil } from 'rxjs/operators';
import { CtlsSpinnerService } from './ctls-spinner.service';

@Component({
  selector: 'ctls-spinner',
  templateUrl: './ctls-spinner.component.html'
})
export class SpinnerComponent implements OnInit {
  @Input() blnSpinnerStatus = false;
  @Input() spinnerMapPopup = false;
  public visibility: boolean = false;
  destroy$ = new Subject();
  showSpinner = false;
  constructor(private spinnerService: CtlsSpinnerService,
    private spinner: NgxSpinnerService) {
  }
  ngOnInit() {
    /**
     * Using Output event emit
     */
    // this.spinnerService.visibility.pipe(takeUntil(this.destroy$)).subscribe(state => {
    //   this.visibility = state;
    //   this.spinner.show()
    // });

    // this.spinnerService.notVisibility.pipe(takeUntil(this.destroy$)).subscribe(state => {
    //   this.visibility = state;
    //   this.spinner.hide()
    // });
    /**
     * Using Observable
     */
    this.spinnerService.isVisible()
      .pipe(takeUntil(this.destroy$))
      .subscribe
      (state => {
        if (state === true) {
          this.spinner.show()
        } else {
          this.spinner.hide()
        }
      });

  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


}