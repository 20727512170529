import { Component,Input, OnInit ,Output,EventEmitter} from '@angular/core';
import { DialogService } from 'primeng/components/common/api';
import { LostBusinessStateModel, LbDdNav1State } from '../model/lost-business-lbcdrilldown';
import { LostBusinessDrilldownService } from '../service/lost-business-drilldown.service';


@Component({
  selector: 'app-lost-business-popup',
  templateUrl: './lost-business-popup.component.html',
  styleUrls: ['./lost-business-popup.component.scss']
})
export class LostBusinessPopupComponent implements OnInit {
  currentState: LostBusinessStateModel | null = null;

  /** Localizing imports */
  enumConstants = LbDdNav1State;
  divhide: boolean = true;
  @Input() showTotalText: any;
  @Input() totalCustomerCount: number;
  @Input() lostBaseYear:any;
  @Output() displayChange = new EventEmitter();

  constructor(private dialogService: DialogService, private drilldownService: LostBusinessDrilldownService) {
    // this.enableLBCPopup
    this.currentState = this.drilldownService.ddNav1GetData();
  }


  ngOnInit() {
  }
  onClickPrevious(data) {
    this.currentState = this.drilldownService.ddNav1.goToPrevComp(data);
  }
  onClickNext(data) {
    this.currentState = this.drilldownService.ddNav1.goToNextComp(data);
  }
  getData(data){
    this.onClickNext(data);
  }
  Hidediv(){
    this.drilldownService.ddNav1ResetStates();
    this.divhide=false;
    this.displayChange.emit({hideArrow : true});
  }
  openfirstpopup(data){
    this.currentState = this.drilldownService.ddNav1.goToPrevComp(data);
    this.currentState = this.drilldownService.ddNav1.goToPrevComp(data);    
  }
  
}
