import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtlsBreadCrumbModule } from './ctls-components/ctls-bread-crumb/ctls-bread-crumb.module';
import { CtlsFilterHeaderModule } from './ctls-components/ctls-flter-header/ctls-filter-header.module';
import { CtlsSurveyOptionModule } from './ctls-components/ctls-survey-options/ctls-survey-options.module';
import { CtlsCommonCommentModule } from './ctls-components/ctls-common-comment/ctls-commomn-comment.module';
import { CtlsShortFilterHeaderModule } from './ctls-components/ctls-short-flter-header/ctls-short-filter-header.module';
import { CtlsFileViewerModule } from './ctls-components/ctls-file-viewer/ctls-file-viewer.module';
import { CtlsRulePopoverComponent } from '../ctls-pages/ctls-ruleset/presentation/ctls-rule-popover/ctls-rule-popover.component';
import { CtlsRulePopoverModule } from '../ctls-pages/ctls-ruleset/presentation/ctls-rule-popover/ctls-rule-popover.module';
import { SharedModule } from 'src/app/shared/shared-module';
import { ReactiveFormsModule } from '@angular/forms';
import { CtlsTextFromArrayPipe } from './ctls-pipes/ctls-text-from-array.pipe';
import { CtlsCommaSeparatedTextPipe } from './ctls-pipes/ctls-comma-separated-text.pipe';
import { TodropdownPipe } from './ctls-directives/todropdown.pipe';
import { CtlsVocFilterHeaderModule } from './ctls-components/ctls-voc-filter-header/ctls-voc-filter-header.module';
import { HeatMapModule } from './heat-map/heat-map.module';
import { CtlsShortenPipe } from './ctls-pipes/ctls-string-shorten.pipe';


const componentArray = [
  CtlsTextFromArrayPipe,
  CtlsCommaSeparatedTextPipe,
  TodropdownPipe,
  CtlsShortenPipe
];

const exportModulesArray = [
  CtlsBreadCrumbModule,
  CtlsFilterHeaderModule,
  CtlsShortFilterHeaderModule,
  CtlsSurveyOptionModule,
  CtlsFileViewerModule,
  CtlsRulePopoverModule,
  SharedModule,
  ReactiveFormsModule,
  CtlsVocFilterHeaderModule,

];
@NgModule({
  imports: [
    CommonModule,
    CtlsBreadCrumbModule,
    CtlsFilterHeaderModule,
    CtlsShortFilterHeaderModule,
    CtlsSurveyOptionModule,
    CtlsCommonCommentModule,
    CtlsRulePopoverModule,
    SharedModule,
    ReactiveFormsModule,


  ],
  declarations: [componentArray],
  exports: [...componentArray, ...exportModulesArray]
})
export class CtlsSharedModule { }
// app-ctls-filter-header
