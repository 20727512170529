import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter, Input } from "@angular/core";
import { LostbusinessCustomerService } from "../../../service/lostbusiness-customer.service";
import { LostBusinessDrilldownService } from "../../../service/lost-business-drilldown.service";
import { LostBusinessStateModel, TrendDrilldownState } from "../../../model/lost-business-lbcdrilldown";
import { ChartConfigs } from "../../models/configs/default-chart-options.config";

@Component({
    selector: 'lb-chart-dd-holder',
    templateUrl: './chart-dd-holder.component.html',
    styleUrls: ['chart-dd-holder.component.scss']
})
export class ChartDdHolderComponent implements OnInit, OnDestroy, OnChanges {
    @Output() closeTrendChart: EventEmitter<any> = new EventEmitter<any>();

    states = TrendDrilldownState;

    showWhat: LostBusinessStateModel = new LostBusinessStateModel();
    filterBy: string = 'Customer';

    /** drilldown inputs */
    private readonly PERIOD = '{period}';
    private readonly ACTION = '{action}';
    private readonly FILTERBY = '{filterBy}';
    continuousCustomerCount: number;
    showContitext: string;
    lostComparisonDate: string;

    constructor(protected filterService: LostbusinessCustomerService
        , private drillDownService: LostBusinessDrilldownService) {
            this.setDefaultValue();
            this.getActiveStateDetails();
    }
    ngOnInit(): void {
        this.filterBy = this.filterService.filterObj1['ViewedBy'] ? this.filterService.filterObj1['ViewedBy'] : 'Customer';
    }
    ngOnChanges(changes: SimpleChanges): void {
    }
    ngOnDestroy(): void {
        this.drillDownService.trendResetStates();
    }

    closeTrendChartAction() {
        this.closeTrendChart.emit(new Date());
    }
    callbackForL1(event: { count: number, period: string, type: string }) {
        let isOnWinPath: boolean = event.type.toLowerCase() === 'win';
        this.continuousCustomerCount = event.count;
        // this.showContitext = this.showContitext.split(this.PERIOD).join(event.period);
        this.showContitext = this.showContitext.split(this.ACTION).join(isOnWinPath ? ChartConfigs.CONTINUED_HOVER_TEXT.split(ChartConfigs.PLACEHOLDER).join('') : ChartConfigs.DISCONTINUED_HOVER_TEXT.split(ChartConfigs.PLACEHOLDER).join(''));
        this.showContitext = this.showContitext.split(this.FILTERBY).join(this.filterBy + 's');
        this.lostComparisonDate = event['period']

        this.drillDownService.trendSetNextPath(isOnWinPath, { 
            ...event, 
            isOnWinPath: isOnWinPath, 
            filterBy: this.filterBy + 's'
        });

        this.getActiveStateDetails();
    }
    callbackForL2(params: LostBusinessStateModel) {
        this.getActiveStateDetails();
    }
    callbackForPrevious(data) {
        this.showWhat = this.drillDownService.trendPrev(data);
        switch (this.showWhat.Id) {
            case this.states.LOST_1:
            case this.states.WIN_1:
                {
                    let isOnWinPath: boolean = this.showWhat.Data.type.toLowerCase() === 'win';
                    this.continuousCustomerCount = this.showWhat.Data.count;
                    // this.showContitext = this.showContitext.split(this.PERIOD).join(event.period);
                    this.showContitext = this.showContitext.split(this.ACTION).join(isOnWinPath ? 'continued' : 'discontinued');
                    this.showContitext = this.showContitext.split(this.FILTERBY).join(this.filterBy + 's');
                }
                break;
            
            case this.states.BASE:
                {
                    this.setDefaultValue();
                }
                break;

            default:
                break;
        }
    }
    callbackForRootView() {
        this.showWhat = this.drillDownService.trendResetStates();
        this.setDefaultValue();
    }

    getActiveStateDetails() {
        this.showWhat = this.drillDownService.trendStateNav.getDataActiveState();
    }
    setDefaultValue() {
        this.showContitext = `${this.FILTERBY} ${this.ACTION} with us in `;
        this.continuousCustomerCount = 0;
        this.lostComparisonDate = new Date().toDateString().split(' ')[1] + ' ' + new Date().toDateString().split(' ')[3];
    }
}