import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsRuleAssignmentService {
  private subject = new Subject<any>();
  // private serviceUrl = this.sharedService.configuration.baseUrl;
  private serviceUrl = environment.baseUrl;
  filterObjForTransactional = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: false,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: false,
    Region: false,
    Zone: false,
    Branch: false,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'TRANS',
    screenCode: 'CTLS_RULE_ASSIGNMENT', //SELECTED SCREEN
    sessionStorageName: 'CTLS_TRANS_RULE_ASSIGNMENT',
    savedFilterSessionStorageName: 'CTLS_TRANS_RULE_ASSIGNMENT_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyInstanceID',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'SurveyID',
      'IsFirstSave',
      'Status',
      'Assign',
      'RecommendedAction'
    ],
  };
  filterObjForRelational = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: false,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: false,
    Region: false,
    Zone: false,
    Branch: false,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'DIST',
    screenCode: 'CTLS_RULE_ASSIGNMENT', //SELECTED SCREEN
    sessionStorageName: 'CTLS_DIST_RULE_ASSIGNMENT',
    savedFilterSessionStorageName: 'CTLS_DIST_RULE_ASSIGNMENT_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyInstanceID',
      'InstanceStartDate',
      'InstanceEndDate',
      'SurveyInstance',
      'Country',
      'Division',
      'SurveyID',
      'IsFirstSave',
      'Status',
      'Assign',
      'RecommendedAction'
    ],
  };
  recordCount: number;
  /** Form wizard state */
  surveyID: number = 0;
  markforsurvey: string = '';

  instanceAccordionStatus: any = {};

  /** BehaviourSubjects */
  blockFull: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  toggleRulePopover = new BehaviorSubject(false);

  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.recordCount = 0;
  }

  deleteSurvey(surveyID) {
    return this.http
      .post(
        this.serviceUrl + 'api/RelationshipSurveys/' + surveyID + '/Delete',
        {}
      )
      .toPromise()
      .then((res: any) => res)
      .then((data) => {
        return data;
      });
  }

  pinUnpin(SurveyId, IsPinned) {
    if (IsPinned) {
      return this.http
        .post(
          this.serviceUrl +
          'api/RelationshipSurveys/' +
          SurveyId +
          '/PinSurvey',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    } else {
      return this.http
        .post(
          this.serviceUrl +
          'api/RelationshipSurveys/' +
          SurveyId +
          '/unPinSurvey',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    }
  }

  /** Helpers */
  public getDateinPreferredFormat = (date: Date): string => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  /**Service for activate and deactivate the survey
   * @param SurveyId
   * @param IsEnable
   */

  activateSurvey(SurveyId, IsEnable) {
    if (IsEnable) {
      return this.http
        .post(
          this.serviceUrl + 'api/RelationshipSurveys/' + SurveyId + '/Disable',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    } else {
      return this.http
        .post(
          this.serviceUrl + 'api/RelationshipSurveys/' + SurveyId + '/Enable',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    }
  }

  /**Service for load grid data in ctls configuration
   * @param filterObj
   * @param value
   */

  getRuleAssignmentData(value, filterObj): Observable<any> {
    if (value === 1) {
      return this.http.post<any>(
        `${this.serviceUrl}api/RuleAssignment/GetTransactionalSurveys`,
        filterObj
      );
    } else if (value === 2) {
      return this.http.post<any>(
        `${this.serviceUrl}api/RuleAssignment/GetRelationshipSurveys`,
        filterObj
      );
    }
  }

  /**Service for save  modified columns
   * @param dataObj
   */
  SaveModifyColumns(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/GridColumn/SaveModifyColumn`,
      dataObj
    );
  }

  /**Service for get  modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj) {
    return this.http.get<any>(
      `${this.serviceUrl}api/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
          SubCategoryCode: dataObj.SubCategoryCode,
        },
      }
    );
  }
  /**Service for get rule set
   * @param dataObj
   */
  getRuleSet(type) {
    return this.http.get<any>(
      `${this.serviceUrl}api/RuleAssignment/GetRuleSetDropDown`,
      {
        params: {
          surveyType: type,
        },
      }
    );
  }

  /**Service for get delete rules
   * @param dataObj
   */
  getDeleteRules(type) {
    return this.http.post<any>(
      `${this.serviceUrl}api/RuleAssignment/GetDeletedRules?surveyType=${type}`,
   null
    );
  }

  /**Service for get added assignee
   * @param dataObj
   */
  getSavedAssigneeDetails(data) {
    return this.http.get<any>(
      `${this.serviceUrl}api/RuleAssignment/GetSavedAssigneeDetails`,
      {
        params: data
      }
    );
  }
  /**Service for save  status
   * @param dataObj
   */
  SaveStatus(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/RuleAssignment/ActivateRuleAssignment?IsEnable=${dataObj.IsEnable}&RuleAssignmentID=${dataObj.RuleAssignmentID}`,
      null
    );
  }
   /**Service for dismissRule
   * @param dataObj
   */
    dismiss(dataObj): Observable<any> {
      return this.http.post<any>(
        `${this.serviceUrl}api/RuleAssignment/SaveDismissedRules`,
        dataObj
      );
    }

       /**Service for restoreRule
   * @param dataObj
   */
        restore(dataObj): Observable<any> {
          return this.http.post<any>(
            `${this.serviceUrl}api/RuleAssignment/SaveRestoreRequest`,
            dataObj
          );
        }

          /**Service for Approve or Reject deleted rule
   * @param dataObj
   */
           acceptorReject(dataObj): Observable<any> {
            return this.http.post<any>(
              `${this.serviceUrl}api/RuleAssignment/AcceptOrRejectRestoreRequest`,
              dataObj
            );
          }    

  /**Service for save  assignee
   * @param dataObj
   */
  saveAssigneeDetails(dataObj) {
    return this.http.post<any>(
      `${this.serviceUrl}api/RuleAssignment/SaveAssigneeDetails`,
      dataObj
    );
  }
  /**Service for save  status
   * @param dataObj
   */
  savePriorityDesignation(dataObj) {
    return this.http.post<any>(
      `${this.serviceUrl}api/RuleAssignment/SaveRuleAssignmentDetails`,
      dataObj
    );
  }

  /**
   * @param dataObj
   */
  GetAllComments(dataObj): Observable<any> {
    // return of([
    //   {
    //     date: '05-Nov-2020',
    //     name: 'Fleming Go',
    //     email: 'fleming@go.com',
    //     desc: 'This is the comment made by felming',
    //   },
    //   {
    //     date: '06-Nov-2020',
    //     name: 'Swan',
    //     email: 'swan@gmail.com',
    //     desc: 'This is the comment made by Swan',
    //   },
    // ]);
    return this.http.post(
      `${this.serviceUrl}api/CTLSConfiguration/GetCommentHistory`,
      dataObj
    );
  }

  /**
 * Saves task listing sequence
 * @param saveObj
 */
  SaveModifiedSequence(saveObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/RuleAssignment/SaveRuleAssignmentSequence`,
      saveObj
    );
    // return of({ Response: saveObj.SequenceDetailsList, Status: 'SUCCESS' })
  }

    /**
* Unpin task sequence
* @param CTLSTaskListID
*/
unpinRuleSequence(RuleAssignmentID): Observable<any> {
  return this.http.post<any>(
    `${this.serviceUrl}api/RuleAssignment/UnpinRuleSequence?RuleAssignmentID=${RuleAssignmentID}`,
    {}
  );
}
  /**Service for saving status change
* @param dataObj
*/
  getRuleCriteriaPopup(CTLSRuleID): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}api/RuleSetup/GetRuleCriteriaPopup`,
      {
        params: {
          CTLSRuleID: CTLSRuleID
        },
      }
    );
  }

  /**
* set
* @param flag
*/
  setOnClickOfRule(flag: boolean) {
    this.toggleRulePopover.next(flag);
  }

  /**
   * get current popover status
   */
  getRulePopoverStatus() {
    // var data = false;
    // this.toggleRulePopover.subscribe((value) => {
    //   data = value;
    // });
    return this.toggleRulePopover.getValue();
  }


  setMessage(message: any) {
    this.subject.next(message);
  }
  clearMessage() {
    this.subject.next();
  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  getDivDetails(data) {
    return this.http.get<any>(
      `${this.serviceUrl}api/RuleAssignment/GetDivDetails`,
      {
        params: data
      }
    );
  }
  getBrandDetails(data) {
    return this.http.put<any>(
      `${this.serviceUrl}api/RuleAssignment/GetBrandDetails`,data
    );
  }
  getAssigneeDetails(data) {
    return this.http.put<any>(
      `${this.serviceUrl}api/RuleAssignment/GetAssigneeDetails`,data
    );
  }
}
