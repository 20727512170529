import { forEach } from '@angular/router/src/utils/collection';
import { widgetCodes } from 'src/app/shared/constants/shared.constants';
import { RelationshipDashboardSharedEventService } from './../../../shared/services/relationship-dashboard-shared-event.service';
import { surveyType } from './../../../shared/constants/shared.constants';
import { Component, OnInit, Input, Output, EventEmitter, Renderer, OnChanges, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subscription } from 'rxjs';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import * as _html2canvas from "html2canvas";
const html2canvas:any = _html2canvas;
import { filter } from 'rxjs/operators';
import { ExportChartModel } from 'src/app/shared/models/export-chart.model';
import { DashboardWidgetService } from '../service/dashboard-widget.service';
import { DistDashbaordService } from 'src/app/Modules/Distributor_Survey/Services/dist-dashbaord.service';
import { ChartDataService } from '../service/chart-data-service.service';
import { environment } from 'src/environments/environment';
const WIDGET_CODE = 'ia';

@Component({
  selector: 'app-influencer-analysis',
  templateUrl: './influencer-analysis.component.html',
  styleUrls: ['./influencer-analysis.component.scss']
})
export class InfluencerAnalysisComponent implements OnInit, OnChanges, OnDestroy {
  @Input('customersData') customersData: any;
  @Input('customerDetailsForInfluence') customerDetailsForInfluence: any;
  @Output('selectedCostemerId') selectedCostemerId: EventEmitter<any> = new EventEmitter<any>();
  @Input('influencerContacts') influencerContacts: any;
  @Input() errorData: boolean;
  @Input('isFromFilterChanged') isFromFilterChanged: number;
  @Output('influencerTypes') influencerTypes: EventEmitter<any> = new EventEmitter<any>();
  @Input('boolInfluencerCustomerLoader') boolInfluencerCustomerLoader;
  @Input('boolInfluencerAccordianLoader') boolInfluencerAccordianLoader;
  @Input('influencerTypesLoader') influencerTypesLoader;
  @Input('onClickChnageDetection') onClickChnageDetection;
  @Input() surveyTypeInput: string = surveyType.Transactional;
  widgetCodesConst: any;
  selectedCustomer: any;
  selectedCustomerId: any;
  isPagination = true;
  ChangedcustomerId: any;
  ExpandAllShowHide = false;
  titleVal: string;

  private chartState: CHART_STATE_OPTIONS = CHART_STATE_OPTIONS.LOADING;
  private exportSubscription: Subscription;
  @ViewChild('pictureOf') pictureOf: ElementRef;

  constructor(private render: Renderer, private sharedService: SharedService
    , private rdSharedEventService: RelationshipDashboardSharedEventService
    , private dashboardWidgetService: DashboardWidgetService, private distdashboardService: DistDashbaordService,
      private chartDataService: ChartDataService) {
    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(val => val !== null && val.includes(WIDGET_CODE.toLowerCase()))).subscribe(val => {
      this.toCanvas();
    });
    this.widgetCodesConst = widgetCodes;
  }
  private imagepath = environment.imagepath;
  custData = null;
  totalCustomer = 0;
  showProm = true;
  showDet = true;
  showNue = true;
  showNR = true;
  styleGuide = {
    caretClass: 'caret',
    selectBoxClass: 'dropdown-wrapper',
    selectMenuClass: 'dropdown',
    optionsClass: 'option'
  };
  customersDatalength: any;

  selectFirstRow = 0;
  configRow: number[] = [12, 20, 30, 40, 50];

  testData = [];
  PNDFlag = [];
  expandAll = false;

  linkCustName = '';
  // for search functionality
  customersDataOptions: any = [];
  customersDataOptionsDB: any = [];
  txtSearchCustomer = '';
  showtopHeading = true;
  SearchNoResult = false;
  isSearchFromDB = false;
  dbSearchLoader = false;
  // subsribers
  getInfluenceSubscription: Subscription;

  //Pagination tooltip issue
  //JIRA Id- 356
  paginatorFirstSelector: any;
  paginatorLaststSelector: any;
  paginatorNextSelector: any;
  paginatorPrevSelector: any;

  ngOnInit() {
    this.txtSearchCustomer = '';
  }

  ngOnChanges() {

    if (this.isSearchFromDB) {
      // Search from DB option click
      this.customersDataOptions = this.customersDataOptionsDB;
    } else {
      this.customersDataOptions = this.customersData;
    }

    this.customersDatalength = 0;
    if (this.customersData != null && this.customersData.length !== 0) {
      try {
        this.customersDatalength = this.customersData[0].TotalCustomers;
      } catch (ex) {
        this.customersDatalength = 0;
      }
      for (let i = 0; i < this.customersData.length; i++) {
        if (typeof (this.customersData[i].CustomerID) != 'undefined' && typeof (this.customersData[i].CustomerName) != 'undefined') {
          this.customersData[i].code = this.customersData[i].CustomerID;
          this.customersData[i].name = this.customersData[i].CustomerName;
          delete this.customersData[i].CustomerID;
          delete this.customersData[i].CustomerName;
        }
      }
      if (this.customersDatalength > 0) {
        this.selectedCustomerId = this.customersData[0].code;
        this.selectedCustomer = this.customersData[0];
        if (this.linkCustName == '') {
          this.linkCustName = this.customersData[0].name;
        }
        if (this.titleVal == '' || this.titleVal == undefined || this.isFromFilterChanged > 0) {
          this.expandAll = false;
          this.titleVal = this.selectedCustomerId;
          this.PNDFlag = [];
          this.showProm = true;
          this.showNue = true;
          this.showDet = true;
          this.showNR = true;
          this.linkCustName = '';
          this.isFromFilterChanged = 0;
          this.selectedCostemerId.emit(this.selectedCustomerId);
        }
      }
    } else {
      this.titleVal = '';
      this.linkCustName = '';
    }

    if (this.customerDetailsForInfluence != null && this.customerDetailsForInfluence.length > 0) {
      this.customerDetailsForInfluence.map(element => {
        if (this.influencerContacts !== undefined && this.influencerContacts != null) {
          const contactArray = this.influencerContacts[element.InfluencerTypeName];
          element['totalRecords'] = (contactArray != null && contactArray.length > 0) ? contactArray[0].TotalRecords : 0;
          element['pageNo'] = element['pageNo'] > 0 ? element['pageNo'] : 1;
          element['pageSize'] = element['pageSize'] > 0 ? element['pageSize'] : 12;

          if (this.influencerTypesLoader.includes(element.InfluencerTypeName)) {
            element['loader'] = true;
          } else {
            element['loader'] = false;
          }

          this.isPagination = true;
        }
        return element;
      });
    }

    let cnt = 0;
    if (this.customerDetailsForInfluence != null) {
      this.customerDetailsForInfluence.forEach(element => {
        if (element.SurveySentCnt > 0) {
          cnt++;
        }
      });
    }

    if (cnt > 0) {
      this.ExpandAllShowHide = true;
    } else {
      this.ExpandAllShowHide = false;
    }
    this.updateChartState();
    this.updateExportDetails();

    setTimeout(() => {
      this.PageToolTip();
    }, 1000);   
  }

  ngOnDestroy() {
    if (this.getInfluenceSubscription) {
      this.getInfluenceSubscription.unsubscribe();
    }
    this.exportSubscription.unsubscribe();
    this.updateExportDetails(true);
  }

  // onChangeCustomer(event: any) {
  //   this.expandAll = false;
  //   this.PNDFlag = [];
  //   this.showProm = true;
  //   this.showNue = true;
  //   this.showDet = true;
  //   this.showNR = true;
  //   if (event != null) {
  //     this.selectedCustomerId = (event.code) ? event.code : null;
  //     this.titleVal = this.selectedCustomerId;
  //     this.ChangedcustomerId = this.selectedCustomerId;
  //     this.selectedCostemerId.emit(this.selectedCustomerId);
  //     this.linkCustName = event.name;
  //   }
  // }

  showHideTypes(event: any) {
    this.isPagination = false;
    event.preventDefault();

    const clickedElement = event.currentTarget;
    const expandedInfluncer = [];
    this.PNDFlag = [];
    let influncerArrayElement = clickedElement.parentElement.parentElement.parentElement.querySelectorAll('div[data-typename]');
    influncerArrayElement.forEach(element => {
      if (!element.classList.contains('collapsed')) {
        expandedInfluncer.push(element.getAttribute('data-typename'));
      }
    });

    if (clickedElement.classList.contains('legend-prom')) {
      if (clickedElement.classList.contains('active') == false) {
        this.showProm = false;
        this.render.setElementClass(clickedElement, 'active', true);
      } else {
        this.showProm = true;
        this.render.setElementClass(clickedElement, 'active', false);
      }
    }

    if (clickedElement.classList.contains('legend-neu')) {
      if (clickedElement.classList.contains('active') == false) {
        this.showNue = false;
        this.render.setElementClass(clickedElement, 'active', true);
      } else {
        this.showNue = true;
        this.render.setElementClass(clickedElement, 'active', false);
      }
    }

    if (clickedElement.classList.contains('legend-det')) {
      if (clickedElement.classList.contains('active') == false) {
        this.showDet = false;
        this.render.setElementClass(clickedElement, 'active', true);
      } else {
        this.showDet = true;
        this.render.setElementClass(clickedElement, 'active', false);
      }
    }

    if (clickedElement.classList.contains('legend-nr')) {
      if (clickedElement.classList.contains('active') == false) {
        this.showNR = false;
        this.render.setElementClass(clickedElement, 'active', true);
      } else {
        this.showNR = true;
        this.render.setElementClass(clickedElement, 'active', false);
      }
    }

    if (this.showProm == false) {
      this.PNDFlag.push('P');
    }
    if (this.showNue == false) {
      this.PNDFlag.push('N');
    }
    if (this.showDet == false) {
      this.PNDFlag.push('D');
    }
    if (this.showNR == false) {
      this.PNDFlag.push('X');
    }

    expandedInfluncer.forEach(element => {
      this.getContactDetails('', 1, 12, element, true);
    });
  }

  getContactDetails(event: any, pageNo = 1, pageSize = 12, influncerType = '', enableLoader = false) {
    const clickedElement = event.currentTarget;
    let isEligibale = false;
    let type = '';
    if (influncerType != '') {
      isEligibale = true;
      type = influncerType;
    } else if (clickedElement.classList.contains('collapsed') == true) {
      isEligibale = true;
      type = clickedElement.getAttribute('data-typename');
    }

    let id = this.titleVal;
    // if(this.ChangedcustomerId){
    //   id = this.ChangedcustomerId
    // }
    if (isEligibale) {
      const data = {
        'CustomerName': id,
        'InfluencerType': type,
        'PNDFlag': this.PNDFlag.join('|'),
        'PageSize': pageSize,
        'PageIndex': pageNo
      };
      if (this.customerDetailsForInfluence != null && this.customerDetailsForInfluence.length > 0) {
        this.customerDetailsForInfluence.map(element => {
          if (element.InfluencerTypeName == type) {
            element['pageNo'] = pageNo;
            element['pageSize'] = pageSize;
            element['loader'] = enableLoader;
          }
          return element;
        });
      }      
      this.influencerTypes.emit(data);
    }
  }

  paginationNavigationDetails(event, type) {
    let pNo = 1;
    let pSize = 12;
    this.customerDetailsForInfluence.forEach(element => {
      if (element.InfluencerTypeName == type) {
        pNo = element['pageNo'];
        pSize = element['pageSize'];
      }
    });

    if (event.page >= pNo || (event.page + 2) <= pNo || pSize != event.rows) {
      this.getContactDetails(type, event.page + 1, event.rows, type, true);
      // this.paginationData(this.pageNo, this.pageSize);
    }
  }

  //Pagination tooltip issue
  //JIRA Id- 356
  PageToolTip() {
    let i = 0;
    this.paginatorFirstSelector = document.querySelectorAll('a.ui-paginator-first');
    for (i = 0; i < this.paginatorFirstSelector.length; i++) {
      this.paginatorFirstSelector[i].title = 'First';
    }

    this.paginatorLaststSelector = document.querySelectorAll('a.ui-paginator-last');
    for (i = 0; i < this.paginatorLaststSelector.length; i++) {
      this.paginatorLaststSelector[i].title = 'Last';
    }

    this.paginatorNextSelector = document.querySelectorAll('a.ui-paginator-next');
    for (i = 0; i < this.paginatorNextSelector.length; i++) {
      this.paginatorNextSelector[i].title = 'Next';
    }

    this.paginatorPrevSelector = document.querySelectorAll('a.ui-paginator-prev');
    for (i = 0; i < this.paginatorPrevSelector.length; i++) {
      this.paginatorPrevSelector[i].title = 'Previous';
    }
  }

  ExpandAll(event, ) {
    event.preventDefault();
    const influncerArray = event.currentTarget.parentElement.querySelectorAll('div[data-typename]');
    this.expandAll = !this.expandAll;
    for (let i = 0; i <= influncerArray.length - 1; i++) {
      const element = influncerArray[i];
      if (element.getAttribute('data-surveycount') > 0) {
        let type = element.getAttribute('data-typename');
        if (type != '') {
          let el = (<HTMLElement>element);
          this.getContactDetails('', 1, 12, type);
          if (this.expandAll) {
            if (element.classList.contains('collapsed')) {
              el.click();
            }
          } else if (!this.expandAll) {
            if (!element.classList.contains('collapsed')) {
              el.click();
            }

          }

        }
      }
      this.PageToolTip();
    }
    // influncerArray.forEach(element=>{
    //   if(element.getAttribute("data-surveycount") > 0){
    //     var type = element.getAttribute("data-typename");
    //     if(type!=""){
    //       var el = (<HTMLElement>element);
    //       this.getContactDetails("", 1, 12, type);
    //       if(this.expandAll ){
    //         if(element.classList.contains("collapsed")){
    //           el.click();
    //         }
    //       }
    //       else if(!this.expandAll){
    //         if(!element.classList.contains("collapsed")){
    //           el.click();
    //         }

    //       }

    //     }
    //   }

    // });
  }

  closeWidget() {
    if (this.surveyTypeInput != null && (this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput == 'OEM')) {
      this.rdSharedEventService.closeWidget(this.widgetCodesConst.InfluencerAnalysis);
    } else {
      this.sharedService.widgetCloseSubject.next(this.widgetCodesConst.InfluencerAnalysis);
    }
  }

  private toCanvas = () => {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    try {
      if (this.pictureOf && this.pictureOf.nativeElement) {
        html2canvas(this.pictureOf.nativeElement, { scrollX:0, scrollY:-window.scrollY, allowTaint: false, useCORS: false, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
          chartUpdate.UpdateTime = new Date();
          chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
          chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
          chartUpdate.CurrentState = this.chartState;
          chartUpdate.ChartImgBase64 = canvas.toDataURL('image/png');
          this.sharedService.updateChartStatusSubject(chartUpdate);
        },
          err => {
            console.log('error occurred during image export, details as below \n', err);
            chartUpdate = this.exportErrorUpdate();
            this.sharedService.updateChartStatusSubject(chartUpdate);
          });
      } else if (this.chartState === CHART_STATE_OPTIONS.NO_DATA) {
        chartUpdate.UpdateTime = new Date();
        chartUpdate.ChartName = this.widgetCodesConst.toLowerCase();
        chartUpdate.ChartImgId = this.widgetCodesConst.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
        chartUpdate.CurrentState = this.chartState;
        chartUpdate.ChartImgBase64 = 'no_image_data_available_for_this_state';
        chartUpdate.HasNoData = true;
        this.sharedService.updateChartStatusSubject(chartUpdate);
      }
    } catch (error) {
      console.log('html2canvas error as', error);
      chartUpdate = this.exportErrorUpdate();
      this.sharedService.updateChartStatusSubject(chartUpdate);
    }
  }
  private updateExportDetails = (isDestroyCall: boolean = false) => {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    chartUpdate.UpdateTime = new Date();
    chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
    if (isDestroyCall) {
      this.chartState = CHART_STATE_OPTIONS.UNKNOWN;
    }
    chartUpdate.CurrentState = this.chartState;// ? this.chartState.toLowerCase() : CHART_STATE_OPTIONS.LOADING;
    this.sharedService.updateChartStatusSubject(chartUpdate);
  }
  private updateChartState = () => {
    this.chartState = !this.boolInfluencerCustomerLoader ? CHART_STATE_OPTIONS.SUCCESS : CHART_STATE_OPTIONS.LOADING;
  }
  exportErrorUpdate = (): ExportChartModel => {
    let err: ExportChartModel = new ExportChartModel();
    err.HasErrorInExport = true;
    err.UpdateTime = new Date();
    err.ChartName = WIDGET_CODE.toLowerCase();
    err.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
    err.CurrentState = this.chartState;
    return err;
  }

  // for contact search functionality
  ContactFilterEvent(event) {
    // if (this.lists1.length == 0) {
    //   this.lists1 = this.lists;
    // }

    // if (event == '') {
    //   this.lists = this.lists1;
    //   return;
    // }

    // // filter list depending on search charactors

    // this.lists = [];
    // this.lists1.forEach(ol => {
    //   if (ol.toLowerCase().includes(event.toLowerCase())) {
    //     this.lists.push(ol);
    //   }
    // });

    // // for show No record found
    // if (event != '' && this.lists.length == 0) {
    //   this.SearchNoResult = true;
    // } else {
    //   this.SearchNoResult = false;
    // }
  }

  onSearchKeyPress(event: any) {
    if (event && event.which == '13') {
      this.FilterSearchClickEvent();
    }
  }

  FilterSearchClickEvent() {
    if (this.txtSearchCustomer == '') {
      // Search from top 100
      this.customersDataOptions = this.customersData;
      this.showtopHeading = true;
      this.SearchNoResult = false;
      this.isSearchFromDB = false;
    } else {
      // Search from Database
      this.showtopHeading = false;
      this.customersDataOptions = [];
      this.customersDataOptionsDB = [];
      this.isSearchFromDB = true;
      this.dbSearchLoader = true;

      if (this.surveyTypeInput != null && (this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput =='OEM')){
        this.getInfluenceSubscription = this.distdashboardService.getInfluenceAllCustomersSearch(this.txtSearchCustomer)
          .subscribe(apiResponse => {
            if (apiResponse != null) {
              if (apiResponse['Response'] != null && apiResponse['Status'] == 'Success') {
                this.customersDataOptionsDB = apiResponse['Response']['Collection'].map(e => {
                  return { 'code': e.CustomerID, 'name': e.ContactName };
                });
                this.customersDataOptions = this.customersDataOptionsDB;
                if (this.customersDataOptions.length > 0) {
                  this.SearchNoResult = false;
                } else {
                  this.SearchNoResult = true;
                }
              }
              this.dbSearchLoader = false;
            } else {
              this.dbSearchLoader = false;
            }
          }, err => {
            this.dbSearchLoader = false;
          });
      }
      else {
        this.getInfluenceSubscription = this.dashboardWidgetService.getInfluenceAllCustomersSearch(this.txtSearchCustomer)
          .subscribe(apiResponse => {
            if (apiResponse != null) {
              if (apiResponse['Response'] != null && apiResponse['Status'] == 'Success') {
                this.customersDataOptionsDB = apiResponse['Response']['Collection'].map(e => {
                  return { 'code': e.CustomerID, 'name': e.ContactName };
                });
                this.customersDataOptions = this.customersDataOptionsDB;
                if (this.customersDataOptions.length > 0) {
                  this.SearchNoResult = false;
                } else {
                  this.SearchNoResult = true;
                }
              }
              this.dbSearchLoader = false;
            } else {
              this.dbSearchLoader = false;
            }
          }, err => {
            this.dbSearchLoader = false;
          });
      }
    }
  }

  SelectContactclick(code, name) {
    this.expandAll = false;
    this.PNDFlag = [];
    this.showProm = true;
    this.showNue = true;
    this.showDet = true;
    this.showNR = true;
    if (code != '') {
      this.selectedCustomerId = (code) ? code : null;
      this.titleVal = this.selectedCustomerId;
      this.ChangedcustomerId = this.selectedCustomerId;
      this.selectedCostemerId.emit(this.selectedCustomerId);
      this.linkCustName = name;
      this.selectedCustomerId = code;
    }
  }
  refreshWidget(widgetKey : string) {
    let refreshData: any = {
      refresh: true
    };
    if (widgetKey) {
      refreshData[widgetKey] = true;
      refreshData.selectedCustomerId = this.selectedCustomerId;
    }
    this.chartDataService.setRefreshWidgetData(refreshData);
  }

  // END - for contact search functionality
}

