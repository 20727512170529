import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FalconSessionStorageService } from './falcon-session-storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as _ from 'lodash';
import * as Rx from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class FalconCommonService {
  public messageToaster$ = new BehaviorSubject<any>(null);
  private masterData$ = new BehaviorSubject<any>({});
  private serviceUrl = this.sharedService.configuration.baseUrl;
  // messageToaster$ = this.messageToaster.asObservable();
  
  filterObj = {
    TrendAnalysis: '',
    StartDate: '',
    EndDate: '',
    PeriodType: '',
    BACode: '',
    FAMCode: '',
    DivCode: '',
    BLCode: '',
    Country: '',
    OperationalCountry: '',
    Category: '',
    BrandName: '',
    SalesEngineer: '',
    Touchpoint: '',
    Influencer: '',
    ServiceTechnician: '',
    Continent: '',
    Region: '',
    Zone: '',
    Branch: '',
    PinnedField: '',
    ActionType: '',
    CustomerNameCode: '', //11
    CustomerAccountNumberCode: '', //22
    Distributors: '', //33
    DistributorSegment: '', //44
    DistributorAccountNumber: '', //55
  };

  filterObjForPostCode = {
    StartDate: false,
    EndDate: false,
    PeriodType: false,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: false,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: false,
    Region: false,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'TRANS',
    screenCode: 'POST_CODE_MASTER', //SELECTED SCREEN
    sessionStorageName: 'POST_CODE_MASTER',
    savedFilterSessionStorageName: 'POST_CODE_MASTER_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: false,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyInstanceID',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'SurveyID',
      'IsFirstSave',
      'Status',
      'Assign',
      'RecommendedAction'
    ],
  };
  filterObjForUngroupedPostCode = {
    StartDate: false,
    EndDate: false,
    PeriodType: false,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: false,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: false,
    Region: false,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'TRANS',
    screenCode: 'POST_CODE_MASTER_UNGROUPED', //SELECTED SCREEN
    sessionStorageName: 'POST_CODE_MASTER_UNGROUPED',
    savedFilterSessionStorageName: 'POST_CODE_MASTER_UNGROUPED_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: false,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyInstanceID',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'SurveyID',
      'IsFirstSave',
      'Status',
      'Assign',
      'RecommendedAction'
    ],
  };
  filterObjForGlobalPostCode = {
    StartDate: false,
    EndDate: false,
    PeriodType: false,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: false,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: false,
    Region: false,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'TRANS',
    screenCode: 'POST_CODE_MASTER_GLOBAL', //SELECTED SCREEN
    sessionStorageName: 'POST_CODE_MASTER_GLOBAL',
    savedFilterSessionStorageName: 'POST_CODE_MASTER_GLOBAL_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: false,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyInstanceID',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'SurveyID',
      'IsFirstSave',
      'Status',
      'Assign',
      'RecommendedAction'
    ],
  };
  selectedFilters = {
    baCount: [],
    divCount: [],
    famCount: [],
    blCount: [],
    countryCount: [],
    operationalCountryCount: [],
    brandCount: [],
    zoneCount: [],
    branchCount: [],
    salesCount: [],
    serviceCount: [],
    touchpointCount: [],
    influencerCount: [],
    continentCount: [],
    regionCount: [],
    customerNameCount: [], //11
    customerAccountNumberCount: [],
    distributorsCount: [], //33
    distributorSegmentCount: [], //44
    distributorAccountNumberCount: [], //55
    
  };
  filterObjForGrid = [];
  globalFilterBehaviousSubject = new Rx.BehaviorSubject(this.filterObj);
  globalFilterSelectedFilterSubject = new Rx.BehaviorSubject(
    this.selectedFilters
  );
  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  categoryOriginal = [];
  OpCountryContiRegionOriginal = [];
  brandOriginal = [];
  engineerAndTechnicianOriginal = [];
  touchPointsOriginal = [];
  influencersOriginal = [];
  customerNameOriginal = []; //11
  customerAccountNumberOriginal = [];
  distributorsOriginal = []; //33
  distributorSegmentOriginal = []; //44
  distributorAccountNumberOriginal = []; //55
  constructor(private falconSessionStorageService: FalconSessionStorageService,
    private http: HttpClient,
    private sharedService: SharedService) {}
  /**
   * Setting mesasage
   * @param data  {type:"Success",
    data:{
    message:"Successfully Updated"
    }
  }
   */
  setToaster(data) {
    this.messageToaster$.next({ ...data });
  }
  getToaster(): Observable<any> {
    return this.messageToaster$.asObservable();
  }
  clearToaster() {
    this.messageToaster$.next(null);
  }

  /**
   * Copy to Clipboard Functionality
   * @param objGrid => Array of data passed from component with Header and Items
   */
  copy2DToClipboard(objGrid) {
    let arrHeader = objGrid.header;
    let arrItems = objGrid.items;
    const blnChangeStatus = objGrid.blnChangeStatusToActiveDeactive;
    let finalArray = [];
    let finalHeaderArray = [];
    //Making 2D array
    arrHeader.forEach((element) => {
      finalHeaderArray.push(this.capitalizeFirstLetter(element.header));
    });
    finalArray.push(finalHeaderArray);
    //Making 2D array

    arrItems.forEach((element) => {
      if (!!blnChangeStatus) {
        if ('Status' in element) {

          element.Status = element.Status === true ? 'Active' : 'Deactive';
        }
      }
      finalArray.push(Object.values(element));
    });

    if (!!finalArray) {
      // get the reference for the body
      let body = document.getElementsByTagName('body')[0];
      // creates a <table> element and a <tbody> element
      let tbl = document.createElement('table');
      let tblBody = document.createElement('tbody');

      // creating all cells
      for (let i = 0; i < finalArray.length; i++) {
        // creates a table row
        let row = document.createElement('tr');

        for (let j = 0; j < Object.keys(finalArray[i]).length; j++) {
          // Create a <td> element and a text node, make the text
          // node the contents of the <td>, and put the <td> at
          // the end of the table row
          let cell;
          let text = '';
          let cellText;

          if (i === 0) {
            cell = document.createElement('th');
            cell.style.border = '1px solid black';
            // cell.style.width = '15%';
            // cell.style.background = '#ccebf5';
            text = finalArray[i][j] !== null ? '' + finalArray[i][j] : ' ';
            cellText = document.createTextNode(text);
            cell.appendChild(cellText);
            row.appendChild(cell);
          } else {
            cell = document.createElement('td');
            cell.style.border = '1px solid black';
            // cell.style.width = '15%';

            text = finalArray[i][j] !== null ? '' + finalArray[i][j] : ' ';
            cellText = document.createTextNode(text);
            cell.appendChild(cellText);
            console.log("cellcell",cell);
            
            row.appendChild(cell);
          }
        }
        // add the row to the end of the table body
        tblBody.appendChild(row);
      }
      // put the <tbody> in the <table>
      tbl.appendChild(tblBody);
      // appends <table> into <body>
      // sets the border attribute of tbl to 2;
      tbl.setAttribute('border', '2');
      tbl.style.color = 'black';
      tbl.style.fontSize = '13px';
      tbl.style.border = '1px solid black';
      document.body.appendChild(tbl);
      try {
        this.selectNode(tbl);
        let successful = document.execCommand('copy');
        document.body.removeChild(tbl);
        return new Promise((resolve, reject) => {
          successful ? resolve(true) : reject(false);
        });
      } catch (err) {
        document.body.removeChild(tbl);
        return new Promise((resolve, reject) => {
          reject(false);
        });
      }
    }
  }
  /**
   * select created table node
   */
  selectNode(node) {
    let range = document.createRange();
    range.selectNodeContents(node);

    let select = window.getSelection();
    select.removeAllRanges();
    select.addRange(range);
  }
  /**
   * Capitalize first letter
   */
  capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  }
  /**
   * module Permission check
   * @param action 
   * @returns 
   */
  allowPermission(action: any) {
    const permissions = this.falconSessionStorageService.getSessionData('Permissions');
    return _.includes(permissions, action);
  }
  converBlobtToFile(theBlob: Blob, fileName: string, fileType: string) {
    if (navigator.appVersion.toString().indexOf('.NET') > 0)
      window.navigator.msSaveBlob(theBlob, fileName);
    else {
      const file: any = new Blob([theBlob], { type: fileType });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
  }
  datetimestamp() {
    var today = new Date();
    var sToday = (((today.getMonth() + 1) < 10 ? '0' : '') + (today.getMonth() + 1)).toString();
    sToday += ((today.getDate() < 10 ? '0' : '') + today.getDate()).toString();
    sToday += today.getFullYear().toString() + '_';
    sToday += (today.getHours() < 10 ? '0' : '') + today.getHours().toString();
    sToday += (today.getMinutes() < 10 ? '0' : '') + today.getMinutes().toString();
    return sToday;
  }
  uploadFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForDivisionMaster', formData);
  }
  getDivisionList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/DivisionMaster', formData);
  }
  addDivision(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/DivisionAddData/' + formData.DivisionID, formData);
  }
  deleteDivision(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/Division/Delete/' + formData.DivisionID);
  }
  exportDivision(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetDivisionMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadRegionFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForRegionMaster', formData);
  }
  getRegionList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/RegionMaster', formData);
  }
  addRegion(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/RegionAddData/' + formData.RegionID, formData);
  }
  deleteRegion(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/Region/Delete/' + formData.RegionID);
  }
 
  exportRegion(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetRegionMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadCountryFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForCountryMaster', formData);
  }
  getCountryList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/CountryMaster', formData);
  }
  addCountry(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/CountryAddData/' + formData.CountryID, formData);
  }
  deleteCountry(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/Country/Delete/' + formData.CountryID);
  }
  exportCountry(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetCountryMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadBranchFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForBranchMaster', formData);
  }
  getBranchList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/BranchMaster', formData);
  }
  addBranch(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/BranchMasterAddData/' + formData.BranchID, formData);
  }
  deleteBranch(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/Branch/Delete/' + formData.BranchID);
  }
  
  exportBranch(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetBranchMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadBusinessAreaFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForBusinessAreaMaster', formData);
  }
  getBusinessAreaList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/BusinessAreaMaster', formData);
  }
  addBusinessArea(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/BusinessAreaAddData/' + formData.BAID, formData);
  }
  deleteBusinessArea(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/BusinessArea/Delete/' + formData.BAID);
  }
  
  exportBusinessArea(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetBusinessAreaMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  downloadTemplate(filepath) {
    let serviceUrl = this.sharedService.configuration.baseUrl + "api/MDM/DownloadTemplateFileFromBlob/" + filepath; 
    return this.http.get(serviceUrl, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  uploadBusinessLineFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForBusinessLineMaster', formData);
  }
  getBusinessLineList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/BusinessLineMaster', formData);
  }
  addBusinessLine(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/BusinessLineAddData/' + formData.BLID, formData);
  }
  deleteBusinessLine(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/BusinessLine/Delete/' + formData.BLID);
  }
  exportBusinessLineData() {
    return this.http.post(this.serviceUrl + 'api/MDM/GetBusinessLineMasterForExport', null, { responseType: 'blob' });
  }

  exportBusinessLine(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetBusinessLineMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadInfluencelevelFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForInfluencelevelMaster', formData);
  }
  getInfluencelevelList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/InfluencelevelMaster', formData);
  }
  addInfluencelevel(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/InfluencelevelAddData/' + formData.InfluencelevelId, formData);
  }
  deleteInfluencelevel(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/Influencelevel/Delete/' + formData.InfluencelevelId);
  }
  exportInfluencelevelData() {
    return this.http.post(this.serviceUrl + 'api/MDM/GetInfluencelevelMasterForExport', null, { responseType: 'blob' });
  }
  exportInfluencelevel(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetInfluencelevelMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadLanguageFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForLanguageMaster', formData);
  }
  getLanguageList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/LanguageMaster', formData);
  }
  addLanguage(formData) {
    console.log('formdata', formData.LangID, formData);
    return this.http.post(this.serviceUrl + 'api/MDM/LanguageAddData/' + formData.LangID, formData);
  }
  deleteLanguage(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/Language/Delete/' + formData.LangID);
  }
  exportLanguageData() {
    return this.http.post(this.serviceUrl + 'api/MDM/GetLanguageMasterForExport', null, { responseType: 'blob' });
  }
  exportLanguage(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetLanguageMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }

  getLanguageByFAM(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/LanguageMaster', formData);
  }
  uploadSalesChannelFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForSalesChannelMaster', formData);
  }
  getSalesChannelList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/SalesChannelMaster', formData);
  }
  addSalesChannel(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/SalesChannelAddData/' + formData.SalesChannelID, formData);
  }
  deleteSalesChannel(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/SalesChannel/Delete/' + formData.SalesChannelID);
  }
  exportSalesChannel(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetSalesChannelMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadServiceTypeFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForServiceTypeMaster', formData);
  }
  getServiceTypeList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/ServiceTypeMaster', formData);
  }
  addServiceType(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/ServiceTypeAddData/' + formData.ServiceTypeID, formData);
  }
  deleteServiceType(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/ServiceType/Delete/' + formData.ServiceTypeID);
  }
 exportServiceType(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetServiceTypeMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadTimeZoneFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForTimeZoneMaster', formData);
  }
  getTimeZoneList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/TimeZoneMaster', formData);
  }
  addTimeZone(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/TimeZoneAddData/' + formData.TimeZoneId, formData);
  }
  deleteTimeZone(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/TimeZone/Delete/' + formData.TimeZoneId);
  }
 exportTimeZone(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetTimeZoneMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadTouchPointFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForTouchPointMaster', formData);
  }
  uploadTouchPointImage(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/TouchPointAddData/TOUCHPOINTIMG?fname=fname', formData);
    
  }
  getTouchPointList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/TouchPointMaster', formData);
  }
  addTouchPoint(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/TouchPointAddData/' + formData.TouchPointId, formData);
  }
  deleteTouchPoint(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/TouchPoint/Delete/' + formData.TouchPointId);
  }
  exportTouchPoint(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetTouchPointMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadZoneFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForZoneMaster', formData);
  }
  getZoneList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/ZoneMaster', formData);
  }
  addZone(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/ZoneAddData/' + formData.ZoneMasterID, formData);
  }
  deleteZone(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/Zone/Delete/' + formData.ZoneMasterID);
  }
 exportZone(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetZoneMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  getFAMList(params) {
    return this.http.get(this.serviceUrl + 'api/MDM/UserManagement/Users/MasterData?buster=' + new Date().getTime());
  }

  getSalutationFAMList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/FAMMasterForSalutation/DropDownFAMSalutation', formData);
  }
  
  getCurrencyList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/CurrencyMaster', formData);
  }
  getContinentList() {
    return this.http.get(this.serviceUrl + 'api/MDM/ContinentMaster');
  }
  uploadInfluencelevelMappingFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForInfluencelevelMappingMaster', formData);
  }
  getInfluencelevelMappingList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/InfluencelevelMappingMaster', formData);
  }
  addInfluencelevelMapping(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/InfluencelevelMappingAddData/' + formData.InfluencelevelMappingId, formData);
  }
  deleteInfluencelevelMapping(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/InfluencelevelMapping/Delete/' + formData.InfluencelevelMappingId);
  }
  exportInfluencelevelMappingData() {
    return this.http.post(this.serviceUrl + 'api/MDM/GetInfluencelevelMappingMasterForExport', null, { responseType: 'blob' });
  }
  exportInfluencelevelMapping(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetInfluencelevelMappingMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadSalutationFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForSalutationMaster', formData);
  }
  getSalutationList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/SalutationMaster', formData);
  }
  addSalutation(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/SalutationMasterAddData/' + formData[0].SalutationID + '/' + formData[0].DivFlag + '/' + formData[0].BrandFlag, formData);
  }
  deleteSalutation(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/Salutation/Delete/' + formData.SalutationID);
  }
 
  exportSalutation(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetSalutationMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  getBrandList(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/BrandData?pageSize=' + formData.pageSize + '&pageNo=' + formData.pageNo + '&sortField=&sortType=');
  }
  getMasterSubSectorList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetMarketSubSectorMasterDetails', formData);
  }
  uploadMarketSubsectorFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForMarketSubSectorMaster', formData);
  }
  getMarketSubsectorList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetMarketSubSectorMasterDetails', formData);
  }
  addMarketSubsector(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/MarketSubSectorAddData/' + formData.MarketSubSectorID, formData);
  }
  deleteMarketSubsector(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/MarketSubSector/Delete/' + formData.MarketSubSectorID);
  }
  exportMarketSubsector(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetMarketSubSectorMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  getFAMBrandBAList() {
    return this.http.get(this.serviceUrl + 'api/Dashboard/GetMasterFilterDataForMarketSubsector');
  }
  uploadSalutationGenderMappingFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForGenderMaster', formData);
  }
  getSalutationGenderMappingList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/SalutationGenderMaster', formData);
  }
  addSalutationGenderMapping(formData, formValue) {
    return this.http.post(this.serviceUrl + 'api/MDM/SalutationGenderMasterAddData/' + formValue.GenderID, formData);
  }
  deleteSalutationGenderMapping(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/Gender/Delete/' + formData.GenderId);
  }
  exportSalutationGenderMappingData() {
    return this.http.post(this.serviceUrl + 'api/MDM/GetSalutationGenderMasterForExport', null, { responseType: 'blob' });
  }
  exportSalutationGenderMapping(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetSalutationGenderMasterForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
  uploadSalesChannelMappingFile(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForSalesChannelMapping', formData);
  }
  getSalesChannelMappingList(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/SalesChannelMapping', formData);
  }
  addSalesChannelMapping(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/SalesChannelMappingAddData/' + formData.ScmsMappingId, formData);
  }
  deleteSalesChannelMapping(formData) {
    return this.http.get(this.serviceUrl + 'api/MDM/SalesChannelMapping/Delete/' + formData.ScmsMappingId);
  }
  exportSalesChannelMapping(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetSalesChannelMappingForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }

  getGetLanguageByFAMId(famId) {
    console.log('famId',famId);
    return this.http.get(this.serviceUrl + 'api/MDM/LanguageByFAM/'+ famId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        console.log('LanguageByFAM data',data);
        return data;
      });


    
  }
  getMasterDataForSalutation() {
    return this.http.get(this.serviceUrl + 'api/Dashboard/GetMasterFilterDataForSalutation');
  }
  generateRandomId() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  setErrorMessage(error: any) {
    if (error.Code && error.ErrorType) {
      if(error.Code === 'E10380' && error.ErrorType === 'Business') {
        if (error.Message === 'AllReady_Used') {
          return 'This item is already in use';
        }
      }
    }
    return error.Message;
  }

  getPostcodeList(formData, mode?) {
    if(!mode) {
      mode ='M'
    }
    return this.http.post(this.serviceUrl + `api/MDM/PostCodeMaster?mode=${mode}`, formData);
  }

  getGroupedPostcodeList(formData,mode?) {
    if(!mode) {
      mode ='M'
    }
    return this.http.post(this.serviceUrl + `api/MDM/GroupNameMaster?mode=${mode}`, formData);
  }

  createOrMoveGroup(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/SaveorMoveGroupData', formData);
  }

  removePostCode(selectedPostcodes) {
    return this.http.post(this.serviceUrl + 'api/MDM/DeleteStatefromGroups/Delete', selectedPostcodes);
  }

  saveStateName(formData, type) {
    if (type === 'group') {
      return this.http.post(this.serviceUrl + 'api/MDM/SaveStateinGroupData/' + formData.GroupId, formData);
    } else {
      return this.http.post(this.serviceUrl + 'api/MDM/SaveStateNameData/' + formData.PostCodeId, formData);
    }
  }

  saveGroupName(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/EditGroupnameData', formData, formData.oldGroupName);
  }

  editRegion(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/EditRegion/'+ formData.RegionId, formData);
  }

  saveUserFilter(filterObject, isDate: boolean) {
    return this.http
      .post(
        this.serviceUrl + `api/BusinessFilters/SaveUserFilter`,
        filterObject
      )
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  clearUserFilter(surveySubCategoryCode, screenCode) {
    return this.http
      .post(
        this.serviceUrl +
        `api/BusinessFilters/ClearUserFilter?surveySubCategoryCode=${surveySubCategoryCode}&screenCode=${screenCode}`,
        null
      )
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  setUserFilterData(res, filterObj) {
    this.mapFilterObject(res, true, filterObj);
    sessionStorage.setItem(
      //not needed to store
      filterObj.sessionStorageName,
      JSON.stringify(res)
    );
    // this.globalFilterBehaviousSubject.next(this.filterObj);
  }
  mapFilterObject(obj, includeDateFields, filterObj) {
    if (includeDateFields && !!obj) {
      filterObj.StartDate = obj.StartDate == null ? '' : obj.StartDate;
      filterObj.EndDate = obj.EndDate == null ? '' : obj.EndDate;
      filterObj.PeriodType = obj.PeriodType == null ? '' : obj.PeriodType;

      var dateObject = {
        StartDate: obj.StartDate == null ? '' : obj.StartDate,
        EndDate: obj.EndDate == null ? '' : obj.EndDate,
        PeriodType: obj.PeriodType == null ? '' : obj.PeriodType,
      };
      /* sessionStorage.setItem(
        filterObj.sessionStorageName,
        JSON.stringify(dateObject)
      ); */
    }
    if (!!obj) {
      filterObj.TrendAnalysis = obj.TrendAnalysis == null ? '' : obj.TrendAnalysis;
      filterObj.BACode = obj.BACode == null ? '' : obj.BACode;
      filterObj.FAMCode = obj.FAMCode == null ? '' : obj.FAMCode;
      filterObj.DivCode = obj.DivCode == null ? '' : obj.DivCode;
      filterObj.BLCode = obj.BLCode == null ? '' : obj.BLCode;
      filterObj.Country = obj.Country == null ? '' : obj.Country;
      filterObj.Region = obj.Region == null ? '' : obj.Region;
      filterObj.Continent = obj.Continent == null ? '' : obj.Continent;
      filterObj.OperationalCountry = obj.OperationalCountry == null ? '' : obj.OperationalCountry;
      filterObj.Category = obj.Category == null ? '' : obj.Category;
      filterObj.BrandName = obj.BrandName == null ? '' : obj.BrandName;
      filterObj.Zone = obj.Zone == null ? '' : obj.Zone;
      filterObj.Branch = obj.Branch == null ? '' : obj.Branch;
      filterObj.Influencer = obj.Influencer == null ? '' : obj.Influencer;
      filterObj.PinnedField = obj.PinnedField == null ? '' : obj.PinnedField;
      filterObj.CustomerNameCode =
        obj.CustomerName == null ? '' : obj.CustomerName;
      filterObj.CustomerAccountNumberCode =
        obj.CustomerAccountNumber == null ? '' : obj.CustomerAccountNumber;
      filterObj.Distributors =
        obj.Distributors == null ? '' : obj.Distributors;
      filterObj.DistributorAccountNumber =
        obj.DistributorAccountNumber == null ? '' : obj.DistributorAccountNumber;
    }
    return filterObj;
  }

  mappingFromFilterDataToObj(filterObj, filterData, haveDate = false) {
    if (!haveDate) {
      filterObj.filterFields.bAIDs = filterData.BACode
        ? filterData.BACode.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.branchIDs = filterData.Branch
        ? filterData.Branch.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.brandIDs = filterData.BrandName
        ? filterData.BrandName.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.contientIDs = filterData.Continent
        ? filterData.Continent.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.countryIDs = filterData.Country
        ? filterData.Country.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.customerAccountNumbers = filterData.CustomerAccountNumber
        ? filterData.CustomerAccountNumber.split('|').map((x) => x)
        : [];
      filterObj.filterFields.customerNames = filterData.CustomerName
        ? filterData.CustomerName.split('|').map((x) => x)
        : [];
      filterObj.filterFields.dIVIDs = filterData.DivCode
        ? filterData.DivCode.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.distributorAccountNumber = filterData.DistributorAccountNumber
        ? filterData.DistributorAccountNumber.split('|').map((x) => x)
        : [];
      filterObj.filterFields.distributorSegment = filterData.DistributorSegment
        ? filterData.DistributorSegment.split('|').map((x) => x)
        : [];
      filterObj.filterFields.distributors = filterData.Distributors
        ? filterData.Distributors.split('|').map((x) => x)
        : [];
      filterObj.filterFields.fAMIDs = filterData.FAMCode
        ? filterData.FAMCode.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.influencers = filterData.Influencer
        ? filterData.Influencer.split('|').map((x) => x)
        : [];
      if (filterData.PeriodType || filterData.StartDate || filterData.EndDate) {
        filterObj.filterFields.periodType = filterData.PeriodType
          ? filterData.PeriodType
          : '';
        filterObj.filterFields.fromDate = filterData.StartDate
          ? filterData.StartDate
          : '';
        filterObj.filterFields.toDate = filterData.EndDate
          ? filterData.EndDate
          : '';
      }
      filterObj.filterFields.regionIDs = filterData.Region
        ? filterData.Region.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.salesEngineers = filterData.SalesEngineer
        ? filterData.SalesEngineer.split('|').map((x) => x)
        : [];
      filterObj.filterFields.serviceTechnicians = filterData.ServiceTechnician
        ? filterData.ServiceTechnician.split('|').map((x) => x)
        : [];
      filterObj.filterFields.touchPointIDs = filterData.Touchpoint
        ? filterData.Touchpoint.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.zoneIDs = filterData.Zone
        ? filterData.Zone.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.CompanyName = filterData.CompanyName
        ? filterData.Zone.CompanyName('|')
        : '';
      filterObj.filterFields.CompanyAccountNumber = filterData.CompanyAccountNumber
        ? filterData.CompanyAccountNumber.split('|')
        : '';
      filterObj.filterFields.RecievedByIDs = filterData.RecievedByIDs
        ? filterData.RecievedByIDs.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.AssigneeIDs = filterData.AssigneeIDs
        ? filterData.AssigneeIDs.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.VocTypeIDs = filterData.VocTypeIDs
        ? filterData.VocTypeIDs.split('|').map((x) => +x)
        : [];
    } else if (haveDate) {
      if (filterData.PeriodType || filterData.StartDate || filterData.EndDate) {
        filterObj.filterFields.periodType = filterData.PeriodType
          ? filterData.PeriodType
          : '';
        filterObj.filterFields.fromDate = filterData.StartDate
          ? filterData.StartDate
          : '';
        filterObj.filterFields.toDate = filterData.EndDate
          ? filterData.EndDate
          : '';
      }
    }
    return filterObj;
  }
  public updateSelectedFilter = (selectedFilters: any) => {
    this.globalFilterSelectedFilterSubject.next(
      JSON.parse(JSON.stringify(selectedFilters))
    );
  }
  getMasterData(): Observable<boolean> {
    let masterData: any = JSON.parse(sessionStorage.getItem('MasterData'));
    if (masterData && masterData.FamBADivBLFilterModel && masterData.FamBADivBLFilterModel.length &&
      masterData.FamCountryContiRegionFilterModel && masterData.FamCountryContiRegionFilterModel.length &&
      masterData.FamBABrandFilterModel && masterData.FamBABrandFilterModel.length &&
      masterData.FamBADivZoneBranchFilterModel && masterData.FamBADivZoneBranchFilterModel.length) {
      return new Observable((obs) => {
        obs.next({ ...masterData });
      });
    } else {
      return this.http.get<any>(this.serviceUrl + 'api/BusinessFilters/GetMasterFilter');
    }
  }
  uploadFileGroupMaster(formData) {
    return this.http.post(this.serviceUrl + 'api/MDM/UploadFileForProcessingForGroupNameMaster', formData);
  }
  groupNameExport(reqData) {
    return this.http.post(this.serviceUrl + 'api/MDM/GetGroupNameForExport', reqData, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }

  getUserFilter(surveySubCategoryCode, screenCode): Observable<any> {
    return this.http.get<any>(
      this.serviceUrl +
      `api/BusinessFilters/GetSavedUserFilter?surveySubCategoryCode=${surveySubCategoryCode}&screenCode=${screenCode}`
    );
  }
  getRegionDetailsForPostCode(reqData){
    return this.http.post(this.serviceUrl + 'api/MDM/RegionDetailsForPostCode',reqData);
  }
}
