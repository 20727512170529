import { Component, OnInit, Input, Output, EventEmitter,OnChanges, DoCheck } from '@angular/core';
import {MessageService} from 'primeng/api';
import { TemplateQuestionsService } from "../../services/template-questions.service";
@Component({
  selector: 'app-multi-choice',
  templateUrl: './multi-choice.component.html',
  styleUrls: ['./multi-choice.component.scss']
})
export class MultiChoiceComponent implements OnInit {
  @Input()
  data: any = {};
  editable: any;
  capturedData:any={};
  constructor(private messageService: MessageService, private _templateQuestionService : TemplateQuestionsService) {
    
  }

  ngOnInit() {
    this.capturedData=JSON.parse(JSON.stringify(this.data));
  }

  ngOnChanges() {
    this.capturedData=JSON.parse(JSON.stringify(this.data));
  }
  save(capturedData) {
    
  }
}
