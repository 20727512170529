import { Component, OnInit,ViewChild,Output, Input, OnChanges, EventEmitter } from '@angular/core';
import { NpsResponselist } from '../model/nps-responselist.model';
import {NpsResponselistService} from '../service/nps-responselist.service';
import { Paginator } from 'primeng/paginator';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from 'src/app/shared/services/filter.service';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { DashboardWidgetService } from '../service/dashboard-widget.service';
import { SurveySubCategory } from 'src/app/shared/constants/survey.sub.category';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-total-response',
  templateUrl: './total-response.component.html',
  styleUrls: ['./total-response.component.scss']
})
export class TotalResponseComponent implements OnInit {
  private imagepath = environment.imagepath;
  constructor(private dashboardWidgetService: DashboardWidgetService) {}

  showDiv: boolean=true;
  showDetails:boolean=false

  @Input() surveySubCategory = SurveySubCategory.Transactional
  
  @Output()
  totalResponseChange = new EventEmitter();
  touchPointData: any = null;
  selectedTp : string = "";
  selected : number = 0;
  tpData = {};
  categoryData: any;
  boolInvitation : string= "none";
  boolInvitationTp  : string= "none";
  boolInvitationCat : string= "none";
  chartData = [];
  catData = [];
  tpNoData : boolean = false;
  tpErrorData :boolean = false;
  dataByTp : boolean = true;
  dataByCat : boolean = false;
  count : number = 0;
  totalRecords : number = 0;
  totalObj : {};
  catObj : {};
  catArr :number[] = [] 
  allSelected : boolean = false;
  totalArr : number[] = [];
  width:any= [];
  Sortedwidth:any=[];
  dropdpwnCls : string = "";
  catErrorData : boolean = false;
  catNoData : boolean = false;
  showParentCatNoData : boolean = false;
  showParentCatErrorData : boolean = false;
  showParentTpNoData : boolean = false;
  showParentTpErrorData : boolean = false;
  selectedCategory = 0;
  firstTp = 0;
  YesWidth:number;
  NoWidth:number;
  NeedsImprovementwidth:number;
  NotApplicablewidth:number;
  NoData:boolean=false;
  touchpointNoData:boolean=false;
  yes:number;
  no:number;
  needImprovement:number;
  notApplicable:number;
  yesLegend:boolean;
  selectFirstRow: number = 0;
  configRow: number[] = [12, 20, 30, 40, 50];
  totalRecordsForPagination:number = 0
  pageNo: number = 1;
  pageSize: number = 5;
  selectedTouchPointId = 0;
  questionGraphLoader: boolean = false;
  showCatgoryData : boolean=false;

  //Pagignation issue
  //JIRA ID- 356
  paginatorFirstSelector: any;
  paginatorLaststSelector: any;
  paginatorNextSelector: any;
  paginatorPrevSelector: any;

  ngOnInit() {
    this.showDetails = false;
    this.boolInvitation = "block";
    this.dataByTp = false;
    this.dataByCat = true;
    this.dropdpwnCls = "selectedDropdown";
    this.getQuestionByCategory();
    this.getQuestionByTouchPoint();
  }
  closeDiv(){
    this.showDiv = false;
    this.totalResponseChange.emit({hideTotalArrow : true});
  }
  toggleCatandTp(filter){
    this.totalRecordsForPagination = 0;    
    //this.selectedCategory = 0
    if(filter == "cat"){
      this.dataByTp = false;
      this.dataByCat = true;
      if(!this.showParentCatNoData && !this.showParentCatErrorData){
        this.dropdpwnCls = "selectedDropdown";
        this.getQuestionByCatId(this.selectedCategory);
      }
      
    }else if(filter == "tp"){
      this.pageNo = 1;      
      this.totalRecordsForPagination = 0;
      this.dataByTp = true;
      this.dataByCat = false;
      this.dropdpwnCls = "";
      if(!this.showParentTpNoData && !this.showParentTpErrorData){
        this.getQuestionsByTP(this.firstTp);
      }  
    }
  }
  getQuestionByCategory(){
    this.showParentCatNoData = false;
    this.showParentCatErrorData = false;
    this.dashboardWidgetService.getQuestionByCategory().then(res => {
      //res = {"Status":"Success","Response":{"PageCount":0,"PageSize":0,"TotalRecords":4,"CurrentPageNo":0,"Collection":[{"QuestionResponseCategoryId":1,"CategoryName":"Ease of Doing Business"},{"QuestionResponseCategoryId":2,"CategoryName":"Communication"},{"QuestionResponseCategoryId":4,"CategoryName":"Lead Time"},{"QuestionResponseCategoryId":6,"CategoryName":"Quality"}]},"Errors":[]}
      //res = {"Status":"Success","Response":null,"Errors":[]}

      this.count++;
      if(this.count == 2){
        this.showDetails = true;
      }
      if(res.Status == "Success" && res.Response && res.Response != null){
          this.catData = [];
          this.catData.push({ label: "All", value:0 })
          res.Response.Collection.forEach(ele => {
            this.catData.push({ label: ele.CategoryName, value:ele.QuestionResponseCategoryId })
          });
          if(this.dataByCat){
            if(res.Response && res.Response.Collection && res.Response.Collection.length > 0){
              this.getQuestionByCatId(0);
            }
          }
      }else if(res.Status == "Success" && res.Response == null){  
          this.showParentCatNoData = true;
      }else if(res.Status == "Failure"){    
          this.showParentCatErrorData = true;
      }
    }).catch(err => {
      this.showParentCatErrorData = true;
    }); 
  } 
  getQuestionByCatId(id){
    this.categoryData = {};
    this.boolInvitationCat = "block";
    this.dashboardWidgetService.getQuestionByCatId(id).then(res => {
      this.boolInvitationCat = "none";
      this.totalObj = {};
      this.catObj = {};
      this.catArr = [];

      if(res.Status == "Success" && res.Response && res.Response.Collection != null){
        this.categoryData = res.Response;   
        res.Response.Collection.forEach(ol =>{          
          if(ol.AnsweredCnt <=0 )
            {
              ol.showCatgoryData=true;
              ol.NoData = true;
             // this.catNoData=true;            
          }
          else{   
            ol.NoData = false;
             this.catNoData=false;
            ol.showCatgoryData=false;
            ol.showAnsCount =false;          
           this.yes = parseInt((ol.OptionDetailTotal.find(function(i) { if(i.OptionAns == 'Yes'){ return i;} })).ValuePerc.toFixed(2));
          this.no = parseInt((ol.OptionDetailTotal.find(function(i) { if(i.OptionAns == 'No'){ return i;} })).ValuePerc.toFixed(2));
          this.needImprovement = parseInt((ol.OptionDetailTotal.find(function(i) { if(i.OptionAns == 'Needs Improvement'){ return i;} })).ValuePerc.toFixed(2));
          this.notApplicable = parseInt((ol.OptionDetailTotal.find(function(i) { if(i.OptionAns == 'Not Applicable'){ return i;} })).ValuePerc.toFixed(2));
          
          this.width=[this.yes,this.no,this.needImprovement,this.notApplicable];
          var total = (this.yes + this.no + this.needImprovement + this.notApplicable);
          if( total > 100){
            var finalTotal = ( total - 100 );
          }
          else{
            var finalTotal = 0;
          }         
          const sortdsc = (a, b) => a - b;
           this.Sortedwidth =this.width.sort(sortdsc);
           var maxWidth= this.Sortedwidth[3];
            ol.OptionDetailTotal.forEach(oa => {           
            if(oa.OptionAns == 'Yes' && oa.ValuePerc!=null) {
              var YesValue =parseInt(oa.ValuePerc.toFixed(2));
              if(maxWidth == YesValue)
              {
                oa.YesWidth = ( YesValue - finalTotal );
              }
              else{
                oa.YesWidth = YesValue;              
              } 
              //decrease the width of bar by 20% to resolve the drilled down issue on story board
              const newwidth = (oa.YesWidth / 100) * 20;
              oa.YesWidth = oa.YesWidth - parseInt(newwidth.toFixed(2));
                              
              oa.yesfinal = oa.YesWidth; 
              oa.showYesFinal = true;
            }
            else if(oa.OptionAns == 'Not Applicable' && oa.ValuePerc!=null ) {
              var NotValue = parseInt(oa.ValuePerc.toFixed(2));
              if (maxWidth == NotValue) {                
                oa.NotApplicablewidth =  ( NotValue - finalTotal );
              }else{
                oa.NotApplicablewidth = NotValue;
              }

               //decrease the width of bar by 20% to resolve the drilled down issue on story board
               const notApplicablewidth = (oa.NotApplicablewidth / 100) * 20;
               oa.NotApplicablewidth = oa.NotApplicablewidth - parseInt(notApplicablewidth.toFixed(2));

             oa.notApplicableFinal = oa.NotApplicablewidth;
             oa.showNotApplicableFinal = true;
          }
          else if(oa.OptionAns == 'No' && oa.ValuePerc!=null) {
            var NoValue = parseInt(oa.ValuePerc.toFixed(2));
            if (maxWidth == NoValue) {
              oa.NoWidth = ( NoValue - finalTotal );
            }else{
              oa.NoWidth = NoValue;
            }
              //decrease the width of bar by 20% to resolve the drilled down issue on story board
              const nowidth = (oa.NoWidth / 100) * 20;
              oa.NoWidth = oa.NoWidth - parseInt(nowidth.toFixed(2));

            oa.noFinal = oa.NoWidth;
            oa.showNoFinal = true;           
          } 
          else if(oa.OptionAns == 'Needs Improvement' && oa.ValuePerc!=null) {
            var NeedsValue = parseInt(oa.ValuePerc.toFixed(2));
            if (maxWidth == NeedsValue) {
              oa.NeedsImprovementwidth = ( NeedsValue - finalTotal );
            }else{
              oa.NeedsImprovementwidth = NeedsValue;
            }
            
            //decrease the width of bar by 20% to resolve the drilled down issue on story board
            const needsImprovementwidth = (oa.NeedsImprovementwidth / 100) * 20;
            oa.NeedsImprovementwidth = oa.NeedsImprovementwidth - parseInt(needsImprovementwidth.toFixed(2));

            oa.needsImprovementFinal = oa.NeedsImprovementwidth;        
            oa.showNeedsImprovementFinal = true;        
          } 
        })
      
        }} )  
        if(id == 0){
          this.allSelected = true;
          for(var o=0; o<res.Response.Collection.length; o++){
            this.totalObj = {};
            this.calculateTotal(res.Response.Collection[o].TouchPointDetail,res.Response.Collection[o].CategoryName);
            
          }  
        }else{
          this.allSelected = false;
          this.calculateTotal(res.Response.Collection[0].TouchPointDetail,res.Response.Collection[0].CategoryName)
        }
      }else if(res.Status == "Success" && res.Response == null){
        this.catNoData = true;
      }else if(res.Status == "Failure"){  
        this.catErrorData = true;
      } 
    }).catch(err => {
      this.boolInvitationCat = "none";
      this.catErrorData = true;
    })  
  }

 
  calculateTotal(data, category){
    this.totalObj = {};
    // console.log(data.OptionDetail.value);
    for(var m=0; m<data.length; m++){
      for(var i=0; i<data[m].OptionDetail.length; i++){
        let temp = data[m].OptionDetail[i];
        let tempValPerc = temp.ValuePerc;
        if  (tempValPerc!=null) {
        tempValPerc = tempValPerc.toFixed(2);
        }
        else  {
        tempValPerc = 0;
        }
        if (this.totalObj.hasOwnProperty(temp.OptionAns)) {
         
          let afterAdd = (parseFloat(this.totalObj[temp.OptionAns]) +  parseFloat(tempValPerc)).toFixed(2);
          this.totalObj[temp.OptionAns] = afterAdd;
        }else{
          this.totalObj[temp.OptionAns] = parseFloat(tempValPerc);
        }      
      }
    }
    this.catObj[category] = this.totalObj;
  }
  onChange(event: any){
    this.totalRecordsForPagination = 0;
    this.dataByTp = false;
    this.dataByCat = true;
    this.getQuestionByCatId(this.selectedCategory)
  }
  getQuestionByTouchPoint(){
    this.showParentTpNoData = false;
    this.showParentTpErrorData = false;
    this.dashboardWidgetService.getQuestionByTouchPoint().then(res => {
      //res = {"Status":"Success","Response":{"PageCount":1,"PageSize":10,"TotalRecords":3,"CurrentPageNo":1,"Collection":[{"TouchPointID":3,"TouchPointDescription":"Planned Maintenance","TotalCount":1},{"TouchPointID":6,"TouchPointDescription":"Sales","TotalCount":45},{"TouchPointID":7,"TouchPointDescription":"Service Repair","TotalCount":1}]},"Errors":[]}
      //res = {"Status":"Failure","Response":null,"Errors":[]}
      this.count++;
      if(this.count == 2){
        this.showDetails = true;
      }
      this.selected = 0;
      this.boolInvitation = "none";
      this.totalRecords = 0;
        if(res.Status == "Success" && res.Response && res.Response != null && res.Response.Collection !=null && res.Response.Collection.length > 0){
          this.touchPointData = res.Response;
          
          this.firstTp = res.Response.Collection[0].TouchPointID;
          
          if(this.dataByTp){
            if(res.Response && res.Response.Collection && res.Response.Collection.length > 0){
              this.getQuestionsByTP(res.Response.Collection[0].TouchPointID);
            }
          } 
          for(var t=0; t<res.Response.Collection.length; t++){
            this.totalRecords = this.totalRecords + res.Response.Collection[t].TotalCount;
          }

        }else  if(res.Status == "Success" && res.Response && res.Response == null && res.Response.Collection.length==0){
          this.showParentTpNoData = true;
        } else  if(res.Status == "Failure"){
          this.showParentTpErrorData = true;
        }  
    }).catch(err => {
      this.showParentTpErrorData = true;
    }); 
  } 
  getQuestionsByTP(id){
        this.boolInvitationTp = "block";
        this.tpNoData = false;
        this.tpErrorData = false;
        this.tpData = {};
      this.dashboardWidgetService.getQuestionsByTP(id, this.pageNo, this.pageSize).then(res => {
        this.boolInvitationTp = "none";
        if(res.Status == "Success" && res.Response && res.Response != null && res.Response.Collection !=null){
          this.tpData = res.Response;
          //console.log(this.tpData,"tpData"); 
          res.Response.Collection.forEach(ol =>{  
            if (ol.AnsweredCnt <=0){
                ol.showTouchPointData=true;
                this.touchpointNoData=true;
            }
            else{
              ol.showTouchPointData=false;
              this.touchpointNoData=false;
              this.tpNoData = false;
            try {
              this.yes = parseInt((ol.OptionDetail.find(function(i) { if(i.OptionAns == 'Yes'){ return i;} })).ValuePerc.toFixed(2));
            }
            catch(err) {
              this.yes = 0;
            }

            try {
              this.no = parseInt((ol.OptionDetail.find(function(i) { if(i.OptionAns == 'No'){ return i;} })).ValuePerc.toFixed(2));
            }
            catch(err) {
              this.no = 0;
            }

            try {
              this.needImprovement = parseInt((ol.OptionDetail.find(function(i) { if(i.OptionAns == 'Needs improvement'){ return i;} })).ValuePerc.toFixed(2));
            }
            catch(err) {
              this.needImprovement = 0;
            }

            try {
              this.notApplicable = parseInt((ol.OptionDetail.find(function(i) { if(i.OptionAns == 'Not applicable'){ return i;} })).ValuePerc.toFixed(2));
            }
            catch(err) {
              this.notApplicable =0;
            }

            var total = (this.yes + this.no + this.needImprovement + this.notApplicable);
            if( total >= 100){
              var finalTotal = ( total - 100 );
            }
            else{
              var finalTotal = 0;
            } 
            this.width=[this.yes,this.no,this.needImprovement,this.notApplicable];
             const sortdsc = (a, b) => a - b;
             this.Sortedwidth =this.width.sort(sortdsc);
             var maxWidth= this.Sortedwidth[3];
              ol.OptionDetail.forEach(oa => {     
              if(oa.OptionAns == 'Yes' && oa.ValuePerc!=null) {            
                var YesValue =parseInt(oa.ValuePerc.toFixed(2));
                if(maxWidth == YesValue)
                {
                  // var YesValue =parseInt(oa.ValuePerc.toFixed());
                  oa.YesWidth= ( YesValue- finalTotal );
                }
                else{
                  oa.YesWidth = YesValue;              
                } 

                //decrease the width of bar by 20% to resolve the drilled down issue on story board
                const newwidth = (oa.YesWidth / 100) * 20;
                oa.YesWidth = oa.YesWidth - parseInt(newwidth.toFixed(2));

                this.yesLegend=false;
                oa.yesfinal = oa.YesWidth; 
                         
              }
              else if(oa.OptionAns == 'Not applicable' && oa.ValuePerc!=null ) {
                var NotValue = parseInt(oa.ValuePerc.toFixed(2));
                if (maxWidth == NotValue) {
                  oa.NotApplicablewidth = ( NotValue - finalTotal );
                }else{
                  oa.NotApplicablewidth = NotValue;
                }

                //decrease the width of bar by 20% to resolve the drilled down issue on story board
                const notApplicable = (oa.NotApplicablewidth / 100) * 20;
                oa.NotApplicablewidth = oa.NotApplicablewidth - parseInt(notApplicable.toFixed(2));

                oa.notApplicableFinal = oa.NotApplicablewidth;
            }
            else if(oa.OptionAns == 'No' && oa.ValuePerc!=null) {
              // this.NoWidth = oa.ValuePerc.toFixed();
               var NoValue = parseInt(oa.ValuePerc.toFixed(2));
              if (maxWidth == NoValue) {
                  oa.NoWidth = ( NoValue - finalTotal ) ;
              }else{
                oa.NoWidth = NoValue;
              }
              
              //decrease the width of bar by 20% to resolve the drilled down issue on story board
              const noWidth = (oa.NoWidth / 100) * 20;
              oa.NoWidth = oa.NoWidth - parseInt(noWidth.toFixed(2));
                              
              oa.noFinal = oa.NoWidth;
             
            } 
            else if(oa.OptionAns == 'Needs improvement' && oa.ValuePerc!=null) {
              // this.NeedsImprovementwidth = oa.ValuePerc.toFixed();
              var NeedsValue = parseInt(oa.ValuePerc.toFixed(2));
              if (maxWidth == NeedsValue) {
                oa.NeedsImprovementwidth = ( NeedsValue - finalTotal );
              }else{
                oa.NeedsImprovementwidth = NeedsValue;
              }  

              //decrease the width of bar by 20% to resolve the drilled down issue on story board
              const needsImprovement = (oa.NeedsImprovementwidth / 100) * 20;
              oa.NeedsImprovementwidth = oa.NeedsImprovementwidth - parseInt(needsImprovement.toFixed(2));

              oa.needsImprovementFinal = oa.NeedsImprovementwidth;        
            } 
  
          })
        }
        } ) 
          this.totalRecordsForPagination = res.Response.TotalRecords;
          this.pageNo = res.Response.CurrentPageNo
          this.pageSize = res.Response.PageSize
        
          setTimeout(() => {
            this.PageToolTip();
          }, 500);  

        }else if(res.Status == "Success" && res.Response == null){
          this.tpNoData = true;
        }else if(res.Status == "Failure"){
          this.tpErrorData = true;
        }
      }).catch(err => {
        this.tpErrorData = true;
        this.boolInvitationTp = "none";
    }); 
  }
  loadData(id, selected){
    this.totalRecordsForPagination = 0;
    this.pageNo = 1;
    this.dataByTp = true;
    this.dataByCat = false;
    this.selected = selected;
    this.boolInvitationTp = "block";
    this.selectedTouchPointId = id;
    this.getQuestionsByTP(id)
  }

  paginationNavigationDetails(event, type) {
    var pNo = 1
    var pSize = 12;   
    
    if (event.page >= pNo || (event.page + 2) <= pNo || pSize != event.rows) { 
      this.pageNo = event.page + 1;
      this.pageSize = event.rows;
      this.getQuestionsByTP(this.selectedTouchPointId);
      //this.paginationData(this.pageNo, this.pageSize);
    }
  }
   
  //Pagignation issue
  //JIRA ID- 356
  PageToolTip() {
    let i = 0;
    this.paginatorFirstSelector = document.querySelectorAll('a.ui-paginator-first');
    for (i = 0; i < this.paginatorFirstSelector.length; i++) {
      this.paginatorFirstSelector[i].title = 'First';
    }

    this.paginatorLaststSelector = document.querySelectorAll('a.ui-paginator-last');
    for (i = 0; i < this.paginatorLaststSelector.length; i++) {
      this.paginatorLaststSelector[i].title = 'Last';
    }

    this.paginatorNextSelector = document.querySelectorAll('a.ui-paginator-next');
    for (i = 0; i < this.paginatorNextSelector.length; i++) {
      this.paginatorNextSelector[i].title = 'Next';
    }

    this.paginatorPrevSelector = document.querySelectorAll('a.ui-paginator-prev');
    for (i = 0; i < this.paginatorPrevSelector.length; i++) {
      this.paginatorPrevSelector[i].title = 'Previous';
    }
  }
}
