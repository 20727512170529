import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { RevenueReportBusinessRule } from '../model/revenue-report.model';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})

export class RevenueReportService {

  constructor(private httpClient: HttpClient, private sharedService: SharedService) { }

  getRevenueReportList(parameters) {
    const serviceUrl = this.sharedService.configuration.baseUrl;
    return this.httpClient.post(serviceUrl + 'api/RevenueMaster/GetRevenueMasterList', parameters)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  saveAsDefaultView(parameters) {
    const serviceUrl = this.sharedService.configuration.baseUrl;
    console.log('serviceUrl' + serviceUrl);
    return this.httpClient.post(serviceUrl + 'api/RevenueMaster/SaveUserPreferences/' + parameters,null)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  exportDataToExcel(parameters) {
    const serviceUrl = this.sharedService.configuration.baseUrl;
    console.log('serviceUrl' + serviceUrl);
    return this.httpClient.post(serviceUrl + 'api/RevenueMaster/ExportCompanyRevenue', parameters, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  getCurrencyMasterList(parameters) {
    const serviceUrl = this.sharedService.configuration.baseUrl;
    return this.httpClient.post(serviceUrl + 'api/RevenueMaster/GetCurrencyMasterList', parameters)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  GetModifyColumns(dataObj) {
    const serviceUrl = this.sharedService.configuration.baseUrl;
    return this.httpClient.post(serviceUrl + 'api/MDM/Grid/GetModifyColumns', dataObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  GetUserPreference() {    
    const serviceUrl = this.sharedService.configuration.baseUrl;
    return this.httpClient.get(serviceUrl + 'api/RevenueMaster/GetUserPreferences')
      .toPromise()
      .then((res: any) => res)
      .then(data => data);      
  }

  SaveModifyColumns(dataObj) {
    const serviceUrl = this.sharedService.configuration.baseUrl;
    return this.httpClient.post(serviceUrl + 'api/MDM/Grid/SaveModifyColumns', dataObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  uploadFile(file) {
    const serviceUrl = this.sharedService.configuration.baseUrl;
    return this.httpClient.post(serviceUrl + 'api/RevenueMaster/UploadRevenueMasterFileForProcessing', file)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  downloadTemplate(filepath) {
    filepath = 'RevenueReport-Add.xlsx';
    let serviceUrl = this.sharedService.configuration.baseUrl + "api/RevenueMaster/DownloadRevenueReportTemplateFileFromBlob/" + filepath;
    return this.httpClient.get(serviceUrl, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
}
