import { Component, OnInit, SimpleChanges, ViewChild, ElementRef, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';

import { ChartDataService } from '../service/chart-data-service.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { SharedService } from 'src/app/shared/services/shared.service';

import { ChartDataParsing } from 'src/app/shared/utils/chart-data-parsing.util';

import * as moment from 'moment';
import * as Highcharts from 'highcharts'

import { ChartBindingsModel, DD_STATE, L1DDOptions, L2DDOptions, L3DDOptions, CONTEXT_MENUES, CommonDDOptions } from './models/chart-binding.model';
import { WidgetOrderReceived } from './models/widget-order-received.model';
import { DragulaService } from 'ng2-dragula';
import { timer, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DashboardWidgetService } from '../service/dashboard-widget.service';
import { StackBarChartComponent } from './stack-bar-chart/stack-bar-chart.component';
import { BubbleChartComponent } from './bubble-chart/bubble-chart.component';
import { surveyType } from 'src/app/shared/constants/shared.constants';
import { RelationshipFilterService } from 'src/app/shared/services/filter-relationship.service';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import HighchartsWordCloud from 'highcharts/modules/wordcloud';
import { UserManagementService } from '../../falcon-setup/user-management/services/user-management.service';
HighchartsWordCloud(Highcharts);
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-chart-listing',
  templateUrl: './chartlisting.component.html',
  styleUrls: ['./chartlisting.component.scss']
})
export class ChartListingComponent implements OnInit {
  private imagepath = environment.imagepath;
  Highcharts: typeof Highcharts = Highcharts;
  HCColor: any = Highcharts;

  viewPeriod: string;
  // @ViewChild('npsWcRating') npsWcRating;
  // @ViewChild('npsNntRating') npsNntRating;
  // @ViewChild('npsScRating') npsScRating;
  // @ViewChild('npsNssRating') npsNssRating;
  // @ViewChild('npsRscRating') npsRscRating;
  @ViewChild(StackBarChartComponent) child: StackBarChartComponent;
  @ViewChildren(StackBarChartComponent) children: QueryList<StackBarChartComponent>;
  @ViewChild('nnsViewPart') nnsViewPart: ElementRef;

  @Output() flipToDeafult = new EventEmitter();
  @Output() flipOnClose = new EventEmitter();
  @Output() closeChartEventNss = new EventEmitter<boolean>();
  inLimit: boolean = false;
  pagex: number = 0;
  pagey: number = 0;
  imagedisplay: string = "hidden";
  @Input() chartsToShow: any[];
  @Input() surveyTypeInput: string = surveyType.Transactional;
  @Input() defaultShow: string;
  @Input() isSigleLocal: boolean = false;
  private npsData: any;
  private npsRollingData: any;
  private npsRollingPayload: any;
  private appendNPSRollingPayload = false;
  showNpsChartState: string;
  private npsChartTitle: string = 'Net Promoter Score Trend';
  private isNpsDualyAxes: boolean = false;
  private npsToolTipAppendStr: string = '%';

  // chart identifier: 'rrt'
  private rrtData: any;
  private rrtRollingData: any;
  private rrtRollingPayload: any;
  private appendRRTRollingPayload = false;
  showRrChartState: string;
  private rrtChartTitle: string = 'Response Rate Trend';
  private isRrtDualyAxes: boolean = false;

  // chart identifier: 'ssrr'
  private ssrData: any;
  private ssrRollingData: any;
  showSsrChartState: string;
  private ssrChartTitle: string = 'Surveys Sent vs. Responses Received';
  private isSsrDualyAxes: boolean = true;
  private showSsrLineLabels: boolean = true;

  // chart identifier: 'nss'
  private nnsData: any;
  private nnsRollingData: any;
  showNnsChartState: string;
  private nnsChartTitle: string = 'Net Sentiment Score';
  private isNnsDualyAxes: boolean = true;
  private showNnsLineLabels: boolean = true;

  // chart identifier: 'sc'
  private scData: any;
  private scRollingData: any;
  showScChartState: string;
  private scChartTitle: string = 'Sentiment Categories';
  private isScDualyAxes: boolean = false;
  private showScLineLabels: boolean = true;

  // chart identifier: 'swc'
  private wcData: any;
  private wcRollingData: any;
  showWcChartState: string;
  private wcChartTitle: string = 'Word Cloud';
  private isWcDualyAxes: boolean = false;
  private showWcLineLabels: boolean = true;

   // chart identifier: 'rsc'
   private rscData: any;
   private rscShowData: any;
   private rscRollingData: any;
   showRscChartState: string;
   private rscChartTitle: string = 'Revenue wise Sentiment Comparison';
   private isRscDualyAxes: boolean = true;
   private showRscLineLabels: boolean = true;

   // chart identifier: 'nnt'
   private nntData: any;
   private nntRollingData: any;
   showNntChartState: string;
   private nntChartTitle: string = 'NPS & Net Sentiment Score Trend';
   private isNntDualyAxes: boolean = true;
   private showNntLineLabels: boolean = true;
   private nntRollingPayload: any;
  private appendNNTRollingPayload = false;

  // chart identifier: 'cc'
  private ccData: any;
  private ccRollingData: any;
  showCcChartState: string;
  private ccChartTitle: string = '';
  private isCcDualyAxes: boolean = false;
  private pndView: string = 'country';
  private nntView: string = 'Month';
  private pndIsDrilldownEnabled: boolean = true;
  private showPNDDrilldown: boolean = false;
  private isPndContextMenuVisibile: boolean = false;
  private isCcDataLoading: boolean = true;
  private isCcTrendLoading: boolean = true;
  private isWcTrendLoading: boolean = true;
  private npsView: string = 'Month';

  private npsChartData: any;
  private rrtChartData: any;
  private ssrChartData: any;
  private ccChartData: any;
  private rscChartData: any;
  private nntChartData: any;
  private nnsChartData: any;
  private scChartData: any;
  private wcChartData: any;
  private globalFilterData: any;

  private wcNpsFilter = [];
  private scNpsFilter = [];
  private nntNpsFilter = [];
  private nssNpsFilter = [];
  private rscNpsFilter = [];

  npsDatas = [];
  currenyViewDuplicate = ''

  private scWidgetData: any = {};
  private nssWidgetData: any = {};


  // private CHART_STATE_OPTIONS = {
  //   LOADING: 'loading',
  //   SUCCESS: 'success',
  //   NO_DATA: 'no_data',
  //   ERROR: 'error'
  // };

  public widgetOrderReceived: WidgetOrderReceived[] = [];
  private wasHideClicked: boolean = false;

  private isNPSRendered: boolean = false;
  private isRRTRendered: boolean = false;
  private isSSRRendered: boolean = false;
  private isCCRendered: boolean = false;
  private isSARendered: boolean = false;
  private isRSCRendered: boolean = false;
  private isNNTRendered: boolean = false;
  private isNNSRendered: boolean = false;
  private isSCRendered: boolean = false;
  private isWCRendered: boolean = false;

  private initFilterCall: boolean;

  private chartBottomGradient: number = 0;
  private chartTopGradient: number = 1;

  private updateFromWidget: boolean = false;

  private isSoftClose: boolean;
  timerSubscribe: any = null;
  startmouseY: number = 0;
  scrollSet = true;
  sectionHeight: number = 0;
  @ViewChild('dragulaChartDiv', { read: ElementRef }) public dragulaChartDiv: ElementRef<any>;
  @ViewChild('chartPanelScroll') chartPanelScroll;
  zoneDrillDownEntityID   : number= -1;
  branchDrillDownEntityID : number= -1;
  sestDrillDownEntityID   : number= -1;
  previousViewBy: string = ('' + this.pndView).trim();
  isClick = true;

  public isDeepDrillDown: boolean = false;

  chartView: any[] = [
    { label: 'Country Comparison', value: 'country' },
    { label: 'FAM Comparison', value: 'fam' },
    { label: 'Brand Comparison', value: 'brand' }
  ];

  

  chartViewComparision: any[] = [
    { label: 'Month', value: 'Month' },
    { label: 'Quarter', value: 'Quarter' },
    { label: 'Year', value: 'Year' }    
  ];

  chartNpsViewComparision: any[] = [
    { label: 'Month', value: 'Month' },
    { label: 'Quarter', value: 'Quarter' },
    { label: 'Half year', value: 'HalfYearly' },
    { label: 'Year', value: 'Year' }    
  ];

  currencyDataView: any[] = [
    { label: 'Euro', value: 'euro' },
    { label: 'Fam Local', value: 'famLocal' },
    { label: 'Local', value: 'local' }
  ];

  LEGEND: any = {
    MRT_12: '12 MRT',
    CT: 'Current Trend (CT)',
    PROMOTERS: 'Promoters',
    NEUTRAL: 'Neutral',
    DETRACTORS: 'Detractor'
  };

  public chart: any = {
    npst: 'npst',
    rrt: 'rrt',
    ssrr: 'ssrr',
    cc: 'cc',
    rsc: 'rsc',
    nnt: 'nnt',
    nss: 'nss',
    sc: 'sc',
    swc:'swc'
  };

  private globFilterSub: Subscription;
  private chartToggelSub: Subscription;
  private ddLocationSub: Subscription;
  private saLocationSub: Subscription;
  private viewSub: Subscription;
  private refreshSub: Subscription;

  private currentView: string = '';
  comparisonAgainst: string | null = null;
  npsDataFilter: any;
  currenyView?: string = '';
  totalRevenueToShow: any;
  totalRevenueRiskToShow: any;
  symboltoShow: any = '€';
  hasAtLeastOneZeroRevenue: boolean= false;
  allHaveZeroRevenue: boolean= false;
  legendShow: boolean = true;
  selectedLegends: any = [];
  

  constructor(private chartDataService: ChartDataService,
    private globalFilter: FilterService,
    private relationshipFilterService: RelationshipFilterService,
    private sharedService: SharedService,
    private dragulaService: DragulaService,
    private dashboardWidgetService: DashboardWidgetService,
    ) {

    this.setDefaultWidgetPos();
    // this.chartDataService.drillDownState.setDropDownViewState(DD_STATE.L1);
    if (dragulaService.find('Drgula_Chart_Widjets'))
      dragulaService.destroy('Drgula_Chart_Widjets');

    dragulaService.createGroup('Drgula_Chart_Widjets', {
      moves: (el, container, handle) => {
        return handle.classList.contains('handle');
      }
    });

    dragulaService.drop().subscribe((value) => {
      if (value.name == "Drgula_Chart_Widjets") {
        this.scrollSet = true;
        this.chartPanelScroll.nativeElement.scrollTop = 800;

        let widjetorderListToSend = new Array();
        let widjetorderListToSendTemp = new Array(), index = 0;
        Array.from(this.dragulaChartDiv.nativeElement.children).forEach((element) => {
          let obj: any = element;
          this.widgetOrderReceived.forEach(widget => {
            if (widget.WidgetCode == obj.children[0].getAttribute('id')) {
              if (widget.IsVisible) {
                widget.Position = "D" + (index + 1);
                widjetorderListToSend.push(widget);
                index++;
              } else {
                widjetorderListToSendTemp.push(widget);
              }
            }
          });
        });

        widjetorderListToSendTemp.forEach(widget => {
          widget.Position = "D" + (index + 1);
          widjetorderListToSend.push(widget);
          index++;
        });

        //timer stop ongoing API call
        if (this.timerSubscribe && this.timerSubscribe.closed == false) {
          this.timerSubscribe.unsubscribe();
        }

        this.timerSubscribe = timer(this.sharedService.configuration.dragDropTimeOut).subscribe(() => {

          //api call to save update list
          this.dashboardWidgetService.postAddUpdateWidgetLocation(widjetorderListToSend).then(res => {
            if (res.Status == "Success") {
              console.log("Charts drag/drop order saved");
            }
          }).catch(e => {

          });

        });
      }
    });


    dragulaService.drag().subscribe((value) => {
      if (value.name == "Drgula_Chart_Widjets") {
        this.scrollSet = false;
        //event for creating scrollable drag-drop
        document.onmousemove = e => {
          const event = e || window.event;
          const mouseY = event['pageY'];
          this.startmouseY ? null : this.startmouseY = mouseY;
          let element: any = value.el;
          const elementHeight = element.offsetHeight > 200 ? 100 : element.offsetHeight;
          if ((this.startmouseY - mouseY) < -elementHeight) {
            this.chartPanelScroll.nativeElement.scrollTop += 10;
          } else if ((this.startmouseY - mouseY) > elementHeight) {
            this.chartPanelScroll.nativeElement.scrollTop -= 10;
          }
        };
      }
    });

    dragulaService.dragend().subscribe((value) => {
      if (value.name == "Drgula_Chart_Widjets") {
        this.scrollSet = true;
        document.onmousemove = null;
        this.startmouseY = 0;
        this.chartPanelScroll.nativeElement.scrollTop = 800;
      }
    });
  }

  ngAfterViewChecked() {
    // set drag scroll to initial position
    if (this.scrollSet && this.chartPanelScroll) {
      this.chartPanelScroll.nativeElement.scrollTop = 800;
    }
  }
  
  ngAfterViewInit() {
    this.npsDropdownOptions();
  }

  mapDropdownOptions() {
    //add values from 1-20 for count and 0-10 fot nps
    for (let i = 0; i <= 20; i++) {
      //if (i > 0) this.CountData.push({ label: i, value: i, })
      if (i <= 10) this.npsDataFilter.push({ label: i.toString(), value: i, })
    }
  }

  onOpenPanel() {
    var element = <HTMLElement>(
      document.getElementsByClassName('ui-multiselect-panel')[0]
    );
    element.className += ' remove-arrow';
  }

  ngDoCheck() {
    //setting out div height for scroll
    if (this.dragulaChartDiv && (this.sectionHeight != this.dragulaChartDiv.nativeElement.clientHeight)) {
      this.sectionHeight = this.dragulaChartDiv.nativeElement.clientHeight;
    }
  }

  npsDropdownOptions() {
    for (let i = 0; i <= 20; i++) {
     // if (i > 0) this.CountData.push({ label: i, value: i, })
      if (i <= 10) this.npsDatas.push({ label: i.toString(), value: i, })
    }
  }

  unselectDataHandler(event) {
    this.selectedLegends = event.legendUnclicked;
    this.rscChartData = this.parseRscData()
  }


  ngOnInit() {
    // subscribe to global filter changes & update charts in real time
    this.globFilterSub = this.globalFilter.globalFilterBehaviousSubject.subscribe(val => {
      this.globalFilterData = val;   
      this.chartDataService.drillDownState.setDropDownViewState(DD_STATE.L1);

      if (this.initFilterCall) {
        this.comparisonAgainst = null;

        let lmnt: StackBarChartComponent | undefined = this.getStackBarChartInstanceFor(this.chart.cc.toLowerCase());
        if(lmnt)
          lmnt.resetDrilldownView(this.pndView);

        this.pndIsDrilldownEnabled = true;
        this.getChartData();
      }
      if (!this.initFilterCall) {
        this.initFilterCall = true;
      }
    });
    this.refreshSub = this.chartDataService.getRefreshWidget()
    .pipe(filter(data => !!data))
    .subscribe(val => {
      if (val && (val.refresh || val.globalRefresh)) {
        this.getChartRefreshData(val);
      }
    });

    // subscribe to month/year toggle changes & update charts in real time
    this.chartToggelSub = this.sharedService.chartToggleParamBehaviousSubject.subscribe(viewBy => {
      this.viewPeriod = viewBy;
      let npst = this.chartsToShow.find(data=>data.WidgetCode.toLowerCase()==='npst')
        if(npst && npst.IsVisible) {
          this.isNPSRendered = false;
        }
      if(this.viewPeriod =='Month' || this.viewPeriod =='Year')
        this.parseExistingData();
      else  {
        this.viewPeriod = 'Month'
        this.orchestrateChartView(this.chartsToShow, false);
      }
       
    });
        this.viewSub = this.sharedService.dashboardStateSubject.subscribe(viewType => {
      this.currentView = viewType;
      
      if (viewType === null)
        this.orchestrateChartView(this.chartsToShow, false);
    })
    // this.getNnsData(this.globalFilterData,this.nssNpsFilter);
    // subscribe to the chart widget listing metadata change
    this.ddLocationSub = this.sharedService.widgetDDLocationSubject.subscribe(widgetData => {
      if (this.currentView !== this.sharedService.TRENDLINE_VIEW) {
        return;
      }
      if (!Array.isArray(widgetData))
        return;
      if (this.isSoftClose) {
        this.isSoftClose = false;
        return;
      }

      this.orchestrateChartView(widgetData, true);
      this.updateFromWidget = false;
    });

    this.saLocationSub = this.sharedService.widgetSALocationSubject.subscribe(widgetData => {
      if (this.currentView !== this.sharedService.SENTIMENTAL_VIEW) {
        return;
      }
      if (!Array.isArray(widgetData))
        return;
      if (this.isSoftClose) {
        this.isSoftClose = false;
        return;
      }

      this.orchestrateChartView(widgetData, true);
      this.updateFromWidget = false;
    });
  }

  onWcSelect(data){
    if(this.wcNpsFilter.includes(data)) {
      this.wcNpsFilter.splice(this.wcNpsFilter.indexOf(data),1)
    } else {
      this.wcNpsFilter.push(data)
    }
     
    if(this.wcNpsFilter && this.wcNpsFilter.length > 0){
      this.getWcData(this.globalFilterData,this.wcNpsFilter)
      
    } else {
      this.getWcData(this.globalFilterData,[])
    }  
  }

  onNntSelect(data){
    if(this.nntNpsFilter.includes(data)) {
      this.nntNpsFilter.splice(this.nntNpsFilter.indexOf(data),1)
    } else {
      this.nntNpsFilter.push(data)
    }
     
    if(this.nntNpsFilter && this.nntNpsFilter.length > 0){
      this.getNNTChartData(this.globalFilterData,this.nntView,this.nntNpsFilter)
      
    } else {
      this.getNNTChartData(this.globalFilterData,this.nntView,[])
    } 
    
  }

  onScSelect(data){
    if(this.scNpsFilter.includes(data)) {
      this.scNpsFilter.splice(this.scNpsFilter.indexOf(data),1)
    } else {
      this.scNpsFilter.push(data)
    }
     
    if(this.scNpsFilter && this.scNpsFilter.length > 0){
      this.getScData(this.globalFilterData,this.scNpsFilter)
      
    } else {
      this.getScData(this.globalFilterData,[])
    } 
   
  }

  onNssSelect(data){
    if(this.nssNpsFilter.includes(data)) {
      this.nssNpsFilter.splice(this.nssNpsFilter.indexOf(data),1)
    } else {
      this.nssNpsFilter.push(data)
    }
     
    if(this.nssNpsFilter && this.nssNpsFilter.length > 0){
      this.getNnsData(this.globalFilterData,this.nssNpsFilter)
      
    } else {
      this.getNnsData(this.globalFilterData,[])
    } 
    
  }

  onRscSelect(data){
    if(this.rscNpsFilter.includes(data)) {
      this.rscNpsFilter.splice(this.rscNpsFilter.indexOf(data),1)
    } else {
      this.rscNpsFilter.push(data)
    }
     
    if(this.rscNpsFilter && this.rscNpsFilter.length > 0){
      this.getRscData(this.globalFilterData,this.rscNpsFilter)
      
    } else {
      this.getRscData(this.globalFilterData,[])
    }
    
   
  }


  ngOnChanges(changes: SimpleChanges) {
    this.currenyView = this.isSigleLocal ? "famLocal" : ''
    if (changes.chartsToShow)
      if (changes.chartsToShow.firstChange && this.defaultShow != 'storyboard')
        return;

    if (changes.chartsToShow && changes.chartsToShow.currentValue) {
      if (!this.chartsToShow || Array.isArray(this.chartsToShow) && 
      (this.currentView != null || JSON.stringify(changes.chartsToShow.currentValue) != JSON.stringify(changes.chartsToShow.previousValue))) {
        if(this.globalFilterData === undefined) {
          this.globalFilterData = this.globalFilter.filterObj;
        }
        let nssPrevious = changes.chartsToShow.previousValue ? changes.chartsToShow.previousValue.find(data=> data.WidgetCode.toLowerCase() === 'nss') : null;
        let nssCurrent = changes.chartsToShow.currentValue ? changes.chartsToShow.currentValue.find(data=> data.WidgetCode.toLowerCase() === 'nss') : null;
        let npstPrevious = changes.chartsToShow.previousValue ? changes.chartsToShow.previousValue.find(data=> data.WidgetCode.toLowerCase() === 'npst') : null;
        let npstCurrent = changes.chartsToShow.currentValue ? changes.chartsToShow.currentValue.find(data=> data.WidgetCode.toLowerCase() === 'npst') : null;
        let lsddPrevious = changes.chartsToShow.previousValue ? changes.chartsToShow.previousValue.find(data=> data.WidgetCode.toLowerCase() === 'lsdd') : null;
        let lsddCurrent = changes.chartsToShow.currentValue ? changes.chartsToShow.currentValue.find(data=> data.WidgetCode.toLowerCase() === 'lsdd'): null;
        if(JSON.stringify(changes.chartsToShow.currentValue) != JSON.stringify(changes.chartsToShow.previousValue)) {
          if((nssPrevious && nssCurrent )
          || (npstPrevious && npstCurrent )
          || (lsddPrevious && lsddCurrent )) {
            this.isSCRendered = true;
            this.isRRTRendered = true;
            // do nothing
          } else{
            this.isNPSRendered = false;
            this.isRRTRendered = false;
            this.isSSRRendered = false;
            this.isCCRendered = false;
            this.isRSCRendered = false;
            this.isNNTRendered = false;
            this.isNNSRendered= false;
            this.isSCRendered = false;
            this.isWCRendered = false;
          }
            
        }
        this.updateFromWidget = true;
        this.orchestrateChartView(this.chartsToShow, false);
      }

      this.imagedisplay = "hidden";
    }
  }

  ngOnDestroy() {
    this.ddLocationSub.unsubscribe();
    this.globFilterSub.unsubscribe();
    this.chartToggelSub.unsubscribe();
    this.saLocationSub.unsubscribe();
    this.viewSub.unsubscribe();
      }

  getChartData() {
    if (this.showChart(this.chart.npst))
      this.getNPSChartData(this.globalFilterData);
    if (this.showChart(this.chart.rrt))
      this.getRRChartData(this.globalFilterData);
    if (this.showChart(this.chart.ssrr))
      this.getSsrData(this.globalFilterData);
    if (this.showChart(this.chart.cc))
      this.getPNDData(this.globalFilterData, this.pndView);
    if (this.showChart(this.chart.rsc))
      this.getRscData(this.globalFilterData, this.rscNpsFilter);
    if (this.showChart(this.chart.nnt))
      this.getNNTChartData(this.globalFilterData, this.nntView, this.nntNpsFilter);
    if (this.showChart(this.chart.nss))
      this.getNnsData(this.globalFilterData, this.nssNpsFilter);
    if (this.showChart(this.chart.sc))
      this.getScData(this.globalFilterData, this.scNpsFilter);
    if (this.showChart(this.chart.swc))
      this.getWcData(this.globalFilterData,this.wcNpsFilter);
  }
  getChartRefreshData(refreshValue: any = {}) {
    if (refreshValue.globalRefresh) {
      this.getNPSChartData({ ...this.globalFilterData, IsRefresh: refreshValue.npst });
      this.getRRChartData({ ...this.globalFilterData, IsRefresh: refreshValue.rrt });
      this.getSsrData({ ...this.globalFilterData, IsRefresh: refreshValue.ssrr });
      this.getRscData({ ...this.globalFilterData, IsRefresh: refreshValue.rsc }, this.rscNpsFilter);
      this.getNnsData({ ...this.globalFilterData, IsRefresh: refreshValue.nss }, this.nssNpsFilter);
      this.getNNTChartData({ ...this.globalFilterData, IsRefresh: refreshValue.nnt }, this.nntView,this.nntNpsFilter);
      this.getScData({ ...this.globalFilterData, IsRefresh: refreshValue.sc }, this.scNpsFilter);
      this.getWcData({ ...this.globalFilterData, IsRefresh: refreshValue.swc },this.wcNpsFilter);
      this.getPNDData({ ...this.globalFilterData, IsRefresh: refreshValue.cc }, this.pndView);
    } else {
      if (refreshValue.npst) {
        this.getNPSChartData({ ...this.globalFilterData, IsRefresh: refreshValue.npst });
      } else if (refreshValue.rrt) {
        this.getRRChartData({ ...this.globalFilterData, IsRefresh: refreshValue.rrt });
      } else if (refreshValue.ssrr) {
        this.getSsrData({ ...this.globalFilterData, IsRefresh: refreshValue.ssrr });
      } else if (refreshValue.cc) {
        this.getPNDData({ ...this.globalFilterData, IsRefresh: refreshValue.cc }, this.pndView);
      } else if (refreshValue.rsc) {
        this.getRscData({ ...this.globalFilterData, IsRefresh: refreshValue.rsc }, this.rscNpsFilter);
      } else if (refreshValue.nnt) {
        this.getNNTChartData({ ...this.globalFilterData, IsRefresh: refreshValue.nnt }, this.nntView, this.nntNpsFilter);
      } else if (refreshValue.nss) {
        this.getNnsData({ ...this.globalFilterData, IsRefresh: refreshValue.nss }, this.nssNpsFilter);
      } else if (refreshValue.sc) {
        this.getScData({ ...this.globalFilterData, IsRefresh: refreshValue.sc }, this.scNpsFilter);
      } else if (refreshValue.swc) {
        this.getWcData({ ...this.globalFilterData, IsRefresh: refreshValue.swc },this.wcNpsFilter);
      }
    }
  }

  parseExistingData() {
    if ((this.npsData || this.npsRollingData) && this.showChart(this.chart.npst))
      this.parseNpsData(this.npsView ? this.npsView : this.viewPeriod);
    if ((this.rrtData || this.rrtRollingData) && this.showChart(this.chart.rrt))
      this.parseRrtData(this.viewPeriod);
    if ((this.ssrData || this.ssrRollingData) && this.showChart(this.chart.ssrr))
      this.parseSsrData(this.viewPeriod);
    if ((this.nntData || this.nntRollingData) && this.showChart(this.chart.nnt)) {
      if(this.viewPeriod !=this.nntView) {
        this.nntView = this.viewPeriod
      }
      this.parseNntData(this.viewPeriod);
    }
      
    // if ((this.rscData || this.rscRollingData) && this.showChart(this.chart.rsc))
    //   this.parseRscData(this.viewPeriod);
    if ((this.ccData || this.ccRollingData) && this.showChart(this.chart.cc))
      this.parsePNDData('Entity', 'TooltipCntry');
  }

  orchestrateChartView(widgetData: any[], isNpsFixed: boolean) {
    this.widgetOrderReceived = this.parseWidgetOrder(Object.assign([], widgetData));
    this.widgetOrderReceived.forEach(widget => {
      switch (widget.WidgetCode.toLowerCase()) {
        case this.chart.npst:
          // hardcoding NPS chart widget to always be visible
          // if (isNpsFixed)
          //   widget.IsVisible = true;

          if (widget.IsVisible) {
            if (this.showNpsChartState === CHART_STATE_OPTIONS.LOADING)
              break;
            if (this.isNPSRendered === false) {
              this.getNPSChartData(this.globalFilterData);
            } else {
            }
          }
          break;
        case this.chart.rrt:
          if (widget.IsVisible) {
            if (this.showRrChartState === CHART_STATE_OPTIONS.LOADING)
              break;
            if (this.isRRTRendered === false) {
              this.getRRChartData(this.globalFilterData);
            } else {
            }
          }
          break;
        case this.chart.ssrr:
          if (widget.IsVisible) {
            if (this.showSsrChartState === CHART_STATE_OPTIONS.LOADING)
              break;
            if (this.isSSRRendered === false) {
              this.getSsrData(this.globalFilterData);
            } else {
            }
          }
          break;
          case this.chart.rsc:
            if (widget.IsVisible) {
              if (this.showRscChartState === CHART_STATE_OPTIONS.LOADING)
                break;
              if (this.isRSCRendered === false) {
                this.getRscData(this.globalFilterData, this.rscNpsFilter);
              } else {
                //this.resetCcChart();
              }
            }
            break;
            case this.chart.nnt:
              if (widget.IsVisible) {
                if (this.showNntChartState === CHART_STATE_OPTIONS.LOADING)
                  break;
                if (this.isNNTRendered === false) {
                  this.getNNTChartData(this.globalFilterData, this.nntView,this.nntNpsFilter);
                } else {
                  //this.resetNntChart();
                }
              }
              break;
            case this.chart.nss:
              if (widget.IsVisible) {
                if (this.showNnsChartState === CHART_STATE_OPTIONS.LOADING)
                  break;
                if (this.isNNSRendered === false) {
                  this.getNnsData(this.globalFilterData, this.nssNpsFilter);
                } else {
                  //this.resetNntChart();
                }
              }
            break;
            case this.chart.sc:
              if (widget.IsVisible) {
                if (this.showScChartState === CHART_STATE_OPTIONS.LOADING)
                  break;
                if (this.isSCRendered === false) {
                  this.getScData(this.globalFilterData, this.scNpsFilter);
                } else {
                  //this.resetNntChart();
                }
              }
            break;
            case this.chart.swc:
              if (widget.IsVisible) {
                if (this.showWcChartState === CHART_STATE_OPTIONS.LOADING)
                  break;
                if (this.isWCRendered === false) {
                  this.getWcData(this.globalFilterData,this.wcNpsFilter);
                } else {
                  //this.resetNntChart();
                }
              }
            break;
            case this.chart.cc:
              if (widget.IsVisible) {
                if (this.showCcChartState === CHART_STATE_OPTIONS.LOADING)
                  break;
                if (this.isCCRendered === false) {
                  this.getPNDData(this.globalFilterData, this.pndView);
                } else {
                  setTimeout(() => {
                    this.resetCcChart();
                  }, 450);
                }
              }
              break;

        default:
          break;
      }
    });
    this.scWidgetData = this.widgetOrderReceived.find(x => x.WidgetCode.toLowerCase() === this.chart.sc) || {};
    this.nssWidgetData = this.widgetOrderReceived.find(x => x.WidgetCode.toLowerCase() === this.chart.nss) || {};
  }

  parseWidgetOrder(widgetData: WidgetOrderReceived[]) {
    widgetData = this.getWidgetSort(widgetData, 'Position');
    let lsddWidgetLoc = widgetData.findIndex(widget => widget.WidgetCode.toLowerCase() === 'lsdd') 
    if(lsddWidgetLoc !=-1)
      widgetData.splice(lsddWidgetLoc, 1);
    return widgetData;
  }

  hideChartWidgetClicked(widget) {
    this.isSoftClose = true;
    this.wasHideClicked = true;
    let index = this.widgetOrderReceived.findIndex(item => item.WidgetCode.toLowerCase() === widget.WidgetCode.toLowerCase());
    this.widgetOrderReceived[index].IsVisible = false;
    this.widgetOrderReceived[index].IsSaved = false;
    // if(widget.WidgetCode.toLowerCase() ==='npst' && this.defaultShow ==this.sharedService.TRENDLINE_VIEW){
    //   this.sharedService.widgetCloseSubject.next(this.widgetOrderReceived[index].WidgetCode);
    // } else if(widget.WidgetCode.toLowerCase() ==='nss' && this.defaultShow ==this.sharedService.SENTIMENTAL_VIEW){
    //   this.sharedService.widgetCloseSubject.next(this.widgetOrderReceived[index].WidgetCode);
    // } else{&& widget.WidgetCode.toLowerCase() !='npst' && widget.WidgetCode.toLowerCase() !='nss'
      if (this.currentView !== null)
        this.sharedService.widgetCloseSubject.next(this.widgetOrderReceived[index].WidgetCode);
    // }
    switch (widget.WidgetCode.toLowerCase()) {
      case this.chart.npst:
        this.isNPSRendered = false;
        // if(this.currentView == this.sharedService.STORYBOARD_VIEW || this.currentView == this.sharedService.SENTIMENTAL_VIEW || this.defaultShow ==this.sharedService.STORYBOARD_VIEW || this.defaultShow == this.sharedService.SENTIMENTAL_VIEW)
        //   this.flipOnClose.emit({chartViewFlag:'showNpstChart'});
        // break;
      case this.chart.rrt:
        this.isRRTRendered = false;
        break;
      case this.chart.ssrr:
        this.isSSRRendered = false;
        break;
      case this.chart.cc:
        this.isCCRendered = false;
        break;
      case this.chart.rsc:
        this.isRSCRendered = false;
        break;
      case this.chart.nnt:
        this.isNNTRendered = false;
        break;
      case this.chart.nss:
        this.isNNSRendered = false;
        // if(this.currentView == this.sharedService.STORYBOARD_VIEW || this.currentView == this.sharedService.TRENDLINE_VIEW || this.defaultShow ==this.sharedService.STORYBOARD_VIEW || this.defaultShow ==this.sharedService.TRENDLINE_VIEW)
        //   this.flipOnClose.emit({chartViewFlag:'showNSSChart'});
        // break;
      case this.chart.sc:
        this.isSCRendered = false;
        break;
      case this.chart.swc:
        this.isWCRendered = false;
        break;
    }
  }

  /**
   * Event for the comparison chart dropdown change
   */
  comparisonParamChanged($event) {

    let view = this.decideCcChartView($event.value.toLowerCase());
    L1DDOptions.filter(i => i.showFor == CONTEXT_MENUES.UNKNOWN).map(i => i.value).includes(view) ? this.pndView = view.toLowerCase() : null;

    if (!this.showChart(this.chart.cc))
      return;
    this.drillDownEngine();

    this.resetDrilldowns();



    this.getPNDData(this.globalFilterData, this.pndView);
  }

  comparisonNNTParamChanged($event) {

   
    if (!this.showChart(this.chart.nnt))
      return;
    this.parseNntData(this.nntView)
    //this.getNNTChartData(this.globalFilterData, this.nntView,this.nntNpsFilter);
  }
  comparisonNPSParamChanged($event) {
    this.npsView = $event && $event.value ? $event.value : 'Month';
    if (!this.showChart(this.chart.npst))
      return;
    this.parseNpsData(this.npsView);
    if (!(this.npsData && this.npsData[this.npsView] && this.npsData[this.npsView].length)) {
      this.showNpsChartState = CHART_STATE_OPTIONS.NO_DATA;
    } else {
      this.showNpsChartState = CHART_STATE_OPTIONS.SUCCESS;
    }
  }

  /**
   * Event emitted by stacked-bar-chart component on click of context menu for drilldown
   * @param $event
   */
  chartContextMenuClicked($event) {
    // TODO: set pndView to FAM in case of additional FAM-wise-drilldown-options in $event.compareWith
    let entityId = this.getEntityIdForPNDData($event.entityName);
    let view = this.decideCcChartView($event.compareWith);
    let currentView = ('' + this.pndView).trim();
    L1DDOptions.filter(i => i.showFor == CONTEXT_MENUES.UNKNOWN).map(i => i.value).includes(view) ? this.pndView = view.toLowerCase() : null;

    let state = this.drillDownEngine($event.compareWith, entityId);

    if (this.showChart('cc')) {
      this.getPNDData(this.globalFilterData, state > 2 ? this.previousViewBy : currentView, $event.compareWith, entityId, true, this.isDeepDrillDown);
    }
    this.previousViewBy = ('' + $event.compareWith).trim();
  }
  decideCcChartView(eventViewType: string): string {
    let compareAgainst = '';
    if (this.sharedService.exportFamComparison.includes(eventViewType.toLowerCase())) {
      compareAgainst = 'fam'.toLowerCase();
      switch (eventViewType.toLowerCase()) {
        case 'zone': this.comparisonAgainst = 'Zone Comparison'; break;
        case 'branch': this.comparisonAgainst = 'Branch Comparison'; break;
        case 'engineer': this.comparisonAgainst = 'Service Engineer Comparison'; break;
        case 'technician': this.comparisonAgainst = 'Service Technician Comparison'; break;
        case 'ProjectManager': this.comparisonAgainst = 'Project Manager Comparison'; break;
        default: this.comparisonAgainst = null; break;
      }
    } else {
      compareAgainst = eventViewType.toLowerCase();
    }
    return compareAgainst;
  }
  /**
   * Click on reset view for CC/PND chart
   */
  resetCcChart() {
    this.pndView = ('' + this.pndView.toLowerCase()).trim();
    this.comparisonAgainst = null;
    this.pndIsDrilldownEnabled = true;
    this.chartDataService.drillDownState.setDropDownViewState(DD_STATE.L1);
    this.resetDrilldowns();
    this.getPNDData(this.globalFilterData, this.pndView, null, null, false);
  }

  resetNntChart() {
    this.nntView = ('' + this.nntView.toLowerCase()).trim();
    //this.comparisonAgainstData = null;
    // this.nntIsDrilldownEnabled = true;
    // this.chartDataService.drillDownState.setDropDownViewState(DD_STATE.L1);
    //this.resetDrilldowns();
    
    this.getNNTChartData(this.globalFilterData, 'month',this.nntNpsFilter);
  }
  drillDownEngine(currentVal: string | null = '', entityId: number = -1): DD_STATE {

    if (this.chartDataService.drillDownState.getDropDownViewState() === DD_STATE.L3) {
      // L3 to L4/Common
      this.isDeepDrillDown = true;
      if (CommonDDOptions.map(i => i.value).includes(currentVal.toLowerCase())) {
        // Add L1 & L2 / FAM & Zone - level &  filter
        // this.shiftL1ToL2();
        // this.branchDrillDownEntityID = entityId;
        this.sestDrillDownEntityID = entityId;
        this.chartDataService.drillDownState.setDropDownViewState(DD_STATE.COMMON);
        this.pndIsDrilldownEnabled = false;
      }
    } else
      if (this.chartDataService.drillDownState.getDropDownViewState() === DD_STATE.L2) {
        // L1 is available use it
        this.isDeepDrillDown = true;
        // this.resetL2DrillDownEntityID();
        // L2 to L3
        if (L3DDOptions.map(i => i.showFor.toString().toLowerCase()).includes(currentVal.toLowerCase())) {
          this.branchDrillDownEntityID = entityId;
          // this.resetL3DrillDownEntityID();
          this.chartDataService.drillDownState.setDropDownViewState(DD_STATE.L3);
        }
        // L2 to L4/Common
        else {
          // this.resetL3DrillDownEntityID();
          // this.sestDrillDownEntityID = entityId;
          this.resetL3DrillDownEntityID();
          this.resetL4DrillDownEntityID();        
          this.chartDataService.drillDownState.setDropDownViewState(DD_STATE.COMMON);
          this.pndIsDrilldownEnabled = false;
        }
      } else
        if (this.chartDataService.drillDownState.getDropDownViewState() === DD_STATE.L1) {
          this.isDeepDrillDown = false;
          if (this.chartDataService.otherDDConfig.includes(currentVal.toLowerCase())) {
            let goToState: DD_STATE;
            // L1 to L3
            if (currentVal.toLowerCase() === CONTEXT_MENUES.BRANCHES.toLowerCase()) {
              this.zoneDrillDownEntityID = entityId;
              // this.resetL2DrillDownEntityID();
              // this.resetL4DrillDownEntityID();
              goToState = DD_STATE.L3;
            }
            // L1 to L2
            if (currentVal.toLowerCase() === CONTEXT_MENUES.ZONE.toLowerCase()) {
              this.zoneDrillDownEntityID = entityId;
              // this.resetL3DrillDownEntityID();
              // this.resetL4DrillDownEntityID();
              goToState = DD_STATE.L2;
            }
            // L1 to L4/Common
            if (currentVal.toLowerCase() === CONTEXT_MENUES.SE.toLowerCase() || 
                currentVal.toLowerCase() === CONTEXT_MENUES.ST.toLowerCase() ||
                currentVal.toLowerCase() === CONTEXT_MENUES.OPERATIONAL_COUNTRY.toLowerCase()
            ) {
              // Clear L1 & L2 filter
              this.resetL2DrillDownEntityID();
              this.resetL3DrillDownEntityID();
              this.resetL4DrillDownEntityID();
              // this.sestDrillDownEntityID = entityId;
              this.isDeepDrillDown = false;
              goToState = DD_STATE.COMMON;
              this.pndIsDrilldownEnabled = false;
            }
         
            this.chartDataService.drillDownState.setDropDownViewState(goToState);
          } else if (L1DDOptions.map(i => i.value).includes(currentVal.toLowerCase())) {
            this.resetL2DrillDownEntityID();
            this.resetL3DrillDownEntityID();
            this.resetL4DrillDownEntityID();
            this.chartDataService.drillDownState.setDropDownViewState(DD_STATE.L1);
          }
        }
    // this.allowContextMenuEnablement();
    return this.chartDataService.drillDownState.getDropDownViewState();
  }
  allowContextMenuEnablement() {
    this.pndIsDrilldownEnabled = this.chartDataService.drillDownState.dropDownOptionsVisible.length === 0 ? false : true;
  }
  /**
   * Get NPS chart data from API
   * @param filterObj optional global filter object
   */
  getNPSChartData(filterObj: any | null) {
    this.showNpsChartState = CHART_STATE_OPTIONS.LOADING;
    this.npsChartData = new ChartBindingsModel();
    this.npsData = null;
    this.npsRollingData = null;
    //make api call for nps data
    this.chartDataService.getNpsTrendData(filterObj).subscribe(npsRes => {
      let npsPayload: any = npsRes;

      if (npsPayload !== null && npsPayload.Status !== 'Success') {
        this.showNpsChartState = CHART_STATE_OPTIONS.ERROR;

        this.npsData = null;
        return;
      }

      if (npsPayload === null || npsPayload.Response === null || (npsPayload.Response.Month.length === 0 && npsPayload.Response.Year.length === 0)) {
        this.showNpsChartState = CHART_STATE_OPTIONS.NO_DATA;

        this.npsData = null;
        return;
      }

      if (npsPayload.Response && npsPayload.Status === 'Success') {
        this.npsData = npsPayload.Response;
        /* if (this.appendNPSRollingPayload) {
          this.npsRollingData = this.npsRollingPayload.Response;
        } */
        
        this.parseNpsData(this.npsView ? this.npsView : (this.viewPeriod ? this.viewPeriod : 'Month'));

        this.showNpsChartState = CHART_STATE_OPTIONS.SUCCESS;
        this.getNPSRollingData(filterObj);
      }
    },
      (err) => {
        this.showNpsChartState = CHART_STATE_OPTIONS.ERROR

        this.npsData = null;
        this.npsRollingData = null;
      });
  }
  getNPSRollingData(filterObj: any | null) {
    this.npsRollingData = null;
    //make api call for nps rolling data
    this.chartDataService.getNpsRollingTrendData(filterObj).subscribe(rollingRes => {
      let rollingPayload: any = rollingRes;

      if (rollingPayload === null || rollingPayload.Response === null || (rollingPayload.Response.Month.length === 0 && rollingPayload.Response.Year.length === 0)) {
        // this.showNpsChartState = CHART_STATE_OPTIONS.NO_DATA;

        this.npsRollingData = null;
        return;
      }
      if (rollingPayload !== null && rollingPayload.Status !== 'Success') {
        // this.showNpsChartState = CHART_STATE_OPTIONS.ERROR;

        this.npsRollingData = null;
        return;
      }
      if (rollingPayload.Response && rollingPayload.Status == 'Success') {
        this.npsRollingData = rollingPayload.Response;
        this.parseNpsData(this.npsView ? this.npsView : this.viewPeriod);
        /* this.npsRollingData = rollingPayload.Response;
        this.parseNpsData(this.viewPeriod); */

        /* if (this.showNpsChartState === CHART_STATE_OPTIONS.LOADING) {
          this.npsRollingPayload = rollingPayload;
          this.appendNPSRollingPayload = true;
        } else if (this.showNpsChartState === CHART_STATE_OPTIONS.SUCCESS) {
          this.npsRollingData = rollingPayload.Response;
          this.parseNpsData(this.npsView ? this.npsView : this.viewPeriod);
        } else {
          this.npsRollingData = null;
        } */

        // this.showNpsChartState = CHART_STATE_OPTIONS.SUCCESS;
      }
    },
      (err) => {
        this.showNpsChartState = CHART_STATE_OPTIONS.ERROR

        this.npsData = null;
        this.npsRollingData = null;
      })
  }
  /**
   * Parse NPS chart data
   * @param period show data by 'Month' or 'Year'
   */
  parseNpsData(period: string) {
    // exclude these in bar plotting
    let stackedBarColumnExclude = ["NpsCount", "Period", "date"], stackedLineExclude = ["Period", "date"];
    let tempNpsChartData: ChartBindingsModel = new ChartBindingsModel();

    tempNpsChartData['Title'] = '';

    if (this.npsData && this.npsData[period]) {
      this.npsData[period] = this.getSortedInput(this.addTimeObject(this.npsData[period], period), 'date');
      ChartDataParsing.parseNpsBarData(period, stackedBarColumnExclude, this.npsData, false, false).forEach(item => tempNpsChartData.yAxis.push(Object.assign({}, item)));

      ChartDataParsing.parseNpsxAxis(period, this.npsData).forEach(item => tempNpsChartData.xAxis.push(item));
    }

    if (this.npsRollingData && this.npsRollingData[period]) {
      this.npsRollingData[period] = this.getSortedInput(this.addTimeObject(this.npsRollingData[period], period, false, true), 'date');
      ChartDataParsing.parseNpsLineData(period, stackedLineExclude, this.npsRollingData, false, this.globalFilterData).forEach(item => tempNpsChartData.yAxis.push(Object.assign({}, item)));
    }
    this.npsChartData = tempNpsChartData;
    // ChartDataParsing.saveParsedDataToSessionStorage(this.chart.npst, this.npsChartData);
    this.isNPSRendered = true;
  }

  /**
   * Get RRT chart data from API
   * @param filterObj optional global filter object
   */
  getRRChartData(filterObj: any | null) {
    this.showRrChartState = CHART_STATE_OPTIONS.LOADING;
    this.rrtChartData = new ChartBindingsModel();
    this.rrtData = null;
    this.rrtRollingData = null;
    //make api call for response rate data
    this.chartDataService.getRRTrendData(filterObj).subscribe(rrRes => {
      let rrPayload: any = rrRes;

      if (rrPayload !== null && rrPayload.Status !== 'Success') {
        this.showRrChartState = CHART_STATE_OPTIONS.ERROR;

        this.rrtData = null;
      }

      if ((rrPayload === null || rrPayload.Response === null || !(rrPayload.Response.Disposition && rrPayload.Response.Disposition.length))) {
        this.showRrChartState = CHART_STATE_OPTIONS.NO_DATA;

        this.rrtData = null;
      }

      if (rrPayload.Response && rrPayload.Status == 'Success') {
        // this.getRRTRollingData(filterObj);
        this.rrtData = rrPayload.Response;
        if (this.appendRRTRollingPayload) {
          this.rrtRollingData = this.rrtRollingPayload.Response;
        }
        this.parseRrtData(this.viewPeriod);

        this.showRrChartState = CHART_STATE_OPTIONS.SUCCESS;
      }

    },
      (err) => {
        this.showRrChartState = CHART_STATE_OPTIONS.ERROR;

        this.rrtData = null;
        this.rrtRollingData = null;
      });
      this.getRRTRollingData(filterObj);
  }
  getRRTRollingData(filterObj: any | null) {
    this.nntRollingData = null;
    //make api call for nps rolling data
    this.chartDataService.getRRRollingTrendData(filterObj).subscribe(rrRollingRes => {
      let rrRollingPayload: any = rrRollingRes;

      if (rrRollingPayload === null || rrRollingPayload.Response === null || (rrRollingPayload.Response.Month.length === 0 && rrRollingPayload.Response.Year.length === 0)) {
        this.rrtRollingData = null;
        return;
      }

      if (rrRollingPayload !== null && rrRollingPayload.Status !== 'Success') {
        this.rrtRollingData = null;
        return;
      }

      if (rrRollingPayload.Response && rrRollingPayload.Status === 'Success') {
        if (this.showRrChartState === CHART_STATE_OPTIONS.LOADING) {
          this.rrtRollingPayload = rrRollingPayload;
          this.appendRRTRollingPayload = true;
        } else if (this.showRrChartState === CHART_STATE_OPTIONS.SUCCESS) {
          this.rrtRollingData = rrRollingPayload.Response;
          this.parseRrtData(this.viewPeriod);
        } else {
          this.rrtRollingData = null;
        }
      }
    },
      (err) => {
        this.rrtData = null;
        this.rrtRollingData = null;
      })
  }

  /**
   * Get RRT chart data from API
   * @param filterObj optional global filter object
   */
  getNNTChartData(filterObj: any | null, comparisonType: string, npsNntRating: any) {
    this.showNntChartState = CHART_STATE_OPTIONS.LOADING;
    this.nntChartData = new ChartBindingsModel();
    this.nntData = null;
    this.nntRollingData = null;
    //make api call for response rate data
    this.chartDataService.getDualChart(filterObj,npsNntRating).subscribe(nntRes => {
      let nntPayload: any = nntRes;

      if (nntPayload !== null && nntPayload.Status !== 'Success') {
        this.showNntChartState = CHART_STATE_OPTIONS.ERROR;

        this.nntData = null;
        this.nntRollingData = null;
        return;
      }

      if (nntPayload === null || nntPayload.Response === null || nntPayload.Response.TotalRecords.length === 0) {
        this.showRrChartState = CHART_STATE_OPTIONS.NO_DATA;

        this.nntData = null;
        this.nntRollingData = null;
        return;
      }

      if (nntPayload.Response && nntPayload.Status == 'Success' ) {
        // this.getRRTRollingData(filterObj);
        // let SentimentalAnalysisBarChartQuarterModel = []
        this.nntData = nntPayload.Response.Collection[0];
        this.nntData.SentimentalAnalysisBarChartMonthModel = this.getSortedInput(this.addTimeObject(this.nntData.SentimentalAnalysisBarChartMonthModel, 'Month', true), 'date');
        let indexData = 0
        // this.nntData.SentimentalAnalysisBarChartMonthModel.forEach((element,index) => {
        //   if((this.nntData.SentimentalAnalysisBarChartMonthModel.length>= 1 && this.nntData.SentimentalAnalysisBarChartMonthModel.length<=2)  || (index +1) %3 ==0){
        //     if(indexData  > 3) {
        //       indexData = 0 
        //     }
        //     indexData++;
        //     let yearData = element.ViewAnalysisBarPeriod.split(' ')[1]
        //     if(index !=1) {
        //       let quarterlyModel = {
        //         NPS3MRT: element.NPS3MRT,
        //         NPS12MRT: element.NPS12MRT,
        //         NPSCurrentTrend: element.NPSCurrentTrend,
        //         NSS3MRT: element.NSS3MRT,
        //         NSS12MRT: element.NSS12MRT,
        //         NSSCurrentTrend: element.NSSCurrentTrend,
        //         ResponseReceived:element.ResponseReceived,
        //         Total3Month: element.Total3Month,
        //         ViewAnalysisBarPeriod: index == 0 && (this.nntData.SentimentalAnalysisBarChartMonthModel.length>= 1 && this.nntData.SentimentalAnalysisBarChartMonthModel.length<=2) ? 'Q1-'+ yearData : 'Q' +indexData +'-'+ yearData,
        //         ViewAnalysisType: 'Q'
        //       }
        //       // SentimentalAnalysisBarChartQuarterModel.push(quarterlyModel)
        //     }
            
        //   }
          
        // });
        // this.nntData = { ...this.nntData, SentimentalAnalysisBarChartQuarterModel: SentimentalAnalysisBarChartQuarterModel };
        this.parseNntData(this.nntView)
        

        this.showNntChartState = CHART_STATE_OPTIONS.SUCCESS;
      }

    },
      (err) => {
        this.showNntChartState = CHART_STATE_OPTIONS.ERROR;

        this.nntData = null;
        this.nntRollingData = null;
      });
      //this.getNNTRollingData(filterObj);
  }
  parseNntData(period) {
    let tempNNTChartModel: ChartBindingsModel = new ChartBindingsModel();
    let ViewAnalysisPeriod = 'SentimentalAnalysisBarChartMonthModel'
    if(period == 'Month') {
      ViewAnalysisPeriod = 'SentimentalAnalysisBarChartMonthModel'
    } else if(period == 'Year') {
      ViewAnalysisPeriod = 'SentimentalAnalysisBarChartYearModel'
    } else {
      ViewAnalysisPeriod = 'SentimentalAnalysisBarChartQuarterModel'
    }
      let responseRecievedData = []
      let NPS3MRT = [];
      let npsCurrentTrend = [];
      let nnsCurrentTrend = [];
      let nss12MRT = [];
      let nps12MRT = [];
      let NSS3MRT = [];
      let quarterModel = ['Mar','Jun','Sep','Dec'];
      this.nntData[ViewAnalysisPeriod].forEach((element,index) => {
        // if(period ==='Quarter') {
        //   responseRecievedData.push(element['Total3Month']) 
        // } else {
          const containsQuarter = quarterModel.some(quarter => element.ViewAnalysisBarPeriod.includes(quarter));
          let data = element['ResponseReceived']
          if(element['ViewAnalysisBarPeriod'].includes('Dec') && element['ViewAnalysisType'] === 'M') {
            data = {y:element['ResponseReceived'],color:'#9b9b9b'}
          }
          else if(containsQuarter && element['ViewAnalysisType'] === 'M') {
            // let dataVal = period ==='Quarter' ? element['Total3Month']: element['ResponseReceived']
            data = {y:element['ResponseReceived'],color:'#a8a8a8'}
          } else if( element['ViewAnalysisType'].includes('Q4')) {
            data = {y:element['ResponseReceived'],color:'#9b9b9b'}
          }
          responseRecievedData.push(data)
        npsCurrentTrend.push(element['NPSCurrentTrend'])
        nnsCurrentTrend.push(element['NSSCurrentTrend']) 
        //nnsCurrentTrend=[-3.29, -3.15, -2.41, -3.29, -2.95, 2.69, 3.26, -3.08, -3.26, -2.64, -3.39, -2.91, 3.19, -2.77, -2.87, -3.41, -3.43]
        nss12MRT.push(element['NSS12MRT'])
        nps12MRT.push(element['NPS12MRT'])
        NPS3MRT.push(element['NPS3MRT']);
        NSS3MRT.push(element['NSS3MRT'])
      });
      let chartData = [
        {
        name:'Response Received',
        placeholder:'ResponseReceived',
        stack:0,
        yAxis: 0,
        color:"#dddddd",
        type: 'column',
        data:responseRecievedData
      },
      {
        name:'NPS Current Trend',
        placeholder:'NPSCurrentTrend',
        stack:0,
        yAxis: 1,
        visible: false,
        dataLabels:{
            "enabled": true,
            "format": "{y}%",
            "style": {
                "fontFamily": "Source Sans Pro Regular",
                "textShadow": false,
                "textOutline": "false",
                "fontSize": "10px"
            }
        },
        color: '#0099CC',
        marker: {enabled: false},
        dashStyle: 'Dash',
        type: 'spline',
        data:npsCurrentTrend
      },
      {
        name:'NSS Current Trend',
        placeholder:'NSSCurrentTrend',
        stack:0,
        yAxis: 2,
        visible: false,
        dataLabels:{
            "enabled": true,
            "format": "{y}",
            "style": {
                "fontFamily": "Source Sans Pro Regular",
                "textShadow": false,
                "textOutline": "false",
                "fontSize": "10px"
            }
        },
        //color: '#0099CC',
        // marker: {enabled: true},
        type: 'areaspline',
        showInLegend:true,
        marker: {
          enabled: true,
          radius: 0
        },
        lineWidth: 0,
        states: {
          hover: {
            enabled: false,
            lineWidth: 0
          }
        },
        data:nnsCurrentTrend
      },
      {
        name:'NSS 12 MRT',
        placeholder:'NSS12MRT',
        stack:0,
        yAxis: 2,
        visible: false,
        dataLabels:{
            "enabled": true,
            "format": "{y}",
            "style": {
                "fontFamily": "Source Sans Pro Regular",
                "textShadow": false,
                "textOutline": "false",
                "fontSize": "10px"
            }
        },
        color: '#A16930',
        marker: {enabled: false},
        type: 'spline',
        data:nss12MRT
      },
      {
        name:'NPS 12 MRT',
        placeholder:'NPS12MRT',
        stack:0,
        yAxis: 1,
        dataLabels:{
            "enabled": true,
            "format": "{y}%",
            "style": {
                "fontFamily": "Source Sans Pro Regular",
                "textShadow": false,
                "textOutline": "false",
                "fontSize": "10px"
            }
        },
        color: '#0099CC',
        marker: {enabled: false},
        type: 'spline',     
        data:nps12MRT
      },
      {
        name:'NPS 3 MRT',
        placeholder:'NPS3MRT',
        stack:0,
        yAxis: 1,
        dataLabels:{
            "enabled": true,
            "format": "{y}%",
            "style": {
                "fontFamily": "Source Sans Pro Regular",
                "textShadow": false,
                "textOutline": "false",
                "fontSize": "10px"
            }
        },
        color: '#0099CC',
        marker: {enabled: false},
        type: 'spline',
        lineWidth: 0.5,
        states: {
          hover: {
              lineWidth: 0.5  // Set the line width on hover for NSS 3 MRT
          }
        },
        data:NPS3MRT
      },
      {
        name:'NSS 3 MRT',
        placeholder:'NSS3MRT',
        visible: false,
        stack:0,
        yAxis: 2,
        dataLabels:{
            "enabled": true,
            "format": "{y}",
            "style": {
                "fontFamily": "Source Sans Pro Regular",
                "textShadow": false,
                "textOutline": "false",
                "fontSize": "10px"
            }
        },
        color: '#A16930',
        marker: {enabled: false},
        type: 'spline',
        lineWidth: 0.5,
        states: {
          hover: {
              lineWidth: 0.5  // Set the line width on hover for NSS 3 MRT
          }
        },
        data:NSS3MRT
      },
    ]

    
    tempNNTChartModel.yAxis = [...chartData] 
    tempNNTChartModel.xAxis= [...ChartDataParsing.parseNntxAxis(period, this.nntData[ViewAnalysisPeriod])];
    this.nntChartData = tempNNTChartModel;
    this.isNNTRendered = true;
    //tempNNTChartModel.xAxis = [...tempNNTChartModel.yAxis,...chartData] 
  }

  BubbleLegendHide() {
    this.legendShow = !this.legendShow;
    this.selectedLegends.map(data=>{
      data.visible = this.legendShow
    })
    this.rscChartData = this.parseRscData();
  }
  /**
   * Parse RRT chart data
   * @param period show data by 'Month' or 'Year'
   */
  parseRrtData(period: string) {
    let data: ChartBindingsModel = new ChartBindingsModel();

    // add line chart data
    let exclusionProp = ["Period", "date"];
    if (this.rrtData && this.rrtData.Disposition && this.rrtData.Disposition.length) {
      let dat = this.addTimeObject(this.rrtData.Disposition, period, true);
      this.rrtData.Disposition = this.getSortedInput(dat, 'date');
      data.yAxis = [...ChartDataParsing.parseRrtBarData(period, this.rrtData)];
      data.xAxis = [...ChartDataParsing.parseRrtxAxis(period, this.rrtData)];
    }

    if (this.rrtRollingData && this.rrtRollingData[period]) {
      this.rrtRollingData[period] = this.getSortedInput(this.addTimeObject(this.rrtRollingData[period], period), 'date');
      data.yAxis = [...data.yAxis, ...ChartDataParsing.parseRrtLineData(period, exclusionProp, this.rrtRollingData)];
    }
    data['Title'] = '';
    data.chartRawData = {...data};
    this.rrtChartData = data;
    this.isRRTRendered = true;
  }


 async getRscData(filterObj: any | null,npsFilter: any) {
    this.showRscChartState = CHART_STATE_OPTIONS.LOADING;
    this.rscChartData = ''
    this.rscData = null;
    this.rscRollingData = null;
    //this.showRscChartState = CHART_STATE_OPTIONS.SUCCESS;
    // make api call for ssr data
    await this.chartDataService.getRSCChartData(filterObj, npsFilter).subscribe(rscRes => {
      let rscPayload: any = rscRes;

      if (rscPayload !== null && rscPayload.Status !== 'Success') {
        this.showRscChartState = CHART_STATE_OPTIONS.ERROR;

        this.rscData = null;
         this.rscRollingData = null;
         this.rscShowData = null;
        return;
      }

      if (rscPayload === null || rscPayload.Response === null || (rscPayload.Response && rscPayload.Response.TotalRecords === 0) ) {
        this.showRscChartState = CHART_STATE_OPTIONS.NO_DATA;

        this.rscData = null;
        this.rscRollingData = null;
        this.rscShowData = null;
        return;
      }

      if (rscPayload.Response && rscPayload.Status == 'Success') {
        this.rscData = rscPayload.Response.Collection;
        this.rscShowData = rscPayload.Response.Collection;
        if(this.rscData && this.rscData.length > 0){
          this.rscData.map((data=> {
            const touchPointCode = data.TouchPointCode;
            const exists = this.selectedLegends.some(legend => legend.name === touchPointCode);
            if (!exists) {
              this.selectedLegends.push({ name: touchPointCode, visible: true });
            }
          }))
        }
          
       this.rscChartData = this.parseRscData();

        this.showRscChartState = CHART_STATE_OPTIONS.SUCCESS;
        this.isRSCRendered = true;
      }
    },
      (err) => {
        this.showRscChartState = CHART_STATE_OPTIONS.ERROR;

        this.rscData = null;
        this.rscRollingData = null;
        this.rscShowData = null
      });
  }

  async getNnsData(filterObj: any | null,npsFilter: any) {
    this.showNnsChartState = CHART_STATE_OPTIONS.LOADING;
    this.nnsChartData = ''
    this.nnsData = null;
    this.nnsRollingData = null;
    //this.showRscChartState = CHART_STATE_OPTIONS.SUCCESS;
    // make api call for ssr data
    await this.chartDataService.getGcFeedback(filterObj,npsFilter).subscribe(nnsRes => {
      let nnsPayload: any = nnsRes;

      if (nnsPayload !== null && nnsPayload.Status !== 'Success') {
        this.showNnsChartState = CHART_STATE_OPTIONS.ERROR;

        this.nnsData = null;
         this.nnsRollingData = null;
        return;
      }

      if (nnsPayload === null || nnsPayload.Response === null) {
        this.showNnsChartState = CHART_STATE_OPTIONS.NO_DATA;

        this.nnsData = null;
        this.nnsRollingData = null;
        return;
      }

      if (nnsPayload.Response && nnsPayload.Status == 'Success') {
        this.nnsData = nnsPayload.Response;
       this.nnsChartData = this.nnsData
       this.nnsRollingData = null;
        this.showNnsChartState = CHART_STATE_OPTIONS.SUCCESS;

      }
    },
      (err) => {
        this.showNnsChartState = CHART_STATE_OPTIONS.ERROR;

        this.nnsData = null;
        this.nnsRollingData = null;
      });
  }

  async getScData(filterObj: any | null,npsFilter: any) {
    this.showScChartState = CHART_STATE_OPTIONS.LOADING;
    this.scChartData = ''
    this.scData = null;
    this.scRollingData = null;
    //this.showRscChartState = CHART_STATE_OPTIONS.SUCCESS;
    // make api call for ssr data
    await this.chartDataService.getSpiderWebchartData(filterObj,npsFilter).subscribe(scRes => {
      let scPayload: any = scRes;

      if (scPayload !== null && scPayload.Status !== 'Success') {
        this.showScChartState = CHART_STATE_OPTIONS.ERROR;

        this.scData = null;
         this.scRollingData = null;
        return;
      }

      if (scPayload === null || scPayload.Response === null || scPayload.Response.TotalRecords==0) {
        this.showScChartState = CHART_STATE_OPTIONS.NO_DATA;

        this.scData = null;
        this.scRollingData = null;
        return;
      }

      if (scPayload.Response && scPayload.Status == 'Success') {
        this.scData = scPayload.Response.Collection;
       this.scChartData = this.scData
       this.scRollingData = null;
        this.showScChartState = CHART_STATE_OPTIONS.SUCCESS;
        this.isSCRendered = true;
      }
    },
      (err) => {
        this.showScChartState = CHART_STATE_OPTIONS.ERROR;

        this.scData = null;
        this.scRollingData = null;
      });
  }

  async getWcData(filterObj: any | null,NpsScore: any | '') {
    /* this.showWcChartState = CHART_STATE_OPTIONS.LOADING;
    this.wcData = null;
    this.wcRollingData = null;
    this.wcChartData = null;
    //this.showRscChartState = CHART_STATE_OPTIONS.SUCCESS;
    // make api call for ssr data
    await this.chartDataService.getwordCloud(filterObj,NpsScore).subscribe(wcRes => {
      let wcPayload: any = wcRes;

      if (wcPayload !== null && wcPayload.Status !== 'Success') {
        this.showWcChartState = CHART_STATE_OPTIONS.ERROR;

        this.wcData = null;
        this.wcRollingData = null;
        return;
      }

      if (wcPayload === null || wcPayload.Response === null || wcPayload.Response.TotalRecords==0) {
        this.showWcChartState = CHART_STATE_OPTIONS.NO_DATA;

        this.wcData = null;
        this.wcRollingData = null;
        return;
      }

      if (wcPayload.Response && wcPayload.Status == 'Success') {
        this.wcData = wcPayload.Response.Collection;
        let finalData = [];
        this.wcData.forEach(element => {
          let obj = {
            name: element.Phrase,
            weight:
              element.Total_Count,
            color: '#054E5A',//element.Total_Count % 3 === 0  || element.Total_Count <=1 ? '#E1B77E' : '#054E5A', // Use conditional to set the color
            className: 'data-font'
          };

          finalData.push(obj)
        });
       this.wcChartData = finalData
       this.wcRollingData = null;
       this.isWCRendered = true;
        this.showWcChartState = CHART_STATE_OPTIONS.SUCCESS;

      }
    },
      (err) => {
        this.showWcChartState = CHART_STATE_OPTIONS.ERROR;

        this.wcData = null;
        this.wcRollingData = null;
      }); */
  }

  /**
   * Get Survey Sent vs Received data from API
   * @param filterObj optional global filter object
   */
  getSsrData(filterObj: any | null) {
    this.showSsrChartState = CHART_STATE_OPTIONS.LOADING;
    this.ssrChartData = new ChartBindingsModel();
    this.ssrData = null;
    this.ssrRollingData = null;
    // make api call for ssr data
    this.chartDataService.getSurveySentResponseRecievedChart(filterObj).subscribe(ssrRes => {
      let ssrPayload: any = ssrRes;

      if (ssrPayload !== null && ssrPayload.Status !== 'Success') {
        this.showSsrChartState = CHART_STATE_OPTIONS.ERROR;

        this.ssrData = null;
        this.ssrRollingData = null;
        return;
      }

      if (ssrPayload === null || ssrPayload.Response === null || (ssrPayload.Response.Month.length === 0 && ssrPayload.Response.Year.length === 0)) {
        this.showSsrChartState = CHART_STATE_OPTIONS.NO_DATA;

        this.ssrData = null;
        this.ssrRollingData = null;
        return;
      }

      if (ssrPayload.Response && ssrPayload.Status == 'Success') {
        this.ssrData = ssrPayload.Response;
        this.parseSsrData(this.viewPeriod);

        this.showSsrChartState = CHART_STATE_OPTIONS.SUCCESS;
        this.getSSRRollingData(filterObj);
      }
    },
      (err) => {
        this.showSsrChartState = CHART_STATE_OPTIONS.ERROR;

        this.ssrData = null;
        this.ssrRollingData = null;
      });
  }
  getSSRRollingData(filterObj: any | null) {
    // make api call for ssr rolling trend data
    this.chartDataService.getSurveySentResponseRecievedTrendChart(filterObj).subscribe(ssrRollingRes => {
      let ssrRollingPayload: any = ssrRollingRes;

      if (ssrRollingPayload === null || ssrRollingPayload.Response === null || (ssrRollingPayload.Response.Month.length === 0 && ssrRollingPayload.Response.Year.length === 0)) {
        this.ssrRollingData = null;
        return;
      }

      if (ssrRollingPayload !== null && ssrRollingPayload.Status !== 'Success') {
        this.ssrData = null;
        this.ssrRollingData = null;
        return;
      }

      if (ssrRollingPayload.Response && ssrRollingPayload.Status === 'Success') {
        this.ssrRollingData = ssrRollingPayload.Response;
        this.parseSsrData(this.viewPeriod);
      }
    },
      (err) => {
        this.showSsrChartState = CHART_STATE_OPTIONS.ERROR;

        this.ssrData = null;
        this.ssrRollingData = null;
      });
  }

  parseRscData() {
    this.rscShowData = this.rscData;
    if(this.selectedLegends && this.selectedLegends.length > 0) {
      const allVisibleFlagsFalse = this.selectedLegends.every(legend => legend.visible === false);
      if(allVisibleFlagsFalse) {
        this.legendShow = false
      }
      const allVisibleFlagsTrue = this.selectedLegends.every(legend => legend.visible === true);
      if(allVisibleFlagsTrue) {
        this.legendShow = true
      }
      this.rscShowData = []
      this.selectedLegends.forEach(element => {

        let isExist = this.rscData.find(data=> data.TouchPointCode === element.name && element.visible == true)
        if(isExist) {
          this.rscShowData.push(isExist);
        }
      });
     
    } 
    if(this.rscData.length > 0) {
      let seriesData = []
      let revenuWiseDetails = []
      let allSymbolsAreSame = 0;
      let firstSymbol =  this.currenyView === 'local' ? this.rscData[0].BubbleChartDetails[0].UserLocalCurrencySymbol:  this.currenyView == "famLocal" ? this.rscData[0].BubbleChartDetails[0].FAMLocalCurrencySymbol : this.rscData[0].BubbleChartDetails[0].EURSymbol;
      this.rscData.map(element1 => {
       
        if( this.currenyView === 'local' ||  this.currenyView == "famLocal") {
          for(let i=0;i<element1.BubbleChartDetails.length;i++) {
            let chcekSymbol = this.currenyView === 'local' ? element1.BubbleChartDetails[i].UserLocalCurrencySymbol:  element1.BubbleChartDetails[i].FAMLocalCurrencySymbol
            if (chcekSymbol !== firstSymbol) {
              allSymbolsAreSame += 1
            }
          }
        }
       
      })
      this.symboltoShow = allSymbolsAreSame == 0 ? firstSymbol :'€'
      this.rscData.map((element,index) => {
        
        
        
        let bubbleData = []
        let isExist = this.rscShowData.find(data=> data.TouchPointCode === element.TouchPointCode) 
          element.BubbleChartDetails.map(data => {
            let isAccExist = null
            let revenueToShow = this.currenyView === 'local' ? parseFloat(data.UserLocalRevenue) :  this.currenyView === "famLocal" ? parseFloat(data.FAMLocalRevenue) :this.currenyView === "euro"? parseFloat(data.Revenue): parseFloat(data.Revenue);
            let revenue = revenueToShow
            if(allSymbolsAreSame > 0) {
              revenue = parseFloat(data.Revenue);
            }
            // if(legendUnclicked && legendUnclicked.length > 0) {
              
              if(isExist) {
                if(revenuWiseDetails.length > 0)
                  isAccExist = revenuWiseDetails.findIndex(elem=> elem.AccountNumber == data.Customer_Account_Number)
                // else
                  // revenuWiseDetails.push({AccountNumber:data.Customer_Account_Number, revenue:revenue,score:data.NSSScore})
                // let revenuesymbol = revenuWiseDetails.map(elem=> elem.revenuesymbol ! = symbolToShow)
                if(isAccExist==-1 || isAccExist == null) {
                  revenuWiseDetails.push({AccountNumber:data.Customer_Account_Number, revenue:revenue,score:data.NSSScore})
                }
              }
            //  else {
            //   if(revenuWiseDetails.length > 0)
            //   isAccExist = revenuWiseDetails.find(elem=> elem.AccountNumber == data.Customer_Account_Number)
            //   else
            //   revenuWiseDetails.push({AccountNumber:data.Customer_Account_Number, revenue:revenue})
            //   // let revenuesymbol = revenuWiseDetails.map(elem=> elem.revenuesymbol ! = symbolToShow)
            //   if(!isAccExist) {
            //     revenuWiseDetails.push({AccountNumber:data.Customer_Account_Number, revenue:revenue})
            //   }
            // }
            let bubbleDataObj = {
              x: data.NPSScore,
              y: data.NSSScore,
              z: revenueToShow,
              name: data.Customer_Name,
              Id: data.Customer_Account_Number
            }
          bubbleData.push(bubbleDataObj)
          });
          let isVisibleprop = this.selectedLegends.find(data=> data.name === element.TouchPointCode).visible
          let obj =  {
            keys: ['x', 'y', 'z', 'name'],
            name: element.TouchPointCode,
            id: element.Description,
            value:element.Description,
            type: 'bubble',
            data: bubbleData,
            visible: isVisibleprop,
            marker: this.getMarkerData(element.TouchPointCode)
          }
          seriesData.push(obj)
        });
       
        let revenueData= 0;
        let revenueAtRisk = 0;
        revenuWiseDetails.forEach(element => {
          revenueData += element.revenue
          if(element.score <0) {
            revenueAtRisk += element.revenue
          }
        });
        this.hasAtLeastOneZeroRevenue = revenuWiseDetails.some(item => item.revenue === 0);
        this.allHaveZeroRevenue = revenuWiseDetails.every(item =>  item.Revenue === 0);
        if(revenueData > 0)
          this.totalRevenueToShow = revenueData.toFixed(3);
        else
          this.totalRevenueToShow = revenueData;
        if(revenueAtRisk > 0)
          this.totalRevenueRiskToShow = revenueAtRisk.toFixed(3);
        else
          this.totalRevenueRiskToShow = revenueAtRisk;
        
      return seriesData;
    }
  }


  getMarkerData(touchPoint){
    if(touchPoint === 'U'){
      return{
        lineColor: 'None',
        fillColor: {
          radialGradient: {
            cx: 0.7,
            cy: 0.3,
            r: 0.4
          },
          stops: [
            [0, 'rgba(255,255,255,0.75)'],
            [1, 'rgba(49,188,235,0.5)']
          ]
        }
      }
    } else if(touchPoint === 'L') {
      return{
          lineColor: 'None',
          fillColor: {
            radialGradient: {
              cx: 0.4,
              cy: 0.3,
              r: 0.7
            },
            stops: [
              [0, 'rgba(255,255,255,0.75)'],
              [1, 'rgba(0,93,169,0.6)']
            ]
          }
        }
    } else if(touchPoint === 'P') {
      return{
          lineColor: 'None',
          fillColor: {
            radialGradient: {
              cx: 0.7,
              cy: 0.3,
              r: 0.4
            },
            stops: [
              [0, 'rgba(255,255,255,0.75)'],
              [1, 'rgba(114,33,123,0.5)']
            ]
          }
        }
    } else if(touchPoint === 'S') {
      return{
          lineColor: 'None',
          fillColor: {
            radialGradient: {
              cx: 0.4,
              cy: 0.3,
              r: 0.7
            },
            stops: [
              [0, 'rgba(255,255,255,.75)'],
              [1, 'rgba(46,168,54,0.5)']
            ]
          }
        }
    } else if(touchPoint === 'W') {
      return{
          lineColor: 'None',
          fillColor: {
            radialGradient: {
              cx: 0.4,
              cy: 0.3,
              r: 0.7
            },
            stops: [
              [0, 'rgba(255,255,255,.75)'],
              [1, 'rgba(255,232,171,0.5)']
            ]
          }
        }
    } else if(touchPoint === 'F') {
      return{
          lineColor: 'None',
          fillColor: {
            radialGradient: {
              cx: 0.4,
              cy: 0.3,
              r: 0.7
            },
            stops: [
              [0, 'rgba(255,255,255,.75)'],
              [1, 'rgba(192,163,201,0.5)']
            ]
          }
        }
    } else if(touchPoint === 'PoJ') {
      return{
          lineColor: 'None',
          fillColor: {
            radialGradient: {
              cx: 0.4,
              cy: 0.3,
              r: 0.7
            },
            stops: [
              [0, 'rgba(255,255,255,.75)'],
              [1, 'rgba(192,163,201,0.5)']
            ]
          }
        }
    }else {
      return{
        lineColor: 'None',
        fillColor: {
          radialGradient: {
            cx: 0.4,
            cy: 0.3,
            r: 0.7
          },
          stops: [
            [0, 'rgba(255,255,255,.75)'],
            [1, 'rgba(192,163,201,0.5)']
          ]
        }
      }
    }
  }

  onCurrencyChange(event) {
    this.currenyView = event.value
    if(this.currenyView === this.currenyViewDuplicate) {
      return;
    } else {
            this.currenyViewDuplicate == this.currenyView
    }
    this.rscChartData = this.parseRscData();
  }
  /**
   * parse Ssr chart data
   * @param period show data by 'Month' or 'Year
   */
  parseSsrData(period: string) {
    this.ssrData[period] = this.getSortedInput(this.addTimeObject(this.ssrData[period], period), 'date');
    // exclude these in bar plotting
    let stackedBarColumnExclude = ["Period", "date"], stackedLineExclude = ["Period", "date"];
    let tempSsrChartData: ChartBindingsModel = new ChartBindingsModel();
    // add line chart data

    if (this.ssrData && this.ssrData[period]) {
      this.ssrData[period] = this.getSortedInput(this.addTimeObject(this.ssrData[period], period), 'date');
      ChartDataParsing.parseSsrBarData(period, stackedBarColumnExclude, this.ssrData).forEach(item => tempSsrChartData.yAxis.push(Object.assign({}, item)));

      ChartDataParsing.parseSsrxAxis(period, this.ssrData).forEach(item => tempSsrChartData.xAxis.push(item));
    }

    if (this.ssrRollingData && this.ssrRollingData[period]) {
      this.ssrRollingData[period] = this.getSortedInput(this.addTimeObject(this.ssrRollingData[period], period), 'date');
      ChartDataParsing.parseSsrLineData(period, stackedLineExclude, this.ssrRollingData).forEach(item => tempSsrChartData.yAxis.push(Object.assign({}, item)));
    }

    tempSsrChartData['Title'] = '';
    this.ssrChartData = tempSsrChartData;
    // ChartDataParsing.saveParsedDataToSessionStorage(this.chart.ssrr, this.ssrChartData);
    this.isSSRRendered = true;
  }

  /**
   * Get PND comparision data from API
   * @param filterObj optional global filter object
   * @param comparisonType optional comparison type API parameter
   * @param compareWith optional comparison type API parameter
   * @param entityId optional Entity Id from original response
   * @param isDrilldown optional flag to indicate drilldown API
   */
  getPNDData(filterObj: any | null, comparisonType: string, compareWith?: string, entityId?: number, isDrilldown?: boolean, isDeepDrilldown?: boolean) {
    // this.isPndContextMenuVisibile = isDrilldown ? true : false;
    this.ccRollingData = isDrilldown ? null : this.ccRollingData;
    this.showCcChartState = CHART_STATE_OPTIONS.LOADING;
    this.ccChartData = new ChartBindingsModel();
    this.isCcDataLoading = true;
    this.ccData = null;
    this.ccRollingData = null;
    this.pndIsDrilldownEnabled = false;

    // TODO: add appropriate params
    // make api call for PND comparision data
    this.chartDataService.getPNDComparison(filterObj, comparisonType, compareWith, entityId, isDrilldown, this.zoneDrillDownEntityID, this.branchDrillDownEntityID, this.sestDrillDownEntityID)
      .subscribe(pndRes => {
        let pndResPayload: any = pndRes;

        if (pndResPayload !== null && pndResPayload.Status !== 'Success') {
          this.showCcChartState = CHART_STATE_OPTIONS.ERROR;
          this.isCCRendered = false;
          this.ccData = null;
          this.ccRollingData = null;
          this.isCcDataLoading = false;
          return;
        }

        if (pndResPayload === null || pndResPayload.Response === null || pndResPayload.Response.length === 0) {
          this.showCcChartState = CHART_STATE_OPTIONS.NO_DATA;
          this.isCCRendered = false;
          this.ccData = null;
          this.ccRollingData = null;
          this.isCcDataLoading = false;
          return;
        }

        if (pndResPayload !== null && pndResPayload.Response !== null && pndResPayload.Status == 'Success') {
          this.ccData = pndResPayload.Response;
          this.parsePNDData('Entity', 'EntityName');

          this.showCcChartState = CHART_STATE_OPTIONS.SUCCESS
          this.getPNDRollingData(filterObj, comparisonType, compareWith, entityId, isDrilldown, isDeepDrilldown);
          this.isCcDataLoading = false;
        }

        this.contextMenuVisibility();
        // this.isDeepDrillDown = false;
      },
        err => {
          this.showCcChartState = CHART_STATE_OPTIONS.ERROR;
          this.isCCRendered = false;
          this.ccData = null;
          this.ccRollingData = null;
          this.isCcDataLoading = false;
          this.isDeepDrillDown = false;
        });
  }
  getPNDRollingData(filterObj: any | null, comparisonType: string, compareWith?: string, entityId?: number, isDrilldown?: boolean, isDeepDrilldown?: boolean, ) {
    this.isCcTrendLoading = true;
    // TODO: add appropriate params
    this.chartDataService.getPNDRollingComparison(filterObj, comparisonType, compareWith, entityId, isDrilldown, this.zoneDrillDownEntityID, this.branchDrillDownEntityID, this.sestDrillDownEntityID)
      .subscribe(pndRollingRes => {
        let pndRollingPayload: any = pndRollingRes;
        // this.allowContextMenuEnablement();
        this.contextMenuVisibility();
        this.parsePNDData('Entity', 'EntityName');
        if (pndRollingPayload === null || pndRollingPayload.Response === null || pndRollingPayload.Response.length === 0) {
          this.ccRollingData = null;
          this.isCcTrendLoading = false;
          return;
        }

        if (pndRollingPayload !== null && pndRollingPayload.Status !== 'Success') {
          this.ccRollingData = null;
          this.isCcTrendLoading = false;
          return;
        }

        if (pndRollingPayload !== null && pndRollingPayload.Response !== null && pndRollingPayload.Status === 'Success') {
          this.ccRollingData = pndRollingPayload.Response;
          this.parsePNDData('Entity', 'EntityName');
          this.isCcTrendLoading = false;
        }
      },
        err => {
          this.contextMenuVisibility();
          this.ccRollingData = null;
          this.isCcTrendLoading = false;
          this.isDeepDrillDown = false;
        });
  }
  /**
   * Parse PND chart data
   * @param period property name from response to grab data and parese from
   * @param xAxisLabel X Axis Label property name to extract from response
   */
  parsePNDData(period: string, xAxisLabel: string) {
    let stackedBarColumnExclude = ["ChartType", "EntityID", "EntityName", "NpsCnt", "TooltipCntry","TooltipOperationalCntry", "TooltipFAMCode", "TooltipBrand", "RegionName", "SalesEngineer", "ServiceBranch", "ServiceTechnician", "DistributorManagerSales", "DistributorManagerServices","ProjectManager"],
      lineChartExclude = ["ChartType", "EntityID", "EntityName"];
    let tempCcChartData: ChartBindingsModel = new ChartBindingsModel();

    if (this.ccData && this.ccData[period]) {
      // this.ccData[period] = this.getSortedInput(this.addTimeObject(this.ccData[period], period), 'date');
      ChartDataParsing.parsePndBarData(period, stackedBarColumnExclude, this.ccData).forEach(item => tempCcChartData.yAxis.push(Object.assign({}, item)));

      ChartDataParsing.parsePndxAxis(period, this.ccData).forEach(item => tempCcChartData.xAxis.push(item));
    }

    if (this.ccRollingData && this.ccRollingData[period]) {
      // this.ccRollingData[period] = this.getSortedInput(this.addTimeObject(this.ccRollingData[period], period), 'date');
      ChartDataParsing.parsePndLineData(period, lineChartExclude, this.ccRollingData).forEach(item => tempCcChartData.yAxis.push(Object.assign({}, item)));
    }
    tempCcChartData.currentChartView = this.pndView;
    tempCcChartData.chartRawData = this.ccData;
    this.ccChartData = JSON.parse(JSON.stringify(tempCcChartData));
    // ChartDataParsing.saveParsedDataToSessionStorage(this.chart.cc, this.ccChartData);
    this.isCCRendered = true;
    // }
  }

  /**
   * Utility method to add Date object to the input provided; returns new data object with Date
   * @param data data to add date object to
   * @param timeParam grab input param from response
   */
  addTimeObject(data: any[], timeParam, dateParam?, checkForAppendStr = false) {
    let str;
    if (timeParam === 'Month')
      str = 'MMM YYYY';
    else if (timeParam === 'Year')
      str = 'YYYY';
    else if (timeParam === 'Quarter') {
      str = 'MMM YYYY';
    } else if (timeParam === 'HalfYearly') {
      str = 'MMM YYYY';
    }
    let _this = this;
    data.forEach(item => {
      if (checkForAppendStr) {
        if (timeParam === 'Quarter' || timeParam === 'HalfYearly') {
          item.Period = item.Number + '-' + item.Period;
        }
      }
      item['date'] = moment(dateParam ? item.ViewAnalysisBarPeriod : _this.getPeriodData(timeParam, item.Period), str).toDate()
    });
    return JSON.parse(JSON.stringify(data));
  }
  getPeriodData(timeParam = '', period = '') {
    let res = period;
    if (timeParam === 'Quarter') {
      let strData = period.split('-');
      if (strData[0] === 'Q1') {
        res = 'Jan ' + strData[1];
      } else if (strData[0] === 'Q2') {
        res = 'Apr ' + strData[1];
      } else if (strData[0] === 'Q3') {
        res = 'Jul ' + strData[1];
      } else if (strData[0] === 'Q4') {
        res = 'Oct ' + strData[1];
      }
    } else if (timeParam === 'HalfYearly') {
      let strData = period.split('-');
      if (strData[0] === 'H1') {
        res = 'Jan ' + strData[1];
      } else if (strData[0] === 'H2') {
        res = 'Jul ' + strData[1];
      }
    } 
    return res;
  }
  /**
   * Sorting the received data in ascending as per date; returns sorted data
   * @param data data to run sorting over
   * @param sortByProp property name to sun sorting over
   */
  getSortedInput(data: any[], sortByProp: string) {
    data.sort(function (a, b) {
      let keyA = a[sortByProp],
        keyB = b[sortByProp];
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return data;
  }


  getWidgetSort(data: any[], sortByProp: string) {
    data.sort(function (a, b) {
      let keyA = parseInt(a.Position.slice(1)); // Extract the numerical part and convert to an integer
      let keyB = parseInt(b.Position.slice(1));
      
      // Compare the numeric values
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return data;
  }
  
  /**
   * Utility method; return true if that chart should be shown
   * @param chartIdentifier chart alias name to check visibility against
   */
  showChart(chartIdentifier: string): boolean {
    let visibilityData = this.widgetOrderReceived.find(a => a.WidgetCode.toLowerCase() === chartIdentifier.toLowerCase())
    let visibility = visibilityData? visibilityData.IsVisible: false;
    return visibility;
  }
  /**
   * Return entity ID for the respective entityName for PND data for drilldowns
   * @param entityName entityName to get the respective entityId from
   */
  getEntityIdForPNDData(entityName): number {
    if (this.ccData)
      if (this.ccData.length === 0) {
        return null;
      }
    return parseInt(this.ccData.Entity.filter(item => (item.EntityName.toLowerCase() === entityName.toLowerCase()))[0].EntityID);
  }
  closeDiv() {
    this.flipToDeafult.emit({ defaultView: this.currentView });
  }
  trackMouse($event) {
    this.pagex = $event.clientX;
    this.pagey = $event.offsetY;
  }
  enableClick($event) {
    this.inLimit = ($event.type == 'mouseover') ? true : false;
    if (this.inLimit) {
      this.pagex = $event.clientX;
      this.pagey = $event.offsetY;
      this.imagedisplay = "visible"
    } else {
      this.imagedisplay = "hidden";
    }
  }
  /**
   * Set default widgetOrderReceived order & visibility
   */
  setDefaultWidgetPos() {
    this.widgetOrderReceived = ChartDataParsing.getDefaultWidgetPos();
  }
  resetL2DrillDownEntityID() { this.zoneDrillDownEntityID = -1; }
  resetL3DrillDownEntityID() { this.branchDrillDownEntityID = -1; }
  resetL4DrillDownEntityID() { this.sestDrillDownEntityID = -1; }
  resetL1L2ForDrilldowns() { this.resetL2DrillDownEntityID(); this.resetL3DrillDownEntityID(); this.resetL4DrillDownEntityID() }
  // shiftL1ToL2() {
  //   this.l2DrillDownEntityID = this.l1DrillDownEntityID;
  //   this.resetL1DrillDownEntityID();
  // }
  resetDrilldowns() {
    let lmnt: StackBarChartComponent | undefined = this.getStackBarChartInstanceFor(this.chart.cc.toLowerCase());
    if(lmnt)
      lmnt.resetDrilldownView(this.pndView);

    this.resetL1L2ForDrilldowns();
    this.isDeepDrillDown = false;
    this.chartDataService.drillDownState.setDropDownViewState(DD_STATE.L1);
    this.previousViewBy = new String(this.pndView).trim();
    this.pndIsDrilldownEnabled = true;
  }
  getStackBarChartInstanceFor(chartIdentifier: string): StackBarChartComponent | undefined {
    let lmnt: StackBarChartComponent | undefined = undefined;
    if(this.children && this.children['_results']) {
      lmnt = this.children['_results'].filter(i => i.chartIdentifier === chartIdentifier.toLowerCase())[0];
    }
    return lmnt ? lmnt : undefined;
  }
  contextMenuVisibility() {
    this.pndIsDrilldownEnabled = !(this.chartDataService.drillDownState.getDropDownViewState() === DD_STATE.COMMON);
  }
  refreshWidget(widgetKey : string) {
    let refreshData = {
      refresh: true
    };
    if (widgetKey) {
      refreshData[widgetKey] = true;
    }
    this.chartDataService.setRefreshWidgetData(refreshData);
  }
  onResetSelect(data) {
    if(data ==='rsc'){
      this.rscNpsFilter = []
      this.getRscData(this.globalFilterData,[])
    } else if(data === 'nss') {
      this.nssNpsFilter = []
      this.getNnsData(this.globalFilterData,[])
    } else if(data === 'nnt') {
      this.nntNpsFilter = []
      this.getNNTChartData(this.globalFilterData,this.nntView,[])
    }  else if(data === 'sc') {
      this.scNpsFilter = []
      this.getScData(this.globalFilterData,[])
    }  else if(data === 'swc') {
      this.wcNpsFilter = []
      this.getWcData(this.globalFilterData,[])
    }  
  }
}
