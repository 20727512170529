import { Component } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-ul-li-item',
  templateUrl: './header-ul-li-item.component.html',
  styleUrls: ['./header-ul-li-item.component.scss'],
  inputs: ['listData', 'index', 'topheader']
})
export class HeaderUlLiItemComponent {
  console = console;
  public listData: any;
  public index: any;
  public topheader: string;
  constructor(private headerService: HeaderService, public router: Router) {
    
  }

  checkProp(listData) {
    if (listData.hasOwnProperty('routerLink')) {
      return true;
    }
    return false;
  }

  listClick(event, urlValue) {

    this.submenuSelected();
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.router.navigate([urlValue]);
      this.headerService.selectLiElement.next();
    }, 0);
   
    var menubar = document.getElementById("acNavbar");
    var menuButton = document.getElementById("navbarButton");
    var dropdownBox =document.getElementById("dropdown-area");
    if (menubar)
      menubar.classList.toggle('show');
    menuButton.setAttribute('aria-expanded','false');
    if (dropdownBox)
      dropdownBox.classList.toggle('show');
   

  }

  
isActiveRoute(routerLink: string): boolean {
  if(routerLink ==='/ctls/360/distributors') {
    return this.router.isActive('/ctls/360/customers?isDistributor=true',true) ||  this.router.isActive('/ctls/360/distributors',true)
  } else { 
    return this.router.isActive('/ctls/360/customers', true)
  } 
}
  /* Mega menu - remove active under line */
  submenuSelected() {
    this.headerService.selectLiElement.next();
  }
}
