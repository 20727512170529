import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
   name: 'formatNumber'
})
export class NumberFormatPipe implements PipeTransform {
   transform(num: any, fixed: any): any {
    num = parseInt(num);
    fixed = parseInt(fixed);
    if (num === null) { return null; } 
    if (num === 0) { return '0'; } 
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
      
   }
}