import { CtlsCommentPopoverComponent } from './presentation/ctls-comment-popover/ctls-comment-popover.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CtlsTaskListingComponent } from './container/ctls-task-listing/ctls-task-listing.component';
import { CtlsCreateEditTaskPopupComponent } from './presentation/ctls-create-edit-task-popup/ctls-create-edit-task-popup.component';
import { CtlsTaskListingTableComponent } from './presentation/ctls-task-listing-table/ctls-task-listing-table.component';
import { CtlsBulkUpdatePopupComponent } from './presentation/ctls-bulk-update-popup/ctls-bulk-update-popup.component';
import { CtlsViewSurveyDetailsComponent } from './presentation/ctls-view-survey-details/ctls-view-survey-details.component';
import { CtlsAddAssignComponent } from './presentation/ctls-add-assign/ctls-add-assign.component';

const routes: Routes = [
  {
    path: 'list',
    component: CtlsTaskListingComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CtlsTaskListingRoutingModule {
  static components = [
    CtlsTaskListingComponent,
    CtlsTaskListingTableComponent,
    CtlsCreateEditTaskPopupComponent,
    CtlsBulkUpdatePopupComponent,
    CtlsViewSurveyDetailsComponent,
    CtlsCommentPopoverComponent,
    CtlsAddAssignComponent
  ];
}
