import { ChartData, PeriodicData, TrendData } from "../models/classes/api-data-models.class";
import { ParseablePeriodicData, SeriesElements, ParsedTrendData } from "../models/classes/chart-data-models.class";
import { ChartConfigs } from "../models/configs/default-chart-options.config";

export module ChartDataUtility {
    export function sortByPeriod(val: ParseablePeriodicData[], sortOn: string): ParseablePeriodicData[] {
        try {
            return val.sort((i, j) => {
                if (i._timestamp < j._timestamp) return -1;
                if (i._timestamp > j._timestamp) return 1;
                return 0;
            });
        } catch (error) {
            console.log('Error occurred while sorting dates. Details: \n', error);
        }
    }

    /**
     * In-place sorting on property _timestamp.
     * @param data data to be sorted
     */
    export function sortAscending(data: ParseablePeriodicData[]): ParseablePeriodicData[] {
        return sortByPeriod(data, '_timestamp');
    }

    /**
     * Cast data for parsing
     * @param data Data to be casted
     */
    export function castToParseablePeriodicData(data: PeriodicData[]): ParseablePeriodicData[] {
        let casted: ParseablePeriodicData[] = [];
        for (let i = 0; i < data.length; i++) {
            let casting: ParseablePeriodicData = new ParseablePeriodicData();
            casting.Data = data[i].Data;
            casting.Period = data[i].Period;
            casting._timestamp = new Date(casting.Period);
            casted.push(casting);
        }
        return casted;
    }

    /**
     * Generate axis labels
     * @param params Base data to extract labels from
     */
    export function generateAxisLabels(params: { Period: string }[]): string[] {
        return params.map(i => i.Period.trim());
    }

    export function extractData(params: ParseablePeriodicData[]): SeriesElements[] {
        let series: SeriesElements[] = [];
        for (let i = 0; i < params.length; i++) {
            const iSeries = params[i];
            for (let j = 0; j < iSeries.Data.length; j++) {
                const jSeries = iSeries.Data[j];
                let idx: number = series.findIndex(e => e.name.toLowerCase().trim() === jSeries.Customer.toLowerCase().trim());
                if (idx > -1) {
                    /** exists in series, push to existing */
                    series[idx].data.push(jSeries.Win);
                } else {
                    /** doesn't exists in series, add new */
                    let lmnt = {
                        // let lmnt: SeriesElements = {
                        data: [jSeries.Win],
                        name: jSeries.Customer.trim(),
                        color: ChartConfigs.WinColor,
                        type: "column",
                        negativeColor: ChartConfigs.LoseColor
                    };
                    series.push(lmnt);
                }
            }
        }
        return series;
    }

    /******************** New models: Jan 31 2020 ********************/

    /**
     * Adds new Date() on _timestamp
     * @param params Trend data to add timestamp on
     */
    export function addTimestamp(params: TrendData[]): ParsedTrendData[] {
        let ret: ParsedTrendData[] = [];
        for (let i = 0; i < params.length; i++) {
            ret.push({ ...params[i], _timestamp: new Date(params[i].Period) });
        }
        return ret;
    }
    /**
     * Sorts trends based on given parameter
     * @param val Value to be sorted
     * @param sortOn Property to apply sortng on
     */
    export function sortTrendsByPeriod(val: ParsedTrendData[], sortOn: string = '_timestamp'): ParsedTrendData[] {
        try {
            return val.sort((i, j) => {
                if (i[sortOn] < j[sortOn]) return -1;
                if (i[sortOn] > j[sortOn]) return 1;
                return 0;
            });
        } catch (error) {
            console.log('Error occurred while sorting dates. Details: \n', error);
        }
    }

    /**
     * In-place sorting on property _timestamp.
     * @param data data to be sorted
     * @param sortAscending if true sorts in ascending, else in descending
     */
    export function sort(data: ParsedTrendData[], sortAscending: boolean = true): ParsedTrendData[] {
        return sortAscending ? sortTrendsByPeriod(data, '_timestamp') : sortTrendsByPeriod(data, '_timestamp').reverse();
    }

    export function dataExtraction(params: ParsedTrendData[]): SeriesElements[] {
        let win: SeriesElements = {
            name: ChartConfigs.CONTINUED_HOVER_TEXT,
            data: [],
            color: ChartConfigs.WinColor,
            maxPointWidth: ChartConfigs.MaxPointWidth,
            // xAxis: 0
        }, loss: SeriesElements = {
            name: ChartConfigs.DISCONTINUED_HOVER_TEXT,
            data: [],
            color: ChartConfigs.LoseColor,
            maxPointWidth: ChartConfigs.MaxPointWidth,
            // xAxis: 1
        };
        for (let i = 0; i < params.length; i++) {
            win.data.push(Math.abs(params[i].WinCount));
            loss.data.push(Math.abs(params[i].LostCount) === 0 ? 0 : Math.abs(params[i].LostCount) * -1);
        }
        return [loss, win];
    }

    export function convertToABB(num, fixed) {
        num = parseInt(num);
        fixed = parseInt(fixed);
        if (num === null) { return null; }
        if (num === 0) { return '0'; }
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
        return e;
    }
}