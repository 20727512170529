import { Component, Input, OnInit, Renderer, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService } from 'src/app/app.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { SessionStorageService } from 'src/app/components/surveys/services/session-storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { DistDashbaordService } from '../../Services/dist-dashbaord.service';
import { RelationshipDashboardSharedEventService } from 'src/app/shared/services/relationship-dashboard-shared-event.service';
import { WidgetMaster, Widget } from 'src/app/shared/models/dashboard-models/dashbaord.models';
import { DashboardEvent } from 'src/app/shared/enums/dashboard.event';
import { widgetCodes } from 'src/app/shared/constants/shared.constants';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-view-selector-distributor',
  templateUrl: './view-selector-distributor.component.html',
  styleUrls: ['./view-selector-distributor.component.scss']
})
export class ViewSelectorDistributorComponent implements OnInit {
  @Input() seletedCategory :any;
  private imagepath = environment.imagepath;
  boolViewSelectorComponentLoader: boolean = false;
  successMsg: any = false;
  showRightMenuDiv : boolean = false;
  viewSelectorSubmitSuccess = false;
  topIconDisplay: string = "none";
  widgetLeftOrderList = new Array<Widget>();
  // widgetNpsOrderList = new Array();
  widgetCenterOrderList = new Array<Widget>();
  widgetDDOrderList = new Array<Widget>();
  ViewDashboard = false;
  isCenterChecked: boolean = true;
  isDDChecked: boolean = false;
  widgetCodes = null;
  msgs: any = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  genericErrorMessage = 'An Error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail;

  constructor(public router: Router,
    private activatedRoute: ActivatedRoute,
    private render: Renderer,
    private renderer: Renderer2,
    private appService: PermissionsService,
    private headerService: HeaderService,
    private _activeRoute: ActivatedRoute,
    
    private dashboardWidgetService: DistDashbaordService,
    private sessionService: SessionStorageService,
    private sharedService: SharedService,
    private rdSharedEventService : RelationshipDashboardSharedEventService) { 
      this.widgetCodes = widgetCodes;
    }
    ngOnChanges() {
      console.log(this.seletedCategory)
    }

  ngOnInit() {   

    /*  Events to update catagory-wise lists, based on shared service. */
    this.rdSharedEventService.widgetPropertyChanged.subscribe((widgetMaster : WidgetMaster) => {    
      if(widgetMaster !=null && (widgetMaster.Event === DashboardEvent.Load || widgetMaster.Event === DashboardEvent.Closed )){
        Array.isArray(widgetMaster.LeftWidgetList) ? this.widgetLeftOrderList = cloneDeep(widgetMaster.LeftWidgetList) : null;      
        Array.isArray(widgetMaster.CenterWidgetList) ? this.widgetCenterOrderList = cloneDeep(widgetMaster.CenterWidgetList) : null;      
        Array.isArray(widgetMaster.DrillDownWidgetList) ? this.widgetDDOrderList = cloneDeep(widgetMaster.DrillDownWidgetList) : null;
        this.setDefaultview();
      }      
    });
  }

  /*  Default view cheque from db. */
  setDefaultview(){
    this.isCenterChecked = this.widgetCenterOrderList.every(item=>{
     return item.IsDefaultView == true;
   });

   this.isCenterChecked? this.isDDChecked = false: this.isDDChecked = true;
 }
  /*  Utility function which search for visible component.*/
  searchRecordFromList(widgetCode, list=[]){
    if(list.length <= 0){
      return { IsApplicable: false }
    } else {
      return list.filter(item => { return (item.WidgetCode == widgetCode) })[0];
    }
  }

  /*  Performs select / deselect operations */
  widgetLeftSelect(widgetCode) {
    this.widgetLeftOrderList.map((item) => {
      if (item.WidgetCode == widgetCode) {
        item.IsVisible? item.IsVisible = false : item.IsVisible = true;
      }
    });
  }

  /*  Performs select / deselect operations */
  widgetCenterSelect(widgetCode) {
    this.widgetCenterOrderList.map((item) => {
      if (item.WidgetCode == widgetCode) {
        item.IsVisible? item.IsVisible = false : item.IsVisible = true;
      }
    });
  }

  /*  Performs select / deselect operations */
  widgetDDSelect(widgetCode) {
    this.widgetDDOrderList.map((item) => {
      if (item.WidgetCode == widgetCode) {
        item.IsVisible? item.IsVisible = false : item.IsVisible = true;
      }
    });
  }

   /*  Checkbox value change event to check for ticked value. */
   onChange(checkBox){
    if(checkBox == 'Center'){
      this.isCenterChecked? this.isDDChecked = false: this.isDDChecked = true;
      this.setDefaultViewParameter(this.widgetCenterOrderList);
      this.unSetDefaultViewParameter(this.widgetDDOrderList);
    }else if(checkBox == 'DD'){
      this.isDDChecked? this.isCenterChecked = false: this.isCenterChecked = true;
      this.unSetDefaultViewParameter(this.widgetCenterOrderList);
      this.setDefaultViewParameter(this.widgetDDOrderList);
    }
  }
  setDefaultViewParameter(listSet) {
    if(listSet.length > 0){
      listSet.map(val =>{
        val.IsDefaultView =true;
      });
    }
  }

  unSetDefaultViewParameter(listSet) {
    if(listSet.length > 0){
      listSet.map(val =>{
        val.IsDefaultView =false;
      });
    }
  }

  submitViewSelector(){
    /*  Combine all lists into one. */
    let widgetListToSend = [...this.widgetLeftOrderList, ...this.widgetCenterOrderList, ...this.widgetDDOrderList];
    
    this.boolViewSelectorComponentLoader = true;
    //api call to save update list
    this.dashboardWidgetService.postAddUpdateWidgetLocation(widgetListToSend).then(res => {
        // this.sharedService.changeWidgetSavedOrderList('saved');
        this.rdSharedEventService.hideWidgets(widgetListToSend, DashboardEvent.ViewChanged);
        this.boolViewSelectorComponentLoader = false;
        this.viewSelectorSubmitSuccess =true;
        this.showRightMenuDiv = false;
    }).catch(e => {
      this.successMsg = true;
      this.boolViewSelectorComponentLoader = false;
        this.callErrorMsg(
          'error',
          '',
          this.genericErrorMessage
        );
    });
  }
  

   // severity: info/success/warn/error
   callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    window.scrollTo(0, 0);
    setTimeout(function () {
      document.getElementById('ClearMsg').click();
    }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    if(this.successMsg){
      this.successMsg = false;
    }
  }
}


