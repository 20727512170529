import { forEach } from '@angular/router/src/utils/collection';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  Input,
} from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CtlsSpinnerService } from 'src/app/Modules/ctls/ctls-shared/ctls-components/ctls-spinner/ctls-spinner.service';
import {
  CtlsSharedService,
  CtlsTaskListingService,
} from 'src/app/Modules/ctls/ctls-shared/ctls-services';
import { CHART_STATE_OPTIONS } from 'src/app/Modules/ctls/ctls-shared/ctls-enums/ctls-chart-state-options.enum';

import { DialogService } from 'primeng/api';
import { Validators, FormBuilder } from '@angular/forms';
import { CtlsTaskSurveyFeedbackService } from 'src/app/Modules/ctls/ctls-shared/ctls-services';
import * as _ from 'lodash';
import { Message } from 'primeng/components/common/api';

import moment = require('moment');
import { CreateTaskService } from 'src/app/Modules/ctls/ctls-shared/ctls-services/ctls-create-task.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-ctls-view-survey-details',
  templateUrl: './ctls-view-survey-details.component.html',
  styleUrls: ['./ctls-view-survey-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtlsViewSurveyDetailsComponent implements OnInit {
  private imagepath = environment.imagepath;
  @Input() selectedRadioOption: number;
  objHeaderContent: any;
  selectedLanguage: any;
  showSurveyDetailsTab: Boolean = true;
  objLanguages = [];
  originalQuestionList = [];
  translatedQuestionList = [];
  mergedQuestionList = [];
  alphabets = 'abcdefghijklmnopqrstuvwxyz';
  showQuestionnaire = false;
  transLoading = false;
  surveyLanguage: string;
  surveyLanguageId: any;
  surveymode = 1;
  destroy$ = new Subject();
  uplodedFiles: any;
  blnCreateTaskpopupClose = false;
  currentDate: any;
  ClosedCompareDate: any;
  availableDate: any;
  isEnabled = true;
  createtaskListing = false;

  objQuestions = {
    "original": [],
    "translated": []
  };
  mergeListQn = [];
  objQnData = {
    type: "",
    parent: false,
    ParentResponse: '',
    field: '',
    value: ''
  }
  fieldType = {
    LABEL: "LABEL",
    INPUT: "INPUT",
    TEXTAREA: "TEXTAREA",
    DIV: "DIV",
    SPAN: "SPAN"
  }

  objPriorities: any;
  objAssignees: any;
  objActions: any;
  objRecommendedActions: any;
  objDesignations: any;
  objDivs: any;
  objBrands: any;
  objStatus: any;
  blnTaskFromGrid = false;

  surveyIDList: any[] = [];
  objTaskForm = this.fb.group({
    strTaskTitle: [''],
    strPriority: ['', Validators.required],
    strAssignee: ['', Validators.required],
    strAction: ['', Validators.required],
    strDesignation: ['', Validators.required],
    strDIV: ['', Validators.required],
    strBrand: ['', Validators.required],
    strStatus: ['', Validators.required],
    strComments: [''],
    strTransComments: [''],
    strLanguage: [''],
  });
  filesToUpload = [];
  base64Images = [];

  arrBase64textString = [];
  strMaxSizeError = '';
  @ViewChild('toggleDiv') toggleDiv: ElementRef;
  intFileTotal = 0;
  successMsg = false;
  msgs: Message[] = [];
  MessageTimeOut = 10000;
  selectedTooltip: any;
  surveymodeType: string;
  divIdToString: string;
  constructor(
    private ctlsTaskListingService: CtlsTaskListingService,
    public config: DynamicDialogConfig,
    private ctlsSharedService: CtlsSharedService,
    private cdr: ChangeDetectorRef,
    private spinnerService: CtlsSpinnerService,
    private dialogService: DialogService,

    private router: Router,
    private fb: FormBuilder,
    public dynamicDialogRef: DynamicDialogRef,
    private ctlsTaskSurveyFeedbackService: CtlsTaskSurveyFeedbackService,
    private renderer2: Renderer2,

    private createTaskService: CreateTaskService
  ) { }

  ngOnInit() {

    if (this.router.url.includes('task-listing') || this.router.url.includes('360') || this.router.url.includes('dashboard/home')){
      this.createtaskListing = true;
    }

    this.surveyLanguageId = 8;
    if (!!this.config && !!this.config.data) {
      this.surveymode = this.config.data.surveyType;
      this.objHeaderContent = this.config.data;
      this.surveyLanguageId = this.config.data.LanguageID;

      this.currentDate = new Date();
      var date = this.config.data.FeedbackSubmitDate
        ? this.config.data.FeedbackSubmitDate.split('-')
        : '';

      this.ClosedCompareDate = new Date(date);

      if (this.surveymode == 1) {   //For the case of Transactional survey
        this.surveymodeType = "TRANS"
        this.availableDate = this.ClosedCompareDate.setDate(
          this.ClosedCompareDate.getDate() + 30
        );
      } else if (this.surveymode == 2) {  //For the case of Relational survey
        this.surveymodeType = "DIST"
        this.availableDate = this.ClosedCompareDate.setDate(
          this.ClosedCompareDate.getDate() + 90
        );
      }

      this.availableDate = moment(this.ClosedCompareDate).format(
        'DD-MMM-YYYY'
      );

      if (this.ClosedCompareDate > this.currentDate)
        this.isEnabled = true;
      else
        this.isEnabled = false;

    }
    this.feedbackComments(this.surveyLanguageId);

    this.createTaskService //To get data from create task pop
      .getMessage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!!res && res.operation === 'SAVE_TASK_RESPONSE') {
          if (res.response.Response) {
            this.successMsg = true;
            this.callErrorMsg(
              'success',
              '',
              res.response.Status,
              this.MessageTimeOut
            );
            this.dynamicDialogRef.close();
            this.objTaskForm.reset();
            res.response = '';
            this.cdr.detectChanges();
          } else {
            this.successMsg = true;
            this.callErrorMsg(
              'error',
              '',
              res.response.Status,
              this.MessageTimeOut
            );
            res.response = '';
            this.cdr.detectChanges();
          }
        }
      });

    this.currentDate = new Date();
    this.surveymode = this.config.data.surveyType;
    this.surveyIDList.push(this.config.data);
    this.blnTaskFromGrid = true;
    this.objHeaderContent = this.config.data;

    /**
     * Dropdown API
     */
    this.ctlsTaskSurveyFeedbackService
      .getManualTaskFeedbackMasters(this.surveymodeType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data.Response) {
          //console.log('data.Response',data.Response);
          let objAllDropdowns = data.Response;
          this.objPriorities = objAllDropdowns.priorities;
          this.objAssignees = objAllDropdowns.assignees;
          this.objStatus = objAllDropdowns.statuses;
          this.objActions = objAllDropdowns.recommendedActions;
          this.objLanguages = objAllDropdowns.languages;
          const selectedStatus = {
            // Open status as selected .. hardcoded.
            CTLSStatusID: 1,
            StatusCode: 'OPN',
            StatusDescription: 'Open',
          };
          this.objTaskForm.controls.strStatus.setValue(selectedStatus);
        }
        this.objLanguages.map((item) => {
          item.Language = item.LanguageDescription + '(' + item.ISOCode + ')';
        });
      });
    if (this.surveyIDList.length) {
      let FamId;
      let BaId;
      this.surveyIDList.forEach((element) => {
        (FamId = element.FAM), (BaId = element.BA);
      });
      let req = {
        params: {
          FAM: FamId,
          BA: BaId,
        },
      };
      //console.log('req',req); 
      this.ctlsTaskSurveyFeedbackService
        .getDesignations(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data.Response) {
            this.objDesignations = _.uniqBy(data.Response, 'CTLSDesignationID');

            if (this.config.data.IsUser === true ||
              (this.config.data.objData &&
                (this.config.data.objData.IsUser ||
                  (this.config.data.objData.length &&
                    this.config.data.objData[0] &&
                    this.config.data.objData[0].IsUser)))
            ) {
              var onLoadDesig = this.objDesignations[0];
              this.objTaskForm.get('strDesignation').setValue(onLoadDesig);
              this.onDesignationChange();
            }
          }
        });
    }

    //console.log('this.surveyIDList',this.surveyIDList)
  }
  /**
     * On Destroy
     */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  feedBackCommenttStatus: CHART_STATE_OPTIONS;
  feedbackComments(langId = 8) {

    const surveyId = this.objHeaderContent.SurveyID;
    this.originalQuestionList = [];
    this.translatedQuestionList = [];
    // setTimeout(() => {
    //   this.spinnerService.show();
    // });
    this.spinnerService.show();
    this.ctlsTaskListingService
      .GetSurveyFeedbackComments(surveyId, langId, this.surveymode)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(async (res) => {
        this.mergeListQn = [];
        this.objQuestions = {
          "original": [],
          "translated": []
        };
        if (res !== null && res.Status !== 'SUCCESS') {
          this.spinnerService.hide();
          this.feedBackCommenttStatus = CHART_STATE_OPTIONS.ERROR;
        }

        if ((res === null || res.Response === null)) {
          this.spinnerService.hide();
          this.feedBackCommenttStatus = CHART_STATE_OPTIONS.NO_DATA;

        }

        if (res.Response && res.Status == 'SUCCESS') {
          this.feedBackCommenttStatus = CHART_STATE_OPTIONS.SUCCESS;
          const response = await res.Response;
          this.originalQuestionList.push(response.OriginalQuestion);
          this.translatedQuestionList.push(response.TranslatedQuestion);
          if (this.originalQuestionList[0].length) {
            this.originalQuestionList[0].forEach((parent: any, i: number) => {
              this.qnTypeCheck(parent, i, 'original')
            });
          }
          if (this.translatedQuestionList[0].length) {
            this.translatedQuestionList[0].forEach((parent: any, i: number) => {
              this.qnTypeCheck(parent, i, 'translated')
            });
          }
          if (this.objQuestions.original.length) {
            this.objQuestions.original.map((data, i) => {
              if (data) {
                this.mergeListQn[i] = [data];
                if (this.objQuestions.translated.length && (this.objQuestions.translated.length == this.objQuestions.original.length)) {
                  this.mergeListQn[i].push(this.objQuestions.translated[i])
                } else {
                  this.mergeListQn[i].push(null)
                }
              }
            })
          }
          this.showQuestionnaire =
            !!response.OriginalQuestion && !!response.OriginalQuestion.length
              ? true
              : false;
          this.surveyLanguage = response.DefaultLanguage;
          this.cdr.detectChanges();
          this.spinnerService.hide();


        }





      }, (err) => {
        this.spinnerService.hide();
        this.feedBackCommenttStatus = CHART_STATE_OPTIONS.ERROR;
      });


  }

  checkOem(surveyName)  {
    if(surveyName.includes('OEM')) {
      return true
    } else
    {
      return false;
    }
  }

  qnTypeCheck(parent: any, i: number, key: string) {
    if (parent.Type == "NPS") {
      this.qnNPS(parent, i, key);
    }
    if (parent.Type == "GRID") {
      this.qnGRID(parent, i, key);
    }
    if (parent.Type == "SO") {
      this.qnSO(parent, i, key);
    }
    if (parent.Type == "FFT") {
      this.qnFFT(parent, i, key);
    }
    if (parent.Type == "Radio") {
      this.qnRADIO(parent, i, key);
    }
    if (parent.Type == "RadioScale") {
      this.qnRADIOSCALE(parent, i, key);
    }
    if (parent.Type == "Single") {
      this.qnSINGLE(parent, i, key);
    }
    if (parent.Type == "MCQ") {
      this.qnMCQ(parent, i, key);
    }
  }
  qnObjSettingUp(
    type: string,
    parent: boolean,
    ParentResponse: string,
    field: string,
    value: any
  ) {
    return {
      type: type,
      parent: parent,
      ParentResponse: ParentResponse,
      field: field,
      value: value
    }
  }

  qnNPS(parent: any, i: number, key: string) {
    this.objQuestions[key].push(
      this.qnObjSettingUp(
        parent.Type,
        false,
        parent.ParentResponse,
        this.fieldType.LABEL,
        "" + 'Q' + this.getQuestionNumber(i) + '.' + parent.QuestionText)
    )
    parent.Response.forEach((sub: any, j: number) => {
      if (sub.AttributeValue == null) {
        this.objQuestions[key].push(
          this.qnObjSettingUp(
            parent.Type,
            true,
            "",
            this.fieldType.INPUT,
            sub.ReasonText
          ))
      } else {
        this.objQuestions[key].push(
          this.qnObjSettingUp(
            parent.Type,
            true,
            "",
            this.fieldType.LABEL,
            "" + 'Q' + this.getQuestionNumber(i) + '.a.' + sub.AttributeValue
          ))
        this.objQuestions[key].push(
          this.qnObjSettingUp(
            parent.Type,
            true,
            "",
            this.fieldType.TEXTAREA,
            sub.ReasonText
          ))
      }
    })
  }
  qnGRID(parent: any, i: number, key: string) {
    this.objQuestions[key].push(
      this.qnObjSettingUp(
        parent.Type,
        false,
        parent.ParentResponse,
        this.fieldType.LABEL,
        "" + 'Q' + this.getQuestionNumber(i) + '.' + parent.QuestionText,
      ))
    parent.Response.forEach((sub: any, j: number) => {
      this.objQuestions[key].push(
        this.qnObjSettingUp(
          parent.Type,
          true,
          "",
          this.fieldType.DIV,
          "" + this.alphabets[j] + '.' + sub.AttributeValue
        ))
      this.objQuestions[key].push(
        this.qnObjSettingUp(
          parent.Type,
          true,
          "",
          this.fieldType.INPUT,
          sub.Response
        ))
      if (sub.ReasonText != null && sub.ReasonText != '') {
        this.objQuestions[key].push(
          this.qnObjSettingUp(
            parent.Type,
            true,
            "",
            this.fieldType.TEXTAREA,
            sub.ReasonText
          ))
      }
    })
  }
  qnSO(parent: any, i: number, key: string) {
    this.objQuestions[key].push(
      this.qnObjSettingUp(
        parent.Type,
        false,
        parent.ParentResponse,
        this.fieldType.LABEL,
        "" + 'Q' + this.getQuestionNumber(i) + '.' + parent.QuestionText
      ))
    parent.Response.forEach((sub: any, j: number) => {
      this.objQuestions[key].push(
        this.qnObjSettingUp(
          parent.Type,
          true,
          "",
          this.fieldType.INPUT,
          sub.Response
        ))
    })
  }
  qnFFT(parent: any, i: number, key: string) {
    this.objQuestions[key].push(
      this.qnObjSettingUp(
        parent.Type,
        false,
        parent.ParentResponse,
        this.fieldType.LABEL,
        "" + 'Q' + this.getQuestionNumber(i) + '.' + parent.QuestionText
      ))
    parent.Response.forEach((sub: any, j: number) => {
      this.objQuestions[key].push(
        this.qnObjSettingUp(
          parent.Type,
          true,
          "",
          this.fieldType.TEXTAREA,
          sub.ReasonText
        ))
    })
  }
  qnRADIO(parent: any, i: number, key: string) {
    this.objQuestions[key].push(
      this.qnObjSettingUp(
        parent.Type,
        false,
        parent.ParentResponse,
        this.fieldType.LABEL,
        "" + 'Q' + this.getQuestionNumber(i) + '.' + parent.QuestionText
      ))
    parent.Response.forEach((sub: any, j: number) => {
      this.objQuestions[key].push(
        this.qnObjSettingUp(
          parent.Type,
          true,
          "",
          this.fieldType.INPUT,
          sub.ReasonText
        ))
    })
  }
  qnRADIOSCALE(parent: any, i: number, key: string) {
    this.objQuestions[key].push(
      this.qnObjSettingUp(
        parent.Type,
        false,
        parent.ParentResponse,
        this.fieldType.LABEL,
        "" + 'Q' + this.getQuestionNumber(i) + '.' + parent.QuestionText,
      ))
    parent.Response.forEach((sub: any, j: number) => {
      this.objQuestions[key].push(
        this.qnObjSettingUp(
          parent.Type,
          true,
          "",
          this.fieldType.INPUT,
          sub.ReasonText
        ))
    })
  }
  qnSINGLE(parent: any, i: number, key: string) {
    this.objQuestions[key].push(
      this.qnObjSettingUp(
        parent.Type,
        false,
        parent.ParentResponse,
        this.fieldType.LABEL,
        "" + 'Q' + this.getQuestionNumber(i) + '.' + parent.QuestionText
      ))
    parent.Response.forEach((sub: any, j: number) => {
      this.objQuestions[key].push(
        this.qnObjSettingUp(
          parent.Type,
          true,
          "",
          this.fieldType.INPUT,
          sub.ReasonText
        ))
    })
  }

  qnMCQ(parent: any, i: number, key: string) {
    this.objQuestions[key].push(
      this.qnObjSettingUp(
        parent.Type,
        false,
        parent.ParentResponse,
        this.fieldType.LABEL,
        "" + 'Q' + this.getQuestionNumber(i) + '.' + parent.QuestionText,
      ))
    parent.Response.forEach((sub: any, j: number) => {
      if (sub.AttributeName == 'OTHER_OPTION') {
        this.objQuestions[key].push(
          this.qnObjSettingUp(
            parent.Type,
            true,
            "",
            this.fieldType.SPAN,
            sub.AttributeValue
          ))
        this.objQuestions[key].push(
          this.qnObjSettingUp(
            parent.Type,
            true,
            "",
            this.fieldType.TEXTAREA,
            sub.Response
          ))
      } else {
        this.objQuestions[key].push(
          this.qnObjSettingUp(
            parent.Type,
            true,
            "",
            this.fieldType.INPUT,
            sub.Response
          ))
      }
    })
  }

  languageChange() {
    if (!!this.selectedLanguage && !!this.selectedLanguage.LangID) {
      this.feedbackComments(this.selectedLanguage.LangID);
    }
  }

  translateTo() {
    this.transLoading = true;
    var transCount = 0;
    //if (this.filter == "Completed" && this.isRTI == false) {
    //this.blockedSection = true;
    this.ctlsTaskListingService.translateWithGoogle(this.translatedQuestionList[0], this.selectedLanguage.LangID).then(data => {
      if (data.Status == "Success") {
        this.translatedQuestionList = [];
        //this.TranslatedQuestion = data.Response.TranslatedQuestion;
        this.translatedQuestionList.push(data.Response.TranslatedQuestion);
        this.transLoading = false;
      }
      if (data.Status == "Failure") {
        this.transLoading = false;
        // this.successMsg = true;
        // this.callErrorMsg('error', '', this.genericErrorMessage);
      }
      // this.blockedSection = false;
    }).catch(error => {
      this.transLoading = false;
      if (error.Status == "Failure") {

      }
    })
  }


  resetLanguage() {
    this.feedbackComments(this.surveyLanguageId);
    if (Object.keys(this.objLanguages).length) {
      this.selectedLanguage = this.objLanguages.find((i) => i.LangID === this.surveyLanguageId);
    }
  }

  getQuestionNumber(index: number): String {
    let oQuestionList = this.originalQuestionList[0];

    let siblingQuestions = oQuestionList.filter((q) => {
      return q.ParentQuestionId == oQuestionList[index].ParentQuestionId;
    });

    if (oQuestionList[index].ParentQuestionId == 0) {
      return (siblingQuestions.indexOf(oQuestionList[index]) + 1).toString();
    } else {
      let parentQuestion = oQuestionList.find((q) => {
        return q.QuestionId == oQuestionList[index].ParentQuestionId;
      });

      let parentQuestionNo = this.getQuestionNumber(
        oQuestionList.indexOf(parentQuestion)
      );
      let subQuestionNo = (
        siblingQuestions.indexOf(oQuestionList[index]) + 1
      ).toString();

      return parentQuestionNo + '.' + subQuestionNo;
    }
  }

  // /**
  //  * open popup
  //  *  Creat task
  //  * */
  //  createTask(objRowData = null) {
  //   console.log('objData',objRowData);
  //   this.openDialog({ operation: 'FROM_GRID', objData: objRowData , mode: "" });
  // }

  // /** open popup for create task
  //  * @param data
  //  */
  // openDialog(data) {
  //   this.uplodedFiles = null;
  //   this.blnCreateTaskpopupClose = false;
  //   const ref = this.dialogService.open(CtlsCreateTaskListPopupComponent, {
  //     width: data.operation === 'FROM_BUTTON_CLICK' ? '50vw' : '65vw',
  //     height: '70vh',
  //     transitionOptions: '0ms',
  //     data: data,
  //   });

  //   ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((objSaveTask) => { });
  // }

  handleChange(event) {
    this.showSurveyDetailsTab = event.index === 1 ? false : true;
  }

  /**
  * Error Message Display
  * severity: info/success/warn/error
  * @param type: string => severity
  * @param summary: string
  * @param detail: string => Message to show
  */
  callErrorMsg(type, summary = '', detail = '', timeOut) {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () {
      document.getElementById('ClearMsg').click();
    }, timeOut);
  }

  /**
   * Clear Message => triggered from HTML Button Click
   */
  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }

  /**
   * Transalte comment
   */
  translate() {
    if (!!this.objTaskForm.value.strComments) {
      let objData = {
        LanguageId: this.objTaskForm.value.strLanguage.LangID,
        TextToTranslate: this.objTaskForm.value.strComments,
      };
      this.ctlsTaskSurveyFeedbackService
        .getTranslateWithGoogle(objData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.Response) {
            this.objTaskForm
              .get('strTransComments')
              .setValue(res.Response.TranslatedText);
          }
        });
    }
  }

  /**
   * Close popup
   */
  close() {
    this.objTaskForm.reset();
    this.dynamicDialogRef.close('close');
  }
  /**
   * Save task
   */
  saveTask() {

    this.surveyIDList.forEach((element) => {

      element.BA = element.BA;
      element.Branch = element.Branch;
      element.Brand = element.Brand;
      element.CTLSConfigurationID = element.CTLSConfigurationID;
      element.ContactName = element.ContactName;
      element.Customer = element.Customer;
      element.CustomerAccountNo = element.CustomerAccountNumber;
      element.DIV = element.DIV;
      element.EmailAddress = element.Email;
      element.FAM = element.FAM;
      element.FeedbackSubmitDate = element.FeedbackSubmitDate;
      element.InfluenceLevel = element.InfluenceLevel;
      element.IsUser = element.IsUser;
      element.NPSComment = element.NPSComment;
      element.NPSScore = element.NPSScore;
      element.OrderNo = element.OrderNo;
      element.SalesEngineer = element.SalesEngineer;
      element.ServiceTechnician = element.ServiceTechnician;
      element.SurveyID = element.SurveyID;
      element.SurveyName = element.SurveyName;
      element.SurveyResponseID = element.SurveyResponseID;
      element.TouchPoint = element.TouchPoint;
      element.Zone = element.Zone;
      element.availableDate = this.availableDate;

      if (!!element.NPSScore && element.NPSScore >= 0 && element.NPSScore <= 6) {
        element.detractor = true;
      } else if (element.NPSScore >= 7 && element.NPSScore <= 8) {
        element.neutral = true;
      } else if (element.NPSScore >= 9 && element.NPSScore <= 10) {
        element.promotor = true;
      } else if (element.NPSScore == 0) {
        element.detractor = true;
      }

      this.currentDate = new Date();

      var date = element.FeedbackSubmitDate
        ? element.FeedbackSubmitDate.split('-')
        : '';

      element.ClosedCompareDate = new Date(date);

      // element.availableDate = element.ClosedCompareDate.setDate(
      //   element.ClosedCompareDate.getDate() + 30
      // );

      if (this.surveymode == 1) {   //For the case of Transactional survey
        element.availableDate = element.ClosedCompareDate.setDate(
          element.ClosedCompareDate.getDate() + 30
        );
      } else if (this.surveymode == 2) {  //For the case of Relational survey
        element.availableDate = element.ClosedCompareDate.setDate(
          element.ClosedCompareDate.getDate() + 90
        );
      }

      element.availableDate = moment(element.ClosedCompareDate).format(
        'DD-MMM-YYYY'
      );

      if (element.ClosedCompareDate > this.currentDate)
        element.isEnabled = true;
      else
        element.isEnabled = false;

      element.isSelected = false;

    });

    let objSaveTask = {
      data: this.objTaskForm.value,
      surveyIDList: this.surveyIDList,
      uplodedFiles: this.uplodedFiles ? this.uplodedFiles : null,
      operation: 'SAVE_TASK',
    };
    this.createTaskService.sendMessage(objSaveTask); // pass data from popup to container
  }
  /**
   * onDesignationChange
   */
  onDesignationChange(event?) {
    this.objDivs = [];
    this.objBrands = [];
    this.objAssignees = [];
    this.f.strDIV.reset();
    this.f.strBrand.reset();
    this.f.strAssignee.reset();
    const strDesignation = this.f.strDesignation.value;
    this.selectedTooltip = this.f.strDesignation.value.Designation;
    let FamId;
    let BaId;
    this.surveyIDList.forEach((element) => {
      (FamId = element.FAM), (BaId = element.BA);
    });
    if (strDesignation) {
      /**
       * Dropdown API
       */
      const req = {
        params: {
          desigId: strDesignation.CTLSDesignationID,
          FAM: FamId,
          BA: BaId,
        },
      };
      this.ctlsTaskSurveyFeedbackService
        .getDivision(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.objDivs = _.uniqBy(res.Response, 'DivisionID');
          if (
            this.config.data.objData.IsUser ||
            (this.config.data.objData[0] && this.config.data.objData[0].IsUser)
          ) {
            var onLoadDIV = this.objDivs[0];
            this.objTaskForm.get('strDIV').setValue(onLoadDIV);
            this.onDIVChange();
          }
        });
    }
  }
  /**
   * onDIVChange
   */
  onDIVChange(event?) {
    this.objBrands = [];
    this.objAssignees = [];
    this.f.strBrand.reset();
    this.f.strAssignee.reset();
    const strDIV = this.f.strDIV.value;
    const divIds = [];
      this.f.strDIV.value.forEach(element => {
        divIds.push(element.DivisionID)
        this.divIdToString=divIds.toString()
      });
    if (strDIV) {
      const strDesignation = this.f.strDesignation.value;
      let FamId;
      let BaId;
      this.surveyIDList.forEach((element) => {
        (FamId = element.FAM), (BaId = element.BA);
      });
      /**
       * Dropdown API
       */
      const req = {
        params: {
          desigId: strDesignation.CTLSDesignationID,
          div: this.divIdToString,
          FAM: FamId,
          BA: BaId,
        },
      };
      this.ctlsTaskSurveyFeedbackService
        .GetAssignee(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.objAssignees = _.uniqBy(res.Response, 'CTLSAssigneeMappingID');
        });
      this.ctlsTaskSurveyFeedbackService
        .GetBrand(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.objBrands = _.uniqBy(res.Response, 'BrandID');
          if (
            this.config.data.objData.IsUser ||
            (this.config.data.objData[0] && this.config.data.objData[0].IsUser)
          ) {
            var onLoadBrand = this.objBrands[0];
            this.objTaskForm.get('strBrand').setValue(onLoadBrand);
            this.onBrandChange();
          }
        });
    }
  }
  /**
   * onBrandChange
   */
  onBrandChange(event?) {
    this.objAssignees = [];
    this.f.strAssignee.reset();
    const strDIV = this.f.strDIV.value;
    const strBrand = this.f.strBrand.value;
    if (strBrand) {
      const strDesignation = this.f.strDesignation.value;
      let FamId;
      let BaId;
      this.surveyIDList.forEach((element) => {
        (FamId = element.FAM), (BaId = element.BA);
      });
      /**
       * Dropdown API
       */
      const req = {
        params: {
          desigId: strDesignation.CTLSDesignationID,
          div: this.divIdToString,
          FAM: FamId,
          BA: BaId,
          BrandID: strBrand.BrandID,
        },
      };
      this.ctlsTaskSurveyFeedbackService
        .GetAssignee(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.objAssignees = _.uniqBy(res.Response, 'CTLSAssigneeMappingID');
          if (
            this.config.data.objData.IsUser ||
            (this.config.data.objData[0] && this.config.data.objData[0].IsUser)
          ) {
            var onLoadAssignee = this.objAssignees[0];
            this.objTaskForm.get('strAssignee').setValue(onLoadAssignee);
          }
        });
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.objTaskForm.controls;
  }

  /**
  * onPriorityChange
  */
  disableAction: boolean = true;
  onPriorityChange(event) {
    
    let CTLSConfigurationID = this.config.data.length ? this.config.data.CTLSConfigurationID : this.config.data.CTLSConfigurationID
    let CTLSTransType = this.config.data.surveyType == 1 ? 'trans' : 'dist';
    this.ctlsTaskSurveyFeedbackService
      .GetSDARecommendedAction(
        CTLSConfigurationID,
        this.objTaskForm.controls.strPriority.value.CTLSPriorityID,
        CTLSTransType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.Response.length != 0) {

          this.objTaskForm.controls.strAction.setValue(res.Response);
          // this.objTaskForm.controls['strAction'].disable();
          this.disableAction = true;

        } else {
          this.objTaskForm.controls.strAction.setValue([]);
          // this.objTaskForm.controls['strAction'].enable();
          this.disableAction = false;
        }


      });

  }

  /*     onPriorityChange(event) {
        const strPriority = this.f.strPriority.value;
        if (strPriority) {
          
          this.objRecommendedActions = this.objActions.filter(
            (item) =>
              Number(item.CTLSRecommendedActionID) ===
              Number(strPriority.CTLSRecommendedActionID)
          );
    
          this.objTaskForm.controls.strAction.setValue(
            this.objRecommendedActions[0]
          );
        } else {
          this.objTaskForm.controls.strAction.setValue('');
        }
      } */
  /**
   * vaildate file type
   */
  isValidFile(file) {
    if (
      file.name.toLowerCase().endsWith('.jpg') ||
      file.name.toLowerCase().endsWith('.jpeg') ||
      file.name.toLowerCase().endsWith('.png') ||
      file.name.toLowerCase().endsWith('.tiff') ||
      file.name.toLowerCase().endsWith('.bmp') ||
      file.name.toLowerCase().endsWith('.msg') ||
      file.name.toLowerCase().endsWith('.doc') ||
      file.name.toLowerCase().endsWith('.docx') ||
      file.name.toLowerCase().endsWith('.pdf') ||
      file.name.toLowerCase().endsWith('.xls') ||
      file.name.toLowerCase().endsWith('.xlsx') ||
      file.name.toLowerCase().endsWith('.ppt') ||
      file.name.toLowerCase().endsWith('.pptx')

      // image/*, .doc, .docx, .pdf, .msg
    ) {
      return true;
    }
  }

  deleteFile(index) {
    this.filesToUpload.splice(index, 1);
    this.intFileTotal = this.filesToUpload.length;
    this.hideShowFilesizeMessage();
  }

  /**
    * Select files
    */
  @ViewChild('attach')
  attach: ElementRef;
  selectFiles(event) {
    let selectedFiles = (event.target as HTMLInputElement).files;
    selectedFiles = _.orderBy(selectedFiles, ['size'], ['asc']);
    Array.from(selectedFiles).forEach((file) => {
      if (!!this.isValidFile(file)) {
        if (this.checkFileSize(file)) {
          this.filesToUpload.push(file);
          this.filesToUpload = this.filesToUpload.filter(
            (thing, index, self) =>
              index ===
              self.findIndex(
                (t) => t.size === thing.size && t.name === thing.name
              )
          );
        } else {
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.base64Images.push(reader.result);
        };
        reader.onerror = (error) => { };
      } else {
        this.strMaxSizeError = 'Invalid file format';
        this.cdr.detectChanges();
        return;
      }
    });

    if (event.target.files && event.target.files[0]) {
      // this.intFileTotal = this.filesToUpload.length;
      if (this.filesToUpload.length > 5) {
        this.strMaxSizeError = 'Number of attachments cannot exceed 5.';

        this.filesToUpload.splice(5, this.filesToUpload.length - 1);
        this.intFileTotal = this.filesToUpload.length;
      }
      if (this.filesToUpload.length) {
        this.filesToUpload = _.orderBy(this.filesToUpload, ['size'], ['asc']);
      }
      this.uplodedFiles = this.filesToUpload;
      this.hideShowFilesizeMessage();
      // if (!!!this.strMaxSizeError) {
      //   this.uplodedFiles = (event.target as HTMLInputElement).files;
      // }
    }
  }

  checkFileSize(file) {
    if (file.size <= 20971520 - this.getFileSize()) {
      return true;
    } else {
      this.strMaxSizeError = 'Total size of upload/s cannot exceed 20MB';
      //  this.clearErrorMsg();
      return false;
    }
  }

  getFileSize() {
    let intByteSize = 0;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      intByteSize += this.filesToUpload[i].size;
    }
    return intByteSize;
  }

  private hideShowFilesizeMessage() {
    let intByteSize = 0;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      intByteSize += this.filesToUpload[i].size;
    }
    // let blnTotalMb = this.checkFormatFileSize(intByteSize, 2);
    this.checkFormatFileSize(intByteSize, 2);
    // if (blnTotalMb) {
    //   this.strMaxSizeError = 'Total size of attachment/s cannot exceed 20MB';
    // } else
    if (
      this.strMaxSizeError === 'Total size of upload/s cannot exceed 20MB' ||
      this.strMaxSizeError === 'Number of attachments cannot exceed 5.' ||
      this.strMaxSizeError === 'Invalid file format'
    ) {
      this.clearErrorMsg();
    } else {
      this.strMaxSizeError = '';
    }
  }

  private clearErrorMsg() {
    setTimeout(() => {
      this.strMaxSizeError = '';
      this.cdr.detectChanges();
    }, 6000);
  }

  /**
   * check file size abouve 20 mb
   */
  checkFormatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return false;
    let k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    if (sizes[i] === 'MB') {
      if (parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) > 20.97) {
        return true;
      } else {
        this.intFileTotal = this.filesToUpload.length;
        return false;
      }
    } else {
      this.intFileTotal = this.filesToUpload.length;
      return false;
    }
  }

  /**
   * clear all uploaded files
   */
  removeAllUplodedFile() {
    this.attach.nativeElement.value = '';
    this.strMaxSizeError = '';
    this.intFileTotal = 0;
    this.filesToUpload = [];
  }

}
