import { Component } from '@angular/core';


@Component({
  selector: 'app-header-feedback-zone',
  templateUrl: './header-feedback-zone.component.html',
  styleUrls: ['./header-feedback-zone.component.scss'],
  inputs: ['itemList', 'index', 'topheader']
})
export class HeaderFeedBackZoneComponent {
  public itemList: any;
  public index: any;
  public topheader: string;
  constructor() {

  }
}
