import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../oauth2.config';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-signin-oidc',
  templateUrl: './signin-oidc.component.html',
  styleUrls: ['./signin-oidc.component.scss']
})
export class SigninOidcComponent implements OnInit {
  code: any;
  sessionstate: any;
  state: any;
  scope: any;
  loader: boolean=false;
  constructor(private oauthService: OAuthService,private route: ActivatedRoute,
    private http: HttpClient, public router: Router,
    @Inject(forwardRef(() => AppComponent)) private app_component:AppComponent) { }

 async ngOnInit() {
  this.loader=true;
    this.oauthService.configure(authConfig)
    this.route.queryParams .subscribe(params => {
      console.log("params--",params);  
      this.code=params.code;
      this.sessionstate = params.session_state;
      this.state = params.state;
      this.scope = params.scope; 
    })
    if (this.code) {
      try {
        //const tokenResponse = await this.oauthService.exchangeCodeForToken(this.code);
        const tokenResponse = await this.exchangeCodeForToken(this.code);
        localStorage.setItem('access_token',tokenResponse.access_token)
        localStorage.setItem('expires_in',tokenResponse.expires_in)
        localStorage.setItem('id_token',tokenResponse.id_token)
        localStorage.setItem('refresh_token',tokenResponse.refresh_token);
        // this.router.navigate(['dashboard/home']);
        this.sendData(tokenResponse);
        console.log('Token Response:', tokenResponse);
      } catch (error) {
        console.error('Token exchange error:', error);
      }

    }
    this.loader=false;
    //const idToken = this.oauthService.getAccessToken();
    //console.log("TOKEN--",idToken)
  //     this.oauthService.tryLogin({     
  //     onTokenReceived: context => {
  //         //
  //         // Output just for purpose of demonstration
  //         // Don't try this at home ... ;-)
  //         //
  //         console.debug("logged in");
  //         console.debug(context);
  //     }
  // });
  }
  exchangeCodeForToken(code: string): Promise<any> {
    const tokenEndpoint = this.oauthService.tokenEndpoint;
    const body = new HttpParams()
      .set('code', code)
      .set('grant_type','authorization_code')
      .set('client_id', this.oauthService.clientId)
      .set('redirect_uri', this.oauthService.redirectUri)
      .set('scope', this.oauthService.scope)
      .set('client_secret','secret');
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',

    });
    return this.http
      .post(tokenEndpoint, body.toString(), { headers })
      .toPromise();
  }
  sendData(data){
    this.app_component.initialSetup(data) // need to pass some data to that component
  }

}
