import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { DashboardWidgetService } from '../service/dashboard-widget.service';
import { DistDashbaordService } from 'src/app/Modules/Distributor_Survey/Services/dist-dashbaord.service';

@Component({
  selector: 'app-response-rate-grid',
  templateUrl: './response-rate-grid.component.html',
  styleUrls: ['./response-rate-grid.component.scss']
})
export class ResponseRateGridComponent implements OnInit, OnChanges {

  isLoading: boolean = true;
  dataNotFoundMessage;
  noData = null;
  @Input() gridData;
  @Input() closeGrid;
  @Input() dispositionStatus;
  @Output() closeGridEvent = new EventEmitter<boolean>();

  columns = [];
  tableHeader = null;
  tableTitle = null;
  tableDetails = [];
  loading: boolean = false;
  constructor() { }

  ngOnChanges() {
    this.paginationData();
  }

  ngOnInit() {

  }

  paginationData() {
    this.loading = false;
    if (this.dispositionStatus === 'Bounce Back') {
      this.tableDetails = [{
        total:  Math.round((this.gridData.BouncedBackCnt ? this.gridData.BouncedBackCnt : 0) +
        (this.gridData.BouncedBackCntCbb ? this.gridData.BouncedBackCntCbb : 0)),
        bounceBackCBB: this.gridData.BouncedBackCntCbb,
        bounceBack: this.gridData.BouncedBackCnt
      }];
      this.columns = [
        { field: 'bounceBackCBB', header: 'Corrected', tooltipText: 'Bounce Back Records corrected for selected date range' },
        { field: 'bounceBack', header: 'Still in Bounce Back', tooltipText: 'Bounce Back Records to be corrected'  },
        { field: 'total', header: 'Total Bounce Back' },
      ];
      this.tableTitle = 'Bounce Back Summary';
    } else if (this.dispositionStatus && (this.dispositionStatus.includes('Processed') ||
      this.dispositionStatus.includes('Delivered') ||
      this.dispositionStatus.includes('Clicked') ||
      this.dispositionStatus.includes('Opened'))) {
      this.tableDetails = [{
        total: this.gridData.NoResponse + this.gridData.NoResponseCbb,
        noResponse: this.gridData.NoResponse,
        noResponseCBB: this.gridData.NoResponseCbb
      }];
      this.columns = [
        { field: 'noResponse', header: 'Unique', tooltipText: 'Survey sent but not responded' },
        { field: 'noResponseCBB', header: 'Corrected', tooltipText: 'Bounce back record corrected but survey not responded' },
        { field: 'total', header: 'Total No Response' },
      ];
      this.tableTitle = 'No response Summary';
    } else if (this.dispositionStatus === 'Completed_CC') {
      this.tableDetails = [{
        total: this.gridData.CompletedCntWithoutNpsAndFns + this.gridData.CompletedCntWithoutNpsAndFnsCbb,
        completed: this.gridData.CompletedCntWithoutNpsAndFns,
        completedCBB: this.gridData.CompletedCntWithoutNpsAndFnsCbb
      }];
      this.columns = [
        { field: 'completed', header: 'Completed', tooltipText: 'Survey status as Completed in the selected date range' },
        { field: 'completedCBB', header: 'Completed_CBB', tooltipText: 'Bounce Back Records that are corrected and Survey status as completed in selected date range' },
        { field: 'total', header: 'Total Completed' }
      ];
      this.tableTitle = 'Completed Summary';
    } else if (this.dispositionStatus === 'Feedback not submitted') {
      this.tableDetails = [{
        total: this.gridData.FnsCurrentDateRangeCompleted + this.gridData.FnsCurrentDateRangeCompletedCbb,
        completed: this.gridData.FnsCurrentDateRangeCompleted,
        completedCBB: this.gridData.FnsCurrentDateRangeCompletedCbb
      }];
      this.columns = [
        { field: 'completed', header: 'Completed', tooltipText: 'Survey status as Completed in the selected date range' },
        { field: 'completedCBB', header: 'Completed_CBB', tooltipText: 'Bounce Back Records that are corrected and Survey status as completed in selected date range' },
        { field: 'total', header: 'Total Completed' }
      ];
      this.tableTitle = 'Completed Summary (Feedback not submitted)';
    } else if (this.dispositionStatus === 'NPS only') {
      this.tableDetails = [{
        total: this.gridData.NpsOnlyCompletedCntCurrentDateRange + this.gridData.NpsOnlyCompletedCBBCntCurrentDateRange,
        completed: this.gridData.NpsOnlyCompletedCntCurrentDateRange,
        completedCBB: this.gridData.NpsOnlyCompletedCBBCntCurrentDateRange
      }];
      this.columns = [
        { field: 'completed', header: 'Completed', tooltipText: 'Survey status as Completed in the selected date range' },
        { field: 'completedCBB', header: 'Completed_CBB', tooltipText: 'Bounce Back Records that are corrected and Survey status as completed in selected date range' },
        { field: 'total', header: 'Total Completed' }
      ];
      this.tableTitle = 'Completed Summary (NPS only)';
    } else if (this.dispositionStatus === 'RTI') {
      this.tableDetails = [{
        total: this.gridData.Rti + this.gridData.RtiCbb,
        RTI: this.gridData.Rti,
        RTICBB: this.gridData.RtiCbb
      }];
      this.columns = [
        { field: 'RTI', header: 'Reply to Invitation', tooltipText: 'Responses received as RTI for selected date range' },
        { field: 'RTICBB', header: 'Reply to Invitation_CBB', tooltipText: 'Bounce Back Records corrected and responded as RTI for selected date range' },
        { field: 'total', header: 'Total Reply to Invitation' },
      ];
      this.tableTitle = 'Reply to Invitation Summary';
    } else {
      let recCurFromPrev = this.gridData.RecCurFromPrev ? this.gridData.RecCurFromPrev : 0;
      let recInCur = this.gridData.RecInCur ? this.gridData.RecInCur : 0;
      let recCurFromPrevCount = this.gridData.RecCurFromPrevCount ? this.gridData.RecCurFromPrevCount : 0;
      let recInCurCount = this.gridData.RecInCurCount ? this.gridData.RecInCurCount : 0;
      this.tableDetails = [{
        total: '(' + Math.round(recCurFromPrevCount + recInCurCount) + ') ' + Math.round(recCurFromPrev + recInCur) + '%',
        RecCurFromPrev: '(' + recCurFromPrevCount + ') ' + recCurFromPrev + '%',
        RecInCur: '(' + recInCurCount + ') ' + recInCur + '%'
      }];
      this.columns = [
        { field: 'RecCurFromPrev', header: 'Response received from previous survey sent' },
        { field: 'RecInCur', header: 'Response received from current date range' },
        { field: 'total', header: 'Total response received' },
      ];
      this.tableTitle = 'Response received';
    }
  }
  onCloseGrid() {
    this.closeGridEvent.emit(false);
  }


}

