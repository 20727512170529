import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DialogService } from 'primeng/api';
import { debug } from 'util';
import { forEach } from '@angular/router/src/utils/collection';
@Injectable({
  providedIn: 'root'
})
export class SurveyService {


  private serviceUrl = this.sharedService.configuration.baseUrl;
  //private serviceUrl = "http:/loyalty.cemfalcon.com:12001/api/SurveyTemplate/GetAllSurveyTemplates"
  recordCount: number;

  constructor(private http: HttpClient, public dialogService: DialogService, private sharedService: SharedService) { }

  getTemplateList(filterObj) {
    //  let serviceUrl = serviceUrl+'api/SurveyTemplate/GetAllSurveyTemplates';
    return this.http.post(this.serviceUrl + 'api/SurveyTemplate/GetAllSurveyTemplates', filterObj)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });

  }
  getAllTemplatesWithCategory(templateCategoryId, languageId, currentTemplateId) {
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/GetTemplateNameByCategory/' + templateCategoryId + "/" + languageId + "/" + currentTemplateId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  getCategoryList() {
    //let serviceUrl = 'http://10.53.19.73:10001/api/MDM/Templates/Categories'
    return this.http.get(this.serviceUrl + 'api/MDM/Templates/Categories')
      //return this.http.get(serviceUrl)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }
  getChannelList() {
    //let serviceUrl = environment.baseUrl + 'api/MDM/GetTemplateCategories';
    // let serviceUrl = 'http://10.53.19.73:10001/api/SurveyTemplate/Channels'
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/Channels')
      //return this.http.get(serviceUrl)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }
  getTypeList() {
    //let serviceUrl = 'http://10.53.19.73:10001/api/MDM/Templates/Types'
    return this.http.get(this.serviceUrl + 'api/MDM/Templates/Types')
      //return this.http.get(serviceUrl)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }
  getLanguageList() {
    //let serviceUrl = environment.baseUrl + 'api/MDM/GetTemplateCategories';
    //let serviceUrl = 'http://10.53.19.73:10001/api/MDM/Languages'
    return this.http.get(this.serviceUrl + 'api/MDM/Languages')
      //return this.http.get(serviceUrl)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }
  getTouchPointList() {
    //let serviceUrl = 'http://10.53.19.73:10001/api/MDM/TouchPoints'
    return this.http.get(this.serviceUrl + 'api/MDM/TouchPoints')
      //  return this.http.get(serviceUrl)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  createTemplate(templateObj) {
    //let serviceUrl = environment.baseUrl + 'api/MDM/GetTemplateCategories';
    // let serviceUrl = 'http://PTD22903.persistent.co.in:10001/api/SurveyTemplate/AddSurveyTemplate'
    return this.http.post(this.serviceUrl + 'api/SurveyTemplate/AddSurveyTemplate', templateObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  updateTemplate(templateObj) {
    //let serviceUrl = environment.baseUrl + 'api/MDM/GetTemplateCategories';
    // let serviceUrl = 'http://PTD22903.persistent.co.in:10001/api/SurveyTemplate/UpdateSurveyTemplate'
    return this.http.post(this.serviceUrl + 'api/SurveyTemplate/UpdateSurveyTemplate', templateObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getTemplateDtaById(templateId) {
    //let serviceUrl = environment.baseUrl + 'api/MDM/GetTemplateCategories';
    //let serviceUrl = 'http://PTD22903.persistent.co.in:10001/api/SurveyTemplate/GetSurveyTemplateById/' + templateId;
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/GetSurveyTemplateById/' + templateId + "?" + Math.random())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  getAllQuestionsFromQuestionBank(qCategory, qText, lId) {
    //let serviceUrl = environment.baseUrl + 'api/MDM/GetTemplateCategories';
    //let serviceUrl = 'http://PTD22903.persistent.co.in:10001/api/SurveyTemplate/GetSurveyTemplateById/' + templateId;
    var qutext = encodeURIComponent(qText);
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/PickQuestionFromQuestionBank/' + qCategory + '/' + qutext + '/' + lId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }


  getAllQuestionsFromTemplates(qCategory, tId, qText, lId, ctId) {
    //let serviceUrl = environment.baseUrl + 'api/MDM/GetTemplateCategories';
    //let serviceUrl = 'http://PTD22903.persistent.co.in:10001/api/SurveyTemplate/GetSurveyTemplateById/' + templateId;
    // http://PTD22903.persistent.co.in:10001/api/SurveyTemplate/PickQuestionFromTemplate/0/0/null/1/3
    var qutext = encodeURIComponent(qText);
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/PickQuestionFromTemplate/' + qCategory + '/' + tId + '/' + qutext + '/' + lId + '/' + ctId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getQuestionCategoryList() {
    return this.http.get(this.serviceUrl + 'api/MDM/Questions/Categories')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }


  createSurvey(surveyObject) {
    return this.http.post(this.serviceUrl + 'api/Survey/AddSurvey', surveyObject)
      .toPromise()
      .then((res: any) => { return res });
  }

  savequestionsToTemplate(questionListObj) {
    //let serviceUrl = environment.baseUrl + 'api/MDM/GetTemplateCategories';
    // let serviceUrl = 'http://PTD22903.persistent.co.in:10001/api/SurveyTemplate/UpdateSurveyTemplate'
    return this.http.post(this.serviceUrl + 'api/SurveyTemplate/AddQuestionsForSurveyTemplate', questionListObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  savequestionsToTemplateAsDraft(questionListObj) {
    return this.http.post(this.serviceUrl + 'api/SurveyTemplate/ReOrderQuestionsForSurveyTemplate', questionListObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  publishTemplate(questionListObj) {
    return this.http.post(this.serviceUrl + 'api/SurveyTemplate/PublishSurveyTemplate', questionListObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  GetTemplateTranslatedLanguages(TemplateId) {
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/GetTemplateTranslatedLanguages/' + TemplateId)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  GetTemplateDetailsInApprovedLanguage(TemplateId, LanguageId) {
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/GetTemplateDetailsInApprovedLanguage/' + TemplateId + "/" + LanguageId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  UpdateUserPreferenceCreateTemplate(isSkip) {
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/UpdateUserPreferenceCreateTemplate/' + isSkip)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  UserAccessGetDetails() {
    return this.http.get(this.serviceUrl + 'api/UserAccess/GetDetails')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  GetTemplateTranslation(TemplateId) {
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/GetTemplateTranslation/' + TemplateId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  deleteQuestionFromTemplate(questionObj) {
    return this.http.post(this.serviceUrl + 'api/SurveyTemplate/DeleteQuestionsFromSurveyTemplate', questionObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  GetImpactedFAMList(templateId) {
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/GetImpactedFAMOnEditTemplate/' + templateId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  openModalPopup(componentName) {
    const ref = this.dialogService.open(componentName, {
      // data: {
      //   id: '51gF3'
      // },s
      header: 'Survey Template Details',
      width: '70%',
      height: '70%'
    });
  }

  // for signature grid
  getSignatureList(urlParams) {
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/GetSignatures' + urlParams + '&buster=' + new Date().getTime() )
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  // For Getting all data for survey

  getAllFamdata() {
    // this.setStorage(this.serviceUrl + 'api/mdm/BusinessAreas');
    return this.http.get(this.serviceUrl + 'api/mdm/BusinessAreas')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getTouchpoints() {
    // this.setStorage(this.serviceUrl + 'api/mdm/TouchPoints');
    return this.http.get(this.serviceUrl + 'api/mdm/TouchPoints')
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  getSurveyDetailsById(surveyid) {
    return this.http.get(this.serviceUrl + 'api/survey/GetSurveyById/' + surveyid)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  // setStorage(url){
  //   var storedObj = localStorage.getItem('urlData');
  //   if(storedObj){
  //     var jsonData = JSON.parse(storedObj);
  //     jsonData.push(url);
  //     localStorage.setItem('urlData', JSON.stringify(jsonData));
  //   }else{
  //     var temp = [];
  //     temp.push(url);
  //     localStorage.setItem('urlData', JSON.stringify(temp));
  //   }
  // }


  getSignatureDetail(urlParams) {
    return this.http.get(this.serviceUrl + 'api/SurveyTemplate/GetSignatureByID' + urlParams + '/' + new Date().getTime() )
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  // to save signature details
  saveSignatureDetail(objSignatureModel) {
    return this.http.post(this.serviceUrl + 'api/SurveyTemplate/SaveSignature?buster=' + new Date().getTime(), objSignatureModel)
      .toPromise()
      .then((res: any) => res);
  }

  // to submit signature details
  submitSignature(objSignatureModel) {
    return this.http.post(this.serviceUrl + 'api/SurveyTemplate/PostSubmitSignature?buster=' + new Date().getTime(), objSignatureModel)
      .toPromise()
      .then((res: any) => res);
  }

}
