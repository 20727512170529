import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-radio-scale',
  templateUrl: './radio-scale.component.html',
  styleUrls: ['./radio-scale.component.scss']
})
export class RadioScaleComponent implements OnInit {

  @Input()
  min:number=0;

  @Input()
  max:number=10;
  rangeArray=[];

  @Input()
  rating:number=-1;

  @Input()
  canRate:boolean=false;

  avgRating=5;
  goodRating=8;

  constructor() { 
   
  }

  ngOnInit() {
    for(let i=this.min;i<=this.max;i++){
      this.rangeArray.push(i);
    }
  }

  rate(value){
    if(this.canRate){
      this.rating=value;
    }
  }

}
