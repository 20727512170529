import { ChangeDetectorRef, Component, Input, OnInit, SimpleChange, SimpleChanges, QueryList, ViewChildren, ElementRef, ViewChild, Pipe, PipeTransform, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CtlsNotificationService } from 'src/app/Modules/ctls/ctls-shared/ctls-services/ctls-notification.service';
import { ConfirmationService, Message } from 'primeng/components/common/api';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Router } from '@angular/router';
import { CtlsAddRuleSetService } from 'src/app/Modules/ctls/ctls-shared/ctls-services/ctls-add-rule-set.service';
import { CtlsClosureTemplateListingService } from 'src/app/Modules/ctls/ctls-shared/ctls-services';
import { SharedService } from '../../services/shared.service';
import { CtlsRulePopoverComponent } from 'src/app/Modules/ctls/ctls-pages/ctls-ruleset/presentation/ctls-rule-popover/ctls-rule-popover.component';
import { DomSanitizer } from '@angular/platform-browser';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input('errorCount') errorCount: number;
  @Input('notificationCount') notificationCount: number;
  @Input('objNotificationResponse') objNotificationResponse: any;
  @Input() showBusyIndicator: boolean;
  @ViewChildren('opMsg') private popoverlay: QueryList<any>;
  @ViewChild('op') private notifyPopOverlay: OverlayPanel;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Output() eventHandler: EventEmitter<any> = new EventEmitter<any>();
  countNotification: any;
  lenOne: boolean = false;
  notificationResponse: any;
  busyIndicator: boolean = true;
  showNotification = false;
  ngOnChanges(changes: SimpleChanges) {
    console.log("change", changes)
    // let changeErrorCount: SimpleChange = changes.errorCount;
    let changeNotificationCount: SimpleChange = changes.notificationCount;
    let changeObjNotificationResponse: SimpleChange = changes.objNotificationResponse;
    let changeBusyIndicator: SimpleChange = changes.showBusyIndicator;
    if (changeBusyIndicator && changeBusyIndicator.currentValue != null) {
      this.busyIndicator = changeBusyIndicator.currentValue;
    }

    if (changeNotificationCount && changeNotificationCount.currentValue) {
      this.countNotification = changeNotificationCount.currentValue;
    }

    if (changeObjNotificationResponse && changeObjNotificationResponse.currentValue) {

      this.notificationResponse = changeObjNotificationResponse.currentValue;


    }
  }
  items = [
    { label: 'Task', id: 0 },
    { label: 'Survey', id: 1 },
    { label: 'Rule', id: 2 },
    { label: 'Template', id: 3 },
  ];
  selectedValue: any = 'TASK';
  activeItem: any;
  destroy$ = new Subject();
  NotifyResponse = {
    Task: [],
    Survey: [],
    Rule: [],
    Template: []
  }
  itemsArray = [];
  totalArray = [];
  taskData = [];
  surveyData = [];
  temData = [];
  ruleData = [];
  successMsg = false;

  constructor(
    private ctlsNotificationService: CtlsNotificationService,
    private confirmationService: ConfirmationService,
    private ref: ChangeDetectorRef,
    public router: Router,
    private addRuleSetService: CtlsAddRuleSetService,
    private closureTemplateListingService: CtlsClosureTemplateListingService,
    private sharedService: SharedService,
    private sanitized: DomSanitizer
  ) { }

  ngOnInit() {
    this.activeItem = this.items[0];
    this.selectedValue = this.activeItem ? this.activeItem.label : null;
  }

  description(item) {
    let id = '';
    if (!!item.CTLSRuleID)
      id = item.CTLSRuleID;
    if (!!item.CTLSRuleSetID)
      id = item.CTLSRuleSetID;
    if (!!item.CTLSTaskClosureTemplateID)
      id = item.CTLSTaskClosureTemplateID;
    if (!!item.CTLSTaskListID)
      id = item.CTLSTaskListID;
    if (item.Category && item.LinkCategory) {
      this.router.navigate(['/ctls/routing-notification'], {
        queryParams: {
          SurveyType: item.Category,
          category: item.LinkCategory,
          id: id
        }
      });
      this.notifyPopOverlay.hide();
    }
  }

  checkIsClickable(item) {
    /* Type Casting */
    item = { ...item, SubCategory: item.Category }

    if (item.TranTypeCategory === "Survey") {

    }
    if (item.TranTypeCategory === "Task" && item.TranType.toUpperCase() != "TASK DELETION") {
      return true;
    }
    if (item.TranTypeCategory === "Rule" && (item.TranType.toUpperCase() != "RULE DELETION" || item.TranType.toUpperCase() != "RULESET DELETION")) {
      //Rule Restore Request Rejected - no need for redirection
      if (item.CTLSRuleID) { // edit rule
        return true;

      }

      if (item.CTLSRuleSetID) { // edit rule set
        return true
      }



    }
    if ((item.TranTypeCategory === "Template") && item.TranType.toUpperCase() != "CLOSURE TEMPLATE DELETION") {
      return true;
    }

    return false;

  }
  navigateClosureTemplate(rowData: any) {
    if (rowData.CTLSTaskClosureTemplateID) {
      this.closureTemplateListingService
        .GetRefreshedData(rowData.CTLSTaskClosureTemplateID)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          console.log("Closure template", res)
          if (res.Status == 'SUCCESS' && res.Response) {
            rowData = res.Response;
            rowData.editTemplate = true;
            if (!!rowData.BrandID) {
              let objBrand = {
                BrandID: rowData.BrandID,
                BrandName: rowData.Brand
              }
              rowData.objBrand = JSON.stringify(objBrand);
            }
            rowData.SetDefault = JSON.stringify(rowData.SetDefault);
            rowData.IsGlobal = JSON.stringify(rowData.IsGlobal);
            rowData.SaveAsDraft = JSON.stringify(rowData.SaveAsDraft);
            rowData.CTLSBrandLogosAndHeaderImages = JSON.stringify(rowData.CTLSBrandLogosAndHeaderImages);
            this.router.navigate(['/ctls/setup/task-closure-listing/add-template'], {
              queryParams: rowData,
              //   skipLocationChange: true,
            });
            this.notifyPopOverlay.hide();
          }
        });
    }
  }
  isTaskTab: boolean = true;
  activateMenu({ activeItem }) {
    console.log("active tab", activeItem)
    this.currentScroll = null;
    this.selectedValue = activeItem ? activeItem.label : null;
    if (activeItem.label.toUpperCase() == 'TASK') {
      this.isTaskTab = true;
    } else {
      this.isTaskTab = false;
    }

  }

  dismissItem(id) {
    console.log('id', id.NotificationId)
  }

  dismissAll() {
    this.busyIndicator = true;
    let payload = [];
    switch (this.selectedValue.toUpperCase()) {
      case 'TASK':
        payload = this.notificationResponse.Task.map((i) => i.NotificationId);
        break;
      case 'TEMPLATE':
        payload = this.notificationResponse.Template.map((i) => i.NotificationId);
        break;
      case 'RULE':
        payload = this.notificationResponse.Rule.map((i) => i.NotificationId);
        break;
      case 'SURVEY':
        payload = this.notificationResponse.Survey.map((i) => i.NotificationId);
        break;
    }

    this.ctlsNotificationService
      .getDismissBellNotifications(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.ref.detectChanges();

        if (data.Response) {
          switch (this.selectedValue.toUpperCase()) {
            case 'TASK':
              this.notificationResponse.Task = [];
              break;
            case 'TEMPLATE':
              this.notificationResponse.Survey = [];
              break;
            case 'RULE':
              this.notificationResponse.Template = [];
              break;
            case 'SURVEY':
              this.notificationResponse.Rule = [];
              break;
          }
          this.notificationResponse=Object.assign({},this.notificationResponse);
          this.busyIndicator = false;
        }

        this.sharedService.loadnotifyCount();
        this.sharedService.loadnotify();

      })
  }
  //busyIndicator: boolean = false;
  deleteTask(items) {

    console.log("delete", this.notificationResponse[this.selectedValue], items)

    this.busyIndicator = true;
    if (!!items.length) {
      this.itemsArray = [];
      this.itemsArray.push(items[0].NotificationId);
    }
    this.ctlsNotificationService
      .getDismissBellNotifications(this.itemsArray)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data.Response) {
          let afterDeletedRecord = this.notificationResponse[this.selectedValue].filter(x => x.NotificationId !== items[0].NotificationId);
          this.notificationResponse = {
            ...this.notificationResponse,
            [this.selectedValue]: afterDeletedRecord
          }
          this.notificationResponse = Object.assign({}, this.notificationResponse)
          this.busyIndicator = false;
        }
        //this.successMsg = true;
        //this.busyIndicator=false;
        // this.callErrorMsg('error', '', rawResponse.Errors[0].Message);

        this.sharedService.loadnotifyCount();
        this.sharedService.loadnotify();

        this.ref.detectChanges();
      })
  }
  callErrorMsg(arg0: string, arg1: string, Message: any) {
    throw new Error('Method not implemented.');
  }

  showPopoverlay(overlayTemp, event, last, data) {
    //this.ctlsRuleSetService.setOnClickOfRule(true)
    if (data && data.length == 1) {
      this.lenOne = true;
    } else {
      this.lenOne = false;
    }

    if (this.isLastLastOne) {
      this.popoverlay.toArray()[this.isLastLastOne].hide(event);
    } else {
      this.isLastLastOne = null;
    }
    this.popoverlay.toArray()[overlayTemp].show(event);

    if (last) {

      this.scrollToBottom();
      this.isLastLastOne = overlayTemp;
    }

  }
  isLastLastOne: any = null;
  hidePopoverlay(overlayTemp, event, last) {
    //this.ctlsRuleSetService.setOnClickOfRule(true)
    /* if (this.isLastLastOne) {
      return;
    } */
    if (last) {
      this.isLastLastOne = overlayTemp;
      /* setTimeout(() => {
        this.popoverlay.toArray()[overlayTemp].hide();
      }, 2000) */
    } else {
      this.popoverlay.toArray()[overlayTemp].hide();
    }

  }
  hideOnePopoverlay(overlayTemp, event, last, data) {
    console.log("One hide", this.isLastLastOne)
    if (this.isLastLastOne) {
      console.log("hide IN")
      this.popoverlay.toArray()[this.isLastLastOne].hide();
      this.isLastLastOne = null;
    }



    /* if (data.length === 1) {
      this.popoverlay.toArray()[overlayTemp].hide();
      this.isLastLastOne = null;
    
    } */

  }
  currentScroll: any = null;
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      this.currentScroll = this.myScrollContainer.nativeElement.scrollHeight;


    } catch (err) { }
  }

  getColorCss(data) {
    if (data && this.isTaskTab && data.PriorityColour) {
      return {
        'border-left': '5px solid ' + data.PriorityColour
      };
    }

  }

  getTooltipContent(data) {
    return this.sanitized.bypassSecurityTrustHtml(data.replace(";'>", " !important;'>").replace("</ span >", "</span>"));
  }
  onClickNotification(el) {
    this.eventHandler.emit({
      showNotification: el.visible
    });
  }
}