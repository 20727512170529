import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CtlsRulePopoverComponent } from './ctls-rule-popover.component';

@NgModule({
  imports: [CommonModule,
    OverlayPanelModule,],
  declarations: [CtlsRulePopoverComponent],
  exports: [CtlsRulePopoverComponent],
})
export class CtlsRulePopoverModule {}
