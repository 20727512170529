import { Injectable } from '@angular/core';
import { WidgetMaster, Widget } from '../models/dashboard-models/dashbaord.models';
import { widgetCategory } from '../enums/widget.category';
import { DashboardEvent} from '../enums/dashboard.event'

@Injectable({
  providedIn: 'root'
})
export class RelationshipDashboardSharedService {

  widgetListMaster : WidgetMaster;

  constructor() {
    this.widgetListMaster = new WidgetMaster();
   }

  public constructSubList(widgetList: Array<Widget>, event : DashboardEvent){
    this.widgetListMaster.Event = event;
    this.widgetListMaster.LeftWidgetList = widgetList
    .filter(w=> w.Category === widgetCategory.Left)
    .sort((a, b) => { return parseFloat(a.Position.charAt(1)) - parseFloat(b.Position.charAt(1)) });
    
    this.widgetListMaster.CenterWidgetList = widgetList
    .filter(w=> w.Category === widgetCategory.Center)
    .sort((a, b) => { return parseFloat(a.Position.charAt(1)) - parseFloat(b.Position.charAt(1)) });

    this.widgetListMaster.DrillDownWidgetList = widgetList
    .filter(w=> w.Category === widgetCategory.DrillDown)
    .sort((a, b) => { return parseFloat(a.Position.charAt(1)) - parseFloat(b.Position.charAt(1)) });    
  }
}
