import { Component, Input, AfterViewInit, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import HighchartsWordCloud from 'highcharts/modules/wordcloud';
import { environment } from 'src/environments/environment';
HighchartsWordCloud(Highcharts);
@Component({
  selector: 'app-wordcloud',
  templateUrl: './wordcloud.component.html',
  styleUrls: ['./wordcloud.component.scss']
})
export class WordcloudComponent implements OnChanges {
  Highcharts: typeof Highcharts = Highcharts;
  @Input() data: any;
  @Input() chartTitle: string = '';
  @Input() isDualyAxes?: boolean = false;
  @Input() chartIdentifier: string = '';
  @Input() chartState: CHART_STATE_OPTIONS;
  chartInstance: any;
  private imagepath = environment.imagepath;

  chartOptions: Highcharts.Options = {
    title: {
      text: null
      , verticalAlign: 'top',
      align: 'left',
      style: {
        fontFamily: 'Source Sans Pro Regular',
        fontSize: '1.3em',
        fontWeight: 'bold',
        color: '#333333'
      }
    }
  };
 // chartOptions: Highcharts.Options = {};

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    //this.data = [...this.data]
    //if (this.data) {
      // setTimeout(() => {
        this.renderWordCloudChart();
      // }, 500);
    //}
  }

  ngAfterViewInit(): void {
        setTimeout(() => {
      this.renderWordCloudChart();
    }, 500);
    //this.renderWordCloudChart();
  }

  private renderWordCloudChart() {
    const minFontSize = 4;
    const maxFontSize = 20;
    const minNormalizedSize = 4;
    // const centerPlacement = function centerPlacement(point, options) {
    //   return {
    //     x: 1,
    //     y: 1,
    //     rotation: 0
    //   };
    // };

    // const ovalSpiral = function ovalSpiral(t) {
    //   t *= 0.06;
    //   return {
    //     x: t * Math.cos(t) * 2,
    //     y: t * Math.sin(t)
    //   };
    // };

  //   var archimedeanSpiral = function archimedeanSpiral(t) {
  //     t *= 0.1;
  //     return {
  //         x: t * Math.cos(t),
  //         y: t * Math.sin(t)
  //     };
  // };
    
 // Highcharts.seriesTypes.wordcloud.prototype.spirals.archimedean = archimedeanSpiral;
 this.chartOptions = {
  chart: {
    type: 'wordcloud',
    // width:'1000',
    // margin: 50,
    marginLeft:50,
    marginRight:50,
    events: {
      load: (event: any) => {
        const chart = event.target;

        // Set an initial font size for all words
        chart.series[0].points.forEach((point: any) => {
          if (point.graphic) {
            point.graphic.css({
              fontSize: `${minFontSize}px`,
            });
          }
        });

        // Delay the normalization process
        setTimeout(() => {
          // Find the maximum weight for normalization
          const maxWeight = Math.max(...this.data.map((item: any) => item.weight));

          // Normalize the font size based on the occurrence count
          chart.series[0].points.forEach((point: any) => {
            if (point.graphic) {
              const normalizedSize = Math.max(
                minNormalizedSize,
                minFontSize + (maxFontSize - minFontSize) * (point.weight / maxWeight)
              );
              console.log(normalizedSize, point.weight)
              // const normalizedSize = point.weight + 4;
              // if()
              point.graphic.css({
                fontSize: normalizedSize + 'px',
              });
            }
          });
        }, 500); // Adjust the delay as needed
      },
    },
  },
  series: [
    {
      type: 'wordcloud',
      rotation: {
        from: 0,
        to: 0,
        // orientations: 0
      },
     
      data: this.data,
      name: 'Occurrences',
      // minFontSize: minFontSize,
      // maxFontSize: maxFontSize,
    }
  ],
  title: {
    text: '',
    verticalAlign: 'top',
    align: 'left',      
    style: {
      fontFamily: 'Source Sans Pro Regular',
      fontSize: '1.3em',
      fontWeight: 'bold',
      color: '#333333',
     
    }
  },
  plotOptions: {
   
    wordcloud: {
     
      placementStrategy: 'center',
      spiral: 'rectangular',
      rotation: {
        from: 0,
        to: 0
      },

    },

  },
  tooltip: {
    followPointer: true,
    headerFormat: '<span style="font-size: 16px"><b>{point.key}</b></span><br>'
  },
};
    //const container = document.getElementById('container');
    //Highcharts.chart(container, this.chartOptions);
  }

  logChartInstance(chart: Highcharts.Chart) {
    this.chartInstance = chart;
  }
}
