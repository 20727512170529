import { Component, OnInit,ViewChild,Output, Input, EventEmitter } from '@angular/core';
import { LostBusinessLBCTotalDispodrilldown, LostBusinessStateModel } from '../model/lost-business-lbcdrilldown';
import {LostBusinessDrilldownService} from '../service/lost-business-drilldown.service';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Paginator } from 'primeng/paginator';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-lostbusi-totalcount-dispositionlist',
  templateUrl: './lostbusi-totalcount-dispositionlist.component.html',
  styleUrls: ['./lostbusi-totalcount-dispositionlist.component.scss']
})
export class LostbusiTotalcountDispositionlistComponent implements OnInit {
  private imagepath = environment.imagepath;
  [x: string]: any;
  @ViewChild(Paginator) dt: Paginator;
  @Output('parameter') parameter: EventEmitter<any> = new EventEmitter<any>();
  @Output('Previous') Previous: EventEmitter<any> = new EventEmitter();
  @Output('divclose') divclose:EventEmitter<any>=new EventEmitter<any>();
  private Total1stddNavSubject: Subscription;

  @Input() showTotalText: any;
  @Input() totalCustomerCount: number;
  @Input() lostBaseYear:any;
  drilldownListData: LostBusinessLBCTotalDispodrilldown[];
  cols: any[];
  totalRecords: number;
  loading: boolean = false;
  pageNo = 1;
  pageSize = 10;
  configRow: number[] = [10, 20, 30, 40, 50];
  sortObj: any = {
    'sortType': '',
    'sortField': ''
  };
  accountNo: string = '';
  customerName: string='';
  flag: any;
  successMsg: boolean = false;
  msgs: Message[] = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;

  showNoData: boolean = false;
  showErrorData: boolean = false;
  genericErrorMessage:any;
  dispositionData:any[];
  customersDataOptions:any[];
  accNo :any;
  complDispositionTxt: any;
  compDispCount: any;
  didNotDispositionTxt: any;
  didNotDispCount: any;
  optoutDispositionTxt: any;
  optoutDispCount: any;
  XYDispositionTxt: any;
  XYDispCount: any;
  notSentDispositionTxt: any;
  notSentDispCount: any;
  RTIDispositionTxt: any;
  RTIDispCount: any;
  otherDispositionTxt: any;
  otherDispCount: any;
  ExclusionDispositionTxt:any;
  ExclusionDispCount:any;
  private transCountSubject: Subscription;
  transData: LostBusinessStateModel | null = null;
  paramValue:any;
  FAMID: number;
  FAMCode: string='';
  BAID:number;
  Data: any;
  constructor(private LostBusinessDrilldownService : LostBusinessDrilldownService
    , private messageService: MessageService
    , private sharedService: SharedService) {
      this.transData = {...this.LostBusinessDrilldownService.ddNav1GetData()};
      this.Data = this.transData.Data;
       //console.log(Data, 'TransDataNav');
       this.accountNo = this.Data.accountNumber;
       this.customerName = this.Data.custName;
       this.FAMCode = this.Data.FAMCode;
       this.FAMID = this.Data.FAMID;
       this.BAID = this.Data.BAID;
       ["IsActive","accountNumber", "custName", "FAMCode", "FAMID", "BAID","DispositionStatus", "DispositionCount"].forEach(el => {
         if(this.Data[el]){
                  delete this.Data[el];
         }
 
        } )
 
       this.Total1stddNavSubject = this.LostBusinessDrilldownService.Total1stddNavSubject.subscribe(cnt => {
        if (cnt == true) {
          this.GotoTransList();
        }
      });
    }

  ngOnInit() {
    this.GetDispositionList(this.pageNo, this.pageSize, this.sortObj.SortField, this.sortObj.sortType, this.accountNo, this.customerName,this.FAMID,this.BAID);
  }
  getTransRecord(count) {
   this.accNo = count;
  // console.log(this.accNo, 'accNo');
   this.GetDispositionList(this.pageNo, this.pageSize, this.sortObj.SortField, this.sortObj.sortType, this.accountNo, this.customerName,this.FAMID,this.BAID);
  }
  GetDispositionList(pageNo, pageSize, sortField, sortType, accountNo, customerName,FAMID,BAID) {
    this.loading = true;
    this.drilldownListData = [];
    this.drilldownListData.push({} as LostBusinessLBCTotalDispodrilldown);
    this.LostBusinessDrilldownService.getTotalDispositionList(pageNo,pageSize,sortField,sortType,accountNo,customerName,FAMID,BAID).then(rawData => {
      if (rawData == null ) {
        this.drilldownListData = [];
        this.totalRecords = 0;
        this.pageNo = pageNo;
        this.pageSize = pageSize;
        this.loading = false;
      } else if(rawData.Status ==="Success" && rawData.Response.Collection.length == 0){
        this.showNoData = true;
        this.loading = false;
      } else if(rawData.Status =="Failure"){
        this.showErrorData = true;
        this.loading = false;
      } else {
        let rawResponse: any = rawData
        if (rawResponse.Status === 'Success') {
          // let data = rawResponse.Response;
          // console.log(data,"data");
          // this.drilldownListData = data.Collection;
          // console.log( this.drilldownListData,"drilldowndata");

          if (rawData['Response'] != null && rawData['Status'] == 'Success') {
            this.dispositionData = rawData['Response']['Collection'].map(e => {
              return { 'code': e.DispositionStatus, 'name': e.count };
            });
            this.customersDataOptions = this.dispositionData;
            for (let elem in this.customersDataOptions) {
              if (this.customersDataOptions[elem].code === 'Completed') {
                this.complDispositionTxt = 'surveys were completed ';
                this.compDispCount = this.customersDataOptions[elem].name;
              }  else if (this.customersDataOptions[elem].code ==='Did not respond ') {
                this.didNotDispositionTxt = 'surveys have not been responded to';
                this.didNotDispCount = this.customersDataOptions[elem].name;
              }  else if (this.customersDataOptions[elem].code === 'Opted Out') {
                this.optoutDispositionTxt = 'contacts opted out of the survey';
                this.optoutDispCount = this.customersDataOptions[elem].name;
              } else if (this.customersDataOptions[elem].code === 'Blocked in X/Y rule') {
                this.XYDispositionTxt = 'surveys were not sent as they are blocked due to X/Y rule ';
                this.XYDispCount = this.customersDataOptions[elem].name;
              } else if (this.customersDataOptions[elem].code === 'Survey not yet sent') {
                this.notSentDispositionTxt = 'surveys have not been sent yet due to issues like bounce backs, etc.';
                this.notSentDispCount = this.customersDataOptions[elem].name;
              }
              else if (this.customersDataOptions[elem].code === 'Reply to Invitation') {
                this.RTIDispositionTxt = 'surveys were replied to invitation';
                this.RTIDispCount = this.customersDataOptions[elem].name;
              }
              else if (this.customersDataOptions[elem].code === 'No-Send/Opt-out') {
                this.otherDispositionTxt = 'surveys were not sent as they are blocked due to a business exception  ';
                this.otherDispCount = this.customersDataOptions[elem].name;
              }
              else if (this.customersDataOptions[elem].code === 'Excluded Customers') {
                this.ExclusionDispositionTxt = 'surveys were not sent as they are excluded customers';
                this.ExclusionDispCount = this.customersDataOptions[elem].name;
              }
            }
          }
          this.loading = false;
        }}
    }).catch(e => {
      this.drilldownListData = null;
      this.successMsg = false;
      this.showErrorData = true;
      this.loading = false;
      //this.callErrorMsg('error', '', this.genericErrorMessage);
    });
  }
  getCustomerList(name, count) {
    if (count > 0) {
      this.paramValue = {
        accountNumber : this.accountNo,
        custName : this.customerName,
        DispositionStatus : name ,
        DispositionCount : count,
        FAMID : this.FAMID,
        FAMCode : this.FAMCode,
        BAID : this.BAID,
        ...this.Data
      }
       this.parameter.emit(this.paramValue);
    } else {
      return null;
    }
  }
  onClickPrevious(){
    this.Previous.emit(this.Data);
 }

 Hidediv(){
  this.divclose.emit();
}
GotoTransList(){
  this.Previous.emit();
}
}
