import { Component, OnInit, EventEmitter, Output, Input, OnChanges, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { Message, SelectItem } from 'primeng/components/common/api';
import * as moment from 'moment';
import { ScrollPanel } from 'primeng/scrollpanel';
import { SharedService } from 'src/app/shared/services/shared.service';
import { surveyType } from '../../constants/shared.constants';
import { SurveySubCategory } from '../../constants/survey.sub.category';
import { RevenueFilterService } from '../../services/revenue-filter.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-revenue-filter',
  templateUrl: './revenue-filter.component.html',
  styleUrls: ['./revenue-filter.component.scss']
})
export class RevenueFilterComponent implements OnInit, OnChanges {
  private imagepath = environment.imagepath;
  @Input() surveySubCategory: string;
  @Input() surveyType: string = surveyType.Revenue;
  @Input() SubCategory: string;
  
  showBusyIndicator: boolean = false;
  //Master data properties
  data = [];
  countryContinent = [];
  brand = [];
  yearList: any = [];

  dataOriginal = [];
  countryContinentOriginal = [];
  brandOriginal = [];
  distributorSegmentOriginal = [];
  distributorOriginal = [];
  industrySegmentOriginal = [];
  famData = [];
  baData = [];
  countryData = [];
  brandData = [];
  selectedYears: string = "";

  distributorData = [];
  distributorSegmentData = [];
  industrySegmentData = [];
  countinentData = [];
  msgs: Message[] = [];
  isShowAlert: boolean = false;
  successMsg: boolean = false;
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  OldSubCategory: string;

  //selected filter
  filterMapping: any = {
    FAMId: null,
    BAId: null,
    CountryId: null,
    BrandId: null,
    distributorType: null,
    distributor: null
  };

  pinnedFields = {
    baPinned: true,
    famPinned: true,
    countryPinned: true,
    brandPinned: true,
    distributorPinned: false,
    distributorSegmentPinned: false,
  }

  pinnedFieldValuess = {
    baPinnedValue: "All",
    famPinnedValue: "All",
    countryPinnedValue: "All",
    brandPinnedValue: "All",
    distributorPinnedValue: "All",
    distributorSegmentPinnedValue: "All",
  }

  pinnedFieldValuesArray = {
    baPinnedValue: [],
    famPinnedValue: [],
    countryPinnedValue: [],
    brandPinnedValue: [],
    distributorPinnedValue: [],
    distributorSegmentPinnedValue: [],
  }

  inlineFilter = {
    baInline: false,
    famInline: false,
    countryInline: false,
    brandInline: false,
    distributorInline: false,
    distributorSegmentInline: false
  }

  countOfAllData = {
    baCount: 0,
    famCuunt: 0,
    countryCount: 0,
    brandCount: 0,
    distributorCount: 0,
    distributorSegmentCount: 0
  }

  selectedCount = {
    baCount: [],
    famCount: [],
    countryCount: [],
    brandCount: [],
    distributorCount: [],
    distributorSegmentCount: []
  }

  periodType = {
    M: true,
    Q: false,
    H: false,
    Y: false,
  }

  delimiter = "|";
  filterToggle: boolean = false;
  filterDateToggle: boolean = false;
  pinFilterSecVisible: boolean = false;
  dateTime = new Date();
  defaultDate = new Date();
  isPeriodType: boolean = false;
  isDateRange: boolean = true;
  StartDate = "";
  EndDate = "";
  startDateCal = "";
  endDateCal = "";
  selectedValues: number[] = [];

  @Input('npsWidgetStatus') npsWidgetStatus;
  @Input('savedFilterObject') savedFilterObject
  @Input('savedFilterObjectFromDB') savedFilterObjectFromDB
  @Output('submitted') submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output('saved') saved: EventEmitter<string> = new EventEmitter<string>();
  @Output('pinChanged') pinChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output('onPinnedValueChange') onPinnedValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output('reset') reset: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('famDDL') famDDL;
  @ViewChild('baDDL') baDDL;
  @ViewChild('countryDDL') countryDDL;
  @ViewChild('brandDDL') brandDDL;
  @ViewChild('distributorDDL') distributorDDL;
  @ViewChild('distributorSegmentDDL') distributorSegmentDDL;

  @ViewChild('famDDLPinned') famDDLPinned;
  @ViewChild('baDDLPinned') baDDLPinned;
  @ViewChild('countryDDLPinned') countryDDLPinned;
  @ViewChild('brandDDLPinned') brandDDLPinned;
  @ViewChild('distributorDDLPinned') distributorDDLPinned;
  @ViewChild('distributorSegmentDDLPinned') distributorSegmentDDLPinned;

  constructor(private filterService: RevenueFilterService, private sharedService: SharedService) { }

  ngOnInit() { 
  }

  ngOnChanges() {
    this.surveyType = this.SubCategory;

    if (this.surveyType == "CUST") {
      this.surveyType = "REVENUE_CUST";
    }
    else {
      this.surveyType = "REVENUE";
    }
    //method to handle onchange
    //start
    if (this.SubCategory != this.OldSubCategory && this.SubCategory != undefined) {
      this.LoadYears();
      this.filterService.filterObj.SubCategory = surveyType.Revenue;
      this.pinnedFieldValuess = {
        baPinnedValue: "All",
        famPinnedValue: "All",
        countryPinnedValue: "All",
        brandPinnedValue: "All",
        distributorPinnedValue: "All",
        distributorSegmentPinnedValue: "All",
      }
      this.filterService.getUserFilter(this.surveyType, "", this.SubCategory).then(res => {
        if (res != null && res.Status == "Success") {
          this.savedFilterObject = this.filterService.filterObj;
          this.OldSubCategory = this.surveyType;
          this.convertToArray();
          this.getViewData();
        }
      }).catch(error => {
        this.savedFilterObject = this.filterService.filterObj;
        this.convertToArray();
        if (this.filterService.dataOriginal.length > 0) {
          this.applyCasecadingOnList().then(ele => {
            this.setPinnedFilterValue();
          });
        } else {
          this.getViewData();
        }
      });
      const today = new Date();
      //End
      this.filterService.advancedSearchDateFilterSubject.subscribe(val => {
        if (val == true && val != null) {
          console.log("ifblock")
          sessionStorage.setItem("revenueperiodFilterObject", JSON.stringify(this.filterService.filterObj));
          this.StartDate = this.filterService.filterObj.StartDate;
          this.EndDate = this.filterService.filterObj.EndDate;
          this.startDateCal = this.StartDate
          this.endDateCal = this.EndDate
          console.log(this.startDateCal, this.endDateCal)
        }
      })
      if (this.npsWidgetStatus != "") {
        this.filterToggle = false;
        this.filterDateToggle = false;
        this.pinFilterSecVisible = false;
      }
    }
  }

  LoadYears() {
    var years = [];
    for (var i = 2014; i <= 2100; i++) {
      years.push({ value: i, label: i });
    }
    this.yearList = years;
  }
  onMonthChange(event, dateType) {
    this.onDateSelection(new Date(event.year, event.month - 1, 1), dateType);
  }

  onYearChange(event, dateType) {
    this.onDateSelection(new Date(event.year, event.month - 1, 1), dateType);
  }

  submitYearfilter() {
    this.selectedYears = '';
    var currYear = new Date().getFullYear();
    if (this.selectedValues.length == 0) {
      this.selectedValues.push(currYear);
    }
    this.selectedValues.forEach(yearElement => {
      if (this.selectedYears == '')
        this.selectedYears = yearElement.toString();
      else
        this.selectedYears = this.selectedYears + this.delimiter + yearElement;
    });
    var filterObject = {
      "Years": this.selectedYears
    }
    sessionStorage.setItem("revenueperiodFilterObject", JSON.stringify(filterObject));
    this.filterService.updateFilterObject();
    this.filterToggle = false;
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
    this.submitted.emit(this.filterService.filterObj);
  }
  resetDatefilter() {
    this.isDateRange = true;
    this.isPeriodType = true;
    this.periodType.Q = false;
    this.periodType.H = false;
    this.periodType.Y = false;
    this.periodType.M = false;

    const today = new Date();
    this.StartDate = this.changeDateFormate(today);
    this.EndDate = this.changeDateFormate(today);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
    this.calculateStartAndEndDate('M');
  }

  calculateStartAndEndDate(key) {

    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = date;

    if (key == "Q") {
      var quarter = Math.floor((date.getMonth() + 3) / 3);
      if (quarter == 1) {
        firstDay = new Date(y, 0, 1);
        lastDay = new Date(y, 2, 31);
      } else if (quarter == 2) {
        firstDay = new Date(y, 3, 1);
        lastDay = new Date(y, 5, 30);
      }
      else if (quarter == 3) {
        firstDay = new Date(y, 6, 1);
        lastDay = new Date(y, 8, 30);
      }
      else if (quarter == 4) {
        firstDay = new Date(y, 9, 1);
        lastDay = new Date(y, 11, 31);
      }
    }
    if (key == "H") {
      var hy = Math.floor((date.getMonth() + 6) / 6);
      if (hy == 1) {
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, 5, 30);
      }
      else if (hy == 2) {
        var firstDay = new Date(y, 6, 1);
        var lastDay = new Date(y, 11, 31);
      }

    }

    if (key == "Y") {
      var firstDay = new Date(y, 0, 1);
      var lastDay = new Date(y, 11, 31);
    }

    this.StartDate = this.changeDateFormate(firstDay);
    this.EndDate = this.changeDateFormate(lastDay);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
  }

  onDateSelection(selectedDate, dateType) {
    this.isPeriodType = false;
    this.isDateRange = true;
    if (selectedDate != null && dateType == 'startDate') {
      this.startDateCal = this.changeDateFormate(new Date(selectedDate));
      var endDateFormat = this.changeDateFormat(this.EndDate);
      var endDate = new Date(endDateFormat);

      if (moment(selectedDate) <= moment(endDate)) {
        this.StartDate = this.changeDateFormate(new Date(selectedDate));
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
      }
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          this.periodType[key] = false;
        }
      }
    } else if (selectedDate != null && dateType == 'endDate') {
      this.endDateCal = this.changeDateFormate(new Date(selectedDate));
      var startDateFormat = this.changeDateFormat(this.StartDate);
      var startDate = new Date(startDateFormat);
      if (selectedDate >= moment(startDate)) {
        this.EndDate = this.changeDateFormate(new Date(selectedDate));
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'End Date should not be lesser than Start Date');

      }
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          this.periodType[key] = false;
        }
      }
    }
  }

  updatePeriodType(periodType) {
    var today = new Date();
    this.StartDate = this.changeDateFormate(today);
    this.EndDate = this.changeDateFormate(today);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
    for (var key in this.periodType) {
      if (this.periodType.hasOwnProperty(key)) {
        if (key == periodType) {
          this.periodType[key] = true;
          this.calculateStartAndEndDate(key);
        } else {
          this.periodType[key] = false;
        }
      }
    }
    this.isDateRange = false;
    this.isPeriodType = true;
  }


  convertToArray() {
    var periodType = null;
    this.selectedValues = [];
    if (this.savedFilterObject != null) {
      this.filterMapping.BAId = (this.savedFilterObject.BACode != null && this.savedFilterObject.BACode != "") ? this.savedFilterObject.BACode.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.FAMId = (this.savedFilterObject.FAMCode != null && this.savedFilterObject.FAMCode != "") ? this.savedFilterObject.FAMCode.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.CountryId = (this.savedFilterObject.Country != null && this.savedFilterObject.Country != "") ? this.savedFilterObject.Country.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.BrandId = (this.savedFilterObject.BrandName != null && this.savedFilterObject.BrandName != "") ? this.savedFilterObject.BrandName.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.DistributorId = (this.savedFilterObject.Distributor != null && this.savedFilterObject.Distributor != "") ? this.savedFilterObject.Distributor.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.DistributorSegmentId = (this.savedFilterObject.DistributorSegment != null && this.savedFilterObject.DistributorSegment != "") ? this.savedFilterObject.DistributorSegment.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []

    }
    if (this.StartDate != null && this.EndDate != null) {
      this.isPeriodType = false;
      this.isDateRange = true;
    }
    if (periodType != null) {
      this.isPeriodType = true;
      this.isDateRange = false;
    }
    if (this.filterService.filterObj.EndDate == null && this.filterService.filterObj.StartDate == null && this.periodType == null) {
      this.periodType.M = true;
      this.calculateStartAndEndDate('M');
    }
  }
  convertToDelimiterSeparated(del): any {
    return {
      "BACode": this.filterMapping.BAId != null ? this.filterMapping.BAId.join(del) : "",
      "FAMCode": this.filterMapping.FAMId != null ? this.filterMapping.FAMId.join(del) : "",
      "Country": this.filterMapping.CountryId != null ? this.filterMapping.CountryId.join(del) : "",
      "BrandName": this.filterMapping.BrandId != null ? this.filterMapping.BrandId.join(del) : "",
      "Distributor": this.filterMapping.DistributorId != null ? this.filterMapping.DistributorId.join(del) : "",
      "DistributorSegment": this.filterMapping.DistributorSegmentId != null ? this.filterMapping.DistributorSegmentId.join(del) : ""
    }
  }
  reloadData() {
    this.famData = this.data.map(x => { return { value: x.FAMID, label: x.FAMCode } });
    this.baData = this.data.map(x => { return { value: x.BAID, label: x.BACode } });
    this.countryData = this.countryContinent.map(x => { return { value: x.CountryID, label: x.CountryName } });
    this.countinentData = this.countryContinent.map(x => { return { value: x.ContinentID, label: x.ContinentName } });
    //Independent dropdowns
    this.brandData = this.brand.map(x => { return { value: x.BrandID, label: x.BrandName } });

    this.distributorSegmentData = this.filterService.distributorSegmentOriginal.map(x => { return { value: x.DistributorSegmentID, label: x.DistributorSegment } });
    this.distributorData = this.filterService.distributorOriginal.map(x => { return { value: x.DistributorID, label: x.DistributorName, number: x.AccountNumber } });
    this.industrySegmentData = this.filterService.industrySegmentOriginal.map(x => { return { value: x.IndustrySegmentID, label: x.IndustrySegment } });
    //Need casecading filter effect
    this.famData = this.getDistinct(this.famData, "value");
    this.baData = this.getDistinct(this.baData, "value");
    this.countryData = this.getDistinct(this.countryData, "value");
    this.countinentData = this.getDistinct(this.countinentData, "value");
    this.brandData = this.getDistinct(this.brandData, "value");
    this.distributorSegmentData = this.getDistinct(this.distributorSegmentData, "value");
    this.distributorData = this.getDistinct(this.distributorData, "value");
    this.industrySegmentData = this.getDistinct(this.industrySegmentData, "value");
    this.setPinnedFilterValue();
  }
  treeData = [];
  selectedFiles2 = [];

  formatTouchPointData(list: Array<any>): Array<any> {
    let ids = [];
    let tpList = [];

    list.forEach(element => {
      if (ids.indexOf(element.TouchPointID) < 0) {
        var label = element.TouchPoint;
        if (element.ParentTouchPointId > 0) {
          let parent = list.filter(ele => { return (ele.TouchPointID == element.ParentTouchPointId) })
          label = parent.map(ele => {
            return ele.TouchPoint + "-" + label
          });
          if (label.length > 0) {
            label = label[0];
          }
        }
        tpList.push({ label: label, value: element.TouchPointID });
        ids.push(element.TouchPointID);
      }
    });
    tpList = tpList.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    return tpList;
  }

  getViewData() {    
    this.filterService.getAllDistCustData(this.SubCategory).then(rawdata => {

      if (rawdata.Response != null) {

        this.filterService.dataOriginal = Object.assign(rawdata.Response.FamBADivBLFilterModel);

        this.filterService.countryContinentOriginal = Object.assign(rawdata.Response.FamCountryContiRegionFilterModel);
        this.filterService.brandOriginal = Object.assign(rawdata.Response.FamBABrandFilterModel);

        this.filterService.distributorSegmentOriginal = Object.assign(rawdata.Response.BABrandDistributorSegmentModel);
        this.filterService.distributorOriginal = Object.assign(rawdata.Response.FamBADistributorFilterModel);


        this.applyCasecadingOnList().then(ele => {
          this.setPinnedFilterValue();
          this.applyfilterOnLabel();
        });
        this.setAllDataCount();

      }
      else {
        this.applyCasecadingOnList().then(ele => {
          this.setPinnedFilterValue();
          this.applyfilterOnLabel();
        });
      }


    }).catch(error => {
      this.applyCasecadingOnList().then(ele => {
        this.setPinnedFilterValue();
      });
    })
    //}
  }

  setAllDataCount() {
    var famData = this.filterService.dataOriginal.map(x => { return { value: x.FAMID, label: x.FAMCode } });
    var baData = this.filterService.dataOriginal.map(x => { return { value: x.BAID, label: x.BACode } });

    var countryData = this.filterService.countryContinentOriginal.map(x => { return { value: x.CountryID, label: x.CountryName } });

    var distributorSegmentData = this.filterService.distributorSegmentOriginal.map(x => { return { value: x.DistributorSegmentID, label: x.DistributorSegment } });
    var distributorData = this.filterService.distributorOriginal.map(x => { return { value: x.DistributorID, label: x.DistributorName, number: x.AccountNumber } });

    //Independent dropdowns    
    var brandData = this.filterService.brandOriginal.map(x => { return { value: x.BrandID, label: x.BrandName } });

    //Need casecading filter effect
    this.countOfAllData.famCuunt = this.getDistinct(famData, "value").length;
    this.countOfAllData.baCount = this.getDistinct(baData, "value").length;

    this.countOfAllData.countryCount = this.getDistinct(countryData, "value").length;

    this.countOfAllData.brandCount = this.getDistinct(brandData, "value").length;

    this.countOfAllData.distributorCount = this.getDistinct(distributorData, "value").length;
    this.countOfAllData.distributorSegmentCount = this.getDistinct(distributorSegmentData, "value").length;
  }

  filterByBA(list, baList): any {
    return list.filter(ele => {
      if (baList.indexOf(ele.BAID) >= 0) {
        return ele;
      }
    });
  };

  filterByFAM(list, famList): any {
    return list.filter(ele => {
      if (famList.indexOf(ele.FAMID) >= 0) {
        return ele;
      }
    });
  };

  // Filter based on FAM
  SelectedFams(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  removeFakeClass() {
    var element = <HTMLElement>document.getElementsByClassName('ui-multiselect-panel')[0];
    if (element) {
      element.classList.remove('remove-arrow');
    }
  }

  onOpenPanel() {
    var element = <HTMLElement>document.getElementsByClassName('ui-multiselect-panel')[0];
    element.className += " remove-arrow";
  }

  // Filter based on BA
  SelectedBas(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList('ba').then(res => {
      this.showBusyIndicator = false;
    });
  }


  SelectedCountries(event) {
    this.showBusyIndicator = true;
    this.hideScroll = false;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedBrands(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedDistributorSegment(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }
  SelectedDistributor(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  resetFilter() {
    if (this.filterService.dataOriginal.length > 0) {
      this.famData = this.filterService.dataOriginal.map(x => { return { value: x.FAMID, label: x.FAMCode } });
      this.baData = this.filterService.dataOriginal.map(x => { return { value: x.BAID, label: x.BACode } });

      this.countryData = this.filterService.countryContinentOriginal.map(x => { return { value: x.CountryID, label: x.CountryName } });
      this.countinentData = this.filterService.countryContinentOriginal.map(x => { return { value: x.ContinentID, label: x.ContinentName } });

      //Independent dropdowns      
      this.brandData = this.filterService.brandOriginal.map(x => { return { value: x.BrandID, label: x.BrandName } });

      //Need casecading filter effect
      this.famData = this.getDistinct(this.famData, "value");
      this.baData = this.getDistinct(this.baData, "value");

      this.countryData = this.getDistinct(this.countryData, "value");
      this.countinentData = this.getDistinct(this.countinentData, "value");

      this.brandData = this.getDistinct(this.brandData, "value");

      this.filterMapping.BAId = [];
      this.filterMapping.FAMId = [];
      this.filterMapping.CountryId = [];
      this.filterMapping.BrandId = [];
      this.filterMapping.DistributorSegmentId = [];
      this.filterMapping.DistributorId = [];
      //Clear selected meta tag
      for (var key in this.selectedCount) {
        if (this.selectedCount.hasOwnProperty(key)) {
          this.selectedCount[key] = [];
        }
      }
    }
    this.getViewData();
  }

  submit() {
    this.reomveExtraIds();
    var delimiterSepratedObject = this.convertToDelimiterSeparated(this.delimiter);
    var piinedFieldsArray = [];
    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          piinedFieldsArray.push(key);
        }
      }
    }
    delimiterSepratedObject["PinnedField"] = piinedFieldsArray.join("|");
    sessionStorage.setItem("revenueFilterObject", JSON.stringify(delimiterSepratedObject));
    this.filterService.updateFilterObject();
    this.setPinnedFilterValue();
    this.filterToggle = false;
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
    this.submitted.emit(delimiterSepratedObject);
  }

  submitDatefilter() {
    this.successMsg = false;
    var startDate = "";
    var endDate = "";
    var periodType = "";

    if (this.isPeriodType) {
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          if (this.periodType[key] == true) {
            periodType = key;
          }
        }
      }
    }
    else {
      var startDateFormat = this.changeDateFormat(this.startDateCal);
      var startDateVal = new Date(startDateFormat);
      var endDateFormat = this.changeDateFormat(this.endDateCal);
      var endDateVal = new Date(endDateFormat);

      if (moment(startDateVal) <= moment(endDateVal)) {
        this.StartDate = this.startDateCal;
        startDate = this.startDateCal;
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
        return;
      }
      if (moment(endDateVal) >= moment(startDateVal)) {
        this.EndDate = this.endDateCal;
        endDate = this.endDateCal;
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'End Date should not be less than Start Date');
        return;
      }
    }
    var filterObject = {
      "StartDate": startDate,
      "EndDate": endDate,
      "PeriodType": periodType,
    }

    sessionStorage.setItem("revenueperiodFilterObject", JSON.stringify(filterObject));
    this.filterService.updateFilterObject();
    this.filterToggle = false;
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
    this.submitted.emit(this.filterService.filterObj);
  }

  saveMasterFilter(isDate = false) {
    var filterObject = null;
    if (isDate) {
      var startDate = "";
      var endDate = "";
      var periodType = "";

      if (this.isPeriodType) {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            if (this.periodType[key] == true) {
              periodType = key;
            }
          }
        }
      }
      else {
        var startDateFormat = this.changeDateFormat(this.startDateCal);
        var startDateVal = new Date(startDateFormat);
        var endDateFormat = this.changeDateFormat(this.endDateCal);
        var endDateVal = new Date(endDateFormat);

        if (moment(startDateVal) <= moment(endDateVal)) {
          this.StartDate = this.startDateCal;
          startDate = this.startDateCal;
        }
        else {
          this.successMsg = true;
          this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
          return;
        }
        if (moment(endDateVal) >= moment(startDateVal)) {
          endDate = this.endDateCal;
          this.EndDate = this.endDateCal;
        }
        else {
          this.successMsg = true;
          this.callErrorMsg('error', '', 'End Date should not be lesser than Start Date');
          return;
        }
      }

      filterObject = {
        "StartDate": startDate,
        "EndDate": endDate,
        "PeriodType": periodType,
      }
    }
    else {
      this.reomveExtraIds();
      filterObject = this.convertToDelimiterSeparated(this.delimiter);
      var piinedFieldsArray = [];
      for (var key in this.pinnedFields) {
        if (this.pinnedFields.hasOwnProperty(key)) {
          if (this.pinnedFields[key] == true) {
            piinedFieldsArray.push(key);
          }
        }
      }
      filterObject["PinnedField"] = piinedFieldsArray.join("|");
    }

    this.filterService.saveUserFilter(filterObject, isDate, this.surveyType).then(res => {
      if (res != null && res.Status == "Success") {
        if (isDate) {
          //filterObject = this.filterService.filterObj;
          sessionStorage.setItem("revenueperiodFilterObject", JSON.stringify(filterObject));
          this.filterDateToggle = false;
        } else {
          sessionStorage.setItem("revenueFilterObject", JSON.stringify(filterObject));
          this.filterToggle = false;
        }
        this.filterService.updateFilterObject();
        this.setPinnedFilterValue();
        this.submitted.emit(this.filterService.filterObj);
        this.pinFilterSecVisible = false;
        this.saved.emit("Success");
      }
      else {

        this.saved.emit("Failure");
      }
    }).catch(e => {

      this.saved.emit("ApiError");
    });
  }

  clearUserFilter() {
    this.filterService.clearUserFilter(this.surveyType).then(res => {
      if (res.Status == "Success" && res.Response === true) {
        this.getViewData();
        this.setPinnedFilterValue();

        var date = new Date();
        var fromDate = this.changeDateFormate(new Date(date.getFullYear(), date.getMonth(), 1));
        var toDate = this.changeDateFormate(date);

        this.isDateRange = true;
        this.isPeriodType = true;
        this.periodType.Q = false;
        this.periodType.H = false;
        this.periodType.Y = false;
        this.periodType.M = false;


        this.StartDate = fromDate;
        this.EndDate = toDate;
        this.startDateCal = this.StartDate;
        this.endDateCal = this.EndDate;

        for (var key in this.filterService.filterObj) {
          if (this.filterService.filterObj.hasOwnProperty(key)) {
            this.filterService.filterObj[key] = ""
          }
        }

        this.filterService.filterObj.StartDate = fromDate;
        this.filterService.filterObj.EndDate = toDate;

        this.filterService.filterObj
        var filterObjMain = {
          "BACode": "",
          "FAMCode": "",
          "BrandName": "",
          "Country": "",
          "Distributor": "",
          "DistributorSegment": "",
          "PinnedField": ""
        }
        var filterObjDate = {
          "StartDate": fromDate,
          "EndDate": toDate,
          "PeriodType": ""
        }
        sessionStorage.setItem("revenueFilterObject", JSON.stringify(filterObjMain));
        sessionStorage.setItem("revenueperiodFilterObject", JSON.stringify(filterObjDate));

        this.resetFilter();
        this.callErrorMsg('success', '', 'Saved filter cleared successfully.');
      }
      else {
        this.callErrorMsg('error', '', 'Unable to clear saved filter.');
      }
    });
  }

  setPinnedFilterValue() {
    this.pinnedFieldValuesArray.famPinnedValue = [];
    this.pinnedFieldValuesArray.baPinnedValue = [];
    this.pinnedFieldValuesArray.countryPinnedValue = [];
    this.pinnedFieldValuesArray.brandPinnedValue = [];

    this.pinnedFieldValuesArray.distributorSegmentPinnedValue = [];
    this.pinnedFieldValuesArray.distributorPinnedValue = [];

    if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length > 0) {
      var codes = this.famData.filter(ele => {
        if (this.filterMapping.FAMId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.famPinnedValue = this.filterMapping.FAMId.length == 1 ? codes[0].toString() : (this.filterMapping.FAMId.length == this.countOfAllData.famCuunt ? "All" : codes.length);
      this.pinnedFieldValuesArray.famPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.famPinnedValue = "All";
    }

    if (this.filterMapping.BAId != null && this.filterMapping.BAId.length > 0) {
      var codes = this.baData.filter(ele => {
        if (this.filterMapping.BAId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.baPinnedValue = this.filterMapping.BAId.length == 1 ? codes[0].toString() : (this.filterMapping.BAId.length == this.countOfAllData.baCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.baPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.baPinnedValue = "All";
    }

    if (this.filterMapping.CountryId != null && this.filterMapping.CountryId.length > 0) {
      var codes = this.countryData.filter(ele => {
        if (this.filterMapping.CountryId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.countryPinnedValue = this.filterMapping.CountryId.length == 1 ? codes[0].toString() : (this.filterMapping.CountryId.length == this.countOfAllData.countryCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.countryPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.countryPinnedValue = "All";
    }

    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length > 0) {
      var codes = this.brandData.filter(ele => {
        if (this.filterMapping.BrandId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.brandPinnedValue = this.filterMapping.BrandId.length == 1 ? codes[0].toString() : (this.filterMapping.BrandId.length == this.countOfAllData.brandCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.brandPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.brandPinnedValue = "All";
    }

    if (this.filterMapping.DistributorId != null && this.filterMapping.DistributorId.length > 0) {
      var codes = this.distributorData.filter(ele => {
        if (this.filterMapping.DistributorId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.distributorPinnedValue = this.filterMapping.DistributorId.length == 1 ? codes[0].toString() : "All";
      this.pinnedFieldValuesArray.distributorPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.distributorPinnedValue = "All";
    }

    if (this.filterMapping.DistributorSegmentId != null && this.filterMapping.DistributorSegmentId.length > 0) {
      var codes = this.distributorSegmentData.filter(ele => {
        if (this.filterMapping.DistributorSegmentId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.distributorSegmentPinnedValue = this.filterMapping.DistributorSegmentId.length == 1 ? codes[0].toString() : "All";
      this.pinnedFieldValuesArray.distributorSegmentPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.distributorSegmentPinnedValue = "All";
    }

    if (this.filterService.filterObj.PinnedField != null && this.filterService.filterObj.PinnedField != "") {
      for (var key in this.pinnedFields) {
        if (this.pinnedFields.hasOwnProperty(key)) {
          this.pinnedFields[key] = false;
          if (this.filterService.filterObj.PinnedField.split(this.delimiter).indexOf(key) >= 0) {
            this.pinnedFields[key] = true;
          }
        }
      }
    }


  }

  reomveExtraIds(displayOnly = false) {

    var famids = [];
    var baids = [];
    var countryids = [];
    var brandids = [];

    var distributorids = [];
    var distributorSegmentids = [];

    if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length > 0) {
      this.filterMapping.FAMId.forEach(element => {
        if (this.famData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          famids.push(element);
        }
      });
    }

    if (this.filterMapping.BAId != null && this.filterMapping.BAId.length > 0) {
      this.filterMapping.BAId.forEach(element => {
        if (this.baData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          baids.push(element);
        }
      });
    }

    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length > 0) {
      this.filterMapping.BrandId.forEach(element => {
        if (this.brandData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          brandids.push(element);
        }
      });
    }

    if (this.filterMapping.CountryId != null && this.filterMapping.CountryId.length > 0) {
      this.filterMapping.CountryId.forEach(element => {
        if (this.countryData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          countryids.push(element);
        }
      });

    }

    if (this.filterMapping.DistributorId != null && this.filterMapping.DistributorId.length > 0) {
      this.filterMapping.DistributorId.forEach(element => {
        if (this.distributorData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          distributorids.push(element);
        }
      });
    }
    if (this.filterMapping.DistributorSegmentId != null && this.filterMapping.DistributorSegmentId.length > 0) {
      this.filterMapping.DistributorSegmentId.forEach(element => {
        if (this.distributorSegmentData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          distributorSegmentids.push(element);
        }
      });
    }

    if (displayOnly) {
      this.selectedCount.famCount = this.famData.filter(ele => { if (famids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.baCount = this.baData.filter(ele => { if (baids.indexOf(ele.value) >= 0) { return ele } });

      this.selectedCount.countryCount = this.countryData.filter(ele => { if (countryids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.brandCount = this.brandData.filter(ele => { if (brandids.indexOf(ele.value) >= 0) { return ele } });

      this.selectedCount.distributorCount = this.distributorData.filter(ele => { if (distributorids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.distributorSegmentCount = this.distributorSegmentData.filter(ele => { if (distributorSegmentids.indexOf(ele.value) >= 0) { return ele } });

    } else {
      this.filterMapping.FAMId = famids;
      this.filterMapping.BAId = baids;
      this.filterMapping.CountryId = countryids;
      this.filterMapping.BrandId = brandids;
      this.filterMapping.DistributorId = distributorids;
      this.filterMapping.DistributorSegmentId = distributorSegmentids;
    }

  }

  togglePin(fieldName) {
    var pinnedCount = 0;

    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          pinnedCount++;
        }
      }
    }

    if (pinnedCount <= 3) {
      switch (fieldName) {
        case "fam": {
          this.pinnedFields.famPinned = !this.pinnedFields.famPinned;
          break;
        }
        case "ba": {
          this.pinnedFields.baPinned = !this.pinnedFields.baPinned;
          break;
        }
        case "country": {
          this.pinnedFields.countryPinned = !this.pinnedFields.countryPinned;
          break;
        }
        case "brand": {
          this.pinnedFields.brandPinned = !this.pinnedFields.brandPinned;
          break;
        }

        case "distributor": {
          this.pinnedFields.distributorPinned = !this.pinnedFields.distributorPinned;
          break;
        }
        case "distributorsegment": {
          this.pinnedFields.distributorSegmentPinned = !this.pinnedFields.distributorSegmentPinned;
          break;
        }
      }
    }
    else {

      switch (fieldName) {
        case "fam": {
          this.isShowAlert = !this.pinnedFields.famPinned;
          this.pinnedFields.famPinned = this.pinnedFields.famPinned ? !this.pinnedFields.famPinned : this.pinnedFields.famPinned;
          break;
        }
        case "ba": {
          this.isShowAlert = !this.pinnedFields.baPinned;
          this.pinnedFields.baPinned = this.pinnedFields.baPinned ? !this.pinnedFields.baPinned : this.pinnedFields.baPinned;
          break;
        }
        case "country": {
          this.isShowAlert = !this.pinnedFields.countryPinned;
          this.pinnedFields.countryPinned = this.pinnedFields.countryPinned ? !this.pinnedFields.countryPinned : this.pinnedFields.countryPinned;
          break;
        }
        case "brand": {
          this.isShowAlert = !this.pinnedFields.brandPinned;
          this.pinnedFields.brandPinned = this.pinnedFields.brandPinned ? !this.pinnedFields.brandPinned : this.pinnedFields.brandPinned;
          break;
        }
        case "distributor": {
          this.isShowAlert = !this.pinnedFields.distributorPinned;
          this.pinnedFields.distributorPinned = this.pinnedFields.distributorPinned ? !this.pinnedFields.distributorPinned : this.pinnedFields.distributorPinned;
          break;
        }
        case "distributorsegment": {
          this.isShowAlert = !this.pinnedFields.distributorSegmentPinned;
          this.pinnedFields.distributorSegmentPinned = this.pinnedFields.distributorSegmentPinned ? !this.pinnedFields.distributorSegmentPinned : this.pinnedFields.distributorSegmentPinned;
          break;
        }
      }


      if (this.isShowAlert) {
        this.successMsg = true;
        this.callErrorMsg('info', '', 'You can select only 4 quick filters.');
      }

    }


    var piinedFieldsArray = [];
    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          piinedFieldsArray.push(key);
        }
      }
    }

    var valueFromSession = sessionStorage.getItem("revenueFilterObject");
    var delimiterSepratedObject = this.filterService.filterObj
    if (valueFromSession != null) {
      delimiterSepratedObject = JSON.parse(sessionStorage.getItem("revenueFilterObject"))
    }
    delimiterSepratedObject["PinnedField"] = piinedFieldsArray.join("|");

    sessionStorage.setItem("revenueFilterObject", JSON.stringify(delimiterSepratedObject));
    this.filterService.updateFilterObject();

    this.pinChanged.emit(this.pinnedFields);
  }

  toggleFilter() {
    this.clearMessage();
    this.filterToggle = !this.filterToggle;

    if (this.filterToggle) {
      this.convertToArray();
      this.applyCasecadingOnList();
    }
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
  }

  toggleDateFilter() {
    this.filterDateToggle = !this.filterDateToggle;
    var currYear = new Date().getFullYear();
    if (this.filterService.filterObj.Years == undefined || this.filterService.filterObj.Years == "") {
      this.selectedValues.push(currYear);
    }
    else {
      this.selectedValues = [];
      var splitYears = this.filterService.filterObj.Years.split(this.delimiter);
      splitYears.forEach(element => {
        if (element != "")
          this.selectedValues.push(parseInt(element));
      });
    }
    this.filterToggle = false;
  }

  closeInlineFilter() {
    this.pinFilterSecVisible = false;
  }

  toggleInlineFilter(fieldName) {
    this.pinFilterSecVisible = !this.pinFilterSecVisible

    if (this.pinFilterSecVisible) {
      this.convertToArray();

      this.applyCasecadingOnList();
    }

    for (var key in this.inlineFilter) {
      if (this.inlineFilter.hasOwnProperty(key)) {
        this.inlineFilter[key] = false;
        if (key == fieldName) {
          this.inlineFilter[key] = true;
        }
      }
    }

    this.filterDateToggle = false;
    this.filterToggle = false;
  }
  applyfilterOnLabel() {
    this.reomveExtraIds(true);
    if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length >= 4) {
      if (this.famDDL) {
        this.famDDL.valuesAsString = this.selectedCount.famCount.length + " items selected";
      }
      else if (this.famDDLPinned) {
        this.famDDLPinned.valuesAsString = this.selectedCount.famCount.length + " items selected";
      }

    }
    else if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length < 4 && this.filterMapping.FAMId.length >= 1) {
      if (this.famDDL) {
        this.famDDL.valuesAsString = this.selectedCount.famCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.famDDLPinned) {
        this.famDDLPinned.valuesAsString = this.selectedCount.famCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.BAId != null && this.filterMapping.BAId.length >= 4) {
      if (this.baDDL) {
        this.baDDL.valuesAsString = this.selectedCount.baCount.length + " items selected";
      }
      else if (this.baDDLPinned) {
        this.baDDLPinned.valuesAsString = this.selectedCount.baCount.length + " items selected";
      }

    }
    else if (this.filterMapping.BAId != null && this.filterMapping.BAId.length < 4 && this.filterMapping.BAId.length >= 1) {
      if (this.baDDL) {
        this.baDDL.valuesAsString = this.selectedCount.baCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.baDDLPinned) {
        this.baDDLPinned.valuesAsString = this.selectedCount.baCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.CountryId != null && this.filterMapping.CountryId.length >= 4) {
      if (this.countryDDL) {
        this.countryDDL.valuesAsString = this.selectedCount.countryCount.length + " items selected";
      }
      else if (this.countryDDLPinned) {
        this.countryDDLPinned.valuesAsString = this.selectedCount.countryCount.length + " items selected";
      }

    }
    else if (this.filterMapping.CountryId != null && this.filterMapping.CountryId.length < 4 && this.filterMapping.CountryId.length >= 1) {
      if (this.countryDDL) {
        this.countryDDL.valuesAsString = this.selectedCount.countryCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.countryDDLPinned) {
        this.countryDDLPinned.valuesAsString = this.selectedCount.countryCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length >= 4) {
      if (this.brandDDL) {
        this.brandDDL.valuesAsString = this.selectedCount.brandCount.length + " items selected";
      } else if (this.brandDDLPinned) {
        this.brandDDLPinned.valuesAsString = this.selectedCount.brandCount.length + " items selected";
      }
    }
    else if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length < 4 && this.filterMapping.BrandId.length >= 1) {
      if (this.brandDDL) {
        this.brandDDL.valuesAsString = this.selectedCount.brandCount.map(ele => { return ele.label }).join(", ");
      } else if (this.brandDDLPinned) {
        this.brandDDLPinned.valuesAsString = this.selectedCount.brandCount.map(ele => { return ele.label }).join(", ")
      }
    }

    if (this.filterMapping.DistributorId != null && this.filterMapping.DistributorId.length >= 4) {
      if (this.distributorDDL) {
        this.distributorDDL.valuesAsString = this.selectedCount.distributorCount.length + " items selected";
      } else if (this.distributorDDLPinned) {
        this.distributorDDLPinned.valuesAsString = this.selectedCount.distributorCount.length + " items selected";
      }

    } else if (this.filterMapping.DistributorId != null && this.filterMapping.DistributorId.length < 4 && this.filterMapping.DistributorId.length >= 1) {
      if (this.distributorDDL) {
        this.distributorDDL.valuesAsString = this.selectedCount.distributorCount.map(ele => { return ele.label }).join(", ");
      } else if (this.distributorDDLPinned) {
        this.distributorDDLPinned.valuesAsString = this.selectedCount.distributorCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.DistributorSegmentId != null && this.filterMapping.DistributorSegmentId.length >= 4) {
      if (this.distributorSegmentDDL) {
        this.distributorSegmentDDL.valuesAsString = this.selectedCount.distributorSegmentCount.length + " items selected";
      } else if (this.distributorSegmentDDLPinned) {
        this.distributorSegmentDDLPinned.valuesAsString = this.selectedCount.distributorSegmentCount.length + " items selected";
      }

    } else if (this.filterMapping.DistributorSegmentId != null && this.filterMapping.DistributorSegmentId.length < 4) {
      if (this.distributorSegmentDDL) {
        this.distributorSegmentDDL.valuesAsString = this.selectedCount.distributorSegmentCount.map(ele => { return ele.label }).join(", ");
      } else if (this.distributorSegmentDDLPinned) {
        this.distributorSegmentDDLPinned.valuesAsString = this.selectedCount.distributorSegmentCount.map(ele => { return ele.label }).join(", ");
      }
    }
  }

  removeSelectedValue(id, fieldName) {
    switch (fieldName) {
      case "fam": {
        var index = this.filterMapping.FAMId.indexOf(id);
        if (index > -1) {
          this.filterMapping.FAMId.splice(index, 1);
          this.SelectedFams(true);
          if (this.famDDL) {
            this.famDDL.updateLabel();
          } else if (this.famDDLPinned) {
            this.famDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "ba": {
        var index = this.filterMapping.BAId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BAId.splice(index, 1);
          this.SelectedBas(true);
          if (this.baDDL) {
            this.baDDL.updateLabel();
          } else if (this.baDDLPinned) {
            this.baDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "country": {
        var index = this.filterMapping.CountryId.indexOf(id);
        if (index > -1) {
          this.filterMapping.CountryId.splice(index, 1);
          this.SelectedCountries(true);
          if (this.countryDDL) {
            this.countryDDL.updateLabel();
          } else if (this.countryDDLPinned) {
            this.countryDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "brand": {
        var index = this.filterMapping.BrandId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BrandId.splice(index, 1);
          this.SelectedBrands(true);
          if (this.brandDDL) {
            this.brandDDL.updateLabel();
          } else if (this.brandDDLPinned) {
            this.brandDDLPinned.updateLabel();
          }

        }
        break;
      }
      case "distributorsegment": {
        var index = this.filterMapping.DistributorSegmentId.indexOf(id);
        if (index > -1) {
          this.filterMapping.DistributorSegmentId.splice(index, 1);
          this.SelectedDistributorSegment(true);
          if (this.distributorSegmentDDL) {
            this.distributorSegmentDDL.updateLabel();
          } else if (this.distributorSegmentDDLPinned) {
            this.distributorSegmentDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "distributor": {
        var index = this.filterMapping.DistributorId.indexOf(id);
        if (index > -1) {
          this.filterMapping.DistributorId.splice(index, 1);
          this.SelectedDistributor(true);
          if (this.distributorDDL) {
            this.distributorDDL.updateLabel();
          } else if (this.distributorDDLPinned) {
            this.distributorDDLPinned.updateLabel();
          }
        }
        break;
      }
    }
  }

  private getDistinct(list, key): any[] {

    var uniqueLists = [];
    var ids = [];
    list.forEach(element => {
      if (!this.includes(ids, element[key]) && element[key] != null) {
        uniqueLists.push(element);
        ids.push(element[key]);
      }
    });
    uniqueLists = uniqueLists.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    })
    return uniqueLists;
  }

  private includes(container, value) {
    var returnValue = false;
    var pos = container.indexOf(value);
    if (pos >= 0) {
      returnValue = true;
    }
    return returnValue;
  }

  private changeDateFormat(data) {
    var splitDate = data.split('-');
    var month_names = ["Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"];
    var indexMonth = month_names.indexOf(splitDate[1]);
    return (indexMonth + 1) +
      "/" + splitDate[0] +
      "/" + splitDate[2];
  }

  private changeDateFormate(date) {
    // Try and parse the passed value.
    var momentDate = moment(date);
    return momentDate.format('DD-MMM-YYYY');
  }

  callErrorMsg(type, summary = '', detail = '') {
    this.successMsg = true;
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () { document.getElementById('ClearMsg').click(); }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }

  applyCasecadingOnList(field = '') {
    return new Promise(resolve => {

      setTimeout(() => {

        this.famData = this.filterList('fam').map(x => { return { value: x.FAMID, label: x.FAMCode } });;
        this.baData = this.filterList('ba').map(x => { return { value: x.BAID, label: x.BACode } });

        this.brandData = this.filterList('brand').map(x => { return { value: x.BrandID, label: x.BrandName } });
        this.countryData = this.filterList('country').map(x => { return { value: x.CountryID, label: x.CountryName } });
        this.countinentData = this.filterList('continent').map(x => { return { value: x.ContinentID, label: x.ContinentName } });

        this.distributorData = this.filterList('distributor').map(x => { return { value: x.DistributorID, label: x.DistributorName + " (" + x.AccountNumber + ")", number: x.AccountNumber } });
        this.distributorSegmentData = this.filterList('distributorsegment').map(x => { return { value: x.DistributorSegmentID, label: x.DistributorSegment } });
        this.industrySegmentData = this.filterList('industrysegment').map(x => { return { value: x.IndustrySegmentID, label: x.IndustrySegment } });


        this.famData = this.getDistinct(this.famData, "value");
        this.baData = this.getDistinct(this.baData, "value");
        this.brandData = this.getDistinct(this.brandData, "value");
        this.countryData = this.getDistinct(this.countryData, "value");
        this.countinentData = this.getDistinct(this.countinentData, "value");
        this.distributorData = this.getDistinct(this.distributorData, "value");
        this.distributorSegmentData = this.getDistinct(this.distributorSegmentData, "label");
        this.industrySegmentData = this.getDistinct(this.industrySegmentData, "label");
        this.removeFakeClass();
        setTimeout(() => {
          this.applyfilterOnLabel()
        }, 5);
        resolve({ Status: "success" });
      }, 5);
    });
  }

  filterList(fieldName): Array<any> {

    var badivfamblList = JSON.parse(JSON.stringify(this.filterService.dataOriginal));
    var brandList = JSON.parse(JSON.stringify(this.filterService.brandOriginal));
    var zoneBranchList = JSON.parse(JSON.stringify(this.filterService.zoneBranchOriginal));
    var countryContinentList = JSON.parse(JSON.stringify(this.filterService.countryContinentOriginal));
    var tpList = JSON.parse(JSON.stringify(this.filterService.touchPointsOriginal));
    var distributorList = JSON.parse(JSON.stringify(this.filterService.distributorOriginal));
    var distributorSegmentList = JSON.parse(JSON.stringify(this.filterService.distributorSegmentOriginal));
    var industrySegmentList = JSON.parse(JSON.stringify(this.filterService.industrySegmentOriginal));
    var listToReturn = [];

    if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length > 0 && fieldName != 'fam') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.FAMId.indexOf(ele.FAMID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, badivfamblList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, countryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, badivfamblList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributorList);

    }

    if (this.filterMapping.BAId != null && this.filterMapping.BAId.length > 0 && fieldName != 'ba') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.BAId.indexOf(ele.BAID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, badivfamblList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, countryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, badivfamblList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributorList);

    }

    if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length > 0 && fieldName != 'div') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.DIVId.indexOf(ele.DivisionID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, badivfamblList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, countryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, badivfamblList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributorList);

    }
    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length > 0 && fieldName != 'brand') {
      brandList = brandList.filter(ele => {
        if (this.filterMapping.BrandId.indexOf(ele.BrandID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, brandList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, brandList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, brandList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, brandList, countryContinentList);
      tpList = this.filterBasedOnEligibility(false, false, true, brandList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, brandList, distributorList);

    }

    if (this.filterMapping.CountryId != null && this.filterMapping.CountryId.length > 0 && fieldName != 'country') {
      countryContinentList = countryContinentList.filter(ele => {
        if (this.filterMapping.CountryId.indexOf(ele.CountryID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, false, countryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, false, countryContinentList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, false, countryContinentList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributorList);
    }

    if (this.filterMapping.ContinentId != null && this.filterMapping.ContinentId.length > 0 && fieldName != 'continent') {
      countryContinentList = countryContinentList.filter(ele => {
        if (this.filterMapping.ContinentId.indexOf(ele.ContinentID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, false, countryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, false, countryContinentList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, false, countryContinentList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributorList);


    }
    if (this.filterMapping.DistributorId != null && this.filterMapping.DistributorId.length > 0 && fieldName != 'distributor') {
      distributorList = distributorList.filter(ele => {
        if (this.filterMapping.DistributorId.indexOf(ele.DistributorID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, distributorList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, distributorList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, distributorList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, distributorList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, true, true, distributorList, distributorList);

    }
    if (this.filterMapping.regionId != null && this.filterMapping.regionId.length > 0 && fieldName != 'region') {
      countryContinentList = countryContinentList.filter(ele => {
        if (this.filterMapping.regionId.indexOf(ele.RegionID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, false, countryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, false, countryContinentList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, false, countryContinentList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributorList);

    }

    if (this.filterMapping.ZoneId != null && this.filterMapping.ZoneId.length > 0 && fieldName != 'zone') {
      zoneBranchList = zoneBranchList.filter(ele => {
        if (this.filterMapping.ZoneId.indexOf(ele.ZoneCode) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, zoneBranchList, countryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, zoneBranchList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, distributorList);

    }

    if (this.filterMapping.BranchId != null && this.filterMapping.BranchId.length > 0 && fieldName != 'branch') {
      zoneBranchList = zoneBranchList.filter(ele => {
        if (this.filterMapping.BranchId.indexOf(ele.BranchCode) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, zoneBranchList, countryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, zoneBranchList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, distributorList);

    }

    if (fieldName == 'fam' || fieldName == 'ba' || fieldName == 'div' || fieldName == 'bl') {
      listToReturn = badivfamblList;
    }
    else if (fieldName == 'branch' || fieldName == 'zone') {
      listToReturn = zoneBranchList;
    }
    else if (fieldName == 'country' || fieldName == 'continent' || fieldName == 'region') {
      listToReturn = countryContinentList;
    }

    else if (fieldName == 'brand') {
      listToReturn = brandList;
    }
    else if (fieldName == 'tp') {
      listToReturn = tpList;
    }
    else if (fieldName == 'distributor') {
      listToReturn = distributorList;
    }
    else if (fieldName == 'distributorsegment') {
      listToReturn = distributorSegmentList;
    }
    else if (fieldName == 'industrysegment') {
      listToReturn = industrySegmentList;
    }
    return listToReturn;
  }

  filterBasedOnEligibility(divEligible, famEligible, baEligible, fromList, toList): Array<any> {
    if (famEligible) {
      var fams = [];
      for (var i = 0; i < fromList.length; i++) {
        fams.push(fromList[i].FAMID);
      };
      toList = this.filterByFAM(toList, fams);
    }
    if (baEligible) {
      var bas = [];
      for (var i = 0; i < fromList.length; i++) {
        bas.push(fromList[i].BAID);
      };
      toList = this.filterByBA(toList, bas);
    }
    return toList
  }

  closeFilterPanel() {
    this.clearMessage();
    this.filterToggle = false;
    this.filterDateToggle = false;
  }

  @ViewChild('scrollPanel') private myScrollContainer;
  hideScroll: boolean = false;
  scrollPos(event) {
    this.hideScroll = true
  }
  removeTitle(){
    let elemList = Array.from(document.querySelectorAll('.ui-multiselect-label-container'));   
    elemList.forEach((elem)=>{
      elem.setAttribute('title', '');
    });    
  }
}
